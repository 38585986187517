// @ts-strict
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box
} from '@chakra-ui/react';
import './ReportListingFilterWidget.css';

interface Props {
  selectedMonth: string;
  setSelectedMonth: (newMonth: string) => void;
  dates: string[];
}

export function ReportListingFilterWidget({ dates, selectedMonth, setSelectedMonth }: Props) {
  const uniqueYears = dates
    .map((d) => new Date(d).getFullYear())
    .filter((v, i, a) => a.indexOf(v) === i)
    .reverse();
  const uniqueMonths = dates
    .map((d) =>
      new Date(d).toLocaleString('default', {
        month: 'long',
        year: 'numeric'
      })
    )
    .filter((v, i, a) => a.indexOf(v) === i)
    .reverse();

  return (
    <Accordion style={{ minWidth: '180px' }} defaultIndex={0}>
      {uniqueYears.map((year) => (
        <AccordionItem key={year}>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              {year}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {uniqueMonths
              .filter((m) => m.includes(year.toString()))
              .map((month) => {
                const className =
                  selectedMonth === month
                    ? 'reportsVisibleLabel reportsVisibleChecked'
                    : 'reportsVisibleLabel';
                return (
                  <label key={month} className={className}>
                    <input
                      type="radio"
                      className="reportsVisibleItem"
                      name="reportsVisible"
                      value={month}
                      onChange={(e) => {
                        setSelectedMonth(e.target.value);
                      }}
                    />
                    {month.split(' ')[0]}
                  </label>
                );
              })}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}
