import React from 'react';
import { useLocation } from 'react-router';

// Converts query params to JS/TS obj
// Ex: ?eventTypes=Follow-Up&eventSeverities=Medium,Low
// { eventTypes: 'Follow-Up', eventSeverities: ['Medium','Low'] }
const useQueryParams = <T,>() => {
  const { search } = useLocation();

  return React.useMemo(() => {
    const query = new URLSearchParams(search);
    const obj = {};
    for (const [key, value] of query.entries()) {
      if (value.includes(',')) {
        obj[key] = value.split(',');
      } else {
        obj[key] = value;
      }
    }
    return obj as T;
  }, [search]);
};

export default useQueryParams;
