import { HStack, Container, Box, Image, Text, VStack, Link } from '@chakra-ui/react';
import React, { ReactChild, useMemo } from 'react';

type FrontPageProps = {
  heading?: ReactChild;
};

const FrontPage: React.FC<FrontPageProps> = ({ children, heading }) => {
  const backgroundIndex = useMemo(() => Math.floor(Math.random() * (12 - 1) + 1), []);

  return (
    <HStack h="100%">
      <Container minH="md" minW="md" maxW="md" h="100%" background="white">
        <VStack h="100%" justifyContent="center">
          <HStack>
            <Link href="/">
              <Image src="/ScootScienceLogo.png" boxSize="50px" alt="Scoot Science Logo" />
            </Link>
            <Text pt="7px" fontSize="2xl">
              Scoot Science
            </Text>
          </HStack>
          {heading}
          <Container mb="40px">{children}</Container>
        </VStack>
      </Container>
      <Box className="background" backgroundImage={`url(backgrounds/${backgroundIndex}.jpg)`}></Box>
    </HStack>
  );
};

export default FrontPage;
