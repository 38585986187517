import { EquipmentAdminForm } from 'pages/project/[Id]/Admin/Equipment/EquipmentAdmin';
import { UseControllerProps, useController } from 'react-hook-form';
import { EquipmentNumberType } from './EquipmentNumberType';
import { EquipmentSelectionType } from './EquipmentSelectionType';
import { EquipmentTextType } from './EquipmentTextType';

export const EquipmentStateRouter = (
  props: UseControllerProps<EquipmentAdminForm, `equipmentTypes.${number}.states.${number}`>
) => {
  const { field, fieldState } = useController<
    EquipmentAdminForm,
    `equipmentTypes.${number}.states.${number}`
  >(props);

  switch (field.value.__typename) {
    case 'EquipmentSelectionState':
      return <EquipmentSelectionType {...props} fieldState={fieldState} />;
    case 'EquipmentNumberState':
      return <EquipmentNumberType {...props} fieldState={fieldState} />;
    case 'EquipmentTextState':
      return <EquipmentTextType {...props} fieldState={fieldState} />;
    default:
      return (
        <>
          {/*
            // @ts-ignore */}
          <p>Handler not found for {field.value.__typename}</p>
        </>
      );
  }
};
