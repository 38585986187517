import { WarningIcon } from '@chakra-ui/icons';
import { Center, Divider, HStack, Skeleton, Text, Tooltip, useInterval } from '@chakra-ui/react';
import { useCubeQuery } from '@cubejs-client/react';
import { ProjectContext } from 'contexts/ProjectContext';
import { format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useContext, useEffect, useState } from 'react';
import AirTemperature from './AirTemperature';
import Cloudiness from './Cloudiness';
import MoonPhase from './MoonPhase';
import Precipitation from './Precipitation';
import Sunrise from './Sunrise';
import Sunset from './Sunset';
import Wind from './Wind';

type WeatherState = {
  cloudiness?: number | string;
  airTemperature?: number | string;
  uWind?: number | string;
  vWind?: number | string;
  rain?: number | string;
};

type CubeWeather = {
  'TessForecastAtmos.u10': number;
  'TessForecastAtmos.v10': number;
  'TessForecastAtmos.tcc': number;
  'TessForecastAtmos.t2m': number;
  'TessForecastAtmos.tp': number;
};

export const WEATHER_PLACEHOLDER = '-';

const Weather = ({ smbSiteId, lat, lon }: { smbSiteId: number; lat: number; lon: number }) => {
  const projectContext = useContext(ProjectContext);

  const [weatherState, setWeatherState] = useState<WeatherState>(null);
  const [now, setNow] = useState('');

  const { resultSet, isLoading, error } = useCubeQuery<CubeWeather>({
    timeDimensions: [
      {
        dimension: 'TessForecastAtmos.measuredAt',
        dateRange: 'from 4 hours ago to 24 hours from now'
        // dateRange: 'next 24 hours from now'
      }
    ],
    dimensions: ['Site.id', 'TessForecastAtmos.measuredAt'],
    measures: [
      'TessForecastAtmos.totalPrecipAvg',
      'TessForecastAtmos.windNorthAvg',
      'TessForecastAtmos.windEastAvg',
      'TessForecastAtmos.cloudCoverAvg',
      'TessForecastAtmos.airTempAvg'
    ],
    filters: [
      {
        member: 'TessForecastAtmos.siteId',
        operator: 'equals',
        values: [smbSiteId.toString()]
      }
    ],
    limit: 8,
    ungrouped: false,
    timezone: projectContext.timezone
  });

  useEffect(() => {
    if (isLoading || error || !resultSet) return;

    const data = resultSet.rawData()?.[0];

    if (data) {
      setWeatherState({
        cloudiness:
          resultSet.rawData()?.reduce((acc, d) => acc + d['TessForecastAtmos.cloudCoverAvg'], 0) /
          resultSet.rawData()?.length,
        airTemperature:
          resultSet.rawData()?.reduce((acc, d) => acc + d['TessForecastAtmos.airTempAvg'], 0) /
          resultSet.rawData()?.length,
        uWind:
          resultSet.rawData()?.reduce((acc, d) => acc + d['TessForecastAtmos.windEastAvg'], 0) /
          resultSet.rawData()?.length,
        vWind:
          resultSet.rawData()?.reduce((acc, d) => acc + d['TessForecastAtmos.windNorthAvg'], 0) /
          resultSet.rawData()?.length,
        rain: resultSet
          .rawData()
          ?.reduce((acc, d) => acc + d['TessForecastAtmos.totalPrecipAvg'], 0)
      });
    }
  }, [resultSet, isLoading, error]);

  useInterval(() => {
    const nowInTz = utcToZonedTime(
      zonedTimeToUtc(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone),
      projectContext.timezone
    );
    setNow(format(nowInTz, 'kk:mm'));
  }, 1000);

  if (isLoading) {
    return <Skeleton w="100%" h="40px" />;
  }

  if (error) {
    return (
      <Center
        data-cypress="weather-headline-error"
        w="100%"
        h="40px"
        border="1px"
        borderColor="red.500"
        borderRadius="3px">
        <WarningIcon boxSize={4} mr="5px" color="red.500" />
        <Text pt="4px">We experienced an unexpected error loading your weather</Text>
      </Center>
    );
  }

  return (
    <HStack
      data-cypress="weather-headline"
      flexWrap="wrap"
      w="100%"
      justifyContent="space-evenly"
      boxShadow="base"
      borderRadius="base">
      <Tooltip label="Local Time">
        <Text>
          {now} {projectContext.timezoneLabel}
        </Text>
      </Tooltip>
      <Divider h="20px" orientation="vertical" borderColor="gray.500" />
      <Tooltip label="Latitude Longitude">
        <HStack justifyContent="space-evenly">
          <Text>Lat: {lat.toFixed(2)}</Text>
          <Text>Long: {lon.toFixed(2)}</Text>
        </HStack>
      </Tooltip>
      <Divider h="20px" orientation="vertical" borderColor="gray.500" />
      <AirTemperature value={weatherState?.airTemperature} />
      <Divider h="20px" orientation="vertical" borderColor="gray.500" />
      <Cloudiness value={weatherState?.cloudiness} />
      <Divider h="20px" orientation="vertical" borderColor="gray.500" />
      <Precipitation value={weatherState?.rain} />
      <Divider h="20px" orientation="vertical" borderColor="gray.500" />
      <Wind u={weatherState?.uWind} v={weatherState?.vWind} />
      <Divider h="20px" orientation="vertical" borderColor="gray.500" />
      <Sunrise lat={lat} lon={lon} />
      <Divider h="20px" orientation="vertical" borderColor="gray.500" />
      <Sunset lat={lat} lon={lon} />
      <Divider h="20px" orientation="vertical" borderColor="gray.500" />
      <MoonPhase />
    </HStack>
  );
};

export default Weather;
