import { EquipmentState } from 'graphql/generated';
import { NumberView } from './NumberView';
import { SelectionView } from './SelectionView';
import { TextView } from './TextView';

export const StateViewRouter = ({ equipmentState }: { equipmentState: EquipmentState }) => {
  switch (equipmentState.__typename) {
    case 'EquipmentSelectionState':
      return <SelectionView equipmentState={equipmentState} />;
    case 'EquipmentNumberState':
      return <NumberView equipmentState={equipmentState} />;
    case 'EquipmentTextState':
      return <TextView equipmentState={equipmentState} />;
    default:
      return <></>;
  }
};
