/* eslint-disable react/jsx-key */
// Shamelessly borrowed from: https://chakra-ui.com/getting-started/with-react-table
import * as React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Input,
  Box,
  Flex,
  TableContainerProps,
  TableContainer,
  TableCaption
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { useTable, useSortBy, useGlobalFilter, Column } from 'react-table';
import { debounce } from 'lodash';

export type DataTableFilter<T extends object> = (
  rows: { original: T }[],
  filterValue: string
) => { original: T }[];

export type SortBy = {
  id: string;
  desc: boolean;
};

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: readonly Column<Data>[];
  onRowClick?: (any) => void;
  globalFilter?: DataTableFilter<Data>;
  initSortBy?: SortBy;
  tableLayout?: TableContainerProps;
  caption?: string;
};

export function DataTable<Data extends object>({
  data,
  columns,
  onRowClick,
  globalFilter,
  initSortBy,
  tableLayout,
  caption
}: DataTableProps<Data>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter } =
    useTable(
      //@ts-ignore
      {
        columns,
        data,
        initialState: initSortBy
          ? {
              sortBy: [initSortBy]
            }
          : {}
      },
      useGlobalFilter,
      useSortBy
    );

  const debouncedFilter = React.useRef(
    debounce((filterString) => {
      setGlobalFilter(filterString);
    }, 300)
  ).current;

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFilter(e.target.value);
  };

  return (
    <Flex w="100%" flexDir="column">
      {globalFilter && (
        <Box w="100%" m="10px">
          <Input onChange={onFilterChange} placeholder="Filter" />
        </Box>
      )}

      <TableContainer {...tableLayout}>
        <Table data-cypress={'report-table'} variant="striped" {...getTableProps()}>
          {caption && (
            <TableCaption background="white" bottom={0} position="sticky">
              {caption}
            </TableCaption>
          )}
          <Thead background="white" position="sticky" top={0}>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()} cursor="pointer">
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <chakra.span pl="4">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <Tr
                    data-cypress-report-id={row.original.id}
                    {...row.getRowProps()}
                    onClick={() => onRowClick && onRowClick(row)}
                    cursor="pointer">
                    {row.cells.map((cell) => (
                      <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    ))}
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td style={{ textAlign: 'center' }} colSpan={columns.length}>
                  No data
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
