import { Box } from '@chakra-ui/react';
import { ProjectContextType } from 'contexts/ProjectContext';
import { Site } from 'graphql/generated';
import { useState } from 'react';
import EventWrapper from '../EventWrapper';
import Chart from './Chart';
import { default as TreatmentsController } from './Controller';

const Combo = ({
  site,
  project,
  skip
}: {
  site?: Site;
  project: ProjectContextType;
  skip?: boolean;
}) => {
  const [selectedSubloc, setSelectedSubloc] = useState('All');

  return (
    <>
      <Box className="w-100">
        <EventWrapper site={site}>
          <Chart
            settings={{
              site: site,
              project: project,
              selectedSublocation: selectedSubloc
            }}
            skip={skip}
            control={({ availableSublocations }) => (
              <TreatmentsController
                chartInputs={{
                  availableSublocations
                }}
                chartSettings={{
                  project,
                  site,
                  selectedSublocation: selectedSubloc
                }}
                setChartSettings={(settings) => setSelectedSubloc(settings.selectedSublocation)}
              />
            )}
          />
        </EventWrapper>
      </Box>
    </>
  );
};

export default Combo;
