import { HStack, Text } from '@chakra-ui/react';
import { EquipmentTypeWithSiteEquipment } from 'components/Site/Tabs/Equipment/Data';
import { Site } from 'graphql/generated';
import Select from 'react-select';
import ChartSelect from '../ChartSelect';
import TimeFragmentPicker from '../Custom/TimeFragmentPicker';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';

export type ControllerInputs = {
  equipmentTypes?: EquipmentTypeWithSiteEquipment[];
  equipmentType?: EquipmentTypeWithSiteEquipment;
  showDateRange?: boolean;
  availableSites?: Site[];
};

export const equipmentTimeFrames = [
  { label: 'Last Day', value: 'last 86400 seconds' },
  { label: 'Last Week', value: 'last 604800 seconds' },
  { label: 'Last Month', value: 'last 2592000 seconds' }
];

const Controller = ({
  chartSettings,
  setChartSettings,
  chartInputs: { equipmentType, equipmentTypes, showDateRange, availableSites }
}: ChartController<ChartSettings, ControllerInputs>) => {
  const locationOptions: { label: string; value: string }[] = availableSites
    ? availableSites.map((s) => ({ label: s.name, value: s.smbId.toString() }))
    : [];

  const equipmentOptions = equipmentType
    ? [
        ...equipmentType.siteEquipment.map((se) => ({
          label: se.sublocation ? `${se.name} - ${se.sublocation}` : se.name,
          value: se.id
        }))
      ]
    : [];

  const stateOptions = equipmentType
    ? [...equipmentType.states.map((s) => ({ label: s.name, value: s.id }))]
    : [];

  const equipmentTypesOptions = equipmentTypes
    ? [...equipmentTypes.map((eq) => ({ label: eq.name, value: eq.id }))]
    : [];

  return (
    <HStack alignItems="center" justifyContent="space-evenly" mb="2px">
      <HStack>
        {locationOptions.length > 0 && (
          <ChartSelect
            label="Sublocation"
            options={locationOptions}
            value={locationOptions.find((o) => o.value === chartSettings.site.smbId.toString())}
            onChange={(option: { label: string; value: string }) => {
              setChartSettings({
                ...chartSettings,
                site: availableSites.find((as) => as.smbId === Number(option.value))
              });
            }}
          />
        )}
        {equipmentTypes && (
          <>
            <Text display="inline">Equipment Type</Text>
            <Select
              styles={{
                control: (styles) => {
                  return { ...styles, width: 200 };
                }
              }}
              value={
                chartSettings.equipmentType
                  ? equipmentTypesOptions.find((eq) => eq.value === chartSettings?.equipmentType.id)
                  : { label: 'None', value: 0 }
              }
              onChange={(x) =>
                setChartSettings({
                  ...chartSettings,
                  equipmentType: equipmentTypes.find((eq) => eq.id === x.value)
                })
              }
              options={equipmentTypesOptions}
            />
          </>
        )}

        <Text display="inline">Equipment</Text>
        <Select
          styles={{
            control: (styles) => {
              return { ...styles, width: 200 };
            }
          }}
          value={
            chartSettings?.selectedEquipment
              ? equipmentOptions.find((se) => se.value === chartSettings?.selectedEquipment)
              : { label: 'None', value: 0 }
          }
          onChange={(x) => setChartSettings({ ...chartSettings, selectedEquipment: x.value })}
          options={equipmentOptions}
        />
      </HStack>
      <HStack>
        <Text display="inline">State</Text>
        <Select
          styles={{
            control: (styles) => {
              return { ...styles, width: 200 };
            }
          }}
          value={
            chartSettings?.stateDimension
              ? stateOptions.find((se) => se.value === chartSettings?.stateDimension)
              : { label: 'None', value: 0 }
          }
          onChange={(x) => setChartSettings({ ...chartSettings, stateDimension: x.value })}
          options={stateOptions}
        />
      </HStack>
      {showDateRange && (
        <TimeFragmentPicker
          display="inline"
          showGranularity={false}
          timeFramesOverride={equipmentTimeFrames}
          timeFragment={{ dateRange: chartSettings.dateRange }}
          onChange={(tf) => setChartSettings({ ...chartSettings, dateRange: tf.dateRange })}
        />
      )}
    </HStack>
  );
};

export default Controller;
