import { Checkbox, CheckboxProps, FormControl, LayoutProps } from '@chakra-ui/react';
import { useController, UseControllerProps } from 'react-hook-form';

type FormCheckboxProps<TForm> = {
  label: string;
  w?: LayoutProps['w'];
} & UseControllerProps<TForm> &
  CheckboxProps;

const FormCheckbox = <TForm,>({ label, w, ...props }: FormCheckboxProps<TForm>) => {
  const { field, fieldState } = useController(props);

  const { value, ...fieldProps } = field;

  return (
    <FormControl w={w} isInvalid={!!fieldState.error} isRequired={props.rules?.required as boolean}>
      <Checkbox {...props} {...fieldProps} isChecked={value as boolean}>
        {label}
      </Checkbox>
    </FormControl>
  );
};

export default FormCheckbox;
