import { CheckCircleIcon, DeleteIcon, EditIcon, EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, IconButton, Input, Text, VStack } from '@chakra-ui/react';
import FormEmailInput from 'components/Forms/FormEmailInput';
import FormPhoneNumberInput from 'components/Forms/FormPhoneNumberInput';
import Tile from 'components/Tile';
import { isEmpty } from 'lodash';
import { ContactList } from 'pages/project/[Id]/Alarm/NotificationList';
import { useFieldArray, useForm } from 'react-hook-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

type NotificationListProps = {
  defaultList: ContactList;
  editMode: boolean;
  showEditButton: boolean;
  onEditMode: () => void;
  onDelete: () => void;
  onSave: (list) => void;
};

const NotificationListTile = ({
  defaultList,
  editMode,
  showEditButton,
  onEditMode,
  onDelete,
  onSave
}: NotificationListProps) => {
  const {
    watch,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    values: {
      id: defaultList?.id,
      name: defaultList.name,
      contacts: defaultList.contacts.map((c) => ({
        value: c,
        type: isPossiblePhoneNumber(c) ? 'phone' : 'email'
      }))
    }
  });

  const {
    fields: contacts,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'contacts'
  });

  const [name] = watch(['name']);

  return (
    <Tile data-cypress="notification-list-tile" w="400px" marginInlineStart="0px">
      <Box background="gray.100" p="10px">
        <HStack minH="50px" alignItems="center" justifyContent="space-between">
          {editMode ? (
            <Input
              data-cypress="list-name-input"
              isInvalid={!!errors.name}
              placeholder="List name..."
              {...control.register('name', { validate: (name) => !isEmpty(name) })}
            />
          ) : (
            <Text fontSize="lg">{name}</Text>
          )}
          {showEditButton && (
            <IconButton
              data-cypress="edit-contact-list"
              icon={<EditIcon />}
              aria-label={'Edit Contact List'}
              onClick={onEditMode}
            />
          )}
          {editMode && (
            <IconButton
              data-cypress="save-contact-list"
              icon={<CheckCircleIcon />}
              color="green.500"
              aria-label={'Save Contact List'}
              onClick={handleSubmit(onSave)}
            />
          )}
        </HStack>
      </Box>
      <VStack padding="5px" alignItems="start" spacing={2}>
        {contacts.map((contact, i) => (
          <HStack w="100%" key={contact.id} justifyContent="space-between">
            {editMode ? (
              contact.type === 'phone' ? (
                <FormPhoneNumberInput
                  data-cypress="new-phone-input"
                  control={control}
                  name={`contacts.${i}.value`}
                />
              ) : (
                <FormEmailInput
                  data-cypress="new-email-input"
                  control={control}
                  name={`contacts.${i}.value`}
                />
              )
            ) : (
              <Box ml="10px">
                {contact.type === 'phone' ? (
                  <PhoneIcon color="blue.500" />
                ) : (
                  <EmailIcon color="blue.500" />
                )}
                <Text ml="10px" display="inline-block">
                  {contact.value}
                </Text>
              </Box>
            )}
            {editMode && (
              <IconButton
                isDisabled={contacts.length === 0}
                icon={<DeleteIcon color="red.500" />}
                aria-label={'Remove Contact'}
                onClick={() => {
                  remove(i);
                }}
              />
            )}
          </HStack>
        ))}
      </VStack>
      {editMode && (
        <HStack m="15px" justifyContent="space-between">
          <Button
            data-cypress="new-phone-btn"
            colorScheme="blue"
            variant="outline"
            onClick={() => {
              append({ value: '', type: 'phone' });
            }}
            leftIcon={<PhoneIcon />}
            aria-label="Add Phone">
            Add Phone
          </Button>
          <Button
            data-cypress="new-email-btn"
            colorScheme="blue"
            variant="outline"
            onClick={() => {
              append({ value: '', type: 'email' });
            }}
            leftIcon={<EmailIcon />}
            aria-label="Add Email">
            Add Email
          </Button>
          <IconButton
            data-cypress="delete-list-btn"
            icon={<DeleteIcon />}
            colorScheme="red"
            onClick={() => onDelete()}
            aria-label={'Delete List'}
          />
        </HStack>
      )}
    </Tile>
  );
};

export default NotificationListTile;
