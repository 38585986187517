import { Box, Center } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export type ShadeProps = {
  shaded: boolean;
  shadeContent: ReactElement;
};

const Shade: React.FC<ShadeProps> = ({ children, shaded, shadeContent }) => {
  return (
    <Box data-cypress="shade" position="relative">
      {children}
      {shaded && (
        <Box
          style={{
            backgroundColor: 'rgba(0,0,0,0.8)',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            position: 'absolute',
            zIndex: 10,
            borderRadius: 5
          }}>
          <Center height="100%">{shadeContent}</Center>
        </Box>
      )}
    </Box>
  );
};

export default Shade;
