import { Box, Heading, HStack, useToast, UseToastOptions } from '@chakra-ui/react';
import { ReactChild } from 'react';
import { Control, FieldValues, useForm, UseFormProps, UseFormWatch } from 'react-hook-form';
import FormModal from './FormModal';

type EntryFormModalProps<TForm extends FieldValues> = {
  siteName: string;
  entryName: string;
  defaultValues: UseFormProps<TForm>['defaultValues'];
  isDeleting: boolean;
  isDeleteDisabled?: boolean;
  deleteTooltip?: string;
  isSubmitting: boolean;
  onSubmit: (form: TForm) => Promise<unknown>;
  onDelete: () => Promise<void>;
  onClose: () => void;
  basicFields: (control: Control<TForm, any>, watch?: UseFormWatch<TForm>) => ReactChild;
  dataEntry: (control: Control<TForm, any>, watch?: UseFormWatch<TForm>) => ReactChild;
};

const GENERIC_ERROR_TOAST: UseToastOptions = {
  title: 'Error',
  description: (
    <>
      {`We're sorry, there was an unexpected error submitting your request.`}
      <br />
      Please try again later.
      <br />
      If the problem persists, please submit a support ticket.
    </>
  ),
  status: 'error',
  duration: 10000,
  isClosable: true
};

const EntryFormModal = <TForm,>({
  siteName,
  entryName,
  defaultValues,
  isDeleting,
  isSubmitting,
  isDeleteDisabled,
  deleteTooltip,
  onSubmit,
  onDelete,
  onClose,
  basicFields,
  dataEntry
}: EntryFormModalProps<TForm>) => {
  const toast = useToast();

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { isSubmitting: isFormSubmitting, isValid }
  } = useForm<TForm>({ defaultValues });

  const submit = async () => {
    try {
      await onSubmit(getValues());
      onClose();
    } catch (error) {
      toast(GENERIC_ERROR_TOAST);
      console.error(error);
    }
  };

  const handleDelete = async () => {
    try {
      await onDelete();
    } catch (error) {
      toast(GENERIC_ERROR_TOAST);
      console.error(error);
    }
  };

  return (
    <FormModal
      modalHeader={`${siteName} - ${entryName}`}
      isDeleting={isDeleting && (!isSubmitting || !isFormSubmitting)}
      isSubmitDisabled={!isValid}
      isSubmitting={isFormSubmitting || isSubmitting}
      size="full"
      onSubmit={handleSubmit(submit)}
      isDeleteDisabled={isDeleteDisabled}
      deleteTooltip={deleteTooltip}
      //@ts-ignore
      onDelete={defaultValues?.id ? handleDelete : undefined}
      onClose={onClose}>
      <HStack alignItems="start" p="20px" spacing={5}>
        <Box w="370px">
          <Heading size="md" mb="10px">
            Info
          </Heading>
          {basicFields(control, watch)}
        </Box>
        <Box w="calc(100% - 370px)">
          <Heading size="md" mb="10px">
            Data
          </Heading>
          {dataEntry(control, watch)}
        </Box>
      </HStack>
    </FormModal>
  );
};

export default EntryFormModal;
