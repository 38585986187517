import React, { useContext } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './custom_slider.css';
import { Handle } from 'rc-slider';
import { ProjectContext } from 'contexts/ProjectContext';

type DepthSliderProps = {
  value: number;
  config: {
    depths: { [key: string]: { [key: number]: string } };
  };
  onChange: (val: number) => void;
};

export const DepthSlider = (props: DepthSliderProps) => {
  const projectContext = useContext(ProjectContext);
  const depths = props.config.depths[projectContext.mapParamSuffix];

  const depthMarks = {};
  Object.keys(depths)
    .map(Number)
    .forEach((depth) => {
      if (depths[depth] === 'Surface') {
        depthMarks[depth] = {
          style: {
            marginLeft: '-2em',
            marginTop: '-1.25em',
            color: 'black'
          },
          label: depths[depth]
        };
      } else if (depths[depth] === 'Bottom') {
        depthMarks[depth] = {
          style: {
            marginLeft: '-2em',
            marginTop: '1.25em',
            color: 'black'
          },
          label: depths[depth]
        };
      } else {
        depthMarks[depth] = {};
      }
    });

  return (
    <div className="fl f6 h-100 text-center black">
      <div className="items-center justify-center" style={{ height: '80%' }}>
        <Slider
          className="black"
          tipFormatter={(depth) => depths[depth]}
          tipProps={{
            placement: 'left'
          }}
          value={props.value}
          marks={depthMarks}
          min={Object.keys(depths).map(Number)[0]}
          max={Object.keys(depths).map(Number)[Object.keys(depths).length - 1]}
          step={null}
          reverse={true}
          vertical={true}
          dots={false}
          onChange={props.onChange}
          included={false}
          handleStyle={{
            marginLeft: -5,
            marginTop: -10
          }}
          dotStyle={{
            marginLeft: -4,
            borderColor: 'black',
            backgroundColor: 'black'
          }}
          trackStyle={{
            display: 'none'
          }}
          handle={(handleProps) => GetHandle(handleProps, depths)}
        />
      </div>
    </div>
  );
};

interface GetHandleProps {
  value: number;
  dragging: boolean;
  index: number;
}

function GetHandle(props: GetHandleProps, depths: { [key: number]: string }) {
  const { value, dragging, index, ...rest } = props;

  return (
    <Handle className="flex justify-center" key={index} value={value} {...rest}>
      {dragging && (
        <div
          className="f6 tc black bg-white nowrap-ns w3 br2 ba b--light-gray shadow-1"
          style={{ marginLeft: '1em', marginTop: '-0.25em' }}>
          {depths[value]}
        </div>
      )}
    </Handle>
  );
}
