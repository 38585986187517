import { Accordion, Box, Center, Input, Text, Tooltip } from '@chakra-ui/react';
import { SiteOption, User, permissions } from '@scoot/permissions';
import ThreeStateCheckbox, { ThreeStateCheck } from 'components/Forms/ThreeStateCheckbox';
import { Site } from 'graphql/generated';
import { debounce, omit } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import PermissionsGrid from './PermissionsGrid';
import SitePermissions from './SitePermissions';

type AllState = { [option: string]: ThreeStateCheck };

type SitePermissionsListingProps = {
  user: User;
  sites: Site[];
  onChange: (option: SiteOption, siteId: number) => void;
  onAllChange: (option: SiteOption, state: ThreeStateCheck) => void;
};

const EXCLUDED_OPTIONS = ['read', 'readDevices'];

const canMapping = {
  asc: permissions.canSubmitASCForSite,
  manualHydrography: permissions.canSubmitManualHydrographyForSite,
  read: permissions.canViewSite,
  events: permissions.canManageEventsForSite,
  equipment: permissions.canSubmitEquipmentForSite,
  plankton: permissions.canSubmitPlanktonForSite,
  seaLice: permissions.canSubmitSeaLiceForSite
};

const tooltips = {
  asc: 'Can enter ASC data.',
  manualHydrography: 'Can enter manual hydrography data.',
  read: 'Can view the site.',
  events: 'Can create events.',
  equipment: 'Can enter equipment status.',
  plankton: 'Can enter plankton data.',
  seaLice: 'Can enter sea lice data.'
};

const getFilteredSiteOptions = (sites: Site[]) => {
  let excludes = permissions.siteOptions.filter((so) => !EXCLUDED_OPTIONS.includes(so));
  if (sites.every((s) => !s.tabs.includes('Plankton Entry'))) {
    excludes = excludes.filter((so) => so !== 'plankton');
  }

  if (sites.every((s) => !s.tabs.includes('Sea Lice Entry'))) {
    excludes = excludes.filter((so) => so !== 'seaLice');
  }

  if (sites.every((s) => !s.tabs.includes('Equipment Status'))) {
    excludes = excludes.filter((so) => so !== 'equipment');
  }

  if (sites.every((s) => !s.tabs.includes('Hydrography Entry'))) {
    excludes = excludes.filter((so) => so !== 'manualHydrography');
  }

  if (!sites.some((s) => s.siteLabel === 'ASC')) {
    excludes = excludes.filter((so) => so !== 'asc');
  }

  return excludes;
};

const SitePermissionsListing = ({
  user,
  sites,
  onChange: onSiteChange,
  onAllChange
}: SitePermissionsListingProps) => {
  const filteredSiteOptions = useMemo(() => (sites ? getFilteredSiteOptions(sites) : []), [sites]);
  const [siteFilter, setSiteFilter] = useState('');
  const [filteredSites, setFilteredSites] = useState([]);
  const [allState, setAllState] = useState<AllState>({});

  const siteOption = (site: { id: number; projectId: number }) => {
    return filteredSiteOptions.reduce((acc, curr) => {
      acc[curr] = canMapping[curr](user, site) ? 'all' : 'none';
      return acc;
    }, {});
  };

  const debouncedFilter = React.useRef(
    debounce((filterString) => {
      setSiteFilter(filterString);
    }, 300)
  ).current;

  useEffect(() => {
    if (siteFilter) {
      setFilteredSites(
        sites.filter((s) => s.name.toLowerCase().includes(siteFilter.toLowerCase()))
      );
    } else {
      setFilteredSites(sites);
    }
  }, [sites, siteFilter]);

  useEffect(() => {
    if (sites) {
      const blSiteIds = sites.map((site: Site) => site.id);
      const allOptions = {};
      [...filteredSiteOptions, 'read'].forEach((op) => {
        if (Object.keys(user).includes(op)) {
          const allSites = blSiteIds.every((siteId) => user[op].includes(siteId));
          const someSites = blSiteIds.some((siteId) => user[op].includes(siteId));

          allOptions[op] = allSites ? 'all' : someSites ? 'some' : 'none';
        }
      });

      setAllState(allOptions);
    }
  }, [sites, filteredSiteOptions, user]);

  const onAllChanged = (option: SiteOption) => {
    const existingState = allState[option];
    let nextOption: ThreeStateCheck = 'all';

    switch (existingState) {
      case 'all':
        nextOption = 'none';
        break;
      case 'some':
        nextOption = 'none';
        break;
      default:
        nextOption = 'all';
        break;
    }

    setAllState({
      ...allState,
      [option]: nextOption
    });

    onAllChange(option, nextOption as ThreeStateCheck);
  };

  return (
    <>
      <Text textAlign="center" fontSize="2xl">
        Site Permissions
      </Text>

      <Box borderWidth="1px" borderRadius="6px" p="5px" mb="10px" borderColor="gray.200">
        <Center>
          <Text>All Sites Data Entry</Text>
        </Center>
        <PermissionsGrid
          user={user}
          options={omit(allState, 'read')}
          onChange={onAllChanged}
          tooltips={tooltips}
        />
      </Box>

      <Input
        mb="5px"
        placeholder="Site Name..."
        onChange={(e) => debouncedFilter(e.currentTarget.value)}
      />
      <Tooltip label="Can view the site.">
        <Box width="fit-content">
          <ThreeStateCheckbox
            state={allState?.read ?? 'none'}
            onChange={() => onAllChanged('read')}
            m="10px"
            ml="15px"
            size="lg"
            colorScheme="green"
            borderColor="red.300">
            View All
          </ThreeStateCheckbox>
        </Box>
      </Tooltip>
      <Accordion maxH="330px" overflowY="scroll">
        {filteredSites?.map((site) => {
          return (
            <SitePermissions
              key={site.id}
              site={site}
              user={user}
              options={siteOption(site)}
              onChange={onSiteChange}
            />
          );
        })}
      </Accordion>
    </>
  );
};

export default SitePermissionsListing;
