//@ts-nocheck
import { gql, useQuery } from '@apollo/client';
import { DeleteIcon, EmailIcon, InfoIcon, PhoneIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  HStack,
  IconButton,
  Skeleton,
  Text,
  VStack,
  useCheckboxGroup
} from '@chakra-ui/react';
import CheckboxCard from 'components/Forms/CheckboxCard';
import FormEmailInput from 'components/Forms/FormEmailInput';
import FormPhoneNumberInput from 'components/Forms/FormPhoneNumberInput';
import { ProjectContext } from 'contexts/ProjectContext';
import { ContactList, ContactType } from 'graphql/generated';
import { uniq } from 'lodash';
import { useContext, useEffect } from 'react';
import { FieldArrayPath, UseControllerProps, useFieldArray } from 'react-hook-form';
import { EditAlarmForm } from './EditAlarmModal';

const GET_CONTACT_LISTS = gql`
  query ContactLists($projectId: Int!) {
    project(id: $projectId) {
      id
      contactLists {
        id
        name
        contacts
      }
    }
  }
`;

export type ToNotifyType = {
  value: string;
  type: ContactType;
  notificationListId?: number;
};

type AlarmNotificationListProps = UseControllerProps<EditAlarmForm>;

const mergeContactLists = (fields: any[], contactLists: ContactList[]) => {
  const nonListFields = fields.filter((f) => f.value && !f.notificationListId);

  const fieldsWithList = fields.filter((f) => f.notificationListId);

  const listedFields = [];
  fieldsWithList.forEach((f) => {
    const contactList = contactLists.find((cl) => cl.id === f.notificationListId);

    contactList.contacts.forEach((c) => {
      // Prevent duplicates
      if (listedFields.find((f) => f.value === c)) return;
      listedFields.push({
        value: c,
        type: c.includes('@') ? ContactType.Email : ContactType.Phone,
        notificationListId: contactList.id
      });
    });
  });

  return [...nonListFields, ...listedFields];
};

const AlarmNotificationList = ({ control, name }: AlarmNotificationListProps) => {
  const { fields, append, remove, replace } = useFieldArray<
    EditAlarmForm,
    FieldArrayPath<EditAlarmForm>
  >({
    control,
    name
  });
  const project = useContext(ProjectContext);
  const { data, loading } = useQuery(GET_CONTACT_LISTS, { variables: { projectId: project.id } });

  //once data is loaded in, add the loaded contacts to the field array
  useEffect(() => {
    if (data?.project?.contactLists && !loading) {
      const mergedFields = mergeContactLists(fields, data.project.contactLists);

      replace(mergedFields);
    }
  }, [data, loading]);

  const { getCheckboxProps } = useCheckboxGroup({
    value: uniq(fields.map((f) => f.notificationListId).filter((nl) => nl)),
    onChange: (contactListIds) => {
      const updatedFields = fields.filter(
        (f) => !f.notificationListId || contactListIds.includes(f.notificationListId)
      );

      const updatedFieldIds = updatedFields.map((f) => f.notificationListId);

      contactListIds.forEach((clid) => {
        if (!updatedFieldIds.includes(clid))
          updatedFields.push({ notificationListId: Number(clid) });
      });

      const mergedFields = mergeContactLists(updatedFields, data.project.contactLists);
      replace(mergedFields);
    }
  });

  return (
    <HStack w="100%" alignItems="start">
      <Box w="30%">
        <Center>
          <Text>Notification Lists</Text>
        </Center>
        <VStack w="100%" maxH="400px" overflowY="scroll" p="10px">
          {loading && <Skeleton h="200px" />}
          {data?.project.contactLists.length === 0 && (
            <Center
              p="20px"
              w="100%"
              h="100%"
              border="1px"
              borderColor="yellow.500"
              borderRadius="3px">
              <VStack>
                <InfoIcon boxSize={6} color="yellow.500" />
                <Text>No notification lists created yet</Text>
              </VStack>
            </Center>
          )}
          {data?.project.contactLists.map((cl) => {
            const checkbox = getCheckboxProps({ value: cl.id });
            return (
              <Box key={cl.id} w="100%">
                <CheckboxCard key={cl.id} {...checkbox}>
                  {cl.name}
                </CheckboxCard>
              </Box>
            );
          })}
        </VStack>
      </Box>
      <VStack w="70%">
        {fields.map((notify, i) => {
          return (
            <HStack w="100%" key={notify.id}>
              {notify.type === ContactType.Email && (
                <FormEmailInput
                  rules={{ minLength: 1 }}
                  data-cypress={`email-input-${i}`}
                  control={control}
                  name={`${name}.${i}.value`}
                  isDisabled={!!notify.notificationListId}
                />
              )}
              {notify.type === ContactType.Phone && (
                <FormPhoneNumberInput
                  rules={{ minLength: 1 }}
                  control={control}
                  name={`${name}.${i}.value`}
                  isDisabled={!!notify.notificationListId}
                />
              )}

              <IconButton
                isDisabled={!!notify.notificationListId}
                icon={<DeleteIcon color="red.500" />}
                aria-label={'Remove Contact'}
                onClick={() => {
                  remove(i);
                }}
              />
            </HStack>
          );
        })}
        <ButtonGroup>
          <Button
            colorScheme="blue"
            variant="outline"
            onClick={() => {
              append({ value: '', type: ContactType.Phone });
            }}
            leftIcon={<PhoneIcon />}
            aria-label="Add Phone">
            Add Phone
          </Button>
          <Button
            data-cypress="add-email-notification"
            colorScheme="blue"
            variant="outline"
            onClick={() => {
              append({ value: '', type: ContactType.Email });
            }}
            leftIcon={<EmailIcon />}
            aria-label="Add Email">
            Add Email
          </Button>
        </ButtonGroup>
      </VStack>
    </HStack>
  );
};

export default AlarmNotificationList;
