import { Center, HStack, Text, useRadioGroup } from '@chakra-ui/react';
import RadioCard from 'components/Forms/RadioCard';
import InfoPopover from 'components/InfoPopover';
import { UnknownAlarmIntervalSetting } from 'graphql/generated';
import { UseControllerProps, useController } from 'react-hook-form';

const AlarmUnknownDataSelect = <TForm,>(props: UseControllerProps<TForm>) => {
  const { field } = useController(props);

  const { getRadioProps } = useRadioGroup({
    //@ts-ignore
    value: field.value,
    onChange: field.onChange
  });

  const conRadio = getRadioProps({ value: UnknownAlarmIntervalSetting.Continue });
  const okRadio = getRadioProps({ value: UnknownAlarmIntervalSetting.Ok });
  const unknownRadio = getRadioProps({ value: UnknownAlarmIntervalSetting.Unknown });

  return (
    <>
      <Center m="20px">
        <Text fontSize="2xl">Alarm Reset Behavior</Text>
      </Center>
      <Text mb="10px">
        Alarm status is checked every 30 minutes. Choose the desired behavior of your alarm if data
        is present in one interval, and no new data is present in the next interval:
        <InfoPopover ml="5px">
          Ex. If at 12:00pm the alarm thresholds are crossed the alarm will be set to the
          &apos;Alarm&apos; state. At 12:30pm, if no new data has come in between 12:00pm and
          12:30pm, then the alarm will be set to the following configured choice. For example, if an
          alarm threshold is crossed at 12:00pm, the alarm will be set to the &apos;Alert&apos;
          state. If no new data is entered between 12:00pm and 12:30pm, at 12:30pm the alarm status
          will either change or stay in &apos;Alert&apos;, depending on your selection below. If it
          stays in &apos;Alert&apos; you may get multiple email or text notifications for the same
          data point.
        </InfoPopover>
        :
      </Text>
      <HStack justifyContent="space-evenly">
        <RadioCard {...conRadio}>Continue with the last known status</RadioCard>
        <RadioCard {...okRadio}>Set status to &apos;Ok&apos;</RadioCard>
        <RadioCard {...unknownRadio}>Set status to &apos;Unknown&apos;</RadioCard>
      </HStack>
    </>
  );
};

export default AlarmUnknownDataSelect;
