import { TimeDimensionGranularity } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { permissions } from '@scoot/permissions';
import { ProjectContextType } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { groupBy } from 'lodash';
import { Layout, LayoutAxis, PlotData, PlotlyDataLayoutConfig } from 'plotly.js';
import { useCallback, useContext, useEffect, useState } from 'react';
import { forecastParamOptions } from 'shared/Config';
import { getResultSetsDateRange } from '../getResultSetsDateRange';
import { BaseChartProps } from '../types';
import { ChartSettings } from './Chart';

export type ParameterOptions = (
  | { label: 'Water Temperature'; value: 'waterTempAvg' }
  | { label: 'Oxygen Saturation'; value: 'oxygenSaturationAvg' }
  | { label: 'Salinity'; value: 'salinityAvg' }
  | { label: 'Oxygen Concentration'; value: 'oxygenConcentrationAvg' }
  | { label: 'Pycnocline Depth'; value: 'pycnoDepthAvg' }
  | { label: 'TKE (mixing force)'; value: 'tkeAvg' }
  | { label: 'Buoyancy Frequency (stability)'; value: 'buoyancyFreqAvg' }
)[];

const AggregateStringMap = {
  0: 'Min',
  25: '25',
  50: 'Avg',
  75: '75',
  100: 'Max'
};

type ForecastHydrographyCubeDatum = {
  'TessNewForecastHydrography.oxygenSaturationAvg': number;
  'TessNewForecastHydrography.salinityAvg': number;
  'TessNewForecastHydrography.waterTempAvg': number;
  'TessNewForecastHydrography.oxygenConcentrationAvg': number;
  'TessNewForecastHydrography.pycnoDepthAvg'?: number;
  'TessNewForecastHydrography.tkeAvg'?: number;
  'TessNewForecastHydrography.buoyancyFreqAvg'?: number;

  'TessNewForecastHydrography.oxygenSaturationMin': number;
  'TessNewForecastHydrography.salinityMin': number;
  'TessNewForecastHydrography.waterTempMin': number;
  'TessNewForecastHydrography.oxygenConcentrationMin': number;
  'TessNewForecastHydrography.pycnoDepthMin'?: number;
  'TessNewForecastHydrography.tkeMin'?: number;
  'TessNewForecastHydrography.buoyancyFreqMin'?: number;
  'TessNewForecastHydrography.oxygenSaturationMax': number;
  'TessNewForecastHydrography.salinityMax': number;
  'TessNewForecastHydrography.waterTempMax': number;
  'TessNewForecastHydrography.oxygenConcentrationMax': number;
  'TessNewForecastHydrography.pycnoDepthMax'?: number;
  'TessNewForecastHydrography.tkeMax'?: number;
  'TessNewForecastHydrography.buoyancyFreqMax'?: number;
  'TessNewForecastHydrography.oxygenSaturation25': number;
  'TessNewForecastHydrography.salinity25': number;
  'TessNewForecastHydrography.waterTemp25': number;
  'TessNewForecastHydrography.oxygenConcentration25': number;
  'TessNewForecastHydrography.pycnoDepth25'?: number;
  'TessNewForecastHydrography.tke25'?: number;
  'TessNewForecastHydrography.buoyancyFreq25'?: number;

  'TessNewForecastHydrography.oxygenSaturation75': number;
  'TessNewForecastHydrography.salinity75': number;
  'TessNewForecastHydrography.waterTemp75': number;
  'TessNewForecastHydrography.oxygenConcentration75': number;
  'TessNewForecastHydrography.pycnoDepth75'?: number;
  'TessNewForecastHydrography.tke75'?: number;
  'TessNewForecastHydrography.buoyancyFreq75'?: number;

  'TessNewForecastHydrographyLookup.depth': number;
  'TessNewForecastHydrographyLookup.ensemble': number;
  'TessNewForecastHydrography.measuredAt': string;
  'TessNewForecastHydrography.measuredAt.hour': string;
};

type HydrographyStructure = {
  contours: {
    [measure: string]: {
      x: string[];
      y: number[];
      z: number[];
      xRange: [string, string];
      zRange: [number, number];
    };
  };
  pycnocline: {
    x: string[];
    y: number[];
  };
};

const nonZero = (v: number) => Number(v) !== 0;

const transform = (
  data: ForecastHydrographyCubeDatum[],
  cube: 'TessNewForecastHydrography',
  granularity: TimeDimensionGranularity,
  parameters: ParameterOptions,
  aggregation: number
): HydrographyStructure => {
  const contourData = data.filter((d) => {
    return d[`${cube}Lookup.depth`] <= 0;
  });
  const x = contourData.map<string>((d) => d[`${cube}.measuredAt.${granularity}`]);
  const y = contourData.map((d) => Math.abs(d[`${cube}Lookup.depth`]));
  const xRange: [string, string] = [x[0], x[x.length - 1]];
  const pycnoAvg = Object.entries(groupBy(data, `${cube}.measuredAt`)).reduce(
    (timeAcc, [time, dataAtTime]) => {
      timeAcc[time] = Number(
        Math.max(
          ...dataAtTime.map((d) => d[`${cube}.pycnoDepth${AggregateStringMap[aggregation]}`])
        )
      );
      return timeAcc;
    },
    {}
  );
  // assemble data for line plots
  const transformedData = {
    contours: {},
    pycnocline: {
      x: Object.keys(pycnoAvg),
      y: Object.values(pycnoAvg).map((d) => Number(d))
    }
  };

  // now assemble the parameters for contour plots
  parameters
    .filter((param) => param.value !== 'pycnoDepthAvg') // filter out params for line plots
    .forEach((param) => {
      const paramMapping = `${param.value.replace('Avg', AggregateStringMap[aggregation])}`;
      let subset = contourData.map((d) => d[`${cube}.${paramMapping}`]);
      switch (param.value) {
        case 'oxygenSaturationAvg':
          subset = subset.filter(nonZero).length > 0 ? subset : Array(x.length).fill(2);
          break;
        case 'salinityAvg':
          subset = subset.filter(nonZero).length > 0 ? subset : Array(x.length).fill(4);
          break;
        case 'buoyancyFreqAvg':
          subset = subset.filter(nonZero).length > 0 ? subset : Array(x.length).fill(4);
          break;
        case 'tkeAvg':
          subset = subset.filter(nonZero).length > 0 ? subset : Array(x.length).fill(4);
          break;
        default:
          break;
      }
      transformedData.contours[param.value] = {
        x,
        y,
        z: subset,
        xRange,
        zRange: [Math.min(...subset.filter(nonZero)), Math.max(...subset)]
      };
    });
  return transformedData;
};
const createContourPlot = (
  data: HydrographyStructure,
  projectContext: ProjectContextType,
  options: {
    plotIdxOffset: number;
    xaxis: string;
    colorbarX?: number;
    zRanges: Record<string, Array<number>>;
    aggregation: string;
  }
) => {
  const layouts: Partial<LayoutAxis>[] = [];
  const numOfPlots =
    Object.keys(data.contours).length + (data.pycnocline ? 1 : 0) + options.plotIdxOffset;
  const plotData: Partial<PlotData>[] = Object.entries(data.contours).map(
    ([measurement, measurementData], idx) => {
      const cubeMeasurement = `${measurement?.replace('Avg', options.aggregation)}`;
      const measurementConfig = forecastParamOptions[cubeMeasurement];
      const plot: Partial<PlotData> = {
        name: measurement,
        x: measurementData.x,
        y: measurementData.y,
        z: measurementData.z,
        type: 'contour' as const,
        line: {
          smoothing: 0.85
        },
        hovertemplate:
          `<b>${measurementConfig.name} %{z:.2f} ${measurementConfig.units} </b></br>` +
          `<b>Time: %{x} ${projectContext.timezone} </b><br>` +
          '<b>Depth: %{y} m</b><br>' +
          '<extra></extra>',
        zmin: options.zRanges[cubeMeasurement][0],
        zmax: options.zRanges[cubeMeasurement][1],

        //@ts-ignore
        contours: {
          coloring: 'fill',
          showlines: false
        },
        showscale: true,
        yaxis: `y${idx + 1}`,
        xaxis: options.xaxis,
        // legendgroup: `y${idx + 1}`,
        colorscale: measurementConfig.color,
        showlegend: true,
        colorbar: {
          lenmode: 'fraction' as const,
          x: 1,
          y: 1 - 1 / numOfPlots / 2 - idx * (1 / numOfPlots),
          len: ((1 / numOfPlots) * 3) / 4,
          ypad: 0,
          yanchor: 'middle' as const,
          title: measurementConfig.label,
          titleside: 'right' as const
        }
      };

      const maxDepth = Math.min(...measurementData.y);

      layouts.push({
        title: { text: 'Depth (m)', font: { size: 12 } },
        range: [1, maxDepth],
        autorange: 'reversed'
      });

      return plot;
    }
  );

  return {
    plotData,
    layouts
  };
};
const getZRanges = (forecastData: HydrographyStructure) => {
  const ranges = Object.entries(forecastData.contours).reduce(
    (prev, [measurement, measurementData]) => {
      prev[measurement] = [
        Math.min(measurementData.zRange[0], forecastData.contours[measurement].zRange[0]),
        Math.max(measurementData.zRange[1], forecastData.contours[measurement].zRange[1])
      ];

      return prev;
    },
    {
      salinityAvg: [],
      oxygenConcentrationAvg: [],
      waterTempAvg: [],
      oxygenSaturation25: [],
      oxygenSaturation75: [],
      oxygenSaturationMax: [],
      oxygenSaturationMin: [],
      oxygenSaturationAvg: [],
      pycnoDepthAvg: [],
      tkeAvg: [],
      buoyancyFreqAvg: []
    }
  );

  return ranges;
};
const createVisibilityPlot = (
  visibilityData: { x: string[]; y: number[] },
  yaxis: number,
  range: number[]
): { plot: Partial<PlotData>; layout: Partial<LayoutAxis> } => {
  const plot: Partial<PlotData> = {
    mode: 'lines+markers',
    yaxis: 'y' + yaxis,
    x: visibilityData.x,
    y: visibilityData.y,
    hovertemplate:
      `<b>Pycnocline %{y:.2f} m</b></br>` + '<b>Time: %{x}</b><br>' + '<extra></extra>',
    name: 'meters (m)',
    showlegend: true,
    connectgaps: true,
    line: {
      color: 'rgb(255, 0, 0)'
    }
  };

  const yMin = Math.min(...(range ?? []));
  const yMax = Math.max(...(range ?? []));

  const layout: Partial<LayoutAxis> = {
    title: { text: 'Depth (m)', font: { size: 12 } },
    range: [yMax, yMin]
  };

  return { plot, layout };
};
const useHydrographyPlot = ({
  granularity = 'hour',
  // dateRange = 'from 1 day ago to 11 days from now',
  chartRange,
  skip,
  refreshInterval,
  settings,
  onDataLoaded
}: BaseChartProps<ChartSettings>): {
  isLoading: boolean;
  error: Error;
  hasData: boolean;
  plot: PlotlyDataLayoutConfig;
} => {
  const currentUser = useContext(UserContext);
  settings.parameters = settings.parameters
    ? settings.parameters
    : permissions.isSuperuser(currentUser)
      ? [
          { label: 'Water Temperature', value: 'waterTempAvg' },
          { label: 'Oxygen Saturation', value: 'oxygenSaturationAvg' },
          { label: 'Salinity', value: 'salinityAvg' },
          { label: 'Oxygen Concentration', value: 'oxygenConcentrationAvg' },
          { label: 'Pycnocline Depth', value: 'pycnoDepthAvg' },
          { label: 'TKE (mixing force)', value: 'tkeAvg' },
          { label: 'Buoyancy Frequency (stability)', value: 'buoyancyFreqAvg' }
        ]
      : [
          { label: 'Water Temperature', value: 'waterTempAvg' },
          { label: 'Oxygen Saturation', value: 'oxygenSaturationAvg' },
          { label: 'Salinity', value: 'salinityAvg' },
          { label: 'Oxygen Concentration', value: 'oxygenConcentrationAvg' }
        ];
  const parameterValues = settings.parameters.map((d) => d.value);
  const useForecasting = settings.project.forecasting;
  const aggregateString = AggregateStringMap[settings.aggregation];
  const graph = useCallback(
    (
      forecastData: HydrographyStructure,
      projectContext: ProjectContextType,
      options?: {
        useLocalMinMax: boolean;
        useForecasting: boolean;
      }
    ) => {
      let layouts: Partial<LayoutAxis>[] = [];
      const plotIdxOffset = 0;

      let zRanges: Record<string, Array<number>> = {};
      if (options?.useLocalMinMax) {
        zRanges = getZRanges(forecastData);
      } else {
        zRanges = {
          salinityAvg: forecastParamOptions.salinityAvg.range,
          oxygenConcentrationAvg: forecastParamOptions.oxygenConcentrationAvg.range,
          waterTempAvg: forecastParamOptions.waterTempAvg.range,
          oxygenSaturationAvg: forecastParamOptions.oxygenSaturationAvg.range,
          oxygenSaturationMin: forecastParamOptions.oxygenSaturationAvg.range,
          oxygenSaturationMax: forecastParamOptions.oxygenSaturationAvg.range,
          oxygenSaturation25: forecastParamOptions.oxygenSaturationAvg.range,
          oxygenSaturation75: forecastParamOptions.oxygenSaturationAvg.range,
          pycnoDepthAvg: forecastParamOptions.pycnoDepthAvg?.range,
          tkeAvg: forecastParamOptions.tkeAvg?.range,
          buoyancyFreqAvg: forecastParamOptions.buoyancyFreqAvg?.range
        };
      }

      const forecastedPlots = createContourPlot(forecastData, projectContext, {
        plotIdxOffset,
        xaxis: 'x1',
        zRanges: zRanges,
        aggregation: aggregateString
      });

      layouts = layouts.concat(forecastedPlots.layouts);
      const hasVisibility = 'buoyancyFreqAvg' in parameterValues;
      if (hasVisibility) {
        const visiblityPlot = createVisibilityPlot(
          forecastData.pycnocline,
          forecastedPlots.plotData.length + 1,
          zRanges.visibility
        );
        forecastedPlots.plotData.push(visiblityPlot.plot);
        layouts.push(visiblityPlot.layout);
      }

      const xMin = forecastData.contours[Object.keys(forecastData.contours)?.[0]]?.xRange[0];
      const xMax = forecastData.contours[Object.keys(forecastData.contours)?.[0]]?.xRange[1];
      const yAxisCount = Object.keys(forecastData.contours).length + (hasVisibility ? 1 : 0);
      const layout: Partial<Layout> = {
        grid: {
          rows: yAxisCount,
          columns: 1
          // xaxes: ['x1'],
          // yaxes: Array(yAxisCount)
          //   .fill(0)
          //   .map((_, idx) => {
          //     return `y${idx + 1}`;
          //   }),
          // ygap: 0.1
        },
        height: (Object.keys(forecastData.contours).length + (hasVisibility ? 1 : 0)) * 120 + 150,
        margin: { t: 10, b: 60, l: 50, r: 20 },
        hovermode: 'closest',
        autosize: true,
        xaxis: {
          showspikes: true,
          spikemode: 'across',
          spikecolor: 'black',
          hoverformat: '%Y-%m-%d %H:00',
          domain: [0, 1],
          range: chartRange ?? (xMin && xMax ? [xMin, xMax] : undefined),
          title: {
            // text: 'Forecast Time',
            font: {
              size: 18
            }
          }
        },
        showlegend: false,
        legend: {
          orientation: 'v',
          // tracegroupgap: 100,
          x: 1,
          y: 0.03
        }
      };
      layouts.forEach((l, i) => {
        layout[`yaxis${i + 1}`] = l;
      });

      const data = forecastedPlots.plotData;

      return {
        data,
        layout
      };
    },
    [chartRange]
  );
  // const queryMeasures = settings.parameters.flatMap((param) => {
  //   return Object.values(AggregateStringMap).map((agg) => {
  //     return `TessNewForecastHydrography.${paramCubeMap[param]}${agg}`;
  //   });
  // });
  const queryMeasuresSmall = settings?.parameters?.map((param) => {
    return `TessNewForecastHydrography.${param.value?.replace('Avg', aggregateString)}`;
  });
  const {
    isLoading: forecastLoading,
    error: forecastError,
    resultSet: forecastResult,
    refetch: forecastRefetch
  } = useCubeQuery<ForecastHydrographyCubeDatum>(
    {
      measures: queryMeasuresSmall,
      timeDimensions: [
        {
          dimension: 'TessNewForecastHydrography.measuredAt',
          granularity: 'hour',
          // dateRange: 'From 1 day ago to 10 days from now'
          dateRange: settings?.dateRange
        }
      ],
      dimensions: [
        // 'TessNewForecastHydrographyLookup.ensemble', # watch out for LIMIT 10000 on Cube.
        'TessNewForecastHydrography.measuredAt',
        'TessNewForecastHydrographyLookup.depth'
      ],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: [settings.site.smbId.toString()]
        },
        { member: 'TessNewForecastHydrographyLookup.depth', operator: 'lte', values: ['0'] },
        // { member: 'TessNewForecastHydrography.pycnoDepthAvg', operator: 'gt', values: ['0'] },
        // { member: 'TessNewForecastHydrographyLookup.ensemble', operator: 'equals', values: ['22'] },
        {
          member: 'TessNewForecastHydrographyLookup.sublocation',
          operator: 'equals',
          values: ['house']
        }
      ],
      timezone: settings.project.timezone,
      limit: 10000
    },
    { skip: skip }
  );
  const [plot, setPlot] = useState(null);

  const start = chartRange?.[0].toISOString() ?? '';
  const end = chartRange?.[1].toISOString() ?? '';

  useEffect(() => {
    if (settings.project.forecasting && (forecastLoading || !forecastResult)) return;
    const forecast = forecastResult?.rawData() ?? [];
    if (onDataLoaded) {
      onDataLoaded(
        [forecastResult],
        getResultSetsDateRange(forecastResult, settings.project.timezone)
      );
    }

    const transformedForecasts = transform(
      forecast,
      'TessNewForecastHydrography',
      'hour',
      [...settings.parameters],
      settings.aggregation
    );
    setPlot(
      graph(transformedForecasts, settings.project, {
        useLocalMinMax: settings.useLocalMinMax,
        useForecasting: true
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    forecastLoading,
    forecastError,
    forecastResult,
    settings.project,
    useForecasting,
    granularity,
    settings.useSensor,
    settings.parameters.length,
    settings,
    settings.useLocalMinMax,
    start,
    end
    // Causing endless render loop for some reason
    // onDataLoaded,
    // graph
  ]);

  useEffect(() => {
    if (refreshInterval) {
      const interval = setInterval(() => {
        forecastRefetch();
        settings?.useSensor;
      }, refreshInterval);

      return () => clearInterval(interval);
    }
  }, [refreshInterval, settings?.useSensor, forecastRefetch]);

  const hasData = settings.parameters.length > 0;

  return {
    isLoading: forecastLoading,
    error: forecastError,
    hasData,
    plot
  };
};

export default useHydrographyPlot;
