import { Box } from '@chakra-ui/react';
import TabHeadline from 'components/TabHeadline';
import { ProjectContext } from 'contexts/ProjectContext';
import { add, format, lastDayOfMonth, parse } from 'date-fns';
import { Site } from 'graphql/generated';
import { useContext, useMemo, useState } from 'react';
import { TbTimelineEventExclamation } from 'react-icons/tb';
import EventControls, { EventControlFilter } from './EventControls';
import EventTable from './EventTable';

interface EventsPageProps {
  site: Site;
}

const Events = ({ site }: EventsPageProps) => {
  const project = useContext(ProjectContext);

  const endTime = lastDayOfMonth(new Date());
  const [eventFilter, setEventFilter] = useState<EventControlFilter>({
    startTime: add(new Date(format(new Date(), 'yyyy-MM-01')), {
      seconds: project.timezoneOffset / 1000
    }),
    endTime: endTime,
    eventTypes: [],
    severities: []
  });

  const onMonthSelected = (monthYear: string) => {
    const date = parse(monthYear.toLowerCase(), 'MMMM yyyy', new Date());
    setEventFilter({
      ...eventFilter,
      startTime: date,
      endTime: lastDayOfMonth(date)
    });
  };

  const selectedMonth = useMemo(() => {
    return format(eventFilter.startTime, 'MMMM yyyy');
  }, [eventFilter.startTime]);

  return (
    <Box mt="1rem">
      <TabHeadline
        text="Log and update events at your farm site. Events are useful for tracking algae blooms, extreme ocean conditions, equipment updates, mortality, and more. Revisit an existing event to provide a status update, tag neighboring farms or other staff who need to stay in the loop, or to review SOPs. Events can also be viewed alongside other farm data in the Explore tab."
        icon={<TbTimelineEventExclamation />}
      />
      <EventControls
        types={Object.keys(project.dataEntry.events.types)}
        severities={['Low', 'Medium', 'High']}
        eventFilter={eventFilter}
        onEventFilterChanged={(ef) => setEventFilter(ef)}
      />
      <EventTable
        site={site}
        filter={eventFilter}
        selectedMonth={selectedMonth}
        setSelectedMonth={onMonthSelected}
      />
    </Box>
  );
};

export default Events;
