import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseModalProps,
  VStack
} from '@chakra-ui/react';
import { useState } from 'react';

const SEND_TEST_EMAIL = gql`
  mutation TestEmail($emails: [String!]!) {
    sendTestEmail(emails: $emails)
  }
`;

export const TestEmailModal = (props: UseModalProps) => {
  const [testEmails, setTestEmails] = useState('');
  const [sendTestEmail, result] = useMutation(SEND_TEST_EMAIL);

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Send Test Email</ModalHeader>
        <ModalBody>
          <VStack>
            <Box textAlign="center">
              <Text>Enter comma-seperated email addresses to send a test email to.</Text>
              <Text fontSize="sm">Used for superuser debug purposes only.</Text>
            </Box>
            <Input value={testEmails} onChange={(e) => setTestEmails(e.currentTarget.value)} />
          </VStack>
          {(result?.data || result?.error) && (
            <VStack>
              <Text>Result from email test:</Text>
              <Text>{JSON.stringify(result?.data)}</Text>
              <Text>{JSON.stringify(result?.error)}</Text>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => sendTestEmail({ variables: { emails: testEmails.split(',') } })}>
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
