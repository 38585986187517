import { Site } from 'graphql/generated';

export type DataInputMetricFieldDefinitions = {
  columns: Record<string, { label: string; value: string }[]>;
  measures: string[];
};

export enum THRESHOLD_LEVEL {
  NO_DATA,
  SAFE,
  CAUTION,
  DANGER
}

type HeadlineComponentProps = {
  site: Site;
  timezoneOffset?: number;
  timezoneLabel?: string;
  parameter?: string;
  sourceType?: string[];
  range?: string;
};

type Indicator = {
  missing: 'True' | 'False';
  score: number;
  units: string;
  value: string;
  weight: number;
  ko: 'True' | 'False';
};

type SiwiDatum = {
  siwiTotal: number;
  indicators: { [key: string]: Indicator };
  time: Date;
};

type TessPlanktonSchema = {
  site_id: number;
  sublocation: string;
  depth: number;
  species: string;
  method: string;
  cell_count: number;
  measured_at: string;
};

type TessLiceSchema = {
  site_id: number;
  sublocation: string;
  stage_id: string;
  number_of_fish: number;
  lice_count: number;
  measured_at: string;
};

type TessASCSchema = {
  site_id: number;
  salinity: number;
  water_temp: number;
  oxygen_saturation: number;
  measured_at: string;
};

type ManualHydrographySchema = {
  site_id: number;
  sublocation: string;
  depth: number;
  water_temp: number;
  salinity: number;
  oxygen_saturation: number;
  visibility: number;
  measured_at: string;
};

type TessEquipmentSchema = {
  site_id: number;
  sublocation: string;
  value: string;
  measured_at: string;
};

export type {
  HeadlineComponentProps,
  Indicator,
  ManualHydrographySchema,
  SiwiDatum,
  TessASCSchema,
  TessEquipmentSchema,
  TessLiceSchema,
  TessPlanktonSchema
};
