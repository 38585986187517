import { Kbd, List, ListItem, Text, VStack } from '@chakra-ui/react';
import FormDataGrid from 'components/Forms/DataGrid/FormDataGrid';
import GridCellDelete from 'components/Forms/DataGrid/GridCellDelete';
import GridNumberEditor from 'components/Forms/DataGrid/GridNumberEditor';
import { ProjectContext, ProjectContextType } from 'contexts/ProjectContext';
import { PlanktonCategory } from 'graphql/generated';
import { difference } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { CellKeyboardEvent, Column } from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import { UseControllerProps, useController } from 'react-hook-form';
import { Form, NO_PLANKTON_OBSERVED, jellyConcFromCount, planktonConcFromCount } from './EntryForm';
import './EntryGrid.css';
import PlanktonEntryGridSelect from './PlanktonEntryGridSelect';

type EntryGridProps = {
  project: ProjectContextType;
  depthColumns: { label: string; value: string }[];
  transformRawCounts: boolean;
  numberOfQuadrants: number;
  numberOfSlides: number;
  jellyNetRadius: number;
  isDisabled: boolean;
} & UseControllerProps<Form, 'samples'>;

export type PlanktonRow = {
  species?: string;
  [depth: string]: string | number;
};

const getColumns = (
  project: ProjectContextType,
  depthColumns: { label: string; value: string }[],
  planktonEditor,
  transformRawCounts: boolean,
  numberOfQuadrants: number,
  numberOfSlides: number,
  jellyNetRadius: number,
  onRowDelete: (row: PlanktonRow) => void,
  editable?: boolean
): readonly Column<PlanktonRow>[] => {
  return [
    {
      key: 'species',
      name: 'Species',
      editor: planktonEditor,
      formatter: ({ row }) => {
        return row?.species ? (
          <Text>{project.findPlanktonPolicy(row.species as string)?.name}</Text>
        ) : (
          <Text color="gray.500">Select...</Text>
        );
      },
      width: 250,
      editable
    },
    ...depthColumns.map<Column<PlanktonRow>>((labelValue) => ({
      key: labelValue.value,
      name: labelValue.label,
      width: 150,
      editor: GridNumberEditor,
      cellClass: (row: PlanktonRow) => {
        if (!row?.species) return '';
        const policy = project.findPlanktonPolicy(row.species);
        const isJelly =
          policy.generic?.category == PlanktonCategory.JellyGenus ||
          policy.generic?.category === PlanktonCategory.JellySpecies ||
          policy.species?.category == PlanktonCategory.JellyGenus ||
          policy.species?.category === PlanktonCategory.JellySpecies;
        // Toggle handling of raw counts versus concentrations based on project config.
        const value =
          transformRawCounts && !isJelly
            ? planktonConcFromCount(
                Number(row[labelValue.value]),
                numberOfSlides,
                numberOfQuadrants
              )
            : transformRawCounts && isJelly
              ? jellyConcFromCount(
                  Number(row[labelValue.value]),
                  jellyNetRadius,
                  parseInt(labelValue.label.split(' ')[0].replace('m', ''))
                )
              : row[labelValue.value];

        // Disable coloring for tow samples
        if (row.species && value && !labelValue.label.toLowerCase().includes('tow')) {
          return Number(value) >= policy.danger
            ? 'threshold-lethal'
            : Number(value) >= policy.caution
              ? 'threshold-caution'
              : 'threshold-safe';
        }
      },
      editable
    })),
    {
      name: 'delete',
      key: 'delete',
      headerCellClass: 'entry-grid__delete-header',
      cellClass: 'entry-grid__delete-cell',
      formatter: (props) => GridCellDelete({ ...props, onRowDelete, shown: !!props.row.species })
    }
  ];
};

const EntryGrid = ({
  depthColumns,
  transformRawCounts,
  numberOfQuadrants,
  numberOfSlides,
  jellyNetRadius,
  isDisabled,
  ...props
}: EntryGridProps) => {
  const project = useContext(ProjectContext);
  const { field, fieldState } = useController(props);

  const onRowDelete = useCallback(
    (row: PlanktonRow) => {
      field.onChange(field.value.filter((r) => r.species !== row.species));
    },
    [field]
  );

  const availableSpecies = useMemo(() => {
    const used = field.value.map((r) => r.species as string);
    const species = project.planktonPolicy.map((p) => p.generic?.key ?? p?.species.key);
    return difference(species, used);
  }, [field.value, project.planktonPolicy]);

  const planktonSelector = useMemo(
    () => PlanktonEntryGridSelect({ species: availableSpecies, portal: document.body }),
    [availableSpecies]
  );

  const columns = useMemo(
    () =>
      getColumns(
        project,
        depthColumns,
        planktonSelector,
        transformRawCounts,
        numberOfQuadrants,
        numberOfSlides,
        jellyNetRadius,
        onRowDelete,
        !isDisabled
      ),
    [
      project,
      depthColumns,
      planktonSelector,
      onRowDelete,
      numberOfQuadrants,
      numberOfSlides,
      jellyNetRadius,
      isDisabled
    ]
  );

  const onRowsChange = (rows: PlanktonRow[]) => {
    // Handle adding dynamic rows
    if (Object.keys(rows?.[rows?.length - 1]).length > 0) {
      field.onChange([...rows, {}]);
    } else {
      field.onChange(rows);
    }
  };

  // Forward the 'enter / virtual click' event to the delete button if applicable
  const onCellKeyDown = (cell, e: CellKeyboardEvent) => {
    if (e.key === 'Enter' && cell.column.key === 'delete') {
      //@ts-ignore
      e?.target?.children?.[0].click();
    }
  };

  return (
    <FormDataGrid
      fieldState={fieldState}
      columns={columns}
      rows={field.value}
      isDisabled={isDisabled}
      shadeContent={
        <VStack>
          <Text color="white">{NO_PLANKTON_OBSERVED}</Text>
          <Text color="white">Any entered data will be lost on submission</Text>
        </VStack>
      }
      alertContent={
        <>
          <Text>
            <List>
              <ListItem>
                Select a species by double clicking in the Species column or start typing the name
                of the species.
              </ListItem>
              <ListItem>
                Enter plankton <b>{transformRawCounts ? 'counts' : 'concentrations'}</b> in the
                depth columns to register data. Use of the <Kbd>arrow</Kbd> keys and <Kbd>Tab</Kbd>{' '}
                are supported for navigation.
              </ListItem>
              {transformRawCounts ? (
                <ListItem>
                  Plankton counts will be converted to concentrations upon submission using the
                  equation: Concentration = Number of Slides * Count * 1000 / Number of Quadrants.
                </ListItem>
              ) : (
                ''
              )}
              {transformRawCounts ? (
                <ListItem>
                  Jelly counts will be converted to concentrations upon submission using the
                  equation: Concentration = Count / (Jelly Net Radius ^ 2 * Pi * Tow Depth).
                </ListItem>
              ) : (
                ''
              )}
              <ListItem>
                Grid cells will be colored according to the risk threshold associated with the
                concentration {transformRawCounts ? 'computed from the entered count.' : 'entered.'}
              </ListItem>
            </List>
          </Text>
          <Text></Text>
          <Text></Text>
          <Text></Text>
        </>
      }
      onRowsChange={onRowsChange}
      onCellKeyDown={onCellKeyDown}
    />
  );
};

export default EntryGrid;
