import { WarningIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Spacer,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { ReactChild, useState } from 'react';

type FormModalProps = {
  children: ReactChild;
  modalHeader: ReactChild;
  isDeleting: boolean;
  isDeleteDisabled?: boolean;
  deleteTooltip?: string;
  isSubmitDisabled: boolean;
  isSubmitting: boolean;
  size?: ModalProps['size'];
  onClose: () => void;
  onDelete?: () => Promise<void>;
  onSubmit: () => void;
};

const FormModal = ({
  children,
  modalHeader,
  isDeleting,
  isDeleteDisabled,
  deleteTooltip,
  isSubmitDisabled,
  isSubmitting,
  size,
  onClose,
  onDelete,
  onSubmit
}: FormModalProps) => {
  const [isVerifyingDelete, setDeleteVerification] = useState(false);

  const verifyDelete = async () => {
    if (!isVerifyingDelete) {
      setDeleteVerification(true);
    } else {
      await onDelete();
      onClose();
    }
  };

  const deleteButton = () => {
    if (onDelete) {
      return (
        <Button
          data-cypress="report-delete-btn"
          p="0px 40px"
          m="0px 40px"
          colorScheme={isVerifyingDelete ? 'white' : 'red'}
          color={isVerifyingDelete ? 'red.500' : 'white'}
          borderColor={isVerifyingDelete && 'red.500'}
          border={isVerifyingDelete && '1px solid'}
          onClick={verifyDelete}
          isLoading={isDeleting}
          isDisabled={isDeleting || isSubmitting || isDeleteDisabled}
          loadingText="Deleting...">
          {isVerifyingDelete ? (
            <Flex alignItems="center">
              <Text>Confirm Delete</Text>
              <WarningIcon ml="10px" />
            </Flex>
          ) : (
            <Text>Delete</Text>
          )}
        </Button>
      );
    }
    return <Box></Box>;
  };

  return (
    <Modal size={size ?? 'full'} isOpen={true} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalHeader}</ModalHeader>
        <ModalCloseButton />
        {children}

        <Spacer />

        <ModalFooter justifyContent="space-between">
          {deleteTooltip ? (
            <Tooltip label={deleteTooltip}>{deleteButton()}</Tooltip>
          ) : (
            deleteButton()
          )}
          <Button
            data-cypress={'form-modal-submit'}
            p="0px 40px"
            onClick={onSubmit}
            isDisabled={isSubmitDisabled || isDeleting}
            isLoading={isSubmitting}
            colorScheme="blue"
            loadingText="Submitting...">
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FormModal;
