import { HStack, Text, VStack } from '@chakra-ui/react';
import { default as Select, default as StateManagedSelect } from 'react-select';
import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController, PlanktonTrendSettings } from '../types';

export type ControllerInputs = {
  availableSublocations: string[];
  availableSpecies: string[];
};

const BaseSelect: StateManagedSelect = (props) => {
  return (
    <Select
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          width: '300px',
          textAlign: 'left'
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          textAlign: 'left'
        })
      }}
      {...props}
    />
  );
};

const Controller = ({
  chartSettings,
  setChartSettings,
  chartInputs: { availableSublocations, availableSpecies }
}: ChartController<PlanktonTrendSettings, ControllerInputs>) => {
  const sublocationOptions = [
    { label: 'All', value: 'All' },
    ...availableSublocations.map((s) => ({ label: s, value: s }))
  ];

  return (
    <HStack mb="5px" alignItems="center" justifyContent="center">
      <VStack>
        {chartSettings.groupSublocations ? (
          <Text>Sublocation Groups</Text>
        ) : (
          <Text>Sublocations</Text>
        )}
        <BaseSelect
          isMulti
          value={(chartSettings.selectedSublocations ?? []).map((ss) =>
            sublocationOptions.find((so) => so.value === ss)
          )}
          placeholder="Select Sublocation Group A"
          onChange={(x) =>
            setChartSettings({ ...chartSettings, selectedSublocations: x.map((x) => x.value) })
          }
          options={sublocationOptions}
        />
        <BaseSelect
          isMulti
          isDisabled={!chartSettings.groupSublocations}
          value={(chartSettings?.comparingSublocations ?? []).map((ss) =>
            sublocationOptions.find((so) => so.value === ss)
          )}
          placeholder="Select Sublocation Group B"
          onChange={(x) =>
            setChartSettings({ ...chartSettings, comparingSublocations: x.map((x) => x.value) })
          }
          options={sublocationOptions}
        />
      </VStack>
      <VStack>
        <Text>Species</Text>
        <BaseSelect
          defaultValue={{ label: 'All', value: 'All' }}
          onChange={(x) => setChartSettings({ ...chartSettings, selectedSpecies: x.value })}
          options={[
            { label: 'All', value: 'All' },
            ...availableSpecies.map((s) => ({
              label: chartSettings.project.findPlanktonPolicy(s)?.name ?? 'Unknown Species',
              value: s
            }))
          ]}
        />
      </VStack>
      <ChartSwitchPanel
        switches={[
          {
            switchProps: { isChecked: chartSettings.depthAveraged },
            left: { value: 'nonAggregated', label: 'Individual Depths' },
            right: { value: 'depthAveraged', label: 'Depth-Averaged' },
            toggle: ({ checked }) => setChartSettings({ ...chartSettings, depthAveraged: checked })
          },
          {
            switchProps: { isChecked: chartSettings.groupSublocations },
            left: { value: 'nonGrouped', label: 'Individual Sublocations' },
            right: { value: 'grouped', label: 'Group Sublocations' },
            formControlProps: { minW: '220px' },
            toggle: ({ checked }) =>
              setChartSettings({
                ...chartSettings,
                comparingSublocations: [],
                groupSublocations: checked
              })
          }
        ]}
      />
    </HStack>
  );
};

export default Controller;
