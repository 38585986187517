import { gql, useMutation, useQuery } from '@apollo/client';
import { EditIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useToast
} from '@chakra-ui/react';
import { SiteOption, User, permissions } from '@scoot/permissions';
import axios from 'axios';
import { ThreeStateCheck } from 'components/Forms/ThreeStateCheckbox';
import Shade from 'components/Shade';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { GET_S3_PRESIGNED_URL } from 'graphql/globalMutations';
import { debounce } from 'lodash';
import { useContext, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocalStorage } from 'shared/LocalStorage';
import PasswordEdit from './PasswordEdit';
import SitePermissionsListing from './SitePermissionsListing';
import UserAlarmPreferences from './UserAlarmPreferences';

const UPDATE_USER = gql`
  mutation UpdateUser($userId: String!, $projectId: Int, $attributes: UserInput!) {
    updateUser(userId: $userId, projectId: $projectId, attributes: $attributes) {
      oryId
      name
      jobTitle
      avatarUrl
      email
      superuser
      creationTime
      lastActivity
      projectAdministrator
      alerts
      asc
      manualHydrography
      read
      events
      equipment
      plankton
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($userId: String!, $projectId: Int!) {
    deleteUser(userId: $userId, projectId: $projectId)
  }
`;

const PROJECT_SITES = gql`
  query OrderedProjectSites($projectId: Int!) {
    sites(projectId: $projectId, order: { field: "name", order: ASC }, includeArchived: true) {
      id
      name
      siteLabel
      tabs
    }
  }
`;

type EditUserModalProps = {
  defaultUser: User;
  showPasswordPanel: boolean;
  showAdminPanel: boolean;
  onClose: () => void;
  isDeleteDisabled?: boolean;
  disabledReason?: string;
};

const EditUserModal = ({
  defaultUser,
  showPasswordPanel,
  showAdminPanel,
  isDeleteDisabled,
  disabledReason,
  onClose
}: EditUserModalProps) => {
  const project = useContext(ProjectContext);
  const currentUser = useContext(UserContext);
  const toast = useToast();
  const [getPresignedUrl] = useMutation<{ getS3PresignedURL: string }>(GET_S3_PRESIGNED_URL);
  const [deleteUser] = useMutation(DELETE_USER);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirmingDelete, setDeleteConfirm] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [forcePasswordReset, setForcePasswordReset] = useLocalStorage('forcePasswordReset', false);
  //Jank alert
  // Had to re-set the state so that react tracks permissions updates from setUser
  const [user, setUser] = useState<User>(defaultUser);

  const [updateUser] = useMutation(UPDATE_USER);

  const { loading, data } = useQuery(PROJECT_SITES, {
    variables: { projectId: project?.id },
    skip: project?.id ? false : true
  });

  const allSiteIds = useMemo(() => data?.sites?.map((site) => site.id) ?? [], [data?.sites]);

  const uploadFile = async (filename, file) => {
    try {
      const url = await getPresignedUrl({
        variables: { name: filename, contentType: file.type, operation: 'putObject' }
      });
      await axios.put(url.data.getS3PresignedURL, file, { headers: { 'Content-Type': file.type } });
    } catch (e) {
      console.error('Error uploading file: ', e);
    }
  };

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    setValue,
    setError,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      avatarUrl: user.avatarUrl,
      avatarFile: [],
      name: user.name,
      jobTitle: user.jobTitle,
      projectAdministrator: user.projectAdministrator.includes(project?.id),
      superuser: user.superuser,
      alerts: permissions.canManageAlertsForProject(user, project?.id),
      password: '',
      passwordConfirmation: '',
      alarmSites: user.alarmSites ?? [],
      //@ts-ignore
      audioNotifications: user.audioNotifications
    }
  });
  const watchAvatarUrl = watch('avatarUrl');
  const watchSuperUser = watch('superuser');
  const watchProjectAdmin = watch('projectAdministrator');
  const watchAlerts = watch('alerts');
  const watchAlarmSites = watch('alarmSites');
  const watchAudioNotifications = watch('audioNotifications');

  const isCurrentUserAdmin = useMemo(() => {
    return currentUser.projectAdministrator.includes(project?.id) || currentUser.superuser;
  }, [currentUser.projectAdministrator, currentUser.superuser, project?.id]);

  const shadeState = useMemo(() => {
    if (!isCurrentUserAdmin) {
      return {
        shaded: true,
        text: 'Contact your project administrator for new permissions'
      };
    }

    if (watchSuperUser || watchProjectAdmin) {
      return {
        shaded: true,
        text: 'Project Administrators have full site access.'
      };
    }

    return {
      shaded: false
    };
  }, [isCurrentUserAdmin, watchSuperUser, watchProjectAdmin]);

  const hasNoProjectAccess = () =>
    permissions.hasNoSiteAccess(user) && !watchSuperUser && !watchProjectAdmin && !watchAlerts;

  const onSitePermissionChange = (option: SiteOption, siteId: number) => {
    let siteOptions = user[option];
    let readChanges = {};
    if (!siteOptions.includes(siteId)) {
      siteOptions = [...siteOptions, siteId];

      // If 'read' is not in options, make to add that as well
      if (!user.read.includes(siteId)) {
        readChanges = { read: [...user.read, siteId] };
      }
    } else {
      siteOptions = siteOptions.filter((sid) => sid !== siteId);

      // When remove read, remove all other permissions
      if (option === 'read') {
        permissions.siteOptions.forEach((so) => {
          if (Object.keys(user).includes(so) && Array.isArray(user[so])) {
            const siteIds = user[so].filter((sid) => sid !== siteId);
            readChanges[so] = siteIds;
          }
        });
      }
    }

    setUser({ ...user, [option]: siteOptions, ...readChanges });
  };

  const onAllChange = (option: SiteOption, state: ThreeStateCheck) => {
    if (!Object.keys(user).includes(option)) return;

    const allUpdates = {};

    if (state === 'all') {
      allUpdates[option] = allSiteIds;
      allUpdates['read'] = allSiteIds;
    } else {
      allUpdates[option] = [];

      if (option === 'read') {
        permissions.siteOptions.forEach((so) => {
          if (Object.keys(user).includes(so) && Array.isArray(user[so])) {
            allUpdates[so] = [];
          }
        });
      }
    }

    setUser({ ...user, ...allUpdates });
  };

  const handleOptionalArrays = (data: any, user: User) => {
    if (!project?.id) return;

    const optionalArrayKeys = ['alerts', 'projectAdministrator'];

    const optionalData = { projectAdministrator: user.projectAdministrator, alerts: user.alerts };

    optionalArrayKeys.forEach((key) => {
      if (data[key]) {
        if (!user[key].includes(project.id)) {
          optionalData[key] = [...user[key], project.id];
        }
      } else {
        optionalData[key] = user[key].filter((p) => p !== project.id);
      }
    });

    return optionalData;
  };

  const debouncePasswordValidate = useRef(
    debounce(() => {
      trigger();
    }, 100)
  ).current;

  const setForcePasswordMessage = () => {
    setError('passwordConfirmation', { type: 'validate', message: 'A new password must be set.' });
  };

  const onSubmit = async (data) => {
    if (forcePasswordReset && (errors.password || errors.passwordConfirmation)) {
      setForcePasswordMessage();
      return;
    }

    setIsLoading(true);

    let avatarUrl = '';
    if (data.avatarFile && data.avatarFile.length == 1) {
      avatarUrl = `avatars/${user.oryId}`;
      await uploadFile(avatarUrl, data.avatarFile[0]);
    }

    const optionalData = handleOptionalArrays(data, user);

    try {
      await updateUser({
        variables: {
          userId: user.oryId,
          projectId: project?.id,
          attributes: {
            name: data.name,
            jobTitle: data.jobTitle,
            avatarUrl,
            password: data.passwordConfirmation,
            superuser: data.superuser,
            projectAdministrator: optionalData?.projectAdministrator,
            alerts: optionalData?.alerts,
            asc: user.asc,
            manualHydrography: user.manualHydrography,
            read: user.read,
            events: user.events,
            equipment: user.equipment,
            plankton: user.plankton,
            seaLice: user.seaLice,
            alarmSites: project?.id ? data.alarmSites : undefined,
            audioNotifications: data.audioNotifications
          }
        },
        refetchQueries: ['projectUsers', 'currentUser', 'GetRecentAlarms']
      });
    } catch (e) {
      console.error(e);
      toast({
        status: 'error',
        description: 'Error updating user.'
      });
      setIsLoading(false);
      return;
    }

    if (forcePasswordReset) {
      setForcePasswordReset(false);
    }

    toast({
      status: 'success',
      description: 'User Updated'
    });
    onClose();
    setIsLoading(false);
  };

  const acceptedFileTypes = 'image/*';
  const inputRef = useRef();

  const setAvatarUrl = (e: InputEvent) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      //@ts-ignore
      setValue('avatarUrl', event.target.result);
    };

    //@ts-ignore
    reader.readAsDataURL(e.currentTarget.files[0]);
  };

  const { ref, ...avatarRest } = register('avatarFile', { onChange: (e) => setAvatarUrl(e) });

  const deleteUserFlow = async () => {
    if (isConfirmingDelete) {
      setIsLoading(true);
      const response = await deleteUser({
        variables: { userId: user.oryId, projectId: project.id },
        refetchQueries: ['projectUsers']
      });

      setIsLoading(false);
      onClose();

      if (!response?.data?.deleteUser || response?.errors?.length > 0) {
        toast({
          status: 'error',
          description: 'User could not be deleted'
        });
      } else {
        toast({
          status: 'success',
          description: 'User Deleted'
        });
      }
    } else {
      setDeleteConfirm(true);
    }
  };

  return (
    <Modal
      size="6xl"
      isOpen={true}
      onClose={() => {
        forcePasswordReset ? setForcePasswordMessage() : onClose();
      }}>
      <form data-cypress="edit-user-form" onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {user.email}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <HStack alignItems="start">
              <VStack w="40%">
                <Box>
                  <Text textAlign="center" fontSize="2xl">
                    General
                  </Text>
                  <Center>
                    <Box>
                      <Avatar src={watchAvatarUrl} size="xl" />
                      <input
                        type="file"
                        accept={acceptedFileTypes}
                        {...avatarRest}
                        ref={(e) => {
                          ref(e);
                          //@ts-ignore
                          inputRef.current = e;
                        }}
                        style={{ display: 'none' }}></input>
                      <EditIcon
                        cursor="pointer"
                        onClick={() => {
                          //@ts-ignore
                          return inputRef.current.click();
                        }}
                        position="absolute"
                      />
                    </Box>
                  </Center>
                  <FormLabel>Name</FormLabel>
                  <Input data-cypress="name-input" placeholder="Name" {...register('name')} />
                  <FormLabel mt="10px">Job Title</FormLabel>
                  <Input placeholder="Job Title" {...register('jobTitle')} />
                  {!showAdminPanel && (
                    //Placeholder for general UI use case
                    <Stack>
                      <Checkbox mt="10px" isDisabled={true} isChecked={getValues('alerts')}>
                        Manage Alarms
                      </Checkbox>
                    </Stack>
                  )}
                  {currentUser.oryId === defaultUser.oryId && showPasswordPanel && (
                    <>
                      <PasswordEdit
                        password={register('password', {
                          required: forcePasswordReset,
                          onChange: debouncePasswordValidate,
                          minLength: { message: 'Must be at least 8 characters long.', value: 8 }
                        })}
                        passwordError={errors.password}
                        passwordConfirmation={register('passwordConfirmation', {
                          validate: (v) => {
                            if (v === getValues('password')) return true;
                            return 'Passwords must match.';
                          },
                          required: forcePasswordReset,
                          onChange: debouncePasswordValidate
                        })}
                        passwordConfirmationError={errors.passwordConfirmation}
                      />
                    </>
                  )}
                </Box>
                {isCurrentUserAdmin && showAdminPanel && (
                  <Box pt="20px">
                    <Text textAlign="center" fontSize="2xl">
                      Admin
                    </Text>
                    <VStack alignItems="start">
                      <Checkbox
                        isDisabled={watchSuperUser || watchProjectAdmin}
                        {...(register('alerts'),
                        {
                          isChecked:
                            getValues('alerts') ||
                            getValues('projectAdministrator') ||
                            getValues('superuser'),
                          onChange: (e) => setValue('alerts', e.currentTarget.checked)
                        })}
                        mt="15px">
                        Manage Alerts
                      </Checkbox>
                      <Checkbox
                        data-cypress="project-admin-check"
                        isDisabled={watchSuperUser || isDeleteDisabled}
                        {...(register('projectAdministrator'),
                        {
                          isChecked: getValues('projectAdministrator') || getValues('superuser'),
                          onChange: (e) => setValue('projectAdministrator', e.currentTarget.checked)
                        })}
                        mt="15px">
                        Project Administrator
                      </Checkbox>
                      {isDeleteDisabled && disabledReason && (
                        <Text color="red.500">{disabledReason}</Text>
                      )}
                      {permissions.isSuperuser(currentUser) && (
                        <Checkbox {...register('superuser')} mt="15px">
                          Super User
                        </Checkbox>
                      )}
                    </VStack>
                  </Box>
                )}
              </VStack>
              <Box w="60%">
                <Tabs variant="soft-rounded" align="center" size="lg" colorScheme="gray" mb="20px">
                  <TabList>
                    <Tab>Permissions</Tab>
                    <Tab>Notifications</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Shade
                        shaded={shadeState.shaded}
                        shadeContent={<Text color="white">{shadeState.text}</Text>}>
                        {loading ? (
                          <Skeleton w="100%" h="600px" />
                        ) : (
                          <SitePermissionsListing
                            user={user}
                            sites={data?.sites}
                            onChange={onSitePermissionChange}
                            onAllChange={onAllChange}
                          />
                        )}
                      </Shade>
                    </TabPanel>
                    <TabPanel>
                      <UserAlarmPreferences
                        showSiteAlarms={!!project?.id}
                        audioNotifications={watchAudioNotifications}
                        onAudioNotificationsChange={(useAudio) =>
                          setValue('audioNotifications', useAudio)
                        }
                        selectedSites={watchAlarmSites}
                        sites={data?.sites ?? []}
                        onAlarmSiteToggled={(option) => {
                          if (option.checked) {
                            setValue('alarmSites', [option.siteId, ...watchAlarmSites]);
                          } else {
                            setValue(
                              'alarmSites',
                              watchAlarmSites.filter((sid) => sid !== option.siteId)
                            );
                          }
                        }}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </HStack>
            {hasNoProjectAccess() ? (
              <Text float="right" pt="10px" color="red.500">
                User must be given access to something.
              </Text>
            ) : (
              <Spacer h="34px" />
            )}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup w="100%" justifyContent="space-between">
              {isCurrentUserAdmin && (
                <Button
                  data-cypress="delete-user"
                  isDisabled={forcePasswordReset || isDeleteDisabled || isLoading}
                  isLoading={isLoading}
                  colorScheme="red"
                  variant={isConfirmingDelete ? 'outline' : 'solid'}
                  onClick={() => deleteUserFlow()}>
                  {isConfirmingDelete ? 'Are You Sure?' : 'Delete'}
                </Button>
              )}
              <Button
                data-cypress="save-user"
                disabled={hasNoProjectAccess() || isLoading}
                isLoading={isLoading}
                type="submit"
                colorScheme="blue">
                Save
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default EditUserModal;
