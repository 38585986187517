import { gql, useQuery } from '@apollo/client';
import { BellIcon } from '@chakra-ui/icons';
import { Box, Skeleton } from '@chakra-ui/react';
import AlarmTable from 'components/Alarm/AlarmTable';
import ViewAlarmModal from 'components/Alarm/ViewAlarmModal';
import NoData from 'components/Charts/NoData';
import TabHeadline from 'components/TabHeadline';
import { sub } from 'date-fns';
import { GetSiteAlarmsQuery, Site, SiteAlarm } from 'graphql/generated';
import { useMemo, useState } from 'react';

const GET_SITE_ALARMS = gql`
  query GetSiteAlarms($siteId: Int!, $startTime: Date!, $endTime: Date!) {
    siteAlarms(siteId: $siteId) {
      id
      suspended
      site {
        id
        name
      }
      lastAlert {
        id
        checkedAt
      }
      lastCheck {
        id
        state
        checkedAt
      }
      alarmGroup {
        id
        name
        checkRuns(startTime: $startTime, endTime: $endTime, siteId: $siteId) {
          id
          state
          checkedAt
        }
      }
      pastNotifications(startTime: $startTime, endTime: $endTime) {
        notifiedAt
      }
    }
  }
`;

type AlarmProps = {
  site: Site;
};

const Alarm = ({ site }: AlarmProps) => {
  const [viewingAlarm, setViewingAlarm] = useState<SiteAlarm | null>();

  const [startTime, endTime] = useMemo(() => {
    return [sub(new Date(), { weeks: 1 }), new Date()];
  }, []);

  const { data, loading } = useQuery<GetSiteAlarmsQuery>(GET_SITE_ALARMS, {
    variables: {
      siteId: site.id,
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString()
    }
  });

  const siteAlarms = useMemo(() => {
    if (!data?.siteAlarms) return;

    return data.siteAlarms.map((sa) => ({
      id: sa.id,
      siteId: sa.site.id,
      name: sa.alarmGroup.name.replaceAll('{site}', sa.site.name),
      alarmGroupId: sa.alarmGroup.id,
      mostRecentCheck: sa.lastCheck,
      lastInAlert: sa.lastAlert?.checkedAt
    }));
  }, [data]);

  return (
    <>
      <TabHeadline
        text="View the status of Alarms set for your site."
        icon={<BellIcon boxSize={8} />}
      />

      {loading ? (
        <Skeleton w="100%" h="500px" />
      ) : siteAlarms.length === 0 ? (
        <NoData minH="500px" />
      ) : (
        <Box mb="200px">
          <AlarmTable
            //@ts-ignore
            siteAlarms={siteAlarms}
            type="site"
            onRowClick={(s) => setViewingAlarm({ ...s, site })}
          />
        </Box>
      )}
      {viewingAlarm && (
        <ViewAlarmModal
          alarm={viewingAlarm}
          onClose={() => {
            setViewingAlarm(null);
          }}
        />
      )}
    </>
  );
};

export default Alarm;
