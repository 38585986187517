import { ViewOffIcon } from '@chakra-ui/icons';
import { Center, Text, VStack } from '@chakra-ui/react';
import React from 'react';

type NotIncludedProps = {
  minH: string;
};

const NotIncluded = ({ minH }: NotIncludedProps) => {
  return (
    <Center w="100%" h="100%" minH={minH} border="1px" borderColor="yellow.500" borderRadius="3px">
      <VStack>
        <ViewOffIcon boxSize={8} color="yellow.500" />
        <Text>
          <b>Feature not included in Free Trial Mode.</b>
        </Text>
        <Text>Please contact your administrator to upgrade.</Text>
      </VStack>
    </Center>
  );
};

export default NotIncluded;
