import { Box, Button, Menu, MenuButton, MenuGroup, MenuItem, MenuList } from '@chakra-ui/react';
import { ProjectContext } from 'contexts/ProjectContext';
import mapboxGL from 'mapbox-gl';
import { useContext, useEffect, useState } from 'react';
import { TbStack } from 'react-icons/tb';
import { useMap } from 'react-map-gl';
import { paramOptions } from 'shared/Config';
import Colorbar from './Colorbar';
import { DepthSlider } from './DepthSlider';
import { DefaultLayerOption, LayerOption, getLayerOptions } from './LayerOptions';
import Timeslider from './Timeslider';

const openWeatherMapKey = process.env.REACT_APP_OWM_KEY;

const ONE_HOUR_MS = 3600000;

function generateMapboxURL(parameter: string, time: Date, suffix: string) {
  const date = time.toISOString().substring(0, 13);
  return [parameter, date, suffix].join('_');
}

interface TileSetListeners {
  clearHighlightFn?: (event: any) => void;
  errorAlertFn?: (event: any) => void;
}

const ForecastLayers = ({ layerButtonTop }: { layerButtonTop?: number }) => {
  const mapContext = useMap();
  const projectContext = useContext(ProjectContext);
  const [selectedTileSet, setSelectedTileSet] = useState(DefaultLayerOption);
  const defaultTileSetTime = new Date();
  defaultTileSetTime.setHours(0, 0, 0, 0);
  defaultTileSetTime.setUTCHours(0);
  const [tileSetTime, setTileSetTime] = useState(defaultTileSetTime);
  const [tileSetDepth, setTileSetDepth] = useState(0);
  const [tileSetParam, setTileSetParam] = useState(DefaultLayerOption.value.param);
  const [tileSetListeners, setTileSetListeners] = useState<TileSetListeners>({});
  const [sourceErrors, setSourceErrors] = useState([]);
  //HA 9/23/2020: Incrementing count is used to keep track of tileSet layers so we ensure that one is always displayed while others load
  const [tileSetCount, setTileSetCount] = useState(0);
  const map = mapContext.dashboard?.getMap();

  //raster functions that use state
  function handleLayerChange(newLayer: LayerOption) {
    setSelectedTileSet(newLayer);
    //HA 4/20/20: handle reset of index components
    setTileSetTime(defaultTileSetTime);
    setTileSetDepth(0);
    if (newLayer.value.param) {
      setTileSetParam(newLayer.value.param);
    }
    //@ts-ignore
    if (map.hoverpop) {
      //@ts-ignore
      map.hoverpop.remove();
    }
  }

  function handleTimesliderChange(response) {
    const newTime = new Date(defaultTileSetTime.getTime() + ONE_HOUR_MS * response);
    setTileSetTime(newTime);
    //@ts-ignore
    if (map.hoverpop) {
      //@ts-ignore
      map.hoverpop.remove();
    }
  }

  function handleDepthSliderChange(response) {
    setTileSetDepth(response);
    //@ts-ignore
    if (map.hoverpop) {
      //@ts-ignore
      map.hoverpop.remove();
    }
  }

  //raster functions
  function filterIndex(map, tilelayerName, index) {
    map
      .setFilter(tilelayerName + '-highlight', ['in', 'index'])
      .setFilter(tilelayerName, ['==', 'index', index])
      .setFilter(tilelayerName + '-disable', ['==', 'index', index]);
  }

  function removeLayer(map, tilelayerName) {
    map
      .removeLayer(tilelayerName)
      .removeLayer(tilelayerName + '-highlight')
      .removeLayer(tilelayerName + '-disable')
      .removeSource(tilelayerName);
  }

  function clearHighlight(e, map, tileSet) {
    if (e.originalEvent.cancelBubble) {
      return;
    }
    if (map.hoverpop) {
      map.hoverpop.remove();
    }
    map.setFilter(tileSet + '-highlight', ['in', 'index']);
  }

  const generateIndex = (parameter, time, depth) => {
    let depthIndex =
      selectedTileSet?.value?.depthslider?.depths[projectContext.mapParamSuffix][
        depth
      ].toLowerCase();
    // TODO - This errored when switching tilesets
    if (!depthIndex) return;
    if (depthIndex !== 'surface' && depthIndex !== 'bottom') {
      depthIndex = depthIndex.split('m')[0];
    }
    const date = time.toISOString().substring(0, 13);
    return parameter + '_' + depthIndex + '_' + date;
  };

  function errorAlert(e, map, sourceErrors, setSourceErrors, currentTileSet, nextTileSet) {
    if (
      e &&
      e.error &&
      e.error.status == 404 &&
      !sourceErrors.includes(e.sourceId) &&
      e.sourceId == nextTileSet
    ) {
      if (map.hoverpop) {
        map.hoverpop.remove();
      }
      if (typeof map.getLayer(currentTileSet) !== 'undefined') {
        removeLayer(map, currentTileSet);
      }
      alert('TileSet not available yet for this date.');
      const newSourceErrors = sourceErrors;
      newSourceErrors.push(e.sourceId);
      setSourceErrors(newSourceErrors);
    }
  }

  const addNextOWMLayer = (selectedTileSet, nextTileSet, currentTileSet, firstSymbolId) => {
    map.addSource(nextTileSet, {
      type: 'raster',
      tiles: [
        `https://maps.openweathermap.org/maps/2.0/weather/${
          selectedTileSet.value.param
        }/{z}/{x}/{y}?opacity=0.75&fill_bound=true&palette=${
          selectedTileSet.value.scalelegend.palette
        }&appid=${openWeatherMapKey}&date=${tileSetTime.getTime().toString().slice(0, 10)}`
      ],
      tileSize: 256
    });
    map.addLayer(
      {
        id: nextTileSet,
        type: 'raster',
        source: nextTileSet,
        minzoom: 0,
        maxzoom: 22
      },
      firstSymbolId
    );

    if (typeof map.getLayer(currentTileSet) !== 'undefined') {
      //Rasters are often transparent, so we need to remove the cached layer too
      removeLayer(map, currentTileSet);
    }
  };

  const addNextVectorLayer = (
    selectedTileSet,
    nextTileSet,
    currentTileSet,
    firstSymbolId,
    min_value,
    max_value,
    colorscale
  ) => {
    const url =
      'mapbox://scootdev.' +
      generateMapboxURL(tileSetParam, tileSetTime, projectContext.mapParamSuffix) +
      '?update=' +
      Date.now();
    map.addSource(nextTileSet, {
      type: 'vector',
      id: url,
      //HA 9/11/2020: Mapbox will cache errors for tileSets it previously couldn't find at a given url. To bypass this, we use the sys time
      //to create a new url for every request. The tileSets are currently not large enough for this latency to be
      //a big concern
      url
    });
    map.addLayer(
      {
        id: nextTileSet,
        type: 'fill',
        source: nextTileSet,
        'source-layer': 'tilelayer',
        filter: ['==', 'index', generateIndex(tileSetParam, tileSetTime, tileSetDepth)],
        layout: {
          visibility:
            !projectContext.forecasting && tileSetTime > defaultTileSetTime ? 'none' : 'visible'
        },
        paint: {
          'fill-opacity': 1,
          'fill-color': [
            'to-string',
            [
              'at',
              [
                'floor',
                [
                  '*',
                  [
                    '/',
                    ['-', ['to-number', ['get', 'value']], min_value],
                    ['-', max_value, min_value]
                  ],
                  255
                ]
              ],
              ['literal', colorscale]
            ]
          ]
        }
      },
      firstSymbolId
    );
    map.addLayer(
      {
        id: nextTileSet + '-highlight',
        type: 'fill',
        source: nextTileSet,
        'source-layer': 'tilelayer',
        filter: ['in', 'index'],
        layout: {
          visibility:
            !projectContext.forecasting && tileSetTime > defaultTileSetTime ? 'none' : 'visible'
        },
        paint: {
          'fill-opacity': 0.4,
          'fill-color': 'white',
          'fill-outline-color': 'white'
        }
      },
      firstSymbolId
    );
    map.addLayer(
      {
        id: nextTileSet + '-disable',
        type: 'fill',
        source: nextTileSet,
        'source-layer': 'tilelayer',
        filter: ['==', 'index', generateIndex(tileSetParam, tileSetTime, tileSetDepth)],
        layout: {
          visibility:
            !projectContext.forecasting && tileSetTime > defaultTileSetTime ? 'visible' : 'none'
        },
        paint: {
          'fill-opacity': 1,
          'fill-color': 'grey'
        }
      },
      firstSymbolId
    );

    map.on('mouseenter', nextTileSet, () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', nextTileSet, () => {
      map.getCanvas().style.cursor = '';
    });

    map.on('click', nextTileSet, (e) => {
      e.originalEvent.cancelBubble = true;
      map.setFilter(nextTileSet + '-highlight', [
        'all',
        ['==', 'index', e.features[0].properties.index],
        ['==', 'value', e.features[0].properties.value]
      ]);
      // @ts-ignore
      map.hoverpop = new mapboxGL.Popup()
        .setLngLat(e.lngLat)
        .setHTML(
          parseInt(e.features[0].properties.value) -
            selectedTileSet.value.res +
            '-' +
            e.features[0].properties.value +
            ' ' +
            paramOptions[selectedTileSet.value.param].units
        )
        .addTo(map);
    });

    map.on('click', nextTileSet + '-disable', (e) => {
      e.originalEvent.cancelBubble = true;
      // @ts-ignore
      map.hoverpop = new mapboxGL.Popup()
        .setLngLat(e.lngLat)
        .setHTML('Forecasts are not enabled for this project.')
        .addTo(map);
    });
  };

  const resetTileSetListeners = (nextTileSet, currentTileSet) => {
    if (tileSetListeners.clearHighlightFn) {
      map.off('click', tileSetListeners.clearHighlightFn);
    }

    const clearHighlightFn = (e) => {
      clearHighlight(e, map, nextTileSet);
    };

    map.on('click', clearHighlightFn);

    if (tileSetListeners.errorAlertFn) {
      map.off('click', tileSetListeners.errorAlertFn);
    }

    const errorAlertFn = (e) => {
      errorAlert(e, map, sourceErrors, setSourceErrors, currentTileSet, nextTileSet);
    };

    map.on('error', errorAlertFn);

    setTileSetListeners({ clearHighlightFn: clearHighlightFn, errorAlertFn: errorAlertFn });
  };

  function findSymbolLayer(layers) {
    let firstSymbolId;
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].type === 'symbol') {
        firstSymbolId = layers[i].id;
        break;
      }
    }
    return firstSymbolId;
  }

  //HA 12/2/20: effect for removing cached layer when new tileset is chosen
  useEffect(() => {
    if (selectedTileSet && map) {
      const previousTileSet = 'tilelayer' + tileSetCount;
      if (typeof map.getLayer(previousTileSet) !== 'undefined') {
        removeLayer(map, previousTileSet);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTileSet]);

  //HA 9/23/20: effect for filtering layers for time animation. This assumes your current layer is a vector layer
  //using index as a way to flip through different time options.The previous tileSet is still rendered beneath the
  //current layer for smooth animations, so we need to filter that one too.
  useEffect(() => {
    if (!map) return;
    const currentTileset = 'tilelayer' + tileSetCount;
    const previousTileSet = 'tilelayer' + (tileSetCount - 1);
    if (map.getLayer(previousTileSet)) {
      filterIndex(map, previousTileSet, generateIndex(tileSetParam, tileSetTime, tileSetDepth));
    }
    if (map.getLayer(currentTileset)) {
      filterIndex(map, currentTileset, generateIndex(tileSetParam, tileSetTime, tileSetDepth));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tileSetDepth]);

  //HA 4/20/20: effect for changing raster layers, happens when new time or layer is selected for display
  useEffect(() => {
    if (selectedTileSet && map) {
      const previousTileSet = 'tilelayer' + (tileSetCount - 1);
      if (selectedTileSet.value.type) {
        const currentTileSet = 'tilelayer' + tileSetCount;
        const nextTileSet = 'tilelayer' + (tileSetCount + 1);
        const min_value = paramOptions[tileSetParam].range[0];
        const max_value = paramOptions[tileSetParam].range[1];
        const colorscale = paramOptions[tileSetParam].colorscale;
        const firstSymbolId = findSymbolLayer(map.getStyle().layers);
        if (selectedTileSet.value.type === 'vector') {
          addNextVectorLayer(
            selectedTileSet,
            nextTileSet,
            currentTileSet,
            firstSymbolId,
            min_value,
            max_value,
            colorscale
          );
        } else if (selectedTileSet.value.type === 'raster') {
          addNextOWMLayer(selectedTileSet, nextTileSet, currentTileSet, firstSymbolId);
        }
        resetTileSetListeners(nextTileSet, currentTileSet);
        setTileSetCount(tileSetCount + 1);
      } else {
        const currentTileSet = 'tilelayer' + tileSetCount;
        if (typeof map.getLayer(currentTileSet) !== 'undefined') {
          removeLayer(map, currentTileSet);
        }
      }
      if (typeof map.getLayer(previousTileSet) !== 'undefined') {
        removeLayer(map, previousTileSet);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTileSet, tileSetParam, tileSetTime]);

  return (
    <>
      <Box position="absolute" right={5} w="50px" h="50px" top={layerButtonTop ?? 200}>
        <Menu direction="ltr">
          <MenuButton as={Button} h="100%" w="100%" background="white" pl="10px">
            <TbStack fontSize="30px" />
          </MenuButton>
          <MenuList zIndex={9999}>
            {selectedTileSet && (
              <MenuItem
                onClick={() =>
                  handleLayerChange({
                    label: 'None',
                    value: {
                      type: null,
                      param: null,
                      res: null
                    }
                  })
                }
                color="red.500">
                Clear
              </MenuItem>
            )}
            {getLayerOptions(projectContext.mapLayers)
              .filter((ml) => ml.label !== 'None')
              .map((l) => (
                <MenuGroup key={l.label} title={l.label}>
                  {l.options?.map((o) => (
                    <MenuItem onClick={() => handleLayerChange(o)} key={o.label}>
                      {o.label}
                    </MenuItem>
                  ))}
                </MenuGroup>
              ))}
          </MenuList>
        </Menu>
      </Box>

      <div
        className="absolute bottom-2 w4 br2 fl pa2 pr5 ma1"
        style={{ zIndex: 100, top: '4em', display: 'block' }}>
        {selectedTileSet.value.depthslider && (
          <DepthSlider
            onChange={handleDepthSliderChange}
            config={selectedTileSet.value.depthslider}
            value={tileSetDepth}
          />
        )}
      </div>

      <div
        className="absolute w-100 pl5 pr3 items-end"
        style={{
          zIndex: 100,
          bottom: '2em',
          right: '2em',
          display: 'flex'
        }}>
        <div className="flex w-80">
          {selectedTileSet.value.timeslider && (
            <Timeslider
              config={selectedTileSet.value.timeslider}
              onChange={handleTimesliderChange}
              startDate={defaultTileSetTime}
              timeOffset={(tileSetTime.valueOf() - defaultTileSetTime.getTime()) / ONE_HOUR_MS}
            />
          )}
        </div>
        <div className="flex w5 f7 pa2">
          {selectedTileSet.value.scalelegend && (
            <div className="tc bg-white w-100 ma0 pa1 br3 ba b--light-gray shadow-1">
              {paramOptions[selectedTileSet.value.param].label}
              <Colorbar param={selectedTileSet.value.scalelegend.param} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ForecastLayers;
