import { Text, VStack } from '@chakra-ui/react';
import { ResultSet } from '@cubejs-client/core';
import CubeTable from 'components/Tables/CubeTable';
import { format } from 'date-fns';
import { Data, Layout, PlotlyDataLayoutConfig } from 'plotly.js';
import { useMemo } from 'react';
import Plot from '../Plot';

export type ChartRendererProps = {
  resultSet: ResultSet;
  chartType: string;
  fillZeros: boolean;
  showLegend?: boolean;
  xaxisTitle?: string;
  beforePlot?: (plot: { data: Data[]; layout: Partial<Layout> }) => {
    data: Data[];
    layout: Partial<Layout>;
  };
};

const Renderer = ({
  resultSet,
  chartType,
  fillZeros,
  beforePlot,
  xaxisTitle,
  showLegend = true
}: ChartRendererProps) => {
  const cubeChartTypeToPlotly = useMemo(
    () => ({
      line: { type: 'scattergl' },
      bar: { type: 'bar' },
      area: { type: 'scattergl', fill: 'tozeroy' },
      scatter: { type: 'scattergl', mode: 'markers' }
    }),
    []
  );

  const plot: PlotlyDataLayoutConfig = useMemo(() => {
    const plotData: Data[] = resultSet.series().map((s) => {
      const series = s.series.filter((d) => {
        if (!fillZeros) {
          return Number(d.value) !== 0;
        } else {
          return true;
        }
      });

      return {
        x: series.map((d) => d.x),
        y: series.map((d) => d.value),
        name: s.title.replace('Explore', '').replace('Tess', ''),
        showlegend: showLegend,
        ...cubeChartTypeToPlotly[chartType]
      };
    });

    const layout: Partial<Layout> = {
      margin: {
        t: 25
      },
      yaxis: {
        title: Object.values(resultSet.annotation().measures)
          .map((d) => d.shortTitle)
          .join(',')
          .replace('Tess', '')
          .replace('Explore', '')
      },
      xaxis: {
        title: xaxisTitle
          ? xaxisTitle
          : Object.values(resultSet.annotation().dimensions)
              .map((d) => d.shortTitle)
              .join(',')
              .replace('Tess', '')
              .replace('Explore', '')
      }
    };

    let plot = { data: plotData, layout };

    if (beforePlot) {
      plot = beforePlot(plot);
    }

    return plot;
  }, [chartType, resultSet, cubeChartTypeToPlotly, fillZeros, showLegend, beforePlot]);

  const [minDate, maxDate] = useMemo(() => {
    const first = new Date(resultSet.query().timeDimensions?.[0].dateRange?.[0]);
    const second = new Date(resultSet.query().timeDimensions?.[0].dateRange?.[1]);
    return [first, second];
  }, [plot]);

  if (!resultSet) {
    return <></>;
  }

  return chartType === 'table' ? (
    <CubeTable resultSet={resultSet} />
  ) : (
    <VStack w="100%" spacing={0}>
      <Plot className="w-100 custom-render-chart" useResizeHandler={true} {...plot} />
      <Text fontSize="sm" color="gray.600">
        {format(minDate, 'PP p')} - {format(maxDate, 'PP p')}
      </Text>
      )
    </VStack>
  );
};

export default Renderer;
