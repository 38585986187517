export default {
  width: '100%',

  control: {
    fontSize: 14,
    fontWeight: 'normal',
    backgroundColor: '#fff'
  },

  '&multiLine': {
    '&focused': {
      outline: 'none'
    },
    control: {
      minHeight: 63
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent'
    },
    input: {
      padding: 9,
      border: '1px solid silver'
    }
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent'
    },
    input: {
      padding: 1,
      border: '2px inset'
    }
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)'
    }
  }
};
