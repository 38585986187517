import { Box, Center, Flex, VStack } from '@chakra-ui/react';
import { ErrorBoundary } from '@sentry/react';
import DashboardMap from 'components/Map/DashboardMap';
import { RenderError } from 'components/Pages/RenderError';
import { ProjectContext } from 'contexts/ProjectContext';
import { FC, useContext } from 'react';
import { Outlet } from 'react-router-dom';

export const TabErrorBoundary: FC = ({ children }) => (
  <ErrorBoundary
    fallback={
      <Center width={'100vw'} height={'100vh'}>
        <RenderError />
      </Center>
    }
    showDialog={false}>
    {children}
  </ErrorBoundary>
);

const ProjectLayout = () => {
  const project = useContext(ProjectContext);

  return (
    <VStack>
      <Flex w="100%">
        <TabErrorBoundary>
          <Box
            w="100%"
            h="400px"
            maxH="800px"
            position="relative"
            borderRadius="base"
            boxShadow="base">
            <DashboardMap projectOverview={true} />
          </Box>
        </TabErrorBoundary>
      </Flex>
      <Box pt="20px" pb="20px" w="100%" id={'sitepage'}>
        <TabErrorBoundary>
          <Outlet context={project} />
        </TabErrorBoundary>
      </Box>
    </VStack>
  );
};

export default ProjectLayout;
