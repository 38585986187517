import { CheckCircleIcon, CloseIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack
} from '@chakra-ui/react';
import { Notice } from 'components/Notice';
import { ContactType, NotificationEvent } from 'graphql/generated';

export const MostRecentNotificationTable = ({
  notification
}: {
  notification?: NotificationEvent;
}) => {
  if (!notification) {
    return (
      <Notice w="100%" py="20px">
        <Text>No Recent Alarm Notification Sent</Text>
      </Notice>
    );
  }

  if (notification.receipts.length === 0) {
    return (
      <Notice w="100%" py="20px">
        <Text>
          There was either an error recording your notifications receipts, or this notification is
          too old to have receipt information.
        </Text>
      </Notice>
    );
  }

  const emailReceipts = notification.receipts.filter((r) => r.type === ContactType.Email) ?? [];
  const textReceipts = notification.receipts.filter((r) => r.type === ContactType.Phone) ?? [];

  return (
    <VStack w="100%">
      <Box w="100%" my="20px">
        <Heading fontSize="sm">Emails</Heading>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Sent</Th>
                <Th>Delivered</Th>
                <Th>
                  <Tooltip
                    label="This field may be incorrect depending on several factors. Firewalls
                    may block the 'opening' of the email event. Certain email clients may
                    mark the email 'open' upon reception of the email.">
                    <Box>
                      <Text display="inline">Opened</Text>
                      <InfoIcon mb="2px" ml="5px" display="inline" />
                    </Box>
                  </Tooltip>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {emailReceipts.map((receipt, i) => (
                <Tr key={i}>
                  <Td>{receipt.destination}</Td>
                  <Td>
                    {receipt.status === 'Send' ||
                    receipt.status === 'Delivery' ||
                    receipt.status === 'Open' ? (
                      <CheckCircleIcon color="green.500" />
                    ) : (
                      <CloseIcon color="red.500" />
                    )}
                  </Td>
                  <Td>
                    {receipt.status === 'Delivery' || receipt.status === 'Open' ? (
                      <CheckCircleIcon color="green.500" />
                    ) : (
                      <CloseIcon color="red.500" />
                    )}
                  </Td>
                  <Td>
                    {receipt.status === 'Delivery' ? (
                      <CheckCircleIcon color="green.500" />
                    ) : (
                      <CloseIcon color="red.500" />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {emailReceipts.length === 0 && (
            <Text mt="15px" w="100%" textAlign="center">
              No email receipts for this notification
            </Text>
          )}
        </TableContainer>
      </Box>

      <Box w="100%" my="20px">
        <Heading fontSize="sm">Texts</Heading>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Number</Th>
                <Th>
                  <Text display="inline">Status</Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {textReceipts.map((receipt, i) => (
                <Tr key={i}>
                  <Td>{receipt.destination}</Td>
                  <Td>{receipt.status}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {textReceipts.length === 0 && (
            <Text mt="15px" w="100%" textAlign="center">
              No text receipts for this notification
            </Text>
          )}
        </TableContainer>
      </Box>
    </VStack>
  );
};
