import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { PlotlyNodeView } from './PlotlyNodeView';

export default Node.create({
  name: 'plotlyComponent',

  group: 'block',

  addAttributes() {
    return {
      chart: {
        default: null
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'plotly-component'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['plotly-component', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(PlotlyNodeView);
  }
});
