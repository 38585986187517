import { useContext } from 'react';
import { ReportingEditContext } from './ReportingEditContext';
import { Text } from '@chakra-ui/react';

const ProjectReportDetails = () => {
  const {
    selectedReportTemplate,
    queryData: { data }
  } = useContext(ReportingEditContext);
  return (
    <>
      <Text fontSize="2xl" textAlign="center">
        Build Your Report
      </Text>
      <selectedReportTemplate.form projectReport={data.projectReport} />
    </>
  );
};

export default ProjectReportDetails;
