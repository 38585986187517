import { Box, HStack, StackProps, Text } from '@chakra-ui/react';
import React, { ReactChild } from 'react';

type FormHeadlineProps = {
  text: string;
  icon: ReactChild;
} & StackProps;

const TabHeadline = ({ text, icon, ...props }: FormHeadlineProps) => {
  return (
    <HStack
      justifyContent={'space-evenly'}
      w="100%"
      ml="auto"
      mr="auto"
      mb="20px"
      maxW="800px"
      {...props}>
      <Box color="blue.500" borderRadius="full" boxShadow="base" w="fit-content" p="10px">
        {/* close enough
            // @ts-ignore */}
        {React.cloneElement(icon, { size: '40' })}
      </Box>
      <Text textAlign={'center'}>{text}</Text>
    </HStack>
  );
};
export default TabHeadline;
