import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Button, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { EquipmentSelectionState } from 'graphql/generated';

export const SelectionView = ({ equipmentState }: { equipmentState: EquipmentSelectionState }) => {
  return (
    <VStack w="200px">
      <HStack>
        <Text>{equipmentState.name}</Text>
        {equipmentState?.description && (
          <Tooltip label={equipmentState.description}>
            <InfoOutlineIcon />
          </Tooltip>
        )}
      </HStack>
      {equipmentState.options.map((option) => (
        <Button
          isDisabled={true}
          variant="outline"
          borderColor={option.color}
          color={option.color}
          key={option.name}>
          {option.name}
        </Button>
      ))}
    </VStack>
  );
};
