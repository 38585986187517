import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { Box, Card, CardBody, CardHeader, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { THRESHOLD_LEVEL } from 'components/types';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { GiLongAntennaeBug } from 'react-icons/gi';
import { MapSite } from '../useMapSites';

const CAGE_DISPLAY_LIMIT = 5;

const colors = {
  0: 'blue.500',
  1: 'green.500',
  2: 'orange.500',
  3: 'red.500'
};

const varColors = {
  0: 'var(--chakra-colors-blue-500)',
  1: 'var(--chakra-colors-green-500)',
  2: 'var(--chakra-colors-orange-500)',
  3: 'var(--chakra-colors-red-500)'
};

const thresholdDisplay = {
  0: 'No Data',
  1: 'Safe',
  2: 'Caution',
  3: 'Danger'
};

export const PlanktonCard = ({ site }: { site: MapSite }) => {
  const noPlankton = useMemo(() => {
    return site.planktonAlerts.every((a) => a.level === THRESHOLD_LEVEL.NO_DATA);
  }, [site.planktonAlerts]);

  const topPlanktonAlert = useMemo(() => {
    return site.planktonAlerts?.[0]?.level ?? THRESHOLD_LEVEL.NO_DATA;
  }, [site.planktonAlerts]);

  const planktonNonSafe = useMemo(() => {
    return site.planktonAlerts.filter((oa) => oa.level > THRESHOLD_LEVEL.SAFE);
  }, [site.planktonAlerts]);

  return (
    <Card minW="340px">
      <CardHeader pb="0" textAlign="center">
        <Text fontSize="sm">Plankton</Text>
        <Divider mb="10px" />
        <VStack fontSize="lg">
          <GiLongAntennaeBug
            color={varColors[topPlanktonAlert]}
            aria-label="Plankton Level"
            fontSize="40px"
          />
          {topPlanktonAlert !== THRESHOLD_LEVEL.NO_DATA && (
            <Text fontSize="xxlg" color={colors[topPlanktonAlert]}>
              {thresholdDisplay[topPlanktonAlert]}
            </Text>
          )}
        </VStack>
      </CardHeader>
      <CardBody textAlign="center">
        {planktonNonSafe.length === 0 && !noPlankton ? (
          <VStack>
            <CheckCircleIcon fontSize="xx-large" color="green.500" />
            <Text fontWeight="bold" color="green.500" fontSize="sm">
              Safe Plankton Levels!
            </Text>
          </VStack>
        ) : noPlankton ? (
          <>
            <VStack>
              <WarningIcon fontSize="xx-large" color="yellow.500" />
              <Text fontWeight="bold" fontSize="sm">
                No Plankton Recorded
              </Text>
            </VStack>
          </>
        ) : (
          <Box w="100%">
            {site.planktonAlerts.slice(0, CAGE_DISPLAY_LIMIT).map((a, i) => (
              <HStack key={i}>
                <Text w="25%" fontWeight="bold" color={colors[a.level]} align="start" fontSize="sm">
                  {thresholdDisplay[a.level]}
                </Text>
                <Text w="50%" align="center" fontSize="sm">
                  {capitalize(a.species.replaceAll('-', ' '))}
                </Text>
                <Text w="25%" fontSize="sm">
                  {capitalize(a.sublocation)}
                </Text>
              </HStack>
            ))}
          </Box>
        )}
        {planktonNonSafe.length - CAGE_DISPLAY_LIMIT > 0 && (
          <Text fontSize="xs">{planktonNonSafe.length - CAGE_DISPLAY_LIMIT} more non-safe...</Text>
        )}
        <Text color="blue.500" fontWeight="bold" fontSize="xs">
          (Last 24 Hours)
        </Text>
      </CardBody>
    </Card>
  );
};
