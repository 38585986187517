import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import ReleaseNotes from 'components/Modals/ReleaseNotes';

type ReleaseNotesModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ReleaseNotesModal = ({ isOpen, onClose }: ReleaseNotesModalProps) => {
  return (
    <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Release Notes</ModalHeader>
        <ModalCloseButton />
        <ModalBody ml="10px" textAlign={['center']}>
          <ReleaseNotes />
        </ModalBody>

        <ModalFooter>
          <Flex width="100%" justifyContent="space-between">
            <Button onClick={onClose}>Close</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReleaseNotesModal;
