// import BaseSelect from 'components/Forms/BaseSelect';
import { HStack } from '@chakra-ui/react';
import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController } from '../types';
import { UseRiverTraceParams } from './useRiversTrace';

export type RiverChartInputs = {
  allRiverStations: { value: string; label: string }[];
};

const Controller = ({
  chartSettings,
  setChartSettings,
  chartInputs
}: ChartController<UseRiverTraceParams, RiverChartInputs>) => {
  return (
    <>
      <HStack alignContent="center" justifyContent="center">
        <ChartSwitchPanel
          selects={[
            {
              value: chartSettings.selectedRiverStations,
              // defaultValue: chartSettings?.parameters,
              options: chartInputs?.allRiverStations ?? [],
              isMulti: true,
              placeholder: 'All Nearby River Gauges',
              styles: {
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: '350px',
                  marginRight: '100px',
                  closeMenuOnSelect: false
                })
              },
              onChange: (options: { label: string; value: string }[]) => {
                setChartSettings({
                  ...chartSettings,
                  selectedRiverStations: options.map((d) => {
                    return { value: d.value, label: d.label };
                  })
                });
              }
            },
            {
              label: 'Search Radius (km)',
              value: chartSettings.searchRadius,
              options: [
                { label: '5', value: '5' },
                { label: '10', value: '10' },
                { label: '25', value: '25' },
                { label: '50', value: '50' },
                { label: '100', value: '100' }
              ],
              isMulti: false,
              placeholder: 'Radius (km)',
              styles: {
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: '150px',
                  closeMenuOnSelect: false
                })
              },
              onChange: (options: { label: string; value: string }) => {
                setChartSettings({
                  ...chartSettings,
                  searchRadius: { value: options.value, label: options.label }
                });
              }
            }
          ]}
          switches={[
            {
              left: { value: 'dischargeAvg', label: 'Discharge' },
              right: {
                value: 'waterlevelAvg',
                label: 'Water Level',
                tooltip:
                  'Display river discharge in cubic meters per second (cms) OR Display river/lake water level in meters'
              },
              toggle: ({ checked }) =>
                setChartSettings({ ...chartSettings, isWaterLevel: checked }),
              switchProps: { isChecked: chartSettings.isWaterLevel }
            }
          ]}></ChartSwitchPanel>
      </HStack>
    </>
  );
};

export default Controller;
