import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseModalProps
} from '@chakra-ui/react';

interface GroupModalProps {
  title: string;
  items: { [key: string]: string[] };
}

export const GroupModal = ({ title, items, ...props }: GroupModalProps & UseModalProps) => {
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Accordion allowMultiple={true}>
            {Object.entries(items).map(([entryKey, entryItems]) => (
              <AccordionItem key={entryKey}>
                <AccordionButton>{entryKey}</AccordionButton>
                <AccordionPanel>
                  <List>
                    {entryItems.map((i) => (
                      <ListItem key={i}>{i}</ListItem>
                    ))}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
