import { WiDayRainWind } from 'react-icons/wi';
import { HStack, VStack } from '@chakra-ui/react';
import TabHeadline from 'components/TabHeadline';
import { ProjectContextType } from 'contexts/ProjectContext';
import { Site } from 'graphql/generated';
import { Chart as AtmosConditionsChart } from 'components/Charts/AtmosConditions/Chart';
import { Chart as AtmosRainChart } from 'components/Charts/AtmosRain/Chart';
import ChartTile from 'components/Charts/ChartTile';
import { WindRose } from 'components/Charts/WindRose/Chart';
import { WindChart } from 'components/Charts/Wind/Chart';
import { SolarChart } from 'components/Charts/AtmosSolar/Chart';

type WeatherProps = {
  site?: Site;
  project: ProjectContextType;
};

const Weather = ({ project, site = null }: WeatherProps) => {
  return (
    <VStack w="100%">
      <TabHeadline text="Recent local weather data recorded on site." icon={<WiDayRainWind />} />
      <ChartTile w="100%" heading="Atmospheric Conditions" site={site} project={project}>
        <AtmosConditionsChart settings={{ project, site }} />
      </ChartTile>
      <ChartTile w="100%" heading="Rain" site={site} project={project}>
        <AtmosRainChart settings={{ project, site }} />
      </ChartTile>
      <HStack alignItems="stretch" w="100%">
        <ChartTile mb="0px" minW="500px" w="30%" heading="Wind Rose" site={site} project={project}>
          <WindRose settings={{ project, site }} />
        </ChartTile>
        <ChartTile w="100%" heading="Wind Conditions" site={site} project={project}>
          <WindChart settings={{ project, site }} />
        </ChartTile>
      </HStack>
      <ChartTile w="100%" heading="Solar Radiation" site={site} project={project}>
        <SolarChart settings={{ project, site }} />
      </ChartTile>
    </VStack>
  );
};

export { Weather };
