import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Image,
  Link,
  Stack
} from '@chakra-ui/react';
import config from 'config';
import React from 'react';

const SeastateServerError: React.FC<{ showLogo?: boolean }> = ({ children, showLogo = true }) => {
  return (
    <Stack align={'center'} transform={`translateY(-30%)`}>
      {showLogo && <Image boxSize={'80px'} src={'/ScootScienceLogo.png'} marginBottom={'1em'} />}
      <Alert
        status="error"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        padding={'2em 1em'}
        margin={'1em'}
        data-cypress={'server-down-alert'}
        maxWidth={{
          base: '100%',
          lg: '50vw'
        }}>
        <AlertIcon boxSize={'40px'} />
        <AlertTitle fontSize="xl" margin={'0.5em 0'}>
          Server Error
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          {children}
          <Link
            mt="30px"
            display="block"
            href={config.ASANA_BUG_FORM_URL}
            target="_blank"
            rel="noopener noreferrer"
            isExternal>
            Report An Issue
            <ExternalLinkIcon ml="5px" />
          </Link>
        </AlertDescription>
      </Alert>
    </Stack>
  );
};

export default SeastateServerError;
