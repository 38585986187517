import { PlusSquareIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionItem,
  Heading,
  AccordionButton,
  Box,
  AccordionPanel
} from '@chakra-ui/react';
import React from 'react';

type FaqEntryProps = {
  title: string;
};

const FaqEntry: React.FC<FaqEntryProps> = ({ title, children }) => {
  return (
    <Accordion allowToggle className="pb3">
      <AccordionItem style={{ backgroundColor: 'white' }}>
        <Heading as="h2">
          <AccordionButton _expanded={{ bg: '#3182CE', color: 'white' }} color="blue.500">
            <Box flex="1" textAlign="left">
              <b>{title}</b>
            </Box>
            <PlusSquareIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel pb={4}>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default FaqEntry;
