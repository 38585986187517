import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react';
import config from 'config';
import { UserContext } from 'contexts/UserContext';
import { useContext } from 'react';
import { Link as ReactLink } from 'react-router-dom';

type TaskbarDropdownProps = {
  showUserEdit: () => void;
  showReleaseNotes: () => void;
};

const TaskbarDropdown = ({ showUserEdit, showReleaseNotes }: TaskbarDropdownProps) => {
  const currentUser = useContext(UserContext);

  return (
    <Menu>
      <MenuButton
        color="blue.500"
        data-cypress={'user-email'}
        as={Button}
        rightIcon={<ChevronDownIcon />}
        variant="link">
        <HStack justifyItems="center">
          {currentUser.avatarUrl && <Avatar size="xs" src={currentUser.avatarUrl} />}
          <Text pt="4px">{currentUser.name ? currentUser.name : currentUser.email}</Text>
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={showUserEdit}>Account</MenuItem>
        <MenuItem as={ReactLink} to="/docs">
          User Guide / FAQ
        </MenuItem>
        <MenuItem data-cypress="release-notes" onClick={showReleaseNotes}>
          Release Notes
        </MenuItem>
        <MenuItem>
          <a href={config.ASANA_BUG_FORM_URL} target="_blank" rel="noopener noreferrer">
            Report An Issue
          </a>
        </MenuItem>
        <MenuDivider />
        <MenuItem data-cypress="user-logout-btn" as={ReactLink} to="/logout">
          Logout
        </MenuItem>
        {currentUser.superuser && (
          <>
            <MenuDivider />
            <MenuItem color={'red.300'} as={ReactLink} to="/import">
              Data Import
            </MenuItem>
          </>
        )}
      </MenuList>
    </Menu>
  );
};

export default TaskbarDropdown;
