import { gql, useQuery } from '@apollo/client';
import { AddIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  HStack,
  Heading,
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tag,
  Text,
  VStack,
  Wrap
} from '@chakra-ui/react';
import { useCubeQuery } from '@cubejs-client/react';
import { EquipmentEntryForm, EquipmentEntryModal } from 'components/Equipment/EquipmentEntryModal';
import InfoPopover from 'components/InfoPopover';
import { Notice } from 'components/Notice';
import TabHeadline from 'components/TabHeadline';
import { ProjectContext } from 'contexts/ProjectContext';
import { format } from 'date-fns-tz';
import {
  EquipmentAction,
  EquipmentSelectionStateOption,
  EquipmentState,
  GetEquipmentOnSiteQuery,
  Site,
  SiteEquipment
} from 'graphql/generated';
import { groupBy, isArray, orderBy, uniq } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { GiGears } from 'react-icons/gi';
import { MdCheckCircle, MdUpdate } from 'react-icons/md';

interface EquipmentEntryProps {
  site: Site;
}

export const GET_EQUIPMENT_ON_SITE = gql`
  query GetEquipmentOnSite($siteId: Int!) {
    site(id: $siteId) {
      id
      equipment {
        id
        name
        details
        sublocation
        deleted
        equipmentType {
          id
          name
          apiManaged
          states {
            ... on EquipmentSelectionState {
              id
              name
              description
              options {
                id
                name
                color
              }
            }
            ... on EquipmentNumberState {
              id
              name
              description
            }
            ... on EquipmentTextState {
              id
              name
              description
            }
          }
          actions {
            id
            name
            description
          }
        }
      }
    }
  }
`;

export type EquipmentDatum = {
  'TessEquipmentLookup.pk': string;
  'TessEquipmentLookup.equipmentSiteId': number;
  'TessEquipmentLookup.stateId': number;
  'TessEquipmentLookup.actionId': number;
  'TessEquipmentLookup.type': string;
  'TessEquipment.value': string;
  'TessEquipment.measuredAt': string;
  'TessEquipment.measuredAt.second': string;
};

type EquipmentUpdate = {
  measuredAt: Date;
  state?: EquipmentState;
  action?: EquipmentAction;
  value: string;
};

export type EquipmentUpdates = {
  [siteEquipmentId: number]: {
    [measuredAt: string]: EquipmentUpdate[];
  };
};

const UpdateList = ({ updates }: { updates: EquipmentUpdate[] }) => {
  return (
    <List>
      {updates.map((u, i) => (
        <ListItem key={u.value + i}>
          <ListIcon as={u?.state ? MdUpdate : MdCheckCircle} color="blue.500" />
          {u?.state ? `${u.state.name}: ${u.value}` : u.value}
        </ListItem>
      ))}
    </List>
  );
};

export const LatestUpdates = ({ updates }: { updates?: EquipmentUpdate[] }) => {
  if (updates) {
    return (
      <Box>
        <Text>{format(updates[0].measuredAt, 'PPpp')}</Text>
        <UpdateList updates={updates} />
      </Box>
    );
  } else {
    return <Text>No recent update.</Text>;
  }
};

export const EquipmentEntry = ({ site }: EquipmentEntryProps) => {
  const project = useContext(ProjectContext);
  const { data } = useQuery<GetEquipmentOnSiteQuery>(GET_EQUIPMENT_ON_SITE, {
    variables: { siteId: site.id }
  });
  const [updatingEquipment, setUpdatingEquipment] = useState<Partial<SiteEquipment>>(null);
  const { resultSet, refetch } = useCubeQuery<EquipmentDatum>(
    {
      dimensions: [
        'TessEquipmentLookup.pk',
        'TessEquipmentLookup.equipmentSiteId',
        'TessEquipmentLookup.stateId',
        'TessEquipmentLookup.actionId',
        'TessEquipment.value',
        'TessEquipment.measuredAt',
        'TessEquipmentLookup.type'
      ],
      timeDimensions: [
        {
          granularity: 'second',
          dimension: 'TessEquipment.measuredAt',
          dateRange: 'from 7 days ago to now'
        }
      ],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: [site.smbId.toString()]
        }
      ],
      timezone: project.timezone,
      order: { 'TessEquipment.measuredAt': 'desc' }
    },
    { subscribe: true }
  );

  const equipmentFormToEagerUpdate = (
    form: EquipmentEntryForm,
    equipment: Partial<SiteEquipment>
  ) => {
    let updates: EquipmentDatum[] = [];
    if (form.updateType === 'action') {
      updates.push({
        'TessEquipment.measuredAt': new Date(form.updateTime).toISOString(),
        'TessEquipment.measuredAt.second': new Date(form.updateTime).toISOString(),
        'TessEquipment.value': form.action.toString(),
        'TessEquipmentLookup.actionId': form.action,
        'TessEquipmentLookup.equipmentSiteId': equipment.id,
        'TessEquipmentLookup.pk': `eager`,
        'TessEquipmentLookup.stateId': -1,
        'TessEquipmentLookup.type': 'EquipmentAction'
      });
    } else {
      updates = updates.concat(
        Object.entries(form.states).map<EquipmentDatum>(([stateId, value]) => {
          const stateTemplate = equipment.equipmentType.states.find(
            (s) => s.id.toString() === stateId
          );
          // When type is EquipmentSelectionState, value is an index on table equipment_type_states
          return {
            'TessEquipment.measuredAt': new Date(form.updateTime).toISOString(),
            'TessEquipment.measuredAt.second': new Date(form.updateTime).toISOString(),
            'TessEquipment.value': value,
            'TessEquipmentLookup.actionId': -1,
            'TessEquipmentLookup.equipmentSiteId': equipment.id,
            'TessEquipmentLookup.pk': `eager`,
            'TessEquipmentLookup.stateId': Number(stateId),
            'TessEquipmentLookup.type': stateTemplate.__typename
          };
        })
      );
    }

    setEagerData([...updates, ...eagerData]);
  };

  const [eagerData, setEagerData] = useState<EquipmentDatum[]>([]);

  // Merge data from cube with eagerly saved/inserted data
  // to fake equipment update in UI while Cube takes it's time.
  const cubeWithEager = useMemo(() => {
    if (resultSet) {
      const data = resultSet.rawData();
      return data.concat(eagerData);
    }

    return [];
  }, [resultSet]);

  const recentUpdatesByEquipment: EquipmentUpdates = useMemo(() => {
    if (cubeWithEager.length > 0 && data?.site) {
      return data.site.equipment
        .filter((e) => !e.deleted)
        .reduce((acc, equipment) => {
          const forEquipment = cubeWithEager.filter(
            (d) => d['TessEquipmentLookup.equipmentSiteId'] === equipment.id
          );

          const updates = forEquipment
            .map((d) => {
              const state = equipment.equipmentType.states.find(
                (s) => s.id === d['TessEquipmentLookup.stateId']
              );

              // If it's an option and the state is now found (most likely from deletion).
              // Return null and we filter it out in a minute

              let option: EquipmentSelectionStateOption | null = null;
              if (state?.__typename === 'EquipmentSelectionState' && isArray(state?.options)) {
                option = state.options.find(
                  (o) =>
                    o.id.toString() === d['TessEquipment.value'] &&
                    d['TessEquipmentLookup.type'] === 'EquipmentSelectionState'
                );

                if (!option) return null;
              }

              return {
                measuredAt: new Date(d['TessEquipment.measuredAt.second']),
                state,
                action: equipment.equipmentType.actions.find(
                  (a) => a.id === d['TessEquipmentLookup.actionId']
                ),
                value: option ? option.name : d['TessEquipment.value']
              };
            })
            .filter((d) => d);

          const latestOrdered = orderBy(updates, 'measuredAt', 'desc');

          const lastSix = uniq(latestOrdered.map((u) => u.measuredAt.getTime())).splice(0, 6);

          acc[equipment.id] = groupBy(
            latestOrdered.filter((u) => lastSix.includes(u.measuredAt.getTime())),
            'measuredAt'
          );
          return acc;
        }, {});
    }
    return {};
  }, [cubeWithEager, data]);

  const updatesForEquipment = (id: number) => Object.entries(recentUpdatesByEquipment?.[id] ?? {});

  return (
    <>
      <TabHeadline
        text="Update and track the current status of your equipment on site."
        icon={<GiGears />}
      />
      <VStack mt="15px" mb="300px" w="100%">
        <Wrap w="100%" justifyItems="start">
          {data?.site?.equipment?.filter((e) => !e.deleted).length ? (
            data?.site?.equipment
              .filter((e) => !e.deleted)
              .map((e) => {
                return (
                  <Card minH="300px" w="500px" key={e.id}>
                    <CardHeader>
                      {e.equipmentType.apiManaged && (
                        <Alert
                          mb="5px"
                          h="30px"
                          overflow="visible"
                          variant="left-accent"
                          status="info">
                          <AlertIcon />
                          <Text pt="3px">API Integration Managed</Text>
                          <InfoPopover ml="5px">
                            This equipment is managed via a third party integration and cannot be
                            manually updated.
                          </InfoPopover>
                        </Alert>
                      )}
                      <HStack alignContent="center" justifyContent="space-between">
                        <Box>
                          {e?.sublocation && (
                            <Tag py="2px" mr="5px" color="blue.500">
                              {e.sublocation}
                            </Tag>
                          )}
                          <Heading display="inline" fontSize="md">
                            {e.equipmentType.name} - {e.name}
                          </Heading>
                        </Box>
                        {!e.equipmentType.apiManaged && (
                          <Button
                            leftIcon={<AddIcon mb="2px" />}
                            colorScheme="green"
                            onClick={() => setUpdatingEquipment(e)}>
                            Update
                          </Button>
                        )}
                      </HStack>
                      <Text>{e.details}</Text>
                    </CardHeader>
                    <CardBody>
                      <Divider mb="15px" />
                      <Heading fontSize="md" mb="5px">
                        Latest Update
                      </Heading>
                      <LatestUpdates
                        updates={Object.values(recentUpdatesByEquipment?.[e.id] ?? {})?.[0]}
                      />
                      <Divider my="15px" />
                      <HStack justifyContent="space-between">
                        <Heading fontSize="md">Previous Updates</Heading>
                        <Text fontSize="xs">(Last 5 updates in 7 days)</Text>
                      </HStack>
                      <VStack mt="5px">
                        {updatesForEquipment(e.id).length <= 1 ? (
                          <Text>No previous entries</Text>
                        ) : (
                          updatesForEquipment(e.id)
                            .slice(1)
                            .map(([measuredAt, updates]) => {
                              return (
                                <Popover key={measuredAt}>
                                  <PopoverTrigger>
                                    <Button
                                      rightIcon={<ChevronRightIcon pb="4px" />}
                                      colorScheme="blue"
                                      variant="outline"
                                      w="100%">
                                      {format(new Date(measuredAt), 'PPpp')}
                                    </Button>
                                  </PopoverTrigger>
                                  <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverHeader>
                                      {
                                        // eslint-disable-next-line
                                        updates[0]?.state ? 'Update' : 'Action'
                                      }
                                    </PopoverHeader>
                                    <PopoverBody>
                                      <UpdateList updates={updates} />
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                              );
                            })
                        )}
                      </VStack>
                    </CardBody>
                  </Card>
                );
              })
          ) : (
            <Notice>
              <Text>
                No equipment assigned to this site. Please contact your Project Administrator if you
                have equipment on site you would like to track.
              </Text>
            </Notice>
          )}
        </Wrap>
        {updatingEquipment && (
          <EquipmentEntryModal
            equipment={updatingEquipment}
            onClose={async (saveSuccess, form, equipment) => {
              if (saveSuccess) {
                await refetch();
                equipmentFormToEagerUpdate(form, equipment);
              }
              setUpdatingEquipment(null);
            }}
          />
        )}
      </VStack>
    </>
  );
};
