import { Heading, Text } from '@chakra-ui/react';
import { FAQPage } from './FAQTypes';

export const ForecastFAQ: FAQPage = {
  name: 'Forecast',
  sections: [
    {
      title: 'What forecasts are available?',
      body: (
        <>
          <Text>
            <b>Regional forecasts</b> are available as layers in the <b>Map View</b>.{' '}
            <b>Site-specific</b> forecast 3-day summaries are available as part of{' '}
            <b>Site Conditions</b>. The <b>Forecast Tab</b> contains more detailed and long range
            site-specific forecasts for ocean, atmosphere, and tidal/celestial processes.
          </Text>
        </>
      )
    },
    {
      title: 'How is data from the farm site used?',
      body: (
        <>
          <Heading as="h4" size="md" textAlign="center" mb="1rem" mt="2rem">
            Which site-specific data are used?
          </Heading>
          <Text mb="5">
            Site-specific forecasts reflect conditions unique to individual farms. Machine learning
            is used to train forecast models on the historical and recent hydrographic and plankton
            data from farm sites, as well as short-term instrument deployments to measure currents
            and other variables. Among the most important training data for these models are the
            hydrographic and plankton data collected by farms.
          </Text>
          <Heading as="h4" size="md" textAlign="center" mb="1rem" mt="2rem">
            Which other data are used?
          </Heading>
          <Text mb="5">
            Remote sensing data measuring freshwater runoff, atmospheric variables, and regional
            ocean conditions are also used for model training.
          </Text>
          <Text mb="5">
            Having a long record of hydrographic conditions allows the model to better understand
            the idiosyncratic responses at a local farm to the broader regional conditions.
          </Text>
          <Heading as="h4" size="md" textAlign="center" mb="1rem" mt="2rem">
            What data collection practices produce the best forecasts?
          </Heading>
          <Text mb="5">
            It is important to have hydrographic data collected at regular time intervals and across
            multiple depths. This allows one to capture important ocean processes that can happen on
            different time scales, ranging from minutes to days and seasons. Ideally, temperature,
            salinity, and oxygen would be recorded from surface to seafloor at regular intervals at
            least every six hours (to capture the dominant tidal periods of ~12 and ~24 hrs).
          </Text>
        </>
      )
    },
    {
      title: 'What weather data is used for the models?',
      body: (
        <>
          <Text>
            Global Ensemble Forecast System (GEFS) atmospheric forecast data are used to force the
            ocean forecast models at each site. Ocean forecasts use several GEFS variables
            representing, for example, wind velocity, precipitation, cloud cover, solar radiation
            flux, and heat flux.
          </Text>
          <Heading as="h4" size="md" textAlign="center" mb="1rem" mt="2rem">
            What are <b>ensembles</b>?
          </Heading>
          <Text>
            GEFS forecasts include information for up to 31 different &quot;ensembles&quot;, each of
            which represents an individual simulation with variations in the initial conditions
            driving the model; these variations reflect uncertainty in the models. We carry that
            ensemble framework through to ocean hydrographic models to help to quantify that
            uncertainty in the ocean forecast originating from the atmospheric forecast.{' '}
          </Text>
        </>
      )
    },
    {
      title: 'Define the tidal indices.',
      body: (
        <>
          <Text>
            Tides are ubiquitous but vary in frequency and amplitude. We use the following indices
            to capture the impact of tides on mixing and the movement of water masses.
          </Text>
          <Heading as="h4" size="md" textAlign="center" mb="1rem" mt="2rem">
            Lunar Index (LI)
          </Heading>
          <Text>
            LI measures the intensity of tidal amplitudes given the tidal phase relative to the
            spring-neap cycle.
          </Text>
          <Heading as="h4" size="md" textAlign="center" mb="1rem" mt="2rem">
            Diurnal Inequality Index (DII)
          </Heading>
          <Text>
            DII measures the raw strength of tidal variation as the rate of change of the
            waterlevel.
          </Text>
        </>
      )
    },
    {
      title: 'What parameters do ocean forecasts produce?',
      body: (
        <>
          <Text></Text>
          <br></br>
          <Text>Site-specific forecasts produce the following parameters: </Text>
        </>
        // component for displaying tabular or modular parameter explanations with or without images.
      )
    }
  ]
};
