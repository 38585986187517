import { Center, Image, Text, VStack } from '@chakra-ui/react';

const DownForMaintenance = () => {
  return (
    <Center h="100vh" w="100vw">
      <VStack>
        <Text fontSize="4xl">Down For Scheduled Maintenance</Text>
        <Image src="/ScootScienceLogo.png" boxSize="200px" alt="Scoot Science Logo" />
        <Image src="https://media.tenor.com/VCOt8cna9WcAAAAC/rosmt-salmon.gif" />
        <Image
          position="absolute"
          left="50px"
          top="50px"
          zIndex={-1}
          src="https://media.tenor.com/VCOt8cna9WcAAAAC/rosmt-salmon.gif"
        />
        <Image
          position="absolute"
          left="500px"
          top="540px"
          zIndex={-1}
          src="https://media.tenor.com/VCOt8cna9WcAAAAC/rosmt-salmon.gif"
        />
        <Image
          position="absolute"
          left="950px"
          top="1000px"
          zIndex={-1}
          src="https://media.tenor.com/VCOt8cna9WcAAAAC/rosmt-salmon.gif"
        />
        <Image
          position="absolute"
          left="100px"
          top="700px"
          zIndex={-1}
          src="https://media.tenor.com/VCOt8cna9WcAAAAC/rosmt-salmon.gif"
        />
        <Image
          position="absolute"
          left="0px"
          top="400px"
          zIndex={-1}
          src="https://media.tenor.com/VCOt8cna9WcAAAAC/rosmt-salmon.gif"
        />
        <Image
          position="absolute"
          left="1450px"
          top="200px"
          zIndex={-1}
          src="https://media.tenor.com/VCOt8cna9WcAAAAC/rosmt-salmon.gif"
        />
        <Image
          position="absolute"
          left="1550px"
          top="600px"
          zIndex={-1}
          src="https://media.tenor.com/VCOt8cna9WcAAAAC/rosmt-salmon.gif"
        />
        <Image
          position="absolute"
          left="950px"
          top="1080px"
          zIndex={-1}
          src="https://media.tenor.com/VCOt8cna9WcAAAAC/rosmt-salmon.gif"
        />
      </VStack>
    </Center>
  );
};

export default DownForMaintenance;
