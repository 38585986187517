import { useQuery } from '@apollo/client';
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Skeleton,
  Tag,
  Text,
  VStack,
  Wrap
} from '@chakra-ui/react';
import { useCubeQuery } from '@cubejs-client/react';
import ChartTile from 'components/Charts/ChartTile';
import EquipmentChart, { ChartSettings } from 'components/Charts/Equipment/Chart';
import EquipmentController, { equipmentTimeFrames } from 'components/Charts/Equipment/Controller';
import GDBStatusChart from 'components/Charts/GDBStatus/Chart';
import { EquipmentTable } from 'components/Equipment/EquipmentTable';
import { Notice } from 'components/Notice';
import { ProjectContext } from 'contexts/ProjectContext';
import {
  EquipmentSelectionStateOption,
  EquipmentType,
  GetEquipmentOnSiteQuery,
  Site,
  SiteEquipment
} from 'graphql/generated';
import { groupBy, isArray, orderBy } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  EquipmentDatum,
  EquipmentUpdates,
  GET_EQUIPMENT_ON_SITE,
  LatestUpdates
} from './EquipmentEntry';

type DataProps = {
  site: Site;
};

export type EquipmentTypeWithSiteEquipment = EquipmentType & {
  siteEquipment: Partial<SiteEquipment>[];
};

const Data = ({ site }: DataProps) => {
  const project = useContext(ProjectContext);

  const { resultSet, isLoading } = useCubeQuery<EquipmentDatum>(
    {
      dimensions: [
        'TessEquipmentLookup.pk',
        'TessEquipmentLookup.equipmentSiteId',
        'TessEquipmentLookup.stateId',
        'TessEquipmentLookup.actionId',
        'TessEquipment.value',
        'TessEquipment.measuredAt',
        'TessEquipmentLookup.type'
      ],
      timeDimensions: [
        {
          granularity: 'second',
          dimension: 'TessEquipment.measuredAt',
          dateRange: 'from 7 days ago to now'
        }
      ],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: [site.smbId.toString()]
        }
      ],
      timezone: project.timezone,
      order: { 'TessEquipment.measuredAt': 'desc' }
    },
    { subscribe: true }
  );

  // TODO: Merge this load with the equipment entry and load in parent component
  const { data, loading } = useQuery<GetEquipmentOnSiteQuery>(GET_EQUIPMENT_ON_SITE, {
    variables: { siteId: site.id }
  });

  const [equipmentChartSettings, setEquipmentChartSettings] = useState<ChartSettings[]>();

  const recentUpdatesByEquipment: EquipmentUpdates = useMemo(() => {
    if (data?.site && resultSet) {
      const cubeData = resultSet.rawData();

      return data.site.equipment
        .filter((e) => !e.deleted)
        .reduce((acc, equipment) => {
          const forEquipment = cubeData.filter(
            (d) => d['TessEquipmentLookup.equipmentSiteId'] === equipment.id
          );

          const updates = forEquipment
            .map((d) => {
              const state = equipment.equipmentType.states.find(
                (s) => s.id === d['TessEquipmentLookup.stateId']
              );

              // If it's an option and the state is now found (most likely from deletion).
              // Return null and we filter it out in a minute

              let option: EquipmentSelectionStateOption | null = null;
              if (state?.__typename === 'EquipmentSelectionState' && isArray(state?.options)) {
                option = state.options.find(
                  (o) =>
                    o.id.toString() === d['TessEquipment.value'] &&
                    d['TessEquipmentLookup.type'] === 'EquipmentSelectionState'
                );

                if (!option) return null;
              }

              return {
                sublocation: equipment?.sublocation,
                name: equipment.name,
                measuredAt: new Date(d['TessEquipment.measuredAt.second']),
                state,
                action: equipment.equipmentType.actions.find(
                  (a) => a.id === d['TessEquipmentLookup.actionId']
                ),
                value: option ? option.name : d['TessEquipment.value']
              };
            })
            .filter((d) => d);
          const latestOrdered = orderBy(updates, 'measuredAt', 'desc');

          acc[equipment.id] = groupBy(latestOrdered, 'measuredAt');
          return acc;
        }, {});
    }
    return {};
  }, [resultSet, data]);

  // Transform the siteEquipment into a data structure for equipment type to site equipment
  const byEquipmentType = useMemo(() => {
    return (
      data?.site.equipment
        .filter((e) => !e.deleted)
        .reduce((acc, curr) => {
          const existingEt = acc.find((et) => et.id === curr.equipmentType.id);
          if (existingEt) {
            existingEt.siteEquipment.push(curr);
          } else {
            acc.push({ ...curr.equipmentType, siteEquipment: [curr] });
          }

          return acc;
        }, [] as EquipmentTypeWithSiteEquipment[]) ?? []
    );
  }, [data]);

  useEffect(() => {
    if (!equipmentChartSettings && byEquipmentType && byEquipmentType.length > 0) {
      setEquipmentChartSettings(
        byEquipmentType.map((eqt) => {
          // Customer request: Default to non-text states first for default. Lets go for numeric then state then text
          let defaultStateId = eqt.states?.find((s) => s.__typename === 'EquipmentNumberState')?.id;

          if (!defaultStateId) {
            defaultStateId = eqt.states?.find(
              (s) => s.__typename === 'EquipmentSelectionState'
            )?.id;
          }

          defaultStateId = eqt.states?.[0]?.id;

          return {
            equipmentType: eqt,
            selectedEquipment: eqt.siteEquipment[0].id,
            stateDimension: eqt.states?.[0]?.id,
            site: site,
            dateRange: equipmentTimeFrames[1].value,
            project
          };
        })
      );
    }
  }, [byEquipmentType]);

  return (
    <VStack w="100%" mb="100px">
      {byEquipmentType.length > 0 && (
        <>
          <Box textAlign="center">
            <Text fontSize="4xl">Latest Updates</Text>
            <Text color="blue.500" fontSize="sm">
              (In the last 7 days)
            </Text>
          </Box>
          {Object.values(recentUpdatesByEquipment).length === 0 && resultSet && data && (
            <Notice>
              <Text>No recent updates</Text>
            </Notice>
          )}
        </>
      )}
      {loading && <Skeleton w="100%" h="400px" />}
      {Object.values(recentUpdatesByEquipment).length > 0 && (
        <Wrap pb="20px">
          {data.site.equipment.map((e) => (
            <Card w="300px" key={e.id}>
              <CardHeader>
                {e?.sublocation && (
                  <Tag py="2px" mr="5px" color="blue.500">
                    {e.sublocation}
                  </Tag>
                )}
                <Heading display="inline" fontSize="md">
                  {e.name}
                </Heading>
              </CardHeader>
              <CardBody>
                <LatestUpdates
                  updates={Object.values(recentUpdatesByEquipment?.[e.id] ?? {})?.[0]}
                />
              </CardBody>
            </Card>
          ))}
        </Wrap>
      )}
      {byEquipmentType.length > 0 && (
        <>
          <Text fontSize="4xl">Data</Text>
          <EquipmentTable
            smbSiteId={site.smbId}
            equipmentData={byEquipmentType}
            deletedSiteEquipmentIds={
              data?.site?.equipment.filter((e) => e.deleted).map((e) => e.id) ?? []
            }
          />
        </>
      )}
      {byEquipmentType &&
        equipmentChartSettings &&
        byEquipmentType.map((et, idx) => {
          return (
            <ChartTile
              key={et.id}
              w="100%"
              h="580px"
              heading={et.name}
              site={site}
              control={
                <EquipmentController
                  chartInputs={{ equipmentType: et, showDateRange: true }}
                  chartSettings={equipmentChartSettings[idx]}
                  setChartSettings={(settings) => {
                    const newSettings = [
                      ...equipmentChartSettings.slice(0, idx),
                      settings,
                      ...equipmentChartSettings.slice(idx + 1, equipmentChartSettings.length)
                    ];
                    setEquipmentChartSettings(newSettings);
                  }}
                />
              }
              tooltip="Data inserted for this type of equipment">
              <EquipmentChart
                dateRange={equipmentChartSettings[idx].dateRange}
                settings={equipmentChartSettings[idx]}
              />
            </ChartTile>
          );
        })}
      {loading || (isLoading && <Skeleton w="100%" h="500px" />)}
      {byEquipmentType.length === 0 && !loading && (
        <Notice>
          <Text>
            No equipment assigned to this site. Please contact your Project Administrator if you
            have equipment on site you would like to track.
          </Text>
        </Notice>
      )}

      {site.hasGdb && (
        <Box w="100%" pt="20px">
          <ChartTile
            w="100%"
            heading="GDB Status"
            site={site}
            tooltip={
              'Measured here is the number of data points we have received from your site in the last 24 hours uploaded from our GDB application.'
            }>
            <GDBStatusChart smbSiteId={site.smbId} />
          </ChartTile>
        </Box>
      )}
    </VStack>
  );
};

export default Data;
