export const MOTILE_LICE_STAGE_IDS = [
  '130',
  '119',
  '118',
  '117',
  '131',
  '116',
  'lepfemaleswitheggstring',
  'lepfemalesnoeggstring',
  'leppreadultsandmales',
  'countlicechalimus',
  'countliceovigiourus',
  'countlicemobile',
  'countlicescottish'
];

export const MOTILE_LICE_STAGE_IDS_PROJECT_ID = {
  2: ['130', '119', '118', '117', '131', '116'],
  4: ['lepfemaleswitheggstring', 'lepfemalesnoeggstring', 'leppreadultsandmales'],
  5: ['countlicechalimus', 'countliceovigiourus', 'countlicemobile', 'countlicescottish'],
  6: [],
  8: [
    'countlicechalimus',
    'countliceovigiourus',
    'countlicemobile',
    'countlicescottish',
    'lepfemaleswitheggstring',
    'lepfemalesnoeggstring',
    'leppreadultsandmales',
    '130',
    '119',
    '118',
    '117',
    '131',
    '116'
  ]
};

export const LICE_STAGE_NAMES_PROJECT_ID = {
  2: {
    '-1': 'No Lice Stage Assigned',
    '102': 'Chalimus 1 Lepo',
    '108': 'Chalimus 4 Lepo',
    '110': 'Chalimus Undefined Lepo',
    '111': 'Chalimus Undefined Cali Lice',
    '112': 'Pre Adult Male 1 Lepo Lice',
    '113': 'Pre Adult Male 2 Lepo Lice',
    '116': 'Pre Adult Unspecified Lepo',
    '117': 'Adult Male Lepo',
    '118': 'Adult Female With Strings Lepo',
    '119': 'Adult Female Without Strings Lepo',
    '120': 'Pre Adult Male 1 Cali Lice',
    '124': 'Pre Adult Unspecified Cali Lice',
    '125': 'Adult Male Cali Lice',
    '126': 'Adult Female With Strings Cali Lice',
    '127': 'Adult Female Without Strings Cali Lice',
    '128': 'Adult Unspecified Cali Lice',
    '130': 'Adult Female Lepo',
    '131': 'Mobile Lepo',
    '132': 'Caligus All Stages'
  },
  4: {
    lepfemaleswitheggstring: 'Lep. Females w/ egg strings',
    lepfemalesnoeggstring: 'Lep. Females w/ no egg strings',
    leppreadultsandmales: 'Lep. Pre-adults and Males',
    chalimus: 'Chalimus (both Lep and Caligus)',
    caligusfemaleswitheggstring: 'Caligus Females w/ egg strings',
    caligusallothermotiles: 'Caligus All other Motiles'
  },
  5: {
    countlicechalimus: 'Chalimus',
    countliceovigiourus: 'Ovigiourus',
    countlicemobile: 'Mobile',
    countlicescottish: 'Scottish'
  },
  6: {},
  8: {
    '-1': 'No Lice Stage Assigned',
    '102': 'Chalimus 1 Lepo',
    '108': 'Chalimus 4 Lepo',
    '110': 'Chalimus Undefined Lepo',
    '111': 'Chalimus Undefined Cali Lice',
    '112': 'Pre Adult Male 1 Lepo Lice',
    '113': 'Pre Adult Male 2 Lepo Lice',
    '116': 'Pre Adult Unspecified Lepo',
    '117': 'Adult Male Lepo',
    '118': 'Adult Female With Strings Lepo',
    '119': 'Adult Female Without Strings Lepo',
    '120': 'Pre Adult Male 1 Cali Lice',
    '124': 'Pre Adult Unspecified Cali Lice',
    '125': 'Adult Male Cali Lice',
    '126': 'Adult Female With Strings Cali Lice',
    '127': 'Adult Female Without Strings Cali Lice',
    '128': 'Adult Unspecified Cali Lice',
    '130': 'Adult Female Lepo',
    '131': 'Mobile Lepo',
    '132': 'Caligus All Stages',
    lepfemaleswitheggstring: 'Lep. Females w/ egg strings',
    lepfemalesnoeggstring: 'Lep. Females w/ no egg strings',
    leppreadultsandmales: 'Lep. Pre-adults and Males',
    chalimus: 'Chalimus (both Lep and Caligus)',
    caligusfemaleswitheggstring: 'Caligus Females w/ egg strings',
    caligusallothermotiles: 'Caligus All other Motiles'
  }
};

export const LICE_STAGE_NAMES = {
  '-1': 'No Lice Stage Assigned',
  '102': 'Chalimus 1 Lepo',
  '108': 'Chalimus 4 Lepo',
  '110': 'Chalimus Undefined Lepo',
  '111': 'Chalimus Undefined Cali Lice',
  '112': 'Pre Adult Male 1 Lepo Lice',
  '113': 'Pre Adult Male 2 Lepo Lice',
  '116': 'Pre Adult Unspecified Lepo',
  '117': 'Adult Male Lepo',
  '118': 'Adult Female With Strings Lepo',
  '119': 'Adult Female Without Strings Lepo',
  '120': 'Pre Adult Male 1 Cali Lice',
  '124': 'Pre Adult Unspecified Cali Lice',
  '125': 'Adult Male Cali Lice',
  '126': 'Adult Female With Strings Cali Lice',
  '127': 'Adult Female Without Strings Cali Lice',
  '128': 'Adult Unspecified Cali Lice',
  '130': 'Adult Female Lepo',
  '131': 'Mobile Lepo',
  '132': 'Caligus All Stages',
  lepfemaleswitheggstring: 'Lep. Females w/ egg strings',
  lepfemalesnoeggstring: 'Lep. Females w/ no egg strings',
  leppreadultsandmales: 'Lep. Pre-adults and Males',
  chalimus: 'Chalimus (both Lep and Caligus)',
  caligusfemaleswitheggstring: 'Caligus Females w/ egg strings',
  caligusallothermotiles: 'Caligus All other Motiles',
  countlicechalimus: 'Chalimus',
  countliceovigiourus: 'Ovigiourus',
  countlicemobile: 'Mobile',
  countlicescottish: 'Scottish'
};
