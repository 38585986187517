import { AspectRatio, Stack } from '@chakra-ui/react';
import Tile from 'components/Tile';
import mapboxGL from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';

mapboxGL.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

interface ProjectCardProps {
  latitude: number;
  longitude: number;
  organizationName: string;
  projectName: string;
}

const ProjectCard = ({ latitude, longitude, organizationName, projectName }: ProjectCardProps) => {
  const mapRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!process.env.REACT_APP_MAPBOX_TOKEN) {
      console.warn('No mapbox token provided - skipping map render.');
      return;
    }
    new mapboxGL.Map({
      container: mapRef.current,
      style: '/style.json',
      transformRequest: (url) => {
        if (url.startsWith('asset://')) {
          return {
            url: url.replace('asset://', location.protocol + '//' + location.host)
          };
        }
      },
      center: [longitude, latitude],
      zoom: 3,
      interactive: false,
      attributionControl: false
    });
  }, [longitude, latitude, projectName, organizationName, mapRef]);

  return (
    <Tile w="300px" p={6} cursor="pointer">
      <Stack align={'center'}>
        <AspectRatio
          ratio={1}
          width={170}
          borderRadius={'100%'}
          overflow={'hidden'}
          border={'2px'}
          borderColor={'gray.200'}>
          <div ref={mapRef} />
        </AspectRatio>
        <h1 className="f3 mb2">{projectName}</h1>
        <h2 className="f5 fw4 gray mt0">{organizationName}</h2>
      </Stack>
    </Tile>
  );
};

export default ProjectCard;
