import { gql, useMutation, useQuery } from '@apollo/client';
import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Skeleton, Text, Wrap, useToast } from '@chakra-ui/react';
import { EquipmentTypeCard } from 'components/Equipment/EquipmentTypeCard';
import { Notice } from 'components/Notice';
import { RenderError } from 'components/Pages/RenderError';
import { ProjectContext } from 'contexts/ProjectContext';
import { EquipmentType, EquipmentTypeInput, GetEquipmentTypesQuery } from 'graphql/generated';
import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

const DELETE_EQUIPMENT_TYPE = gql`
  mutation DeleteEquipmentType($id: Int!) {
    deleteEquipmentType(equipmentTypeId: $id)
  }
`;

const SAVE_EQUIPMENT_TYPE = gql`
  mutation SaveEquipmentType($projectId: Int!, $equipmentType: EquipmentTypeInput!) {
    saveEquipmentType(projectId: $projectId, equipmentType: $equipmentType)
  }
`;

const GET_EQUIPMENT_TYPES = gql`
  query GetEquipmentTypes($projectId: Int!) {
    equipmentTypes(projectId: $projectId) {
      id
      name
      apiManaged
      states {
        ... on EquipmentSelectionState {
          id
          name
          description
          options {
            id
            name
            color
          }
        }
        ... on EquipmentNumberState {
          id
          name
          description
        }
        ... on EquipmentTextState {
          id
          name
          description
        }
      }
      actions {
        id
        name
        description
      }
    }
  }
`;

export interface EquipmentAdminForm {
  equipmentTypes: EquipmentType[];
}

export const EquipmentAdmin = () => {
  const project = useContext(ProjectContext);
  const toast = useToast();

  const { data, loading, error } = useQuery<GetEquipmentTypesQuery>(GET_EQUIPMENT_TYPES, {
    variables: { projectId: project.id }
  });

  const [saveEquipmentType, result] = useMutation(SAVE_EQUIPMENT_TYPE);
  const [deleteEquipmentType, deleteResult] = useMutation(DELETE_EQUIPMENT_TYPE);

  const { control, trigger, getValues } = useForm<EquipmentAdminForm>({
    defaultValues: { equipmentTypes: [] }
  });

  const { fields, append, remove, replace } = useFieldArray({ control, name: 'equipmentTypes' });

  useEffect(() => {
    if (data?.equipmentTypes) replace(data.equipmentTypes);
  }, [data, result.data, deleteResult.data]);

  const [editingType, setEditingType] = useState<
    `equipmentTypes.${number}` | `equipmentTypes.${number}` | null
  >();

  const onDelete = async (equipmentIdx: number) => {
    const { id } = getValues(`equipmentTypes.${equipmentIdx}`);
    setEditingType(null);
    if (id) {
      //TODO: https://www.apollographql.com/docs/react/data/mutations/#the-update-function
      // Update here to refresh the content in EquipmentAssign on deletion of related equipment types
      // see EquipmentAssign#~56
      const resp = await deleteEquipmentType({
        variables: { id },
        refetchQueries: ['GetEquipmentTypes']
      });
      toast({
        status: 'success',
        description: 'Successfully deleted equipment type.'
      });
      if (!resp.data) {
        toast({
          status: 'error',
          description: 'Error deleting equipment type.'
        });
      }
    }
    {
      remove(equipmentIdx);
    }
  };

  const onSave = async (equipmentIdx: number) => {
    setEditingType(null);
    const verified = await trigger(`equipmentTypes.${equipmentIdx}`);
    if (verified) {
      const formValue = getValues(`equipmentTypes.${equipmentIdx}`);

      const equipmentTypeInput: EquipmentTypeInput = {
        id: formValue?.id,
        name: formValue.name,
        states: [
          ...formValue.states.map((s) => {
            return {
              id: s?.id,
              name: s.name,
              description: s.description ?? '',
              //@ts-ignore
              options: s?.options?.map((o) => ({
                id: Number(o?.id) ? Number(o.id) : undefined,
                name: o.name,
                color: o.color
              })),
              type: s.__typename
            };
          })
        ],
        actions: [
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ...formValue.actions.map(({ __typename, ...rest }) => ({
            ...rest,
            description: rest.description ?? '',
            id: Number(rest?.id) ? Number(rest?.id) : undefined
          }))
        ]
      };

      try {
        const resp = await saveEquipmentType({
          variables: {
            projectId: project.id,
            equipmentType: equipmentTypeInput
          },
          refetchQueries: ['GetEquipmentTypes']
        });

        if (!resp.data.saveEquipmentType) throw Error('Error saving equipment type');
        toast({
          status: 'success',
          description: 'Saved Equipment Type.'
        });
      } catch (e: any) {
        console.error(e);
        toast({
          status: 'error',
          description: 'Error saving equipment type.'
        });
      }
    }
  };

  const onNewEquipmentTypeClicked = () => {
    //@ts-ignore
    append({ name: '', states: [], actions: [] });
    setEditingType(`equipmentTypes.${fields.length}`);
  };

  if (error) {
    return <RenderError />;
  }

  return (
    <>
      <Flex pl="5px" w="100%" pt="20px">
        <Box mr="10px">
          <Button
            isDisabled={!!editingType}
            data-cypress="new-equipment-type-btn"
            w="100%"
            mb="10px"
            colorScheme="green"
            leftIcon={<AddIcon mb="2px" />}
            variant="solid"
            onClick={() => {
              onNewEquipmentTypeClicked();
            }}>
            New Equipment Type
          </Button>
        </Box>
      </Flex>
      {loading ? (
        <Skeleton w="100%" h="500px" />
      ) : fields.length > 0 ? (
        <Wrap w="100%" p="5px" mt="15px" spacing="15px" align="start">
          {fields.map((_eq, i) => {
            return (
              <EquipmentTypeCard
                key={i}
                control={control}
                name={`equipmentTypes.${i}`}
                trigger={trigger}
                isEditing={`equipmentTypes.${i}` === editingType}
                canEdit={!editingType}
                onEquipmentTypeDelete={() => {
                  onDelete(i);
                }}
                onSetEditing={() => setEditingType(`equipmentTypes.${i}`)}
                onSave={() => onSave(i)}
              />
            );
          })}
        </Wrap>
      ) : (
        data.equipmentTypes.length === 0 && (
          <Notice>
            <Text fontSize="lg">
              No equipment types created yet. Select &quot;Create Equipment Type&quot; to get
              started.
            </Text>
          </Notice>
        )
      )}
    </>
  );
};
