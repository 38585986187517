import { ExternalLinkIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { Badge, IconButton, Skeleton, Text, Tooltip } from '@chakra-ui/react';
import { DataTable } from 'components/Tables/DataTable';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { format } from 'date-fns';
import { AlarmState, SiteAlarm } from 'graphql/generated';
import { useContext, useMemo } from 'react';
import { BiPauseCircle, BiPlayCircle } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';

type AlarmTableProps = {
  type: 'alarmGroup' | 'site';
  siteAlarms: {
    id: number;
    name: string;
    alarmGroupId: number;
    siteId: number;
    mostRecentCheck: { checkedAt?: Date; state: AlarmState } | 'loading' | 'error';
    lastInAlert?: Date | 'loading';
  }[];
  showLastInAlert?: boolean;
  showLastChecked?: boolean;
  onRowClick: (alarm: SiteAlarm) => void;
  onToggleSuspend?: (alarm: {
    siteAlarmId: number;
    alarmGroupId: number;
    suspend: boolean;
  }) => void;
};

export const badgeForState = (state?: AlarmState, errorMessage?: string) => {
  if (!state || state === AlarmState.Unknown) return <Badge variant="outline">No Data</Badge>;

  if (state === AlarmState.Error)
    return errorMessage ? (
      <Tooltip label={errorMessage}>
        <Badge variant="outline" colorScheme="red">
          Error
        </Badge>
      </Tooltip>
    ) : (
      <Badge variant="outline" colorScheme="red">
        Error
      </Badge>
    );

  if (state === AlarmState.Alert)
    return (
      <Badge variant="outline" colorScheme="red">
        Alert
      </Badge>
    );

  return (
    <Badge variant="outline" colorScheme="green">
      Ok
    </Badge>
  );
};

const AlarmTable = ({
  siteAlarms,
  type,
  showLastChecked = true,
  showLastInAlert = true,
  onRowClick,
  onToggleSuspend
}: AlarmTableProps) => {
  const project = useContext(ProjectContext);
  const user = useContext(UserContext);

  const columns: Column<SiteAlarm> = useMemo(() => {
    const base: Column<SiteAlarm>[] = [
      {
        id: 'status',
        Header: () => {
          return (
            <>
              <Tooltip
                label={
                  <>
                    <Text>No Data: No data received in the latest poll.</Text>
                    <Text>Error: There was an issue polling your alarm.</Text>
                    <Text>Ok: Alarm thresholds are in acceptable range.</Text>
                    <Text>Alert: Alarm thresholds are currently crossed.</Text>
                  </>
                }>
                <Text>
                  Status
                  <InfoOutlineIcon mb="4px" ml="5px" fontSize="md" />
                </Text>
              </Tooltip>
            </>
          );
        },
        Cell: (rowState) => {
          const check = rowState.row.original?.mostRecentCheck;
          if (check === 'error')
            return (
              <Badge color="red.300" variant="subtle">
                Error Loading Data
              </Badge>
            );
          if (check === 'loading') return <Skeleton w="100px" h="10px" />;
          // Show error message details to superusers only for now. They're verbose and not 'human' friendly at times.
          return badgeForState(check?.state, user.superuser ? check?.errorMessage : null);
        }
      }
    ];

    if (type === 'alarmGroup') {
      base.push({
        id: 'site',
        Header: 'Site Name',
        accessor: (siteAlarm) => siteAlarm.name,
        Cell: (rowState) => {
          return (
            <Link
              onClick={(e) => e.stopPropagation()}
              data-cypress={`alarm-table-row-${rowState.row.original.name.replace(' ', '-')}`}
              to={`/project/${project.id}/site/${rowState.row.original.siteId}`}>
              {rowState.row.original.name} <ExternalLinkIcon mx="2px" />
            </Link>
          );
        }
      });
    } else {
      base.push({
        id: 'alarm',
        Header: 'Alarm',
        accessor: (siteAlarm) => siteAlarm.name,
        Cell: (rowState) => {
          return <>{rowState.row.original.name}</>;
        }
      });
    }

    if (showLastChecked) {
      base.push({
        id: 'lastChecked',
        Header: 'Last Checked',
        accessor: (siteAlarm) => {
          const check = siteAlarm.mostRecentCheck;

          if (check === 'error')
            return (
              <Badge color="red.300" variant="subtle">
                Error Loading Data
              </Badge>
            );
          if (check === 'loading') return <Skeleton w="100px" h="10px" />;

          return check?.checkedAt ? format(new Date(check.checkedAt), 'PP p') : 'Not Checked Yet';
        }
      });
    }

    if (showLastInAlert) {
      base.push({
        id: 'lastAlarm',
        Header: 'Last in Alert',
        accessor: (siteAlarm) => siteAlarm?.lastInAlert,
        Cell: ({ value }: { value?: string }) => {
          if (value === 'error')
            return (
              <Badge color="red.300" variant="subtle">
                Error Loading Data
              </Badge>
            );
          if (value === 'loading') return <Skeleton w="100px" h="10px" />;
          return value ? format(new Date(value), 'PP p') : '-';
        }
      });
    }

    if (onToggleSuspend) {
      base.push({
        id: 'suspend',
        Header: () => {
          return (
            <>
              <Tooltip
                label={
                  <Text>
                    Suspends the alarm. The alarm will{' '}
                    <Text display="inline" fontWeight="bold">
                      not
                    </Text>{' '}
                    be checked while suspended. Alarms may be resumed out of suspension.
                  </Text>
                }>
                <Text>
                  Suspended
                  <InfoOutlineIcon mb="4px" ml="5px" fontSize="md" />
                </Text>
              </Tooltip>
            </>
          );
        },
        accessor: (siteAlarm) => {
          return (
            <IconButton
              data-cypress="suspend-alarm-btn"
              backgroundColor={'transparent'}
              size="sm"
              onClick={(e) => {
                onToggleSuspend({
                  siteAlarmId: siteAlarm.id,
                  alarmGroupId: siteAlarm.alarmGroupId,
                  suspend: !siteAlarm.suspended
                });
                e.stopPropagation();
              }}
              color={siteAlarm.suspended ? 'red.500' : 'green.500'}
              icon={
                !siteAlarm.suspended ? <BiPlayCircle size="25px" /> : <BiPauseCircle size="25px" />
              }
              aria-label={'Suspend Alarm Group'}
            />
          );
        }
      });
    }

    return base;
  }, [type, project.id]);

  return (
    <DataTable
      initSortBy={{ id: 'lastAlarm', desc: true }}
      columns={columns}
      data={siteAlarms}
      onRowClick={(row) => onRowClick(row.original)}
    />
  );
};

export default AlarmTable;
