import React, { CSSProperties } from 'react';

interface ChartErrorTextProps {
  style?: CSSProperties;
}
export const ChartErrorText = ({ style }: ChartErrorTextProps) => {
  const appliedStyle: CSSProperties = {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    display: 'block',
    minHeight: '15rem',
    width: '90%',
    textAlign: 'center',
    fontStyle: 'italic',
    ...(style || {})
  };
  return <span style={appliedStyle}>An error occurred</span>;
};
