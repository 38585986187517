import { Skeleton } from '@chakra-ui/react';
import GraphError from '../GraphError';
import NoData from '../NoData';
import Plot from '../Plot';
import { BaseChartProps } from '../types';
import useTideTrace from './useTideTrace';
import { useContext } from 'react';
import { ProjectContext } from 'contexts/ProjectContext';
import NotIncluded from '../NotIncluded';

const Chart = (props: BaseChartProps) => {
  const projectContext = useContext(ProjectContext);
  const {
    isLoading: tidesLoading,
    error: tidesError,
    plot: tidePlot
  } = useTideTrace({
    dateRange: 'from 3 days ago to 7 days from now',
    settings: {
      props
    },
    ...props
  });

  if (tidesLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (tidesError) {
    return <GraphError minH="450px" />;
  }
  const noData = tidePlot?.data[0]['x'].length === 0;
  return !noData ? (
    <>
      {props.control ? props.control({ lunarParameter: props?.settings?.lunarParameter }) : <></>}
      <Plot className="w-100 tides-plot" useResizeHandler={true} {...tidePlot} />
    </>
  ) : noData && projectContext.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <>
      {props.control ? props.control({ lunarParameter: props?.settings?.lunarParameter }) : <></>}
      <NoData minH="450px" />
    </>
  );
};

export default Chart;
