//HA 1/3/2022:
//IMPORTANT: This  config REFLECTS the metadata of the tilesets we are importing, it does not define them!
//To change a value such a resolution, or time extents, you probably have to change the tileset itself.
//For tileset pipelines we own, that means contacting Data Engineering, for 3rd party tilesets, that
//means finding a new tileset.
//Each layer option provides the info for the DashboardMap to find and render a map layer.
//This info includes:
//   label: Select label for the map layer option
//   value: Object that includes the following info for the DashboardMap:
//      type: 'vector' or 'raster' tileset
//      param: parameter for the given layer
//      URLCode: (Optional) For OWM only, gives a string to be used in the url to fetch the correct tileset
//      res: resolution of vector layer values, used for on click events. 'null' for raster tiles
//      timeslider: Object for the timeslider to use the correct units for this tileset
//         start: default timeslider value, with 0 being the current time (int)
//         min: min timeslider value, with 0 being the current time (int)
//         max: default timeslider value, with 0 being the current time (int)
//         step: step for each option on the timeslider (int)
//         timeunit: unit of time for the timeslider
//      depthslider: Object for the depthslider to use the correct info for this tileset
//      scalelegend:
//         param: parameter to use for legend label and pallette
//         palette: (Optional) if we don't have a default pallette for our 'param', use the pallette specified here

export const getLayerOptions = (layerOptionNames: string[]): LayerOption[] => {
  const options = [];
  AllLayerOptions.forEach((option) => {
    if (Object.keys(option).indexOf('options') > -1) {
      const subGroupOptions = [];
      option.options.forEach((subgroupOption) => {
        if (layerOptionNames.indexOf(subgroupOption.label) > -1) {
          subGroupOptions.push(subgroupOption);
        }
      });
      options.push({
        label: option.label,
        options: subGroupOptions
      });
    } else {
      if (option.label === 'None' || layerOptionNames.indexOf(option.label) > -1) {
        options.push(option);
      }
    }
  });
  return options;
};

export const DefaultLayerOption: LayerOption = {
  label: 'None',
  value: {
    type: null,
    param: null,
    res: null
  }
};

export interface MapLayer {
  type: string;
  URLCode?: string;
  param: string;
  res: number;
  timeslider?: {
    start: number;
    min: number;
    step: number;
    max: number;
    timeunit: string;
  };
  depthslider?: {
    depths: { [key: string]: { [key: number]: string } };
  };
  scalelegend?: {
    param: string;
    palette?: string;
  };
}

export type LayerOption = {
  label: string;
  options?: Array<LayerOption>;
  value?: MapLayer;
};

const AllLayerOptions: LayerOption[] = [
  {
    label: 'Oceanographic',
    options: [
      {
        label: 'Oxygen',
        value: {
          type: 'vector',
          param: 'oxygen_mgl',
          res: 2,
          timeslider: {
            start: 0,
            min: -72,
            step: 12,
            max: 72,
            timeunit: 'hours'
          },
          depthslider: {
            depths: {
              lo: {
                0: 'Surface',
                10: '10m',
                20: '20m',
                30: '30m',
                50: '50m',
                80: 'Bottom'
              },
              no: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              },
              uk: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              }
            }
          },
          scalelegend: { param: 'oxygen_mgl' }
        }
      },
      {
        label: 'Temperature',
        value: {
          type: 'vector',
          param: 'water_temp_c',
          res: 2,
          timeslider: {
            start: 0,
            min: -72,
            step: 12,
            max: 72,
            timeunit: 'hours'
          },
          depthslider: {
            depths: {
              lo: {
                0: 'Surface',
                10: '10m',
                20: '20m',
                30: '30m',
                50: '50m',
                80: 'Bottom'
              },
              no: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              },
              uk: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              }
            }
          },
          scalelegend: { param: 'temp_c' }
        }
      },
      {
        label: 'East Current',
        value: {
          type: 'vector',
          param: 'u_mps',
          res: 2,
          timeslider: {
            start: 0,
            min: -72,
            step: 12,
            max: 72,
            timeunit: 'hours'
          },
          depthslider: {
            depths: {
              lo: {
                0: 'Surface',
                10: '10m',
                20: '20m',
                30: '30m',
                50: '50m',
                80: 'Bottom'
              },
              no: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              },
              uk: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              }
            }
          },
          scalelegend: { param: 'u_mps' }
        }
      },
      {
        label: 'North Current',
        value: {
          type: 'vector',
          param: 'v_mps',
          res: 2,
          timeslider: {
            start: 0,
            min: -72,
            step: 12,
            max: 72,
            timeunit: 'hours'
          },
          depthslider: {
            depths: {
              lo: {
                0: 'Surface',
                10: '10m',
                20: '20m',
                30: '30m',
                50: '50m',
                80: 'Bottom'
              },
              no: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              },
              uk: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              }
            }
          },
          scalelegend: { param: 'v_mps' }
        }
      },
      {
        label: 'Salinity',
        value: {
          type: 'vector',
          param: 'salinity_psu',
          res: 2,
          timeslider: {
            start: 0,
            min: -72,
            step: 12,
            max: 72,
            timeunit: 'hours'
          },
          depthslider: {
            depths: {
              lo: {
                0: 'Surface',
                10: '10m',
                20: '20m',
                30: '30m',
                50: '50m',
                80: 'Bottom'
              },
              no: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              },
              uk: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              }
            }
          },
          scalelegend: { param: 'salinity_psu' }
        }
      },
      {
        label: 'Chlorophyll-A',
        value: {
          type: 'vector',
          param: 'phyto_umol',
          res: 2,
          timeslider: {
            start: 0,
            min: -72,
            step: 12,
            max: 72,
            timeunit: 'hours'
          },
          depthslider: {
            depths: {
              lo: {
                0: 'Surface',
                10: '10m',
                20: '20m',
                30: '30m',
                50: '50m',
                80: 'Bottom'
              },
              no: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              },
              uk: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              }
            }
          },
          scalelegend: { param: 'phyto_umol' }
        }
      },
      {
        label: 'Nitrate',
        value: {
          type: 'vector',
          param: 'nitrate_umol',
          res: 4,
          timeslider: {
            start: 0,
            min: -72,
            step: 12,
            max: 72,
            timeunit: 'hours'
          },
          depthslider: {
            depths: {
              lo: {
                0: 'Surface',
                10: '10m',
                20: '20m',
                30: '30m',
                50: '50m',
                80: 'Bottom'
              },
              no: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              },
              uk: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              }
            }
          },
          scalelegend: { param: 'nitrate_umol' }
        }
      },
      {
        label: 'Aragonite Saturation State',
        value: {
          type: 'vector',
          param: 'aragonite',
          res: 1,
          timeslider: {
            start: 0,
            min: -72,
            step: 12,
            max: 72,
            timeunit: 'hours'
          },
          depthslider: {
            depths: {
              lo: {
                0: 'Surface',
                10: '10m',
                20: '20m',
                30: '30m',
                50: '50m',
                80: 'Bottom'
              },
              no: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              },
              uk: {
                0: 'Surface',
                10: '10m',
                15: '15m',
                25: '25m',
                50: '50m',
                80: 'Bottom'
              }
            }
          },
          scalelegend: { param: 'aragonite' }
        }
      }
    ]
  },
  {
    label: 'Weather',
    options: [
      {
        label: 'Air Temperature',
        value: {
          type: 'raster',
          URLCode: 'TA2',
          param: 'TA2',
          res: null,
          timeslider: {
            start: 0,
            min: -72,
            step: 12,
            max: 72,
            timeunit: 'hours'
          },
          scalelegend: {
            param: 'TA2',
            palette:
              '-20:821692;-11.42:8257DB;-2.84:208CEC;5.74:20C4E8;14.32:23DDDD;22.9:C2FF28;31.48:FFF028;35.77:FFC228;40:FC8014'
          }
        }
      },
      {
        label: 'Precipitation',
        value: {
          type: 'raster',
          URLCode: 'PA0',
          param: 'PA0',
          res: null,
          timeslider: {
            start: 0,
            min: -72,
            step: 12,
            max: 72,
            timeunit: 'hours'
          },
          scalelegend: {
            param: 'PA0',
            palette:
              '0:00000000;0.1:C8969600;0.2:9696AA00;0.5:7878BE19;1:6E6ECD33;10:5050E1B2;140:1414FFE5'
          }
        }
      },
      {
        label: 'Wind',
        value: {
          type: 'raster',
          URLCode: 'WND',
          param: 'WND',
          res: null,
          timeslider: {
            start: 0,
            min: -72,
            step: 12,
            max: 72,
            timeunit: 'hours'
          },
          scalelegend: {
            param: 'WND',
            palette:
              '1:FFFFFF00;1.5:EECECC66;4.5:B364BCB3;7.5:3F213BCC;15:744CACE6;30:4600AFFF;60:0D1126FF'
          }
        }
      },
      {
        label: 'Pressure',
        value: {
          type: 'raster',
          param: 'APM',
          URLCode: 'APM',
          res: null,
          timeslider: {
            start: 0,
            min: -72,
            step: 12,
            max: 72,
            timeunit: 'hours'
          },
          scalelegend: {
            param: 'APM',
            palette:
              '94000:0073FF;96000:00AAFF;98000:4BD0D6;100000:8DE7C7;101000:B0F720;102000:F0B800;104000:FB5515;106000:F3363B;108000:C60000'
          }
        }
      }
    ]
  },
  {
    label: 'None',
    value: {
      type: null,
      param: null,
      res: null
    }
  }
];
