import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Spacer, useRadio, UseRadioProps, Text } from '@chakra-ui/react';
import Shade from 'components/Shade';
import { FC } from 'react';

const RadioCard: FC<
  UseRadioProps & {
    cardProps?: BoxProps;
    boxProps?: BoxProps & { 'data-cypress'?: string };
    checkedColor?: string;
  }
> = ({ children, boxProps, cardProps, checkedColor, ...props }) => {
  const { getInputProps, getRadioProps, state } = useRadio(props);

  const input = getInputProps();
  const radio = getRadioProps();

  return (
    <Box {...boxProps} as="label">
      <Shade shaded={props.isDisabled} shadeContent={<Text color="white">Coming Soon!</Text>}>
        <input {...input} />
        <Box
          {...radio}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="md"
          _checked={{
            bg: checkedColor ?? 'blue.400',
            color: 'white',
            borderColor: 'blue.400'
          }}
          _focus={{
            boxShadow: 'outline'
          }}
          px={5}
          py={3}
          {...cardProps}>
          {state.isChecked ? (
            <CheckCircleIcon boxSize="5" mr="5px" />
          ) : (
            <Spacer display="inline-block" w="25px" h="5px" />
          )}
          {children}
        </Box>
      </Shade>
    </Box>
  );
};

export default RadioCard;
