import { HStack, Box, IconButton } from '@chakra-ui/react';
import { NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react';
import InflatedChartRender from 'components/Site/Tabs/Explore/InflatedChartRender';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ProjectContext } from 'contexts/ProjectContext';
import useExploreCharts from 'components/Site/Tabs/Explore/useExploreCharts';
import TimeFragmentPicker from 'components/Charts/Custom/TimeFragmentPicker';
import { isEqual, orderBy } from 'lodash';
import { DeleteIcon } from '@chakra-ui/icons';
import { Site } from 'graphql/generated';
import BaseSelect from '../BaseSelect';
import { ExploreChart } from 'components/Charts/types';
import ChartWithController from 'components/Site/Tabs/Explore/ChartWithController';
import { IoMdSettings } from 'react-icons/io';
import { utcToZonedTime } from 'date-fns-tz';

export const PlotlyNodeView = ({
  node,
  editor,
  updateAttributes,
  deleteNode
}: NodeViewWrapperProps) => {
  const project = useContext(ProjectContext);

  const [showingControls, setShowingControls] = useState(true);

  const [site, setSite] = useState<Site>(
    node.attrs?.chart?.settings?.siteId
      ? project.sites.find((s) => s.id === node.attrs?.chart?.settings.siteId)
      : null
  );

  const { charts } = useExploreCharts({ site });

  const changeChart = (newChart: ExploreChart) => {
    updateAttributes({
      ...node.attrs,
      chart: {
        ...node.attrs?.chart,
        chartId: newChart.id,
        settings: { ...node.attrs?.chart?.settings, ...newChart.initSettings }
      }
    });
  };

  useEffect(() => {
    if (!node.attrs?.chart?.chartId) changeChart(charts?.[1]);
  }, [node.attrs?.chart?.chartId]);

  const inflateSettings = (settings: any) => ({
    ...settings,
    site,
    project,
    // If in view only mode, use the dates finalized with the chart, not the relative time that may
    // be present
    dateRange: editor.options.editable
      ? settings.dateRange
      : [
          utcToZonedTime(settings?.finalizedDateRange?.dateRange[0], project.timezone),
          utcToZonedTime(settings.finalizedDateRange?.dateRange[1], project.timezone)
        ],
    granularity: editor.options.editable
      ? settings?.granularity
      : settings?.finalizedDateRange?.granularity
  });

  const inflatedSettings = useMemo(() => {
    if (!node.attrs?.chart?.settings) return { project, site };
    return inflateSettings(node.attrs.chart.settings);
  }, [inflateSettings, project, site]);

  const siteOptions = useMemo(
    () => orderBy(project.sites, 'name').map((s) => ({ label: s.name, value: s })),
    [project]
  );

  const chartOptions = useMemo(
    () => charts.map((c) => ({ label: c.displayName, value: c })),
    [charts]
  );

  const selectedChart = useMemo(() => {
    const chart = charts.find((c) => c.id === node.attrs?.chart?.chartId);

    return chart;
  }, [node.attrs?.chart?.chartId]);

  useEffect(() => {
    if (node.attrs?.chart?.settings && !node.attrs.chart.settings?.dateRange) {
      updateAttributes({
        chart: {
          ...node.attrs.chart,
          settings: {
            ...node.attrs.chart.settings,
            dateRange: 'last 7 days until now',
            granularity: 'day'
          }
        }
      });
    }
  }, [node.attrs?.chart]);

  useEffect(() => {
    if (!editor.options.editable) return;
    if (selectedChart.custom) {
      updateAttributes({
        chart: {
          ...node.attrs.chart,
          settings: {
            ...node.attrs.chart.settings,
            dateRange: 'last 7 days until now',
            granularity: undefined
          }
        }
      });
    } else {
      updateAttributes({
        chart: {
          ...node.attrs.chart,
          settings: {
            ...node.attrs.chart.settings,
            dateRange: 'last 7 days until now',
            granularity: 'day'
          }
        }
      });
    }
  }, [selectedChart, editor]);

  return (
    <NodeViewWrapper style={{ margin: '45px 0px' }}>
      <Box p="20px" border="1px solid" borderColor="gray.300">
        {editor.options.editable && (
          <HStack w="100%" justifyContent="space-between">
            <IconButton
              icon={<IoMdSettings />}
              aria-label={'Show/Hide Settings'}
              onClick={() => setShowingControls(!showingControls)}
            />

            {showingControls && (
              <>
                <BaseSelect
                  label="Chart"
                  options={chartOptions}
                  value={chartOptions.find((co) => co.value.id === node.attrs?.chart?.chartId)}
                  onChange={(c) => {
                    changeChart(c.value);
                  }}
                />
                {!selectedChart.custom && (
                  <>
                    <TimeFragmentPicker
                      includeNoGrouping={false}
                      timeFragment={node.attrs?.chart?.settings}
                      onChange={(tf) => {
                        updateAttributes({
                          chart: {
                            ...node.attrs?.chart,
                            settings: { ...node.attrs.chart?.settings, ...tf }
                          }
                        });
                      }}
                    />
                    <BaseSelect
                      isClearable={true}
                      label="Site"
                      options={siteOptions}
                      value={siteOptions.find((so) => so.value.id === site?.id)}
                      onChange={(o) => {
                        setSite(o?.value ?? null);
                        updateAttributes({
                          ...node.attrs,
                          chart: {
                            ...node.attrs?.chart,
                            settings: { ...node.attrs?.chart?.settings, site: o?.value }
                          }
                        });
                      }}
                    />
                    <IconButton
                      icon={<DeleteIcon />}
                      color="red.500"
                      onClick={() => {
                        deleteNode();
                      }}
                      aria-label="Delete Chart"
                    />
                  </>
                )}
              </>
            )}
          </HStack>
        )}

        {editor.options.editable ? (
          <ChartWithController
            exploreChart={selectedChart}
            showSublocations={!!site}
            chartSettings={inflatedSettings}
            showingControls={showingControls}
            timeFragment={node.attrs.chart?.settings}
            setChartSettings={(settings) => {
              updateAttributes({
                ...node.attrs,
                chart: { ...node.attrs?.chart, settings }
              });
            }}
            onDataLoaded={(_resultSets, dateRange) => {
              console.log('onDataLoaded!');
              console.log(dateRange);
              if (
                editor.options.editable &&
                !isEqual(dateRange, node?.attrs?.chart?.settings?.finalizedDateRange?.dateRange)
              ) {
                const chartSettings = {
                  ...node.attrs?.chart,
                  settings: {
                    ...node.attrs.chart?.settings,
                    finalizedDateRange: {
                      granularity: node.attrs.chart?.settings?.granularity,
                      dateRange
                    }
                  }
                };
                updateAttributes({ chart: chartSettings });
              }
            }}
          />
        ) : (
          <InflatedChartRender
            inflatedChart={{
              ...charts.find((c) => c.id === node.attrs.chart.chartId),
              ...node.attrs.chart,
              settings: { ...inflatedSettings, showTitle: true }
            }}
          />
        )}
      </Box>
    </NodeViewWrapper>
  );
};
