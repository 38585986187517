import { gql } from '@apollo/client';

const GET_USERS = gql`
  query GetUsers {
    users {
      id
      email
      superuser
      creationTime
      lastActivity
    }
  }
`;

const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      oryId
      name
      lastVersion
      jobTitle
      avatarUrl
      email
      superuser
      projectAdministrator
      alerts
      asc
      manualHydrography
      read
      events
      equipment
      plankton
      seaLice
      alarmSites
      audioNotifications
      organizations {
        id
        name
        projects {
          id
          name
          latitude
          longitude
        }
      }
    }
  }
`;

const SITE_FRAGMENT = gql`
  fragment Site on Site {
    __typename
    id
    smbId
    name
    lat
    lon
    region
    siteLabel
    hasGdb
    tabs
    sublocations
    sampleLocations
    projectId
    archived
    type
    riverStations
    tideStations
  }
`;

const GET_SITES = gql`
  query sitesOfProject($pid: Int!, $includeArchived: Boolean = false) {
    sites(projectId: $pid, includeArchived: $includeArchived) {
      ...Site
    }
  }
  ${SITE_FRAGMENT}
`;

const GET_SITE = gql`
  query siteById($id: Int!) {
    site(id: $id) {
      ...Site
    }
  }
  ${SITE_FRAGMENT}
`;

const GET_EVENT_NOTIFICATION_EMAILS = gql`
  query getEventNotificationEmails($projectId: Int!) {
    getEventNotificationEmails(projectId: $projectId)
  }
`;

export { CURRENT_USER, GET_EVENT_NOTIFICATION_EMAILS, GET_SITE, GET_SITES, GET_USERS };
