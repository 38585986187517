import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Text,
  VStack
} from '@chakra-ui/react';
import useExploreCube from 'components/Charts/Custom/useExploreCube';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import AlarmEditor from '../AlarmEditor';
import AlarmUnknownDataSelect from '../AlarmUnknownDataSelect';
import { EditAlarmForm } from '../EditAlarmModal';

export type ConfigureAlarmProps = {
  sites: { id: number; name: string; smbId: number; sublocations: string[] }[];
  control: Control<EditAlarmForm>;
};

const DISPLAY_NUM = 3;

const ConfigureAlarm = (props: ConfigureAlarmProps) => {
  const exploreCube = useExploreCube();

  const siteNames = useMemo(() => {
    if (props.sites.length > DISPLAY_NUM) {
      const s = `${props.sites
        .slice(0, 3)
        .map((s) => s.name)
        .join(', ')}... and ${props.sites.length - DISPLAY_NUM} more...`;

      return s;
    } else {
      return props.sites.map((s) => s.name).join(', ');
    }
  }, [props.sites]);

  return (
    <>
      <Center m="20px">
        <Text fontSize="2xl">
          Create an alarm for{' '}
          <Box
            as="u"
            textDecoration={'underline'}
            textDecorationColor="blue.500"
            textDecorationThickness="3px">
            {siteNames}
          </Box>
        </Text>
      </Center>
      <Text mb="10px">
        Configure your alarm. The red shading in the plot(s) below indicate when your alarm would
        enter alert status.
      </Text>
      <VStack>
        <AlarmEditor {...props} {...exploreCube} />
        <Accordion w="100%" allowToggle={true}>
          <AccordionItem>
            <AccordionButton>Advanced settings...</AccordionButton>
            <AccordionPanel>
              <AlarmUnknownDataSelect control={props.control} name={'unknownIntervalSetting'} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>
    </>
  );
};

export default ConfigureAlarm;
