import { UserContext } from 'contexts/UserContext';
import { useContext } from 'react';
import { Navigate } from 'react-router';

const ProjectIndex = () => {
  const currentUser = useContext(UserContext);
  const singleProjectId = currentUser?.projects.length === 1 && currentUser.projects[0].id;
  const userHasSingleProject = !!singleProjectId;

  if (userHasSingleProject && !currentUser.superuser) {
    return <Navigate to={`/project/${singleProjectId}`} replace={true} />;
  } else {
    return <Navigate to={`/project/picker`} replace={true} />;
  }
};

export default ProjectIndex;
