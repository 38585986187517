import { gql, useQuery } from '@apollo/client';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  UseModalProps
} from '@chakra-ui/react';
import { User } from '@scoot/permissions';
import UsershipChart from 'components/Charts/Usership/Chart';
import { ProjectContext } from 'contexts/ProjectContext';
import { sub } from 'date-fns';
import { useContext, useMemo } from 'react';

const GET_USER_ACTIVITY = gql`
  query GetUserActivity($projectId: Int!, $userId: String!, $after: Date!) {
    project(id: $projectId) {
      usership(userId: $userId, start: $after) {
        datetime
        count
      }
    }
  }
`;

const UsershipModal = ({ user, ...props }: { user: User } & UseModalProps) => {
  const project = useContext(ProjectContext);
  const now = useMemo(() => new Date(), []);
  const usershipAfter = useMemo(() => sub(now, { months: 1 }), [now]);

  const { data, loading } = useQuery(GET_USER_ACTIVITY, {
    variables: {
      projectId: project.id,
      userId: user?.oryId,
      after: usershipAfter.toISOString()
    },
    skip: !user
  });

  if (loading) {
    return <Skeleton w="100%" h="200px" />;
  }

  return (
    <Modal size="5xl" {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>User Activity For {user?.email}</ModalHeader>
        <UsershipChart usership={data?.project.usership} chartRange={[usershipAfter, now]} />
      </ModalContent>
    </Modal>
  );
};

export default UsershipModal;
