import { ApolloError, gql, useQuery } from '@apollo/client';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { GetRecentAlarmsQuery } from 'graphql/generated';
import { useContext } from 'react';

const GET_RECENT_ALARMS = gql`
  query GetRecentAlarms($projectId: Int!) {
    alarmStatusCheckRuns(projectId: $projectId, uiFilter: true) {
      id
      state
      checkedAt
      site {
        id
        name
      }
      alarmGroup {
        id
        name
      }
    }
  }
`;

const useAlarmPoller = (): {
  data: GetRecentAlarmsQuery['alarmStatusCheckRuns'];
  previousData?: GetRecentAlarmsQuery['alarmStatusCheckRuns'];
  loading: boolean;
  error?: ApolloError;
} => {
  const userContext = useContext(UserContext);
  const projectContext = useContext(ProjectContext);

  const hasDeps = !!userContext && projectContext?.id;

  const { loading, data, previousData, error } = useQuery<GetRecentAlarmsQuery>(GET_RECENT_ALARMS, {
    variables: { projectId: projectContext?.id },
    pollInterval: 150000,
    skip: !hasDeps
  });

  if (!hasDeps) return { loading: true, data: [] };

  return {
    loading,
    data: data?.alarmStatusCheckRuns ?? [],
    error,
    previousData: previousData?.alarmStatusCheckRuns
  };
};

export default useAlarmPoller;
