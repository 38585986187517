import NotFound from 'components/Pages/NotFound';
import { siteTabs } from 'components/Site/Tabs/tabs';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { Site } from 'graphql/generated';
import { useContext } from 'react';
import { Navigate, useOutletContext, useParams } from 'react-router-dom';

const PaneId = () => {
  const site = useOutletContext<Site>();
  const project = useContext(ProjectContext);
  const user = useContext(UserContext);
  const { paneId, tabId } = useParams();
  const tabList = siteTabs(site, user);

  //Probably a better way of doing this...
  const paneUrl = (paneId: string) => `/project/${project.id}/site/${site.id}/${tabId}/${paneId}`;

  const tab = tabList.find((tab) => tab.id == tabId);

  const pane = tab?.panes.find((p) => p.id === paneId);

  if (!pane) {
    if (tab?.panes.length > 0) {
      return <Navigate to={paneUrl(tab.panes[0].id)} />;
    }
    return <NotFound />;
  }

  //@ts-ignore
  return <pane.element project={project} site={site} />;
};

export default PaneId;
