import { InfoOutlineIcon } from '@chakra-ui/icons';
import { HStack, Text, Textarea, Tooltip, VStack } from '@chakra-ui/react';
import { EquipmentTextState } from 'graphql/generated';

export const TextView = ({ equipmentState }: { equipmentState: EquipmentTextState }) => {
  return (
    <VStack w="200px">
      <HStack>
        <Text>{equipmentState.name}</Text>
        {equipmentState?.description && (
          <Tooltip label={equipmentState.description}>
            <InfoOutlineIcon />
          </Tooltip>
        )}
      </HStack>
      <Textarea isDisabled={true} resize="none" placeholder="Example text..." />
    </VStack>
  );
};
