// @ts-strict
import { gql, useQuery } from '@apollo/client';
import { Skeleton } from '@chakra-ui/react';
import { permissions } from '@scoot/permissions';
import { ChartErrorText } from 'components/Charts/ChartErrorText';
import { ReportListing } from 'components/ReportListing/ReportListing';
import { UserContext } from 'contexts/UserContext';
import { AscReport, Site } from 'graphql/generated';
import { useContext } from 'react';

export const GET_ASC_REPORTS = gql`
  query getASCReports($siteId: Int!) {
    reports: getASCReports(siteId: $siteId) {
      id
      reportTime
      lastUpdated
      reportedBy
      siteId
      formInput
      transactionId
    }
  }
`;

interface AscReportListProps {
  site: Site;
  openReportForm: (report: AscReport | null) => void;
}

export function ASCReportList({ site, openReportForm }: AscReportListProps) {
  const currentUser = useContext(UserContext);
  const reportsQuery = useQuery<{
    reports: AscReport[];
  }>(GET_ASC_REPORTS, {
    variables: { siteId: site.id },
    pollInterval: 600000
  });

  if (reportsQuery.error) {
    return (
      <div className={'flex flex-column items-center'}>
        <ChartErrorText />
      </div>
    );
  }

  if (reportsQuery.loading) {
    return <Skeleton h="800px" />;
  }

  return (
    <ReportListing
      userCanCreateNew={permissions.canSubmitASCForSite(currentUser, site)}
      reports={reportsQuery.data?.reports || []}
      openReportForm={openReportForm}
    />
  );
}
