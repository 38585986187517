import Transfer from 'components/Charts/Transfer/Chart';
// import ChartSwitchPanel from 'components/Charts/ChartSwitchPanel';
import ChartTile from 'components/Charts/ChartTile';
import { useState } from 'react';
import { ChartSettings } from '../../../Charts/Transfer/Chart';
import { BaseChartSettings } from '../../../Charts/types';
import TransferController from '../../../Charts/Transfer/Controller';

// type TransfersProps = BaseChartSettings & ChartSettings & ChartInputs;

const Transfers = (props: BaseChartSettings) => {
  const [TransferParams, setTransferParams] = useState<ChartSettings>({
    site: props.site,
    project: props.project,
    selectedSublocation: { label: '', value: '' },
    useBiomass: false
  });
  return (
    <>
      <ChartTile hash="transfer-history" heading="Transfer History" site={props.site ?? null}>
        <Transfer
          settings={TransferParams}
          control={({ sublocations }) => (
            <TransferController
              chartSettings={{ ...TransferParams }}
              setChartSettings={setTransferParams}
              chartInputs={{ sublocations }}
            />
          )}
        />
      </ChartTile>
    </>
  );
};

export default Transfers;
