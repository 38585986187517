import colormap from 'colormap';
import * as d3 from 'd3';
import React from 'react';
import { Config } from 'shared/Config';
import './Colorbar.css';

const NSHADES = 32;

let ox_colorscale = [
  { index: 0, rgb: [14, 0, 0] },
  { index: 0.13, rgb: [76, 3, 10] },
  { index: 0.25, rgb: [134, 26, 7] },
  { index: 0.38, rgb: [168, 64, 3] },
  { index: 0.5, rgb: [188, 100, 4] },
  { index: 0.63, rgb: [206, 136, 11] },
  { index: 0.75, rgb: [225, 180, 25] },
  { index: 0.88, rgb: [238, 230, 54] },
  { index: 1, rgb: [255, 255, 25] }
];
let risk_colorscale = [
  { index: 0, rgb: [0, 145, 0] },
  { index: 0.13, rgb: [58, 170, 31] },
  { index: 0.25, rgb: [117, 195, 62] },
  { index: 0.38, rgb: [155, 195, 83] },
  { index: 0.5, rgb: [194, 195, 104] },
  { index: 0.63, rgb: [195, 156, 82] },
  { index: 0.75, rgb: [196, 118, 59] },
  { index: 0.88, rgb: [175, 59, 30] },
  { index: 1, rgb: [153, 0, 0] }
];
let WND_colorscale = [
  { index: 0, rgb: [255, 255, 255] },
  { index: 0.025, rgb: [238, 206, 204] },
  { index: 0.075, rgb: [179, 100, 188] },
  { index: 0.125, rgb: [63, 33, 59] },
  { index: 0.25, rgb: [116, 76, 172] },
  { index: 0.5, rgb: [70, 0, 175] },
  { index: 1.0, rgb: [13, 17, 38] }
];
let TA2_colorscale = [
  { index: 0, rgb: [130, 22, 146] },
  { index: 0.143, rgb: [130, 87, 219] },
  { index: 0.286, rgb: [32, 140, 236] },
  { index: 0.429, rgb: [32, 196, 232] },
  { index: 0.571, rgb: [35, 221, 221] },
  { index: 0.714, rgb: [194, 255, 40] },
  { index: 0.857, rgb: [255, 240, 40] },
  { index: 0.929, rgb: [255, 194, 40] },
  { index: 1, rgb: [252, 128, 20] }
];
let PA0_colorscale = [
  { index: 0, rgb: [0, 0, 0] },
  { index: 0.0007, rgb: [200, 150, 150] },
  { index: 0.0014, rgb: [150, 150, 170] },
  { index: 0.0035, rgb: [120, 120, 190] },
  { index: 0.007, rgb: [110, 110, 205] },
  { index: 0.071, rgb: [80, 80, 225] },
  { index: 1, rgb: [20, 20, 255] }
];
let APM_colorscale = [
  { index: 0, rgb: [0, 115, 255] },
  { index: 0.14, rgb: [0, 170, 255] },
  { index: 0.29, rgb: [75, 208, 214] },
  { index: 0.43, rgb: [141, 231, 199] },
  { index: 0.5, rgb: [176, 247, 32] },
  { index: 0.57, rgb: [240, 184, 0] },
  { index: 0.71, rgb: [251, 85, 21] },
  { index: 0.86, rgb: [243, 54, 59] },
  { index: 1, rgb: [198, 0, 0] }
];

const colormaps = {
  temp_c: colormap({
    colormap: 'temperature',
    nshades: NSHADES,
    format: 'rba',
    alpha: 1.0
  }),
  temp_c_ec: colormap({
    colormap: 'temperature',
    nshades: NSHADES,
    format: 'rba',
    alpha: 1.0
  }),
  salinity_psu: colormap({
    colormap: 'salinity',
    nshades: NSHADES,
    format: 'rba',
    alpha: 1.0
  }),
  oxygen_mgl: colormap({
    colormap: ox_colorscale,
    nshades: NSHADES,
    format: 'rba',
    alpha: 1.0
  }),
  oxygen_percent: colormap({
    colormap: ox_colorscale,
    nshades: NSHADES,
    format: 'rba',
    alpha: 1.0
  }),
  'density_kg_m^3': colormap({
    colormap: 'density',
    nshades: NSHADES,
    format: 'rba',
    alpha: 1.0
  }),
  phyto_umol: colormap({
    colormap: 'chlorophyll',
    nshades: NSHADES,
    format: 'rba',
    alpha: 1.0
  }),
  nitrate_umol: colormap({
    colormap: 'turbidity',
    nshades: NSHADES,
    format: 'rba',
    alpha: 1.0
  }),
  aragonite: colormap({
    colormap: 'copper',
    nshades: NSHADES,
    format: 'rba',
    alpha: 1.0
  }),
  WND: colormap({
    colormap: WND_colorscale,
    nshades: NSHADES,
    format: 'rba',
    alpha: 0.6
  }),
  PA0: colormap({
    colormap: PA0_colorscale,
    nshades: NSHADES,
    format: 'rba',
    alpha: 0.6
  }),
  TA2: colormap({
    colormap: TA2_colorscale,
    nshades: NSHADES,
    format: 'rba',
    alpha: 0.3
  }),
  APM: colormap({
    colormap: APM_colorscale,
    nshades: NSHADES,
    format: 'rba',
    alpha: 0.4
  }),
  risk: colormap({
    colormap: risk_colorscale,
    nshades: NSHADES,
    format: 'rba',
    alpha: 1.0
  })
};

colormaps['density_kg_m^3'].reverse();
colormaps['phyto_umol'].reverse();

class Colorbar extends React.Component {
  margin = 12;

  render() {
    return (
      <div>
        <div className="f7" dangerouslySetInnerHTML={this.props.label}></div>
        <div id="colormap-render-area"></div>
      </div>
    );
  }

  componentDidMount() {
    this.componentDidUpdate();
  }
  componentDidUpdate() {
    var w = 300,
      h = 50;
    const margin = this.getMargin();
    let map = colormaps[this.props.param];
    d3.select('div#colormap-render-area div').remove();
    if (!map) {
      return;
    }

    var key = d3
      .select('div#colormap-render-area')
      .append('div')
      .classed('svg-container', true)
      .append('svg')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', '0 0 300 50')
      .classed('svg-content-responsive', true);
    // .append("rect")
    // .classed("rect", true)
    // .attr("width", w)
    // .attr("height", h);

    var legend = key
      .append('defs')
      .append('svg:linearGradient')
      .attr('id', 'gradient')
      .attr('x1', '0%')
      .attr('y1', '100%')
      .attr('x2', '100%')
      .attr('y2', '100%')
      .attr('spreadMethod', 'pad');

    var percent_per_stop = Math.round(100 / NSHADES);
    for (var i = 0; i < NSHADES; i++) {
      var color = `rgb(${map[i][0]}, ${map[i][1]}, ${map[i][2]})`;
      legend
        .append('stop')
        .attr('offset', parseInt(i * percent_per_stop) + '%')
        .attr('stop-color', color)
        .attr('stop-opacity', 1);
    }

    key
      .append('rect')
      .classed('rect', true)
      .attr('width', w - margin)
      .attr('height', h - 30)
      .style('fill', 'url(#gradient)')
      .attr('transform', `translate(${margin / 2}, 10)`);

    var y = d3.scaleLinear().range([w - margin, 0]);

    var domain = this.getDomain();
    y.domain(domain);

    var ticks = this.generateTicks();
    var yAxis = null;
    if (this.props.param === 'risk') {
      var tickLabels = ['High', 'Medium', 'Low'];
      yAxis = d3
        .axisBottom()
        .scale(y)
        .tickValues(ticks)
        .tickFormat(function (d, i) {
          return tickLabels[i];
        });
    } else {
      yAxis = d3.axisBottom().scale(y).tickValues(ticks);
    }

    key
      .append('g')
      .attr('class', 'y axis')
      .attr('transform', `translate(${margin / 2},30)`)
      .call(yAxis)
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0)
      .attr('dy', '.71em')
      .style('text-anchor', 'end')
      .text('axis title');

    return null;
  }

  getDomain() {
    if (this.props.param === 'temp_c') {
      return [Config.MAX_TEMP, Config.MIN_TEMP];
    } else if (this.props.param === 'temp_c_ec') {
      return [Config.MAX_TEMP_EC, Config.MIN_TEMP_EC];
    } else if (this.props.param === 'salinity_psu') {
      return [Config.MAX_SAL, Config.MIN_SAL];
    } else if (this.props.param === 'oxygen_mgl') {
      return [Config.MAX_OX, Config.MIN_OX];
    } else if (this.props.param === 'oxygen_percent') {
      return [Config.MAX_OX_PERCENT, Config.MIN_OX_PERCENT];
    } else if (this.props.param === 'density_kg_m^3') {
      return [Config.MAX_DEN - 1000, Config.MIN_DEN - 1000];
    } else if (this.props.param === 'nitrate_umol') {
      return [Config.MAX_NO3, Config.MIN_NO3];
    } else if (this.props.param === 'phyto_umol') {
      return [Config.MAX_PLANK, Config.MIN_PLANK];
    } else if (this.props.param === 'aragonite') {
      return [Config.MAX_ARAG, Config.MIN_ARAG];
    } else if (this.props.param === 'WND') {
      return [Config.MAX_WND, Config.MIN_WND];
    } else if (this.props.param === 'TA2') {
      return [Config.MAX_TA2, Config.MIN_TA2];
    } else if (this.props.param === 'PA0') {
      return [Config.MAX_PA0, Config.MIN_PA0];
    } else if (this.props.param === 'APM') {
      return [Config.MAX_APM, Config.MIN_APM];
    } else if (this.props.param === 'risk') {
      return [1, 0];
    }
  }

  generateTicks() {
    var min, max;
    min = this.getDomain()[1];
    max = this.getDomain()[0];

    const nTicks = 5;
    const tickRange = max - min;
    const step = Math.floor(tickRange / nTicks);
    var ticks = [max];
    var i = 0;
    while (max - i * step > min) {
      i = i + 1;
      ticks.push(max - i * step);
    }
    ticks.push(min);
    return ticks;
  }

  getMargin() {
    if (this.props.param === 'density_kg_m^3') {
      return 26;
    } else if (this.props.param === 'risk') {
      return 20;
    } else {
      return 14;
    }
  }
}
export default Colorbar;
