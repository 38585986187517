import { siteTabs } from 'components/Site/Tabs/tabs';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { Site } from 'graphql/generated';
import { useContext } from 'react';
import { Navigate, useOutletContext } from 'react-router-dom';

const SiteId = () => {
  const project = useContext(ProjectContext);
  const user = useContext(UserContext);
  const site = useOutletContext<Site>();

  const firstTab =
    project.id === 6
      ? siteTabs(site, user).find((t) => t.displayName === 'Plankton')
      : siteTabs(site, user)[0];

  const paneId = firstTab?.panes?.[0].id;

  let tabUrl = `/project/${project.id}/site/${site.id}/${firstTab.id}`;
  tabUrl = paneId ? `${tabUrl}/${paneId}` : tabUrl;

  return <Navigate to={tabUrl} replace={true} />;
};

export default SiteId;
