import { Skeleton, Text } from '@chakra-ui/react';
import { Notice } from 'components/Notice';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';
import useHydrographyPlot, { ParameterOptions } from './useHydrographyPlot';

export type ChartSettings = BaseChartSettings & {
  useLocalMinMax?: boolean;
  useSensor?: boolean;
  skipForecasting?: boolean;
  parameters: ParameterOptions;
  showParameterSelect?: boolean;
};

const height = '550px';

const Chart = (props: BaseChartProps<ChartSettings>) => {
  const { isLoading, error, hasData, plot } = useHydrographyPlot(props);

  if (!props.settings?.site)
    return (
      <Notice>
        <Text>A site is required to view this chart.</Text>
      </Notice>
    );

  if (isLoading) {
    return <Skeleton minH={height} height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH={height} />;
  }
  return hasData ? (
    <Plot className="w-100 barge-hydrography-plot" useResizeHandler={true} {...plot} />
  ) : plot?.data[0]['x'].length === 0 && props.settings.project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <NoData minH={height} />
  );
};

export default Chart;
