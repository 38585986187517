import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';

type VideoModalProps = {
  modalHeader: string;
  src: string;
  onClose: () => void;
};

const VideoModal = ({ modalHeader, src, onClose }: VideoModalProps) => {
  return (
    <Modal isCentered size={'3xl'} isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">{modalHeader}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <video
            width="900px"
            height="900px"
            src={src}
            controls
            // @ts-ignore
            //eslint-disable-next-line react/no-unknown-property
            playbackRate="2.0"
            controlsList="nodownload"
            autoPlay
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="solid" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VideoModal;
