import { CubeMap } from '@cubejs-client/core';
import { cubejsApi } from 'App';
import { useEffect, useState } from 'react';

const useExploreCube = () => {
  const [exploreMeta, setExploreMeta] = useState<CubeMap | null>();

  useEffect(() => {
    cubejsApi.meta().then((meta) => {
      const originalExplore = meta.cubesMap.Explore;

      const measures = Object.entries(originalExplore.measures).reduce((acc, [key, measure]) => {
        const ogCube = key.replace('Explore.', '').split('_')[0];
        const ogKey = key.replace('Explore.', '').replace('_', '.');
        acc[key] = {
          ...measure,
          original: meta.cubesMap[ogCube].measures[ogKey]
        };

        return acc;
      }, {});

      const dimensions = Object.entries(originalExplore.dimensions).reduce(
        (acc, [key, dimension]) => {
          const ogCube = key.replace('Explore.', '').split('_')[0];
          const ogKey = key.replace('Explore.', '').replace('_', '.');
          acc[key] = {
            ...dimension,
            original: meta.cubesMap[ogCube].dimensions[ogKey]
          };

          return acc;
        },
        {}
      );

      const segments = Object.entries(originalExplore.segments).reduce((acc, [key, segment]) => {
        const ogCube = key.replace('Explore.', '').split('_')[0];
        const ogKey = key.replace('Explore.', '').replace('_', '.');
        acc[key] = {
          ...segment,
          original: meta.cubesMap[ogCube].segments[ogKey]
        };

        return acc;
      }, {});

      setExploreMeta({
        measures,
        dimensions,
        segments
      });
    });
  }, []);

  return { exploreCube: exploreMeta };
};

export default useExploreCube;
