import { useQuery } from '@apollo/client';
import { Box, Button, Flex, Skeleton, TableContainer } from '@chakra-ui/react';
import { permissions } from '@scoot/permissions';
import { ReportListingFilterWidget } from 'components/ReportListing/ReportListingFilterWidget';
import { DataTable } from 'components/Tables/DataTable';
import { UserContext } from 'contexts/UserContext';
import { format } from 'date-fns';
import { GET_EVENTS } from 'graphql/SharedQueries';
import { Event as SeastateEvent, Site } from 'graphql/generated';
import { useContext, useEffect, useState } from 'react';
import { Column } from 'react-table';
import { EventControlFilter } from './EventControls';
import EventForm from './EventForm';

type EventTableProps = {
  site: Site;
  filter: EventControlFilter;
  selectedMonth?: string;
  setSelectedMonth: (string) => void;
};

const EventTable = ({ site, filter, selectedMonth, setSelectedMonth }: EventTableProps) => {
  const userContext = useContext(UserContext);

  const [showForm, setShowForm] = useState(false);
  const [selectedEvent, selectEvent] = useState<SeastateEvent | null>(null);
  const [filteredEvents, setFilteredEvents] = useState<SeastateEvent[]>([]);

  const {
    data: events,
    loading,
    refetch
  } = useQuery<{ getEvents: SeastateEvent[] }>(GET_EVENTS, {
    variables: {
      siteId: site.id
    }
  });

  useEffect(() => {
    if (!events?.getEvents) return;
    let filteredEvents = events.getEvents;

    //JH: This filtering could all be done 'properly' through the
    //react-table api, but I find their api confusing So. ¯\_(ツ)_/¯.
    if (selectedMonth) {
      filteredEvents = filteredEvents.filter((e) => {
        const eventMonth = new Date(e.startTime).toLocaleString('default', {
          month: 'long',
          year: 'numeric'
        });
        return eventMonth.includes(selectedMonth);
      });
    }

    if (filter.startTime) {
      filteredEvents = filteredEvents.filter((e) => new Date(e.startTime) >= filter.startTime);
    }

    if (filter.endTime) {
      filteredEvents = filteredEvents.filter((e) => new Date(e.startTime) <= filter.endTime);
    }

    if (filter?.severities.length > 0) {
      filteredEvents = filteredEvents.filter((e) => filter.severities.includes(e.severity));
    }

    if (filter?.eventTypes.length > 0) {
      filteredEvents = filteredEvents.filter((e) => filter.eventTypes.includes(e.eventType));
    }

    setFilteredEvents(filteredEvents);
  }, [events, selectedMonth, filter]);

  if (loading || !events) {
    return <Skeleton h="800px" />;
  }

  const formatTime = (d) => format(Date.parse(d), 'PP p');

  const columns: Column<SeastateEvent> = [
    {
      id: 'type',
      Header: 'Type',
      accessor: 'eventType'
    },
    {
      id: 'severity',
      Header: 'Severity',
      accessor: 'severity'
    },
    {
      id: 'reported-by',
      Header: 'Reported By',
      accessor: 'creator',
      Cell: ({ value }: { value: string }) =>
        // eslint-disable-next-line react/prop-types
        value.length > 20 ? `${value.substring(0, 20)}...` : value
    },
    {
      id: 'observed',
      Header: 'Observed',
      accessor: 'startTime',
      Cell: ({ value }) => formatTime(value),
      isSortedDesc: true
    },
    {
      id: 'reported-at',
      Header: 'Reported At',
      accessor: 'created',
      Cell: ({ value }) => formatTime(value)
    },
    {
      id: 'details',
      Header: 'Details',
      accessor: 'detail',
      Cell: ({ value }: { value: string }) =>
        // eslint-disable-next-line react/prop-types
        value?.length > 20 ? `${value?.substring(0, 20)}...` : value
    }
  ];

  return (
    <>
      <Flex mt="10px">
        <Box mr="10px">
          {permissions.canManageEventsForSite(userContext, site) && (
            <Button
              w="100%"
              mb="10px"
              colorScheme="blue"
              variant="solid"
              onClick={() => {
                selectEvent(null);
                setShowForm(true);
              }}>
              New Event
            </Button>
          )}
          <ReportListingFilterWidget
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            dates={events.getEvents.map((e) => e.startTime)}
          />
        </Box>
        <TableContainer minH="800px" w="100%">
          <DataTable
            columns={columns}
            data={filteredEvents}
            initSortBy={{ id: 'observed', desc: true }}
            onRowClick={(row) => {
              selectEvent(row.original);
              setShowForm(true);
            }}
          />
        </TableContainer>
      </Flex>
      {showForm && (
        <EventForm
          site={site}
          event={selectedEvent ? filteredEvents.find((e) => e.id === selectedEvent.id) : null}
          onClose={() => setShowForm(false)}
          onCommentSaved={() => refetch()}
        />
      )}
    </>
  );
};

export default EventTable;
