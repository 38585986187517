// import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
// import { RouterHistory } from '@sentry/react/dist/reactrouter';

function initializeSentry() {
  // Do not boot Sentry within Cypress tests.
  //   Not only is it not necessary, it crashes Electron test runners. - MEA
  // @ts-ignore - ignore the global check
  if (typeof window !== 'undefined' && window.Cypress) {
    console.info('Skipping sentry init because Cypress was found');
    return;
  }
  // Making types happy, may need to add routing instrumentation differently (via a Route wrapper) - MEA
  //  Docs have either-or in separate locations. Check below if this does not work:
  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
  // const history = createBrowserHistory() as unknown as RouterHistory;
  Sentry.init({
    dsn: 'https://ec724665f61443289a14177d8e5d2480@o405260.ingest.sentry.io/5270599',
    integrations: [
      // new BrowserTracing({
      //   routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      // }),
      new Sentry.Integrations.Breadcrumbs({
        console: false
      })
    ],
    // We'll only capture errors on 'prod' builds, and not send errors to sentry for development/testing.
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.2 : 1.0,
    environment: process.env.REACT_APP_DASHBOARD_ENV || 'development',
    // TODO - Provide the Release Semver as an env var on build - MEA
    release: 'dashboard@' + process.env.REACT_APP_GIT_SHA || 'development'
  });
}

export { initializeSentry };
