import { isBarge, isPen } from './location';
const pallets = {
  default: {
    barge: [
      '#2e2b28',
      '#3b3734',
      '#474440',
      '#54504c',
      '#6b506b',
      '#ab3da9',
      '#de25da',
      '#eb44e8',
      '#ff80ff'
    ],
    pens: [
      '#CC40A0',
      '#CC4040',
      '#40A0CC',
      '#CCCC40',
      '#CC40CC',
      '#604000',
      '#00CC40',
      '#40CCCC',
      '#4040CC',
      '#A040CC',
      '#006040',
      '#A0A0A0',
      '#CCA040',
      '#A0CC40',
      '#406000',
      '#004060',
      '#40CCA0'
    ],
    unknown: [
      '#fd7f6f',
      '#7eb0d5',
      '#b2e061',
      '#bd7ebe',
      '#ffb55a',
      '#ffee65',
      '#beb9db',
      '#fdcce5',
      '#8bd3c7'
    ],
    site: ['#21130d'],
    plankton: [
      '#00467d',
      '#2474a6',
      '#66a3c8',
      '#f68f84',
      '#c57fa1',
      '#793263',
      '#922e67',
      '#713eb7',
      '#3b4a52',
      '#44607f',
      '#588283',
      '#599575',
      '#809f64'
    ],
    years: [
      '#fde725',
      '#dde318',
      '#bade28',
      '#95d840',
      '#75d054',
      '#56c667',
      '#3dbc74',
      '#29af7f',
      '#20a386',
      '#1f968b',
      '#238a8d',
      '#287d8e',
      '#2d718e',
      '#33638d',
      '#39558c',
      '#404688',
      '#453781',
      '#482576',
      '#481467'
    ],
    mortalityCauses: [
      'rgba(128,0,0,1)', // maroon	#800000
      // 'rgba(139,0,0,1)', // dark red	#8B0000
      // 'rgba(165,42,42,1)', // brown	#A52A2A
      // 'rgba(178,34,34,1)', // firebrick	#B22222
      'rgba(220,20,60,1)', // crimson	#DC143C
      'rgba(255,0,0,1)', // red	#FF0000
      'rgba(255,99,71,1)', // tomato	#FF6347
      // 'rgba(255,127,80,1)', // coral	#FF7F50
      'rgba(205,92,92,1)', // indian red	#CD5C5C
      // 'rgba(240,128,128,1)', // light coral	#F08080
      // 'rgba(233,150,122,1)', // dark salmon	#E9967A
      'rgba(250,128,114,1)', // salmon	#FA8072
      // 'rgba(255,160,122,1)', // light salmon	#FFA07A
      'rgba(255,69,0,1)', // orange red	#FF4500
      // 'rgba(255,140,0,1)', // dark orange	#FF8C00
      'rgba(255,165,0,1)', // orange	#FFA500
      'rgba(255,215,0,1)', // gold	#FFD700
      'rgba(184,134,11,1)', // dark golden rod	#B8860B
      'rgba(218,165,32,1)', // golden rod	#DAA520
      'rgba(238,232,170,1)', // pale golden rod	#EEE8AA
      'rgba(189,183,107,1)', // dark khaki	#BDB76B
      'rgba(240,230,140,1)', // khaki	#F0E68C
      'rgba(128,128,0,1)', // olive	#808000
      'rgba(255,255,0,1)', // yellow	#FFFF00
      'rgba(154,205,50,1)', // yellow green	#9ACD32
      'rgba(85,107,47,1)', // dark olive green	#556B2F
      // 'rgba(107,142,35,1)', // olive drab	#6B8E23
      'rgba(124,252,0,1)', // lawn green	#7CFC00
      // 'rgba(127,255,0,1)', // chartreuse	#7FFF00
      // 'rgba(173,255,47,1)', // green yellow	#ADFF2F
      'rgba(0,100,0,1)', // dark green	#006400
      // 'rgba(0,128,0,1)', // green	#008000
      'rgba(34,139,34,1)', // forest green	#228B22
      'rgba(0,255,0,1)', // lime	#00FF00
      'rgba(50,205,50,1)', // lime green	#32CD32
      'rgba(144,238,144,1)', // light green	#90EE90
      // 'rgba(152,251,152,1)', // pale green	#98FB98
      'rgba(143,188,143,1)', // dark sea green	#8FBC8F
      // 'rgba(0,250,154,1)', // medium spring green	#00FA9A
      // 'rgba(0,255,127,1)', // spring green	#00FF7F
      // 'rgba(46,139,87,1)', // sea green	#2E8B57
      'rgba(102,205,170,1)', // medium aqua marine	#66CDAA
      'rgba(60,179,113,1)', // medium sea green	#3CB371
      'rgba(32,178,170,1)', // light sea green	#20B2AA
      'rgba(47,79,79,1)', // dark slate gray	#2F4F4F
      'rgba(0,128,128,1)', // teal	#008080
      // 'rgba(0,139,139,1)', // dark cyan	#008B8B
      'rgba(0,255,255,1)', // aqua	#00FFFF
      // 'rgba(0,255,255,1)', // cyan	#00FFFF
      // 'rgba(224,255,255,1)', // light cyan	#E0FFFF
      'rgba(0,206,209,1)', // dark turquoise	#00CED1
      'rgba(64,224,208,1)', // turquoise	#40E0D0
      // 'rgba(72,209,204,1)', // medium turquoise	#48D1CC
      'rgba(175,238,238,1)', // pale turquoise	#AFEEEE
      'rgba(127,255,212,1)', // aqua marine	#7FFFD4
      // 'rgba(176,224,230,1)', // powder blue	#B0E0E6
      'rgba(95,158,160,1)', // cadet blue	#5F9EA0
      'rgba(70,130,180,1)', // steel blue	#4682B4
      'rgba(100,149,237,1)', // corn flower blue	#6495ED
      'rgba(0,191,255,1)', // deep sky blue	#00BFFF
      'rgba(30,144,255,1)', // dodger blue	#1E90FF
      'rgba(173,216,230,1)', // light blue	#ADD8E6
      // 'rgba(135,206,235,1)', // sky blue	#87CEEB
      'rgba(135,206,250,1)', // light sky blue	#87CEFA
      // 'rgba(25,25,112,1)', // midnight blue	#191970
      // 'rgba(0,0,128,1)', // navy	#000080
      'rgba(0,0,139,1)', // dark blue	#00008B
      // 'rgba(0,0,205,1)', // medium blue	#0000CD
      'rgba(0,0,255,1)', // blue	#0000FF
      'rgba(65,105,225,1)', // royal blue	#4169E1
      'rgba(138,43,226,1)', // blue violet	#8A2BE2
      'rgba(75,0,130,1)', // indigo	#4B0082
      'rgba(72,61,139,1)', // dark slate blue	#483D8B
      'rgba(106,90,205,1)', // slate blue	#6A5ACD
      // 'rgba(123,104,238,1)', // medium slate blue	#7B68EE
      'rgba(147,112,219,1)', // medium purple	#9370DB
      'rgba(139,0,139,1)', // dark magenta	#8B008B
      // 'rgba(148,0,211,1)', // dark violet	#9400D3
      'rgba(153,50,204,1)', // dark orchid	#9932CC
      // 'rgba(186,85,211,1)', // medium orchid	#BA55D3
      // 'rgba(128,0,128,1)', // purple	#800080
      // 'rgba(216,191,216,1)', // thistle	#D8BFD8
      'rgba(221,160,221,1)', // plum	#DDA0DD
      'rgba(238,130,238,1)', // violet	#EE82EE
      'rgba(255,0,255,1)', // magenta / fuchsia	#FF00FF
      // 'rgba(218,112,214,1)', // orchid	#DA70D6
      'rgba(199,21,133,1)', // medium violet red	#C71585
      'rgba(219,112,147,1)', // pale violet red	#DB7093
      'rgba(255,20,147,1)', // deep pink	#FF1493
      'rgba(255,105,180,1)', // hot pink	#FF69B4
      // 'rgba(255,182,193,1)', // light pink	#FFB6C1
      'rgba(255,192,203,1)', // pink	#FFC0CB
      // 'rgba(250,235,215,1)', // antique white	#FAEBD7
      // 'rgba(245,245,220,1)', // beige	#F5F5DC
      // 'rgba(255,228,196,1)', // bisque	#FFE4C4
      // 'rgba(255,235,205,1)', // blanched almond	#FFEBCD
      // 'rgba(245,222,179,1)', // wheat	#F5DEB3
      // 'rgba(255,248,220,1)', // corn silk	#FFF8DC
      'rgba(255,250,205,1)', // lemon chiffon	#FFFACD
      // 'rgba(250,250,210,1)', // light golden rod yellow	#FAFAD2
      // 'rgba(255,255,224,1)', // light yellow	#FFFFE0
      'rgba(139,69,19,1)', // saddle brown	#8B4513
      // 'rgba(160,82,45,1)', // sienna	#A0522D
      'rgba(210,105,30,1)', // chocolate	#D2691E
      'rgba(205,133,63,1)', // peru	#CD853F
      'rgba(244,164,96,1)', // sandy brown	#F4A460
      // 'rgba(222,184,135,1)', // burly wood	#DEB887
      'rgba(210,180,140,1)', // tan	#D2B48C
      'rgba(188,143,143,1)', // rosy brown	#BC8F8F
      'rgba(255,228,181,1)', // moccasin	#FFE4B5
      // 'rgba(255,222,173,1)', // navajo white	#FFDEAD
      'rgba(255,218,185,1)', // peach puff	#FFDAB9
      'rgba(255,228,225,1)', // misty rose	#FFE4E1
      'rgba(255,240,245,1)', // lavender blush	#FFF0F5
      // 'rgba(250,240,230,1)', // linen	#FAF0E6
      // 'rgba(253,245,230,1)', // old lace	#FDF5E6
      // 'rgba(255,239,213,1)', // papaya whip	#FFEFD5
      // 'rgba(255,245,238,1)', // sea shell	#FFF5EE
      // 'rgba(245,255,250,1)', // mint cream	#F5FFFA
      'rgba(112,128,144,1)', // slate gray	#708090
      // 'rgba(119,136,153,1)', // light slate gray	#778899
      'rgba(176,196,222,1)', // light steel blue	#B0C4DE
      'rgba(230,230,250,1)', // lavender	#E6E6FA
      // 'rgba(255,250,240,1)', // floral white	#FFFAF0
      // 'rgba(240,248,255,1)', // alice blue	#F0F8FF
      // 'rgba(248,248,255,1)', // ghost white	#F8F8FF
      // 'rgba(240,255,240,1)', // honeydew	#F0FFF0
      // 'rgba(255,255,240,1)', // ivory	#FFFFF0
      // 'rgba(240,255,255,1)', // azure	#F0FFFF
      // 'rgba(255,250,250,1)', // snow	#FFFAFA
      'rgba(0,0,0,1)', // black	#000000
      // 'rgba(105,105,105,1)', // dim gray / dim grey	#696969
      'rgba(128,128,128,1)', // gray / grey	#808080
      'rgba(169,169,169,1)', // dark gray / dark grey	#A9A9A9
      'rgba(192,192,192,1)', // silver	#C0C0C0
      'rgba(211,211,211,1)', // light gray / light grey	#D3D3D3
      // 'rgba(220,220,220,1)', // gainsboro	#DCDCDC
      'rgba(245,245,245,1)' // white smoke	#F5F5F5
      // 'rgba(255,255,255,1)' // white	#FFFFFF
    ]
  }
};

type CreateLocationPalletArgs = {
  locations: string[];
  palletName?: keyof typeof pallets;
  palletType?: keyof typeof pallets.default;
};

const createLocationPallet = ({
  palletName = 'default',
  palletType = 'unknown',
  locations
}: CreateLocationPalletArgs) => {
  const pallet = pallets[palletName];
  let bargeIndex = 0;
  let planktonIndex = 0;
  let unknownIndex = 0;
  let siteIndex = 0;
  let yearsIndex = 0;
  let mortalityCausesIndex = 0;

  const chartPallet = locations.reduce((currentPallet, location) => {
    if (isPen(location)) {
      let cageNum = parseInt(location.replace(/\D+/g, ''));
      // Adjust 1xx's cages to map to non-1xx cages
      cageNum = cageNum > 100 ? cageNum + 2 : cageNum;
      currentPallet[location] = pallet.pens[cageNum % pallet.pens.length];
    } else if (isBarge(location)) {
      currentPallet[location] = pallet.barge[bargeIndex % pallet.barge.length];
      bargeIndex++;
    } else if (location.includes('site')) {
      currentPallet[location] = pallet.site[siteIndex % pallet.site.length];
      siteIndex++;
    } else if (palletType == 'plankton') {
      currentPallet[location] = pallet.plankton[planktonIndex % pallet.plankton.length];
      planktonIndex++;
    } else if (palletType == 'years') {
      currentPallet[location] = pallet.years[yearsIndex % pallet.years.length];
      yearsIndex++;
    } else if (palletType == 'mortalityCauses') {
      currentPallet[location] =
        pallet.mortalityCauses[mortalityCausesIndex % pallet.mortalityCauses.length];
      mortalityCausesIndex++;
    } else {
      currentPallet[location] = pallet.unknown[unknownIndex % pallet.unknown.length];
      unknownIndex++;
    }

    return currentPallet;
  }, {});

  return chartPallet;
};

export const siwiPallet = {
  Mortality: '#660981',
  Oxygen: '#363859',
  Temperature: '#425c75',
  'Plankton Toxic': '#3a5550',
  'Plankton Mechanical': '#5c94a3',
  Lice: '#8fb8bd',
  Salinity: '#9cc9bf',
  Appetite: '#ebcb5d',
  Disturbance: '#aa8711',
  Stocking: '#dc8e0b',
  ko: '#80be4d25',
  cat_Mortality: '#660981',
  cat_Environmental: '#5c94a3',
  cat_Operational: '#dc8e0b'
};

export const trafficLightPallet = {
  0.0: '#38A169',
  0.1: '#48BB78',
  0.2: '#68D391',
  0.3: '#9AE6B4',
  0.4: '#FAF089',
  0.5: '#F6E05E',
  0.6: '#FBD38D',
  0.7: '#F6AD55',
  0.8: '#FC8181',
  0.9: '#F56565',
  1.0: '#E53E3E'
};

export { createLocationPallet };
