import { gql, useMutation, useQuery } from '@apollo/client';
import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
  useRadioGroup,
  useToast
} from '@chakra-ui/react';
import FormInput from 'components/Forms/FormInput';
import FormSelect from 'components/Forms/FormSelect';
import FormTextarea from 'components/Forms/FormTextarea';
import RadioCard from 'components/Forms/RadioCard';
import { ProjectContext } from 'contexts/ProjectContext';
import { SaveSiteEquipmentMutation, SaveSiteEquipmentMutationVariables } from 'graphql/generated';
import { useContext, useMemo, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { MdClear } from 'react-icons/md';

const GET_EQUIPMENT_TYPES = gql`
  query GetEquipmentTypesForAssignment($projectId: Int!) {
    equipmentTypes(projectId: $projectId, includeApiManaged: false) {
      id
      name
    }
  }
`;

const GET_SITES = gql`
  query GetSitesForEquipmentAssignment($projectId: Int!) {
    sites(projectId: $projectId, order: { field: "name", order: ASC }) {
      id
      name
      sublocations
    }
  }
`;

const ASSIGN_EQUIPMENT = gql`
  mutation SaveSiteEquipment($siteEquipment: SiteEquipmentInput!) {
    saveSiteEquipment(siteEquipment: $siteEquipment)
  }
`;

interface AssignEquipmentModalProps {
  onClose: () => void;
}

interface AssignEquipmentForm {
  equipment?: number;
  site?: number;
  name: string;
  details: string;
  sublocation?: { label: string; value: string };
}

export const AssignEquipmentModal = ({ onClose }: AssignEquipmentModalProps) => {
  const project = useContext(ProjectContext);
  const toast = useToast();
  const { data: equipmentData, loading } = useQuery(GET_EQUIPMENT_TYPES, {
    variables: { projectId: project.id }
  });
  const { data: siteData } = useQuery(GET_SITES, { variables: { projectId: project.id } });
  const [saveSiteEquipment] = useMutation<
    SaveSiteEquipmentMutation,
    SaveSiteEquipmentMutationVariables
  >(ASSIGN_EQUIPMENT, { refetchQueries: ['GetEquipmentAssignmentsForProject'] });
  const [siteFilter, setSiteFilter] = useState('');
  const [equipmentFilter, setEquipmentFilter] = useState('');

  const filteredEquipment = useMemo(() => {
    return equipmentFilter
      ? equipmentData?.equipmentTypes.filter((e) =>
          e.name.toLowerCase().includes(equipmentFilter.toLowerCase())
        )
      : equipmentData?.equipmentTypes ?? [];
  }, [equipmentData, equipmentFilter]);

  const filteredSites = useMemo(() => {
    return siteFilter
      ? siteData?.sites.filter((s) => s.name.toLowerCase().includes(siteFilter.toLowerCase()))
      : siteData?.sites ?? [];
  }, [siteData, siteFilter]);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = useForm<AssignEquipmentForm>();

  const { fieldState: equipmentField } = useController({
    control,
    name: 'equipment',
    rules: { required: 'Equipment selection is required.' }
  });
  const { fieldState: siteField } = useController({
    control,
    name: 'site',
    rules: { required: 'Site selection is required.' }
  });

  const { field: equipment } = useController({ control, name: 'equipment' });
  const { getRadioProps: getEquipmentRadioProps } = useRadioGroup({
    value: equipment?.value?.toString(),
    onChange: (value) => equipment.onChange(Number(value))
  });

  const { field: site } = useController({ control, name: 'site' });
  const { getRadioProps: getSiteRadioProps } = useRadioGroup({
    value: site?.value?.toString(),
    onChange: (value) => site.onChange(Number(value))
  });

  const selectedSiteSublocationOptions = useMemo(() => {
    return (
      siteData?.sites
        .find((s) => s.id === site?.value)
        ?.sublocations.map((s) => ({ label: s, value: s })) ?? []
    );
  }, [site?.value, siteData]);

  const submit = async (form: AssignEquipmentForm) => {
    try {
      const resp = await saveSiteEquipment({
        variables: {
          siteEquipment: {
            siteId: form.site,
            details: form.details,
            name: form.name,
            equipmentTypeId: form.equipment,
            sublocation: form?.sublocation?.value
          }
        }
      });

      if (!resp.data.saveSiteEquipment) throw Error('Error saving equipment assignment');
      toast({
        status: 'success',
        description: 'Assigned equipment successfully.'
      });
      onClose();
    } catch (e: any) {
      console.error(e);
      toast({
        status: 'error',
        description: 'Error saving equipment assignment.'
      });
    }
  };

  return (
    <Modal isOpen={true} size="4xl" onClose={onClose}>
      <ModalOverlay />
      <form data-cyrpess="assign-equipment-form" onSubmit={handleSubmit(submit)}>
        <ModalContent>
          <ModalHeader>
            Assign Equipment
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody w="100%">
            <HStack>
              {equipmentData?.equipmentTypes.length > 0 && !loading ? (
                <Box w="50%">
                  <FormLabel>Select Equipment</FormLabel>
                  <InputGroup mb="15px" w="100%">
                    <Input
                      data-cypress="equipment-type-filter"
                      placeholder="Filter Equipment Name..."
                      value={equipmentFilter}
                      onChange={(e) => {
                        setEquipmentFilter(e.currentTarget.value);
                      }}
                    />
                    <InputRightElement>
                      <IconButton
                        size="xs"
                        aria-label="Clear filter"
                        icon={<MdClear />}
                        onClick={() => setEquipmentFilter('')}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <Box
                    h="300px"
                    p="10px"
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor={equipmentField.error ? 'red.500' : 'gray.200'}
                    overflowY="auto">
                    <VStack w="100%">
                      {filteredEquipment.map((et, i) => {
                        return (
                          <RadioCard
                            boxProps={{
                              'data-cypress': `equipment-type-${i}`,
                              w: '100%',
                              position: 'relative'
                            }}
                            key={et.id}
                            {...getEquipmentRadioProps({ value: et.id.toString() })}>
                            {et.name}
                          </RadioCard>
                        );
                      })}
                    </VStack>
                  </Box>
                  {equipmentField.error && (
                    <Text color="red.500">{equipmentField.error.message}</Text>
                  )}
                </Box>
              ) : (
                <Box w="50%" textAlign={'center'}>
                  <Text>No Equipment Types found.</Text>
                  <a href={`/project/${project.id}/admin/equipment/manage`}>
                    Try creating some to get started.
                  </a>
                </Box>
              )}
              <Box w="50%">
                <FormLabel>Select Site</FormLabel>
                <InputGroup w="100%" mb="15px">
                  <Input
                    placeholder="Filter Site Name..."
                    onChange={(e) => {
                      setSiteFilter(e.currentTarget.value);
                    }}
                    value={siteFilter}
                  />
                  <InputRightElement p="15px">
                    <IconButton
                      size="xs"
                      aria-label="Clear filter"
                      icon={<MdClear />}
                      onClick={() => setSiteFilter('')}
                    />
                  </InputRightElement>
                </InputGroup>
                <Box
                  h="300px"
                  p="10px"
                  borderRadius="md"
                  borderWidth="1px"
                  borderColor={siteField.error ? 'red.500' : 'gray.200'}
                  overflowY="auto">
                  <VStack w="100%">
                    {filteredSites.map((s) => {
                      return (
                        <RadioCard
                          boxProps={{
                            'data-cypress': `site-${s.id}`,
                            w: '100%',
                            position: 'relative'
                          }}
                          key={s.id}
                          {...getSiteRadioProps({ value: s.id.toString() })}>
                          {s.name}
                        </RadioCard>
                      );
                    })}
                  </VStack>
                </Box>
                {siteField.error && <Text color="red.500">{siteField.error.message}</Text>}
              </Box>
            </HStack>
            <Divider my="15px" />
            <Box mx="auto" w="50%">
              <FormLabel mb="0px">Name</FormLabel>
              <FormInput
                data-cypress="site-equipment-name"
                placeholder="Name of equipment on site"
                control={control}
                name="name"
                rules={{
                  required: 'An equipment name is required',
                  minLength: { value: 1, message: 'An equipment name is required' }
                }}
                borderColor={errors?.name ? 'red.500' : undefined}
              />
              {errors?.name && <Text color="red.500">{errors.name.message}</Text>}
              <Spacer mt="15px" />
              <FormTextarea
                data-cypress="site-equipment-details"
                resize="none"
                label="Details"
                placeholder="Optional details. Serial number, identifier, etc..."
                control={control}
                name="details"
              />
              <FormSelect
                data-cypress="site-equipment-sublocation"
                label="Sublocation"
                placeholder="Optional sublocation"
                control={control}
                name="sublocation"
                options={selectedSiteSublocationOptions}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <VStack w="100%">
              <ButtonGroup w="100%" justifyContent="space-between">
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  data-cypress="submit-site-equipment"
                  type="submit"
                  isDisabled={isSubmitting}
                  leftIcon={<AddIcon mb="2px" />}
                  colorScheme="green">
                  Assign
                </Button>
              </ButtonGroup>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};
