import { Skeleton } from '@chakra-ui/react';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, uniq } from 'lodash';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { MOTILE_LICE_STAGE_IDS_PROJECT_ID } from 'shared/Utils';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot, { plotDates } from '../Plot';
import { BaseChartProps } from '../types';

type LiceCubeDatum = {
  'TessLiceLookup.sublocation'?: string;
  'Site.id'?: string;
  'TessLice.measuredAt': string;
  'TessLice.measuredAt.week': string;
  'TessLice.measuredAt.day': string;
  'TessLiceLookup.stageId': string;
  'TessLice.avgLicePerFish': number;
  'TessLice.sum': number;
  'TessLice.avgFishCount': number;
};

// Target data structure for plot output
type LiceStructure = {
  [sublocation: string]: {
    [measuredAt: string]: {
      cageAvgLice: number;
      cageSumLice: number;
      cageAvgFish: number;
    };
  };
};

type SiteAvgLiceStructure = {
  [measuredAt: string]: number[];
};

const Chart = ({
  settings,
  granularity = 'day',
  dateRange = 'from 30 days ago to 1 day from now',
  chartRange,
  skip,
  onDataLoaded
}: BaseChartProps) => {
  const locationDimension = settings.site?.smbId ? 'TessLiceLookup.sublocation' : 'Site.id';

  const timeDimension = `TessLice.measuredAt.${granularity}`;
  const transform = (rawData: LiceCubeDatum[]): LiceStructure => {
    let uniqueDates = [];
    rawData.map((d) => {
      uniqueDates.push(d[timeDimension]);
    });
    uniqueDates = uniq(uniqueDates);
    // Plot by sublocation
    const bySublocation = groupBy(rawData, locationDimension);
    const sublocArrays = Object.entries(bySublocation)
      .sort((one, two) => (one > two ? 1 : -1))
      .reduce((acc, [sublocation, dataAtLocation]) => {
        const byHour = dataAtLocation.reduce((timeAcc, datapoint) => {
          const avg = Number(datapoint['TessLice.avgLicePerFish']) ?? null;
          timeAcc[datapoint[timeDimension]] = avg.toFixed(2);
          return timeAcc;
        }, {});
        acc[sublocation] = byHour;
        return acc;
      }, {});
    const siteAvgArrays: SiteAvgLiceStructure = uniqueDates.reduce((map, d) => {
      map[d] = [];
      return map;
    }, {});
    Object.values(sublocArrays).map((sublocationValues) => {
      Object.entries(sublocationValues).map(([time, value]) => {
        siteAvgArrays[time].push(Number(value));
      });
    });
    const siteAvgArray = Object.entries(siteAvgArrays).reduce((timeAcc, [time, vals]) => {
      const sum = vals.filter(Number).reduce((a, b) => a + b, 0);
      timeAcc[time] = (sum / vals.length || 0).toFixed(2);
      return timeAcc;
    }, {});
    sublocArrays['site-average'] = siteAvgArray;
    return sublocArrays;
  };

  const graph = (data: LiceStructure): PlotlyDataLayoutConfig => {
    const locations = uniq(
      Object.keys(data).sort((a, b) => locationToIndex(a) - locationToIndex(b))
    );
    const pallet = createLocationPallet({ locations: [...locations, 'All locations'] });
    //@ts-ignore
    const plotData: Data[] = Object.keys(data)
      .sort((a, b) => locationToIndex(a) - locationToIndex(b))
      .map((sublocation) => {
        const sublocationPlot = {
          mode: 'lines+markers',
          // type: 'bar',
          type: 'scattergl',
          x: Object.keys(data[sublocation]),
          y: Object.values(data[sublocation]),
          // name: sublocation,
          name: settings.site?.smbId
            ? sublocation
            : settings.project.siteNameMappings[sublocation]
              ? settings.project.siteNameMappings[sublocation]
              : sublocation == 'site-average'
                ? 'Average'
                : 'unknown',
          legendgroup: sublocation,
          marker: {
            color: pallet[sublocation]
          },
          line: {
            dash: sublocation.includes('site') ? 'dash' : 'solid',
            width: sublocation.includes('site') ? 3 : 2
          }
        };
        return sublocationPlot;
      });

    const title = settings?.site
      ? `Motile Lice Per Fish - ${settings.site.name}`
      : `Motile Lice Per Fish - All Sites`;

    const byLocation = settings?.site ? 'Cage' : 'Site';

    const [minDate, maxDate] = plotDates(plotData);

    return {
      data: plotData,
      layout: {
        title: {
          text: title,
          y: 1
        },
        autosize: true,
        showlegend: true,
        hovermode: 'x unified',
        yaxis: {
          hoverformat: '.2f',
          showticklabels: true,
          showline: true,
          rangemode: 'tozero',
          title: {
            text: `Average Motile Lice per Fish By ${byLocation}`
          }
        },
        xaxis: {
          title: `${minDate} - ${maxDate} by ${granularity}`,
          range: chartRange
        },
        legend: {
          orientation: 'h',
          x: 0,
          y: 1.25
        }
        //@ts-ignore
        // annotations
      }
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      timeDimensions: [{ dimension: 'TessLice.measuredAt', granularity, dateRange }],
      dimensions: [locationDimension],
      measures: ['TessLice.avgLicePerFish', 'TessLice.sum', 'TessLice.avgFishCount'],
      filters: settings.site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [settings.site?.smbId.toString()]
            },
            { member: 'TessLice.liceCount', operator: 'gte', values: ['0'] },
            {
              member: 'TessLiceLookup.stageId',
              operator: 'equals',
              values: MOTILE_LICE_STAGE_IDS_PROJECT_ID[settings.project.id]
            }
          ]
        : [
            { member: 'TessLice.liceCount', operator: 'gte', values: ['0'] },
            {
              member: 'TessLiceLookup.stageId',
              operator: 'equals',
              values: MOTILE_LICE_STAGE_IDS_PROJECT_ID[settings.project.id]
            }
          ],
      timezone: settings.project.timezone
      // ungrouped: true
    },
    transform,
    graph,
    options: {
      refreshInterval: 900000,
      skip,
      onDataLoaded,
      dependencies: { chartRange }
    }
  });

  return isLoading ? (
    <Skeleton minH="450px" height="100%" width="100%" />
  ) : error && !settings.project.freeTrial ? (
    <GraphError minH="450px" />
  ) : plot?.data?.length > 1 ? (
    <Plot className="w-100 weekly-average-lice-plot" {...plot} useResizeHandler={true} />
  ) : settings.project.freeTrial ? (
    <NotIncluded minH="500px" />
  ) : (
    <NoData minH="500px" />
  );
};

export default Chart;
