import { HStack, StackProps } from '@chakra-ui/react';
import ChartSelect, { ChartSelectProps } from './ChartSelect';
import ChartSwitch, { ChartSwitchProps } from './ChartSwitch';

type ChartSwitchPanelProps = {
  switches?: ChartSwitchProps[];
  selects?: ChartSelectProps[];
} & StackProps;

const ChartSwitchPanel = ({ switches = [], selects = [], ...props }: ChartSwitchPanelProps) => {
  return (
    <HStack alignItems="center" justifyContent="space-evenly" {...props}>
      {switches.map((csp, i) => (
        <ChartSwitch key={i} {...csp} />
      ))}

      {selects.map((csp, i) => (
        <ChartSelect key={i} {...csp} />
      ))}
    </HStack>
  );
};

export default ChartSwitchPanel;
