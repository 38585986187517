import { gql, useQuery } from '@apollo/client';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Divider,
  HStack,
  Skeleton,
  Text
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import MarkdownView from 'react-showdown';
import './ReleaseNotes.css';
import Select from 'react-select';

const RELEASE_NOTES_QUERY = gql`
  query ReleaseNotes {
    releaseNotes {
      version
      content
    }
  }
`;

const ReleaseNotes = () => {
  const { loading, data, error } = useQuery(RELEASE_NOTES_QUERY);

  const [selectedVersion, setSelectedVersion] = useState<{ value: string; label: string }>(null);

  useEffect(() => {
    if (data?.releaseNotes && data.releaseNotes.length > 0) {
      const current = data.releaseNotes[0].version;
      setSelectedVersion({ value: current, label: `${current} (Current)` });
    }
  }, [data]);

  if (loading) {
    return <Skeleton w="100%" h="600px" />;
  }

  if (error) {
    return (
      <Alert
        status="warning"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center">
        <AlertIcon boxSize={'40px'} />
        <AlertTitle mt="10px" fontSize="xl">
          Unexpected Error
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          <Text>{`We're sorry, we encountered an unexpected error loading our release notes.`}</Text>
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <Box>
      <HStack justifyContent="center">
        <Select
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              width: '200px'
            })
          }}
          //@ts-ignore
          onChange={(option) => setSelectedVersion(option)}
          //@ts-ignore
          value={selectedVersion}
          options={data?.releaseNotes.map((d, i) => ({
            label: i === 0 ? `${d.version} (Current)` : d.version,
            value: d.version
          }))}
        />
      </HStack>
      {data?.releaseNotes?.length > 0 && selectedVersion && (
        <Box data-cypress="release-notes" className="release-notes">
          <MarkdownView
            markdown={data.releaseNotes.find((n) => n.version === selectedVersion.value).content}
            options={{ tables: true, emoji: true }}
            flavor="github"
          />
          <Divider mt="10px" mb="10px" />
        </Box>
      )}
    </Box>
  );
};

export default ReleaseNotes;
