import { gql, useMutation } from '@apollo/client';
import { User } from '@scoot/permissions';
import EditUserModal from 'components/Admin/EditUserModal';
import { Navbar } from 'components/Navbar/Navbar';
import ReleaseNotesModal from 'components/Navbar/ReleaseNotesModal';
import config from 'config';
import { UserContext } from 'contexts/UserContext';
import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocalStorage } from 'shared/LocalStorage';

const CURRENT_VERSION = config?.SEASTATE_VERSION;

const USER_VERSION_AKNOWLEDGE = gql`
  mutation AknowledgeVersion($userId: String!, $version: String!) {
    updateUser(userId: $userId, attributes: { lastVersion: $version }) {
      oryId
      lastVersion
    }
  }
`;

const areDifferentMinors = (versionOne?: string, versionTwo?: string) => {
  const versionOneParts = versionOne?.split('.') ?? [];
  const versionTwoParts = versionTwo?.split('.') ?? [];

  if (versionOneParts.length > 1 && versionTwoParts.length > 1) {
    return !(
      versionOneParts[0] === versionTwoParts[0] && versionOneParts[1] === versionTwoParts[1]
    );
  }

  return false;
};

const MainLayout = () => {
  const currentUser = useContext(UserContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [forcePasswordReset, _] = useLocalStorage('forcePasswordReset', false);
  const [aknowledgeReleaseMut] = useMutation(USER_VERSION_AKNOWLEDGE);
  const [showingUserAccount, setShowingUserAccount] = useState(forcePasswordReset);
  const [showingReleaseNotes, setShowingReleaseNotes] = useState(false);

  const aknowledgeRelease = () => {
    aknowledgeReleaseMut({
      variables: {
        userId: currentUser.oryId,
        version: CURRENT_VERSION
      }
    });

    setShowingReleaseNotes(false);
  };

  useEffect(() => {
    if (!currentUser) return;
    if (
      (areDifferentMinors(currentUser?.lastVersion, CURRENT_VERSION) ||
        currentUser?.lastVersion === '') &&
      // @ts-ignore
      !window.Cypress
    ) {
      setShowingReleaseNotes(true);
    }
  }, [currentUser]);

  return (
    <>
      <Navbar
        showUserEdit={() => setShowingUserAccount(true)}
        showReleaseNotes={() => setShowingReleaseNotes(true)}
      />

      <Outlet />

      <ReleaseNotesModal isOpen={showingReleaseNotes} onClose={aknowledgeRelease} />
      {showingUserAccount && currentUser && (
        <EditUserModal
          showAdminPanel={false}
          showPasswordPanel={true}
          defaultUser={currentUser as User}
          onClose={() => setShowingUserAccount(null)}
        />
      )}
    </>
  );
};

export default MainLayout;
