import { Text, VStack } from '@chakra-ui/layout';
import { Heading } from '@chakra-ui/react';
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import { permissions } from '@scoot/permissions';
import { Notice } from 'components/Notice';
import NotFound from 'components/Pages/NotFound';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { PlanktonPolicy } from 'graphql/generated';
import { sortBy } from 'lodash';
import { useContext } from 'react';

export const ProjectPlankton = () => {
  const project = useContext(ProjectContext);
  const user = useContext(UserContext);

  if (!permissions.canAdminProject(user, project.id)) {
    return <NotFound />;
  }

  const PlanktonPolicyTable = ({
    idName,
    policies
  }: {
    idName: string;
    policies: ({ name: string; key: string } & PlanktonPolicy)[];
  }) => (
    <TableContainer w="100%">
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>{idName}</Th>
            <Th color="orange.500">Caution</Th>
            <Th color="red.500">Danger</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortBy(policies, 'name').map((p) => (
            <Tr key={p.key}>
              <Td>{p.name}</Td>
              <Td>{p.caution}</Td>
              <Td>{p.danger}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );

  return (
    <VStack mb="100px">
      <Text fontSize="4xl" margin="1em 0">
        Plankton Configuration
      </Text>
      <Text>The following are the plankton thresholds configured for your project.</Text>
      <Text>Please contact Scoot if you would like your plankton thresholds adjusted.</Text>
      {project.planktonPolicy.length === 0 ? (
        <Notice>
          <Text>No plankton currently configured for your project.</Text>
        </Notice>
      ) : (
        <>
          <Heading pt="30px" fontWeight="medium">
            Generic
          </Heading>
          <PlanktonPolicyTable
            idName={'Name'}
            policies={project.planktonPolicy
              .filter((pp) => pp.generic)
              .map((g) => ({ name: g.generic.name, key: g.generic.key, ...g }))}
          />

          <Heading pt="30px" fontWeight="medium">
            Species
          </Heading>
          <PlanktonPolicyTable
            idName={'Species'}
            policies={project.planktonPolicy
              .filter((pp) => pp.species)
              .map((s) => ({ name: s.species.species, key: s.species.key, ...s }))}
          />
        </>
      )}
    </VStack>
  );
};
