import BaseSelect from 'components/Forms/BaseSelect';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';

const Controller = ({ chartSettings, setChartSettings }: ChartController<ChartSettings>) => {
  const variableOptions = [
    {
      label: 'Water Temperature',
      value: 'waterTempAvg' as const
    },
    {
      label: 'Oxygen Saturation (%)',
      value: 'oxygenSaturationAvg' as const
    },
    {
      label: 'Oxygen Saturation (mgl)',
      value: 'oxygenConcentrationAvg' as const
    },
    {
      label: 'Depth',
      value: 'measuredDepthAvg' as const
    }
  ];

  return (
    <BaseSelect
      label="Variables"
      options={variableOptions}
      isMulti={true}
      value={
        chartSettings.measures?.map((af) => variableOptions.find((vo) => vo.value === af)) ??
        variableOptions
      }
      onChange={(options) => {
        setChartSettings({
          ...chartSettings,
          measures: options.map((o) => o.value)
        });
      }}
    />
  );
};

export default Controller;
