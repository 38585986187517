import { Box, Center, Spacer } from '@chakra-ui/react';
import { ResultSet, TimeDimensionGranularity } from '@cubejs-client/core';
import { ErrorBoundary } from '@sentry/react';
import { BaseChartSettings, ExploreChart } from 'components/Charts/types';
import { RenderError } from 'components/Pages/RenderError';
import { TimeFragment } from 'graphql/generated';
import { MutableRefObject } from 'react';

interface ChartWithControllerProps<
  TChartSettings extends BaseChartSettings = any,
  TControllerInput = any
> {
  exploreChart: ExploreChart<TChartSettings, TControllerInput>;
  controllerRef?: MutableRefObject<any>;
  showSublocations?: boolean;
  chartSettingsOverrides?: Partial<BaseChartSettings>;
  chartSettings: TChartSettings;
  setChartSettings: (chartSettings: TChartSettings) => void;
  timeFragment?: TimeFragment;
  onDataLoaded?: (resultSets: ResultSet[], dateRange?: [string, string]) => void;
  showingControls?: boolean;
}

const ChartWithController = ({
  exploreChart,
  controllerRef,
  showSublocations,
  chartSettingsOverrides,
  chartSettings,
  setChartSettings,
  timeFragment,
  onDataLoaded,
  showingControls = true
}: ChartWithControllerProps) => {
  if (!exploreChart) return <></>;
  return (
    <ErrorBoundary
      fallback={
        <Center width={'100vw'} height={'100vh'}>
          <RenderError />
        </Center>
      }
      showDialog={false}>
      <Box w="100%">
        <Spacer mt="20px" />
        {exploreChart.control && showingControls && !exploreChart?.yieldedControl ? (
          <exploreChart.control
            ref={controllerRef}
            controllerSettings={{ showSublocations }}
            chartSettings={{ ...chartSettings, ...chartSettingsOverrides }}
            chartInputs={exploreChart.initInputs}
            setChartSettings={setChartSettings}
          />
        ) : (
          <></>
        )}
        <Spacer mt="20px" />
        <exploreChart.chart
          dateRange={timeFragment?.dateRange}
          granularity={timeFragment?.granularity as TimeDimensionGranularity}
          settings={{
            ...chartSettings,
            ...chartSettingsOverrides,
            showTitle: true
          }}
          onDataLoaded={onDataLoaded}
          control={(controlInput) => {
            return showingControls ? (
              <exploreChart.control
                controllerSettings={{ showSublocations }}
                chartInputs={{ ...exploreChart?.initInputs, ...controlInput }}
                chartSettings={{
                  ...chartSettings,
                  ...chartSettingsOverrides
                }}
                setChartSettings={setChartSettings}
              />
            ) : (
              <></>
            );
          }}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default ChartWithController;
