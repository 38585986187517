// @ts-strict
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  CheckboxGroup,
  HStack,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react';
import { ProjectContext, ProjectContextType } from 'contexts/ProjectContext';
import { Station, StationType } from 'graphql/generated';
import { sortBy, throttle } from 'lodash';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BiGitCompare } from 'react-icons/bi';
import Map, {
  Layer,
  MapLayerMouseEvent,
  MapProvider,
  MapboxEvent,
  NavigationControl,
  Popup,
  Source,
  TransformRequestFunction
} from 'react-map-gl';
import { useNavigate } from 'react-router-dom';
import './DashboardMap.css';
import ForecastLayers from './ForecastLayers/ForecastLayers';
import { PopupRouter } from './Popup/PopupRouter';
import useMapSites, { MapSite } from './useMapSites';

interface DashboardMapProps {
  activeSiteId?: number;
  projectOverview?: boolean;
}

function DashboardMap({ activeSiteId, projectOverview }: DashboardMapProps) {
  // const location = useLocation();
  const navigate = useNavigate();

  const [focusedSiteId, setFocusedSiteId] = useState<number | undefined>(undefined);
  const projectContext = useContext(ProjectContext) as ProjectContextType;
  const [activePopupSite, setActivePopupSite] = useState<MapSite | undefined>(undefined);
  const [activePopupStation, setActivePopupStation] = useState<Station | undefined>(undefined);
  const mapSites = useMapSites();

  useEffect(() => {
    const site = mapSites.sitesById[activeSiteId];

    if (site && !mapSites.visibleSiteTypes.includes(site.type)) {
      mapSites.setVisibleSiteTypes([...mapSites.visibleSiteTypes, site.type]);
    }
  }, [activeSiteId]);

  const siteTypeCardRef = useRef(null);

  const transformRequest: TransformRequestFunction = useCallback((url) => {
    return {
      // Assets have three '/'s, we'll re-use the last one
      url: url.startsWith('asset:///') ? url.replace('asset://', `${window.location.origin}`) : url
    };
  }, []);

  //@ts-ignore
  const sitesGeoJson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: [
        ...mapSites.visibleSiteIds.map((siteId) => {
          const site = mapSites.sitesById[siteId];
          return {
            type: 'Feature' as const,
            id: site.id,
            properties: {
              zIndex: site.zIndex,
              smbId: site.smbId,
              image: site.image,
              type: 'site'
            },
            geometry: {
              type: 'Point',
              coordinates: [site.position.lon, site.position.lat]
            }
          };
        }),
        ...projectContext.region.stations
          .filter((s) => mapSites.visibleStationTypes.includes(s.type))
          .map((station) => {
            return {
              type: 'Feature' as const,
              id: station.id,
              properties: {
                image: station.type === StationType.River ? 'river' : 'station',
                type: 'station'
              },
              geometry: {
                type: 'Point',
                coordinates: [station.lon, station.lat]
              }
            };
          })
      ]
    };
  }, [mapSites.visibleSiteIds, mapSites.sitesById, mapSites.visibleStationTypes]);

  // Using mouseMove instead of mouseOver/Leave because Over/Leave only trigger on
  //  entering *any* part of the layer. In other words, the first site hovered triggers
  // 'enter', but if nearby sites are hovered without hovering directly over the map,
  //  new sites are not picked up.
  // Also throttling just a bit to avoid hammering CPU
  const handleMapMouseMove = throttle((event: MapLayerMouseEvent) => {
    if (!event || typeof event.features === 'undefined' || event.features.length === 0) {
      return;
    }
    // Grab the first 'site-icon' feature. Running find in case multiple types of layers exist and
    //   sites are not the highest z-index. Happens sometimes even with map annotations.
    const topSiteIcon = event.features.find((feature) => feature.layer.id === 'site-icons');
    if (!topSiteIcon) return;
    event.target.getCanvas().style.cursor = 'pointer';
    const hoveredSite = topSiteIcon as GeoJSON.Feature<GeoJSON.Point>;
    if (activePopupSite?.id === hoveredSite.id && hoveredSite.properties.type === 'site') return;
    if (activePopupStation?.id === hoveredSite.id && hoveredSite.properties.type === 'station')
      return;

    if (hoveredSite.properties.type === 'site') {
      setActivePopupSite(mapSites.sitesById[hoveredSite.id as number]);
      setActivePopupStation(null);
    }

    if (hoveredSite.properties.type === 'station') {
      setActivePopupStation(projectContext.region.stations.find((s) => s.id === hoveredSite.id));
      setActivePopupSite(null);
    }
  }, 50);

  const handleMapMouseLeave = (event: MapLayerMouseEvent) => {
    event.target.getCanvas().style.cursor = '';
    setActivePopupSite(undefined);
    setActivePopupStation(undefined);
  };

  function handleMapMouseDown(event: MapLayerMouseEvent) {
    if (!event.features || event.features.length === 0) return;
    const topSiteIcon = event.features.find((feature) => feature.layer.id === 'site-icons');
    if (!topSiteIcon) return;
    const clickedSiteId = topSiteIcon.id;
    if (topSiteIcon.properties.type === 'site')
      navigate(`/project/${projectContext.id}/site/${clickedSiteId}`);
  }

  // Because Map.reuseMaps=true to save unecessary billing events,
  //   We're tracking Renders to zoom on the active site instead of useEffect.
  //   useEffect does not re-trigger when reuseMaps=true
  // Heavier throttle because this triggers a LOT.
  const handleMapRender = throttle((event: MapboxEvent) => {
    if (!activeSiteId || mapSites.loadingSites || focusedSiteId) {
      return;
    }
    const activeSite = mapSites.sitesById[activeSiteId];
    if (!activeSite) {
      return;
    }
    event.target.setCenter([activeSite.position.lon, activeSite.position.lat]);
    event.target.setZoom(13);
    setTimeout(() => {
      event?.target?.resize();
      // Give a bit of a timeout to resize
      //   Sometimes this happens so fast the resize triggers before the rest of the page
      //   resizes.
    }, 300);
    setFocusedSiteId(activeSiteId);
  }, 500);

  if (!projectContext) return null;

  return (
    <div>
      <div className={'w-100 h-100'} style={{ zIndex: 1 }}>
        <div>
          <div className="w-100 h-100 absolute top-0 left-0 bottom-0 right-0">
            <MapProvider>
              <Map
                reuseMaps={true}
                id={'dashboard'}
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                transformRequest={transformRequest}
                mapStyle={'/style.json'}
                initialViewState={{
                  longitude: projectContext.longitude as number,
                  latitude: projectContext.latitude as number,
                  zoom: projectContext.zoomlevel as number
                }}
                interactiveLayerIds={['site-icons']}
                onMouseMove={handleMapMouseMove}
                onMouseLeave={handleMapMouseLeave}
                onMouseDown={handleMapMouseDown}
                onRender={handleMapRender}
                // Trigger a resize after loading to ensure the map fits in bounds of parent
                onLoad={(event) => event.target.resize()}
                style={{
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute'
                }}>
                <Source id={'sites'} type={'geojson'} data={sitesGeoJson}>
                  <Layer
                    id={'site-icons'}
                    type={'symbol'}
                    layout={{
                      'icon-image': ['get', 'image'],
                      'icon-size': ['get', 'size'],
                      'symbol-sort-key': ['get', 'zIndex'],
                      'icon-allow-overlap': true,
                      'icon-anchor': 'center'
                    }}
                    source={'sites'}
                  />
                </Source>
                {!activeSiteId && <NavigationControl position={'bottom-right'} />}
                {(activePopupSite || activePopupStation) &&
                  (activeSiteId ? (
                    <Popup
                      longitude={activePopupSite.position.lon}
                      latitude={activePopupSite.position.lat}
                      closeButton={false}
                      closeOnClick={false}>
                      <Text fontSize="lg">{activePopupSite.name}</Text>
                    </Popup>
                  ) : (
                    <Popup
                      style={{ backgroundColor: 'none' }}
                      anchor={projectOverview ? 'left' : 'bottom'}
                      maxWidth="600px"
                      className="map-popup"
                      longitude={activePopupSite?.position.lon ?? activePopupStation.lon}
                      latitude={activePopupSite?.position.lat ?? activePopupStation.lat}
                      offset={10}
                      closeButton={false}
                      closeOnClick={false}>
                      <PopupRouter
                        site={activePopupSite}
                        station={activePopupStation}
                        isLoading={mapSites.loadingThresholdData}
                      />
                    </Popup>
                  ))}
              </Map>
              {/* TODO - If these are ported to react-map-gl, move them into the Map
                    They're down here because reuseMaps skips re-renders and goofs when activeSite is present - MEA
               */}
              {!activeSiteId && (
                <ForecastLayers layerButtonTop={siteTypeCardRef?.current?.clientHeight + 30} />
              )}
            </MapProvider>
          </div>
        </div>
        {activeSiteId && (
          <Box
            position="absolute"
            top={0}
            right={0}
            backgroundColor="blue.500"
            color="white"
            p={2}
            borderBottomStartRadius="2xl"
            style={{ zIndex: 105, cursor: 'pointer' }}
            onClick={() => {
              navigate('/project/' + projectContext.id);
            }}>
            <ExternalLinkIcon fontSize="20px" />
          </Box>
        )}

        {!activeSiteId && (
          <Stack
            direction={projectOverview ? 'row' : 'column'}
            alignItems={projectOverview ? 'start' : 'end'}
            ref={siteTypeCardRef}
            zIndex={100}
            position="absolute"
            right={5}
            top={5}>
            <Card minW="150px">
              <CardBody>
                <CheckboxGroup>
                  <List ml="0px">
                    {sortBy(mapSites.availableSiteTypes).map((avst) => (
                      <ListItem key={avst}>
                        <Checkbox
                          isChecked={mapSites.visibleSiteTypes.includes(avst)}
                          onChange={() => {
                            if (mapSites.visibleSiteTypes.includes(avst)) {
                              mapSites.setVisibleSiteTypes(
                                mapSites.visibleSiteTypes.filter((vst) => vst !== avst)
                              );
                            } else {
                              mapSites.setVisibleSiteTypes([...mapSites.visibleSiteTypes, avst]);
                            }
                          }}>
                          {avst}
                        </Checkbox>
                      </ListItem>
                    ))}
                  </List>
                </CheckboxGroup>
              </CardBody>
            </Card>

            {mapSites.availableStationTypes.length > 0 && (
              <Card minW="150px">
                <CardBody>
                  <CheckboxGroup>
                    <List ml="0px">
                      {sortBy(mapSites.availableStationTypes).map((stationType) => (
                        <ListItem key={stationType.toString()}>
                          <Checkbox
                            isChecked={mapSites.visibleStationTypes.includes(stationType)}
                            onChange={() => {
                              if (mapSites.visibleStationTypes.includes(stationType)) {
                                mapSites.setVisibleStationTypes(
                                  mapSites.visibleStationTypes.filter((vst) => vst !== stationType)
                                );
                              } else {
                                mapSites.setVisibleStationTypes([
                                  ...mapSites.visibleStationTypes,
                                  stationType
                                ]);
                              }
                            }}>
                            {stationType === StationType.River ? 'River Stations' : 'Tide Stations'}
                          </Checkbox>
                        </ListItem>
                      ))}
                    </List>
                  </CheckboxGroup>
                </CardBody>
              </Card>
            )}

            <Card w="100%">
              <CardBody>
                <Text mb="15px">Color By</Text>
                <RadioGroup
                  onChange={(v) => mapSites.setSiteColorBy(v)}
                  value={mapSites.colorSitesBy}>
                  <VStack alignItems="start">
                    <Radio value="all">All</Radio>
                    <Radio value="oxygen_saturation">Oxygen</Radio>
                    <Radio value="plankton">Plankton</Radio>
                  </VStack>
                </RadioGroup>
              </CardBody>
            </Card>
          </Stack>
        )}

        <HStack alignItems="start" ml="10px" pt="10px" maxW="600px" w="100%">
          <Box zIndex={100}>
            {
              <Button
                leftIcon={<BiGitCompare fontSize="24px" />}
                bgColor="white"
                onClick={() => navigate(`/project/${projectContext.id}/overview/`)}>
                Compare All Sites
              </Button>
            }
          </Box>
        </HStack>
      </div>
    </div>
  );
}

export default DashboardMap;
