import { VisitInfo } from 'graphql/generated';
import { Box } from '@chakra-ui/react';
import NoData from '../NoData';
import Plot from '../Plot';
import { useMemo } from 'react';

type SankeyData = {
  nodes: string[];
  links: { source: number; target: number; value: number }[];
};

const Chart = ({
  visits,
  ignoreIds,
  align,
  height
}: {
  visits: VisitInfo[];
  ignoreIds: boolean;
  align: 'left' | 'right';
  height: number;
}) => {
  //GPTTTT
  const transform = (visits: VisitInfo[]): SankeyData => {
    const nodesSet = new Set<string>();
    const linksMap = new Map<string, { source: string; target: string; value: number }>();

    visits.forEach((visit) => {
      const pathParts = visit.path.split('/').filter((part) => part);
      for (let i = 0; i < pathParts.length - 1; i++) {
        const source = '/' + pathParts.slice(0, i + 1).join('/');
        const target = '/' + pathParts.slice(0, i + 2).join('/');
        nodesSet.add(source);
        nodesSet.add(target);
        const key = `${source}->${target}`;
        if (linksMap.has(key)) {
          linksMap.get(key)!.value += visit.count;
        } else {
          linksMap.set(key, { source, target, value: visit.count });
        }
      }
    });

    const nodesArray = Array.from(nodesSet);
    const linksArray = Array.from(linksMap.values());

    // Calculate total counts for each node
    const nodeCounts = new Map<string, number>();
    linksArray.forEach((link) => {
      nodeCounts.set(link.source, (nodeCounts.get(link.source) || 0) + link.value);
      nodeCounts.set(link.target, (nodeCounts.get(link.target) || 0) + link.value);
    });

    // Sort nodes by total counts
    nodesArray.sort((a, b) => (nodeCounts.get(b) || 0) - (nodeCounts.get(a) || 0));

    // Update links according to the sorted nodes
    const nodeIndexMap = new Map<string, number>();
    nodesArray.forEach((node, index) => nodeIndexMap.set(node, index));

    const links = linksArray.map((link) => ({
      source: nodeIndexMap.get(link.source)!,
      target: nodeIndexMap.get(link.target)!,
      value: link.value
    }));

    return { nodes: nodesArray, links };
  };

  const filteredVisits = useMemo(() => {
    if (ignoreIds) {
      return visits.map((v) => ({ ...v, path: v.path.replace(/\d+/g, '[id]') }));
    }

    return visits;
  }, [ignoreIds]);

  const sankeyData = transform(filteredVisits);

  const plot = {
    data: [
      {
        type: 'sankey',
        name: 'Page Views',
        node: {
          label: sankeyData.nodes,
          align
        },
        link: {
          source: sankeyData.links.map((link) => link.source),
          target: sankeyData.links.map((link) => link.target),
          value: sankeyData.links.map((link) => link.value)
        }
      }
    ],
    layout: {
      height
    }
  };

  if (visits.length === 0) {
    return (
      <Box w="100%" m="20px">
        <NoData minH="400px" />
      </Box>
    );
  }

  return <Plot className="w-100 h-100" useResizeHandler={true} {...plot} />;
};

export default Chart;
