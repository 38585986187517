const isPen = (sublocation: string) =>
  (sublocation.includes('cage') && !sublocation.includes('insideclosed')) ||
  sublocation.includes('pen');
const isBarge = (sublocation: string) =>
  sublocation.includes('house') || sublocation.includes('barge');
const isSCCS = (sublocation: string) => sublocation.toLowerCase().includes('scc');
const extractNumber = (sublocation: string) => parseInt(sublocation.replace(/\D+/g, ''));

const locationToIndex = (location) => {
  if (typeof location != 'string') {
    location = String(location);
  }
  // cages / pens first
  if (isPen(location)) {
    const cageNum = extractNumber(location);
    return cageNum;
  }

  // then barges
  if (isBarge(location)) {
    let depth = extractNumber(location);
    if (isNaN(depth)) depth = 0;
    return depth - 1000;
  }

  // then whatever else by int
  if (!isNaN(extractNumber(location))) {
    return extractNumber(location) + 1000;
  }

  return 0;
};

export { isPen, isBarge, isSCCS, locationToIndex };
