import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';
import { ParameterOptions } from './ForecastDetailPlot';

const Controller = ({ chartSettings, setChartSettings }: ChartController<ChartSettings>) => {
  const variableOptions = [
    { label: 'Air Temperature', value: 'airTempAvg' },
    { label: 'Cloud Cover', value: 'cloudCoverAvg' },
    { label: 'Easterly Wind Speed', value: 'windEastAvg' },
    { label: 'Northerly Wind Speed', value: 'windNorthAvg' },
    { label: 'Total Precipitation', value: 'totalPrecipAvg' },
    { label: 'Sea Surface Pressure', value: 'seaSurfacePressureAvg' },
    { label: 'Relative Humidity', value: 'relativeHumidityAvg' },
    { label: 'Incoming Long-wave Radiation', value: 'downwardLongRadiationAvg' },
    { label: 'Incoming Short-wave Radiation', value: 'downwardShortRadiationAvg' },
    { label: 'Upwelling Index', value: 'upwellingIndexAvg' }
  ];
  chartSettings.parameters = chartSettings.parameters
    ? chartSettings.parameters
    : (variableOptions as ParameterOptions);

  return (
    <ChartSwitchPanel
      w="100%"
      selects={[
        {
          value: chartSettings?.parameters,
          defaultValue: chartSettings?.parameters,
          options: variableOptions,
          isMulti: true,
          placeholder: 'Select Hydrographic Parameters',
          minWidth: '500px',
          width: '100%',
          maxWidth: '600px',
          onChange: (x) =>
            setChartSettings({
              ...chartSettings,
              parameters: x as ParameterOptions
            })
        }
      ]}
    />
  );
};

export default Controller;
