import { Card, Skeleton } from '@chakra-ui/react';
import { useCubeQuery } from '@cubejs-client/react';
import NoData from 'components/Charts/NoData';
import Plot from 'components/Charts/Plot';
import { ProjectContext } from 'contexts/ProjectContext';
import { add, closestTo, lightFormat, sub } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Station, StationType } from 'graphql/generated';
import { useContext, useMemo } from 'react';

export const StationPopup = ({ station }: { station: Station }) => {
  const project = useContext(ProjectContext);

  const now = useMemo(() => new Date(), []);

  const { resultSet: tidesSet, isLoading: tidesLoading } = useCubeQuery(
    {
      measures: ['TessTides.waterlevelAvg'],
      timeDimensions: [
        {
          granularity: 'hour',
          dimension: 'TessTides.measuredAt',
          dateRange: [sub(now, { days: 2 }).toISOString(), add(now, { days: 2 }).toISOString()]
        }
      ],
      filters: [
        {
          member: 'TessTides.tideStationId',
          operator: 'equals',
          values: [station.stationKey.toString()]
        }
      ],
      timezone: project.timezone,
      order: { 'TessTides.measuredAt': 'desc' }
    },
    { skip: station.type !== StationType.Tide }
  );

  const { resultSet: riversSet, isLoading: riversLoading } = useCubeQuery(
    {
      measures: ['TessRivers.dischargeAvg'],
      timeDimensions: [
        {
          granularity: 'hour',
          dimension: 'TessRivers.measuredAt',
          dateRange: [sub(now, { days: 3 }).toISOString(), now.toISOString()]
        }
      ],
      filters: [
        {
          member: 'TessRivers.riverStationId',
          operator: 'equals',
          values: [station.stationKey]
        }
      ],
      timezone: project.timezone,
      order: { 'TessRivers.measuredAt': 'desc' }
    },
    { skip: station.type !== StationType.River }
  );

  const tidesPlot = useMemo(() => {
    if (!tidesSet) return null;
    const data = tidesSet.rawData();
    const times = data.map((d) => new Date(d['TessTides.measuredAt.hour'].toString()));
    const waterLevelAvg = data.map((d) => d['TessTides.waterlevelAvg']);

    const nowInTz = utcToZonedTime(
      zonedTimeToUtc(now, Intl.DateTimeFormat().resolvedOptions().timeZone),
      project.timezone
    );

    const closest = closestTo(nowInTz, times);

    return {
      data: [
        {
          type: 'spline',
          name: 'Water Level Avg',
          x: times,
          y: waterLevelAvg,
          fill: 'tozeroy',
          fillcolor: 'rgba(132, 255, 249, 0.5)',
          mode: 'none'
        },
        closest
          ? {
              type: 'scatter',
              name: 'Now',
              x: [closest],
              y: [
                data.find(
                  (d) =>
                    d['TessTides.measuredAt.hour'] ===
                    lightFormat(closest, `yyyy-MM-dd'T'HH:mm:00.000`)
                )?.['TessTides.waterlevelAvg']
              ],
              marker: { size: 12 }
            }
          : undefined
      ],
      layout: {
        width: 500,
        height: 250,
        margin: { t: 0, b: 50, l: 35, r: 0 },
        showlegend: false,
        yaxis: {
          title: {
            text: 'Meters'
          }
        },
        xaxis: {
          title: {
            text: 'Last 2 days - Next 2 days'
          }
        }
      }
    };
  }, [tidesLoading, tidesSet]);

  const riversPlot = useMemo(() => {
    if (!riversSet) return null;
    const data = riversSet.rawData();
    const times = data.map((d) => new Date(d['TessRivers.measuredAt.hour'].toString()));
    const dischargeAvg = data.map((d) => d['TessRivers.dischargeAvg']);

    return {
      data: [
        {
          type: 'bar',
          name: 'Avg Discharge',
          x: times,
          y: dischargeAvg,
          fillcolor: 'rgba(132, 255, 249, 0.5)'
        }
      ],
      layout: {
        width: 500,
        height: 250,
        margin: { t: 0, b: 50, l: 35, r: 0 },
        yaxis: {
          title: {
            text: 'Cubic Meters Per Second'
          }
        },
        xaxis: {
          title: {
            text: 'Last 3 days'
          }
        },
        showlegend: false
      }
    };
  }, [riversLoading, riversSet]);

  return (
    <Card w="100%">
      {station.type === StationType.Tide ? (
        tidesLoading ? (
          <Skeleton h="50px" w="100%" />
        ) : tidesPlot?.data?.[0].x.length === 0 ? (
          <NoData minH="250px" />
        ) : (
          <Plot className="w-100" useResizeHandler={true} {...tidesPlot} />
        )
      ) : riversLoading ? (
        <Skeleton h="50px" w="100%" />
      ) : riversPlot?.data?.[0].x.length === 0 ? (
        <NoData minH="250px" />
      ) : (
        <Plot className="w-100" useResizeHandler={true} {...riversPlot} />
      )}
    </Card>
  );
};
