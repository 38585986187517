import {
  CardBody,
  Divider,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  VStack
} from '@chakra-ui/react';
import { EquipmentAdminForm } from 'pages/project/[Id]/Admin/Equipment/EquipmentAdmin';
import { ControllerFieldState, UseControllerProps } from 'react-hook-form';

export const EquipmentNumberType = (
  props: UseControllerProps<EquipmentAdminForm, `equipmentTypes.${number}.states.${number}`> & {
    fieldState: ControllerFieldState;
  }
) => {
  return (
    <CardBody>
      <VStack>
        <Input
          data-cypress="number-state-name"
          placeholder="State Name..."
          isInvalid={!!props.fieldState?.error?.['name']}
          {...props.control.register(`${props.name}.name`, {
            required: 'A name is required',
            minLength: 1
          })}
        />
        <Input
          placeholder="Optional Description..."
          {...props.control.register(`${props.name}.description`)}
        />
        {props?.fieldState?.error?.['name'] && (
          <Text color="red.500">{props.fieldState?.error?.['name'].message}</Text>
        )}
        <Divider my="10px" />
        <Text fontStyle={'italic'}>Example number input:</Text>
        <NumberInput isDisabled={true}>
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </VStack>
    </CardBody>
  );
};
