import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Heading,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import TimeFragmentPicker, { TimeFragment } from 'components/Charts/Custom/TimeFragmentPicker';
import { EquipmentTypeWithSiteEquipment } from 'components/Site/Tabs/Equipment/Data';
import { EquipmentDatum } from 'components/Site/Tabs/Equipment/EquipmentEntry';
import { format } from 'date-fns';
import { EquipmentState, SiteEquipment } from 'graphql/generated';
import { groupBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import Select from 'react-select';
import { useCubeEquipment } from './useCubeEquipment';

export const EquipmentTable = ({
  smbSiteId,
  equipmentData,
  deletedSiteEquipmentIds
}: {
  smbSiteId: number;
  equipmentData: EquipmentTypeWithSiteEquipment[];
  deletedSiteEquipmentIds: number[];
}) => {
  const [equipmentType, setEquipmentType] = useState<EquipmentTypeWithSiteEquipment>();
  const [equipment, setEquipment] = useState<EquipmentTypeWithSiteEquipment['siteEquipment'][0]>();

  const timeFrames = [
    { label: 'Last Day', value: 'last 86400 seconds' },
    { label: 'Last Week', value: 'last 604800 seconds' },
    { label: 'Last Month', value: 'last 2592000 seconds' }
  ];

  const [timeFragment, setTimeFragment] = useState<TimeFragment>({
    dateRange: timeFrames[1].value
  });

  const { resultSet, isLoading } = useCubeEquipment({
    smbSiteId,
    dateRange: timeFragment?.dateRange
  });

  useEffect(() => {
    if (equipmentData?.length > 0) setEquipmentType(equipmentData[0]);
  }, [equipmentData]);

  const toOption = (eq: Partial<SiteEquipment>) => {
    return {
      label: eq.sublocation ? `${eq.name} - ${eq.sublocation}` : eq.name,
      value: eq
    };
  };

  const filteredData = useMemo(() => {
    const notDeleted =
      resultSet
        ?.rawData()
        ?.filter(
          (cd) => !deletedSiteEquipmentIds.includes(cd['TessEquipmentLookup.equipmentSiteId'])
        ) ?? [];

    if (!equipment) return notDeleted;

    return notDeleted.filter((cd) => cd['TessEquipmentLookup.equipmentSiteId'] === equipment.id);
  }, [equipment, resultSet]);

  const actions = useMemo(
    () => filteredData.filter((d) => d['TessEquipmentLookup.actionId'] !== -1),
    [filteredData]
  );

  const groupedStateUpdates = useMemo(() => {
    if (!equipmentType) return [];
    const siteEqIds = equipmentType.siteEquipment.map((se) => se.id);
    return groupBy(
      filteredData.filter((d) => siteEqIds.includes(d['TessEquipmentLookup.equipmentSiteId'])),
      (v) => `${v['TessEquipment.measuredAt']}-${v['TessEquipmentLookup.equipmentSiteId']}`
    );
  }, [filteredData, equipmentType]);

  const rowContent = (et: EquipmentState, ed: EquipmentDatum) => {
    if (!ed) return '';
    if (et.__typename === 'EquipmentSelectionState') {
      return et.options.find((o) => o.id.toString() === ed['TessEquipment.value'])?.name ?? '';
    }

    return ed['TessEquipment.value'];
  };

  const siteEquipmentValue = (equipment: Partial<SiteEquipment>[], equipmentSiteId: number) => {
    if (!equipment) return '';

    const siteEquipment = equipment.find((et) => equipmentSiteId === et.id);

    if (!siteEquipment) return '';

    return siteEquipment.sublocation
      ? `${siteEquipment.name} - ${siteEquipment.sublocation}`
      : siteEquipment.name;
  };

  return (
    <Card w="100%">
      <CardHeader>
        <HStack justifyContent="center" w="100%">
          <HStack w="500px">
            <Text>Equipment Type</Text>
            <Select
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: '200px',
                  textAlign: 'left'
                })
              }}
              placeholder="Equipment Type..."
              value={{ label: equipmentType?.name, value: equipmentType }}
              options={equipmentData.map((ed) => ({ label: ed.name, value: ed }))}
              onChange={(v) => setEquipmentType(v.value)}
            />
          </HStack>
          <HStack w="500px">
            <Text>Equipment</Text>
            <Select
              placeholder="Optional..."
              isClearable={true}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: '300px',
                  textAlign: 'left'
                })
              }}
              value={equipment ? toOption(equipment) : null}
              options={equipmentType?.siteEquipment?.map((se) => toOption(se)) ?? []}
              onChange={(v) => setEquipment(v?.value)}
            />
          </HStack>
          <TimeFragmentPicker
            display="inline"
            timeFragment={timeFragment}
            timeFramesOverride={timeFrames}
            onChange={(tf) => setTimeFragment(tf)}
            showGranularity={false}
          />
        </HStack>
      </CardHeader>

      <CardBody>
        {isLoading ? (
          <Skeleton w="100%" h="400px" />
        ) : (
          <>
            <Heading fontSize="lg">State Updates</Heading>
            <TableContainer>
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>Updated At</Th>
                    <Th>Equipment</Th>
                    {(equipmentType?.states ?? []).map((s) => (
                      <Th key={s.name}>{s.name}</Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.values(groupedStateUpdates).map((ed, i) => (
                    <Tr key={i}>
                      <Td>{format(new Date(ed[0]['TessEquipment.measuredAt']), 'PPp')}</Td>
                      <Td>
                        {siteEquipmentValue(
                          equipmentType?.siteEquipment,
                          ed[0]['TessEquipmentLookup.equipmentSiteId']
                        )}
                      </Td>
                      <>
                        {equipmentType?.states.map((equipmentState, j) => {
                          return (
                            <Td key={`${i}-${j}`}>
                              {rowContent(
                                equipmentState,
                                ed.find(
                                  (datum) =>
                                    datum['TessEquipmentLookup.stateId'] === equipmentState.id
                                )
                              )}
                            </Td>
                          );
                        })}
                      </>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>

            <Heading mt="15px" fontSize="lg">
              Actions
            </Heading>
            {actions.map((a, i) => (
              <HStack
                py="5px"
                _odd={{ backgroundColor: 'var(--chakra-colors-gray-100)' }}
                w="100%"
                justifyContent="space-between"
                key={i}>
                <Flex>
                  <MdCheckCircle color="var(--chakra-colors-blue-500)" />
                  <Text ml="5px">{format(new Date(a['TessEquipment.measuredAt']), 'PPpp')}</Text>
                </Flex>
                <Text ml="5px">{a['TessEquipment.value']}</Text>
              </HStack>
            ))}
          </>
        )}
      </CardBody>
    </Card>
  );
};
