import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  Text,
  FormControl,
  FormErrorMessage,
  Spacer
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

type PasswordEditProps = {
  password: UseFormRegisterReturn;
  passwordError?: FieldError;
  passwordConfirmation: UseFormRegisterReturn;
  passwordConfirmationError?: FieldError;
};

const PasswordEdit = ({
  password,
  passwordError,
  passwordConfirmation,
  passwordConfirmationError
}: PasswordEditProps) => {
  const [passwordInputType, setInputPasswordType] = useState('password');
  return (
    <>
      <Text mt="25px" mb="20px" textAlign="center" fontSize="2xl">
        New Password
      </Text>
      <Box>
        <FormControl isInvalid={!!passwordError || !!passwordConfirmationError}>
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <Input type={passwordInputType} placeholder="Password" {...password} />
            <InputRightElement>
              {passwordInputType === 'password' ? (
                <ViewIcon onClick={() => setInputPasswordType('')} />
              ) : (
                <ViewOffIcon onClick={() => setInputPasswordType('password')} />
              )}
            </InputRightElement>
          </InputGroup>
          {passwordError ? (
            <FormErrorMessage>{passwordError.message}</FormErrorMessage>
          ) : (
            <Spacer h="25px" />
          )}
        </FormControl>
        <FormControl isInvalid={!isEmpty(passwordConfirmationError)}>
          <FormLabel mt="10px">Confirm Password</FormLabel>
          <Input type="password" placeholder="Confirm Password" {...passwordConfirmation} />
          {passwordConfirmationError ? (
            <FormErrorMessage>{passwordConfirmationError.message}</FormErrorMessage>
          ) : (
            <Spacer h="25px" />
          )}
        </FormControl>
      </Box>
    </>
  );
};

export default PasswordEdit;
