import { HStack, Skeleton } from '@chakra-ui/react';
import { MapSite } from '../useMapSites';
import { OxygenCard } from './OxygenCard';
import { PlanktonCard } from './PlanktonCard';

interface SitePopupProps {
  site: MapSite;
  showOxygen: boolean;
  isLoading: boolean;
}

export const SitePopup = ({ site, showOxygen, isLoading }: SitePopupProps) => {
  return (
    <HStack pt="15px" w="100%" alignItems="stretch">
      {isLoading ? <Skeleton h="200px" w="200px" /> : <PlanktonCard site={site} />}
      {showOxygen && isLoading ? <Skeleton h="200px" w="200px" /> : <OxygenCard site={site} />}
    </HStack>
  );
};
