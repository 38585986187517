import { Text } from '@chakra-ui/react';
import SeastateServerError from './Pages/SeastateServerError';

const UserLoginError = () => {
  return (
    <SeastateServerError>
      <Text>{`We're sorry, there was an issue logging your user in.`}</Text>
      <Text>{`If this error persists, please contact a Seastate administrator.`}</Text>
    </SeastateServerError>
  );
};

export { UserLoginError };
