import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react';
import { FAQPage } from './FAQTypes';

const FAQModal = ({
  data,
  isOpen,
  onClose,
  idx
}: {
  data: FAQPage;
  isOpen: boolean;
  onClose: () => void;
  idx: number;
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} size="5xl">
      <ModalOverlay />
      <ModalContent mt="100px">
        <ModalHeader>{data.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs orientation="vertical" size="sm" defaultIndex={idx}>
            <TabList w="20%" h="fit-content">
              {data.sections.map((x, idx) => (
                <Tab key={idx}>{x.title}</Tab>
              ))}
            </TabList>
            <TabPanels h="calc(70vh)" overflowY="auto" w="80%">
              {data.sections.map((x, idx) => (
                <TabPanel key={idx}>
                  <Heading as="h3" size="lg" textAlign="center" mb="1rem">
                    {x.title}
                  </Heading>
                  {x.body}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FAQModal;
