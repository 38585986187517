import React from 'react';
import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';

type DemoLegalModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const DemoLegalModal = ({ isOpen, onClose }: DemoLegalModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent data-cypress={'demo-modal'}>
      <ModalHeader>Notice</ModalHeader>
      <ModalCloseButton />
      <ModalBody textAlign={['center']}>
        <Center>
          <p>
            This SeaState space is for demonstration purposes only. The farm locations presented
            here do not correspond to any actual farm sites. The data presented here is real data
            but has been anonymized to protect aquaculture operators according to agreed upon data
            licensing.
          </p>
        </Center>
      </ModalBody>

      <ModalFooter>
        <Button style={{ margin: 'auto' }} colorScheme="blue" onClick={onClose}>
          Acknowledge
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export { DemoLegalModal };
