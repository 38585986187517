import { gql, useMutation } from '@apollo/client';
import { Divider, ModalBody, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { User } from '@scoot/permissions';
import { formatDate } from 'components/Forms/BaseInput';
import FormInput from 'components/Forms/FormInput';
import FormModal from 'components/Forms/FormModal';
import FormSelect from 'components/Forms/FormSelect';
import FormTextarea from 'components/Forms/FormTextarea';
import SearchableUserInput from 'components/Forms/SearchableUserInput';
import { ProjectContext } from 'contexts/ProjectContext';
import { GET_EVENTS } from 'graphql/SharedQueries';
import { Site } from 'graphql/generated';
import { useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import EventComment from './EventComment';
import EventCommentInput from './EventCommentInput';

const SAVE_EVENT = gql`
  mutation saveEvent($event: EventInput!, $notify: [String!]) {
    saveEvent(event: $event, notify: $notify) {
      id
      eventType
      severity
      creator
      created
      siteId
      startTime
      endTime
      trace
      detail
      comments {
        id
        eventId
        creator
        comment
        created
        attachmentRefs
      }
      subscribed
      hasNew
    }
  }
`;

const DELETE_EVENT = gql`
  mutation deleteEvent($eventId: Int!) {
    deleteEvent(eventId: $eventId)
  }
`;

type EventFormProps = {
  event?: any;
  site: Site;
  onClose: () => void;
  onCommentSaved?: () => void;
};

type Form = {
  id?: number;
  eventType: { label: string; value: string };
  severity: { label: string; value: string };
  startTime: string;
  endTime: string;
  trace: { label: string; value: string };
  reportedBy: string;
  details?: string;
  specializedDetail?: { label: string; value: string };
  notify?: { label: string; value: User };
};

const EventForm = ({ event, site, onClose, onCommentSaved }: EventFormProps) => {
  const projectContext = useContext(ProjectContext);

  const eventTypes = useMemo(
    () =>
      Object.keys(projectContext.dataEntry.events.types).map((et) => ({ label: et, value: et })),
    [projectContext]
  );

  const severities = ['Low', 'Medium', 'High'].map((s) => ({ label: s, value: s }));

  const specializedDetail = {
    'Algae Bloom': projectContext.planktonPolicy,
    'Mortality Investigation': ['Disease', 'Transport', 'Event/Other']
  };

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isValid }
  } = useForm<Form>({
    defaultValues: {
      id: event?.id,
      eventType: eventTypes.find((et) => et.value === event?.eventType),
      severity: severities.find((s) => s.value === event?.severity),
      startTime: formatDate(event?.startTime ? new Date(event?.startTime) : new Date()),
      endTime: event?.endTime ? formatDate(new Date(event.endTime)) : null,
      reportedBy: event?.creator,
      details: event?.detail,
      specializedDetail: event?.detail ? { label: event.detail, value: event.detail } : null,
      notify: null
    }
  });

  const eventType = watch('eventType');

  // Reset the "specializedDetails" field if we're switching types
  useEffect(() => {
    setValue('specializedDetail', null);
  }, [eventType, setValue]);

  const [saveEvent, { loading: isSubmitting }] = useMutation(SAVE_EVENT, {
    refetchQueries: [GET_EVENTS, 'getEvents']
  });

  const [deleteEvent, { loading: isDeleting }] = useMutation(DELETE_EVENT, {
    refetchQueries: [GET_EVENTS, 'getEvents']
  });

  const submit = async (form: Form) => {
    await saveEvent({
      variables: {
        event: {
          id: form?.id,
          eventType: form.eventType.value,
          severity: form.severity?.value ?? '',
          creator: form.reportedBy,
          created: new Date(),
          siteId: Number(site.id),
          startTime: new Date(form.startTime),
          endTime: form?.endTime,
          trace: JSON.stringify(form.trace),
          detail: form.details
        },
        notify: !form.id && !!form.notify?.value?.email ? form.notify.value.email : null
      }
    });

    onClose();
  };

  const handleDelete = async () => {
    await deleteEvent({ variables: { eventId: event.id } });
    onClose();
  };

  return (
    <FormModal
      modalHeader={`${site.name} - Events`}
      isDeleting={isDeleting}
      isSubmitDisabled={!isValid}
      isSubmitting={isSubmitting}
      onClose={onClose}
      onDelete={event ? handleDelete : undefined}
      onSubmit={handleSubmit(submit)}>
      <ModalBody display="flex">
        {event?.comments && (
          <VStack
            w="30%"
            overflowY="scroll"
            overflowX="hidden"
            pr="10px"
            divider={<Divider opacity="1" color="gray.300" />}
            borderRight={'1px solid var(--chakra-colors-gray-300)'}>
            <EventCommentInput
              onCommentSaved={() => {
                if (onCommentSaved) onCommentSaved();
              }}
              eventId={Number(event.id)}
            />
            {event?.comments.length > 0 ? (
              event?.comments.map((c) => <EventComment key={c.id} comment={c} />)
            ) : (
              <Text p="10px">There are no comments for this event</Text>
            )}
          </VStack>
        )}
        <VStack w="100%" maxW="800px" ml="auto" mr="auto" spacing={10}>
          <SimpleGrid columns={2} spacingX={20} spacingY={5} w="100%">
            <SearchableUserInput
              control={control}
              rules={{ required: true }}
              name="reportedBy"
              projectId={projectContext.id}
            />

            <FormSelect
              control={control}
              rules={{ required: true }}
              name="eventType"
              options={eventTypes}
              label={'Event Type'}
            />

            <FormInput
              control={control}
              rules={{ required: true }}
              name="startTime"
              type="datetime-local"
              label="Start Time"
            />

            <FormInput control={control} name="endTime" type="datetime-local" label="End Time" />

            <FormSelect control={control} name="severity" options={severities} label={'Severity'} />

            {Object.keys(specializedDetail).some((sd) => sd === eventType?.value) ? (
              <FormSelect
                control={control}
                name="specializedDetail"
                options={specializedDetail[eventType.value].map((et) => ({ label: et, value: et }))}
                label={'Details'}
              />
            ) : (
              <FormTextarea resize={'none'} control={control} name="details" label="Details" />
            )}

            {!event?.id && (
              <SearchableUserInput
                control={control}
                label="Notify"
                name="notify"
                projectId={projectContext.id}
                emailsOnly={true}
                rules={{ required: false }}
              />
            )}
          </SimpleGrid>
        </VStack>
      </ModalBody>
    </FormModal>
  );
};

export default EventForm;
