import { DeeplyReadonly, Query, ResultSet } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { ProjectContext } from 'contexts/ProjectContext';
import { max, min } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { PlotlyDataLayoutConfig } from 'plotly.js';
import { useContext, useEffect, useState } from 'react';

type useCubeLTGProps<TQueryData, TTransformed, TDependency> = {
  cubeQuery: DeeplyReadonly<Query | Query[]>;
  transform: (rawData: TQueryData[], dependencies?: TDependency) => TTransformed;
  graph: (data: TTransformed, dependencies?: TDependency) => PlotlyDataLayoutConfig;
  options?: {
    refreshInterval?: number;
    skip?: boolean;
    subscribe?: boolean;
    dependencies?: TDependency;
    onDataLoaded?: (resultSet: ResultSet[], dateRange: [string, string]) => void;
  };
};

const useCubeLTG = <TQueryResult, TTransformed, TDependency>({
  cubeQuery,
  transform,
  graph,
  options
}: useCubeLTGProps<TQueryResult, TTransformed, TDependency>) => {
  const { isLoading, resultSet, error, refetch } = useCubeQuery<TQueryResult>(cubeQuery, {
    skip: options?.skip,
    subscribe: options?.subscribe
  });

  const [plot, setPlot] = useState<PlotlyDataLayoutConfig>(null);

  const project = useContext(ProjectContext);

  useEffect(() => {
    if (!isLoading && resultSet) {
      if (options?.onDataLoaded) {
        const minMaxDates = resultSet.query().timeDimensions?.map((td) => td.dateRange);

        // Date() constructor converts time to local browser timezone. Convert back out to utc.
        // Conversion needed in first place for min and max functionality...
        const minDate = zonedTimeToUtc(
          min(minMaxDates.map((d) => new Date(d[0]))),
          Intl.DateTimeFormat().resolvedOptions().timeZone
        );
        const maxDate = zonedTimeToUtc(
          max(minMaxDates.map((d) => new Date(d[1]))),
          Intl.DateTimeFormat().resolvedOptions().timeZone
        );

        options.onDataLoaded(
          [resultSet],
          [
            // Convert utc to project time.
            utcToZonedTime(minDate, project.timezone).toISOString(),
            utcToZonedTime(maxDate, project.timezone).toISOString()
          ]
        );
      }
      const data = resultSet.rawData();
      const transformed = transform(data, options?.dependencies);
      const plot = graph(transformed, options?.dependencies);
      setPlot(plot);
    }

    if (options?.refreshInterval) {
      const interval = setInterval(() => {
        refetch();
      }, options.refreshInterval);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSet, isLoading, error, JSON.stringify(options?.dependencies ?? {})]);

  return { isLoading: isLoading, error, plot, resultSet };
};

export default useCubeLTG;
