import AbsoluteMortality from 'components/Charts/AbsoluteMortality/Chart';
import AbsoluteMortalityControl from 'components/Charts/AbsoluteMortality/Controller';
import AllLicePerFish from 'components/Charts/AllLicePerFish/Chart';
import AllLicePerFishControl from 'components/Charts/AllLicePerFish/Controller';
import AverageWeight from 'components/Charts/AverageWeight/Chart';
import SiteConditions from 'components/Charts/Conditions/Chart';
import SiteConditionsControl from 'components/Charts/Conditions/Controller';
import CustomControl from 'components/Charts/Custom/Controller';
import EquipmentChart from 'components/Charts/Equipment/Chart';
import FeedUse from 'components/Charts/FeedUse/Chart';
import FeedUseControl from 'components/Charts/FeedUse/Controller';
import {
  default as BargeHydrography,
  ChartSettings as BargeHydrographyOptions
} from 'components/Charts/Hydrography/Chart';
import BargeHydrographyControl from 'components/Charts/Hydrography/Controller';
import MortalityByCause, {
  ChartSettings as MortalityByCauseSettings
} from 'components/Charts/MortalityByCause/Chart';
import MortalityByCauseControl from 'components/Charts/MortalityByCause/Controller';
import MotileLicePerFish from 'components/Charts/MotileLicePerFish/Chart';
import { Chart as AtmosphericConditions } from 'components/Charts/AtmosConditions/Chart';
import { WindRose } from 'components/Charts/WindRose/Chart';
import PercentageMortality from 'components/Charts/PercentageMortality/Chart';
import TrendsController from 'components/Charts/Plankton/TrendsController';
import PlanktonDiscreteSample from 'components/Charts/PlanktonDiscreteSample/Chart';
import PlanktonDiscreteSampleControl from 'components/Charts/PlanktonDiscreteSample/Controller';
import PlanktonRecentTrendsDetail from 'components/Charts/PlanktonRecentTrendsDetail/Chart';
import PlanktonSpeciesPresent from 'components/Charts/PlanktonSpeciesPresent/Chart';
import Rivers from 'components/Charts/Rivers/Chart';
import RiversControl from 'components/Charts/Rivers/Controller';
import { UseRiverTraceParams } from 'components/Charts/Rivers/useRiversTrace';
import SiwiDailyStress from 'components/Charts/SiwiDailyStress/Chart';
import SiwiDailyStressControl from 'components/Charts/SiwiDailyStress/Controller';
import SFR from 'components/Charts/SpecificFeedingRate/Chart';
import Tides from 'components/Charts/Tides/Chart';
import TidesController from 'components/Charts/Tides/Controller';
import TotalFish from 'components/Charts/TotalFish/Chart';
import TotalFishControl from 'components/Charts/TotalFish/Controller';
import Treatments from 'components/Charts/Treatments/Chart';
import TreatmentsControl from 'components/Charts/Treatments/Controller';
import { ProjectContext } from 'contexts/ProjectContext';
import { useContext, useMemo } from 'react';
import { Site } from 'graphql/generated';
import { BaseChartSettings, ExploreChart } from 'components/Charts/types';
import ExploreEquipmentControl from './Controllers/ExploreEquipmentController';
import Custom from 'components/Charts/Custom/Chart';
import { sortBy } from 'lodash';

const useExploreCharts = ({ site }: { site?: Site }) => {
  const project = useContext(ProjectContext);

  const siteTypeChartFilters = {
    'Seed Area': [
      'Custom',
      'Plankton Discrete Sample',
      'Plankton Trends',
      'Plankton Species Presence'
    ],
    ASC: ['Custom'],
    Pilot: ['Custom', 'Plankton Discrete Sample', 'Plankton Trends', 'Plankton Species Presence']
  };

  const charts: ExploreChart<BaseChartSettings, any>[] = useMemo(() => {
    const baseCharts = [
      {
        id: 'custom',
        displayName: 'Custom',
        chart: Custom,
        control: CustomControl,
        initSettings: {
          project,
          site
        },
        custom: true
      },
      ...sortBy(
        [
          {
            id: 'tides',
            displayName: 'Tides',
            chart: Tides,
            control: TidesController,
            yieldedControl: true,
            initSettings: {
              showAxisTitle: true,
              site,
              project
            }
          },
          {
            id: 'rivers',
            displayName: 'River Gauges',
            chart: Rivers,
            control: RiversControl,
            yieldedControl: true,
            initSettings: {
              site,
              project,
              includeStationList: site?.riverStations?.include ?? [],
              excludeStationList: site?.riverStations?.exclude ?? []
            } as UseRiverTraceParams
          },
          {
            id: 'barge-hydrography',
            displayName: 'Barge Hydrography',
            chart: BargeHydrography,
            control: BargeHydrographyControl,
            initInputs: site ? null : { availableSites: project.sites },
            initSettings: {
              project,
              skipForecasting: true,
              showParameterSelect: true,
              site: site ?? project.sites[0],
              parameters: ['waterTempC', 'oxygenSat', 'salinityPsu', 'visibility']
            } as BargeHydrographyOptions
          },
          {
            id: 'site-conditions',
            displayName: 'Site Conditions',
            chart: SiteConditions,
            control: SiteConditionsControl,
            initSettings: {
              useHires: false,
              project,
              site
            }
          },
          {
            id: 'absolute-mortality',
            displayName: 'Absolute Mortality',
            chart: AbsoluteMortality,
            control: AbsoluteMortalityControl,
            initSettings: {
              project,
              site
            }
          },
          {
            id: 'mortality-by-cause',
            displayName: 'Mortality by Cause',
            chart: MortalityByCause,
            control: MortalityByCauseControl,
            yieldedControl: true,
            initSettings: {
              project,
              site
            } as MortalityByCauseSettings,
            initInputs: site ? null : { sublocations: project.sites.map((s) => s.name) }
          },
          {
            id: 'percentage-mortality',
            displayName: 'Percentage Mortality',
            chart: PercentageMortality,
            initSettings: {
              project,
              site
            }
          },
          {
            id: 'total-fish',
            displayName: 'Total Fish',
            chart: TotalFish,
            control: TotalFishControl,
            initSettings: {
              project,
              site
            }
          },
          {
            id: 'average-weight',
            displayName: 'Average Weight',
            chart: AverageWeight,
            initSettings: {
              project,
              site
            }
          },
          {
            id: 'treatments',
            displayName: 'Treatments',
            chart: Treatments,
            control: TreatmentsControl,
            yieldedControl: true,
            initInputs: {
              availableSublocations: site ? site.sublocations : project.sites.map((s) => s.name)
            },
            initSettings: {
              project,
              site,
              selectedSublocation: 'All'
            }
          },
          {
            id: 'feed-use',
            displayName: 'Feed Use',
            chart: FeedUse,
            control: FeedUseControl,
            initSettings: {
              project,
              site
            }
          },
          {
            id: 'specific-feed-rate',
            displayName: 'Specific Feed Rate',
            chart: SFR,
            initSettings: {
              project,
              site
            }
          },
          {
            id: 'siwi-daily-stress',
            displayName: 'SIWI Daily Stress',
            chart: SiwiDailyStress,
            control: SiwiDailyStressControl,
            initInputs: site ? null : { availableSites: project.sites },
            initSettings: {
              project,
              site: site ?? project.sites[0]
            }
          },
          {
            id: 'plankton-discrete-sample',
            displayName: 'Plankton Discrete Sample',
            chart: PlanktonDiscreteSample,
            control: PlanktonDiscreteSampleControl,
            yieldedControl: true,
            initInputs: site ? null : { availableSublocations: project.sites.map((s) => s.name) },
            initSettings: {
              project,
              site,
              sublocations: ['All']
            }
          },
          {
            id: 'plankton-trends',
            displayName: 'Plankton Trends',
            chart: PlanktonRecentTrendsDetail,
            control: TrendsController,
            yieldedControl: true,
            initInputs: site
              ? null
              : { availableSublocations: project.sites.map((s) => s.name), availableSpecies: [] },
            initSettings: {
              project,
              site,
              selectedSublocations: ['All'],
              selectedSpecies: 'All',
              depthAveraged: true,
              groupSublocations: true
            }
          },
          {
            id: 'plankton-species-presence',
            displayName: 'Plankton Species Presence',
            chart: PlanktonSpeciesPresent,
            initSettings: {
              project,
              site
            }
          },
          {
            id: 'motile-lice-per-fish',
            displayName: 'Motile Lice Per Fish',
            chart: MotileLicePerFish,
            initSettings: {
              project,
              site
            }
          },
          {
            id: 'atmospheric-conditions',
            displayName: 'Atmospheric Conditions',
            chart: AtmosphericConditions,
            initSettings: {
              project,
              site
            }
          },
          {
            id: 'wind-rose',
            displayName: 'Wind Rose',
            chart: WindRose,
            initSettings: {
              project,
              site,
              showDateRange: false
            }
          },
          {
            id: 'all-lice-per-fish',
            displayName: 'All Lice Per Fish',
            chart: AllLicePerFish,
            control: AllLicePerFishControl,
            yieldedControl: true,
            initInputs: site ? null : { availableSublocations: project.sites.map((s) => s.name) },
            initSettings: {
              project,
              site
            }
          },
          {
            id: 'equipment',
            displayName: 'Equipment',
            chart: EquipmentChart,
            control: ExploreEquipmentControl,
            yieldedControl: true,
            initInputs: site ? null : { availableSites: project.sites },
            initSettings: {
              site: site ?? project.sites[0],
              project
            }
          }
        ],
        'displayName'
      )
    ];

    let charts: ExploreChart[] = baseCharts.filter((c) => {
      if (c.displayName.includes('SIWI')) {
        return project.siwi && (site ? site.tabs.includes('Siwi') : true);
      } else if (c.id === 'atmospheric-conditions' && site && !site.tabs.includes('Weather')) {
        return false;
      } else {
        return true;
      }
    });

    if (site && Object.keys(siteTypeChartFilters).includes(site.siteLabel)) {
      charts = charts.filter((c) => siteTypeChartFilters[site.siteLabel].includes(c.displayName));
    }

    return charts;
  }, [site, project, project.siwi]);

  return { charts };
};

export default useExploreCharts;
