import { FormControl, FormLabel, Textarea, TextareaProps } from '@chakra-ui/react';
import { useController, UseControllerProps } from 'react-hook-form';

type FormTextareaProps<TForm> = {
  label: string;
} & UseControllerProps<TForm> &
  TextareaProps;

const FormTextarea = <TForm,>({ label, ...props }: FormTextareaProps<TForm>) => {
  const { field, fieldState } = useController(props);

  const { value, ...fieldProps } = field;

  return (
    <FormControl
      isInvalid={!!fieldState.error}
      isRequired={props.rules?.required as boolean}
      mb="20px">
      <FormLabel mb="0px">{label}</FormLabel>
      <Textarea {...props} {...fieldProps} value={value as string} />
    </FormControl>
  );
};

export default FormTextarea;
