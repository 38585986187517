import TabHeadline from 'components/TabHeadline';
import { AscReport, Site } from 'graphql/generated';
import { useState } from 'react';
import { TbReportAnalytics } from 'react-icons/tb';
import { ASCReportList } from './ASCReportList';
import EntryForm from './Entry/EntryForm';

interface ASCTabProps {
  site: Site;
}

export function ASCEntry({ site }: ASCTabProps) {
  const [formIsVisible, setFormIsVisible] = useState(false);
  const [activeReport, setActiveReport] = useState<AscReport | null>(null);

  function handleReportFormOpen(report: AscReport | null) {
    setFormIsVisible(true);
    setActiveReport(report);
  }
  return (
    <>
      <TabHeadline text="Submit data from your ASC reference sites." icon={<TbReportAnalytics />} />
      <div data-cypress={'asc-tab'}>
        <ASCReportList site={site} openReportForm={handleReportFormOpen} />
        {formIsVisible && (
          <EntryForm report={activeReport} site={site} onClose={() => setFormIsVisible(false)} />
        )}
      </div>
    </>
  );
}
