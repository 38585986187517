import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { Box, Button, Divider, HStack, IconButton, VStack } from '@chakra-ui/react';
import { BinaryOperator, TCubeDimension, TCubeMeasure } from '@cubejs-client/core';
import FormInput from 'components/Forms/FormInput';
import FormSelect from 'components/Forms/FormSelect';
import { Path, useFieldArray, UseFieldArrayProps } from 'react-hook-form';

export const supportedOperators: { label: string; value: BinaryOperator }[] = [
  { label: '=', value: 'equals' },
  { label: '!=', value: 'notEquals' },
  { label: 'Contains', value: 'contains' },
  { label: 'Not Contains', value: 'notContains' },
  { label: '>', value: 'gt' },
  { label: '>=', value: 'gte' },
  { label: '<', value: 'lt' },
  { label: '<=', value: 'lte' }
];

export type FormFilter = {
  member: { label: string; value: TCubeDimension | TCubeMeasure };
  operator: { label: string; value: BinaryOperator };
  value: string;
};

type FormCubeFiltersListProps<TForm> = {
  members: { label: string; value: TCubeDimension | TCubeMeasure }[];
} & UseFieldArrayProps<TForm>;

const FormCubeFiltersList = <TForm,>({ members, ...props }: FormCubeFiltersListProps<TForm>) => {
  const { fields, append, remove } = useFieldArray({ control: props.control, name: props.name });

  return (
    <VStack alignItems="start">
      <Button
        isDisabled={members.length === 0}
        onClick={() => {
          //@ts-ignore
          append({});
        }}
        fontSize="smaller"
        variant="outline">
        Add Filter <AddIcon ml="5px" mb="3px" />
      </Button>
      {fields.map((f, i) => {
        return (
          <Box key={f.id}>
            <Divider mb="15px" />
            <HStack maxW="800px">
              <FormSelect
                label="Filter"
                control={props.control}
                name={`${props.name}.${i}.member` as Path<TForm>}
                options={members}
              />
              <FormSelect
                w="400px"
                options={supportedOperators}
                control={props.control}
                name={`${props.name}.${i}.operator` as Path<TForm>}
                label="Operator"
              />
              <FormInput
                control={props.control}
                tooltip="Values can be comma separated to include multiple values. ex. cage-1, cage-2"
                name={`${props.name}.${i}.value` as Path<TForm>}
                label="Value(s)"
              />
              <IconButton
                onClick={() => {
                  remove(i);
                }}
                icon={<DeleteIcon />}
                aria-label={'Delete Filter'}
                colorScheme="red"
              />
            </HStack>
            <Divider mt="15px" />
          </Box>
        );
      })}
    </VStack>
  );
};

export default FormCubeFiltersList;
