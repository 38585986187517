import { FormControl, FormLabel, HStack, Switch, Text } from '@chakra-ui/react';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';
import Select from 'react-select';

export type ControllerInputs = {
  availableSublocations: string[];
};

const Controller = ({
  chartSettings,
  setChartSettings,
  controllerSettings,
  chartInputs: { availableSublocations } = { availableSublocations: [] }
}: ChartController<ChartSettings, ControllerInputs>) => {
  const locationOptions = [
    { label: 'All', value: 'All' },
    ...availableSublocations.map((s) => ({ label: s, value: s }))
  ];

  return (
    <HStack alignContent="center" justify="center">
      {controllerSettings?.showSublocations && (
        <>
          <Text>Sublocations</Text>
          <Select
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                width: '300px',
                textAlign: 'left'
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                textAlign: 'left'
              })
            }}
            isMulti
            value={chartSettings?.sublocations?.map((s) => ({ label: s, value: s }))}
            placeholder="Select Sublocation(s)"
            onChange={(values) =>
              setChartSettings({ ...chartSettings, sublocations: values.map((v) => v.label) })
            }
            options={locationOptions}
          />
        </>
      )}
      <FormControl w="auto">
        <FormLabel display="inline" marginInlineEnd="0">
          Risk Threshold
        </FormLabel>
        <Switch
          id="group_sublocs_toggle"
          onChange={(e) =>
            setChartSettings({ ...chartSettings, displayConcentration: e.target.checked })
          }
          isChecked={chartSettings.displayConcentration}
          colorScheme="blue"
          ml="1rem"
          mr="1rem"
        />
        <FormLabel display="inline">Avg. Concentration</FormLabel>
      </FormControl>
    </HStack>
  );
};

export default Controller;
