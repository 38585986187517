import { BaseChartProps } from '../types';
import { BasicLineChart } from '../BasicLineChart';

type AtmosDatum = {
  'TessAtmos.avgSolarRadiation': number;
  'TessAtmos.measuredAt': string;
};

const SolarChart = (props: BaseChartProps) => {
  return (
    <BasicLineChart<AtmosDatum>
      className="atmos-solar-chart"
      {...props}
      settings={{
        ...props.settings,
        traces: [
          { title: 'Solar Radiation', measure: 'TessAtmos.avgSolarRadiation', yAxisLabel: 'W/m2' }
        ],
        timeDimension: 'TessAtmos.measuredAt',
        xAxisLabel: 'Hour'
      }}
    />
  );
};

export { SolarChart };
