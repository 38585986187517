import { Center, HStack, Image, Text, Tooltip } from '@chakra-ui/react';
import { ProjectContext } from 'contexts/ProjectContext';
import { capitalize, max, pickBy } from 'lodash';
import { useContext } from 'react';
import { planktonThresholdToColor, thresholdForSpeciesValue } from 'shared/plankton';

interface PlanktonDataCellProps {
  counts: {
    species: string | number;
    [depth: string]: string | number;
  }[];
}

const getMaxCount = (counts: PlanktonDataCellProps['counts'][0]) => {
  return max(Object.values(counts).filter((cv) => !isNaN(Number(cv))));
};

const thresholdOrder = ['danger', 'caution', 'safe'];

export function PlanktonDataCell({ counts }: PlanktonDataCellProps) {
  const projectContext = useContext(ProjectContext);

  const definedCounts = counts.filter((c) =>
    projectContext.findPlanktonPolicy(c.species.toString())
  );

  const withThresholds = definedCounts.map((c) => {
    {
      const withoutTow = pickBy(
        c,
        (_value, key) => !key.includes('tow')
      ) as PlanktonDataCellProps['counts'][0];

      return {
        ...withoutTow,
        threshold: thresholdForSpeciesValue(
          projectContext,
          withoutTow.species as string,
          //@ts-ignore
          getMaxCount(withoutTow)
        )
      };
    }
  });

  withThresholds.sort(
    (a, b) => thresholdOrder.indexOf(a.threshold) - thresholdOrder.indexOf(b.threshold)
  );

  const planktonFallback = (record: { threshold: string; species: string | number }) => {
    const policy = projectContext.findPlanktonPolicy(record.species.toString());

    const parts = policy.name.split(' ');
    let abbr = policy.name.toString()[0].toUpperCase();

    if (parts.length == 2) {
      abbr += ` ${parts[1][0].toUpperCase()}`;
    }

    // Not sure why I have to re-wrap the tooltip, but, it wasn't showing before without an image.
    return (
      <Tooltip label={policy.name}>
        <Center w="2em" h="2em" key={record.species}>
          <Text>{abbr}</Text>
        </Center>
      </Tooltip>
    );
  };

  return (
    <HStack>
      {withThresholds.length === 0 && <Text>None</Text>}
      {withThresholds.slice(0, 5).map((r) => {
        const policy = projectContext.findPlanktonPolicy(r.species.toString());
        return (
          <Center p="5px" backgroundColor={planktonThresholdToColor(r.threshold)} key={r.species}>
            <Tooltip label={policy.name}>
              <Image
                fallback={planktonFallback(r)}
                key={r.species}
                alt={policy.name}
                src={`/plankton/${capitalize(r.species.toString())}.png`}
                w="2em"
                h="2em"
                className="species-observed-row"
              />
            </Tooltip>
          </Center>
        );
      })}
    </HStack>
  );
}
