import { Box, Center } from '@chakra-ui/react';
import React from 'react';
import './FishLoader.css';

const Fish = ({ fishId, width }: { fishId: number; width: string }) => (
  <Box w="100%" className={`fish fish-${fishId}`}>
    <svg width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path
        fill="currentColor"
        d="M327.1 96c-89.97 0-168.54 54.77-212.27 101.63L27.5 131.58c-12.13-9.18-30.24.6-27.14 14.66L24.54 256 .35 365.77c-3.1 14.06 15.01 23.83 27.14 14.66l87.33-66.05C158.55 361.23 237.13 416 327.1 416 464.56 416 576 288 576 256S464.56 96 327.1 96zm87.43 184c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24 13.26 0 24 10.74 24 24 0 13.25-10.75 24-24 24z"
      />
    </svg>
  </Box>
);

const Fry = ({ size = 'md', fishId = 0 }: { size?: 'xs' | 'sm' | 'md'; fishId: number }) => {
  let pxSize = '60px';

  if (size == 'xs') {
    pxSize = '20px';
  } else if (size == 'sm') {
    pxSize = '35px';
  }

  return <Fish width={pxSize} fishId={fishId} />;
};

const FishLoader = (props: React.ComponentProps<typeof Center>) => {
  return (
    <Center className="backdrop" w="100%" position="relative" {...props} color="blue.500">
      <Fry fishId={0} size="xs" />
      <Fry fishId={1} />
      <Fry fishId={2} size="sm" />
    </Center>
  );
};

export default FishLoader;
