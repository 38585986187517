import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle
} from '@chakra-ui/react';
import { useContext } from 'react';
import { ReportingEditContext } from './ReportingEditContext';

const ReportStepper = () => {
  const { steps, activeStep, setActiveStep } = useContext(ReportingEditContext);

  return (
    <Stepper w="100%" index={activeStep - 1}>
      {steps.map((step, index) => (
        <Step key={index} onClick={() => setActiveStep(index + 1)}>
          <StepIndicator cursor={'pointer'}>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>
          <Box flexShrink="0">
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
};

export default ReportStepper;
