import FormDataGrid from 'components/Forms/DataGrid/FormDataGrid';
import GridNumberEditor from 'components/Forms/DataGrid/GridNumberEditor';
import { useMemo } from 'react';
import { Column } from 'react-data-grid';
import { useController, UseControllerProps } from 'react-hook-form';
import { HydrographyType } from '../hydrographyUtils';
import { Form } from './EntryForm';

type EntryGridProps = {
  depthColumns: { label: string; value: string }[];
} & UseControllerProps<Form, 'measures'>;

export type HydrographyRow = {
  type: HydrographyType;
  [depth: string]: string | number;
};

const getColumns = (
  depthColumns: { label: string; value: string }[]
): readonly Column<HydrographyRow>[] => {
  return [
    {
      key: 'measure',
      name: 'Measure',
      width: 250,
      editable: false
    },
    ...depthColumns.map<Column<HydrographyRow>>((labelValue) => ({
      key: labelValue.value,
      name: labelValue.label,
      width: 150,
      editor: GridNumberEditor
    }))
  ];
};

const EntryGrid = ({ depthColumns, ...props }: EntryGridProps) => {
  const { field, fieldState } = useController(props);
  const columns = useMemo(() => getColumns(depthColumns), [depthColumns]);

  return (
    <FormDataGrid
      fieldState={fieldState}
      columns={columns}
      rows={field.value}
      onRowsChange={(rows) => field.onChange(rows)}
    />
  );
};

export default EntryGrid;
