import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

const PageLayout = () => {
  return (
    <Box w="100%" padding="0 2rem">
      <Outlet />
    </Box>
  );
};

export default PageLayout;
