import { ProjectContextType } from 'contexts/ProjectContext';
import { concentrationNumFormatter } from './TraceHelpers';

export type PlanktonValue = {
  depth: number;
  time: Date;
  // source_id: string;
  // source: any;
  thresholdVal: number;
  threshold: number[];
  value: number;
  info: string;
};

export function aggregatePlankton(
  project: ProjectContextType,
  planktonValues,
  valueName: string,
  granularity = 'minute'
): PlanktonValue[] {
  //HA 12/11/2020: goal of this function is to aggregate plankton across species and sublocations
  //with the highest threshold val being displayed, and all other plankton values
  //above zero being kept so they can be displayed on hover on the graph
  const aggregatedPlankton = planktonValues.reduce((agg, curr) => {
    const measuredAt = curr[`TessPlankton.measuredAt.${granularity}`];
    if (!agg[curr['TessPlanktonLookup.depth'].toString() + '_' + measuredAt]) {
      // add data
      agg[curr['TessPlanktonLookup.depth'].toString() + '_' + measuredAt] = {
        depth: curr['TessPlanktonLookup.depth'],
        time: measuredAt,
        // source_id: curr.source.id, // used by EventPlotlyWrapper to determine trace
        // source: curr.source,
        thresholdVal: curr['thresholdVal'],
        threshold: curr['threshold'],
        value: curr[valueName],
        info: '<b>No plankton activity detected</b><br>'
      };
    }
    if (
      agg[curr['TessPlanktonLookup.depth'].toString() + '_' + measuredAt].thresholdVal <
      curr['thresholdVal']
    ) {
      agg[curr['TessPlanktonLookup.depth'].toString() + '_' + measuredAt].thresholdVal =
        curr['thresholdVal'];
      agg[curr['TessPlanktonLookup.depth'].toString() + '_' + measuredAt].threshold =
        curr['threshold'];
    }
    if (parseFloat(curr[valueName]) > 0) {
      if (
        agg[curr['TessPlanktonLookup.depth'].toString() + '_' + measuredAt].info ===
        '<b>No plankton activity detected</b><br>'
      ) {
        agg[curr['TessPlanktonLookup.depth'].toString() + '_' + measuredAt].info =
          '<b>Plankton species detected (cells/mL):</b><br>';
      }
      agg[curr['TessPlanktonLookup.depth'].toString() + '_' + measuredAt].info =
        agg[curr['TessPlanktonLookup.depth'].toString() + '_' + measuredAt].info +
        `<b>${project.findPlanktonPolicy(curr['TessPlanktonLookup.species']).name} at ${
          curr['TessPlanktonLookup.sublocation']
        } (${concentrationNumFormatter(parseFloat(curr[valueName]), 3, 5)})</b><br>`;
    }
    return agg;
  }, {});
  return Object.values(aggregatedPlankton);
}
