import JSZip from 'jszip';
import { saveAs } from 'file-saver';

type CsvExport = {
  filename: string;
  data: string[][];
};

export const toCsvZip = (exports: CsvExport[]) => {
  const zip = new JSZip();

  exports.forEach(({ filename, data }) => {
    const csvContent = data.map((e) => e.join(',')).join('\n');

    zip.file(filename, csvContent);
  });

  zip
    .generateAsync({ type: 'blob' })
    .then((content) => saveAs(content, 'SeaStateExploreExport.zip'));
};
