// @ts-strict
import { useQuery } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useCubeQuery } from '@cubejs-client/react';
import { THRESHOLD_LEVEL } from 'components/types';
import { ProjectContext, ProjectContextType } from 'contexts/ProjectContext';
import { Site, StationType } from 'graphql/generated';
import { GET_SITES } from 'graphql/globalQueries';
import { Dictionary, cloneDeep, forEach, groupBy, orderBy, uniq } from 'lodash';
import { Reducer, useContext, useEffect, useReducer, useState } from 'react';
import { thresholdForSpeciesValue } from 'shared/plankton';
import getSiteStatusIcon from './getSiteStatusIcon';

export interface MapSite {
  id: number;
  smbId: number;
  name: string;
  type: string;
  fishCount?: number;
  image: string;
  zIndex: number;
  lastSampleTimestamps: Record<string, string | undefined>;
  oxygenAlerts: {
    level: THRESHOLD_LEVEL;
    sublocation?: string;
    value?: number;
    measuredAt?: string;
  }[];
  planktonAlerts: {
    level: THRESHOLD_LEVEL;
    species?: string;
    sublocation?: string;
    count?: number;
    measuredAt?: string;
  }[];
  position: {
    lat: number;
    lon: number;
  };
  archived: boolean;
}

interface UseMapSiteState {
  loadingThresholdData: boolean;
  loadingSites: boolean;
  loadingOxygen: boolean;
  loadingPlankton: boolean;
  loadingFishCounts: boolean;
  error: boolean;
  colorSitesBy: string;
  availableSiteTypes: string[];
  visibleSiteTypes: string[];
  availableStationTypes: StationType[];
  visibleStationTypes: StationType[];
  showingArchivedSites: boolean;
  // Use a dictionary for ALL sites to make lookups quick when new data gets added
  //  This is the only place Sites are fully represented. All access should use this by looking an id up.
  // **Notice that there is a distinction between 'id' and 'smbId' - the 'smbId' is used to retrieve data from SMB**
  sitesById: Dictionary<MapSite>;
  visibleSiteIds: number[];
}

// Setting the threshold is added after/outside of the state
//   because it uses a dispatch against the reducer.
interface UseMapSitesValue extends UseMapSiteState {
  setSiteColorBy: (threshold: string) => void;
  setVisibleSiteTypes: (types: string[]) => void;
  setVisibleStationTypes: (types: StationType[]) => void;
}

type Action =
  | { type: 'triggerError' }
  | { type: 'loadSites'; sites: Site[] }
  | {
      type: 'loadOxygen';
      do: { smbSiteId: number; sublocation: string; value: number }[];
      thresholds: number[];
    }
  | {
      type: 'loadPlankton';
      plankton: {
        siteId: number;
        sublocation: string;
        value: number;
        species: string;
        time: string;
      }[];
      project: ProjectContextType;
    }
  | { type: 'loadFishCounts'; fishCounts: { siteId: string; value: string }[] }
  | { type: 'allThresholdsLoaded' }
  | { type: 'setVisibleSiteTypes'; types: string[] }
  | { type: 'setVisibleStationTypes'; types: StationType[] }
  | { type: 'setSiteColorBy'; colorBy: string };

const SITE_TYPE_Z_INDEX: Record<string, number> = {
  Farm: 5,
  Sensor: 4,
  'Seed Area': 3,
  ASC: 2,
  Pilot: 1
};

function setVisibleSites(sitesById: Dictionary<MapSite>, visibleSiteTypes: string[]) {
  const visibleSiteIds: number[] = [];

  forEach(sitesById, (site) => {
    if (!site.archived || (site.archived && visibleSiteTypes.includes('Archived'))) {
      if (visibleSiteTypes.includes(site.type)) {
        visibleSiteIds.push(site.id);
      }
    }
  });
  return visibleSiteIds;
}

function loadSites(prevState: UseMapSiteState, blSites: Site[]): UseMapSiteState {
  const availableSiteTypes: string[] = [];
  const visibleSiteIds: number[] = [];
  const sitesById: Dictionary<MapSite> = {};
  blSites.forEach((site) => {
    // TODO - These are typed by Apollo as Maybe<string>
    //   shouldn't at least siteLabel be required/not maybe? - MEA
    if (!site.smbId || !site.siteLabel || !site.lat || !site.lon) {
      console.warn(
        `Site ${site.id}:${site.name} is missing some attributes needed to render! Skipping.`
      );
      return;
    }
    const siteType = site.siteLabel;
    if (!availableSiteTypes.includes(siteType)) {
      availableSiteTypes.push(siteType);
    }
    if (site.archived && !availableSiteTypes.includes('Archived')) {
      availableSiteTypes.push('Archived');
    }
    if (prevState.visibleSiteTypes.includes(siteType) && !site.archived) {
      visibleSiteIds.push(site.id);
    }
    sitesById[site.id] = {
      id: site.id,
      smbId: site.smbId,
      name: site.name,
      type: siteType,
      zIndex: SITE_TYPE_Z_INDEX[siteType] || 1,
      image: getSiteStatusIcon(siteType, site.archived, '', {}, undefined, true),
      oxygenAlerts: [],
      planktonAlerts: [],
      lastSampleTimestamps: {},
      position: {
        lat: site.lat,
        lon: site.lon
      },
      archived: site.archived
    };
  });
  return {
    ...prevState,
    loadingSites: false,
    sitesById,
    availableSiteTypes,
    visibleSiteIds
  };
}

function loadSMBFishCounts(sitesById: Dictionary<MapSite>, action: Action) {
  if (action.type !== 'loadFishCounts') return sitesById;
  const sitesWithFishCounts = cloneDeep(sitesById);
  const factsPerSite = groupBy(action.fishCounts, 'siteId');
  // All sites will either get counts from values or 0 here.
  //   Sites with no values in this dataset are to be considered fallow and have 0 fish.
  // TODO - Should this be a boolean isFallow? This was adding up all of the fish, resulting in a number
  //   much higher than the fish count at a site. - MEA
  Object.keys(sitesWithFishCounts).forEach((siteId) => {
    const smbId = sitesWithFishCounts[siteId].smbId;
    sitesWithFishCounts[siteId].fishCount = factsPerSite[smbId]
      ? factsPerSite[smbId]
          .filter((f) => f.value)
          .reduce((prev, curr) => {
            return prev + parseInt(curr.value);
          }, 0)
      : 0;
  });
  return sitesWithFishCounts;
}

function loadPlanktonData(sitesById: Dictionary<MapSite>, action: Action) {
  if (action.type !== 'loadPlankton') return sitesById;
  const factsPerSite = groupBy(action.plankton, 'smbSiteId');

  return Object.entries(sitesById).reduce((acc, [siteId, site]) => {
    const facts = factsPerSite[site.smbId] ?? [];

    const bySublocation = groupBy(facts, 'sublocation');

    const sublocationThresholds: MapSite['planktonAlerts'] = [];

    Object.entries(bySublocation).forEach(([sublocation, facts]) => {
      let level = THRESHOLD_LEVEL.NO_DATA;
      let datapoint = null;

      facts.forEach((f) => {
        const threshold = thresholdForSpeciesValue(action.project, f.species, f.value);

        if (!threshold) return;

        if (threshold === 'danger') {
          level = THRESHOLD_LEVEL.DANGER;
          datapoint = f;
        } else if (threshold === 'caution') {
          level = THRESHOLD_LEVEL.CAUTION;
          datapoint = f;
        } else {
          level = THRESHOLD_LEVEL.SAFE;
          datapoint = f;
        }

        sublocationThresholds.push({
          sublocation,
          level,
          count: datapoint?.value,
          species: datapoint?.species ?? 'Unknown',
          measuredAt: datapoint?.measuredAt
        });
      });
    });

    acc[siteId] = {
      ...site,
      planktonAlerts:
        facts.length === 0
          ? [{ level: THRESHOLD_LEVEL.NO_DATA }]
          : orderBy(sublocationThresholds, 'level', 'desc')
    };

    return acc;
  }, {} as Dictionary<MapSite>);
}

function loadOxygenData(sitesById: Dictionary<MapSite>, action: Action) {
  if (action.type !== 'loadOxygen') return sitesById;

  const factsBySmbId = groupBy(action.do, 'smbSiteId');

  return Object.entries(sitesById).reduce((acc, [siteId, site]) => {
    const facts = factsBySmbId[site.smbId] ?? [];

    const bySublocation = groupBy(facts, 'sublocation');

    const sublocationThresholds: MapSite['oxygenAlerts'] = [];
    Object.entries(bySublocation).forEach(([sublocation, facts]) => {
      let level = THRESHOLD_LEVEL.NO_DATA;
      let datapoint = null;

      facts.forEach((f) => {
        if (f.value <= action.thresholds[1]) {
          level = THRESHOLD_LEVEL.DANGER;
          datapoint = f;
        } else if (f.value <= action.thresholds[0]) {
          level = THRESHOLD_LEVEL.CAUTION;
          datapoint = f;
        } else {
          level = THRESHOLD_LEVEL.SAFE;
          datapoint = f;
        }
      });

      sublocationThresholds.push({
        sublocation,
        level,
        value: datapoint?.value,
        measuredAt: datapoint?.measuredAt
      });
    });

    acc[siteId] = {
      ...site,
      oxygenAlerts:
        facts.length === 0
          ? [{ level: THRESHOLD_LEVEL.NO_DATA }]
          : orderBy(sublocationThresholds, 'level', 'desc')
    };

    return acc;
  }, {} as Dictionary<MapSite>);
}

const colorByThresholds = (prevSites: Dictionary<MapSite>, colorSitesBy: string) => {
  return Object.entries(prevSites).reduce((curr, [id, site]) => {
    curr[id] = {
      ...site,
      image: getSiteStatusIcon(
        site.type,
        site.archived,
        colorSitesBy,
        {
          plankton: site.planktonAlerts?.[0]?.level ?? THRESHOLD_LEVEL.NO_DATA,
          oxygen_saturation: site.oxygenAlerts?.[0]?.level ?? THRESHOLD_LEVEL.NO_DATA
        },
        site.fishCount
      )
    };
    return curr;
  }, {} as Dictionary<MapSite>);
};

/**
 * When updating this reducer, be sure to return a totally new state after any update,
 *   rather than altering the previous state and returning a mutated reference to the same object.
 *   Mutating previous state will cause unexpected edge cases.
 * If you'd like to mutate state in a sub-reducer, perform a cloneDeep or spread before doing so.
 * Check out Redux's Best Practices guide and apply that here:
 * https://redux.js.org/style-guide/#priority-a-rules-essential
 * @param prevState
 * @param action
 */
const reducer: Reducer<UseMapSiteState, Action> = (prevState, action) => {
  switch (action.type) {
    case 'triggerError':
      return {
        ...prevState,
        error: true
      };
    case 'setVisibleSiteTypes':
      return {
        ...prevState,
        visibleSiteIds: setVisibleSites(prevState.sitesById, action.types),
        visibleSiteTypes: action.types
      };
    case 'setVisibleStationTypes':
      return {
        ...prevState,
        visibleStationTypes: action.types
      };
    case 'setSiteColorBy':
      return {
        ...prevState,
        colorSitesBy: action.colorBy,
        sitesById: colorByThresholds(prevState.sitesById, action.colorBy)
      };
    case 'loadSites':
      return {
        ...loadSites(prevState, action.sites)
      };
    case 'loadOxygen':
      return {
        ...prevState,
        sitesById: loadOxygenData(prevState.sitesById, action),
        loadingOxygen: false
      };
    case 'loadPlankton':
      return {
        ...prevState,
        sitesById: loadPlanktonData(prevState.sitesById, action),
        loadingPlankton: false
      };
    case 'loadFishCounts':
      return {
        ...prevState,
        sitesById: loadSMBFishCounts(prevState.sitesById, action),
        loadingFishCounts: false
      };
    case 'allThresholdsLoaded':
      return {
        ...prevState,
        sitesById: colorByThresholds(prevState.sitesById, prevState.colorSitesBy),
        loadingThresholdData: false
      };
    default:
      return prevState;
  }
};

/**
 * Manage the loading and state of sites for display on the map.
 *   Loads data such as DO/Plankton/etc and puts into a reducer.
 *   Sites ultimately stored in a map object by siteId.
 */
function useMapSites(): UseMapSitesValue {
  const projectContext = useContext(ProjectContext) as ProjectContextType;
  const toast = useToast();
  const [renderedErrors, setRenderedErrors] = useState<string[]>([]);

  const availableStationTypes = uniq(projectContext.region.stations.map((s) => s.type));

  const [mapSiteState, dispatchSiteAction] = useReducer(reducer, {
    loadingSites: true,
    loadingThresholdData: true,
    loadingOxygen: true,
    loadingPlankton: true,
    loadingFishCounts: true,
    error: false,
    sitesById: {},
    visibleSiteIds: [],
    colorSitesBy: 'all',
    // TODO - Should this be set explicitly on the Project Context? - MEA
    visibleSiteTypes:
      projectContext?.id === 2 ? ['Farm', 'Sensor', 'Seed Area', 'ASC'] : ['Farm', 'Sensor'],
    availableStationTypes,
    visibleStationTypes: [],
    availableSiteTypes: [],
    showingArchivedSites: false
  });
  const sitesQuery = useQuery<{ sites: Site[] }>(GET_SITES, {
    variables: { pid: projectContext?.id, includeArchived: true },
    onCompleted: (data) =>
      dispatchSiteAction({
        type: 'loadSites',
        sites: data.sites
      })
  });
  const oxygenData = useCubeQuery(
    {
      measures: ['TessSensorHydrography.oxygenSaturationMedian'],
      timeDimensions: [
        {
          dimension: 'TessSensorHydrography.measuredAt',
          dateRange: 'last 30 minutes from now',
          granularity: 'minute'
        }
      ],
      dimensions: ['Site.id', 'TessSensorHydrographyLookup.sublocation'],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: Object.values(mapSiteState.sitesById).map((sid) => sid.smbId.toString())
        },
        //Ignore deep sensor oxygen values in Grieg projects
        {
          member: 'TessSensorHydrographyLookup.depth',
          operator: 'lt',
          values: ['15']
        }
      ],
      timezone: projectContext.timezone
    },
    {
      skip:
        !projectContext.thresholds.oxygen_saturation ||
        Object.keys(mapSiteState.sitesById).length === 0,
      subscribe: true
    }
  );
  useEffect(() => {
    if (!oxygenData.isLoading && !oxygenData.error && oxygenData.resultSet) {
      const oxygenBySite = oxygenData.resultSet
        .rawData()
        .map((d) => {
          return {
            smbSiteId: d['Site.id'] as number,
            value: d['TessSensorHydrography.oxygenSaturationMedian'] as number,
            sublocation: d['TessSensorHydrographyLookup.sublocation'] as string,
            measuredAt: d['TessSensorHydrography.measuredAt'] as string
          };
        })
        .filter((d) => Number(d.value) !== 0);
      dispatchSiteAction({
        type: 'loadOxygen',
        do: oxygenBySite,
        thresholds: projectContext.thresholds.oxygen_saturation as number[]
      });
    }
  }, [
    oxygenData.isLoading,
    oxygenData.error,
    oxygenData.resultSet,
    projectContext.thresholds.oxygen_saturation
  ]);
  const planktonData = useCubeQuery(
    {
      measures: ['TessPlankton.maxCellCount'],
      dimensions: ['Site.id', 'TessPlanktonLookup.sublocation', 'TessPlanktonLookup.species'],
      timeDimensions: [
        {
          dimension: 'TessPlankton.measuredAt',
          dateRange: 'last 24 hours from now',
          granularity: 'hour'
        }
      ],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: Object.values(mapSiteState.sitesById).map((s) => s.smbId.toString())
        },
        {
          member: 'TessPlanktonLookup.method',
          operator: 'equals',
          values: ['discrete']
        }
      ],
      order: { 'TessPlankton.measuredAt': 'desc' },
      timezone: projectContext.timezone
    },
    {
      skip:
        projectContext.planktonPolicy.length === 0 ||
        Object.values(mapSiteState.sitesById).length === 0,
      subscribe: true
    }
  );
  useEffect(() => {
    if (!planktonData.isLoading && !planktonData.error && planktonData.resultSet) {
      const planktonBySite = planktonData.resultSet.rawData().map((d) => {
        return {
          smbSiteId: d['Site.id'],
          value: Number(d['TessPlankton.maxCellCount']),
          sublocation: d['TessPlanktonLookup.sublocation'],
          species: d['TessPlanktonLookup.species']
        };
      });
      dispatchSiteAction({
        type: 'loadPlankton',
        // @ts-ignore
        plankton: planktonBySite,
        planktonPolicy: projectContext,
        project: projectContext
      });
    }
  }, [
    planktonData.isLoading,
    planktonData.error,
    planktonData.resultSet,
    projectContext.planktonPolicy
  ]);

  const fishCountData = useCubeQuery(
    {
      measures: ['Biology.endCountSum'],
      dimensions: ['Site.id'],
      timeDimensions: [
        {
          dimension: 'Biology.measuredAt',
          dateRange: 'last 7 days',
          granularity: 'day'
        }
      ],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: Object.values(mapSiteState.sitesById).map((s) => s.smbId.toString())
        }
      ],
      timezone: projectContext.timezone
    },
    {
      skip: Object.values(mapSiteState.sitesById).length === 0,
      subscribe: true
    }
  );

  useEffect(() => {
    if (!fishCountData.isLoading && !fishCountData.error && fishCountData.resultSet) {
      const spoofFishDataProjects: number[] = [6];
      const fishBySite = spoofFishDataProjects.includes(projectContext.id)
        ? Object.values(mapSiteState.sitesById).map((s) => {
            return {
              siteId: s.smbId,
              value: 1
            };
          })
        : fishCountData.resultSet.rawData().map((d) => {
            return {
              siteId: d['Site.id'],
              value: d['Biology.endCountSum']
            };
          });
      dispatchSiteAction({
        type: 'loadFishCounts',
        // @ts-ignore
        fishCounts: fishBySite
      });
    }
  }, [fishCountData.isLoading, fishCountData.error, fishCountData.resultSet]);

  // When all data/color providing queries load up,
  //   we'll trigger thresholdsLoaded so site icons can be colored.
  useEffect(() => {
    if (!projectContext) return;
    if (!mapSiteState.loadingThresholdData) return;
    // These will not load if the project does not set thresholds for that type.
    const planktonLoaded =
      projectContext.planktonPolicy.length === 0 || !mapSiteState.loadingPlankton;
    const oxygenLoaded =
      projectContext.thresholds.oxygen_saturation.length === 0 || !mapSiteState.loadingOxygen;
    if (planktonLoaded && oxygenLoaded && !mapSiteState.loadingFishCounts) {
      dispatchSiteAction({ type: 'allThresholdsLoaded' });
    }
  }, [
    mapSiteState.loadingPlankton,
    mapSiteState.loadingOxygen,
    mapSiteState.loadingFishCounts,
    mapSiteState.loadingThresholdData,
    projectContext
  ]);

  function displayThresholdLoadError(threshold: string, error: Error) {
    if (!renderedErrors.includes(threshold)) {
      console.error(error);
      toast({
        title: 'Error',
        description: `Encountered error loading ${threshold} information on map`,
        status: 'error'
      });
      setRenderedErrors([...renderedErrors, threshold]);
    }
  }

  if (sitesQuery.error) {
    throw new Error(sitesQuery.error.message);
  }
  if (planktonData.error) {
    displayThresholdLoadError('plankton', planktonData.error);
  }
  if (oxygenData.error) {
    displayThresholdLoadError('oxygen', oxygenData.error);
  }
  if (fishCountData.error) {
    displayThresholdLoadError('fish count', fishCountData.error);
  }

  return {
    ...mapSiteState,
    setSiteColorBy: (colorBy) => dispatchSiteAction({ type: 'setSiteColorBy', colorBy }),
    setVisibleSiteTypes: (types) => dispatchSiteAction({ type: 'setVisibleSiteTypes', types }),
    setVisibleStationTypes: (types) => dispatchSiteAction({ type: 'setVisibleStationTypes', types })
  };
}

export default useMapSites;
