import { Skeleton } from '@chakra-ui/react';
import { ProjectContext } from 'contexts/ProjectContext';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, max } from 'lodash';
import { PlotlyDataLayoutConfig } from 'plotly.js';
import { useContext } from 'react';
import { createLocationPallet } from 'shared/functions/colorPallets';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import { convertToIndex, filterBestResultForDay, safeFormat, siwiDatum } from './SIWIShared';

type siwiHistStructure = {
  [cohortId: string]: {
    total: number;
    isCurrentCohort: boolean;
    measuredAt: string;
  }[];
};

const cohortHistTransform = (data: siwiDatum[]): siwiHistStructure => {
  const filteredData = data.filter(
    (x) => x['TessSiwi.cohortId'] != 'NaN' && x['TessSiwi.cohortId'] != null
  );
  const byCohort = groupBy(filteredData, (d) => Number(d['TessSiwi.cohortId'].split('-')[1]));

  return Object.keys(byCohort)
    .filter((x) => Number(x) >= 2015) // This is needed to filter cohorts that ended in 2015
    .reduce((acc, cohortId: string) => {
      const byMeasuredDay = filterBestResultForDay(byCohort[cohortId], 'TessSiwi');
      acc[cohortId] = Object.keys(byMeasuredDay).map((measuredAt) => {
        return {
          total: convertToIndex(byMeasuredDay[measuredAt]['TessSiwi.sT']),
          isCurrentCohort:
            byMeasuredDay[measuredAt]['TessSiwi.cohortId'].split('-')[1] ===
            max(Object.keys(byCohort)),
          measuredAt: measuredAt
        };
      });

      return acc;
    }, {});
};

const SIWICohort = ({ smbSiteId, skip }: { smbSiteId: number; skip?: boolean }) => {
  const projectContext = useContext(ProjectContext);
  const graph = (data: siwiHistStructure): PlotlyDataLayoutConfig => {
    const pallet = createLocationPallet({
      locations: Object.keys(data).sort((a, b) => Number(b) - Number(a))
    });

    const plotData = Object.keys(data)
      .sort((a, b) => Number(a) - Number(b))
      .map((cohortId) => {
        return {
          x: data[cohortId].map((d) => d.total),
          name: Object.values(data[cohortId])[0].isCurrentCohort
            ? `${cohortId} Cohort (current)`
            : `${cohortId} Cohort`,
          type: 'histogram',
          histnorm: 'probability',
          marker: {
            color: pallet[cohortId]
          },
          opacity: 0.6,
          xbins: {
            size: 1
          },
          hovertemplate: `<b>Stress Score:<b>%{x:.0f}<br><b>Probability:<b>%{y:.2f}`
        };
      });

    // Add the Latest Result line to the plot
    const latestCohort = max(Object.keys(data).map((x) => Number(x)));
    const latestResult = data[latestCohort]?.sort((a, b) =>
      new Date(b.measuredAt) > new Date(a.measuredAt) ? 1 : -1
    )?.[0];

    //@ts-ignore
    if (latestCohort) {
      plotData.push({
        x: Array(11).fill(Math.round(latestResult?.total)),
        //@ts-ignore
        y: [0, 0.03, 0.06, 0.09, 0.12, 0.15, 0.18, 0.21, 0.24, 0.27, 0.3],
        name: 'Latest Result',
        type: 'scatter',
        mode: 'lines',
        histnorm: 'density',
        line: {
          color: '#000000',
          dash: 'dash',
          width: 3
        },
        opacity: 1,
        hovertemplate: `Score: ${safeFormat(latestResult?.total)}<br>Measured At: ${format(
          parseISO(latestResult?.measuredAt),
          'yyyy-MM-dd'
        )}`
      });
    }

    const layout = {
      yaxis: {
        title: {
          text: 'Probability'
        },
        showline: true,
        range: [0, 0.3]
      },
      xaxis: {
        title: {
          text: 'Daily Stress Total'
        }
      },
      autosize: true,
      margin: {
        t: 0,
        b: 40,
        l: 80,
        r: 0
      },
      barmode: 'overlay',
      legend: {
        traceorder: 'reversed'
      }
    };

    return {
      //@ts-ignores
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: ['TessSiwi.sT'],
      dimensions: ['TessSiwi.measuredAt', 'TessSiwi.cohortId'],
      filters: [{ member: 'Site.id', operator: 'equals', values: [smbSiteId.toString()] }],
      timezone: projectContext.timezone,
      timeDimensions: [
        {
          dimension: 'TessSiwi.measuredAt',
          granularity: 'day',
          dateRange: ['2015-01-01', format(new Date(), 'yyyy-MM-dd')]
        }
      ],
      order: {
        'TessSiwi.measuredAt': 'desc'
      }
    },
    transform: cohortHistTransform,
    graph,
    options: {
      skip,
      dependencies: {
        cubeName: 'TessSiwi'
      }
    }
  });

  if (isLoading) {
    return <Skeleton minH="450px" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.length ? (
    <Plot className="w-100 h-100 siwi-histogram" useResizeHandler={true} {...plot} />
  ) : plot?.data?.length === 0 && projectContext.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <NoData minH="450px" />
  );
};

export default SIWICohort;
