import { Skeleton } from '@chakra-ui/react';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, uniq } from 'lodash';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot, { plotDates } from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';

type TotalFishDatum = {
  'Biology.endCountSum': number;
  'Biology.endBiomassSum': number;
  'BiologyLookup.sublocation'?: string;
  'Site.id'?: string;
  'Biology.measuredAt': string;
};

type TotalFishStructure = {
  [sublocation: string]: {
    [day: string]: {
      count: number;
      biomass: number;
    };
  };
};

export type ChartSettings = BaseChartSettings & {
  showInBiomass?: boolean;
};

const Chart = ({
  skip,
  dateRange = 'Last 30 days',
  chartRange,
  granularity = 'day',
  settings,
  onDataLoaded
}: BaseChartProps<ChartSettings>) => {
  const locationDimension = settings.site?.smbId ? 'BiologyLookup.sublocation' : 'Site.id';

  const transform = (data: TotalFishDatum[]): TotalFishStructure => {
    const bySublocation = groupBy(data, (d) => d[locationDimension]);
    return Object.keys(bySublocation).reduce((acc, subloc: string) => {
      const byMeasuredAt = bySublocation[subloc].reduce((subAcc, datum) => {
        subAcc[datum['Biology.measuredAt']] = {
          count: datum['Biology.endCountSum'],
          biomass: datum['Biology.endBiomassSum']
        };
        return subAcc;
      }, {});
      acc[subloc] = byMeasuredAt;
      return acc;
    }, {});
  };

  const graph = (data: TotalFishStructure, dependencies?: any): PlotlyDataLayoutConfig => {
    const sublocations = uniq(Object.keys(data)).sort(
      (a, b) => locationToIndex(a) - locationToIndex(b)
    );
    const pallet = createLocationPallet({ locations: sublocations });
    const plotData: Data[] = sublocations.map((subloc) => {
      return {
        type: 'bar',
        name: settings.site?.smbId ? subloc : settings.project.siteNameMappings[subloc],
        x: Object.keys(data[subloc]),
        y: Object.values(data[subloc]).flatMap((d) =>
          dependencies?.biomassToggle ? d.biomass : d.count
        ),
        marker: {
          color: pallet[subloc]
        },
        hovertemplate: Object.values(data[subloc]).flatMap(
          (d) => `<b>Count: ${d.count}<br>Weight (kg): ${d.biomass.toFixed(0)}</b>`
        )
      };
    });

    const measurementLabel = settings.showInBiomass ? 'Weight (kg)' : 'Count';
    const locationLabel = settings?.site ? 'Cage' : 'Site';
    const title = settings?.site ? `Total Fish - ${settings.site.name}` : `Total Fish - All Sites`;

    const [minDate, maxDate] = plotDates(plotData);

    const layout = {
      title: settings?.showTitle && {
        text: title,
        y: 1
      },
      barmode: 'stack',
      yaxis: {
        title: `${measurementLabel} By ${locationLabel}`
      },
      xaxis: {
        range: chartRange,
        title: `${minDate} - ${maxDate} by ${granularity}`
      },
      autosize: true,
      showlegend: true,
      legend: {
        orientation: 'h',
        x: 0,
        y: 1.25,
        traceorder: 'normal'
        // bgcolor: '#EDEDED',
      },
      margin: {
        t: 25
      },
      hovermode: 'closest'
    };

    return {
      //@ts-ignore
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: ['Biology.endCountSum', 'Biology.endBiomassSum'],
      dimensions: [locationDimension],
      filters: settings.site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [settings.site?.smbId.toString()]
            }
          ]
        : [],
      timezone: settings.project.timezone,
      timeDimensions: [
        {
          dimension: 'Biology.measuredAt',
          granularity,
          dateRange
        }
      ]
    },
    transform,
    graph,
    options: {
      skip,
      dependencies: { biomassToggle: settings.showInBiomass, chartRange },
      onDataLoaded
    }
  });

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.length ? (
    <Plot className="w-100 total-fish-plot" useResizeHandler={true} {...plot} />
  ) : plot?.data?.length === 0 && settings.project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <NoData minH="450px" />
  );
};

export default Chart;
