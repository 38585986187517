// @ts-strict
import { Button, HStack, VStack } from '@chakra-ui/react';
import { DataTable, DataTableFilter } from 'components/Tables/DataTable';
import { sortBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { ReportListingFilterWidget } from './ReportListingFilterWidget';

const LOCATION_COLUMN = {
  Header: 'Location',
  accessor: 'sublocation',
  width: 150
};

export interface DataReport {
  reportTime: string;
  id: number;
  lastUpdated: string;
  reportedBy: string;
}

export interface ExportButtonProps<T> {
  reports: T[];
  siteLabel: string;
}

interface Props<T extends object> {
  reports: T[];
  openReportForm: (report: T | null) => void;
  dataColumns?: Column[];
  tableFilter?: DataTableFilter<T>;
  siteLabel?: string;
  // TODO - Remove and put locationColumn in dataColumns
  //   Perhaps rename dataColumns to extraColumns - MEA
  addLocationColumn?: boolean;
  userCanCreateNew: boolean;
  ExportButton?: React.FC<ExportButtonProps<T>>;
}

export function ReportListing<T extends DataReport>({
  reports,
  openReportForm,
  dataColumns,
  ExportButton,
  tableFilter,
  addLocationColumn,
  siteLabel,
  userCanCreateNew
}: Props<T>) {
  const orderedReports = useMemo(() => {
    return sortBy(reports, 'reportTime');
  }, [reports]);

  const [visibleReports, setVisibleReports] = useState<T[]>([]);

  const [selectedMonth, setSelectedMonth] = useState<string>();

  useEffect(() => {
    const latestMonth =
      orderedReports.length > 0
        ? new Date(orderedReports[orderedReports.length - 1].reportTime).toLocaleString('default', {
            month: 'long',
            year: 'numeric'
          })
        : null;

    setSelectedMonth(latestMonth);
  }, [orderedReports]);

  useEffect(() => {
    const nextVisibleReports = reports.filter((report) => {
      const dt = new Date(report.reportTime);
      return dt.toLocaleString('default', { month: 'long', year: 'numeric' }) === selectedMonth;
    });
    setVisibleReports(nextVisibleReports);
  }, [reports, selectedMonth]);

  const tableColumns = useMemo(() => {
    const tableColumns: Column[] = [
      {
        Header: 'Report Date',
        accessor: 'reportTime',
        width: 150,
        Cell: ({ value }: { value: string }) => moment(value).format('lll')
      },
      {
        Header: 'Reported By',
        accessor: 'reportedBy',
        width: 200
      },
      {
        Header: 'Last Updated',
        accessor: 'lastUpdated',
        width: 150,
        Cell: ({ value }: { value: string }) => moment(value).format('lll')
      }
    ];

    // dataColumn and location are optional includes
    if (addLocationColumn) {
      // @ts-ignore column type is a tangled web - MEA
      tableColumns.splice(2, 0, LOCATION_COLUMN);
    }

    if (dataColumns) {
      // @ts-ignore column type again - MEA
      tableColumns.splice(-1, 0, ...dataColumns);
    }

    return tableColumns;
  }, [addLocationColumn, dataColumns]);

  if (ExportButton && !siteLabel) {
    // TODO - A SiteContext would remove the need to pass the site around, and this warning - MEA
    console.warn(
      'Report listing: Export button provided without a siteLabel! Files will be saved without labels.'
    );
  }

  const setFormOpen = (selectedForm: { id }) => {
    const report = reports.find((report) => report.id === selectedForm.id);

    openReportForm(report);
  };

  return (
    <HStack minH="800px" alignItems="flex-start">
      <VStack mt="10px">
        {userCanCreateNew && (
          <Button
            w="100%"
            data-cypress={'new-entry-btn'}
            colorScheme={'blue'}
            onClick={() => openReportForm(null)}>
            New Entry
          </Button>
        )}
        {ExportButton && <ExportButton siteLabel={siteLabel as string} reports={visibleReports} />}
        <ReportListingFilterWidget
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          dates={orderedReports.map((r) => r.reportTime)}
        />
      </VStack>
      <DataTable
        columns={tableColumns}
        data={visibleReports}
        globalFilter={tableFilter}
        initSortBy={{ id: 'reportTime', desc: true }}
        onRowClick={(row) => setFormOpen(row.original)}
      />
    </HStack>
  );
}
