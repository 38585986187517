import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';

type LoginFormProps = {
  submit: (form: any) => void;
  loginError: string | null;
};

const LoginForm = ({ submit, loginError }: LoginFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm();

  return (
    <form id="login-form" onSubmit={handleSubmit(submit)}>
      <FormControl mt="30px" isInvalid={Object.keys(errors).length > 0 || loginError?.length > 0}>
        <FormLabel htmlFor="email">Email</FormLabel>
        <Input
          id="email"
          type="email"
          placeholder="Enter Email"
          {...register('email', { required: 'Please enter an email.' })}
        />
        {errors?.email?.message ? (
          <FormErrorMessage id="email-error">{errors.email.message}</FormErrorMessage>
        ) : (
          <Spacer h="25px" />
        )}
        <FormLabel htmlFor="password">Password</FormLabel>
        <Input
          id="password"
          type="password"
          placeholder="Enter Password"
          {...register('password', { required: 'Please enter a password.' })}
        />
        {errors?.password?.message ? (
          <FormErrorMessage id="password-error">{errors.password.message}</FormErrorMessage>
        ) : (
          <Spacer h="25px" />
        )}
        <Button
          id="sign-in"
          isLoading={isSubmitting}
          w="100%"
          mt="25px"
          colorScheme="blue"
          type="submit">
          Sign In
        </Button>
        {loginError ? (
          <FormErrorMessage id="login-error">{loginError}</FormErrorMessage>
        ) : (
          <Spacer h="25px" />
        )}
        <Center></Center>
      </FormControl>
    </form>
  );
};

export default LoginForm;
