import { Skeleton } from '@chakra-ui/react';
import { ProjectContext } from 'contexts/ProjectContext';
import format from 'date-fns/format';
import max from 'date-fns/max';
import min from 'date-fns/min';
import sub from 'date-fns/sub';
import useCubeLTG from 'hooks/useCubeLTG';
import { PlotlyDataLayoutConfig } from 'plotly.js';
import { useContext } from 'react';
import { trafficLightPallet } from 'shared/functions/colorPallets';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import {
  categoryPrefix,
  fullDatumTransform,
  indicatorMap,
  indicatorOrder,
  siwiDimensions,
  siwiMeasures,
  siwiStructure
} from './SIWIShared';

const Calculate7DayScore = (data: any, old: boolean, weighted: boolean): number => {
  const midDate = sub(max(Object.keys(data).map((x) => new Date(x))), { days: 7 });
  const week = Object.keys(data).filter((x) =>
    old ? new Date(x) <= midDate : new Date(x) > midDate
  );

  const weekSum = week.reduce((acc, measuredAt: string) => {
    const value = weighted
      ? data[measuredAt].score * data[measuredAt].weight
      : data[measuredAt].score;
    return acc + value;
  }, 0);

  // This stops us from counting days where no result was calculted due to an error
  const weekCount = week.filter((measuredAt) => data[measuredAt].ko != null).length;
  return weekSum / weekCount;
};

const SIWIArrowChart = ({
  smbSiteId,
  skip,
  showWeighted
}: {
  smbSiteId: number;
  skip?: boolean;
  showWeighted: boolean;
}) => {
  const projectContext = useContext(ProjectContext);

  const graph = (data: siwiStructure, dependencies?: any): PlotlyDataLayoutConfig => {
    // Get all the indicators to plot and drop all the categorised results (environmental, operations, and mortality)
    const indicators = Object.keys(data).filter(
      (ind) =>
        !Object.values(indicatorMap)
          .filter((x) => x.includes(categoryPrefix))
          .includes(ind)
    );
    const plotData = indicators.map((indicator) => {
      const previous = Calculate7DayScore(data[indicator], true, dependencies?.showWeighted);
      const last = Calculate7DayScore(data[indicator], false, dependencies?.showWeighted);
      return {
        type: 'scatter',
        x: [previous, last],
        y: [indicatorOrder[indicator] + 1, indicatorOrder[indicator] + 1],
        name: indicator,
        mode: 'lines+markers',
        marker: {
          size: [8, 16],
          symbol: [
            'circle',
            previous === last ? 'circle' : previous > last ? 'triangle-left' : 'triangle-right'
          ]
        },
        line: {
          color: last > previous ? trafficLightPallet[1.0] : trafficLightPallet[0.0]
        },
        showlegend: false,
        hovertemplate: `<b>${dependencies?.showWeighted ? 'Contribution' : 'Score'}: %{x:.2f}</b>`,
        visible: true
      };
    });

    // Legend markers
    const thisWeekEnd = max(Object.keys(data[indicatorMap.sT]).map((x) => new Date(x)));
    const thisWeekStart = sub(thisWeekEnd, { days: 6 });
    const lastWeekEnd = sub(thisWeekEnd, { days: 7 });
    const lastWeekStart = min(Object.keys(data[indicatorMap.sT]).map((x) => new Date(x)));

    // null check for when the site is fallow
    thisWeekEnd instanceof Date &&
      !isNaN(thisWeekEnd.valueOf()) &&
      [
        {
          name: `${format(thisWeekStart, 'MMM dd')} - ${format(thisWeekEnd, 'MMM dd')}`,
          size: 15,
          mode: 'markers',
          score: 0.0,
          symbol: 'triangle-left'
        },
        {
          name: `${format(lastWeekStart, 'MMM dd')} - ${format(lastWeekEnd, 'MMM dd')}`,
          size: 8,
          mode: 'markers',
          score: 0.0,
          symbol: 'circle'
        },
        { name: 'Decreasing Stress', size: 15, mode: 'lines', score: 0.0, symbol: 'circle' },
        { name: 'Increasing Stress', size: 15, mode: 'lines', score: 1.0, symbol: 'circle' }
      ]
        .map((value) => {
          return {
            type: 'scatter',
            x: [0],
            y: [100],
            name: value.name,
            mode: value.mode,
            marker: {
              size: [value.size],
              symbol: [value.symbol]
            },
            line: {
              color: trafficLightPallet[value.score]
            },
            showlegend: true,
            hovertemplate: '',
            visible: true
          };
        })
        // @ts-ignore
        .forEach((x) => plotData.push(x));

    const layout = {
      yaxis: {
        showTickLabels: true,
        zeroline: false,
        automargin: true,
        font: {
          size: 14
        },
        tickmode: 'array',
        ticktext: indicators.map((x) => x.replace(' ', '<br>')),
        tickvals: indicators.map((ind) => indicatorOrder[ind] + 1),
        range: [0.5, indicators.length + 0.5],
        showgrid: false,
        fixedrange: true
      },
      xaxis: {
        zeroline: false,
        title: {
          text: dependencies?.showWeighted
            ? 'Average Contribution to SIWI Stress Total'
            : 'Average Indicator Score',
          standoff: 5
        },
        automargin: true
      },
      margin: {
        t: 10,
        b: 20,
        l: 40,
        r: 150
      },
      hovermode: 'closest',
      autosize: true
    };

    return {
      //@ts-ignores
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: siwiMeasures('TessSiwi'),
      dimensions: siwiDimensions('TessSiwi'),
      filters: [{ member: 'Site.id', operator: 'equals', values: [smbSiteId.toString()] }],
      timezone: projectContext.timezone,
      timeDimensions: [
        {
          dimension: 'TessSiwi.measuredAt',
          granularity: 'day',
          dateRange: 'From 13 days ago to now'
        }
      ],
      order: {
        'TessSiwi.measuredAt': 'desc'
      }
    },
    transform: fullDatumTransform,
    graph,
    options: {
      skip,
      dependencies: {
        cubeName: 'TessSiwi',
        showWeighted: showWeighted
      }
    }
  });

  if (isLoading) {
    return <Skeleton minH="450px" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }
  const noData = plot?.data
    ?.filter((d) => d.name === indicatorMap.sT)[0]
    ['x'].every((x) => Number.isNaN(x));

  return noData && projectContext.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : noData ? (
    <NoData minH="450px" />
  ) : (
    <Plot className="w-100 h-100 siwi-arrow-plot" useResizeHandler={true} {...plot} />
  );
};

export default SIWIArrowChart;
