import {
  CheckCircleIcon,
  CheckIcon,
  DeleteIcon,
  EditIcon,
  InfoOutlineIcon
} from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  HStack,
  Heading,
  IconButton,
  Input,
  SimpleGrid,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react';
import InfoPopover from 'components/InfoPopover';
import { EquipmentState } from 'graphql/generated';
import { EquipmentAdminForm } from 'pages/project/[Id]/Admin/Equipment/EquipmentAdmin';
import { useState } from 'react';
import {
  UseControllerProps,
  UseFormTrigger,
  useController,
  useFieldArray,
  useWatch
} from 'react-hook-form';
import { ActionEditor } from './StateTypeHandlers/ActionEditor';
import { EquipmentStateRouter } from './StateTypeHandlers/EquipmentStateRouter';
import { SelectState } from './StateTypeHandlers/SelectState';
import { StateViewRouter } from './StateViews/StateViewRouter';

export const EquipmentTypeCard = ({
  control,
  name: formName,
  trigger,
  isEditing,
  canEdit,
  onEquipmentTypeDelete,
  onSetEditing,
  onSave
}: UseControllerProps<EquipmentAdminForm, `equipmentTypes.${number}`> & {
  onEquipmentTypeDelete: () => void;
  onSetEditing: () => void;
  onSave: () => void;
  isEditing: boolean;
  canEdit: boolean;
  trigger: UseFormTrigger<EquipmentAdminForm>;
}) => {
  const [editingPath, setEditingPath] = useState<
    | `equipmentTypes.${number}.states.${number}`
    | `equipmentTypes.${number}.actions.${number}`
    | null
  >();

  const [name, states, actions, apiManaged] = useWatch({
    control: control,
    name: [
      `${formName}.name`,
      `${formName}.states`,
      `${formName}.actions`,
      `${formName}.apiManaged`
    ]
  });

  // Intentionally not using the fields here in favor of the watch of states above. When nested
  // properties were being updated, it was not being detected in the 'fields' returned here
  const { append: appendState, remove: removeState } = useFieldArray({
    name: `${formName}.states`,
    control
  });

  const { append: appendAction, remove: removeAction } = useFieldArray({
    name: `${formName}.actions`,
    control
  });

  const { fieldState: nameFieldState } = useController<EquipmentAdminForm>({
    control,
    name: `${formName}.name`
  });

  const makeNewState = (selectedOption: EquipmentState['__typename']) => {
    let newState = null;
    switch (selectedOption) {
      case 'EquipmentSelectionState':
        newState = {
          name: '',
          options: [
            { name: '', color: 'blue.500', description: '' },
            { name: '', color: 'green.500', description: '' }
          ],
          __typename: selectedOption
        };
        break;
      case 'EquipmentNumberState':
        newState = {
          name: '',
          type: 'number',
          value: 0,
          __typename: selectedOption
        };
        break;
      case 'EquipmentTextState':
        newState = {
          name: '',
          type: 'text',
          value: '',
          __typename: selectedOption
        };
        break;
      default:
        break;
    }

    appendState(newState);
  };

  return (
    <Card data-cypress="equipment-type-card" w="500px" marginInlineStart="0px">
      <CardHeader background="gray.100">
        <HStack justifyContent="space-between">
          {isEditing ? (
            <>
              {!editingPath && (
                <IconButton
                  data-cypress="delete-equipment-type"
                  size="md"
                  color="red.500"
                  icon={<DeleteIcon />}
                  aria-label={'Delete Equipment Type'}
                  onClick={() => onEquipmentTypeDelete()}
                />
              )}
              <Input
                data-cypress="new-equipment-type-name"
                isInvalid={!!nameFieldState?.error}
                placeholder="Equipment Name..."
                {...control.register(`${formName}.name`, { required: 'A name is required.' })}
              />
              {!editingPath && (
                <IconButton
                  data-cypress="save-equipment-type-btn"
                  size="md"
                  color="green.500"
                  icon={<CheckCircleIcon />}
                  aria-label={'Save Equipment Type'}
                  onClick={async () => {
                    const res = await trigger();
                    if (res) {
                      onSave();
                    }
                  }}
                />
              )}
            </>
          ) : (
            <>
              <Heading my="8px" size="md">
                {name}
              </Heading>
              {canEdit && !apiManaged && (
                <IconButton
                  data-cypress="edit-equipment-type-btn"
                  size="md"
                  color="blue.500"
                  icon={<EditIcon />}
                  aria-label={'Edit Equipment Type'}
                  onClick={() => {
                    onSetEditing();
                  }}
                />
              )}
            </>
          )}
        </HStack>
        {nameFieldState?.error?.message && (
          <Text color="red.500">{nameFieldState.error.message}</Text>
        )}
        {apiManaged && (
          <Alert overflow="visible" variant="left-accent" status="info">
            <AlertIcon />
            API Integration Managed
            <InfoPopover ml="5px">
              This equipment type is managed via a third party integration. If the information is
              incorrect, please contact us.
            </InfoPopover>
          </Alert>
        )}
      </CardHeader>
      {isEditing ? (
        editingPath ? (
          editingPath.includes('state') ? (
            <EquipmentStateRouter
              control={control}
              name={editingPath as `equipmentTypes.${number}.states.${number}`}
            />
          ) : (
            <ActionEditor
              control={control}
              name={editingPath as `equipmentTypes.${number}.actions.${number}`}
            />
          )
        ) : (
          <SelectState
            states={states}
            actions={actions}
            onStateSelected={(selection) => {
              if (selection.type === 'action') {
                if (!selection.idx && selection.idx !== 0) {
                  //@ts-ignore
                  appendAction({ name: '' });
                }
                setEditingPath(`${formName}.actions.${selection.idx ?? actions.length}`);
              } else {
                if (selection.idx === null || selection.idx === undefined) {
                  makeNewState(selection.type);
                }
                setEditingPath(`${formName}.states.${selection.idx ?? states.length}`);
              }
            }}
            onDeleteState={({ idx, type }) => {
              if (type === 'action') {
                removeAction(idx);
              } else {
                removeState(idx);
              }
            }}
          />
        )
      ) : (
        <CardBody>
          <Box textAlign="center">
            <Heading size="sm">States</Heading>
          </Box>
          <Divider mb="15px" />
          {states.length ? (
            <SimpleGrid columns={2} spacing={10}>
              {states.map((s) => (
                <StateViewRouter key={s.name + s?.id} equipmentState={s} />
              ))}
            </SimpleGrid>
          ) : (
            <Box mb="15px" textAlign="center" w="100%">
              <Text fontStyle="italic">No states declared</Text>
            </Box>
          )}
          <Box textAlign="center" mt="10px">
            <Heading size="sm">Actions</Heading>
          </Box>
          <Divider mb="15px" />
          <VStack w="100%">
            {actions.length ? (
              actions.map((a) => (
                <HStack key={a.id} w="100%">
                  <Button isDisabled={true} h="30px" w="100%" colorScheme="blue">
                    {a.name}
                  </Button>
                  {a?.description && (
                    <Tooltip label={a.description}>
                      <InfoOutlineIcon />
                    </Tooltip>
                  )}
                </HStack>
              ))
            ) : (
              <Box mb="15px" textAlign="center" w="100%">
                <Text fontStyle="italic">No actions declared</Text>
              </Box>
            )}
          </VStack>
        </CardBody>
      )}
      {isEditing && (
        <CardFooter justify="space-between">
          {editingPath && (
            <VStack spacing={5} w="100%">
              <Button
                data-cypress="save-state-action-btn"
                w="100%"
                colorScheme="green"
                leftIcon={<CheckIcon />}
                onClick={async () => {
                  const res = await trigger();
                  if (res) {
                    setEditingPath(null);
                  }
                }}>
                Save
              </Button>
            </VStack>
          )}
        </CardFooter>
      )}
    </Card>
  );
};
