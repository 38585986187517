import { Card, CardBody, HStack, Input, Spacer, Text } from '@chakra-ui/react';
import BaseSelect from 'components/Forms/BaseSelect';
import { ProjectContext } from 'contexts/ProjectContext';
import { add, format } from 'date-fns';
import { useContext, useMemo } from 'react';

export type EventControlFilter = {
  startTime?: Date;
  endTime?: Date;
  eventTypes?: string[];
  severities?: string[];
};

type EventControlProps = {
  eventFilter: EventControlFilter;
  onEventFilterChanged: (filter: EventControlFilter) => void;
  types: string[];
  severities: string[];
};

const EventControls = ({
  types,
  severities,
  eventFilter,
  onEventFilterChanged
}: EventControlProps) => {
  const project = useContext(ProjectContext);

  const severityOptions = useMemo(
    () => severities.map((s) => ({ label: s, value: s })),
    [severities]
  );
  const typeOptions = useMemo(() => types.map((t) => ({ label: t, value: t })), [types]);

  return (
    <Card>
      <CardBody>
        <HStack w="100%" justifyContent="space-evenly">
          <HStack>
            <Text>Start</Text>
            <Input
              type="date"
              value={format(eventFilter.startTime, 'yyyy-MM-dd')}
              onChange={(e) =>
                onEventFilterChanged({
                  ...eventFilter,
                  startTime: add(new Date(e.currentTarget.value), {
                    seconds: project.timezoneOffset / 1000
                  })
                })
              }
              max={format(eventFilter.endTime, 'yyyy-MM-dd')}
            />
          </HStack>

          <HStack>
            <Text>End</Text>
            <Input
              type="date"
              value={format(eventFilter.endTime, 'yyyy-MM-dd')}
              onChange={(e) =>
                onEventFilterChanged({
                  ...eventFilter,
                  endTime: add(new Date(e.currentTarget.value), {
                    seconds: project.timezoneOffset / 1000
                  })
                })
              }
              min={format(eventFilter.startTime, 'yyyy-MM-dd')}
            />
          </HStack>
        </HStack>

        <HStack mt="5px">
          <BaseSelect
            isClearable={true}
            label="Event Type"
            options={typeOptions}
            isMulti={true}
            value={typeOptions.filter((o) => eventFilter.eventTypes.includes(o.value))}
            onChange={(options) => {
              onEventFilterChanged({
                ...eventFilter,
                eventTypes: options.map((o) => o.value)
              });
            }}
          />

          <Spacer w="10px" />

          <BaseSelect
            isClearable={true}
            label="Severity"
            options={severityOptions}
            isMulti={true}
            value={severityOptions.filter((o) => eventFilter.severities.includes(o.value))}
            onChange={(options) => {
              onEventFilterChanged({
                ...eventFilter,
                severities: options.map((o) => o.value)
              });
            }}
          />
        </HStack>
      </CardBody>
    </Card>
  );
};

export default EventControls;
