import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Image,
  Link,
  Stack,
  Text
} from '@chakra-ui/react';
import config from 'config';

export function RenderError() {
  return (
    <Stack mb="200px" align={'center'}>
      <Image boxSize={'80px'} src={'/ScootScienceLogo.png'} marginBottom={'1em'} />
      <Alert
        status="warning"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        padding={'2em 1em'}
        margin={'1em'}
        data-cypress={'server-down-alert'}
        maxWidth={{
          base: '100%',
          lg: '50vw'
        }}>
        <AlertIcon boxSize={'40px'} />
        <AlertTitle fontSize="xl" margin={'0.5em 0'}>
          Unexpected Error
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          <Text>{`We're sorry, we encountered an unexpected error rendering this page.`}</Text>
          <Text>
            Please{' '}
            <Link href={config.ASANA_BUG_FORM_URL} isExternal color={'blue.600'}>
              file a ticket <ExternalLinkIcon mx={'2px'} />
            </Link>{' '}
            outlining what you were doing before this happened.
          </Text>
        </AlertDescription>
      </Alert>
    </Stack>
  );
}
