import { BaseChartProps } from '../types';
import { BasicLineChart } from '../BasicLineChart';

type AtmosDatum = {
  'TessAtmos.avgRainRateHr': string;
  'TessAtmos.measuredAt': string;
};

const Chart = (props: BaseChartProps) => {
  return (
    <BasicLineChart<AtmosDatum>
      className="atmos-rain-chart"
      {...props}
      settings={{
        ...props.settings,
        traces: [{ title: 'Rain Rate', measure: 'TessAtmos.avgRainRateHr', yAxisLabel: 'mm/hr' }],
        timeDimension: 'TessAtmos.measuredAt',
        xAxisLabel: 'Hour'
      }}
    />
  );
};

export { Chart };
