import { HStack, Image, Text } from '@chakra-ui/react';
import { ProjectContext } from 'contexts/ProjectContext';
import { useContext } from 'react';
import { EditorProps } from 'react-data-grid';
import Select from 'react-select';
import { PlanktonRow } from './EntryGrid';

type PlanktonSelectProps = {
  species: string[];
  portal: HTMLElement;
};

const PlanktonEntryGridSelect = ({ species, portal }: PlanktonSelectProps) => {
  const project = useContext(ProjectContext);
  return function PlanktonDropdown({
    onRowChange,
    row,
    column
  }: EditorProps<PlanktonRow, unknown>) {
    const planktonOptions = species.map((s) => {
      const policy = project.findPlanktonPolicy(s);
      return {
        value: s,
        label: (
          <HStack alignItems="center">
            <Image fallback={<></>} h="40px" src={`/${policy.imageUrl}`} />
            <Text>{policy.name}</Text>
          </HStack>
        )
      };
    });

    return (
      <Select
        isDisabled={!column.editable as boolean}
        styles={{
          container: (baseStyles) => ({ ...baseStyles, lineHeight: 'initial', height: '100%' }),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          control: (_baseStyles) => ({ backgroundColor: 'none', paddingTop: '6px' }),
          input: (baseStyles) => ({ ...baseStyles, color: 'black', margin: 0, paddingTop: 0 }),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          indicatorsContainer: (_base) => ({ display: 'none' }),
          menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 999999
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            position: 'absolute',
            top: 0,
            left: 0,
            color: 'black',
            width: '400px'
          }),
          menuList: (baseStyles) => ({ ...baseStyles, margin: 0 })
        }}
        autoFocus
        menuIsOpen={true}
        menuPortalTarget={portal}
        options={planktonOptions}
        onChange={(option) => onRowChange({ ...row, species: option.value }, true)}
      />
    );
  };
};

export default PlanktonEntryGridSelect;
