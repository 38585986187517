import { gql, useMutation } from '@apollo/client';
import EntryFormModal from 'components/Forms/EntryFormModal';
import { TessPlanktonSchema } from 'components/types';
import { ProjectContext } from 'contexts/ProjectContext';
import { PlanktonCategory, PlanktonReport, Site } from 'graphql/generated';
import { SUBMIT_DEVICE_VALUES } from 'graphql/globalMutations';
import { useContext } from 'react';
import { GET_PLANKTON_REPORTS } from '../PlanktonDataEntryTab/PlanktonReportList';
import BasicFields from './BasicFields';
import EntryGrid from './EntryGrid';

export const NO_PLANKTON_OBSERVED = 'No Plankton Observed';

const LEGACY_REPORT = 'pre_transaction_id';

const SAVE_PLANKTON_REPORT = gql`
  mutation savePlanktonReport(
    $id: Int
    $reportTime: Date!
    $lastUpdated: Date!
    $reportedBy: String!
    $siteId: Int!
    $sublocation: String
    $formInput: JSON!
    $transactionId: String!
  ) {
    savePlanktonReport(
      id: $id
      reportTime: $reportTime
      lastUpdated: $lastUpdated
      reportedBy: $reportedBy
      siteId: $siteId
      sublocation: $sublocation
      formInput: $formInput
      transactionId: $transactionId
    ) {
      id
      reportTime
      lastUpdated
      reportedBy
      siteId
      sublocation
      formInput
      transactionId
    }
  }
`;

const DELETE_PLANKTON_REPORTS = gql`
  mutation deletePlanktonReports($ids: [Int!]!) {
    deletePlanktonReports(ids: $ids)
  }
`;

type EntryFormProps = {
  site: Site;
  report?: PlanktonReport;
  onClose: () => void;
};

export type Form = {
  id: number;
  info: {
    reporter: string;
    samplingLocation?: { label: string; value: string };
    sampleTime: string;
    numberOfSlides: number;
    numberOfQuadrants: number;
    jellyNetRadius: number;
    noPlanktonObserved: boolean;
    transformRawCounts: boolean;
  };
  samples: {
    [planktonKey: string]: number | string;
  }[];
};

const formatDate = (date?: Date) =>
  date
    ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, -5)
    : '';

const noPlanktonObserved = (samples: Form['samples']) =>
  samples?.some((c) => c?.species === NO_PLANKTON_OBSERVED) ?? false;

export const planktonConcFromCount = (
  planktonCount: number,
  numSlides: number,
  numQuadrants: number
): number => {
  // Probably this should throw an error if numSlides or numQuadrants is undefined
  return numSlides * ((planktonCount / numQuadrants) * 1000);
};

export const jellyConcFromCount = (
  jellyCount: number,
  netRadius: number,
  depth: number
): number => {
  // Jellies per cubic meter of water
  return jellyCount / (netRadius ** 2 * Math.PI * depth);
};

const EntryForm = ({ site, report, onClose }: EntryFormProps) => {
  const projectContext = useContext(ProjectContext);
  const transformRawCounts: boolean =
    projectContext?.dataEntry?.plankton?.transform_raw_counts ?? false;
  // if the project calls for data_entry.plankton.transform_raw_counts, then we need to use numSlides and numQuadrants to compute the plankton concentration from raw counts
  const defaultValues = {
    id: report?.id ?? null,
    info: {
      reporter: report?.reportedBy ?? '',
      samplingLocation: report?.sublocation
        ? { label: report.sublocation, value: report.sublocation }
        : { label: site.sampleLocations?.[0], value: site.sampleLocations?.[0] },
      sampleTime: formatDate(report?.reportTime ? new Date(report?.reportTime) : new Date()),
      numberOfSlides:
        report?.formInput.numSlides ?? projectContext.dataEntry.plankton.default_number_of_slides,
      numberOfQuadrants:
        report?.formInput.numQuadrants ??
        projectContext.dataEntry.plankton.default_number_of_quadrants,
      jellyNetRadius:
        report?.formInput.jellyNetRadius ??
        projectContext.dataEntry.plankton.default_jelly_net_radius
          ? projectContext.dataEntry.plankton.default_jelly_net_radius
          : 0,
      noPlanktonObserved: noPlanktonObserved(report?.formInput.counts),
      transformRawCounts: transformRawCounts
    },
    samples:
      report?.formInput.counts.length > 0
        ? noPlanktonObserved(report?.formInput.counts)
          ? [{}, {}]
          : [...report.formInput.counts, {}]
        : [{}, {}]
  };

  const [saveReport] = useMutation(SAVE_PLANKTON_REPORT, {
    refetchQueries: [GET_PLANKTON_REPORTS, 'getPlanktonReports']
  });

  const [deleteReport, { loading: isDeleting }] = useMutation(DELETE_PLANKTON_REPORTS, {
    refetchQueries: [GET_PLANKTON_REPORTS, 'getPlanktonReports']
  });

  const [submitDeviceValues, { loading: isSubmittingSmb }] = useMutation(SUBMIT_DEVICE_VALUES);

  const submit = async (form: Form) => {
    let validSamples = [];

    if (form.info.noPlanktonObserved) {
      validSamples = [{ species: NO_PLANKTON_OBSERVED }];
    } else {
      validSamples = form.samples.filter((s) => s.species);
    }

    let transactionId = NO_PLANKTON_OBSERVED;
    if (!form.info.noPlanktonObserved) {
      const items: TessPlanktonSchema[] = [];
      validSamples.forEach((entry) => {
        const policy = projectContext.findPlanktonPolicy(entry.species);
        Object.keys(entry)
          .filter((k) => k != 'species')
          .forEach((k) => {
            const regexp = /([a-z]*)(\d{1,2})/g;
            const result = [...k.matchAll(regexp)];
            const isJelly = [
              PlanktonCategory.Jelly,
              PlanktonCategory.JellyGenus,
              PlanktonCategory.JellySpecies
            ].includes(policy.species?.category ?? policy.generic?.category);
            items.push({
              site_id: site.smbId,
              sublocation:
                site.siteLabel === 'Seed Area'
                  ? 'seedsite'
                  : site.siteLabel === 'Pilot'
                    ? 'pilotsite'
                    : form.info?.samplingLocation.value.toLowerCase(),
              depth: parseInt(result[0][2].toString()),
              species: entry.species,
              method: result[0][1].toString(),
              cell_count:
                transformRawCounts && isJelly
                  ? jellyConcFromCount(
                      entry[k],
                      form.info.jellyNetRadius,
                      parseInt(result[0][2].toString())
                    )
                  : transformRawCounts && !isJelly
                    ? planktonConcFromCount(
                        entry[k],
                        form.info.numberOfSlides,
                        form.info.numberOfQuadrants
                      )
                    : entry[k],
              measured_at: new Date(form.info.sampleTime).toISOString()
            });
          });
      });

      const encodedItems = btoa(JSON.stringify(items));
      const response = await submitDeviceValues({
        variables: { tableName: 'tess_plankton', values: encodedItems, action: 'INSERT' }
      });
      transactionId = response.data?.submitDeviceValues?.data?.transaction_id;
    }

    await saveReport({
      variables: {
        id: report?.id,
        reportedBy: form.info.reporter,
        reportTime: new Date(form.info.sampleTime).toISOString(),
        sublocation: form.info?.samplingLocation.value,
        lastUpdated: new Date().toISOString(),
        siteId: site.id,
        formInput: {
          location: form.info?.samplingLocation.value,
          numSlides: form.info.numberOfSlides,
          numQuadrants: form.info.numberOfQuadrants,
          jellyNetRadius: form.info.jellyNetRadius,
          reportedBy: form.info.reporter,
          counts: validSamples,
          transformRawCounts: transformRawCounts
        },
        transactionId: transactionId
      }
    });
  };
  const handleDelete = async () => {
    if (!report?.transactionId || report.transactionId === LEGACY_REPORT) return;
    await submitDeviceValues({
      variables: {
        tableName: 'tess_plankton',
        values: btoa(JSON.stringify({ where: [['transaction_id', '=', report?.transactionId]] })),
        action: 'DELETE'
      }
    });

    await deleteReport({ variables: { ids: [report.id] } });
  };

  return (
    <EntryFormModal
      siteName={site.name}
      entryName="Plankton"
      defaultValues={defaultValues}
      isDeleting={isDeleting}
      isDeleteDisabled={report?.transactionId === LEGACY_REPORT}
      deleteTooltip={
        report?.transactionId === LEGACY_REPORT
          ? 'This is a legacy report. Please contact SeaState support if it needs deletion.'
          : undefined
      }
      isSubmitting={isSubmittingSmb}
      onSubmit={submit}
      onDelete={handleDelete}
      onClose={onClose}
      basicFields={(control) => (
        <BasicFields
          //@ts-ignore some weird conversion with samples to any[]
          control={control}
          isDisabled={!!report}
          sampleLocations={site.sampleLocations}
          showSamplingLocation={site.siteLabel === 'Farm'}
          transformRawCounts={transformRawCounts}
          projectId={projectContext.id}
        />
      )}
      dataEntry={(control, watch) => (
        <>
          <EntryGrid
            name="samples"
            //@ts-ignore some weird conversion with samples to any[]
            control={control}
            rules={{
              validate: (samples) =>
                watch('info.noPlanktonObserved')
                  ? true
                  : samples.map((s) => s.species).filter((s) => s).length <= 0
                    ? 'Samples must include at least 1 species'
                    : null
            }}
            isDisabled={watch('info.noPlanktonObserved')}
            transformRawCounts={transformRawCounts}
            numberOfQuadrants={watch('info.numberOfQuadrants')}
            numberOfSlides={watch('info.numberOfSlides')}
            jellyNetRadius={watch('info.jellyNetRadius')}
            depthColumns={projectContext.dataEntry.plankton.columns[site.siteLabel]}
            planktonPolicy={projectContext.planktonPolicy}
          />
        </>
      )}
    />
  );
};

export default EntryForm;
