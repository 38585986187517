import { ResultSet } from '@cubejs-client/core';
import { max, min } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const getResultSetsDateRange = (
  resultSet: ResultSet,
  projectTimezone: string
): [string, string] => {
  const minMaxDates = resultSet.query().timeDimensions?.map((td) => td.dateRange);

  // Date() constructor converts time to local browser timezone. Convert back out to utc.
  // Conversion needed in first place for min and max functionality...
  const minDate = zonedTimeToUtc(
    min(minMaxDates.map((d) => new Date(d[0]))),
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const maxDate = zonedTimeToUtc(
    max(minMaxDates.map((d) => new Date(d[1]))),
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  return [
    // Convert utc to project time.
    utcToZonedTime(minDate, projectTimezone).toISOString(),
    utcToZonedTime(maxDate, projectTimezone).toISOString()
  ];
};
