import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, FormControl, FormLabel, Text, Tooltip } from '@chakra-ui/react';
import Select, { GroupBase, Props } from 'react-select';

export type BaseSelectProps<
  TOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
> = {
  label?: string;
  tooltip?: string;
} & Props<TOption, IsMulti, Group> & {
    display?: string;
    w?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
  };

const BaseSelect = <
  TOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
>({
  label,
  tooltip,
  display,
  isDisabled = false,
  isRequired = false,
  w,
  ...props
}: BaseSelectProps<TOption, IsMulti, Group>) => {
  return (
    <FormControl isRequired={isRequired} w={w} display={display}>
      {label && (
        <FormLabel mb="0px">
          <Text display="inline-block">{label}</Text>
          {tooltip && (
            <Tooltip label={tooltip}>
              <InfoOutlineIcon ml="5px" />
            </Tooltip>
          )}
        </FormLabel>
      )}
      <Box data-cypress={props['data-cypress']}>
        <Select
          isDisabled={isDisabled}
          placeholder=""
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              border: '1px solid var(--chakra-colors-chakra-border-color)'
            })
          }}
          {...props}
        />
      </Box>
    </FormControl>
  );
};

export default BaseSelect;
