import { gql, useQuery } from '@apollo/client';
import { EmailIcon, RepeatClockIcon } from '@chakra-ui/icons';
import { Box, HStack, SimpleGrid, Skeleton, Text } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { ProjectContext } from 'contexts/ProjectContext';
import { sub } from 'date-fns';
import { capitalize } from 'lodash';
import { useContext, useMemo } from 'react';

const NOTIFICATONS_QUERY = gql`
  query GetNotificationsSummary($projectId: Int!, $startTime: Date!, $endTime: Date!) {
    sites(projectId: $projectId) {
      id
      alarms {
        id
        pastNotifications(startTime: $startTime, endTime: $endTime) {
          notifiedAt
        }
      }
    }
  }
`;

const SUMMARY_QUERY = gql`
  query GetAlarmChecksSummary($projectId: Int!, $startTime: Date!, $endTime: Date!) {
    alarmChecksSummary(projectId: $projectId, startTime: $startTime, endTime: $endTime) {
      state
      count
    }
  }
`;

export const AlarmStats = () => {
  const project = useContext(ProjectContext);

  const now = useMemo(() => new Date(), []);

  const { data: notificationData, loading: notificationDataLoading } = useQuery(
    NOTIFICATONS_QUERY,
    {
      variables: {
        projectId: project.id,
        startTime: sub(now, { days: 7 }),
        endTime: now
      }
    }
  );

  const { data: summaryData, loading: summaryLoading } = useQuery(SUMMARY_QUERY, {
    variables: {
      projectId: project.id,
      startTime: sub(now, { hours: 24 }),
      endTime: now
    }
  });

  return (
    <HStack alignItems="stretch" w="100%" justifyContent="center">
      {notificationDataLoading ? (
        <Skeleton w="240px" h="100" />
      ) : (
        <Stat
          label="Notifications Sent"
          value={
            notificationData?.sites.flatMap((s) => s.alarms.flatMap((a) => a.pastNotifications))
              .length
          }
          timeLabel="Last 7 days"
          icon={
            <Box p="5px" color="blue.500">
              <EmailIcon fontSize={20} />
            </Box>
          }
        />
      )}
      {summaryLoading ? (
        <Skeleton w="240px" h="140px" />
      ) : (
        <Stat
          label="Alarm Checks Status"
          value={
            summaryData?.alarmChecksSummary?.length === 0 ? (
              <Text pb="10px" fontSize="sm">
                No Recent Alarm Checks
              </Text>
            ) : (
              <SimpleGrid columns={2}>
                {summaryData?.alarmChecksSummary.map((d) => (
                  <>
                    <Text key={`${d.state}-${d.count}-state`} align="left" fontSize="sm">
                      {capitalize(d.state)}
                    </Text>
                    <Text key={`${d.state}-${d.count}-count`} align="right" fontSize="sm">
                      {d.count}
                    </Text>
                  </>
                ))}
              </SimpleGrid>
            )
          }
          timeLabel="Last 24 hours"
          icon={
            <Box p="5px" color="blue.500">
              <RepeatClockIcon fontSize={20} />
            </Box>
          }
        />
      )}
    </HStack>
  );
};
