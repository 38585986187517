import { HStack, Text } from '@chakra-ui/react';
import Select, { Props } from 'react-select';

export type ChartSelectProps<T = unknown> = Props<T> & {
  label?: string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
};

const ChartSelect = <T,>({
  label,
  width,
  minWidth,
  maxWidth,
  ...selectProps
}: ChartSelectProps<T>) => (
  <HStack>
    <Text>{label ?? ''}</Text>
    <Select
      id="select-dropdown"
      menuPlacement="auto"
      maxMenuHeight={130}
      minMenuHeight={100}
      closeMenuOnSelect={selectProps.isMulti ? false : true}
      styles={{
        container: (baseStyles) => ({
          ...baseStyles,
          width,
          minWidth,
          maxWidth
        }),
        control: (baseStyles) => ({
          ...baseStyles,
          width: '100%',
          border: '1px solid var(--chakra-colors-chakra-border-color)'
        }),
        menu: (base) => ({
          ...base,
          zIndex: 99
        })
      }}
      {...selectProps}
    />
  </HStack>
);

export default ChartSelect;
