import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { EquipmentNumberState } from 'graphql/generated';
import { UseControllerProps, useController } from 'react-hook-form';
import { EquipmentEntryForm } from '../EquipmentEntryModal';

export const EquipmentNumberEntry = ({
  state,
  ...props
}: UseControllerProps<EquipmentEntryForm, `states`> & {
  state: EquipmentNumberState;
}) => {
  const { field } = useController<EquipmentEntryForm, `states`>(props);
  return (
    <HStack w="100%" justifyContent="space-between">
      <Box>
        <Text display="inline">{state.name}</Text>
        {state?.description && (
          <Tooltip display="inline" label={state.description}>
            <InfoOutlineIcon ml="5px" />
          </Tooltip>
        )}
      </Box>
      <NumberInput
        value={field?.value?.[state.id] ?? ''}
        onChange={(e) => field.onChange({ ...field.value, [state.id]: e })}>
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </HStack>
  );
};
