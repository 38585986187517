import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, Checkbox, SimpleGrid, Stack, Text, Tooltip } from '@chakra-ui/react';
import { permissions } from '@scoot/permissions';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { orderBy } from 'lodash';
import { useContext } from 'react';

// const SAVE_USER_ALARM_PREFERENCES = gql`
//   mutation SaveUserAlarmPreferences($userId: String!, $siteIds: [Int!]!) {
//     updateUser(userId: $userId, alarmPreferences: $siteIds)
//   }
// `;

type UserAlarmPreferencesProps = {
  showSiteAlarms: boolean;
  sites: { id: number; name: string }[];
  selectedSites: number[];
  audioNotifications: boolean;
  onAudioNotificationsChange: (useAudio: boolean) => void;
  onAlarmSiteToggled: (option: { checked: boolean; siteId: number }) => void;
};

const UserAlarmPreferences = ({
  showSiteAlarms,
  sites,
  selectedSites,
  audioNotifications,
  onAudioNotificationsChange,
  onAlarmSiteToggled
}: UserAlarmPreferencesProps) => {
  const user = useContext(UserContext);
  const project = useContext(ProjectContext);

  return (
    <Stack>
      {showSiteAlarms && (
        <>
          <Text>Select which alarm notifications you would like to recieve in SeaState.</Text>
          <SimpleGrid columns={2} spacing="2">
            {orderBy(sites, 'name').map((s) => {
              return (
                <Checkbox
                  onChange={(e) => onAlarmSiteToggled({ checked: e.target.checked, siteId: s.id })}
                  isDisabled={!permissions.canViewSite(user, { id: s.id, projectId: project.id })}
                  isChecked={selectedSites.includes(s.id)}
                  colorScheme="green"
                  size="lg"
                  key={s.id}>
                  {s.name}
                </Checkbox>
              );
            })}
          </SimpleGrid>
          <Alert status="info" variant="left-accent">
            <AlertIcon mb="5px" />
            Note: This only determines which alerts you are notified of in the &apos;alarm
            bell&apos;. If you would like to adjust your email or text notifications, please contact
            your project administrator.
          </Alert>
        </>
      )}

      <Checkbox
        mt="10px"
        isChecked={audioNotifications}
        onChange={(e) => {
          onAudioNotificationsChange(e.currentTarget.checked);
        }}>
        Audio Notifications
      </Checkbox>
      {'Notification' in window && (
        <Checkbox
          mt="10px"
          isDisabled={Notification.permission === 'granted'}
          isChecked={Notification.permission === 'granted'}
          onChange={() => {
            if (Notification.permission !== 'granted') {
              Notification.requestPermission();
            }
          }}>
          Allow Desktop Notifications
          <Tooltip
            label={
              "Allow push notifications to the desktop when new alerts come in. Disable in your browser settings by revoking 'Notification' access."
            }>
            <InfoOutlineIcon mb="4px" ml="5px" fontSize="md" />
          </Tooltip>
        </Checkbox>
      )}
    </Stack>
  );
};

export default UserAlarmPreferences;
