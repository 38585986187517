import { VStack } from '@chakra-ui/react';
import { formatDate } from 'components/Forms/BaseInput';
import FormCheckbox from 'components/Forms/FormCheckbox';
import FormInput from 'components/Forms/FormInput';
import FormNumberInput from 'components/Forms/FormNumberInput';
import FormSelect from 'components/Forms/FormSelect';
import SearchableUserInput from 'components/Forms/SearchableUserInput';
import { Control } from 'react-hook-form';
import { Form, NO_PLANKTON_OBSERVED } from './EntryForm';

type BasicFieldsProps = {
  sampleLocations: string[];
  projectId: number;
  control: Control<Form, any>;
  showSamplingLocation?: boolean;
  isDisabled?: boolean;
};

const BasicFields = ({
  sampleLocations,
  projectId,
  control,
  showSamplingLocation = true,
  isDisabled = false
}: BasicFieldsProps) => {
  const sampleLocationsOptions = sampleLocations.map((sl) => ({ label: sl, value: sl }));
  return (
    <VStack w="100%" spacing={5}>
      <SearchableUserInput projectId={projectId} name="info.reporter" control={control} />

      {showSamplingLocation && (
        <FormSelect
          id="samplingLocation"
          name="info.samplingLocation"
          label="Sampling Location"
          rules={{ required: true }}
          options={sampleLocationsOptions}
          control={control}
        />
      )}
      <FormInput
        isDisabled={isDisabled}
        max={formatDate(new Date())}
        name="info.sampleTime"
        control={control}
        label="Sample Time"
        rules={{ required: true }}
        type="datetime-local"
      />
      <FormNumberInput
        isDisabled={isDisabled}
        name="info.numberOfSlides"
        rules={{ required: true, min: 1 }}
        precision={0}
        control={control}
        label="Number of Slides"
      />
      <FormNumberInput
        isDisabled={isDisabled}
        name="info.numberOfQuadrants"
        rules={{ required: true, min: 1 }}
        precision={0}
        control={control}
        label="Number of Quadrants"
      />
      <FormNumberInput
        isDisabled={isDisabled}
        name="info.jellyNetRadius"
        rules={{ required: true, min: 0 }}
        control={control}
        step={0.01}
        label="Jelly Tow Net Radius (m)"
      />
      <FormCheckbox name="info.noPlanktonObserved" control={control} label={NO_PLANKTON_OBSERVED} />
      <FormCheckbox
        name="info.transformRawCounts"
        control={control}
        isDisabled={true} // set at the project config level.
        label={'Compute Concentration From Counts'}
      />
    </VStack>
  );
};

export default BasicFields;
