import React from 'react';
import Slider from 'rc-slider';
import "./custom_slider.css";
import { Handle } from "rc-slider";

//HA 4/20/20: this controls the source and characteristics of the overlay layer options on the map
const text_style = {
  marginTop: -4,
  color: 'black',
}

const one_hour = 1000 * 60 * 60;  // 1 hour in milliseconds

function createLabel(startDate, hours, type) {
  let newDate = new Date(startDate.getTime() + (one_hour * hours));
  //default to only showing the day
  var displayString = newDate.toISOString().substring(5, 10)
  if (type === 'hour') {
    displayString = newDate.toISOString().substring(5, 10) + " " + newDate.toISOString().substring(11, 16)
  }
  return displayString
}

function Timeslider(props) {

  return (
    <div className="w-100 br2 mr2 ml2 pa2 black tc items-center">
      <Slider
        marks={{
          '-72': {
            style: text_style,
            label: createLabel(props.startDate, -72)
          },
          '-48': {
            style: text_style,
            label: createLabel(props.startDate, -48)
          },
          '-24': {
            style: text_style,
            label: createLabel(props.startDate, -24)
          },
          0: {
            style: text_style,
            label: createLabel(props.startDate, 0)
          },
          24: {
            style: text_style,
            label: createLabel(props.startDate, 24)
          },
          48: {
            style: text_style,
            label: createLabel(props.startDate, 48)
          },
          72: {
            style: text_style,
            label: createLabel(props.startDate, 72)
          }
        }}
        min={props.config.min}
        max={props.config.max}
        step={props.config.step}
        included={true}
        dots={false}
        dotStyle={{
          marginLeft: -4,
          borderColor: 'black',
          backgroundColor: "black",
        }}
        trackStyle={{
          display: 'none',
        }}
        startDate={props.startDate}
        onChange={props.onChange}
        value={props.timeOffset}
        handle={handleprops => MyHandle(handleprops, props.startDate)}
      />
    </div>
  )

}

function MyHandle(props, startDate) {
  const { value, dragging, index, ...rest } = props;

  return (
    <Handle className='flex justify-center' key={index} value={value} {...rest}>
      {dragging && <div className='f6 black bg-white nowrap-ns w4 br2 ba b--light-gray shadow-1 tc' style={{ marginTop: '-1.65em', marginLeft: '-4em' }}>{createLabel(startDate, value, 'hour')}</div>}
    </Handle>
  );
}

export default Timeslider;