import { Skeleton } from '@chakra-ui/react';
import { ProjectContext } from 'contexts/ProjectContext';
import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, max } from 'lodash';
import { PlotlyDataLayoutConfig } from 'plotly.js';
import { useContext } from 'react';
import { createLocationPallet } from 'shared/functions/colorPallets';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import { convertToIndex, filterBestResultForDay, siwiDatum } from './SIWIShared';

type SiwiCohortDatum = {
  [measuredAt: string]: {
    total: number;
    rollingAverage: number;
    isCurrentCohort: boolean;
    measuredAt: string;
  };
};

type SiwiCohortStructure = {
  [cohort: string]: SiwiCohortDatum;
};

const simpleMovingAverage = (totals: SiwiCohortDatum, window = 30): SiwiCohortDatum => {
  if (!totals || Object.keys(totals).length < window) {
    return {};
  }

  let index = window - 1;
  const length = Object.keys(totals).length;
  while (++index < length) {
    const windowSlice = Object.values(totals).slice(index - window, index);
    const sum = windowSlice.reduce((prev, curr) => prev + curr.total, 0);
    totals[Object.keys(totals)[index]].rollingAverage = sum / window;
  }
  return totals;
};

const SIWICohort = ({
  smbSiteId,
  skip,
  rollingWindow
}: {
  smbSiteId: number;
  skip?: boolean;
  rollingWindow: number;
}) => {
  const projectContext = useContext(ProjectContext);

  const cohortTransform = (data: siwiDatum[]): SiwiCohortStructure => {
    const filteredData = data.filter(
      (x) => x['TessSiwi.cohortId'] != 'NaN' && x['TessSiwi.cohortId'] != null
    );
    const byCohort = groupBy(filteredData, (d) => Number(d['TessSiwi.cohortId'].split('-')[1]));
    return Object.keys(byCohort)
      .filter((x) => Number(x) >= 2015) // This is needed to filter cohorts that ended in 2015
      .reduce((acc, cohortId: string) => {
        const byMeasuredDay = filterBestResultForDay(byCohort[cohortId], 'TessSiwi');
        acc[cohortId] = Object.keys(byMeasuredDay).reduce((subacc, measuredAt) => {
          subacc[byMeasuredDay[measuredAt]['TessSiwi.stockingDay']] = {
            total: convertToIndex(byMeasuredDay[measuredAt]['TessSiwi.sT']),
            rollingAverage: null, // convertToIndex(byMeasuredDay[measuredAt]['TessSiwi.sT']), // this prefills for the early days in the rolling avg
            isCurrentCohort:
              byMeasuredDay[measuredAt]['TessSiwi.cohortId'].split('-')[1] ===
              max(Object.keys(byCohort)),
            measuredAt: measuredAt
          };
          return subacc;
        }, {});

        acc[cohortId] = simpleMovingAverage(acc[cohortId], rollingWindow);
        return acc;
      }, {});
  };

  const graph = (data: SiwiCohortStructure): PlotlyDataLayoutConfig => {
    const pallet = createLocationPallet({
      locations: Object.keys(data).sort((a, b) => Number(b) - Number(a))
    });
    const plotData = Object.keys(data)
      .sort((a, b) => Number(a) - Number(b))
      .filter((cohortId) => Object.keys(data[cohortId]).length != 0)
      .map((cohortId) => {
        return {
          type: 'scatter',
          mode: 'lines',
          name: Object.values(data[cohortId])[0].isCurrentCohort
            ? `${cohortId} Cohort (current)`
            : `${cohortId} Cohort`,
          x: Object.keys(data[cohortId]),
          y: Object.keys(data[cohortId]).map(
            (stockingDay) => data[cohortId][stockingDay].rollingAverage
          ),
          marker: {
            color: pallet[cohortId]
          },
          line: {
            dash: Object.values(data[cohortId])[0].isCurrentCohort ? 'solid' : 'dash',
            width: Object.values(data[cohortId])[0].isCurrentCohort ? 3 : 2
          },
          showlegend: true,
          hovertemplate: Object.keys(data[cohortId]).map(
            (stockingDay) =>
              `SIWI Stress Total: %{y:.2f}<br>Stocking Day: %{x:.0f}<br>Measured At: ${format(
                parseISO(data[cohortId][stockingDay].measuredAt),
                'dd MMM yyyy'
              )}`
          )
        };
      });

    const layout = {
      yaxis: {
        title: {
          text: 'SIWI Stress Total'
        },
        showline: true
      },
      xaxis: {
        title: {
          text: 'Estimated Site Stocking Day'
        },
        showline: true
      },
      autosize: true,
      margin: {
        t: 0,
        b: 40,
        l: 80,
        r: 0
      },
      legend: {
        traceorder: 'reversed'
      }
    };

    return {
      //@ts-ignores
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: ['TessSiwi.sT'],
      dimensions: ['TessSiwi.cohortId', 'TessSiwi.stockingDay', 'TessSiwi.measuredAt'],
      filters: [{ member: 'Site.id', operator: 'equals', values: [smbSiteId.toString()] }],
      timezone: projectContext.timezone,
      timeDimensions: [
        {
          dimension: 'TessSiwi.measuredAt',
          granularity: 'day',
          dateRange: ['2015-01-01', format(new Date(), 'yyyy-MM-dd')]
        }
      ],
      order: {
        'TessSiwi.measuredAt': 'desc'
      }
    },
    transform: cohortTransform,
    graph,
    options: {
      skip,
      dependencies: {
        cubeName: 'TessSiwi',
        rollingWindow: rollingWindow
      }
    }
  });

  if (isLoading) {
    return <Skeleton minH="450px" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.length ? (
    <Plot className="w-100 h-100 siwi-cohort" useResizeHandler={true} {...plot} />
  ) : plot?.data?.length === 0 && projectContext.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <NoData minH="450px" />
  );
};

export default SIWICohort;
