import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Text, Tooltip, VStack, useRadioGroup } from '@chakra-ui/react';
import RadioCard from 'components/Forms/RadioCard';
import { EquipmentSelectionState } from 'graphql/generated';
import { UseControllerProps, useController } from 'react-hook-form';
import { EquipmentEntryForm } from '../EquipmentEntryModal';

export const EquipmentSelectionEntry = ({
  state,
  ...props
}: UseControllerProps<EquipmentEntryForm, `states`> & {
  state: EquipmentSelectionState;
}) => {
  const { field } = useController<EquipmentEntryForm, `states`>(props);
  const { getRadioProps } = useRadioGroup({
    value: field?.value?.[state.id]?.toString() ?? '',
    onChange: (value) => field.onChange({ ...field.value, [state.id]: value })
  });

  return (
    <VStack w="100%">
      <Box>
        <Text display="inline">{state.name}</Text>
        {state?.description && (
          <Tooltip display="inline" label={state.description}>
            <InfoOutlineIcon ml="5px" />
          </Tooltip>
        )}
      </Box>
      {state.options.map((o) => {
        return (
          <RadioCard
            key={o.id}
            boxProps={{ w: '100%', borderRadius: 'lg', borderColor: o.color, borderWidth: '2px' }}
            checkedColor={o.color}
            {...getRadioProps({ value: o.id.toString() })}>
            <Text display="inline">{o.name}</Text>
          </RadioCard>
        );
      })}
    </VStack>
  );
};
