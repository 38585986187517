import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Center, CenterProps, VStack } from '@chakra-ui/react';
import { FC } from 'react';

type NoticeProps = {
  noticeColor?: string;
} & CenterProps;

export const Notice: FC<NoticeProps> = ({ children, noticeColor = 'blue.500', ...props }) => {
  return (
    <Center w="100%" {...props}>
      <VStack w="100%" border="2px" borderRadius="md" borderColor={noticeColor} p="25px">
        <InfoOutlineIcon boxSize={12} color={noticeColor} />
        {children}
      </VStack>
    </Center>
  );
};
