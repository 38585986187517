import { MinusIcon } from '@chakra-ui/icons';
import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import React from 'react';

export type ThreeStateCheck = 'all' | 'some' | 'none';

type ThreeStateCheckboxProps = {
  state?: ThreeStateCheck;
} & CheckboxProps;

const ThreeStateCheckbox = (props: ThreeStateCheckboxProps) => {
  return (
    <Checkbox
      isIndeterminate={props.state === 'some'}
      icon={props.state === 'some' ? <MinusIcon /> : undefined}
      isChecked={props.state === 'all' || props.state === 'some'}
      {...props}
    />
  );
};

export default ThreeStateCheckbox;
