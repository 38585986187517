import { Box, HStack, Text } from '@chakra-ui/react';
import { DateRange, TimeDimensionGranularity } from '@cubejs-client/core';
import BaseInput from 'components/Forms/BaseInput';
import BaseSelect from 'components/Forms/BaseSelect';
import { format, sub } from 'date-fns';
import { isArray } from 'lodash';
import { useMemo } from 'react';

export type TimeFragment = {
  granularity?: TimeDimensionGranularity;
  dateRange?: DateRange;
};

export type TimeFragmentOption = {
  granularity?: { label: string; value: TimeDimensionGranularity };
  dateRange?: { label: string; value: DateRange };
};

export const GRANULARITIES: { label: string; value: TimeDimensionGranularity }[] = [
  { label: 'No Grouping', value: undefined },
  { label: 'Minute', value: 'minute' },
  { label: 'Hour', value: 'hour' },
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' }
];

export const TIMEFRAMES: { label: string; value: DateRange }[] = [
  {
    label: 'Custom',
    value: [format(sub(new Date(), { months: 1 }), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]
  },
  { label: 'Last Day', value: 'last day until now' },
  { label: 'Last Week', value: 'last 7 days until now' },
  { label: 'Last Month', value: 'last 30 days until now' },
  { label: 'Last Quarter', value: 'last 90 days until now' },
  { label: 'Last Year', value: 'last 365 days until now' }
];

export type TimeFragmentPickerProps = {
  timeFragment?: TimeFragment;
  includeNoGrouping?: boolean;
  includeByMinute?: boolean;
  onChange?: (timeFragment: TimeFragment) => void;
  isDisabled?: boolean;
  granularityIsDisabled?: boolean;
  showGranularity?: boolean;
  timeFrames?: string[];
  timeFramesOverride?: { label: string; value: DateRange }[];
  label?: string;
  display?: 'inline';
};

const TimeFragmentPicker = ({
  timeFragment = { granularity: 'day', dateRange: 'last 30 days until now' },
  includeNoGrouping = true,
  includeByMinute = false,
  isDisabled = false,
  granularityIsDisabled = false,
  showGranularity = true,
  label = 'Time Frame',
  timeFrames,
  timeFramesOverride,
  display,
  onChange
}: TimeFragmentPickerProps) => {
  const granularityOptions = useMemo(() => {
    let grans = GRANULARITIES;

    if (!includeNoGrouping) {
      grans = grans.filter((g) => g.value);
    }

    if (!includeByMinute) {
      grans = grans.filter((g) => g.value !== 'minute');
    }

    return grans;
  }, [includeNoGrouping, includeByMinute]);

  const allTimeFrames = useMemo(
    () => (timeFramesOverride ? timeFramesOverride : TIMEFRAMES),
    [timeFramesOverride]
  );

  const timeFrameOptions = useMemo(() => {
    if (timeFramesOverride) return timeFramesOverride;
    if (timeFrames && timeFrames.length > 0) {
      return allTimeFrames.filter((tf) => timeFrames.includes(tf.label));
    } else {
      return allTimeFrames;
    }
  }, [timeFrames]);

  return (
    <Box display={'inline-block'} minW="400px">
      {display === 'inline' && (
        <Text mr="10px" display="inline">
          {label}
        </Text>
      )}
      <BaseSelect
        data-cypress="time-fragment-time-frame"
        isDisabled={isDisabled}
        display="inline-block"
        w="fit-content"
        styles={{
          control: (base) => ({
            ...base,
            width: '200px',
            marginRight: '15px',
            border: '1px solid var(--chakra-colors-chakra-border-color)'
          }),
          menu: (base) => ({
            ...base,
            zIndex: 99
          })
        }}
        value={allTimeFrames.find((tf) => tf?.value === timeFragment.dateRange)}
        options={timeFrameOptions}
        label={display !== 'inline' && label}
        onChange={(option) => {
          onChange({ ...timeFragment, dateRange: option.value });
        }}
      />

      {showGranularity && (
        <BaseSelect
          isDisabled={isDisabled || granularityIsDisabled}
          display="inline-block"
          w="fit-content"
          styles={{
            control: (base) => ({
              ...base,
              width: '150px',
              border: '1px solid var(--chakra-colors-chakra-border-color)'
            })
          }}
          value={GRANULARITIES.find((g) => g?.value === timeFragment.granularity)}
          options={granularityOptions}
          label="By"
          onChange={(option) => {
            onChange({ ...timeFragment, granularity: option.value });
          }}
        />
      )}

      {isArray(timeFragment.dateRange) && (
        <HStack w="100%" alignItems="start">
          <BaseInput
            isDisabled={isDisabled}
            w="180px"
            max={timeFragment.dateRange?.[1]}
            label="Start"
            type="date"
            value={timeFragment.dateRange?.[0]}
            onChange={(e) => {
              onChange({
                ...timeFragment,
                dateRange: [e.currentTarget.value, timeFragment.dateRange[1]]
              });
            }}
          />
          <BaseInput
            isDisabled={isDisabled}
            name="end"
            w="180px"
            min={timeFragment.dateRange?.[0]}
            label="End"
            type="date"
            value={timeFragment.dateRange?.[1]}
            onChange={(e) => {
              onChange({
                ...timeFragment,
                dateRange: [timeFragment.dateRange[0], e.currentTarget.value]
              });
            }}
          />
        </HStack>
      )}
    </Box>
  );
};

export default TimeFragmentPicker;
