import { gql, useMutation } from '@apollo/client';
import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  VStack,
  useRadioGroup,
  useToast
} from '@chakra-ui/react';
import { formatDate } from 'components/Forms/BaseInput';
import FormInput from 'components/Forms/FormInput';
import RadioCard from 'components/Forms/RadioCard';
import Shade from 'components/Shade';
import { SiteEquipment } from 'graphql/generated';
import { isEmpty, isNumber } from 'lodash';
import { useController, useForm } from 'react-hook-form';
import { EquipmentEntryStateRouter } from './StateTypeHandlers/EquipmentEntryStateRouter';

const CREATE_EQUIPMENT_ENTRY = gql`
  mutation CreateEquipmentEntry($equipmentUpdate: SiteEquipmentEntryInput!) {
    createSiteEquipmentEntry(equipmentUpdate: $equipmentUpdate)
  }
`;

interface EquipmentEntryModalProps {
  onClose: (
    saveSuccess?: boolean,
    form?: EquipmentEntryForm,
    equipment?: Partial<SiteEquipment>
  ) => Promise<void>;
  equipment: Partial<SiteEquipment>;
}

export interface EquipmentEntryForm {
  updateType?: 'action' | 'state' | null;
  action?: number;
  states?: Record<number, string>;
  updateTime: string;
}

export const EquipmentEntryModal = ({ onClose, equipment }: EquipmentEntryModalProps) => {
  const toast = useToast();
  const [createEquipmentEntry, result] = useMutation(CREATE_EQUIPMENT_ENTRY);

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { isSubmitting, isValid, errors }
  } = useForm<EquipmentEntryForm>({ defaultValues: { updateTime: formatDate(new Date()) } });

  //Sorry for the mess.
  const validateForm = (formData: EquipmentEntryForm) => {
    let isValid = true;
    if (formData.updateType === 'action') {
      if (formData.action === null || formData.action === undefined) {
        isValid = false;
        setError('action', {
          message: 'An action must be selected when submitting an action update.'
        });
      }
    } else if (formData.updateType === 'state') {
      //Potential need for change -- enforce all state fields have a value. AKA we don't support optional fields atm
      if (Object.keys(formData?.states ?? {}).length !== equipment.equipmentType.states.length) {
        isValid = false;
        setError('states', { message: 'A state update must include values for all fields.' });
      }
      Object.entries(formData?.states ?? {}).map(([stateId, value]) => {
        const stateTemplate = equipment.equipmentType.states.find(
          (s) => s.id.toString() === stateId
        );
        switch (stateTemplate.__typename) {
          case 'EquipmentNumberState':
            if (isEmpty(value) || !isNumber(Number(value))) {
              //@ts-ignore
              setError(`states.${stateId}`, { message: 'Must have numeric value.' });
              isValid = false;
            }
            break;
          case 'EquipmentTextState':
            if (isEmpty(value)) {
              //@ts-ignore
              setError(`states.${stateId}`, { message: 'Must have numeric value.' });
              isValid = false;
            }
            break;
        }
      });
    } else {
      isValid = false;
      setError('root', { message: 'A state or action update must be chosen.' });
    }

    if (isValid) clearErrors();

    return isValid;
  };

  const submit = async (form: EquipmentEntryForm) => {
    const v = validateForm(form);
    if (v) {
      try {
        const resp = await createEquipmentEntry({
          variables: {
            equipmentUpdate: {
              siteEquipmentId: Number(equipment.id),
              actionId: form.updateType === 'action' ? form.action : undefined,
              stateUpdates: !isEmpty(form?.states)
                ? Object.entries(form.states).map(([stateId, value]) => ({
                    equipmentStateId: Number(stateId),
                    value
                  }))
                : undefined,
              updateTime: new Date(form.updateTime).toISOString()
            }
          }
        });
        if (!resp?.data?.createSiteEquipmentEntry)
          throw new Error('Error submitting equipment update.');

        toast({
          status: 'success',
          description: 'Equipment updated successfully.'
        });
        onClose(true, form, equipment);
      } catch (e) {
        console.error(e);
        toast({
          status: 'error',
          description: 'Error updating equipment.'
        });
      }
    }
  };

  const { field: actionSelection } = useController({ control, name: 'action' });
  const { field: updateType } = useController({ control, name: 'updateType' });

  const { getRadioProps: getUpdateTypeRadioProps } = useRadioGroup({
    value: updateType?.value?.toString() ?? '',
    defaultValue:
      equipment.equipmentType.states.length === 0 && equipment.equipmentType.actions.length !== 0
        ? 'action'
        : equipment.equipmentType.actions.length === 0 && equipment.equipmentType.states.length > 0
          ? 'state'
          : null,
    onChange: (value) => {
      if (equipment.equipmentType.actions.length === 1) {
        actionSelection.onChange(equipment.equipmentType.actions[0].id);
      }
      updateType.onChange(value);
    }
  });

  const { getRadioProps: getActionRadioProps } = useRadioGroup({
    value: actionSelection?.value?.toString() ?? '',
    onChange: (value) => actionSelection.onChange(Number(value))
  });

  return (
    <Modal isOpen={true} size="4xl" onClose={() => onClose(false)}>
      <ModalOverlay />
      <form data-cyrpess="equipment-entry-form" onSubmit={handleSubmit(submit)}>
        <ModalContent>
          <ModalHeader>
            Update Equipment
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody w="100%">
            <Alert mb="15px" status="info">
              <AlertIcon />
              <Text>
                An equipment update can either be an{' '}
                <Text display="inline" fontWeight="bold">
                  Action
                </Text>{' '}
                or{' '}
                <Text display="inline" fontWeight="bold">
                  State
                </Text>{' '}
                update.
              </Text>
            </Alert>
            <HStack alignItems="start">
              <Box w="50%">
                {equipment.equipmentType.states.length > 0 && (
                  <>
                    <RadioCard
                      boxProps={{ w: '100%' }}
                      {...getUpdateTypeRadioProps({ value: 'state' })}>
                      <Text display="inline">State Update</Text>
                    </RadioCard>
                    <Shade
                      shaded={updateType.value !== 'state'}
                      shadeContent={
                        !updateType.value && (
                          <Text color="white">
                            Select &quot;State Update&quot; or &quot;Action Update&quot; to continue
                          </Text>
                        )
                      }>
                      <VStack mt="15px" alignItems="start">
                        {equipment.equipmentType.states.map((state) => {
                          return (
                            <>
                              <EquipmentEntryStateRouter
                                key={state.id}
                                control={control}
                                name="states"
                                state={state}
                              />
                              <Divider my="15px" />
                            </>
                          );
                        })}
                      </VStack>
                      {errors?.states && (
                        <Text mt="5px" textAlign="center" color="red.500">
                          {errors.states.message}
                        </Text>
                      )}
                    </Shade>
                  </>
                )}
              </Box>
              <Box w="50%">
                {equipment.equipmentType.actions.length > 0 && (
                  <>
                    <RadioCard
                      boxProps={{ w: '100%' }}
                      {...getUpdateTypeRadioProps({ value: 'action' })}>
                      <Text display="inline">Action Update</Text>
                    </RadioCard>
                    <Shade
                      shaded={updateType.value !== 'action'}
                      shadeContent={
                        !updateType.value && (
                          <Text color="white">
                            Select &quot;Action Update&quot; or &quot;State Update&quot; to continue
                          </Text>
                        )
                      }>
                      <VStack mt="15px" alignItems="start">
                        {equipment.equipmentType.actions.map((action) => {
                          return (
                            <Tooltip key={action.id} label={action.description}>
                              <RadioCard
                                boxProps={{ w: '100%' }}
                                key={action.id}
                                {...getActionRadioProps({ value: action.id.toString() })}>
                                <Text display="inline">{action.name}</Text>
                              </RadioCard>
                            </Tooltip>
                          );
                        })}
                        {errors?.action && (
                          <Text mt="5px" textAlign="center" color="red.500">
                            {errors.action.message}
                          </Text>
                        )}
                      </VStack>
                    </Shade>
                  </>
                )}
              </Box>
            </HStack>
            <HStack mt="25px" w="100%" justifyContent="center">
              <Box>
                <FormInput
                  max={formatDate(new Date())}
                  name="updateTime"
                  control={control}
                  label="Update Time"
                  rules={{ required: true }}
                  type="datetime-local"
                />
              </Box>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <VStack w="100%">
              {errors?.root && <Text color="red.500">{errors.root.message}</Text>}
              <ButtonGroup w="100%" justifyContent="space-between">
                <Button isLoading={result?.loading} onClick={() => onClose(false)}>
                  Cancel
                </Button>
                <Button
                  isLoading={result?.loading}
                  type="submit"
                  isDisabled={isSubmitting || !isValid}
                  leftIcon={<CheckCircleIcon mb="2px" />}
                  colorScheme="green">
                  Submit
                </Button>
              </ButtonGroup>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};
