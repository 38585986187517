import {
  Box,
  HStack,
  FormLabel,
  Slider,
  SliderTrack,
  Text,
  SliderFilledTrack,
  SliderMark
} from '@chakra-ui/react';
import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';
import { ParameterOptions } from './ForecastDetailPlot';
import { permissions } from '@scoot/permissions';
import { UserContext } from 'contexts/UserContext';
import { useContext } from 'react';

const Controller = ({ chartSettings, setChartSettings }: ChartController<ChartSettings>) => {
  const currentUser = useContext(UserContext);
  const variableOptions = permissions.isSuperuser(currentUser)
    ? [
        { label: 'Water Temperature', value: 'waterTempAvg' },
        { label: 'Oxygen Saturation', value: 'oxygenSaturationAvg' },
        { label: 'Salinity', value: 'salinityAvg' },
        { label: 'Oxygen Concentration', value: 'oxygenConcentrationAvg' },
        { label: 'Pycnocline Depth', value: 'pycnoDepthAvg' },
        { label: 'TKE (mixing force)', value: 'tkeAvg' },
        { label: 'Buoyancy Frequency (stability)', value: 'buoyancyFreqAvg' }
      ]
    : [
        { label: 'Water Temperature', value: 'waterTempAvg' },
        { label: 'Oxygen Saturation', value: 'oxygenSaturationAvg' },
        { label: 'Salinity', value: 'salinityAvg' },
        { label: 'Oxygen Concentration', value: 'oxygenConcentrationAvg' }
      ];
  chartSettings.parameters = chartSettings.parameters
    ? chartSettings.parameters
    : (variableOptions as ParameterOptions);

  const minDepth = Math.min(...chartSettings.availableDepths);
  const maxDepth = Math.max(...chartSettings.availableDepths);
  return (
    <Box>
      <HStack alignItems="center" justifyContent="space-evenly" h="75px">
        <FormLabel display="inline" mr="-50px" alignItems="right">
          <Text mb="-10px" mr="40px" ml="40px">
            Depth (m)
          </Text>
        </FormLabel>
        <Slider
          aria-label="slider-ex-6"
          width="15%"
          overflow={'visible'}
          min={minDepth}
          max={maxDepth}
          step={3}
          onChange={(value) => {
            setChartSettings({
              ...chartSettings,
              selectedDepth: value
            });
          }}>
          {chartSettings.availableDepths.map((d, idx) => (
            <>
              <SliderMark value={d} key={idx} ml="-3px">
                <b>{'.'}</b>
              </SliderMark>
              <SliderMark value={d} key={idx} mt="18px" ml="-18px" w="35px">
                {Math.abs(d).toFixed(0)}
              </SliderMark>
            </>
          ))}
          <SliderTrack>
            <SliderFilledTrack bg="blue.400" />
          </SliderTrack>
        </Slider>
        <ChartSwitchPanel
          selects={[
            {
              value: chartSettings?.parameters,
              defaultValue: chartSettings?.parameters,
              options: variableOptions,
              isMulti: true,
              placeholder: 'Select Hydrographic Parameters',
              onChange: (x) =>
                setChartSettings({
                  ...chartSettings,
                  parameters: x as ParameterOptions
                })
            }
          ]}
          width="75%"
        />
      </HStack>
    </Box>
  );
};

export default Controller;
