import { Skeleton } from '@chakra-ui/react';
import GraphError from '../GraphError';
import NoData from '../NoData';
import Plot from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';
import useAtmosPlot, { ParameterOptions } from './ForecastDetailPlot';

export type ChartSettings = BaseChartSettings & {
  useLocalMinMax?: boolean;
  useSensor?: boolean;
  skipForecasting?: boolean;
  dateRange?: string;
  parameters: ParameterOptions;
  showParameterSelect?: boolean;
  availableDepths?: number[];
  selectedDepth?: number;
  setChartSettings?: (chartSettings: ChartSettings) => void;
};

const Chart = (
  props: BaseChartProps<ChartSettings> & {
    setChartSettings: (chartSettings: ChartSettings) => void;
  }
) => {
  const { isLoading, error, hasData, plot } = useAtmosPlot(props);
  const height = props.settings?.parameters?.length
    ? `${props.settings?.parameters?.length * 250}px`
    : '500px';

  if (isLoading) {
    return <Skeleton minH={height} height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH={height} />;
  }

  return hasData ? (
    <Plot
      style={{ height }}
      className="w-100 barge-hydrography-plot"
      useResizeHandler={true}
      {...plot}
    />
  ) : (
    <NoData minH={height} />
  );
};

export default Chart;
