import { Skeleton } from '@chakra-ui/react';
import { ProjectContext } from 'contexts/ProjectContext';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import useCubeLTG from 'hooks/useCubeLTG';
import { PlotlyDataLayoutConfig } from 'plotly.js';
import { useContext } from 'react';
import { trafficLightPallet } from 'shared/functions/colorPallets';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import {
  categoryPrefix,
  fullDatumTransform,
  indicatorMap,
  safeFormat,
  siwiDimensions,
  siwiMeasures,
  siwiStructure,
  unitMap
} from './SIWIShared';

const SIWIDoughnut = ({ smbSiteId, skip }: { smbSiteId: number; skip?: boolean }) => {
  const projectContext = useContext(ProjectContext);

  const graph = (data: siwiStructure): PlotlyDataLayoutConfig => {
    const plotTime = Object.keys(data[Object.keys(data)[0]])[0];

    const sortedIndicators = Object.keys(data)
      .filter(
        (indicator) =>
          indicator != indicatorMap.sT &&
          !Object.values(indicatorMap)
            .filter((x) => x.includes(categoryPrefix))
            .includes(indicator)
      )
      .sort((a, b) => data[a][plotTime]?.weight - data[b][plotTime]?.weight);

    const plotData = Object.keys(data[indicatorMap.sT]).length
      ? [
          {
            hole: 0.5,
            type: 'pie',
            values: sortedIndicators.map((indicator) => data[indicator][plotTime].weight),
            labels: sortedIndicators,
            showlegend: false,
            hoverinfo: 'text',
            hovertext: sortedIndicators.map(
              (indicator) =>
                `Contribution to total stress: ${safeFormat(
                  data[indicator][plotTime].score * data[indicator][plotTime].weight,
                  2,
                  '0.0'
                )}<br>${indicator} score: ${safeFormat(
                  data[indicator][plotTime].score,
                  0,
                  '0'
                )}<br>${unitMap[data[indicator][plotTime].units]}: ${
                  isNaN(data[indicator][plotTime].value)
                    ? data[indicator][plotTime].value?.toString().replace('NaN', 'None')
                    : parseFloat(Number(data[indicator][plotTime].value).toPrecision(3))
                }<br>Data Source: ${data[indicator][plotTime].missing ? 'Estimated' : 'Farm Data'}`
            ),
            textinfo: 'label',
            textposition: 'outside',
            marker: {
              line: {
                width: 1
              },
              colors: sortedIndicators.map(
                (indicator) =>
                  trafficLightPallet[Number((data[indicator][plotTime].score / 100).toFixed(1))]
              ),
              colorbar: {}
            },
            sort: false
          }
        ]
      : [];

    const layout = {
      autosize: true,
      hovermode: 'closest',
      margin: {
        t: 20,
        b: 20,
        l: 0,
        r: 0
      },
      annotations: Object.keys(data[indicatorMap.sT]).length
        ? [
            {
              showarrow: false,
              text: data[indicatorMap.sT][plotTime].ko
                ? `<b style="font-size: 40px; color: ${trafficLightPallet[1.0]}">KO</b><br>(${plotTime})`
                : `<b style="font-size: 40px">${safeFormat(
                    data[indicatorMap.sT][plotTime].score
                  )}</b><br>(${format(parseISO(plotTime), 'eee dd MMM yyyy')})`,
              x: 0.5,
              y: 0.5
            }
          ]
        : []
    };

    return {
      //@ts-ignores
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: siwiMeasures('LatestSiwi'),
      dimensions: siwiDimensions('LatestSiwi'),
      filters: [{ member: 'Site.id', operator: 'equals', values: [smbSiteId.toString()] }],
      timezone: projectContext.timezone,
      timeDimensions: [
        {
          dimension: 'LatestSiwi.measuredAt',
          granularity: 'day'
        }
      ]
    },

    transform: fullDatumTransform,
    graph,
    options: {
      skip,
      dependencies: {
        cubeName: 'LatestSiwi'
      }
    }
  });

  if (isLoading) {
    return <Skeleton minH="350px" width="100%" />;
  }

  if (error) {
    return <GraphError minH="350px" />;
  }

  return plot?.data?.length ? (
    <Plot className="w-100 h-100 siwi-doughnut-plot" useResizeHandler={true} {...plot} />
  ) : plot?.data?.length === 0 && projectContext.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <NoData minH="350px" />
  );
};

export default SIWIDoughnut;
