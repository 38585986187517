import { gql, useMutation, useQuery } from '@apollo/client';
import { AttachmentIcon } from '@chakra-ui/icons';
import { Button, Center, Flex, Spinner } from '@chakra-ui/react';
import axios from 'axios';
import { ChartErrorText } from 'components/Charts/ChartErrorText';
import { ProjectContext } from 'contexts/ProjectContext';
import { GET_S3_PRESIGNED_URL } from 'graphql/globalMutations';
import { GET_EVENT_NOTIFICATION_EMAILS } from 'graphql/globalQueries';
import { useContext, useEffect, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import MentionStyle from './MentionStyle';

const SAVE_EVENT_COMMENT = gql`
  mutation saveEventComment(
    $eventId: Int!
    $comment: String!
    $attachmentRefs: [String!]
    $notify: [String!]
  ) {
    saveEventComment(
      eventId: $eventId
      comment: $comment
      attachmentRefs: $attachmentRefs
      notify: $notify
    ) {
      id
      eventType
      severity
      creator
      created
      siteId
      startTime
      endTime
      detail
      comments {
        id
        eventId
        creator
        comment
        created
        attachmentRefs
      }
      subscribed
      hasNew
    }
  }
`;

type EventCommentInputProps = {
  onCommentSaved?: () => void;
  eventId: number;
};

const EventCommentInput = ({ onCommentSaved, eventId }: EventCommentInputProps) => {
  const projectContext = useContext(ProjectContext);

  const [comment, setComment] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [notify, setNotify] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [emails, setEmails] = useState<string[]>([]);

  const [getPresignedUrl] = useMutation<{ getS3PresignedURL: string }>(GET_S3_PRESIGNED_URL);
  const [saveEventComment] = useMutation(SAVE_EVENT_COMMENT);

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError
  } = useQuery(GET_EVENT_NOTIFICATION_EMAILS, {
    variables: { projectId: projectContext.id }
  });

  useEffect(() => {
    if (!usersData) return;

    const emails = usersData.getEventNotificationEmails.map((u) => ({ id: u, display: u }));

    setEmails(emails);
  }, [usersData]);

  const saveComment = async () => {
    setIsSubmitting(true);
    try {
      await saveEventComment({
        variables: {
          eventId,
          comment: comment,
          attachmentRefs: attachments,
          notify: notify
        }
      });

      setIsSubmitting(false);
      setComment('');
      setAttachments([]);
      setNotify([]);
    } catch (e) {
      console.error('Error saving comment ', e);
    }

    setIsSubmitting(false);
    if (onCommentSaved) onCommentSaved();
  };

  const onCommentChange = (_event: unknown, newValue, newTextValue, mentions) => {
    setComment(newValue);
    setNotify(mentions.map((m) => m.display));
  };

  const uploadFile = async (e) => {
    setIsUploading(true);
    const f = e.target.files[0];
    const uniqueFileName = new Date().getTime() + '/' + f.name;
    try {
      const url = await getPresignedUrl({
        variables: { name: uniqueFileName, contentType: f.type, operation: 'putObject' }
      });
      await axios.put(url.data.getS3PresignedURL, f, { headers: { 'Content-Type': f.type } });
      setIsUploading(false);
      const attTmp = attachments.slice();
      attTmp.push(uniqueFileName);
      setAttachments(attTmp);
    } catch (e) {
      console.error('Error uploading file: ', e);
    }

    setIsUploading(false);
  };

  // eslint-disable-next-line no-constant-condition
  if (usersLoading || !usersData) {
    return (
      <Center w="100%" className="w-40">
        <Spinner size={'lg'} />
      </Center>
    );
  }

  if (usersError) {
    console.error(`GraphQL Error: ${usersError}`);
    return <ChartErrorText />;
  }

  return (
    <>
      <MentionsInput
        value={comment}
        onChange={onCommentChange}
        placeholder="New Comment"
        style={MentionStyle}>
        <Mention data={emails} />
      </MentionsInput>
      <Flex align="center" w="100%" justifyContent="space-evenly" m="10px 0px">
        <p style={{ fontSize: 'small' }}>
          <i>Use &apos;@&apos; to notify someone.</i>
        </p>
        {isUploading ? (
          <Flex className="event-attach-disabled">
            <Spinner size="sm" />
            <p>Uploading...</p>
          </Flex>
        ) : (
          <label className="event-attach" htmlFor="eventAttach">
            Attach
          </label>
        )}
        <input
          className="event-attach-input"
          type="file"
          name="fileUpload"
          id="eventAttach"
          onChange={uploadFile}
        />
        <Button
          ml="10px"
          isLoading={isSubmitting}
          loadingText="Submitting..."
          onClick={saveComment}
          colorScheme="blue"
          isDisabled={comment.length === 0 && attachments.length === 0}>
          Submit
        </Button>
      </Flex>
      {attachments.map((name) => {
        name = name.split('/')[1]; // remove the timestamp to make it more readable
        return (
          <Flex key={name} align="center">
            <small>
              <AttachmentIcon />
              <i>{name}</i>
            </small>
          </Flex>
        );
      })}
    </>
  );
};

export default EventCommentInput;
