// @ts-strict
import ChartTile from 'components/Charts/ChartTile';
import {
  default as BargeHydrography,
  ChartSettings as BargeHydrographyOptions
} from 'components/Charts/Hydrography/Chart';
import BargeHydrographyController from 'components/Charts/Hydrography/Controller';
import { ProjectContextType } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { Site } from 'graphql/generated';
import { useContext, useState } from 'react';

interface ManualHydrographyTabProps {
  project: ProjectContextType;
  site: Site;
  selected: boolean;
}

function HydrographyData({ site, project, selected }: ManualHydrographyTabProps) {
  const [hydrographyOptions, setHydrographyOptions] = useState<BargeHydrographyOptions>({
    project,
    site,
    useLocalMinMax: false,
    useSensor: false,
    parameters: ['waterTempC', 'oxygenSat', 'salinityPsu', 'visibility']
  });
  const userContext = useContext(UserContext);

  if (!userContext) return null;

  return (
    <ChartTile
      heading="Barge Hydrography"
      hash="barge-hydrography"
      site={site}
      control={
        <BargeHydrographyController
          chartSettings={hydrographyOptions}
          setChartSettings={setHydrographyOptions}
        />
      }>
      <BargeHydrography skip={!selected} settings={hydrographyOptions} />
    </ChartTile>
  );
}

export default HydrographyData;
