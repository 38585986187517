import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Operation } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { SentryLink } from 'apollo-link-sentry';
import config from '../config';
// @ts-ignore
import * as shajs from 'sha.js';

async function sha256(message) {
  // @ts-ignore - shajs is ancient, hotfix until cypress
  return shajs('sha256').update(message).digest('hex');
}

const httpLink = new HttpLink({
  uri: ({ operationName }) => `${config.api.DASHBOARD_GQL_API}/${operationName}`,
  credentials: 'include'
});

const sentryLink = new SentryLink();

const persistedLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true }).concat(
  httpLink
);

const baseLinks = ApolloLink.from([sentryLink]);

const persistedLinkExceptions = ['getUser', 'currentUser'];

const cypressTest = localStorage.getItem('cypress.disableAPQ');
const useApq = (operation: Operation) => {
  if (process.env.NODE_ENV === 'test' || cypressTest) return false;

  const roughylSerializedParams = Object.values(operation.variables).reduce(
    (prev, curr) => (prev += curr),
    ''
  );

  // Rough way to get around GET / OPTIONS url length limits.
  // Without this, GET requests are attempted with too long of URL's and the request fails.
  if (roughylSerializedParams.length > 2000) {
    return false;
  }

  return !persistedLinkExceptions.includes(operation.operationName);
};

const link = baseLinks.split(useApq, persistedLink, httpLink);

const client = new ApolloClient({
  cache: new InMemoryCache({
    // disable data normalization for timeseries data since these types will never change.
    // See https://www.apollographql.com/docs/react/caching/cache-configuration/#disabling-normalization
    typePolicies: {
      DataValue: {
        keyFields: false
      },
      AggregatedValue: {
        keyFields: false
      }
    }
  }),
  link
});

export { client };
