import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react';
import { EquipmentNumberState } from 'graphql/generated';

export const NumberView = ({ equipmentState }: { equipmentState: EquipmentNumberState }) => {
  return (
    <VStack w="200px">
      <HStack>
        <Text>{equipmentState.name}</Text>
        {equipmentState?.description && (
          <Tooltip label={equipmentState.description}>
            <InfoOutlineIcon />
          </Tooltip>
        )}
      </HStack>
      <NumberInput isDisabled={true}>
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </VStack>
  );
};
