import { useController, UseControllerProps } from 'react-hook-form';
import { GroupBase } from 'react-select';
import BaseSelect, { BaseSelectProps } from './BaseSelect';

type FormSelectProps<
  TForm,
  TOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
> = BaseSelectProps<TOption, IsMulti, Group> & UseControllerProps<TForm>;

const FormSelect = <
  TForm,
  TOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
>({
  ...props
}: FormSelectProps<TForm, TOption, IsMulti, Group>) => {
  const { field, fieldState } = useController(props);

  return (
    <BaseSelect
      {...field}
      {...fieldState}
      {...props}
      isRequired={props?.rules?.required ? true : false}
    />
  );
};

export default FormSelect;
