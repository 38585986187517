import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper
} from '@chakra-ui/react';
import { useController, UseControllerProps } from 'react-hook-form';

type FormNumberInputProps<TForm> = {
  label: string;
} & UseControllerProps<TForm> &
  NumberInputProps;

const FormNumberInput = <TForm,>({ label, ...props }: FormNumberInputProps<TForm>) => {
  const { field, fieldState } = useController(props);

  const { value, ...fieldProps } = field;

  return (
    <FormControl
      isInvalid={!!fieldState.error}
      isRequired={props?.rules?.required ? true : false}
      w={props?.w}
      mb="20px">
      <FormLabel mb="0px">{label}</FormLabel>
      <NumberInput
        min={props?.rules?.min as number}
        max={props?.rules?.max as number}
        //@ts-ignore
        value={value}
        onBlur={field.onBlur}
        precision={2}
        step={1}
        {...fieldProps}
        {...props}
        onChange={(s, n) => {
          field.onChange(s);
          if (props.onChange) {
            props.onChange(s, n);
          }
        }}>
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </FormControl>
  );
};

export default FormNumberInput;
