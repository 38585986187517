import React from 'react';
import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import { WEATHER_PLACEHOLDER } from './Weather';
import { WiRaindrop } from 'weather-icons-react';

const Precipitation = ({ value }: { value: number | string }) => {
  return (
    <Box textAlign="center">
      <Tooltip label="Total Precipitation (sum next 24 hrs)">
        {value === WEATHER_PLACEHOLDER || value === undefined ? (
          <Text>{WEATHER_PLACEHOLDER}</Text>
        ) : (
          <HStack>
            <Box color="blue.600">
              <WiRaindrop style={{ marginRight: '-5px' }} size={40} />
            </Box>
            <Text marginInlineStart="0px">{(value as number).toFixed(1)} mm</Text>
          </HStack>
        )}
      </Tooltip>
    </Box>
  );
};

export default Precipitation;
