import { BaseChartProps, BaseChartSettings } from '../types';
import useCubeLTG from 'hooks/useCubeLTG';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import { Skeleton } from '@chakra-ui/react';
import { Layout, PlotData, PlotlyDataLayoutConfig } from 'plotly.js';
import { MPH_TO_MS } from '../WindRose/Chart';

type WindDatum = {
  'TessAtmos.avgWindDegrees': number;
  'TessAtmos.measuredAt': string;
  'TessAtmos.avgWindMph': number;
};

// Plotly.js does not support rotation of markers _yet_. It's implemented in python though.
// Some poor attempt at it doing annotations. Very far from complete.
// const createWindArrows = (data: WindDatum[]): Partial<Shape>[] => {
//   const first = data[0];
//   const x = Date.parse(first['TessAtmos.measuredAt']);
//   const y = first['TessAtmos.avgWindMph'] * MPH_TO_MS;

//   const shape: Partial<Shape> = {
//     type: 'path',
//     path: `M ${x} ${y} L ${x} ${y - 1} L ${add(x, { hours: 1 }).getTime()} ${y + 0.5} Z`,
//     fillcolor: 'rgba(44, 160, 101, 0.5)',
//     line: {
//       color: 'rgb(44, 160, 101)'
//     }
//   };

//   return [shape];
// };

const WindChart = ({
  dateRange = 'from 3 days ago until now',
  chartRange,
  granularity = 'hour',
  skip,
  settings: { project, site },
  onDataLoaded
}: BaseChartProps<BaseChartSettings>) => {
  const transform = (data: WindDatum[]) => data;

  const graph = (data: WindDatum[]): PlotlyDataLayoutConfig => {
    const plotData: Partial<PlotData>[] = [
      {
        mode: 'lines+markers',
        type: 'scattergl',
        name: 'Wind Speed and Direction, Last 3 days',
        x: data.map((datum) => datum['TessAtmos.measuredAt'].toString()),
        y: data.map((datum) => (datum['TessAtmos.avgWindMph'] * MPH_TO_MS).toString()),
        hovertemplate: `Wind Speed (m/s): <b>%{y:0.2f}</b><extra></extra>`
      }
    ];

    const layout: Partial<Layout> = {
      xaxis: {
        range: chartRange,
        title: {
          text: 'Hour'
        }
      },
      yaxis: {
        title: 'Wind Speed m/s'
      },
      autosize: true,
      legend: {
        orientation: 'h',
        x: 0,
        y: 1.25,
        traceorder: 'normal'
      },
      margin: {
        t: 25
      },
      hovermode: 'x unified'

      // shapes: createWindArrows(data)
    };

    return {
      data: plotData,
      layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: ['TessAtmos.avgWindMph', 'TessAtmos.avgWindDegrees'],
      timeDimensions: [
        {
          dimension: 'TessAtmos.measuredAt',
          granularity,
          dateRange
        }
      ],
      dimensions: [],
      filters: site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [site.smbId.toString()]
            }
          ]
        : [],
      timezone: project.timezone,
      order: { 'TessAtmos.measuredAt': 'desc' }
    },
    transform,
    graph,
    options: {
      skip,
      dependencies: { chartRange },
      onDataLoaded
    }
  });

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.length === 0 && project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : plot?.data?.length === 0 && !project.freeTrial ? (
    <NoData minH="450px" />
  ) : (
    <Plot className="w-100 atmos-wind-chart" useResizeHandler={true} {...plot} />
  );
};

export { WindChart };
