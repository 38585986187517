import { gql, useMutation } from '@apollo/client';
import { ArrowRightIcon, CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  useToast
} from '@chakra-ui/react';
import { BinaryFilter } from '@cubejs-client/core';
import { ProjectContext } from 'contexts/ProjectContext';
import { AlarmFrequencyUnit, AlarmThreshold, UnknownAlarmIntervalSetting } from 'graphql/generated';
import { isEmpty, uniq, upperCase } from 'lodash';
import { useContext, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { ToNotifyType } from './AlarmNotificationList';
import AlarmSummary from './AlarmSteps/AlarmSummary';
import ConfigureAlarm from './AlarmSteps/ConfigureAlarm';
import NotificationSettings from './AlarmSteps/NotificationSettings';
import SelectSite from './AlarmSteps/SelectSite';

const SAVE_ALARM_GROUP = gql`
  mutation SaveAlarmGroup(
    $projectId: Int!
    $alarmGroup: AlarmGroupInput!
    $siteAlarms: [SiteAlarmInput!]!
  ) {
    saveAlarmGroup(projectId: $projectId, alarmGroup: $alarmGroup, siteAlarms: $siteAlarms)
  }
`;

type EditAlarmModalProps = {
  editingAlarm?: EditAlarmForm;
  onClose: () => void;
};

const steps = [
  { title: 'Site', description: 'Site Selection' },
  { title: 'Alarm', description: 'Alarm Configuration' },
  { title: 'Notifications', description: 'Notification Settings' },
  { title: 'Verify', description: 'Verify Configuration' }
];

export type EditAlarmForm = {
  id?: number;
  notifyAll: ToNotifyType[];
  siteAlarms: {
    id?: number;
    site: { id: number; name: string; smbId: number; sublocations: string[] };
    notify: ToNotifyType[];
  }[];
  thresholds: (Omit<AlarmThreshold, 'alarmGroupId' | 'alarmGroup' | 'alarmStatusChecks'> & {
    additionalFilters?: BinaryFilter[];
  })[];
  name: string;
  description: string;
  frequency: {
    interval: number;
    unit: AlarmFrequencyUnit;
  };
  unknownIntervalSetting: UnknownAlarmIntervalSetting;
  chartSetId?: number;
};

const EditAlarmModal = ({ onClose, editingAlarm }: EditAlarmModalProps) => {
  const [saveAlarmGroup] = useMutation(SAVE_ALARM_GROUP, { refetchQueries: ['GetAlarmGroups'] });
  const [isSaving, setIsSaving] = useState(false);
  const toast = useToast();
  const project = useContext(ProjectContext);

  const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    index: 1,
    count: steps.length
  });

  const { control, getValues, handleSubmit } = useForm<EditAlarmForm>({
    defaultValues: editingAlarm ?? {
      siteAlarms: [],
      thresholds: [{ operator: 'gte', value: '0', additionalFilters: [] }],
      frequency: {
        interval: 3,
        unit: AlarmFrequencyUnit.Hours
      },
      name: '',
      description: '',
      unknownIntervalSetting: UnknownAlarmIntervalSetting.Continue
    }
  });

  const [id, siteAlarms, thresholds, name] = useWatch({
    control,
    name: ['id', 'siteAlarms', 'thresholds', 'name']
  });

  const stepIsComplete = () => {
    if (activeStep == 1) {
      return siteAlarms.length > 0;
    }

    if (activeStep == 2) {
      return thresholds.every((t) => t.measure && t.value);
    }

    if (activeStep == 3) {
      return (
        name.length > 0 &&
        siteAlarms.flatMap((sa) => sa.notify).every((toNotify) => !isEmpty(toNotify.value))
      );
    }

    if (activeStep == 4) {
      return name.length > 0;
    }

    return false;
  };

  const submitAlarms = async (form: EditAlarmForm) => {
    const alarmGroup = {
      id: form.id,
      name: form.name,
      description: form.description,
      unknownIntervalSetting: form.unknownIntervalSetting,
      frequency: { interval: form.frequency.interval, unit: form.frequency.unit },
      thresholds: form.thresholds.map((t) => ({
        id: t.id,
        value: t.value.toString(),
        measure: t.measure,
        timeDimension: t.timeDimension,
        operator: t.operator,
        filters: JSON.stringify(t.additionalFilters ?? [])
      })),
      chartSetId: form?.chartSetId
    };

    const alarmInputs = form.siteAlarms.map((site) => {
      return {
        id: site.id,
        siteId: site.site.id,
        notify: site.notify
          .filter((n) => !n.notificationListId)
          .map((notify) => ({ value: notify.value, type: upperCase(notify.type) })),
        contactListIds: uniq(site.notify.map((n) => n.notificationListId).filter((nl) => nl))
      }; //: CreateAlarmType
    });

    setIsSaving(true);
    try {
      const resp = await saveAlarmGroup({
        variables: { projectId: project.id, alarmGroup, siteAlarms: alarmInputs }
      });
      if (!resp.data.saveAlarmGroup) throw Error('Error creating alarms');
      toast({
        status: 'success',
        description: 'Saved Alarms'
      });
      onClose();
    } catch (e: any) {
      console.error(e);
      toast({
        status: 'error',
        description: 'Error saving alarms.'
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal size="6xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {id ? 'Edit Alarm' : 'New Alarm'}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Stepper index={activeStep - 1}>
            {steps.map((step, index) => (
              <Step key={index} onClick={() => id && setActiveStep(index + 1)}>
                <StepIndicator cursor={id && 'pointer'}>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription>{step.description}</StepDescription>
                </Box>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>

          {activeStep === 1 && <SelectSite control={control} editing={!!id} />}

          {activeStep === 2 && (
            <ConfigureAlarm sites={siteAlarms.map((s) => s.site)} control={control} />
          )}

          {activeStep === 3 && <NotificationSettings control={control} />}

          {activeStep === 4 && <AlarmSummary form={getValues()} />}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup w="100%" justifyContent="space-between">
            {activeStep == 1 ? <Spacer /> : <Button onClick={goToPrevious}>Previous</Button>}
            <Button
              data-cypress="alarm-next-step"
              isDisabled={!stepIsComplete()}
              isLoading={isSaving}
              rightIcon={activeStep === steps.length ? <CheckIcon /> : <ArrowRightIcon />}
              colorScheme="blue"
              onClick={activeStep === steps.length ? handleSubmit(submitAlarms) : goToNext}>
              {activeStep === steps.length ? 'Finish' : 'Next'}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditAlarmModal;
