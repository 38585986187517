import {
  Button,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import NewUserPane from './NewUserPane';
import { gql, useMutation } from '@apollo/client';
import { ProjectContext } from 'contexts/ProjectContext';
import { User } from '@scoot/permissions';

const CREATE_USER = gql`
  mutation CreateUser($email: String!, $projectId: Int!) {
    createUser(email: $email, projectId: $projectId) {
      error
      updated
      user {
        oryId
        name
        jobTitle
        avatarUrl
        email
        superuser
        creationTime
        projectAdministrator
        alerts
        asc
        manualHydrography
        read
        events
        equipment
        plankton
        seaLice
      }
    }
  }
`;

type NewUserFlowModalProps = {
  onClose: () => void;
  onContinue: (user: User) => void;
};

// thanks internet
const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

const NewUserFlowModal = ({ onClose, onContinue }: NewUserFlowModalProps) => {
  const [newUserEmail, setNewUserEmail] = useState<string>('');
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [createError, setCreateError] = useState<string>('');
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const project = useContext(ProjectContext);

  const [createUser] = useMutation(CREATE_USER);

  const onCreateUser = async () => {
    if (!isEmail(newUserEmail)) {
      setInvalidEmail(true);
      return;
    }

    setInvalidEmail(false);
    setIsCreating(true);
    const {
      data: {
        createUser: { error, user, updated }
      },
      errors
    } = await createUser({
      variables: { email: newUserEmail, projectId: project.id },
      refetchQueries: ['projectUsers']
    });
    setIsCreating(false);

    if (error || errors) {
      setCreateError(
        error ?? 'There was an error creating your SeaState user. Please contact SeaState support.'
      );
      setIsCreating(false);
    }

    if (updated) {
      onContinue(updated);
    }

    if (user) {
      onContinue(user);
    }
  };

  return (
    <Modal size="md" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New User</ModalHeader>
        <ModalBody>
          <FormControl isInvalid={invalidEmail || !!createError}>
            <NewUserPane disabled={isCreating} email={newUserEmail} setEmail={setNewUserEmail} />
            {invalidEmail && (
              <FormErrorMessage data-cypress="email-error" colorScheme="red">
                Please enter a valid email address
              </FormErrorMessage>
            )}
            {createError && <FormErrorMessage colorScheme="red">{createError}</FormErrorMessage>}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            data-cypress="create-user-submit"
            isLoading={isCreating}
            colorScheme="blue"
            onClick={onCreateUser}>
            {isCreating ? 'Please Wait...' : 'Create'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewUserFlowModal;
