import { Annotation } from '@cubejs-client/core';
import { cloneDeep } from 'lodash';
import { Layout, PlotlyDataLayoutConfig } from 'plotly.js';

const mergePlots = (
  coreLayout: Layout,
  plots: PlotlyDataLayoutConfig[],
  options?: {
    basePlot?: PlotlyDataLayoutConfig;
    createAnnotationsForPlots?: (plots: PlotlyDataLayoutConfig[]) => Annotation[];
  }
): PlotlyDataLayoutConfig => {
  const basePlots = [];
  if (options?.basePlot) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    plots.forEach((_p) => basePlots.push(cloneDeep(options.basePlot)));
  }

  const plotsWithBases: PlotlyDataLayoutConfig[] = [...basePlots, ...plots];

  const legendNames = [];

  plotsWithBases.forEach((p, idx) => {
    const plotIdx = idx + 1;

    p.data.forEach((d) => {
      d['yaxis'] = `y${plotIdx}`;
      d['showlegend'] = !legendNames.includes(d.name);
      if (!legendNames.includes(d.name)) legendNames.push(d.name);
    });

    const layoutAtY = p.layout ?? {};

    if (idx >= plots.length) {
      layoutAtY['overlaying'] = `y${plotIdx - plots.length}`;
    } else {
      layoutAtY['showgrid'] = false;
      layoutAtY['side'] = options.basePlot ? 'right' : 'left';
      layoutAtY['fixedrange'] = true;
    }

    coreLayout[`yaxis${plotIdx}`] = layoutAtY;
  });

  if (options?.createAnnotationsForPlots) {
    const annotations = options.createAnnotationsForPlots(plotsWithBases);
    const coreAnnotations = coreLayout.annotations ?? [];
    //@ts-ignore
    coreLayout.annotations = [...coreAnnotations, ...annotations];
  }
  return {
    data: plotsWithBases.flatMap((d) => d.data),
    layout: coreLayout
  };
};

export default mergePlots;
