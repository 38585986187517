import { Skeleton } from '@chakra-ui/react';
import GraphError from 'components/Charts/GraphError';
import NoData from 'components/Charts/NoData';
import NotIncluded from 'components/Charts/NotIncluded';
import Plot from 'components/Charts/Plot';
import { BaseChartProps, BaseChartSettings } from 'components/Charts/types';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, max, mean } from 'lodash';
import { Layout, PlotData } from 'plotly.js';

const granularityDisplayName = {
  hour: 'Hourly',
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly'
};

// Right now only using Davis sensors, which have ~7 degree accuracy
// https://www.fondriest.com/pdf/onset_davis_wind_manual.pdf
const THETA_RESOLUTION = 10;
export const MPH_TO_MS = 0.44704;

type WindDatum = {
  'TessAtmos.windDegrees': number;
  'TessAtmos.avgWindMph': number;
  'TessAtmos.maxWindMph': number;
  'Site.id': string;
  'TessAtmos.measuredAt': string;
};

type SimpleWind = {
  degrees: number;
  ms: number;
  maxMs: number;
};

type ChartSettings = BaseChartSettings & {
  showDateRange?: boolean;
};

const WindRose = ({
  dateRange = 'Last 3 days',
  skip,
  settings: { project, site, showDateRange = true },
  granularity = 'hour',
  onDataLoaded
}: BaseChartProps<ChartSettings>) => {
  const transform = (datum: WindDatum[]): SimpleWind[] => {
    const resolutionGroups = groupBy(
      datum,
      (d) => Math.round(d['TessAtmos.windDegrees'] / THETA_RESOLUTION) * THETA_RESOLUTION
    );

    return Object.entries(resolutionGroups).map(([degree, values]) => ({
      degrees: Number(degree),
      ms: mean(values.map((v) => v['TessAtmos.avgWindMph'])) * MPH_TO_MS,
      maxMs: max(values.map((v) => v['TessAtmos.maxWindMph'])) * MPH_TO_MS
    }));
  };

  const graph = (datum: SimpleWind[]) => {
    const granDisplay = granularityDisplayName[granularity];

    const data: Partial<PlotData>[] = datum.flatMap((d, i) => [
      {
        name: `${granDisplay} Wind Speed Max. (m/s)`,
        showlegend: i === 0,
        type: 'scatterpolargl',
        mode: 'lines',
        r: [0, d.maxMs, d.maxMs, 0],
        theta: [0, d.degrees - THETA_RESOLUTION / 2, d.degrees + THETA_RESOLUTION / 2, 0],
        fill: 'toself',
        fillcolor: '#D53F8C',
        line: {
          color: 'black'
        },
        hovertemplate: `%{theta}: <b>%{r:0.2f} (m/s)</b><extra></extra>`
      },
      {
        name: `${granDisplay} Wind Speed Avg. (m/s)`,
        showlegend: i === 0,
        type: 'scatterpolargl',
        mode: 'lines',
        r: [0, d.ms, d.ms, 0],
        theta: [0, d.degrees - THETA_RESOLUTION / 2, d.degrees + THETA_RESOLUTION / 2, 0],
        fill: 'toself',
        fillcolor: '#319795',
        line: {
          color: 'black'
        },
        hovertemplate: `%{theta}: <b>%{r:0.2f} (m/s)</b><extra></extra>`
      }
    ]);

    const layout: Partial<Layout> = {
      title: `${granDisplay} Wind Speed and Direction. ${showDateRange ? dateRange.toString() : ''}`,
      polar: {
        radialaxis: {
          range: [0, max(data.flatMap((d) => d.r))]
        },
        angularaxis: {
          rotation: 90,
          direction: 'clockwise'
        }
      },
      legend: {
        orientation: 'h',
        x: 0,
        y: 0,
        font: {
          size: 14
        }
      }
    };

    return {
      data,
      layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: ['TessAtmos.avgWindMph', 'TessAtmos.maxWindMph'],
      timeDimensions: [
        {
          dimension: 'TessAtmos.measuredAt',
          granularity,
          dateRange
        }
      ],
      dimensions: ['TessAtmos.windDegrees'],
      filters: site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [site.smbId.toString()]
            }
          ]
        : [],
      timezone: project.timezone
    },
    transform,
    graph,
    options: {
      skip,
      onDataLoaded
    }
  });

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.length === 0 && project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : plot?.data?.length === 0 && !project.freeTrial ? (
    <NoData minH="450px" />
  ) : (
    <Plot className="w-100 atmos-wind-rose" useResizeHandler={true} {...plot} />
  );
};

export { WindRose };
