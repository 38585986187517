import { gql, useMutation } from '@apollo/client';
import { DeleteIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseModalProps,
  VStack,
  HStack,
  Text,
  Button,
  Box,
  Alert,
  AlertIcon
} from '@chakra-ui/react';
import { useEditor } from '@tiptap/react';
import FancyEditor, { DEFAULT_EXTENSIONS } from 'components/Forms/FancyEditor';
import { format } from 'date-fns';
import { ProjectReport } from 'graphql/generated';
import { useState } from 'react';

const DELETE_PROJECT_REPORT = gql`
  mutation DeleteProjectReport($projectReportId: Int!) {
    deleteProjectReport(projectReportId: $projectReportId)
  }
`;

const Info = ({ name, value }: { name: string; value: string }) => {
  return (
    <VStack spacing="0" alignItems="start">
      <Text fontSize="sm" as="b" color="gray.500">
        {name}
      </Text>
      <Text>{value}</Text>
    </VStack>
  );
};

const ProjectReportModal = ({
  isOpen,
  onClose,
  report
}: UseModalProps & { report: Partial<ProjectReport> }) => {
  const [deleteProjectReport] = useMutation(DELETE_PROJECT_REPORT, {
    refetchQueries: ['GetProjectReports']
  });
  const [isVerifyingDelete, setDeleteVerification] = useState(false);

  const editor = useEditor({
    extensions: DEFAULT_EXTENSIONS,
    content: report.content,
    editable: false,
    autofocus: false
  });

  const verifyDelete = async () => {
    if (!isVerifyingDelete) {
      setDeleteVerification(true);
    } else {
      await deleteProjectReport({ variables: { projectReportId: report.id } });
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{report.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack w="100%" alignItems="start">
            <VStack w="20%" alignItems="start" justifyContent="start" spacing="5">
              <Info name="Type" value={report.type} />
              <Info name="Description" value={report.description} />
              <Info name="Created By" value={report.createdBy?.name} />
              <Info
                name="Published"
                value={report?.published ? format(new Date(report.published), 'PP') : 'Draft'}
              />

              <Button
                data-cypress="delete-report"
                colorScheme={isVerifyingDelete ? 'white' : 'red'}
                color={isVerifyingDelete ? 'red.500' : 'white'}
                borderColor={isVerifyingDelete && 'red.500'}
                border={isVerifyingDelete && '1px solid'}
                leftIcon={<DeleteIcon />}
                onClick={verifyDelete}>
                {isVerifyingDelete ? 'Confirm Delete?' : 'Delete'}
              </Button>
            </VStack>
            <Box w="100%">
              {!report.published && (
                <Alert status="info" variant="left-accent">
                  <AlertIcon mb="5px" />
                  This report is currently in the draft stage. It is not finalized and may change!
                </Alert>
              )}
              <FancyEditor editor={editor} />
            </Box>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProjectReportModal;
