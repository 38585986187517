import { HStack, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import AllLicePerFish from 'components/Charts/AllLicePerFish/Chart';
import ChartSwitchPanel from 'components/Charts/ChartSwitchPanel';
import ChartTile from 'components/Charts/ChartTile';
import MotileLicePerFish from 'components/Charts/MotileLicePerFish/Chart';
import { ProjectContextType } from 'contexts/ProjectContext';
import { Site } from 'graphql/generated';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link as ReactLink } from 'react-router-dom';
import Select from 'react-select';
import {
  LICE_STAGE_NAMES,
  LICE_STAGE_NAMES_PROJECT_ID,
  MOTILE_LICE_STAGE_IDS_PROJECT_ID
} from 'shared/Utils';

type SeaLiceDataProps = {
  site?: Site;
  project: ProjectContextType;
};

const SeaLiceData = ({ site, project }: SeaLiceDataProps) => {
  const [selectedSubloc, setSelectedSubloc] = useState('All');
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 899px)' });
  const [showWeeklyLiceAverage, setWeeklyLiceAverage] = useState(false);
  const MOTILE_LICE_STAGE_IDS_HERE = MOTILE_LICE_STAGE_IDS_PROJECT_ID[project.id];
  const LICE_STAGE_NAMES_PROJECT_ID_HERE = LICE_STAGE_NAMES_PROJECT_ID[project.id];
  const weeklyMotileLicePopover = (
    <>
      <Text fontWeight="bold">Motile Lice Stages:</Text>
      <UnorderedList textAlign="left">
        {MOTILE_LICE_STAGE_IDS_HERE?.map((ls, i) => {
          return <ListItem key={i}>{LICE_STAGE_NAMES[ls]}</ListItem>;
        })}
      </UnorderedList>
      <Text fontWeight="bold">For more info about SeaLice:</Text>
      <ReactLink
        style={{ textDecoration: 'underline', color: '#6495ED' }}
        target="_blank"
        to="/docs">
        Go to the FAQ
      </ReactLink>
    </>
  );

  const stagesLiceInfoPopover = (
    <>
      <Text fontWeight="bold">Lice Stages:</Text>
      <UnorderedList textAlign="left">
        {LICE_STAGE_NAMES_PROJECT_ID_HERE
          ? Object.values(LICE_STAGE_NAMES_PROJECT_ID_HERE)
              .sort()
              .map((ls: string) => {
                return <ListItem key={ls}>{ls}</ListItem>;
              })
          : []}
      </UnorderedList>
      <Text fontWeight="bold">For more info about SeaLice:</Text>
      <ReactLink
        style={{ textDecoration: 'underline', color: '#6495ED' }}
        target="_blank"
        to="/docs">
        Go to the FAQ
      </ReactLink>
    </>
  );

  return (
    <div data-cypress={'sea-lice-data'}>
      <div className="ma2 mr4" style={isTabletOrMobile ? { width: '850px' } : {}}>
        <ChartTile
          hash="motile-per-fish"
          heading="Motile Stages: Lice per Fish"
          site={site ?? null}
          tooltip={weeklyMotileLicePopover}
          control={
            <ChartSwitchPanel
              switches={[
                {
                  left: { value: 'daily-value', label: 'Daily Average' },
                  right: {
                    value: 'weekly-value',
                    label: 'Weekly Average'
                  },
                  toggle: ({ checked }) => setWeeklyLiceAverage(checked)
                }
              ]}
            />
          }>
          <MotileLicePerFish
            settings={{ project, site }}
            granularity={showWeeklyLiceAverage ? 'week' : 'day'}
            dateRange={
              showWeeklyLiceAverage
                ? 'from 90 days ago to 1 day from now'
                : 'from 30 days ago to 1 day from now'
            }
          />
        </ChartTile>
        <ChartTile
          heading="All Stages: Lice per Fish"
          site={site ?? null}
          tooltip={stagesLiceInfoPopover}>
          <AllLicePerFish
            settings={{ project, site, selectedSublocation: selectedSubloc }}
            control={({ availableSublocations }) => (
              <HStack alignItems="center" justifyContent="center">
                <Text>Sublocation: </Text>
                <Select
                  className="w-20 tl"
                  defaultValue={{ label: 'All', value: 'All' }}
                  onChange={(x) => setSelectedSubloc(x.value)}
                  options={[
                    { label: 'All', value: 'All' },
                    ...availableSublocations.map((s) => ({ label: s, value: s }))
                  ]}
                />
              </HStack>
            )}
          />
        </ChartTile>
      </div>
    </div>
  );
};

export default SeaLiceData;
