import {
  Alert,
  AlertIcon,
  FormControl,
  FormErrorMessage,
  Kbd,
  Spacer,
  Text
} from '@chakra-ui/react';
import Shade from 'components/Shade';
import { ReactElement } from 'react';
import DataGrid, { DataGridProps } from 'react-data-grid';
import { ControllerFieldState } from 'react-hook-form';
import './FormDataGrid.css';

type FormDataGridProps<TRow> = {
  fieldState: ControllerFieldState;
  isDisabled?: boolean;
  shadeContent?: ReactElement;
  alertContent?: ReactElement;
} & DataGridProps<TRow>;

const FormDataGrid = <TRow,>({
  fieldState,
  isDisabled,
  shadeContent,
  alertContent,
  ...dataGridProps
}: FormDataGridProps<TRow>) => {
  return (
    <FormControl isDisabled={isDisabled} isInvalid={fieldState.invalid}>
      {fieldState.invalid ? (
        <FormErrorMessage h="16px">{fieldState.error.message}</FormErrorMessage>
      ) : (
        <Spacer h="16px" />
      )}
      <Shade shaded={isDisabled} shadeContent={shadeContent}>
        <DataGrid
          className={fieldState.invalid ? 'entry-grid entry-grid--error' : 'entry-grid'}
          {...dataGridProps}
        />
      </Shade>
      <Alert status="info">
        <AlertIcon />
        <Text>
          {alertContent ? (
            alertContent
          ) : (
            <Text>
              Type directly in each cell to register data. Use of the arrow keys and <Kbd>Tab</Kbd>{' '}
              are supported for navigation.
            </Text>
          )}
        </Text>
      </Alert>
    </FormControl>
  );
};

export default FormDataGrid;
