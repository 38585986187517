import { Box, HStack, Icon, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { WiDaySunny, WiDaySunnyOvercast, WiDayCloudy, WiCloudy } from 'weather-icons-react';
import { WEATHER_PLACEHOLDER } from './Weather';

const percentToLabel = (cloudiness) => {
  if (cloudiness < 25) {
    //0-2 oktas
    return { icon: WiDaySunny, label: 'Sunny' };
  }
  if (cloudiness < 50) {
    //2-4 oktas
    return { icon: WiDaySunnyOvercast, label: 'Overcast' };
  }
  if (cloudiness < 75) {
    //4-6 oktas
    return { icon: WiDayCloudy, label: 'Cloudy' };
  }
  return { icon: WiCloudy, label: 'Cloudy' }; //6-8 oktas
};

const Cloudiness = ({ value }: { value: number | string }) => {
  const label = percentToLabel(value);
  return (
    <Box textAlign="center">
      <Tooltip label="Cloudiness (avg. next 24 hrs)">
        {value === WEATHER_PLACEHOLDER ? (
          <Text>{WEATHER_PLACEHOLDER}</Text>
        ) : (
          <HStack>
            <Icon as={label.icon} boxSize="40px" color="gray.900" />
            <Text>{label.label}</Text>
          </HStack>
        )}
      </Tooltip>
    </Box>
  );
};

export default Cloudiness;
