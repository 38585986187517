import { DateRange } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { EquipmentDatum } from 'components/Site/Tabs/Equipment/EquipmentEntry';
import { ProjectContext } from 'contexts/ProjectContext';
import { useContext } from 'react';

export const useCubeEquipment = ({
  smbSiteId,
  dateRange
}: {
  smbSiteId: number;
  dateRange: DateRange;
}) => {
  const project = useContext(ProjectContext);

  return useCubeQuery<EquipmentDatum>(
    {
      dimensions: [
        'TessEquipmentLookup.pk',
        'TessEquipmentLookup.equipmentSiteId',
        'TessEquipmentLookup.stateId',
        'TessEquipmentLookup.actionId',
        'TessEquipment.value',
        'TessEquipment.measuredAt',
        'TessEquipmentLookup.type'
      ],
      timeDimensions: [
        {
          granularity: 'second',
          dimension: 'TessEquipment.measuredAt',
          dateRange
        }
      ],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: [smbSiteId.toString()]
        }
      ],
      timezone: project.timezone,
      order: { 'TessEquipment.measuredAt': 'desc' }
    },
    { subscribe: true }
  );
};
