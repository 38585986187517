import { SessionContext } from 'contexts/SessionContext';
import { useContext } from 'react';

const Logout = () => {
  const session = useContext(SessionContext);
  window.location.replace(session?.logoutUrl);
  return <></>;
};

export default Logout;
