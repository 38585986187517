import { Table, Tbody, Td, Text, Thead, Tr } from '@chakra-ui/react';

const ImportExampleTable = ({
  headers,
  exampleData
}: {
  headers: string[];
  exampleData: string[];
}) => {
  return (
    <Table>
      <Thead>
        <Tr>
          {headers.map((h, i) => (
            <Td key={i}>{h}</Td>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          {exampleData.map((d, i) => (
            <Td key={i}>{d}</Td>
          ))}
        </Tr>
      </Tbody>
    </Table>
  );
};

const ImportDetails = () => {
  return (
    <>
      <Text fontSize="xl" margin="1em 0">
        manual-hydrography.csv
      </Text>
      <ImportExampleTable
        headers={[
          'site_id',
          'sublocation',
          'depth',
          'water_temp',
          'salinity',
          'oxygen_saturation',
          'visibility',
          'measured_at',
          'transaction_id'
        ]}
        exampleData={[
          '{site_id}',
          'house',
          '1',
          '13.5',
          '11',
          '114',
          '',
          '{now}',
          'seastate-superuser-import-{now}'
        ]}
      />
      <Text fontSize="xl" margin="1em 0">
        sensor-hydrography.csv
      </Text>
      <ImportExampleTable
        headers={[
          'site_id',
          'sublocation',
          'depth',
          'water_temp',
          'salinity',
          'oxygen_saturation',
          'measured_depth',
          'turbidity',
          'chlorophyll',
          'measured_at',
          'transaction_id'
        ]}
        exampleData={[
          '{site_id}',
          'cage-1',
          '-1',
          '13.5',
          '11',
          '114',
          '',
          '',
          '',
          '{now}',
          'seastate-superuser-import-{now}'
        ]}
      />
      <Text fontSize="xl" margin="1em 0">
        biology.csv
      </Text>
      <ImportExampleTable
        headers={[
          'avg_harvest_weight',
          'avg_weight',
          'calc_feed_amount',
          'end_biomass',
          'end_count',
          'feed_conversaion_ratio',
          'feed_use_weight',
          'growth_weight',
          'havest_count',
          'harvest_weight',
          'live_days',
          'mortality_count',
          'mortality_weight',
          'net_growth',
          'pen_density',
          'specific_feed_rate',
          'specific_growth_rate',
          'start_count',
          'start_biomass',
          'measured_at',
          'transaction_id',
          'site_id',
          'sublocation'
        ]}
        exampleData={[
          '1',
          '0.58841485',
          '1',
          '49024.37',
          '83316',
          '1.2045516',
          '431',
          '1',
          '0',
          '0',
          '1,97',
          '56.593773',
          '1',
          '1',
          '0.8823786',
          '0.732537',
          '83413',
          '48666.562',
          '{yesterday}',
          'seastate-superuser-import-{now}',
          '{siteId}',
          'cage-1'
        ]}
      />
      <Text fontSize="xl" margin="1em 0">
        lice.csv
      </Text>
      <ImportExampleTable
        headers={[
          'site_id',
          'sublocation',
          'stage_id',
          'number_of_fish',
          'lice_count',
          'measured_at',
          'transaction_id'
        ]}
        exampleData={[
          '{siteId}',
          'cage-1',
          '110',
          '20',
          '20',
          '{now}',
          'seastate-superuser-import-{now}'
        ]}
      />
      <Text fontSize="xl" margin="1em 0">
        plankton.csv
      </Text>
      <ImportExampleTable
        headers={[
          'site_id',
          'sublocation',
          'depth',
          'species',
          'method',
          'cell_count',
          'measured_at',
          'transaction_id'
        ]}
        exampleData={[
          '{siteId}',
          'house',
          '1',
          'alexandrium-sp',
          'discrete',
          '100',
          '{now}',
          'seastate-superuser-import-{now}'
        ]}
      />
      <Text fontSize="xl" margin="1em 0">
        siwi.csv
      </Text>
      <ImportExampleTable
        headers={[
          'siwi_total',
          'stocking_weight',
          'stocking_score',
          'stocking_value',
          'stocking_units',
          'stocking_missing',
          'appetite_weight',
          'appetite_score',
          'appetite_value',
          'appetite_units',
          'appetite_missing',
          'mortality_weight',
          'mortality_score',
          'mortality_value',
          'mortality_units',
          'mortality_missing',
          'oxygen_weight',
          'oxygen_score',
          'oxygen_value',
          'oxygen_units',
          'oxygen_missing',
          'salinity_weight',
          'salinity_score',
          'salinity_value',
          'salinity_units',
          'salinity_missing',
          'temperature_weight',
          'temperature_score',
          'temperature_value',
          'temperature_units',
          'temperature_missing',
          'lice_weight',
          'lice_score',
          'lice_value',
          'lice_units',
          'lice_missing',
          'disturbance_weight',
          'disturbance_score',
          'disturbance_value',
          'disturbance_units',
          'disturbance_missing',
          'plankton_mechanical_weight',
          'plankton_mechanical_score',
          'plankton_mechanical_value',
          'plankton_mechanical_units',
          'plankton_mechanical_missing',
          'plankton_toxic_weight',
          'plankton_toxic_score',
          'plankton_toxic_value',
          'plankton_toxic_units',
          'plankton_toxic_missing',
          'measured_at',
          'stocking_ko',
          'appetite_ko',
          'mortality_ko',
          'oxygen_ko',
          'salinity_ko',
          'temperature_ko',
          'lice_ko',
          'disturbance_ko',
          'plankton_mechanical_ko',
          'plankton_toxic_ko',
          'siwi_env',
          'siwi_fish',
          'siwi_indv',
          'cohort_id',
          'status_code',
          'stocking_day',
          'transaction_id',
          'site_id'
        ]}
        exampleData={[
          '0.792',
          '0.06',
          '100',
          '12.69500462962963',
          'mean_pen_density_kg/m3',
          'f',
          '0.09',
          '50',
          '1.6929559285714285e-14',
          'daily_sfr',
          'f',
          '0.17',
          '75',
          '0.00213679500812584',
          'mean_mortality_percent',
          'f',
          '0.14',
          '100,84.6742616688332',
          'oxygen_sat_percent',
          'f',
          '0.02',
          '100',
          '28.25',
          'salinity_ppt',
          'f',
          '0.13',
          '75',
          '8.225',
          'temperature_c',
          'f',
          '0.09',
          '100',
          '0',
          'lice_per_cm2',
          't',
          '0.09',
          '100',
          'NaN',
          'level',
          't',
          '0.09',
          '0',
          'pot_lethal',
          'level',
          'f',
          '0.12',
          '100',
          'safe',
          'level',
          't',
          '{now}',
          'f',
          'f',
          'f',
          'f',
          'f',
          'f',
          'f',
          'f',
          'f',
          'f',
          '0.815',
          '0.664',
          '1',
          '77-2020-2.0',
          'indicator_data_missing',
          '532',
          'seastate-superuser-import-{now}',
          '{siteId}'
        ]}
      />
    </>
  );
};

export default ImportDetails;
