import { Box, Checkbox, Flex, Text, VStack } from '@chakra-ui/react';
import { BinaryFilter } from '@cubejs-client/core';
import BaseSelect from 'components/Forms/BaseSelect';
import PlanktonSelect from 'components/Site/Tabs/Plankton/Entry/PlanktonSelect';
import { ProjectContext } from 'contexts/ProjectContext';
import { PlanktonPolicy } from 'graphql/generated';
import { sortBy, startCase, toLower } from 'lodash';
import { useContext, useMemo } from 'react';
import { MortalityByCauseSelect } from './MortalityByCauseSelect';
import useRiverStations from './RiverStations';

export type AdvancedFilterType = {
  planktonSpecies?: PlanktonPolicy;
  depth?: number;
  sublocation?: string;
  seaLice?: string;
  excludeBarge?: boolean;
  discreteSample?: boolean;
  riverStation?: { value: string; label: string };
  mortalityCause?: string;
};

type AdvancedFilterProps = {
  measure: string;
  filters?: BinaryFilter[];
  sublocations?: string[];
  onFieldChange: (filters: BinaryFilter[]) => void;
};

const depthToNum = (depth: string) => Number(depth.replace(/[^0-9]/g, ''));

// JH: I am not proud of any of this...
const AdvancedFilter = ({ measure, filters, sublocations, onFieldChange }: AdvancedFilterProps) => {
  const project = useContext(ProjectContext);

  const filterType = useMemo(() => {
    const lower = measure?.toLowerCase();
    if (lower?.includes('plankton')) return 'plankton';
    if (lower?.includes('lice')) return 'seaLice';
    if (lower?.includes('hydrography')) return 'hydrography';
    if (lower?.includes('rivers')) return 'rivers';
    if (lower?.includes('tessmortality')) return 'mortalityByCause';

    return 'default';
  }, [measure]);

  const riverStations = useRiverStations(project.name.replaceAll(' ', '_'));

  const advancedFilter: AdvancedFilterType = useMemo(() => {
    const filter: AdvancedFilterType = {};
    filters.map((f) => {
      const lowered = f.member.toLowerCase();
      if (lowered.includes('plankton')) {
        if (lowered.includes('species')) {
          filter['planktonSpecies'] = project.planktonPolicy.find((pp) => {
            return (pp?.generic?.key ?? pp.species.key).includes(f.values[0]);
          });
        } else {
          filter['discreteSample'] = true;
        }
      }
      if (lowered.includes('depth')) {
        filter['depth'] = Number(f.values[0]);
      }
      if (lowered.includes('lice')) {
        filter['seaLice'] = f.values[0];
      }
      if (lowered.includes('riverstationname')) {
        filter['riverStation'] = f.values.map((d) => {
          return { value: d, label: startCase(toLower(d.replaceAll('_', ' '))) };
        })[0];
      }
      if (lowered.includes('sublocation') && f.operator === 'equals') {
        filter['sublocation'] = f.values[0];
      }
      if (
        lowered.includes('sublocation') &&
        f.operator === 'notContains' &&
        f.values
          .map((v) => v.toLowerCase())
          .some((v) => v.includes('barge') || v.includes('house') || v.includes('station'))
      ) {
        filter['excludeBarge'] = true;
      }
    });
    return filter;
  }, [filters]);

  const depths = useMemo(() => {
    if (filterType === 'plankton') {
      const depthStrings = project.dataEntry.plankton.columns['Farm'].map((o) => o.value);
      return depthStrings.map(depthToNum);
    }

    if (filterType === 'hydrography') {
      const depthStrings = project.dataEntry.hydrography.columns['Pilot'].map((o) => o.value);
      return depthStrings.map(depthToNum);
    }

    return [];
  }, [project, filterType]);

  const onFilterChange = ({
    depth,
    sublocation,
    planktonSpecies,
    discreteSample,
    excludeBarge,
    riverStation,
    mortalityCause
  }: AdvancedFilterType) => {
    if (!measure) return;

    const filters: BinaryFilter[] = [];

    if (depth) {
      // I hate everything. Hackily guess the dimension name...
      const depthDimension = `${measure.split('_')[0]}Lookup_depth`;
      filters.push({
        member: depthDimension,
        operator: 'equals',
        values: [depth.toString()]
      });
    }

    if (sublocation) {
      const sublocationDimension = `${measure.split('_')[0]}Lookup_sublocation`;

      const subValue = project.sublocationToTesseractSublocation(sublocation);

      filters.push({
        member: sublocationDimension,
        operator: 'equals',
        values: [subValue]
      });
    }

    if (planktonSpecies) {
      // I hate everything. Convert from display name to magic key
      const speciesDimension = `${measure.split('_')[0]}Lookup_species`;

      filters.push({
        member: speciesDimension,
        operator: 'equals',
        values: [planktonSpecies?.generic?.key ?? planktonSpecies?.species?.key]
      });
    }

    if (discreteSample) {
      const methodDimension = `${measure.split('_')[0]}Lookup_method`;
      filters.push({
        member: methodDimension,
        operator: 'equals',
        values: ['discrete']
      });
    }

    if (excludeBarge) {
      const sublocationDimension = `${measure.split('_')[0]}Lookup_sublocation`;
      filters.push({
        member: sublocationDimension,
        operator: 'notContains',
        values: ['barge', 'house', 'station']
      });
    }

    if (riverStation) {
      filters.push({
        member: 'TessRivers.riverStationName',
        operator: 'equals',
        values: [riverStation.value]
      });
    }

    if (mortalityCause) {
      filters.push({
        member: 'TessMortalityLookup.mortalityCauseName',
        operator: 'equals',
        values: [mortalityCause]
      });
    }

    onFieldChange(filters);
  };

  return (
    <Flex pt="10px" gap="20px" pb="10px" w="100%" alignItems="start" justifyContent="start">
      {filterType === 'mortalityByCause' && (
        <MortalityByCauseSelect
          value={advancedFilter?.mortalityCause}
          onChange={(cause) => onFilterChange({ ...advancedFilter, mortalityCause: cause })}
        />
      )}
      {filterType === 'plankton' && (
        <>
          <VStack w="250px" alignItems="start" spacing={0}>
            <Text display="inline-block">Plankton</Text>
            <Box w="100%">
              <PlanktonSelect
                value={{
                  key:
                    advancedFilter.planktonSpecies.generic?.key ??
                    advancedFilter.planktonSpecies.species?.key,
                  name:
                    advancedFilter?.planktonSpecies?.generic?.name ??
                    advancedFilter?.planktonSpecies?.species?.species
                }}
                species={project.planktonPolicy.map((pp) => ({
                  key: pp?.species?.key ?? pp?.generic?.key,
                  name: pp?.species?.species ?? pp?.generic?.name
                }))}
                onChange={(species) => {
                  onFilterChange({
                    ...advancedFilter,
                    planktonSpecies: project.planktonPolicy.find(
                      (pp) => pp.generic?.key === species.key || pp.species?.key === species.key
                    )
                  });
                }}
                cellStyle={false}
              />
            </Box>
          </VStack>
          <VStack spacing={0}>
            <Text display="inline-block">Discrete Samples Only</Text>
            <Checkbox
              size="lg"
              isChecked={advancedFilter?.discreteSample ?? false}
              onChange={(e) =>
                onFilterChange({ ...advancedFilter, discreteSample: e.currentTarget.checked })
              }
            />
          </VStack>
        </>
      )}

      {filterType === 'seaLice' && project.dataEntry.seaLice && (
        <BaseSelect
          value={project.dataEntry.seaLice.fishLiceStages.find(
            (fls) => fls.value === advancedFilter?.seaLice
          )}
          placeholder="(Optional) Lice Stage..."
          isClearable={true}
          options={project.dataEntry.seaLice.fishLiceStages}
          onChange={(species) => onFilterChange({ ...advancedFilter, seaLice: species?.value })}
        />
      )}
      {filterType === 'rivers' && (
        <BaseSelect
          w="250px"
          placeholder="All Nearby River Gauges"
          label="River Gauge"
          isClearable={false}
          value={advancedFilter?.riverStation}
          options={riverStations}
          maxMenuHeight={130}
          minMenuHeight={100}
          onChange={(riverStation) => {
            onFilterChange({
              ...advancedFilter,
              riverStation: riverStation
            });
          }}
        />
      )}
      {depths && depths.length > 0 && (
        <BaseSelect
          w="250px"
          placeholder="(Optional) Depth (m)..."
          label="Depth"
          isClearable={true}
          value={depths
            .map((d) => ({ label: d.toString(), value: d.toString() }))
            .find((d) => d.value === advancedFilter?.depth?.toString())}
          options={depths.map((d) => ({ label: d.toString(), value: d.toString() }))}
          onChange={(depth) => {
            onFilterChange({
              ...advancedFilter,
              depth: depth?.value ? Number(depth?.value) : null
            });
          }}
        />
      )}

      {measure?.toLowerCase().includes('sensor') && (
        <VStack spacing={0}>
          <Text display="inline-block">Exclude Barge Sensors</Text>
          <Checkbox
            size="lg"
            isChecked={advancedFilter?.excludeBarge ?? false}
            onChange={(e) =>
              onFilterChange({ ...advancedFilter, excludeBarge: e.currentTarget.checked })
            }
          />
        </VStack>
      )}

      {sublocations && filterType != 'rivers' && sublocations.length > 0 && (
        <BaseSelect
          data-cypress="alarm-filter-sublocation"
          w="300px"
          isClearable={true}
          placeholder="(Optional) Sublocation..."
          label="Sublocation"
          value={sublocations
            .map((s) => ({ label: s, value: project.sublocationToTesseractSublocation(s) }))
            .find((so) => so.value === advancedFilter?.sublocation)}
          options={sortBy(
            sublocations.map((s) => ({
              label: s,
              value: project.sublocationToTesseractSublocation(s)
            })),
            ({ value }) => Number(value.replace(/[^0-9]/g, ''))
          )}
          onChange={(option) => onFilterChange({ ...advancedFilter, sublocation: option?.value })}
        />
      )}
    </Flex>
  );
};

export default AdvancedFilter;
