import { UserContext } from 'contexts/UserContext';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

const IndexRedirect = () => {
  const user = useContext(UserContext);

  if (!user) {
    return <Navigate to="/login" />;
  } else {
    return <Navigate to="/project" />;
  }
};

export default IndexRedirect;
