import config from 'config';

const url = config.api.URL;

const apiClient = {
  post: (path: string, data: any) => {
    return fetch(`${url}/${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(data)
    });
  },
  get: (path: string) => {
    return fetch(`${url}/${path}`, {
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
  }
};

export default apiClient;
