import { Filter } from '@cubejs-client/core';
import { ProjectContext } from 'contexts/ProjectContext';
import { closestTo, lightFormat } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import useCubeLTG from 'hooks/useCubeLTG';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { useContext } from 'react';
import { BaseChartProps, BaseChartSettings } from '../types';

type TessTideCubeDatum = {
  'TessTides.waterlevelAvg': number;
  'TessTides.diiAvg': number;
  'TessTides.liAvg': number;
  'TessTides.tideStationId': string;
  'TessTides.tideStationLat': number;
  'TessTides.tideStationLon': number;
  'TessTides.measuredAt': string;
  'TessTides.measuredAt.minute': string;
  'TessTides.measuredAt.hour': string;
};

type ForecastedTideStructure = {
  waterLevel: number;
  stationId: string;
  stationLat: number;
  stationLon: number;
  measuredAt: string;
}[];

export type UseTideTraceParams = BaseChartSettings & {
  showNow?: boolean;
  showAxisTitle?: boolean;
  tideStationId?: string;
  searchRadius?: { label: string; value: string };
  lunarParameter?: { label: string; value: string };
};

const useTideTrace = ({
  skip,
  dateRange,
  chartRange,
  onDataLoaded,
  settings
}: BaseChartProps<UseTideTraceParams>) => {
  const nowMarker = settings?.showNow ?? false;
  const axisTitle = settings?.showAxisTitle ?? false;
  const timeDimension = settings.site?.smbId
    ? 'TessTides.measuredAt.minute'
    : 'TessTides.measuredAt.hour';
  const transform = (data: TessTideCubeDatum[]): ForecastedTideStructure => {
    return data.map((d) => ({
      waterLevel: Number(d[settings?.lunarParameter?.value ?? 'TessTides.waterlevelAvg']),
      stationId: d['TessTides.tideStationId'],
      stationLat: d['TessTides.tideStationLat'],
      stationLon: d['TessTides.tideStationLon'],
      measuredAt: d[timeDimension]
    }))
  };

  const graph = (data: ForecastedTideStructure): PlotlyDataLayoutConfig => {
    let plotData: Data = {
      //@ts-ignore
      type: 'spline',
      x: data.map((d) => d.measuredAt),
      y: data.map((d) => d.waterLevel),
      name: 'Forecasted',
      fill: 'tozeroy',
      fillcolor: 'rgba(132, 255, 249, 0.5)',
      mode: 'none',
      hovertemplate:
        '<b>Forecasted Tide: %{y:.1f} m</b></br>' +
        `<b>Time: %{x} ${projectContext.timezoneLabel}</b><br>` +
        '<extra></extra>'
    };

    if (nowMarker) {
      const closest = closestTo(
        utcToZonedTime(
          zonedTimeToUtc(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone),
          projectContext.timezone
        ),
        data.map((d) => new Date(d.measuredAt))
      );

      if (closest) {
        const projectNow = lightFormat(closest, `yyyy-MM-dd'T'HH:mm:00.000`);

        //@ts-ignore
        plotData = [
          plotData,
          {
            type: 'scatter',
            x: [projectNow],
            y: [data.find((d) => d.measuredAt === projectNow)['waterLevel']],
            name: 'Now',
            hoverinfo: 'skip',
            marker: { size: 12 }
          }
        ];
      }
    }
    const layout = {
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: 1.1
      },
      margin: { t: 40, b: 40, l: 60, r: 60 },
      yaxis: axisTitle
        ? {
            title: settings?.lunarParameter?.label,
            side: 'left'
          }
        : {},
      xaxis: {
        range: chartRange
      },
      annotations: [
        {
          xref: 'paper',
          yref: 'paper',
          x: 1,
          borderpad: 45,
          xanchor: 'right',
          y: 1.2,
          yanchor: 'center',
          textangle: 0,
          text: `Tide Station: ${data?.[0]?.stationId} (${data?.[0]?.stationLat} / ${data?.[0]?.stationLon})`,
          font: { size: 12 },
          showarrow: false
        }
      ]
    };
    return {
      data: Array.isArray(plotData) ? plotData : [plotData],
      //@ts-ignore
      layout: layout
    };
  };
  const projectContext = useContext(ProjectContext);
  const queryFilter: Filter[] = [
    {
      member: 'TessTides.filterRadius',
      operator: 'equals',
      values: [settings?.searchRadius?.value ?? '5000']
    }
  ];

  if (settings?.site?.lat) {
    queryFilter.push({
      member: 'TessTides.fromLat',
      operator: 'equals',
      values: [settings.site.lat.toString()]
    });
  }

  if (settings?.site?.lon) {
    queryFilter.push({
      member: 'TessTides.fromLon',
      operator: 'equals',
      values: [settings.site.lon.toString()]
    });
  }

  if (settings?.site?.tideStations?.include?.length > 0) {
    queryFilter.push({
      member: 'TessTides.includeStationList',
      operator: 'equals',
      values: settings.site.tideStations.include
    });
  } else {
    queryFilter.push({
      member: 'TessTides.includeStationList',
      operator: 'equals',
      values: ['NULL']
    });
  }

  if (settings?.site?.tideStations?.exclude?.length > 0) {
    queryFilter.push({
      member: 'TessTides.excludeStationList',
      operator: 'equals',
      values: settings.site.tideStations.exclude
    });
  }

  return useCubeLTG({
    cubeQuery: {
      measures: ['TessTides.waterlevelAvg', 'TessTides.diiAvg', 'TessTides.liAvg'],
      dimensions: [
        'TessTides.tideStationId',
        'TessTides.tideStationLon',
        'TessTides.tideStationLat'
      ],
      timeDimensions: [
        {
          dimension: 'TessTides.measuredAt',
          granularity: settings.site?.smbId ? 'minute' : 'hour',
          dateRange
        }
      ],
      filters: queryFilter,
      order: {'TessTides.measuredAt': 'asc'},
      timezone: projectContext.timezone
    },
    transform,
    graph,
    options: {
      refreshInterval: 900000,
      skip,
      onDataLoaded,
      dependencies: [settings]
    }
  });
};

export default useTideTrace;
