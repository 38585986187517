import FormDataGrid from 'components/Forms/DataGrid/FormDataGrid';
import GridNumberEditor from 'components/Forms/DataGrid/GridNumberEditor';
import { useMemo } from 'react';
import { Column } from 'react-data-grid';
import { useController, UseControllerProps } from 'react-hook-form';
import { Form } from './EntryForm';

type EntryGridProps = {
  liceColumns: { label: string; value: string }[];
} & UseControllerProps<Form, 'fishCounts'>;

export type SeaLiceRow = {
  fishIndex: number;
  [seaLice: string]: string | number;
};

const getColumns = (
  liceColumns: { label: string; value: string }[]
): readonly Column<SeaLiceRow>[] => {
  return [
    {
      key: 'fishIndex',
      name: 'Fish',
      width: 50,
      editable: false
    },
    ...liceColumns.map<Column<SeaLiceRow>>((labelValue) => ({
      key: labelValue.value,
      name: labelValue.label,
      width: 250,
      editor: GridNumberEditor
    }))
  ];
};

const EntryGrid = ({ liceColumns, ...props }: EntryGridProps) => {
  const { field, fieldState } = useController(props);
  const columns = useMemo(() => getColumns(liceColumns), [liceColumns]);

  return (
    <FormDataGrid
      fieldState={fieldState}
      columns={columns}
      rows={field.value}
      onRowsChange={(rows) => field.onChange(rows)}
    />
  );
};

export default EntryGrid;
