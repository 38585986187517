import FancyEditor from 'components/Forms/FancyEditor';
import { ReportingEditContext } from './ReportingEditContext';
import { useContext } from 'react';

const SeaStateReportBuilder = () => {
  const { editor } = useContext(ReportingEditContext);

  return <FancyEditor editor={editor} />;
};

export default SeaStateReportBuilder;
