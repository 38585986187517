// @ts-strict
import { THRESHOLD_LEVEL } from 'components/types';

function getThresholdStatusIcon(
  colorBy: string,
  siteType: string,
  siteShape: string,
  thresholdLevels: Record<string, THRESHOLD_LEVEL> = {},
  fishCount?: number | undefined,
  loading?: boolean
) {
  if (loading) {
    return `white_${siteShape}`;
  }
  if (fishCount === 0 && siteType == 'Farm') {
    return `grey_${siteShape}`;
  }
  const highestAlertLevel = Object.keys(thresholdLevels).reduce((prevLevel, currentThreshold) => {
    if (thresholdLevels[currentThreshold] > prevLevel) {
      return thresholdLevels[currentThreshold];
    }
    return prevLevel;
  }, THRESHOLD_LEVEL.NO_DATA);
  const activeLevel = colorBy === 'all' ? highestAlertLevel : thresholdLevels[colorBy];
  switch (activeLevel) {
    case THRESHOLD_LEVEL.SAFE:
      return `green_${siteShape}`;
    case THRESHOLD_LEVEL.CAUTION:
      return `orange_${siteShape}`;
    case THRESHOLD_LEVEL.DANGER:
      return `red_${siteShape}`;
    case THRESHOLD_LEVEL.NO_DATA:
    default:
      return `white_${siteShape}`;
  }
}

function getSiteStatusIcon(
  siteType: string,
  archived: boolean,
  colorBy: string,
  thresholdLevels?: Record<string, THRESHOLD_LEVEL>,
  fishCount?: number | undefined,
  loading?: boolean
): string {
  const siteShapes = {
    ASC: 'square',
    Pilot: 'star',
    Sensor: 'triangle',
    'Seed Area': 'triangle',
    default: 'circle'
  };
  const siteShape: string = siteShapes[siteType] ?? siteShapes['default'];
  if (archived) {
    return `black_${siteShape}`;
  }

  switch (siteType) {
    case 'ASC':
      return `blue_square`;
    case 'Pilot':
      return `brown_star`;
    case 'Seed Area':
      if (loading) {
        return `white_${siteShape}`;
      }
      // Seed sites are only colored by plankton
      //   If another threshold is selected, render default.
      switch (colorBy) {
        case 'all':
        case 'plankton':
          if (!thresholdLevels) {
            return `white_${siteShape}`;
          }
          switch (thresholdLevels.plankton) {
            case THRESHOLD_LEVEL.SAFE:
              return `green_${siteShape}`;
            case THRESHOLD_LEVEL.CAUTION:
              return `orange_${siteShape}`;
            case THRESHOLD_LEVEL.DANGER:
              return `red_${siteShape}`;
            case THRESHOLD_LEVEL.NO_DATA:
            default:
              return `white_${siteShape}`;
          }
        default:
          return `white_${siteShape}`;
      }
    case 'Farm':
      return getThresholdStatusIcon(
        colorBy,
        siteType,
        siteShape,
        thresholdLevels,
        fishCount,
        loading
      );
    case 'Sensor':
      return getThresholdStatusIcon(
        colorBy,
        siteType,
        siteShape,
        thresholdLevels,
        fishCount,
        loading
      );
    default:
      return 'brown_star';
  }
}

export default getSiteStatusIcon;
