export type HiResHydrographyCubeDatum = {
  'TessSensorHydrography.sublocation'?: string;
  'Site.id'?: string;
  'TessSensorHydrography.depth': number;
  'TessSensorHydrography.measuredAt': string;
  'TessSensorHydrography.waterTempAvg': number;
  'TessSensorHydrography.oxygenSaturationAvg': number;
  'TessSensorHydrography.oxygenConcentrationAvg': number;
  'TessSensorHydrography.measuredDepthAvg': number;
  'TessSensorHydrography.salinityAvg': number;
};

export const cubeDimensionToPlotName = {
  oxygenSaturationAvg: 'oxygenSat',
  oxygenConcentrationAvg: 'oxygenConcentration',
  waterTempAvg: 'waterTemp',
  measuredDepthAvg: 'depth',
  salinityAvg: 'salinity'
};
