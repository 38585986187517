import { gql, useQuery } from '@apollo/client';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Code,
  Input,
  SimpleGrid,
  Skeleton,
  Text,
  VStack,
  useCheckboxGroup,
  useToast
} from '@chakra-ui/react';
import BaseSelect from 'components/Forms/BaseSelect';
import CheckboxCard from 'components/Forms/CheckboxCard';
import ImportDetails from 'components/Tables/ImportDetails';
import config from 'config';
import { UserContext } from 'contexts/UserContext';
import { useContext, useEffect, useState } from 'react';
import { redirect } from 'react-router-dom';

const hostUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : config.api.URL;

const GET_DEMO_SITES = gql`
  query GetDemoSites {
    sites(projectId: 8) {
      id
      name
    }
  }
`;

type Scenario = {
  id: number;
  name: string;
};

const DataImport = () => {
  const user = useContext(UserContext);
  const { data, loading } = useQuery(GET_DEMO_SITES);
  const [selectedScenarios, setSelectedScenarios] = useState<Scenario[]>([]);
  const [verifyingImport, setVerifyingImport] = useState<boolean>(false);
  const [importingSite, setImportingSite] = useState<{ id: number; name: string }>();
  const [scenarios, setScenarios] = useState<Scenario[]>(null);
  const [scenariosLoading, setScenariosLoading] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [importingFile, setImportingFile] = useState<File>();
  const toast = useToast();

  if (!user.superuser) redirect('/not-found');

  const { getCheckboxProps } = useCheckboxGroup({
    value: selectedScenarios.map((s) => s.name),
    onChange: (inputScenarios) => {
      setVerifyingImport(false);
      setSelectedScenarios(scenarios.filter((sc) => inputScenarios.includes(sc.name)));
    }
  });

  useEffect(() => {
    fetch(`${hostUrl}/import/scenarios`, { method: 'GET', credentials: 'include' })
      .then((resp) => {
        resp.json().then((scs) => {
          setScenarios(scs);
          setScenariosLoading(false);
        });
      })
      .catch((e) => {
        toast({
          status: 'error',
          description: `Error fetching scenarios ${e}`
        });
      });
  }, []);

  const doImport = async () => {
    setIsImporting(true);

    let resp = null;
    if (importingFile) {
      const formData = new FormData();
      formData.append('scenario', importingFile);

      resp = await fetch(`${hostUrl}/import/${importingSite.id}`, {
        method: 'POST',
        credentials: 'include',
        body: formData
      });
    } else {
      resp = await fetch(
        `${hostUrl}/import/${importingSite.id}?scenarioIds=${selectedScenarios
          .map((s) => s.id)
          .join(',')}`,
        { method: 'POST', credentials: 'include' }
      );
    }

    if (resp.ok) {
      toast({
        status: 'success',
        description: `Scenarios imported successfully.`
      });
    } else {
      toast({
        status: 'error',
        description: `Scenarios could not be imported, or may have partially imported.`
      });
    }

    setIsImporting(false);
    setSelectedScenarios([]);
    setVerifyingImport(false);
  };

  return (
    <Box w="100%" padding="0 2rem">
      <Center>
        <Text fontSize="4xl" margin="1em 0">
          Data Import
        </Text>
      </Center>
      <Center>
        <Text fontSize="2xl" margin="1em 0">
          Scenarios
        </Text>
      </Center>
      <SimpleGrid columns={4} spacing="5">
        {scenariosLoading ? (
          <Skeleton w="100%" minH="500px" />
        ) : (
          scenarios?.map((s) => {
            const checkbox = getCheckboxProps({ value: s.name });
            return (
              <CheckboxCard key={s.id} {...checkbox}>
                <Text display="inline-block" data-cypress={`select-scenario-${s.id}`}>
                  {s.name}
                </Text>
              </CheckboxCard>
            );
          })
        )}
      </SimpleGrid>
      <VStack spacing={5} mt="20px" mb="20px">
        <Box w="300px">
          <BaseSelect
            isLoading={loading}
            value={{ label: importingSite?.name ?? '', value: importingSite }}
            options={data?.sites.map((s) => ({ label: s.name, value: s })) ?? []}
            onChange={(option: { value: { id: number; name: string } }) => {
              setVerifyingImport(false);
              setImportingSite(option.value);
            }}
            label="Apply to Site"
          />
        </Box>
        <Text fontSize="2xl" margin="1em 0">
          Zip Import
        </Text>
        <Text maxW="600px">
          To use zip import, create <Code>.csv</Code> files with the names matching{' '}
          <b>exactly as appears in the examples with the same schema *including headers</b>. Throw
          them all into a <Code>.zip</Code> file (file name does not matter) and use the uploader
          below. Data is included in the examples below just to demonstrate practical data.
        </Text>
        <Text maxW="600px">
          &#123;siteId&#125; will be substituted for the selected site id from the dropdown below.
          &#123;now&#125; will be substituted for the current time. &#123;yesterday&#125; will be
          substituted for &#123;now - 1 day&#125;.
        </Text>
        <Accordion allowToggle={true} w="100%" mt="10px">
          <AccordionItem>
            <AccordionButton fontSize="xl">CSV Details</AccordionButton>
            <AccordionPanel>
              <ImportDetails />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Input
          maxW="400px"
          placeholder="Scenario zip..."
          type="file"
          onChange={(e) => setImportingFile(e.currentTarget.files?.[0])}
        />
        <Button
          mt="10px"
          onClick={verifyingImport ? doImport : () => setVerifyingImport(true)}
          isDisabled={
            (selectedScenarios.length === 0 && importingFile === null) ||
            !importingSite ||
            isImporting
          }
          colorScheme={verifyingImport ? 'red' : 'blue'}>
          {verifyingImport
            ? 'Are you sure!? This may import and merge weird with other data!'
            : 'Import!'}
        </Button>
      </VStack>
    </Box>
  );
};

export default DataImport;
