import { TimeDimensionGranularity } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { ProjectContextType } from 'contexts/ProjectContext';
import { groupBy, uniq } from 'lodash';
import { Layout, LayoutAxis, PlotData } from 'plotly.js';
import { useCallback, useEffect, useState } from 'react';
import { forecastParamOptions } from 'shared/Config';
import { getResultSetsDateRange } from '../getResultSetsDateRange';
import { BaseChartProps } from '../types';
import { ChartSettings } from './Chart';

export type ParameterOptions = (
  | { label: 'Air Temperature'; value: 'airTempAvg' }
  | { label: 'Cloud Cover'; value: 'cloudCoverAvg' }
  | { label: 'Easterly Wind Speed'; value: 'windEastAvg' }
  | { label: 'Northerly Wind Speed'; value: 'windNorthAvg' }
  | { label: 'Total Precipitation'; value: 'totalPrecipAvg' }
  | { label: 'Sea Surface Pressure'; value: 'seaSurfacePressureAvg' }
  | { label: 'Relative Humidity'; value: 'relativeHumidityAvg' }
  | { label: 'Incoming Long-wave Radiation'; value: 'downwardLongRadiationAvg' }
  | { label: 'Incoming Short-wave Radiation'; value: 'downwardShortRadiationAvg' }
  | { label: 'Upwelling Index'; value: 'upwellingIndexAvg' }
)[];

type ForecastHydrographyCubeDatum = {
  'TessForecastAtmos.airTempAvg': number;
  'TessForecastAtmos.cloudCoverAvg': number;
  'TessForecastAtmos.salinityAvg': number;
  'TessForecastAtmos.windEastAvg': number;
  'TessForecastAtmos.windNorthAvg': number;
  'TessForecastAtmos.totalPrecipAvg': number;
  'TessForecastAtmos.seaSurfacePressureAvg': number;
  'TessForecastAtmos.relativeHumidityAvg': number;
  'TessForecastAtmos.downwardLongRadiationAvg': number;
  'TessForecastAtmos.downwardShortRadiationAvg': number;
  'TessForecastAtmos.upwellingIndexAvg': number;
  'TessForecastAtmosLookup.ensemble': number;
  'TessForecastAtmos.measuredAt': string;
  'TessForecastAtmos.measuredAt.hour': string;
};

type HydrographyStructure = {
  [ensemble: string]: {
    measuredAt: string[];
    airTempAvg: string[];
    cloudCoverAvg: string[];
    windEastAvg: string[];
    windNorthAvg: string[];
    totalPrecipAvg: string[];
    seaSurfacePressureAvg: string[];
    relativeHumidityAvg: string[];
    downwardLongRadiationAvg: string[];
    downwardShortRadiationAvg: string[];
    upwellingIndexAvg: string[];
  };
};

const transform = (
  data: ForecastHydrographyCubeDatum[],
  cube: 'TessForecastAtmos',
  granularity: TimeDimensionGranularity,
  parameters: ParameterOptions
): HydrographyStructure => {
  const byEnsemble = groupBy(data, (d) => d['TessForecastAtmosLookup.ensemble']);
  const byMeasuredAt = groupBy(data, (d) => d['TessForecastAtmos.measuredAt']);
  const tsData = Object.entries(byEnsemble).reduce((acc, [ens, ensData]) => {
    const measured = Object.values(ensData).map((d) => d['TessForecastAtmos.measuredAt']);
    const ensTimeseries = {
      measuredAt: measured
    };
    Object.values(parameters).map((d) => {
      ensTimeseries[d.value] = Object.values(ensData).map((dd) =>
        d.value == 'airTempAvg'
          ? dd[`TessForecastAtmos.${d.value}`] - 273.15
          : d.value == 'seaSurfacePressureAvg'
            ? dd[`TessForecastAtmos.${d.value}`] / 100
            : dd[`TessForecastAtmos.${d.value}`]
      );
    });
    acc[ens] = ensTimeseries;
    return acc;
  }, {});
  // Average across the ensemble
  tsData[-1] = {}; // use -1 to mark ensemble average
  Object.values(parameters).map((d) => {
    const ensAverage = Object.entries(byMeasuredAt).reduce((acc, [time, timeData]) => {
      // timeData has all ensembles, so must avg. the param
      const paramSum = Object.values(timeData).reduce(
        (sumAcc, dd) => sumAcc + dd[`TessForecastAtmos.${d.value}`],
        0
      );
      let paramAvg = paramSum / Object.values(timeData).length;
      paramAvg =
        d.value == 'airTempAvg'
          ? paramAvg - 273.15
          : d.value == 'seaSurfacePressureAvg'
            ? paramAvg / 100
            : paramAvg;
      acc[time] = paramAvg;
      return acc;
    }, {});
    tsData[-1][d.value] = Object.values(ensAverage);
  }, {});
  tsData[-1]['measuredAt'] = Object.keys(byMeasuredAt);
  return tsData;
};

const createLinePlots = (
  data: HydrographyStructure,
  project: ProjectContextType,
  options: {
    plotIdxOffset: number;
    xaxis: string;
    colorbarX?: number;
    parameters: { value: string; label: string }[];
  }
) => {
  const layouts: Partial<LayoutAxis>[] = [];
  const plotData: Partial<PlotData>[] = Object.values(options.parameters).flatMap(
    (measurement, midx) => {
      layouts.push({
        title: { text: measurement.label, font: { size: 12 } }
      });
      return Object.entries(data ?? {}).map(([ensemble, ensembleData]) => {
        const cubeMeasurement = `${measurement.value}`;
        const measurementConfig = forecastParamOptions[cubeMeasurement];
        const plot: Partial<PlotData> = {
          name: ensemble === '-1' ? 'average' : ensemble,
          x: ensembleData.measuredAt,
          y: ensembleData[measurement.value],
          mode: 'lines+markers',
          hovertemplate:
            `<b>${measurement.value} ensemble ${ensemble === '-1' ? 'average' : ensemble}</br>` +
            `<b>%{y:.2f} ${measurementConfig?.units} </b></br>` +
            `<b>Time: %{x} ${project.timezone} </b><br>` +
            '<extra></extra>',
          showscale: true,
          yaxis: `y${midx + 1}`,
          xaxis: options.xaxis,
          legendgroup: `y${midx + 1}`,
          line: {
            color: ensemble === '-1' ? 'black' : null,
            width: ensemble === '-1' ? 3 : 1.5
          },
          marker: {
            color: ensemble === '-1' ? 'black' : null,
            size: ensemble === '-1' ? 2 : 1
          }
        };

        return plot;
      });
    }
  );
  return {
    plotData,
    layouts
  };
};

const useAtmosPlot = ({
  granularity = 'hour',
  chartRange,
  skip,
  refreshInterval,
  settings,
  onDataLoaded,
  setChartSettings
}: BaseChartProps<ChartSettings> & {
  setChartSettings: (chartSettings: ChartSettings) => void;
}) => {
  const parameterValues = settings.parameters.map((d) => d.value);
  const useForecasting = settings.project.forecasting;
  const graph = useCallback(
    (forecastData: HydrographyStructure) => {
      let layouts: Partial<LayoutAxis>[] = [];
      const plotIdxOffset = 0;

      const forecastedPlots = createLinePlots(forecastData, settings.project, {
        plotIdxOffset,
        xaxis: 'x1',
        parameters: settings.parameters
      });
      layouts = layouts.concat(forecastedPlots.layouts);
      const hasVisibility = 'buoyancyFreqAvg' in parameterValues;

      const yAxisCount = settings.parameters.length + (hasVisibility ? 1 : 0);
      const layout: Partial<Layout> = {
        grid: {
          rows: yAxisCount,
          columns: 1
        },
        height: (settings.parameters.length + (hasVisibility ? 1 : 0)) * 200 + 200,
        margin: { t: 10, b: 60, l: 50, r: 20 },
        hovermode: 'closest',
        autosize: true,
        xaxis: {
          showspikes: true,
          spikemode: 'across',
          spikecolor: 'black',
          hoverformat: '%Y-%m-%d %H:00',
          domain: [0, 1],
          title: {
            font: {
              size: 18
            }
          }
        },
        showlegend: false,
        legend: {
          orientation: 'v',
          x: 1,
          y: 0.09
        }
      };
      layouts.forEach((l, i) => {
        layout[`yaxis${i + 1}`] = l;
      });

      const data = forecastedPlots.plotData;

      return {
        data,
        layout
      };
    },
    [chartRange, settings.selectedDepth, settings.parameters]
  );
  const variableOptions = [
    { label: 'Air Temperature', value: 'airTempAvg' },
    { label: 'Cloud Cover', value: 'cloudCoverAvg' },
    { label: 'Easterly Wind Speed', value: 'windEastAvg' },
    { label: 'Northerly Wind Speed', value: 'windNorthAvg' },
    { label: 'Total Precipitation', value: 'totalPrecipAvg' },
    { label: 'Sea Surface Pressure', value: 'seaSurfacePressureAvg' },
    { label: 'Relative Humidity', value: 'relativeHumidityAvg' },
    { label: 'Incoming Long-wave Radiation', value: 'downwardLongRadiationAvg' },
    { label: 'Incoming Short-wave Radiation', value: 'downwardShortRadiationAvg' },
    { label: 'Upwelling Index', value: 'upwellingIndexAvg' }
  ];
  const queryMeasuresSmall = variableOptions.map((param) => {
    return `TessForecastAtmos.${param.value}`;
  });
  const {
    isLoading: forecastLoading,
    error: forecastError,
    resultSet: forecastResult,
    refetch: forecastRefetch
  } = useCubeQuery<ForecastHydrographyCubeDatum>(
    {
      measures: queryMeasuresSmall,
      timeDimensions: [
        {
          dimension: 'TessForecastAtmos.measuredAt',
          granularity: 'hour',
          dateRange: settings.dateRange
        }
      ],
      dimensions: ['TessForecastAtmos.measuredAt', 'TessForecastAtmosLookup.ensemble'],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: [settings.site.smbId.toString()]
        }
      ],
      timezone: settings.project.timezone,
      limit: 50000
    },
    { skip: skip }
  );
  const [plot, setPlot] = useState(null);

  const start = chartRange?.[0].toISOString() ?? '';
  const end = chartRange?.[1].toISOString() ?? '';

  useEffect(() => {
    if (settings.project.forecasting && (forecastLoading || !forecastResult)) return;
    const forecast = forecastResult?.rawData() ?? [];
    if (onDataLoaded) {
      onDataLoaded(
        [forecastResult],
        getResultSetsDateRange(forecastResult, settings.project.timezone)
      );
    }

    const transformedForecasts = transform(forecast, 'TessForecastAtmos', 'hour', [
      ...settings.parameters
    ]);
    const availableDepths =
      uniq(forecast.map((d) => d['TessForecastAtmosLookup.depth'])).sort((a, b) => a - b) ?? [];

    setChartSettings({
      ...settings,
      availableDepths
    });
    setPlot(graph(transformedForecasts));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    forecastLoading,
    forecastError,
    forecastResult,
    settings.project,
    useForecasting,
    granularity,
    settings.parameters,
    settings.selectedDepth,
    start,
    end
    // Causing endless render loop for some reason
    // onDataLoaded,
    // graph
  ]);

  useEffect(() => {
    if (refreshInterval) {
      const interval = setInterval(() => {
        forecastRefetch();
        // settings?.useSensor;
      }, refreshInterval);

      return () => clearInterval(interval);
    }
  }, [refreshInterval, forecastRefetch]);

  const hasData = settings.parameters.length > 0;

  return {
    isLoading: forecastLoading,
    error: forecastError,
    hasData,
    plot
  };
};

export default useAtmosPlot;
