import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer,
  Text
} from '@chakra-ui/react';
import apiClient from 'ApiClient';
import FrontPage from 'components/Pages/FrontPage';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

const StartRecovery = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm();

  const [resetResult, setResetResult] = useState(false);

  const onSubmit = async ({ email }) => {
    await apiClient.post('reset-password', { email });
    setResetResult(true);
  };

  return (
    <FrontPage>
      <Center>
        <Text fontSize="4xl">Account Recovery</Text>
      </Center>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormLabel htmlFor="email">Email</FormLabel>
        <FormControl isInvalid={!!errors?.code}>
          <Input
            id="email"
            placeholder="Email"
            type="email"
            {...register('email', { required: 'Please enter an email address' })}
          />
          {errors?.email?.message ? (
            <FormErrorMessage>{errors.code.message}</FormErrorMessage>
          ) : (
            <Spacer h="25px" />
          )}
        </FormControl>
        <Button
          disabled={resetResult}
          isLoading={isSubmitting}
          w="100%"
          mt="25px"
          colorScheme="blue"
          type="submit">
          Submit
        </Button>
        {resetResult && (
          <Center textAlign="center" mt="15px">
            <Text color="green.600">
              An email has been sent to your recovery email. Please follow the instructions sent.
            </Text>
          </Center>
        )}
      </form>
    </FrontPage>
  );
};

export default StartRecovery;
