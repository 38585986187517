import { useController, UseControllerProps } from 'react-hook-form';
import TimeFragmentPicker, { TimeFragment, TimeFragmentPickerProps } from './TimeFragmentPicker';

type FormTimeFragmentPickerProps<TForm extends { timeFragment: TimeFragment }> =
  UseControllerProps<TForm> & TimeFragmentPickerProps;

const FormTimeFragmentPicker = <TForm extends { timeFragment: TimeFragment }>(
  props: FormTimeFragmentPickerProps<TForm>
) => {
  const { field, fieldState } = useController(props);

  return (
    <TimeFragmentPicker
      includeNoGrouping={false}
      timeFragment={field.value as TimeFragment}
      {...field}
      {...fieldState}
      {...props}
      onChange={(timeFragment) => field.onChange(timeFragment)}
    />
  );
};

export default FormTimeFragmentPicker;
