import { useCubeQuery } from '@cubejs-client/react';
import { result, startCase, toLower } from 'lodash';
import { useMemo } from 'react';

const useRiverStations = (region: string): { value: string; label: string }[] => {
  // Needs a region like "British_Columbia"
  // Returns all available riverStationName values for that region in TessRivers
  const { resultSet } = useCubeQuery(
    {
      measures: [],
      dimensions: ['TessRivers.riverStationName'],
      filters: [
        {
          member: 'TessRivers.riverStationRegion',
          operator: 'equals',
          values: [region]
        }
      ]
    },
    {
      skip: false,
      subscribe: false
    }
  );
  const riverStations = useMemo(() => {
    if (!result) return [];
    return resultSet?.rawData().map((d) => {
      return {
        value: d['TessRivers.riverStationName']?.toString(),
        // Need to lodash this to human readable
        // label: d['TessRivers.riverStationName']?.toString().replaceAll('_', ' ')
        label: startCase(toLower(d['TessRivers.riverStationName']?.toString().replaceAll('_', ' ')))
      };
    });
  }, [resultSet]);

  return riverStations;
};

export default useRiverStations;
