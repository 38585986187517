import {
  Box,
  Button,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure
} from '@chakra-ui/react';
import ChartTile from 'components/Charts/ChartTile';
import {
  default as ForecastAtmosDetail,
  ChartSettings as ForecastAtmosDetailOptions
} from 'components/Charts/ForecastAtmosDetail/Chart';
import ForecastAtmosDetailController from 'components/Charts/ForecastAtmosDetail/Controller';
import {
  default as ForecastHydrography,
  ChartSettings as ForecastHydrographyOptions
} from 'components/Charts/ForecastHydrography/Chart';
import ForecastHydrographyController from 'components/Charts/ForecastHydrography/Controller';
import {
  default as ForecastHydrographyDetail,
  ChartSettings as ForecastHydrographyDetailOptions
} from 'components/Charts/ForecastHydrographyDetail/Chart';
import ForecastHydrographyDetailController from 'components/Charts/ForecastHydrographyDetail/Controller';
import FAQModal from 'components/FAQ/FAQModal';
import { ForecastFAQ } from 'components/FAQ/ForecastFAQ';
import { Site } from 'graphql/generated';
import { useState } from 'react';
// import InfoPopover from 'components/InfoPopover';
import Tile from 'components/Tile';
import { ProjectContextType } from 'contexts/ProjectContext';

type ForecastProps = {
  site: Site;
  project: ProjectContextType;
};

const Forecast = ({ site, project }: ForecastProps) => {
  // const [sliderValue, setSliderValue] = useState(50);
  const [hydrographyOptions, setHydrographyOptions] = useState<ForecastHydrographyOptions>({
    site,
    project,
    useLocalMinMax: false,
    showParameterSelect: false,
    dateRange: 'from now to 10 days from now',
    useSensor: false,
    parameters: [
      { label: 'Water Temperature', value: 'waterTempAvg' },
      { label: 'Oxygen Saturation', value: 'oxygenSaturationAvg' },
      { label: 'Salinity', value: 'salinityAvg' }
    ],
    aggregation: 50
  });
  const [hydrographyDetailOptions, setHydrographDetailyOptions] =
    useState<ForecastHydrographyDetailOptions>({
      site,
      project,
      useLocalMinMax: false,
      showParameterSelect: false,
      dateRange: 'from 1 day ago to 10 days from now',
      useSensor: false,
      parameters: [
        { label: 'Water Temperature', value: 'waterTempAvg' },
        { label: 'Oxygen Saturation', value: 'oxygenSaturationAvg' },
        { label: 'Salinity', value: 'salinityAvg' }
      ],
      selectedDepth: undefined,
      availableDepths: [-2]
    });
  const [atmosDetailOptions, setAtmosDetailOptions] = useState<ForecastAtmosDetailOptions>({
    site,
    project,
    useLocalMinMax: false,
    showParameterSelect: false,
    dateRange: 'from 1 day ago to 10 days from now',
    useSensor: false,
    parameters: [
      { label: 'Air Temperature', value: 'airTempAvg' },
      { label: 'Cloud Cover', value: 'cloudCoverAvg' },
      // { label: 'Easterly Wind Speed', value: 'windEastAvg' },
      // { label: 'Northerly Wind Speed', value: 'windNorthAvg' },
      { label: 'Total Precipitation', value: 'totalPrecipAvg' },
      { label: 'Sea Surface Pressure', value: 'seaSurfacePressureAvg' },
      // { label: 'Relative Humidity', value: 'relativeHumidityAvg' },
      // { label: 'Incoming Long-wave Radiation', value: 'downwardLongRadiationAvg' },
      // { label: 'Incoming Short-wave Radiation', value: 'downwardShortRadiationAvg' },
      { label: 'Upwelling Index', value: 'upwellingIndexAvg' }
    ]
  });
  const [faqIdx, setFaqIdx] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openModal = (idx = 0) => {
    setFaqIdx(idx);
    onOpen();
  };

  const leanMoreButton = (idx = 0, fontSize = 14) => (
    <Button variant="link" onClick={() => openModal(idx)} fontSize={fontSize} colorScheme="blue">
      Learn more...
    </Button>
  );
  return (
    <>
      <Box textAlign="center">
        <FAQModal data={ForecastFAQ} isOpen={isOpen} onClose={onClose} idx={faqIdx} />
        <HStack justifyContent="center">
          <Box className="w-70">
            <Text fontSize="md" mt="15px" mb="10px" textAlign="center">
              Site-specific ocean and atmospheric forecasts and climatologies.&nbsp;
              {leanMoreButton(0, 16)}
            </Text>
          </Box>
        </HStack>
        <Tile mb="1rem" p="1rem" textAlign="center" id="overview">
          <ChartTile
            heading="Atmospheric Forecast Ensembles"
            hash="atmos-forecast-detail"
            site={site}
            isNew={true}
            tooltip={
              <>
                <UnorderedList>
                  <ListItem key={'first'}>
                    {
                      'Data: Global Ensemble Forecast System (GEFS) atmospheric forecasts showing variation of simulated ensembles for the site coordinates.'
                    }
                  </ListItem>
                  <ListItem key={'firsty'}>
                    {
                      'Forecast Ensembles are individual model runs simulating variaion in the forces driving forecasts. Together, ensembles represent uncertainty around forecast simulations for the given time period while the ensemble average is presented as the concensus forecast. There are 31 ensembles represented in each forecast.'
                    }
                  </ListItem>
                  ;<ListItem key={'second'}>{'Color: Forecast Ensemble'}</ListItem>;
                </UnorderedList>
                <Text fontWeight="bold"></Text>
                <Text fontWeight="bold">For more info:</Text>
                {leanMoreButton(0, 16)}
              </>
            }
            control={
              <ForecastAtmosDetailController
                chartSettings={atmosDetailOptions}
                setChartSettings={setAtmosDetailOptions}
              />
            }>
            <ForecastAtmosDetail
              skip={false}
              settings={atmosDetailOptions}
              setChartSettings={setAtmosDetailOptions}
            />
          </ChartTile>
          <ChartTile
            heading="Ocean Forecast: Water Column"
            hash="ocean-foreacst-summary-barge"
            site={site}
            isNew={true}
            tooltip={
              <>
                <UnorderedList>
                  <ListItem key={'first-of'}>
                    {
                      'Data: Scoot site-specific ocean forecast at a given ensemble percentile. For example, Avg. is the average forecast across 31 simulated ensembles, 75% is the 75th-percentile of the ensembles, and Max is the maximum value across the ensembles.'
                    }
                  </ListItem>
                  <ListItem key={'firsty-of'}>
                    {
                      'Forecast Ensembles are individual model runs simulating variaion in the forces driving forecasts. Together, ensembles represent uncertainty around forecast simulations for the given time period while the ensemble average is presented as the concensus forecast. There are 31 ensembles represented in each forecast. Ocean forecasts derive ensemble variation from the atmospheric forcing variables (GEFS ensembles).'
                    }
                  </ListItem>
                  ;
                  <ListItem key={'second-of'}>
                    {'Color: Value of forecasted variable at a given depth and time.'}
                  </ListItem>
                  ;
                </UnorderedList>
                <Text fontWeight="bold"></Text>
                <Text fontWeight="bold">For more info:</Text>
                {leanMoreButton(0, 16)}
              </>
            }
            control={
              <ForecastHydrographyController
                chartSettings={hydrographyOptions}
                setChartSettings={setHydrographyOptions}
              />
            }>
            <ForecastHydrography skip={false} settings={hydrographyOptions} />
          </ChartTile>
          <ChartTile
            heading="Ocean Forecast Ensembles by Depth Layer"
            hash="ocean-foreacst-summary-barge-detail"
            site={site}
            isNew={true}
            tooltip={
              <>
                <UnorderedList>
                  <ListItem key={'first-ofd'}>
                    {
                      'Data: Scoot site-specific ocean forecast at a given depth showing variation of given  ensembles.'
                    }
                  </ListItem>
                  <ListItem key={'firsty-ofd'}>
                    {
                      'Forecast Ensembles are individual model runs simulating variaion in the forces driving forecasts. Together, ensembles represent uncertainty around forecast simulations for the given time period while the ensemble average is presented as the concensus forecast. There are 31 ensembles represented in each forecast. Ocean forecasts derive ensemble variation from the atmospheric forcing variables (GEFS ensembles).'
                    }
                  </ListItem>
                  ;<ListItem key={'second-ofd'}>{'Color: Forecast Ensemble'}</ListItem>;
                </UnorderedList>
                <Text fontWeight="bold"></Text>
                <Text fontWeight="bold">For more info:</Text>
                {leanMoreButton(0, 16)}
              </>
            }
            control={
              <ForecastHydrographyDetailController
                chartSettings={hydrographyDetailOptions}
                setChartSettings={setHydrographDetailyOptions}
              />
            }>
            <ForecastHydrographyDetail
              skip={false}
              settings={hydrographyDetailOptions}
              setChartSettings={setHydrographDetailyOptions}
            />
          </ChartTile>
        </Tile>
      </Box>
    </>
  );
};
export default Forecast;
