import { gql, useLazyQuery } from '@apollo/client';
import { debounce } from 'lodash';
import { useMemo, useRef } from 'react';
import { UseControllerProps } from 'react-hook-form';
import FormSearchableInput from './FormSearchableInput';

const GET_PROJECT_USERS = gql`
  query ProjectUsersByName($projectId: Int!, $name: String!) {
    project(id: $projectId) {
      users(search: $name) {
        oryId
        email
        name
      }
    }
  }
`;

type SearchableUserInputProps = {
  projectId: number;
  label?: string;
  emailsOnly?: boolean;
} & UseControllerProps<any>;

const SearchableUserInput = ({
  projectId,
  label = 'Reporter',
  emailsOnly = false,
  ...controllerProps
}: SearchableUserInputProps) => {
  const [getProjectUsers, { data, loading }] = useLazyQuery(GET_PROJECT_USERS);

  const debounceUserInputChange = useRef(
    debounce((name) => {
      if (name) getProjectUsers({ variables: { projectId, name } });
    }, 300)
  );

  const userOptions = useMemo(() => {
    const users = data?.project?.users;
    if (users) {
      return users.map((u) => ({ label: emailsOnly ? u.email : u.name ?? u.email, value: u }));
    }
  }, [data, emailsOnly]);

  return (
    <FormSearchableInput
      searchPlaceholder={'Search for a name or email...'}
      label={label}
      loading={loading}
      options={userOptions}
      rules={{ required: true, minLength: 1 }}
      onImmediateChange={(e) => {
        debounceUserInputChange.current(e.target.value);
      }}
      {...controllerProps}
    />
  );
};

export default SearchableUserInput;
