import { Skeleton } from '@chakra-ui/react';
import { Query } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { ProjectContext } from 'contexts/ProjectContext';
import { max, min } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useContext, useEffect } from 'react';
import GraphError from '../GraphError';
import NoData from '../NoData';
import { BaseChartProps, BaseChartSettings } from '../types';
import Renderer, { ChartRendererProps } from './Renderer';

export type ChartSettings = BaseChartSettings & {
  query: Query;
  aggregate: boolean;
  chartType: 'line' | 'table' | 'bar' | 'area' | 'scatter';
  displayName: string;
  fillZeros?: boolean;
  showLegend?: boolean;
  beforePlot?: ChartRendererProps['beforePlot'];
  xaxisTitle?: string;
};

const Chart = ({
  granularity,
  dateRange,
  settings: { query, chartType, fillZeros = false, showLegend, beforePlot, xaxisTitle },
  onDataLoaded
}: BaseChartProps<ChartSettings>) => {
  const project = useContext(ProjectContext);

  const { resultSet, isLoading, error } = useCubeQuery(
    {
      ...query,
      timeDimensions: query?.timeDimensions?.map((td) => {
        return {
          ...td,
          granularity: granularity ?? td.granularity,
          dateRange: dateRange ?? td.dateRange
        };
      })
    } ?? []
  );

  useEffect(() => {
    if (!isLoading && resultSet) {
      if (onDataLoaded) {
        const minMaxDates = resultSet.query().timeDimensions?.map((td) => td.dateRange);

        // Date() constructor converts time to local browser timezone. Convert back out to utc.
        // Conversion needed in first place for min and max functionality...
        const minDate = zonedTimeToUtc(
          min(minMaxDates.map((d) => new Date(d[0]))),
          Intl.DateTimeFormat().resolvedOptions().timeZone
        );
        const maxDate = zonedTimeToUtc(
          max(minMaxDates.map((d) => new Date(d[1]))),
          Intl.DateTimeFormat().resolvedOptions().timeZone
        );

        onDataLoaded(
          [resultSet],
          [
            // Convert utc to project time.
            utcToZonedTime(minDate, project.timezone).toISOString(),
            utcToZonedTime(maxDate, project.timezone).toISOString()
          ]
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, resultSet]);

  return isLoading ? (
    <Skeleton h="400px" w="100%" />
  ) : resultSet?.rawData()?.length === 0 ? (
    <NoData minH="400px" />
  ) : resultSet ? (
    <Renderer
      resultSet={resultSet}
      chartType={chartType}
      fillZeros={fillZeros}
      showLegend={showLegend}
      xaxisTitle={xaxisTitle}
      beforePlot={beforePlot}
    />
  ) : error ? (
    <GraphError minH="400px" />
  ) : (
    <></>
  );
};

export default Chart;
