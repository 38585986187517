// @ts-strict
// import { Container } from '@chakra-ui/react';
// import DashboardMap from 'components/Map/DashboardMap';
// import { useRef } from 'react';
import { projectTabs } from 'components/Project/tabs';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
// import { Site } from 'graphql/generated';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

const ProjectOverviewId = () => {
  const project = useContext(ProjectContext);
  const user = useContext(UserContext);

  const firstTab = projectTabs(project, user)[0];
  const paneId = firstTab?.panes?.[0].id;

  let tabUrl = `/project/${project.id}/overview/${firstTab.id}`;
  tabUrl = paneId ? `${tabUrl}/${paneId}` : tabUrl;

  return <Navigate to={tabUrl} replace={true} />;
};

export default ProjectOverviewId;
