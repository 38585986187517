import { gql } from '@apollo/client';

const SUBMIT_DEVICE_VALUES = gql`
  mutation submitDeviceValues($tableName: String!, $values: String!, $action: String!) {
    submitDeviceValues(tableName: $tableName, values: $values, action: $action)
  }
`;

const GET_S3_PRESIGNED_URL = gql`
  mutation getS3PresignedURL(
    $name: String!
    $contentType: String
    $operation: String!
    $bucket: String
  ) {
    getS3PresignedURL(
      name: $name
      contentType: $contentType
      operation: $operation
      bucket: $bucket
    )
  }
`;

export { SUBMIT_DEVICE_VALUES, GET_S3_PRESIGNED_URL };
