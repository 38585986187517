import { gql, useQuery } from '@apollo/client';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal';
import { Center, Flex, Select, Skeleton, Text, VStack } from '@chakra-ui/react';
import AlarmChart from 'components/Charts/AlarmStatusChecks/Chart';
// import FormInput from 'components/Forms/FormInput';
import { format, sub } from 'date-fns';
import { NotificationEvent, SiteAlarm } from 'graphql/generated';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MostRecentNotificationTable } from './MostRecentNotificationTable';

const GET_CHECKS = gql`
  query GetChecks($id: Int!, $siteId: Int!, $startTime: Date!, $endTime: Date!) {
    siteAlarm(id: $id) {
      id
      site {
        name
      }
      pastNotifications(startTime: $startTime, endTime: $endTime) {
        notifiedAt
        receipts {
          destination
          type
          status
        }
      }
      alarmGroup {
        id
        name
        checkRuns(startTime: $startTime, endTime: $endTime, siteId: $siteId) {
          id
          state
          checkedAt
        }
      }
    }
  }
`;

type ViewAlarmModalProps = {
  alarm: SiteAlarm;
  onClose: () => void;
};

const ViewAlarmModal = ({ alarm, onClose }: ViewAlarmModalProps) => {
  const { watch } = useForm({
    defaultValues: {
      startTime: sub(new Date(), { weeks: 1 }),
      endTime: new Date()
    }
  });

  const [selectedNotification, setSelectedNotification] = useState<string | null>();
  const [startTime, endTime] = watch(['startTime', 'endTime']);

  const { data, loading, refetch } = useQuery(GET_CHECKS, {
    variables: {
      id: alarm.id,
      siteId: alarm.site.id,
      startTime: new Date(startTime),
      endTime: new Date(endTime)
    }
  });

  useEffect(() => {
    refetch();
  }, [startTime, endTime]);

  const checks = data?.siteAlarm?.alarmGroup.checkRuns ?? [];

  const notificationHistory: NotificationEvent[] = useMemo(() => {
    if (!data?.siteAlarm) return [];
    return data?.siteAlarm?.pastNotifications;
  }, [data]);

  useEffect(() => {
    if (notificationHistory && !selectedNotification) {
      setSelectedNotification(notificationHistory?.[0]?.notifiedAt);
    }
  }, [notificationHistory, selectedNotification]);

  const currentNotification = notificationHistory?.find(
    (n) => n.notifiedAt === selectedNotification
  );

  return (
    <Modal size="6xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {data?.siteAlarm.alarmGroup.name.replace('{site}', data.siteAlarm.site.name) ?? ''}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Center>
            <Text fontSize="xl" m="10px">
              Check History
            </Text>
          </Center>
          <Flex justifyContent="space-evenly">
            {/* <FormInput w="250px" type="date" label="Start" control={control} name="startTime" />
            <FormInput w="250px" type="date" label="End" control={control} name="endTime" /> */}
          </Flex>
          {loading ? (
            <Skeleton w="100%" h="500px" />
          ) : (
            <VStack w="100%">
              <AlarmChart alarmStatusChecks={checks} notificationHistory={notificationHistory} />
              <Text fontSize="xl" m="10px">
                Most Recent Notification Details
              </Text>
              {notificationHistory.length > 0 && (
                <Select
                  onChange={(e) => setSelectedNotification(e.currentTarget.value)}
                  value={selectedNotification}
                  w="fit-content">
                  {notificationHistory.map((n, i) => (
                    <option key={i} value={n.notifiedAt}>
                      {format(new Date(n.notifiedAt), 'PP p')}
                    </option>
                  ))}
                </Select>
              )}
              <MostRecentNotificationTable notification={currentNotification} />
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewAlarmModal;
