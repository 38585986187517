import { gql, useMutation } from '@apollo/client';
import EntryFormModal from 'components/Forms/EntryFormModal';
import FormInput from 'components/Forms/FormInput';
import SearchableUserInput from 'components/Forms/SearchableUserInput';
import { ManualHydrographySchema } from 'components/types';
import { ProjectContext } from 'contexts/ProjectContext';
import { HydrographyReport, Site } from 'graphql/generated';
import { SUBMIT_DEVICE_VALUES } from 'graphql/globalMutations';
import { useContext } from 'react';
import { GET_HYDRO_REPORTS } from '../HydrographyDataEntryTab/HydrographyReportList';
import EntryGrid from './EntryGrid';

const SAVE_HYDRO_REPORT = gql`
  mutation saveHydrographyReport(
    $id: Int
    $reportTime: Date!
    $lastUpdated: Date!
    $reportedBy: String!
    $siteId: Int!
    $formInput: JSON!
    $transactionId: String!
  ) {
    saveHydrographyReport(
      id: $id
      reportTime: $reportTime
      lastUpdated: $lastUpdated
      reportedBy: $reportedBy
      siteId: $siteId
      formInput: $formInput
      transactionId: $transactionId
    ) {
      id
      reportTime
      lastUpdated
      reportedBy
      siteId
      formInput
      transactionId
    }
  }
`;

const DELETE_HYDRO_REPORT = gql`
  mutation deleteHydrographyReports($ids: [Int!]!) {
    deleteHydrographyReports(ids: $ids)
  }
`;

type EntryFormProps = {
  site: Site;
  report?: HydrographyReport;
  onClose: () => void;
};

export type Form = {
  id: number;
  info: {
    reportedBy: string;
    sampleTime: string;
  };
  measures: {
    [measurement: string]: number | string;
  }[];
};

const formatDate = (date?: Date) =>
  date
    ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, -5)
    : '';

const EntryForm = ({ site, report, onClose }: EntryFormProps) => {
  const projectContext = useContext(ProjectContext);

  const defaultValues = {
    id: report?.id ?? null,
    info: {
      reportedBy: report?.reportedBy ?? '',
      sampleTime: formatDate(report?.reportTime ? new Date(report?.reportTime) : new Date())
    },
    measures: report?.formInput?.counts ?? [
      {
        measure: 'Water Temperature'
      },
      {
        measure: 'Salinity'
      },
      {
        measure: 'Oxygen Saturation'
      }
    ]
  };

  const [saveReport] = useMutation(SAVE_HYDRO_REPORT, {
    refetchQueries: [GET_HYDRO_REPORTS, 'getHydrographyReports']
  });

  const [deleteReport, { loading: isDeleting }] = useMutation(DELETE_HYDRO_REPORT, {
    refetchQueries: [GET_HYDRO_REPORTS, 'getHydrographyReports']
  });

  const [submitDeviceValues, { loading: isSubmittingSmb }] = useMutation(SUBMIT_DEVICE_VALUES);

  const submit = async (form: Form) => {
    const items: ManualHydrographySchema[] = [];
    const depths = form.measures
      .reduce((acc, measure) => {
        return acc.concat(
          Object.keys(measure)
            .filter((x) => x != 'measure')
            .map((x) => Number(x.slice(0, -1)))
        );
      }, [])
      .filter((value, index, array) => array.indexOf(value) === index);

    depths.forEach((depth) => {
      items.push({
        site_id: site.smbId,
        sublocation: 'Barge',
        depth: depth,
        water_temp: Number(
          form.measures.filter((x) => x.measure === 'Water Temperature')[0][`${depth}m`]
        ),
        salinity: Number(form.measures.filter((x) => x.measure === 'Salinity')[0][`${depth}m`]),
        oxygen_saturation: Number(
          form.measures.filter((x) => x.measure === 'Oxygen Saturation')[0][`${depth}m`]
        ),
        visibility: NaN,
        measured_at: new Date(form.info?.sampleTime).toISOString()
      });
    });

    const encodedItems = btoa(JSON.stringify(items));
    const response = await submitDeviceValues({
      variables: { tableName: 'tess_manual_hydrography', values: encodedItems, action: 'INSERT' }
    });

    await saveReport({
      variables: {
        id: report?.id,
        reportedBy: form.info.reportedBy,
        reportTime: new Date(form.info.sampleTime).toISOString(),
        lastUpdated: new Date().toISOString(),
        siteId: site.id,
        formInput: {
          reportedBy: form.info.reportedBy,
          counts: form.measures
        },
        transactionId: response.data?.submitDeviceValues?.data?.transaction_id
      }
    });
  };

  const handleDelete = async () => {
    await submitDeviceValues({
      variables: {
        tableName: 'tess_manual_hydrography',
        values: btoa(JSON.stringify({ where: [['transaction_id', '=', report?.transactionId]] })),
        action: 'DELETE'
      }
    });

    await deleteReport({ variables: { ids: [report.id] } });
  };

  return (
    <EntryFormModal
      siteName={site.name}
      entryName="Hydrography"
      defaultValues={defaultValues}
      isDeleting={isDeleting}
      isSubmitting={isSubmittingSmb}
      onSubmit={submit}
      onDelete={handleDelete}
      onClose={onClose}
      basicFields={(control) => (
        <>
          <SearchableUserInput
            projectId={projectContext.id}
            name="info.reportedBy"
            control={control}
          />

          <FormInput
            isDisabled={!!report}
            name="info.sampleTime"
            control={control}
            label="Sample Time"
            rules={{ required: true }}
            type="datetime-local"
          />
        </>
      )}
      dataEntry={(control) => (
        <EntryGrid
          name="measures"
          //@ts-ignore
          control={control}
          depthColumns={projectContext.dataEntry.hydrography.columns[site.siteLabel]}
        />
      )}
    />
  );
};

export default EntryForm;
