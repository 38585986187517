import {
  ResponsiveValue,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import Tile from 'components/Tile';
import { Property } from 'csstype';

const InfoTable = ({
  headers,
  data,
  wordBreak = 'break-word'
}: {
  headers: string[];
  data: Record<string, unknown>[];
  wordBreak?: ResponsiveValue<Property.WordBreak>;
}) => {
  return (
    <Tile p="0.5rem">
      <TableContainer whiteSpace="unset">
        <Table variant="striped">
          <Thead>
            <Tr>
              {headers.map((x, i) => (
                <Th key={i}>{x}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((x, i) => {
              return (
                <Tr key={i}>
                  {Object.keys(x).map((key, j) => (
                    <Td verticalAlign="top" wordBreak={wordBreak} key={j}>
                      {typeof x[key] == 'string' ? (
                        <Text as={j === 0 ? 'i' : undefined}>{x[key]}</Text>
                      ) : (
                        x[key]
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Tile>
  );
};

export default InfoTable;
