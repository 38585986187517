import { FC, useContext, useMemo } from 'react';
import { Plot } from 'react-plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'shared/plotly-seastate-bundle.min.js';
import { ProjectContext } from 'contexts/ProjectContext';
import { ChartEventContext } from 'components/Site/Tabs/Explore/Explore';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { Data } from 'plotly.js';
import { format, max, min } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const plotDates = (plotData: Partial<Data>[]) => {
  const dates = plotData
    //@ts-ignore
    .flatMap((d) => d.x)
    .map((d) => (d instanceof Date ? d : new Date(d)))
    .filter((d) => d.toString() !== 'Invalid Date');

  if (dates.length <= 1) return ['', ''];

  const minDate = format(min(dates), 'PP p');
  const maxDate = format(max(dates), 'PP p');

  return [minDate, maxDate];
};

const CorePlot = createPlotlyComponent(Plotly) as Plot;

const removePlotlyButtons = {
  modeBarButtonsToRemove: [
    'lasso2d',
    'zoomIn2d',
    'zoomOut2d',
    'toggleHover',
    'toggleSpikelines',
    'hoverCompareCartesian',
    'hoverClosestCartesian',
    'select2d',
    'autoScale2d'
  ],
  displaylogo: false
};

const LocalPlot: FC<Plot> = (props) => {
  const project = useContext(ProjectContext);
  const chartEvents = useContext(ChartEventContext);

  const eventShapes = useMemo(() => {
    if (!chartEvents.showEvents || !chartEvents) return [];
    const pallet = createLocationPallet({ locations: chartEvents.events.map((e) => e.eventType) });

    return chartEvents.events.map((e) => ({
      type: 'rect',
      xref: 'x',
      yref: 'paper',
      x0: utcToZonedTime(e.startTime, project.timezone),
      y0: 0,
      x1: utcToZonedTime(e.endTime, project.timezone),
      y1: 1,
      fillcolor: pallet[e.eventType],
      opacity: 0.4,
      line: {
        width: 0
      },
      label: {
        text: e.eventType,
        textangle: 90,
        font: { size: 10, color: 'black' },
        textposition: 'top left'
      }
    }));
  }, [chartEvents.showEvents, chartEvents.events]);

  const layoutWithShapes = useMemo(() => {
    return {
      ...props?.layout,
      shapes: [...(props?.layout?.shapes ?? []), ...eventShapes]
    };
  }, [props?.layout, eventShapes]);

  return (
    <CorePlot
      {...props}
      layout={layoutWithShapes}
      config={{ ...props?.config, ...removePlotlyButtons }}
      useResizeHandler={true}
    />
  );
};

export default LocalPlot;
