import { WarningIcon } from '@chakra-ui/icons';
import { Center, Text, VStack } from '@chakra-ui/react';
import React from 'react';

type GraphErrorProps = {
  minH: string;
};

const GraphError = ({ minH }: GraphErrorProps) => {
  return (
    <Center w="100%" h="100%" minH={minH} border="1px" borderColor="red.500" borderRadius="3px">
      <VStack>
        <WarningIcon boxSize={8} color="red.500" />
        <Text>We experienced an unexpected error loading your chart.</Text>
        <a href=".">Try reloading the page.</a>
      </VStack>
    </Center>
  );
};

export default GraphError;
