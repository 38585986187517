import {
  Button,
  Center,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid
} from '@chakra-ui/react';
import { EquipmentAdminForm } from 'pages/project/[Id]/Admin/Equipment/EquipmentAdmin';
import { UseControllerProps, useController } from 'react-hook-form';

// https://codesandbox.io/s/chakra-ui-color-picker-h1vvn
export const ColorPicker = (
  props: UseControllerProps<
    EquipmentAdminForm,
    `equipmentTypes.${number}.states.${number}.options.${number}.color`
  >
) => {
  const colors = [
    'gray.500',
    'red.300',
    'red.500',
    'yellow.300',
    'yellow.700',
    'orange.300',
    'blue.500',
    'blue.200',
    'green.500',
    'green.200',
    'green.800',
    'purple.400',
    'purple.800',
    'pink.500'
  ];

  const { field } = useController(props);
  return (
    <Center>
      <Popover variant="picker">
        <PopoverTrigger>
          <Button
            aria-label={field.value}
            background={field.value}
            height="22px"
            width="22px"
            padding={0}
            minWidth="unset"
            borderRadius={3}></Button>
        </PopoverTrigger>
        <PopoverContent width="170px">
          <PopoverArrow bg={field.value} />
          <PopoverCloseButton color="white" />
          <PopoverHeader
            height="100px"
            backgroundColor={field.value}
            borderTopLeftRadius={5}
            borderTopRightRadius={5}
            color="white"></PopoverHeader>
          <PopoverBody height="120px">
            <SimpleGrid columns={5} spacing={2}>
              {colors.map((c) => (
                <Button
                  key={c}
                  aria-label={c}
                  background={c}
                  height="22px"
                  width="22px"
                  padding={0}
                  minWidth="unset"
                  borderRadius={3}
                  _hover={{ background: c }}
                  onClick={() => {
                    field.onChange(c);
                  }}></Button>
              ))}
            </SimpleGrid>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Center>
  );
};
