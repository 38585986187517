import { Box, Card, CardBody, StackProps, Text, VStack } from '@chakra-ui/react';
import { Station, StationType } from 'graphql/generated';
import { FC } from 'react';
import { IoMdArchive } from 'react-icons/io';
import { TbReportAnalytics } from 'react-icons/tb';
import { MapSite } from '../useMapSites';
import { SitePopup } from './SitePopup';
import { StationPopup } from './StationPopup';

const SitePopupTemplate: FC<{ name: string; type: string; owner?: string } & StackProps> = ({
  name,
  type,
  owner,
  children,
  ...props
}) => {
  return (
    <VStack spacing={0} {...props}>
      <Text mb="5px" fontSize="xs">
        {owner ? `${owner} - ${type}` : type}
      </Text>
      <Text fontSize="2xl">{name}</Text>
      {children}
    </VStack>
  );
};

export const PopupRouter = ({
  site,
  station,
  isLoading
}: {
  site: MapSite;
  station: Station;
  isLoading: boolean;
}) => {
  if (station) {
    return (
      <SitePopupTemplate
        name={station.name}
        owner={station.owner}
        type={station.type === StationType.River ? 'River Station' : 'Tide Station'}>
        <StationPopup station={station} />
      </SitePopupTemplate>
    );
  }

  if (site.archived) {
    return (
      <SitePopupTemplate name={site.name} type={site.type}>
        <Box pt="10px">
          <Card>
            <CardBody textAlign="center">
              <VStack>
                <IoMdArchive color="var(--chakra-colors-blue-500" fontSize="50px" />
                <Text>This site is archived.</Text>
                <Text>You can still explore its data, but it is not receiving updates.</Text>
              </VStack>
            </CardBody>
          </Card>
        </Box>
      </SitePopupTemplate>
    );
  } else if (site.type === 'ASC') {
    return (
      <SitePopupTemplate name={site.name} type={site.type}>
        <Box pt="10px">
          <Card>
            <CardBody textAlign="center">
              <VStack>
                <TbReportAnalytics color="var(--chakra-colors-blue-500" fontSize="50px" />
                <Text>This site is an ASC monitored location.</Text>
              </VStack>
            </CardBody>
          </Card>
        </Box>
      </SitePopupTemplate>
    );
  } else {
    return (
      <SitePopupTemplate name={site.name} type={site.type}>
        <SitePopup showOxygen={site.type === 'Farm'} site={site} isLoading={isLoading} />
      </SitePopupTemplate>
    );
  }
};
