import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Spacer, useCheckbox, UseRadioProps } from '@chakra-ui/react';
import { FC } from 'react';

const CheckboxCard: FC<UseRadioProps> = ({ children, ...props }) => {
  const { getInputProps, getCheckboxProps, state } = useCheckbox(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: 'blue.400',
          color: 'white',
          borderColor: 'blue.400'
        }}
        _focus={{
          boxShadow: 'outline'
        }}
        px={5}
        py={3}>
        {state.isChecked ? (
          <CheckCircleIcon boxSize="5" mr="5px" />
        ) : (
          <Spacer display="inline-block" w="25px" h="5px" />
        )}
        {children}
      </Box>
    </Box>
  );
};

export default CheckboxCard;
