import { EmailIcon } from '@chakra-ui/icons';
import { useController, UseControllerProps } from 'react-hook-form';
import BaseInput, { BaseInputProps } from './BaseInput';

type FormEmailInputProps<TForm> = BaseInputProps & UseControllerProps<TForm>;

const FormEmailInput = <TForm,>(props: FormEmailInputProps<TForm>) => {
  const { field, fieldState } = useController({
    ...props,
    rules: {
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid Email'
      }
    }
  });

  return (
    //@ts-ignore
    <BaseInput
      {...field}
      {...fieldState}
      {...props}
      placeholder="Email..."
      type="email"
      errorMessage={fieldState?.error?.message}
      icon={<EmailIcon color="blue.500" />}
    />
  );
};

export default FormEmailInput;
