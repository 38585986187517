// @ts-strict
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertStatus,
  AlertTitle,
  Skeleton,
  Stack
} from '@chakra-ui/react';
import { useCubeQuery } from '@cubejs-client/react';
import { THRESHOLD_LEVEL } from 'components/types';
import { ProjectContext } from 'contexts/ProjectContext';
import { PlanktonPolicy } from 'graphql/generated';
import { uniq } from 'lodash';
import { useContext, useMemo } from 'react';

interface ThresholdAlert {
  title: string;
  level: THRESHOLD_LEVEL;
  description: string;
}

const ThresholdStatusMap: Record<THRESHOLD_LEVEL, AlertStatus> = {
  [THRESHOLD_LEVEL.NO_DATA]: 'info',
  [THRESHOLD_LEVEL.SAFE]: 'success',
  [THRESHOLD_LEVEL.CAUTION]: 'warning',
  [THRESHOLD_LEVEL.DANGER]: 'error'
};

interface AlertBarProps {
  status: AlertStatus;
  title: string;
  description: string;
}

const AlertBar = ({ status, title, description }: AlertBarProps) => {
  return (
    <Alert status={status} variant="left-accent">
      <AlertIcon />
      <AlertTitle mr={1}>{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
    </Alert>
  );
};

interface AlertListProps {
  siteSmbId: number;
  siteHasGdb: boolean | null | undefined;
  siteName: string;
}

const planktonAlerts = (
  planktonBySite: { siteId: number; value: number; policy: PlanktonPolicy }[]
) => {
  const alertSpecies = planktonBySite.reduce(
    (acc, curr) => {
      let threshold = THRESHOLD_LEVEL.SAFE;

      if (!curr.policy) {
        console.error(
          'No policy for plankton found when building site status messages. Look earlier in console for species info.'
        );
        return acc;
      }
      const name = curr.policy.generic?.name ?? curr.policy?.species.species;

      if (curr.value >= curr.policy.danger) {
        threshold = THRESHOLD_LEVEL.DANGER;
      } else if (curr.value >= curr.policy.caution) {
        threshold = THRESHOLD_LEVEL.CAUTION;
      }

      if (Object.keys(acc).includes(Number(threshold).toString())) {
        acc[threshold] = [...acc[threshold], name];
      } else {
        acc[threshold] = [name];
      }

      return acc;
    },
    {} as { [level: string]: string[] }
  );

  const cautious = alertSpecies?.[THRESHOLD_LEVEL.CAUTION]
    ? uniq(alertSpecies[THRESHOLD_LEVEL.CAUTION])
    : [];
  const dangerous = alertSpecies?.[THRESHOLD_LEVEL.DANGER]
    ? uniq(alertSpecies[THRESHOLD_LEVEL.DANGER])
    : [];

  const alerts = [];

  if (cautious.length > 0) {
    alerts.push({
      title: 'High Plankton Concentrations',
      level: THRESHOLD_LEVEL.CAUTION,
      description: `Cautionary ${cautious.join(', ')} recorded in the last 24 hours`
    });
  }

  if (dangerous.length > 0) {
    alerts.push({
      title: 'High Plankton Concentrations',
      level: THRESHOLD_LEVEL.DANGER,
      description: `Dangerous ${dangerous.join(', ')} recorded in the last 24 hours`
    });
  }

  return alerts;
};

const oxygenAlerts = (
  oxygenBySite: { siteId: number; value: number; sublocation: string }[],
  oxygenThresholds: number[]
) => {
  const alertSublocations = oxygenBySite.reduce(
    (acc, curr) => {
      if (!curr.value) return acc;
      let threshold = THRESHOLD_LEVEL.SAFE;

      if (curr.value <= oxygenThresholds[1]) {
        threshold = THRESHOLD_LEVEL.DANGER;
      } else if (curr.value <= oxygenThresholds[0]) {
        threshold = THRESHOLD_LEVEL.CAUTION;
      }

      if (Object.keys(acc).includes(Number(threshold).toString())) {
        acc[threshold] = [...acc[threshold], curr.sublocation];
      } else {
        acc[threshold] = [curr.sublocation];
      }

      return acc;
    },
    {} as { [level: string]: string[] }
  );

  const cautious = alertSublocations?.[THRESHOLD_LEVEL.CAUTION]
    ? uniq(alertSublocations[THRESHOLD_LEVEL.CAUTION])
    : [];
  const dangerous = alertSublocations?.[THRESHOLD_LEVEL.DANGER]
    ? uniq(alertSublocations[THRESHOLD_LEVEL.DANGER])
    : [];

  const alerts: ThresholdAlert[] = [];

  if (cautious.length > 0) {
    alerts.push({
      title: 'Low Oxygen',
      level: THRESHOLD_LEVEL.CAUTION,
      description: `Cautionary Oxygen recorded in the last 30 minutes at ${cautious.join(', ')}`
    });
  }
  if (dangerous.length > 0) {
    alerts.push({
      title: 'Low Oxygen',
      level: THRESHOLD_LEVEL.DANGER,
      description: `Dangerous Oxygen recorded in the last 30 minutes at ${dangerous.join(', ')}`
    });
  }

  return alerts;
};

export const SiteStatusMessages = ({ siteSmbId, siteHasGdb }: AlertListProps) => {
  const projectContext = useContext(ProjectContext);

  const {
    isLoading: oxygenLoading,
    resultSet: oxygenData,
    error: oxygenError
  } = useCubeQuery({
    measures: ['TessSensorHydrography.oxygenSaturationMedian'],
    timeDimensions: [
      {
        dimension: 'TessSensorHydrography.measuredAt',
        dateRange: 'last 30 minutes from now',
        granularity: 'minute'
      }
    ],
    dimensions: ['Site.id', 'TessSensorHydrographyLookup.sublocation'],
    filters: [
      {
        member: 'Site.id',
        operator: 'equals',
        values: [siteSmbId.toString()]
      },
      //Ignore deep sensor oxygen values in Grieg projects
      {
        member: 'TessSensorHydrographyLookup.depth',
        operator: 'lt',
        values: ['15']
      }
    ],
    timezone: projectContext.timezone
  });

  const {
    isLoading: planktonLoading,
    error: planktonError,
    resultSet: planktonData
  } = useCubeQuery({
    measures: ['TessPlankton.maxCellCount'],
    dimensions: ['Site.id', 'TessPlanktonLookup.sublocation', 'TessPlanktonLookup.species'],
    timeDimensions: [
      {
        dimension: 'TessPlankton.measuredAt',
        dateRange: 'last 24 hours',
        granularity: 'hour'
      }
    ],
    filters: [
      {
        member: 'Site.id',
        operator: 'equals',
        values: [siteSmbId.toString()]
      },
      {
        member: 'TessPlanktonLookup.method',
        operator: 'equals',
        values: ['discrete']
      }
    ],
    timezone: projectContext.timezone
  });

  type GdbCubeDatum = {
    'TessGdbStatus.sumCount': string;
    'TessGdbStatus.measuredAt': string;
  };

  const {
    isLoading: gdbLoading,
    error: gdbError,
    resultSet: gdbResult
  } = useCubeQuery<GdbCubeDatum>({
    measures: ['TessGdbStatus.sumCount'],
    timeDimensions: [
      { dimension: 'TessGdbStatus.measuredAt', granularity: 'hour', dateRange: 'last 24 hours' }
    ],
    filters: [
      {
        member: 'TessGdbStatus.siteId',
        operator: 'equals',
        values: [siteSmbId.toString()]
      }
    ],
    timezone: projectContext.timezone
  });

  const brokenThresholds: ThresholdAlert[] = useMemo(() => {
    let brokenThresholds: ThresholdAlert[] = [];
    if (projectContext.thresholds.oxygen_saturation && oxygenData && !oxygenError) {
      const oxygenBySite = oxygenData.rawData().map((d) => {
        return {
          siteId: d['Site.id'] as number,
          value: d['TessSensorHydrography.oxygenSaturationMedian'] as number,
          sublocation: d['TessSensorHydrographyLookup.sublocation'] as string
        };
      });
      brokenThresholds.push(
        ...oxygenAlerts(oxygenBySite, projectContext.thresholds.oxygen_saturation)
      );
    }
    if (planktonData && !planktonError) {
      const planktonBySite = planktonData.rawData().map((d) => {
        return {
          siteId: d['Site.id'] as number,
          value: d['TessPlankton.maxCellCount'] as number,
          sublocation: d['TessPlanktonLookup.sublocation'] as string,
          species: d['TessPlanktonLookup.species'] as string,
          policy: projectContext.findPlanktonPolicy(d['TessPlanktonLookup.species'] as string)
        };
      });
      brokenThresholds.push(...planktonAlerts(planktonBySite));
    }

    if (siteHasGdb && gdbResult && !gdbError) {
      const data = gdbResult.rawData();
      if (data.length === 0) {
        brokenThresholds.push({
          title: 'GDB Offline',
          level: THRESHOLD_LEVEL.DANGER,
          description: 'GDB has not uploaded any data over the last 24 hours.'
        });
      }
    }

    brokenThresholds = brokenThresholds.filter((t) => t.level !== THRESHOLD_LEVEL.SAFE);

    return brokenThresholds;
  }, [
    gdbResult,
    gdbError,
    oxygenData,
    oxygenError,
    planktonData,
    planktonError,
    projectContext.thresholds.oxygen_saturation,
    projectContext.planktonPolicy,
    siteHasGdb
  ]);

  if (oxygenLoading || planktonLoading || gdbLoading) {
    return <Skeleton w="100%" height={'48px'} />;
  }

  if (oxygenError || planktonError || gdbError) {
    return (
      <AlertBar
        title={'Error'}
        status={'error'}
        description={'An error occurred loading warning data'}
      />
    );
  }

  if (brokenThresholds.length == 0) {
    return <AlertBar status={'success'} title={'Clear'} description={'No thresholds crossed'} />;
  }

  return (
    <Stack w="100%">
      {brokenThresholds.map((alert, index) => (
        <AlertBar
          status={ThresholdStatusMap[alert.level]}
          title={alert.title}
          description={alert.description}
          key={index}
        />
      ))}
    </Stack>
  );
};

export default SiteStatusMessages;
