// @ts-strict
import { gql, useQuery } from '@apollo/client';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { cubejsApiOptions } from 'App';
import { DemoLegalModal } from 'components/Modals/DemoLegalModal';
import NotFound from 'components/Pages/NotFound';
import { PlanktonGeneric, PlanktonSpecies, Project, Site } from 'graphql/generated';
import { createContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router';
import { Navigate } from 'react-router-dom';
import { useLocalStorage } from 'shared/LocalStorage';

export type FieldDefinition = {
  label: string;
  value: string;
};

export interface PlanktonDataEntryDefinition {
  columns: Record<string, FieldDefinition[]>;
  groups: Record<string, Record<string, string>>;
  transform_raw_counts?: boolean;
  default_number_of_slides?: number;
  default_number_of_quadrants?: number;
  default_jelly_net_radius?: number;
}

export interface HydrographyDataEntryDefinition {
  columns: Record<string, FieldDefinition[]>;
}

export interface SeaLiceDataEntryDefinition {
  fishConditions: FieldDefinition[];
  fishLiceStages: FieldDefinition[];
  toteLiceStages: FieldDefinition[];
}

export interface EquipmentDataEntryDefinition {
  parameters: string[];
  rows: string[];
  device_ids: Record<string, string>;
  labels: Record<string, string>;
  colors: Record<string, string>;
  values: Record<string, string[]>;
  reasons: Record<string, string[]>;
  historical_value_map: Record<string, any>;
}

export interface EventsDataEntryDefinition {
  types: Record<string, string>;
  colors: Record<string, string>;
}

interface DataEntry {
  equipment?: EquipmentDataEntryDefinition;
  plankton?: PlanktonDataEntryDefinition;
  hydrography?: HydrographyDataEntryDefinition;
  seaLice?: SeaLiceDataEntryDefinition;
  events?: EventsDataEntryDefinition;
}

// Object keyed by species with array of threshold values.
export type PlanktonThresholds = Record<string, number[]>;

export interface ThresholdConfiguration {
  oxygen_saturation?: number[];
}

interface ProjectContextType extends Omit<Project, 'dataEntry' | 'thresholds'> {
  timezoneLabel: string;
  timezoneOffset: number;
  hasModule: (name: string) => boolean;
  setProject: (project: Project | null) => void;
  dataEntry: DataEntry;
  thresholds: ThresholdConfiguration;
  altBargeHydrography: boolean;
  sites: Site[];
  siteNameMappings?: { [smbId: number]: string };
  freeTrial: boolean;
  sublocationToTesseractSublocation: (sublocation: string) => string;
  findPlanktonPolicy: (speciesKey: string) => {
    imageUrl: string;
    name: string;
    key: string;
    __typename?: 'PlanktonPolicy';
    caution: number;
    danger: number;
    generic?: PlanktonGeneric;
    species?: PlanktonSpecies;
  };
}

const ProjectContext = createContext<ProjectContextType | null>(null);

const getOffset = (timezone: string) => {
  const date = new Date();
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
  return utcDate.getTime() - tzDate.getTime();
};

const GET_PROJECT = gql`
  query GetProject($id: Int!) {
    project(id: $id) {
      id
      name
      active
      latitude
      longitude
      zoomlevel
      forecasting
      siwi
      hiresParams
      dataEntry
      thresholds
      timezone
      tabs
      demo
      freeTrial
      mapLayers
      mapParamSuffix
      altBargeHydrography
      region {
        id
        stations {
          id
          name
          lat
          lon
          stationKey
          type
          owner
        }
      }
      organization {
        id
        name
      }
      sites(includeArchived: true) {
        id
        smbId
        name
        type
        tabs
        tideStations
      }
      planktonPolicy {
        species {
          id
          key
          species
          category
          imageUrl
        }
        generic {
          id
          key
          name
          category
          imageUrl
        }
        caution
        danger
      }
    }
  }
`;

const ProjectContextProvider = () => {
  const { projectId } = useParams();
  const [activeProject, setActiveProject] = useState<null | Project>(null);
  const projectQuery = useQuery<{ project: Project }>(GET_PROJECT, {
    variables: { id: Number(projectId) },
    skip: !projectId || !!activeProject
  });

  const [acceptedDemo, setAcceptedDemo] = useLocalStorage('acceptedDemo', false);
  const [showingDemoModal, setShowingDemoModal] = useState(false);

  useEffect(() => {
    if (activeProject?.demo && !acceptedDemo) {
      setShowingDemoModal(true);
    }
  }, [activeProject?.demo, acceptedDemo]);

  useEffect(() => {
    if (!projectId) setActiveProject(null);
  }, [projectId]);

  const contextProject = useMemo(() => {
    if (!activeProject) return null;
    // Reset to assert the type - Project currently has this as a JSON Scalar.
    const dataEntry = activeProject.dataEntry as DataEntry;
    return {
      ...activeProject,
      dataEntry,
      setProject: setActiveProject,
      hasModule: (name: string) =>
        Object.keys(activeProject.dataEntry).includes(name) &&
        Object.values(activeProject.dataEntry[name]).length !== 0,
      timezoneOffset: getOffset(activeProject.timezone),
      timezoneLabel: new Date()
        .toLocaleTimeString('en-us', {
          timeZone: activeProject.timezone,
          timeZoneName: 'short'
        })
        .split(' ')[2],
      siteNameMappings: activeProject.sites?.reduce(
        (acc, d) => {
          acc[d.smbId] = d.name;
          return acc;
        },
        {} as { [smbId: number]: string }
      ),
      // Converts project sublocation decleration (found in project config file) to tesseract convention
      sublocationToTesseractSublocation: (sublocation: string) => {
        // Petuna uses a different convention for cages and they are stored in tess different than others.
        // Rather than 'pen-' they use 'cage-' and < 10's are 'cage-01'. there is also a 'cage-sensor station' value to wrangle.
        if (activeProject.id === 7) {
          if (sublocation.toLowerCase().includes('sensor station')) return 'cage-sensor station';

          const index = sublocation.replace(/[^0-9]/g, '');

          let subValue = '';
          if (index.length === 0) {
            subValue = sublocation;
          } else {
            subValue = `cage-${index.padStart(2, '0')}`;
          }

          return subValue;
        } else {
          const index = sublocation.replace(/[^0-9]/g, '');
          let subValue = '';
          if (index.length === 0) {
            subValue = sublocation;
          } else {
            subValue = `cage-${index}`;
          }

          return subValue;
        }
      },
      findPlanktonPolicy: (key: string) => {
        const speciesKey = key
          .toLowerCase()
          .replaceAll('_', '-')
          .replaceAll(' ', '-')
          .replaceAll('.', '');
        let policy = activeProject?.planktonPolicy.find(
          (pp) => (pp?.generic?.key ?? pp?.species.key) === speciesKey
        );

        if (!policy) {
          policy = activeProject?.planktonPolicy.find(
            (pp) => (pp?.generic?.key ?? pp?.species.key) === `${speciesKey}-sp`
          );
        }

        if (!policy) {
          console.error(`Policy not found for plankton species: ${key} ${speciesKey}`);
          return null;
        }

        return {
          ...policy,
          imageUrl: policy?.generic?.imageUrl ?? policy?.species?.imageUrl,
          name: policy?.generic?.name ?? policy.species.species,
          key: policy?.generic?.key ?? policy.species.key
        };
      }
    };
  }, [activeProject]);

  if (projectQuery.loading) {
    return <></>;
  }

  if (projectQuery.error) {
    console.error(projectQuery.error);
    return <NotFound />;
  }

  if (projectQuery.data?.project && !activeProject) {
    setActiveProject(projectQuery.data.project);
    // The project loaded, but let's return a loading spinner until next render
    //   where the activeProject/contextProject will be set correctly. This
    //   prevents loading a flash where the children are passed a null value.
    return <></>;
  }

  if (!projectQuery.loading && projectQuery?.data?.project === null) {
    return <NotFound />;
  }

  if (activeProject && !activeProject.active) {
    return <Navigate to={'suspended'} replace={true} />;
  }

  return (
    <ProjectContext.Provider value={contextProject}>
      {contextProject?.demo && (
        <DemoLegalModal
          isOpen={showingDemoModal}
          onClose={() => {
            setAcceptedDemo(true);
            setShowingDemoModal(false);
          }}
        />
      )}

      <CubeProvider
        cubejsApi={cubejs({
          ...cubejsApiOptions,
          headers: { projectId }
        })}>
        <Outlet />
      </CubeProvider>
    </ProjectContext.Provider>
  );
};

export { ProjectContext, ProjectContextProvider, ProjectContextType };
