import { Skeleton } from '@chakra-ui/react';
import { getDayOfYear } from 'date-fns';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, uniq } from 'lodash';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { useMemo } from 'react';
import { createLocationPallet } from 'shared/functions/colorPallets';
import GraphError from '../GraphError';
import NoData from '../NoData';
import { ControllerInputs } from '../Plankton/TrendsController';
import Plot from '../Plot';
import { BaseChartProps, PlanktonTrendSettings } from '../types';

type PlanktonCubeDatum = {
  'TessPlanktonLookup.sublocation'?: string;
  'Site.id'?: string;
  'TessPlankton.measuredAt': string;
  'TessPlankton.localTime': string;
  'TessPlankton.localYear': string;
  'TessPlanktonLookup.species': string;
  'TessPlanktonLookup.depth': string;
  'TessPlankton.avgCellCount': number;
  actual_sublocation: string;
};

// Target data structure for plot output
type PlanktonStructure = {
  [sublocation: string]: {
    [depth: number]: {
      [species: string]: {
        [year: number]: {
          [week: number]: {
            avgCellCount: number;
            thresholdVal: number;
            threshold: string;
            dataSublocations: string[];
          };
        };
      };
    };
  };
};

const Chart = ({
  control,
  skip,
  settings: {
    selectedSublocations = ['All'],
    comparingSublocations,
    selectedSpecies = 'All',
    depthAveraged: depthSumAggregate = true,
    groupSublocations: groupSubplots = true,
    ...settings
  }
}: BaseChartProps<PlanktonTrendSettings, ControllerInputs>) => {
  const locationDimension = settings.site?.smbId ? 'TessPlanktonLookup.sublocation' : 'Site.id';

  const transform = (rawData: PlanktonCubeDatum[]): PlanktonStructure => {
    let filteredDataSpp = rawData;
    // filter to spp.
    if (selectedSpecies.toString() != 'All') {
      filteredDataSpp = rawData.filter((d) => d['TessPlanktonLookup.species'] == selectedSpecies);
    } else {
      filteredDataSpp = rawData.map((d) => ({ ...d, 'TessPlanktonLookup.species': 'All' }));
    }
    // filter to subloc
    const sublocationLookupValues = settings.site?.smbId
      ? selectedSublocations
      : Object.values(selectedSublocations).flatMap((d) => {
          return Object.keys(settings.project.siteNameMappings).find(
            (sublocId) => settings.project.siteNameMappings[sublocId] === d
          );
        });
    const comparingSublocationsLookupValues = settings.site?.smbId
      ? comparingSublocations
      : comparingSublocations
        ? Object.values(comparingSublocations).flatMap((d) => {
            return Object.keys(settings.project.siteNameMappings).find(
              (sublocId) => settings.project.siteNameMappings[sublocId] === d
            );
          })
        : undefined;
    let filteredDataSublocation = filteredDataSpp;
    filteredDataSublocation = filteredDataSublocation.map((d) => ({
      ...d,
      'TessPlankton.actual_sublocation': d['TessPlanktonLookup.sublocation']
    }));
    // Filter logic gets a little complicated here with all of the cases.
    // but much of the complexity is to handle "All" when it comes up in one or both Select boxes.
    if (
      (selectedSublocations.length > 0 && comparingSublocations?.length > 0) ||
      (selectedSublocations.length > 0 &&
        comparingSublocations?.length == 0 &&
        !selectedSublocations.includes('All'))
    ) {
      // Case 1 : Selections in Group A or Group B
      if (groupSubplots) {
        // setup comparison of selected vs. not-selected sublocatitons
        let selected = [];
        if (!selectedSublocations.includes('All')) {
          // Use selected values for Group A
          selected = filteredDataSublocation.filter((d) =>
            sublocationLookupValues.includes(d[locationDimension].toString())
          );
        } else {
          // Use All values for Group A
          selected = filteredDataSublocation;
        }
        // Label data records for Group A
        selected = selected.map((d) => ({
          ...d,
          [locationDimension]: 'sublocation group A'
        }));
        let unselected = [];
        if (comparingSublocations?.length > 0) {
          if (!comparingSublocations?.includes('All')) {
            // Use selected values for Group B
            unselected = filteredDataSublocation.filter((d) =>
              comparingSublocationsLookupValues?.includes(d[locationDimension].toString())
            );
          } else {
            // Use All values for Group B
            unselected = filteredDataSublocation;
          }
          // Label data records for Group B
          unselected = unselected.map((d) => ({
            ...d,
            [locationDimension]: 'sublocation group B'
          }));
        } else {
          // No input for group B, so use all values not in selected
          unselected = filteredDataSublocation
            .filter((d) => !sublocationLookupValues.includes(d[locationDimension].toString()))
            .map((d) => ({
              ...d,
              [locationDimension]: 'unselected sublocations'
            }));
        }
        filteredDataSublocation = [...selected, ...unselected];
      } else {
        // Not grouping
        // Should we be checking for "All" here? We do in RecentTrendsDetail
        if (!selectedSublocations.includes('All')) {
          filteredDataSublocation = filteredDataSublocation.filter((d) =>
            sublocationLookupValues.includes(d[locationDimension].toString())
          );
        }
      }
    } else {
      // No comparison sites.
      // Filter to any Group A selections
      if (selectedSublocations.length > 0) {
        if (!selectedSublocations.includes('All')) {
          filteredDataSublocation = filteredDataSublocation.filter((d) =>
            sublocationLookupValues.includes(d[locationDimension].toString())
          );
        }
      }
      if (groupSubplots) {
        filteredDataSublocation = filteredDataSublocation.map((d) => ({
          ...d,
          [locationDimension]: 'all sublocations'
        }));
      }
    }
    const bySublocation = groupBy(filteredDataSublocation, locationDimension);
    // Aggregate on sublocation and depth
    // takes the mean of each time series for each grouping and time bin.
    const depthData: PlanktonStructure = Object.entries(bySublocation)
      .sort((one, two) => (one > two ? 1 : -1))
      .reduce((sublocAcc, [sublocation, dataAtSublocation]) => {
        // Aggregate on species
        const byDepth = Object.entries(groupBy(dataAtSublocation, 'TessPlanktonLookup.depth'))
          .sort((a, b) => Number(a) - Number(b))
          .reduce((depthAcc, [depth, dataAtDepth]) => {
            const bySpecies = Object.entries(
              groupBy(dataAtDepth, 'TessPlanktonLookup.species')
            ).reduce((speciesAcc, [species, speciesData]) => {
              // speciesAcc[species] = speciesAcc[species] || {};
              const byYear = Object.entries(groupBy(speciesData, 'TessPlankton.localYear')).reduce(
                (yearAcc, [year, yearData]) => {
                  yearAcc[year] = yearAcc[year] || {};
                  Object.entries(groupBy(yearData, 'TessPlankton.localTime')).map(
                    ([week, weekData]) => {
                      let weekFormatted = getDayOfYear(new Date(week));
                      weekFormatted = Math.ceil(Number(weekFormatted) / 7);
                      yearAcc[year][weekFormatted] = yearAcc[year][weekFormatted] || {};
                      yearAcc[year][weekFormatted] = {
                        avgCellCount:
                          weekData.reduce((a, b) => a + b['TessPlankton.avgCellCount'], 0) /
                          weekData.length
                      };
                    },
                    {}
                  );
                  return yearAcc;
                },
                {}
              );
              speciesAcc[species] = byYear;
              return speciesAcc;
            }, {});
            depthAcc[depth] = bySpecies;
            return depthAcc;
          }, {});
        sublocAcc[sublocation] = byDepth;
        return sublocAcc;
      }, {});
    return depthData;
  };
  const graph = (data: PlanktonStructure): PlotlyDataLayoutConfig => {
    // get lists of dates and depths so we can use them for plot layout attributes
    let uniqueSublocations = [];
    let uniqueDates = [];
    let uniqueDepths = [];
    Object.entries(data).flatMap(([sublocation, sublocationData]) => {
      uniqueSublocations.push(sublocation);
      Object.entries(sublocationData).flatMap(([depth, depthData]) => {
        uniqueDepths.push(depth);
        Object.values(depthData).flatMap((sppData) => {
          uniqueDates.push(...Object.keys(sppData));
        }, {});
      }, {});
    }, {});
    uniqueSublocations = uniq(uniqueSublocations);
    uniqueDates = uniq(uniqueDates);
    uniqueDepths = uniq(uniqueDepths);
    const pallet = createLocationPallet({
      palletType: 'years',
      locations: uniqueDates.sort()
    });
    const xAxisTicks = {
      8: 8,
      16: 16,
      24: 24,
      32: 32,
      40: 40,
      48: 48
    };
    xAxisTicks[Math.ceil(Number(getDayOfYear(new Date())) / 7)] = 'now';
    const layout = {
      // ...layout,
      autosize: true,
      height: !depthSumAggregate ? 500 + 50 * uniqueDepths.length : 500,
      // width: 100,
      margin: { b: 75, t: 50 },
      // barmode: 'stack',
      showlegend: true,
      grid: {
        rows: uniqueDepths.length,
        // ygap: 20,
        // xgap: 20,
        columns: uniqueSublocations.length,
        pattern: 'independent',
        // @ts-ignore
        subplots: [],
        roworder: 'top to bottom'
      },
      hovermode: 'x unified',
      yaxis: {
        type: 'log',
        showticklabels: true,
        autorange: true,
        automargin: true,
        rangemode: 'tozero',
        // range: [0, Number(maxCellVals)],
        showgrid: true,
        showline: true,
        title: {
          // text: 'Species Present',
          font: {
            size: 16
          },
          standoff: 0
        },
        // autorange: true,
        tickfont: {
          size: 14
        },
        tickangle: 0
      },
      xaxis: {
        automargin: true,
        showticklabels: true,
        showline: true,
        showgrid: true,
        range: [0.5, 53.5],
        tickfont: { size: 14 },
        ticklen: 5,
        tickvals: Object.keys(xAxisTicks),
        ticktext: Object.values(xAxisTicks),
        // margin: { pad: 50 }
        type: 'int'
      },
      legend: {
        orientation: 'h',
        x: 0,
        y: ~~(uniqueDates.length / 16) * 0.05 + 1.2,
        font: {
          size: 14
        }
      },
      annotations: [
        {
          xref: 'paper',
          yref: 'paper',
          x: -0.007,
          borderpad: 35,
          xanchor: 'right',
          y: 0.5,
          yanchor: 'center',
          textangle: -90,
          text: 'Plankton concentration (cells / mL)',
          font: { size: 16 },
          showarrow: false
        },
        {
          xref: 'paper',
          yref: 'paper',
          x: 0.5,
          // borderpad: 35,
          xanchor: 'right',
          y: -0.15,
          yanchor: 'center',
          textangle: 0,
          text: 'Week of Year',
          font: { size: 16 },
          showarrow: false
        }
      ]
      //@ts-ignore
      // annotations
    };
    if (!depthSumAggregate) {
      layout.annotations.push({
        xref: 'paper',
        yref: 'paper',
        x: 0.5,
        borderpad: 0.4,
        xanchor: 'right',
        y: 1.09,
        yanchor: 'top',
        textangle: 0,
        text: `<b>Depth ${uniqueDepths[0]}m`,
        font: { size: 16 },
        showarrow: false
      });
    }
    // counters for the grid layout reference for axes, which can be shared across grid rows or columns
    let xAxisCounter = 1;
    let yAxisCounter = 1;
    // counters for the grid layout itself, which is specified like [[plot, objects, for, row1], [plot, objects, for, row2]]
    let sublocationCounter = 0;
    let depthCounter = 0;
    const legendFlags = {};
    uniqueDates.forEach((year) => {
      legendFlags[year] = true;
    });
    //@ts-ignore
    const plotData: Data[] = Object.entries(data).flatMap(([sublocation, sublocationData]) => {
      uniqueDepths.forEach((depth) => {
        if (!Object.keys(sublocationData).includes(depth)) {
          sublocationData[depth] = {};
        }
      });
      const depthPlots = Object.values(sublocationData).flatMap((depthData) => {
        const sppPlots = Object.entries(depthData).flatMap(([species, dataAtSpecies]) => {
          const yearlyPlots = Object.entries(dataAtSpecies)
            .sort((a, b) => Number(a) - Number(b))
            .flatMap(([year, dataAtYear]) => {
              layout['grid']['subplots'][depthCounter] =
                layout['grid']['subplots'][depthCounter] || [];

              const name = settings.project?.findPlanktonPolicy(species)?.name;
              const sppPlot = {
                mode: 'lines+markers',
                type: 'scatter',
                x: Object.keys(dataAtYear),
                y: Object.values(dataAtYear).flatMap((d) => {
                  return d.avgCellCount.toFixed(2);
                }),
                xaxis: 'x' + xAxisCounter,
                yaxis: 'y' + yAxisCounter,
                hovertemplate: `<b>${name}</b><br>year: ${year} <br>cell/ml: %{y}<extra></extra>`,
                text: { year: year },
                hoverlabel: {
                  align: 'left'
                },
                name: year,
                legendgroup: year,
                showlegend: legendFlags[year],
                marker: {
                  size: 5,
                  color: Number(year) == new Date().getUTCFullYear() ? 'red' : pallet[year]
                },
                line: {
                  width: 2,
                  color: Number(year) == new Date().getUTCFullYear() ? 'red' : pallet[year]
                }
              };
              if (legendFlags[year]) {
                legendFlags[year] = false;
              }
              return sppPlot;
            });
          return yearlyPlots;
        }, {});

        layout['yaxis' + yAxisCounter] = {
          rangemode: 'tozero',
          autorange: true,
          showticklabels: true,
          tickfont: { size: 14 },
          showgrid: true,
          showline: true,
          type: 'log'
        };
        layout['xaxis' + xAxisCounter] = {
          showticklabels: true,
          showline: true,
          tickfont: { size: 14 },
          margin: { pad: 50 },
          tickvals: Object.keys(xAxisTicks),
          ticktext: Object.values(xAxisTicks),
          ticklen: 5,
          showgrid: true,
          range: [0.5, 53.5]
        };
        let xAxisMarker = 'x' + xAxisCounter;
        if (xAxisCounter == 1) {
          xAxisMarker = 'x';
        }
        let yAxisMarker = 'y' + yAxisCounter;
        if (yAxisCounter == 1) {
          yAxisMarker = 'y';
        }
        layout['grid']['subplots'][depthCounter].push(xAxisMarker + yAxisMarker);
        if (yAxisCounter > 1 && sublocationCounter == 0 && !depthSumAggregate) {
          layout.annotations.push({
            xref: 'paper',
            yref: 'paper',
            x: 0.5,
            borderpad: 2,
            xanchor: 'right',
            y: ((uniqueDepths.length - yAxisCounter + 1) * 1.05) / uniqueDepths.length,
            yanchor: 'top',
            textangle: 0,
            text: `<b>Depth ${uniqueDepths[yAxisCounter - 1]}m`,
            font: { size: 16 },
            showarrow: false
          });
        }
        if (depthCounter == 0 && uniqueSublocations.length >= 1) {
          const sublocAnnotationAnchor = 1;
          layout.annotations.push({
            xref: 'x' + xAxisCounter,
            yref: 'paper',
            x: sublocAnnotationAnchor,
            borderpad: 0,
            xanchor: 'left',
            y: 1.05,
            yanchor: 'top',
            textangle: 0,
            text: `<b>${settings.project.siteNameMappings[sublocation] ?? sublocation}`,
            font: { size: uniqueSublocations.length > 4 ? 12 : 14 },
            showarrow: false
          });
        }
        yAxisCounter++;
        depthCounter++;
        return sppPlots;
      });
      sublocationCounter++;
      xAxisCounter++;
      depthCounter = 0;
      return depthPlots;
    });
    return {
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };
  const queryDimensions = [
    locationDimension,
    'TessPlanktonLookup.species',
    'TessPlankton.localTime',
    'TessPlankton.localYear'
  ];
  if (!depthSumAggregate) {
    queryDimensions.push('TessPlanktonLookup.depth');
  }
  const { isLoading, error, plot, resultSet } = useCubeLTG({
    cubeQuery: {
      dimensions: queryDimensions,
      timeDimensions: [
        {
          dimension: 'TessPlankton.measuredAt',
          granularity: 'week'
        }
      ],
      measures: ['TessPlankton.avgCellCount'],
      filters: settings.site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [settings.site?.smbId.toString()]
            },
            { member: 'TessPlanktonLookup.method', operator: 'equals', values: ['discrete'] },
            { member: locationDimension, operator: 'set' },
            { member: 'TessPlanktonLookup.depth', operator: 'set' },
            { member: 'TessPlankton.avgCellCount', operator: 'gt', values: ['0'] }
          ]
        : [
            { member: 'TessPlanktonLookup.method', operator: 'equals', values: ['discrete'] },
            { member: locationDimension, operator: 'set' },
            { member: 'TessPlanktonLookup.depth', operator: 'set' },
            { member: 'TessPlankton.avgCellCount', operator: 'gt', values: ['0'] }
          ],
      timezone: settings.project.timezone,
      limit: 50_000
    },
    transform,
    graph,
    options: {
      refreshInterval: 900000,
      skip,
      dependencies: {
        depthSumAggregate,
        selectedSublocations,
        comparingSublocations,
        selectedSpecies,
        groupSubplots
      }
    }
  });

  const species = useMemo(() => {
    return uniq(resultSet?.rawData().map((d) => d['TessPlanktonLookup.species'])) ?? [];
  }, [resultSet]);

  const sublocations = useMemo(() => {
    return (
      uniq(
        resultSet
          ?.rawData()
          .map((d) =>
            settings.site?.smbId
              ? d[locationDimension]
              : settings.project.siteNameMappings[d[locationDimension]] ?? d[locationDimension]
          )
      ) ?? []
    );
  }, [resultSet]);

  if (isLoading) {
    return (
      <>
        {control({ availableSpecies: species, availableSublocations: sublocations })}
        <Skeleton minH="500px" height="100%" width="100%" />
      </>
    );
  }

  if (error) {
    return <GraphError minH="500px" />;
  }

  return plot?.data?.length > 0 ? (
    <>
      {control({ availableSpecies: species, availableSublocations: sublocations })}
      <Plot className="w-100 discrete-plankton-historical" {...plot} useResizeHandler={true} />
    </>
  ) : (
    <>
      {control({ availableSpecies: species, availableSublocations: sublocations })}
      <NoData minH="500px" />
    </>
  );
};

export default Chart;
