import { Session } from '@ory/client';
import * as Sentry from '@sentry/browser';
import ory from 'ory';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Outlet } from 'react-router-dom';

//Probably not the best design ATM. But it works for now while we have a smolt number of unauth'd routes
//TODO: Move to <AuthRoute> component in App.tsx
export const UNAUTH_ROUTES = ['login', 'recovery', 'under-maintenance'];

export const SessionContext = React.createContext<
  | null
  | (Session & {
      logoutUrl: string;
      setSession: React.Dispatch<React.SetStateAction<Session>>;
      setLogoutUrl: React.Dispatch<React.SetStateAction<string>>;
    })
>(null);

const SessionContextProvider = () => {
  const [session, setSession] = useState<Session | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [logoutUrl, setLogoutUrl] = useState<string | undefined>();
  const location = useLocation();
  const navigate = useNavigate();

  // Returns either the email or the username depending on the user's Identity Schema
  // const getUserName = (identity: Identity) => identity.traits.email || identity.traits.username;

  // Second, gather session data, if the user is not logged in, redirect to login
  useEffect(() => {
    if (session) return;

    ory
      .toSession()
      .then(({ data }) => {
        setSession(data);
        Sentry.setUser({ email: data.identity.traits.email, id: data.identity.id });
        ory.createBrowserLogoutFlow().then(({ data }) => {
          setLogoutUrl(data.logout_url);
        });
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((_err) => {
        // Redirect to login page
        if (!UNAUTH_ROUTES.some((r) => location.pathname.includes(r)))
          navigate(`/login?redirect=${location.pathname}`);
      });
  }, [session, location.pathname, navigate]);

  if (!session && !UNAUTH_ROUTES.some((r) => location.pathname.includes(r))) {
    // Still loading
    return <></>;
  }

  return (
    <SessionContext.Provider value={{ ...session, logoutUrl, setSession, setLogoutUrl }}>
      <Outlet />
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;
