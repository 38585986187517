import { PhoneIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement
} from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';

const CustomPhoneInput = forwardRef(function CustomPhoneInput(
  props,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <InputGroup>
      <InputLeftElement>
        <PhoneIcon color="blue.500" />
      </InputLeftElement>
      <Input {...props} ref={ref} placeholder="Mobile Number... Ex 1 888 227 8255" />;
    </InputGroup>
  );
});

const FormPhoneNumberInput = <TForm,>(
  props: UseControllerProps<TForm> & { isDisabled?: boolean }
) => {
  const { field, fieldState } = useController(props);

  return (
    <FormControl isDisabled={props.isDisabled} isInvalid={!!fieldState.error}>
      <PhoneInput
        data-cypress={props['data-cypress']}
        inputComponent={CustomPhoneInput}
        name={props.name}
        control={props.control}
        rules={{
          validate: (v) => (isValidPhoneNumber(v) ? true : 'Invalid phone number')
        }}
        {...field}
        {...fieldState}
      />
      {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormPhoneNumberInput;
