import { HStack, Heading, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import ChartTile from 'components/Charts/ChartTile';
import DiscreteSampleCombo from 'components/Charts/Plankton/DiscreteSampleCombo';
import LatestSample from 'components/Charts/Plankton/LatestSample';
import RecentTrendsController, {
  trendsTimeFrames
} from 'components/Charts/Plankton/RecentTrendsController';
import {
  ChartSettings as RecentTrendSettings,
  RecentTrendsV2
} from 'components/Charts/Plankton/RecentTrendsV2';
import PlanktonRecentTrendsDetailControl from 'components/Charts/Plankton/TrendsController';
import HistoricalBySpecies from 'components/Charts/PlanktonHistoricalBySpecies/Chart';
import Chart from 'components/Charts/PlanktonHistoricalByYear/Chart';
import PlanktonRecentTrendsDetail from 'components/Charts/PlanktonRecentTrendsDetail/Chart';
import PlanktonSpeciesPresent from 'components/Charts/PlanktonSpeciesPresent/Chart';
import { PlanktonTrendSettings } from 'components/Charts/types';
import InfoPopover from 'components/InfoPopover';
import Tile from 'components/Tile';
import { ProjectContextType } from 'contexts/ProjectContext';
import { Site } from 'graphql/generated';
import { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import Select from 'react-select';

type PlanktonDataProps = {
  site?: Site;
  project: ProjectContextType;
};

const PlanktonData = ({ site, project }: PlanktonDataProps) => {
  // Latest Sample
  const [selectedSublocPolar, setSelectedSublocPolar] = useState([{ label: 'All', value: 'All' }]);
  const handleChangeLatestSample = (newValue) => {
    setSelectedSublocPolar(newValue);
    return newValue;
  };

  // Recent Trends
  const [recentSettings, setRecentSettings] = useState<RecentTrendSettings>({
    site,
    project,
    sublocations: [],
    dateRange: trendsTimeFrames[1].value
  });

  // Recent Trends Detail
  const [planktonDetailSettings, setPlanktonDetailSettings] = useState<PlanktonTrendSettings>({
    site,
    project,
    selectedSpecies: 'All',
    selectedSublocations: ['All'],
    depthAveraged: true,
    groupSublocations: true
  });

  // Historical by Spp.
  const [historicalBySpeciesSettings, setHistoricalBySpeciesSettings] =
    useState<PlanktonTrendSettings>({
      site,
      project,
      selectedSpecies: 'All',
      selectedSublocations: ['All'],
      depthAveraged: true,
      groupSublocations: true
    });

  // Historical by Year
  const [historicalByYearSettings, setHistoricalByYearSettings] = useState<PlanktonTrendSettings>({
    site,
    project,
    selectedSpecies: 'All',
    selectedSublocations: ['All'],
    depthAveraged: true,
    groupSublocations: true
  });

  // popovers
  const latestSamplePopover = (
    <>
      <UnorderedList>
        <ListItem key={'latestSamplePopover-first'}>
          {
            'Data: The most recent discrete plankton sample within the last 3 days for each sublocation averaged across depths.'
          }
        </ListItem>
        ;
        <ListItem key={'latestSamplePopover-second'}>
          {
            'Color: Risk threshold status based on the maximum concentration for each species across all depths. Thresholds are configured at the project level by site admins.'
          }
        </ListItem>
        <ListItem key={'latestSamplePopover-third'}>{'Hover: Species name.'}</ListItem>;
      </UnorderedList>
      <Text fontWeight="bold"></Text>
      <Text fontWeight="bold">For more info:</Text>
      <ReactLink
        style={{ textDecoration: 'underline', color: '#6495ED' }}
        target="_blank"
        to="/docs">
        Go to the FAQ
      </ReactLink>
    </>
  );

  const RecentTrendsPopover = () => (
    <>
      <UnorderedList>
        <ListItem key={'recentTrendsPopover-first'}>
          Data: Discrete plankton samples across selected Time Frame. Concentrations are averaged
          across depths.
        </ListItem>
        <ListItem key={'recentTrendsPopover-sixth'}>
          Charts: Depth-averaged cells/mL for each species with data points colored by risk
          threshold status and chart background colored by species.
        </ListItem>
        <ListItem key={'recentTrendsPopover-second'}>Color: Risk status</ListItem>
      </UnorderedList>
      <Text fontWeight="bold">For more info:</Text>
      <ReactLink
        style={{ textDecoration: 'underline', color: '#6495ED' }}
        target="_blank"
        to="/docs">
        Go to the FAQ
      </ReactLink>
    </>
  );

  const discreteSummaryPopover = (
    <>
      <UnorderedList>
        <ListItem key={'discreteSummaryPopover-first'}>
          {
            'Data: Discrete plankton samples. Requires > 1 sample and > 1 depth in time range to plot contour data.'
          }
        </ListItem>
        ;
        <ListItem key={'discreteSummaryPopover-second'}>
          {
            'Color: Highest recorded risk level for each sample given the concentrations of each species found. OR plankton concentration for all species averaged across selected sublocations.'
          }
        </ListItem>
        <ListItem key={'discreteSummaryPopover-third'}>
          {'Hover: All species and their concentrations for a given sample.'}
        </ListItem>
        ;
      </UnorderedList>
      <Text fontWeight="bold"></Text>
      <Text fontWeight="bold">For more info:</Text>
      <ReactLink
        style={{ textDecoration: 'underline', color: '#6495ED' }}
        target="_blank"
        to="/docs">
        Go to the FAQ
      </ReactLink>
    </>
  );
  const towSummaryPopover = (
    <>
      <UnorderedList>
        <ListItem key={'towSummaryPopover-first'}>
          {'Data: Plankton tow sample showing species presence/absence.'}
        </ListItem>
        ;
        <ListItem key={'towSummaryPopover-second'}>
          {'Color: Sublocation where plankton sample was taken.'}
        </ListItem>
        <ListItem key={'towSummaryPopover-third'}>
          {'Hover: All species for a given sample and the depth of the tow sample.'}
        </ListItem>
        ;
      </UnorderedList>
      <Text fontWeight="bold"></Text>
      <Text fontWeight="bold">For more info:</Text>
      <ReactLink
        style={{ textDecoration: 'underline', color: '#6495ED' }}
        target="_blank"
        to="/docs">
        Go to the FAQ
      </ReactLink>
    </>
  );
  const discreteDetailPopover = (
    <>
      <UnorderedList>
        <ListItem key={'discreteDetailPopover-first'}>{'Data: Discrete plankton sample.'}</ListItem>
        ;
        <ListItem key={'discreteDetailPopover-second'}>
          {
            'Color: (Line) Plankton species; (Marker Fill) Risk threshold status using max cells/mL.'
          }
        </ListItem>
        <ListItem key={'discreteDetailPopover-third'}>
          {'Hover: All species, depths, and concentrations for a given sample.'}
        </ListItem>
        <ListItem key={'discreteDetailPopover-fourth'}>
          {'Panels: (Columns) Sample sublocations; (Rows) Sample depths.'}
        </ListItem>
        <ListItem key={'discreteDetailPopover-fifth'}>
          {'Depth-averaged Toggle: average across depths OR allow subplots for each depth.'}
        </ListItem>
        <ListItem key={'discreteDetailPopover-sixth'}>
          {
            'Group selected sublocations Toggle: Compare avg. values between Group A (top selected sublocations) and Group B (bottom selected sublocations). If no Group B selections are made, Group B is all sublocations that were not included in Group A.'
          }
        </ListItem>
        ; ;
      </UnorderedList>
      <Text fontWeight="bold"></Text>
      <Text fontWeight="bold">For more info:</Text>
      <ReactLink
        style={{ textDecoration: 'underline', color: '#6495ED' }}
        target="_blank"
        to="/docs">
        Go to the FAQ
      </ReactLink>
    </>
  );
  const historicalPopover = (
    <>
      <UnorderedList>
        <ListItem key={'first'}>
          {'Data: All historical discrete plankton sample for the site.'}
        </ListItem>
        ;<ListItem key={'second'}>{'Color: species'}</ListItem>
        <ListItem key={'third'}>
          {'Hover: All species and their avg. concentrations for a given week-of-year.'}
        </ListItem>
        ;
        <ListItem key={'fourth'}>
          {'Depth-averaged Toggle: average across depths OR allow subplots for each depth.'}
        </ListItem>
        ;
        <ListItem key={'fifth'}>
          {
            'Group selected sublocations Toggle: Compare avg. values between Group A (top selected sublocations) and Group B (bottom selected sublocations). If no Group B selections are made, Group B is all sublocations that were not included in Group A.'
          }
        </ListItem>
        ;
      </UnorderedList>
      <Text fontWeight="bold"></Text>
      <Text fontWeight="bold">For more info:</Text>
      <ReactLink
        style={{ textDecoration: 'underline', color: '#6495ED' }}
        target="_blank"
        to="/docs">
        Go to the FAQ
      </ReactLink>
    </>
  );
  const yearlyPopover = (
    <>
      <UnorderedList>
        <ListItem key={'yearlyPopover-first'}>
          {'Data: All historical discrete plankton sample for the site.'}
        </ListItem>
        ;<ListItem key={'yearlyPopover-second'}>{'Color: year'}</ListItem>
        <ListItem key={'yearlyPopover-third'}>
          {'Hover: All species and their avg. concentrations for a given week-of-year.'}
        </ListItem>
        ;
        <ListItem key={'yearlyPopover-fourth'}>
          {'Depth-averaged Toggle: average across depths OR allow subplots for each depth.'}
        </ListItem>
        ;
        <ListItem key={'yearlyPopover-fifth'}>
          {
            'Group selected sublocations Toggle: Compare avg. values between Group A (top selected sublocations) and Group B (bottom selected sublocations). If no Group B selections are made, Group B is all sublocations that were not included in Group A.'
          }
        </ListItem>
        ;
      </UnorderedList>
      <Text fontWeight="bold"></Text>
      <Text fontWeight="bold">For more info:</Text>
      <ReactLink
        style={{ textDecoration: 'underline', color: '#6495ED' }}
        target="_blank"
        to="/docs">
        Go to the FAQ
      </ReactLink>
    </>
  );
  return (
    <VStack w="100%">
      <HStack w="100%" alignItems="stretch">
        <ChartTile
          w="38%"
          m={0}
          heading="Latest Sample"
          hash="latest-sample"
          site={site}
          tooltip={latestSamplePopover}
          overflow="visible">
          <LatestSample
            smbSiteId={site?.smbId}
            selectedSublocation={selectedSublocPolar}
            control={(sublocations) => (
              <HStack mb="5px" alignItems="center" justifyContent="center">
                <Text>Sublocations</Text>
                <Select
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      width: '200px',
                      textAlign: 'left'
                    }),
                    menu: (baseStyles) => ({
                      ...baseStyles,
                      textAlign: 'left'
                    })
                  }}
                  isMulti
                  defaultValue={[{ label: 'All', value: 'All' }]}
                  onChange={handleChangeLatestSample}
                  options={[{ label: 'All', value: 'All' }, ...sublocations]}
                />
              </HStack>
            )}
          />
        </ChartTile>
        <Tile w="62%">
          <HStack w="100%" justify="center" alignContent="center" my="10px">
            <Heading as="h5" size="lg">
              Recent Trends
            </Heading>
            <InfoPopover>
              <RecentTrendsPopover />
            </InfoPopover>
          </HStack>
          <RecentTrendsV2
            settings={recentSettings}
            control={(props) => (
              <RecentTrendsController
                chartSettings={recentSettings}
                setChartSettings={setRecentSettings}
                chartInputs={props}
              />
            )}
          />
        </Tile>
      </HStack>
      <ChartTile
        w="100%"
        heading="Discrete Sample Summary"
        site={site}
        tooltip={discreteSummaryPopover}>
        <DiscreteSampleCombo settings={{ project, site }} />
      </ChartTile>
      <ChartTile
        w="100%"
        heading="Tow Summary: Species Presence"
        site={site}
        tooltip={towSummaryPopover}>
        <PlanktonSpeciesPresent settings={{ project, site }} />
      </ChartTile>
      <ChartTile
        w="100%"
        heading="Recent Trends Detail"
        site={site}
        tooltip={discreteDetailPopover}>
        <PlanktonRecentTrendsDetail
          settings={planktonDetailSettings}
          control={(props) => (
            <PlanktonRecentTrendsDetailControl
              chartSettings={planktonDetailSettings}
              setChartSettings={setPlanktonDetailSettings}
              chartInputs={props}
            />
          )}
        />
      </ChartTile>
      <ChartTile
        w="100%"
        heading="Historical Trends by Species"
        site={site}
        tooltip={historicalPopover}>
        <HistoricalBySpecies
          settings={historicalBySpeciesSettings}
          control={(props) => (
            <PlanktonRecentTrendsDetailControl
              chartSettings={historicalBySpeciesSettings}
              setChartSettings={setHistoricalBySpeciesSettings}
              chartInputs={props}
            />
          )}
        />
      </ChartTile>
      <ChartTile
        w="100%"
        heading="Historical Trends by Year"
        data-cypress={'plankton-discrete-yearly'}
        site={site}
        tooltip={yearlyPopover}>
        <Chart
          settings={historicalByYearSettings}
          control={(props) => (
            <PlanktonRecentTrendsDetailControl
              chartSettings={historicalByYearSettings}
              setChartSettings={setHistoricalByYearSettings}
              chartInputs={props}
            />
          )}
        />
      </ChartTile>
    </VStack>
  );
};

export { PlanktonData };
