import { VStack, Text } from '@chakra-ui/react';
import { SiteOption, User } from '@scoot/permissions';
import ThreeStateCheckbox, { ThreeStateCheck } from 'components/Forms/ThreeStateCheckbox';

export type PermissionToggleProps = {
  user: User;
  option: SiteOption;
  state: ThreeStateCheck;
  onChange: (option: SiteOption) => void;
};

const optionsConfiguration = {
  asc: 'ASC',
  manualHydrography: 'Hydrography',
  events: 'Events',
  equipment: 'Equipment',
  plankton: 'Plankton',
  seaLice: 'Sea Lice'
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PermissionToggle = ({ option, state, onChange }: PermissionToggleProps) => {
  return (
    <VStack padding="10px">
      <Text textAlign="center">{optionsConfiguration[option]}</Text>
      <ThreeStateCheckbox
        data-cypress={`${option}-checkbox`}
        state={state}
        onChange={() => onChange(option as SiteOption)}
        size="lg"
        colorScheme="green"
        borderColor="red.300"
      />
    </VStack>
  );
};

export default PermissionToggle;
