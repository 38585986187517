// import BaseSelect from 'components/Forms/BaseSelect';
import { HStack } from '@chakra-ui/react';
import { Station } from 'graphql/generated';
import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController } from '../types';
import { UseTideTraceParams } from './useTideTrace';

type TideControllerInputs = {
  stations: Station[];
};

const Controller = ({
  chartSettings,
  setChartSettings,
  chartInputs
}: ChartController<UseTideTraceParams, TideControllerInputs>) => {
  const stationOptions: { label: string; value: string }[] = chartInputs?.stations
    ? chartInputs.stations.map((s) => ({ label: s.name, value: s.stationKey }))
    : [];

  return (
    <>
      <HStack alignContent="center" justifyContent="center">
        <ChartSwitchPanel
          selects={[
            {
              label: 'Lunar Parameter',
              value: chartSettings?.lunarParameter ?? {
                label: 'Tidal Water Level (m)',
                value: 'TessTides.waterlevelAvg'
              },
              options: [
                { label: 'Tidal Water Level (m)', value: 'TessTides.waterlevelAvg' },
                { label: 'Diurnal Inequality Index (DII)', value: 'TessTides.diiAvg' },
                { label: 'Lunar Index (LI)', value: 'TessTides.liAvg' }
              ],
              isMulti: false,
              placeholder: 'Select A Lunar Parameter',
              styles: {
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: '300px',
                  marginLeft: '30px',
                  closeMenuOnSelect: false
                })
              },
              onChange: (options: { label: string; value: string }) => {
                setChartSettings({
                  ...chartSettings,
                  lunarParameter: { value: options.value, label: options.label }
                });
              }
            },
            chartInputs?.stations
              ? {
                  label: 'Station',
                  value: stationOptions.find((so) => so.value === chartSettings?.tideStationId),
                  options: stationOptions,
                  onChange: (option: { label: string; value: string }) => {
                    setChartSettings({
                      ...chartSettings,
                      tideStationId: option.value
                    });
                  }
                }
              : null
          ].filter((o) => o)}></ChartSwitchPanel>
      </HStack>
    </>
  );
};

export default Controller;
