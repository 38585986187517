import { DeleteIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  Button,
  ButtonProps,
  CardBody,
  Divider,
  HStack,
  Heading,
  IconButton,
  VStack
} from '@chakra-ui/react';
import { EquipmentAction, EquipmentState } from 'graphql/generated';
import { BiCollection, BiCommentDetail, BiPlus } from 'react-icons/bi';
import { MdNumbers, MdOutlinePendingActions } from 'react-icons/md';

export const AddButton = (props: ButtonProps) => {
  return <Button {...props} w="100%" leftIcon={<BiPlus />} variant="outline" color="green.500" />;
};

const StateIcon = ({ stateType }: { stateType: EquipmentState['__typename'] | 'action' }) => {
  if (stateType === 'EquipmentSelectionState') {
    return <BiCollection />;
  } else if (stateType === 'EquipmentNumberState') {
    return <MdNumbers />;
  } else if (stateType === 'EquipmentTextState') {
    return <BiCommentDetail />;
  } else if (stateType === 'action') {
    return <MdOutlinePendingActions />;
  } else {
    return <QuestionOutlineIcon />;
  }
};

export const SelectState = ({
  states,
  actions,
  onStateSelected,
  onDeleteState
}: {
  states: EquipmentState[];
  actions: EquipmentAction[];
  onStateSelected: (selection: {
    idx?: number;
    type: EquipmentState['__typename'] | 'action';
  }) => void;
  onDeleteState: (selection: {
    idx: number;
    type: EquipmentState['__typename'] | 'action';
  }) => void;
}) => {
  const addStateOfType = (stateType: EquipmentState['__typename']) => {
    onStateSelected({ type: stateType });
  };

  return (
    <CardBody>
      {states.length > 0 && (
        <>
          <Heading size="sm">Edit State</Heading>
          <Divider mb="10px" />
          <VStack>
            {states.map((s, i) => (
              <HStack w="100%" key={s.name + i}>
                <Button
                  data-cypress={`edit-state-${i}`}
                  onClick={() => onStateSelected({ idx: i, type: s.__typename })}
                  w="100%"
                  leftIcon={<StateIcon stateType={s.__typename} />}>
                  {s.name}
                </Button>
                <IconButton
                  colorScheme="red"
                  icon={<DeleteIcon />}
                  aria-label={'Delete Equipment State'}
                  onClick={() => onDeleteState({ idx: i, type: s.__typename })}
                />
              </HStack>
            ))}
          </VStack>
        </>
      )}

      <Heading mt="10px" size="sm">
        New State
      </Heading>
      <Divider mb="10px" />
      <VStack>
        <AddButton
          data-cypress="add-selection-btn"
          onClick={() => addStateOfType('EquipmentSelectionState')}>
          Selection
        </AddButton>
        <AddButton
          data-cypress="add-number-btn"
          onClick={() => addStateOfType('EquipmentNumberState')}>
          Number Input
        </AddButton>
        <AddButton data-cypress="add-text-btn" onClick={() => addStateOfType('EquipmentTextState')}>
          Text Input
        </AddButton>
      </VStack>
      {actions.length > 0 && (
        <>
          <Heading mt="30px" size="sm">
            Edit Action
          </Heading>
          <Divider mb="10px" />
          <VStack>
            {actions.map((a, i) => (
              <HStack w="100%" key={a.name + i}>
                <Button
                  data-cypress={`edit-action-${i}`}
                  onClick={() => onStateSelected({ idx: i, type: 'action' })}
                  w="100%"
                  leftIcon={<StateIcon stateType={'action'} />}>
                  {a.name}
                </Button>
                <IconButton
                  colorScheme="red"
                  icon={<DeleteIcon />}
                  aria-label={'Delete Action'}
                  onClick={() => onDeleteState({ idx: i, type: 'action' })}
                />
              </HStack>
            ))}
          </VStack>
        </>
      )}
      <Heading mt="10px" size="sm">
        New Action
      </Heading>
      <Divider mb="10px" />
      <AddButton data-cypress="add-action-btn" onClick={() => onStateSelected({ type: 'action' })}>
        Action
      </AddButton>
    </CardBody>
  );
};
