import { useMutation } from '@apollo/client';
import { AttachmentIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { EventComment as EventCommentType } from 'graphql/generated';
import { GET_S3_PRESIGNED_URL } from 'graphql/globalMutations';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

type EventCommentProps = {
  comment: EventCommentType;
};

const EventComment = ({ comment }: EventCommentProps) => {
  const [attachmentLinks, setAttachmentLinks] = useState<string[]>([]);
  const [getPresignedUrl] = useMutation<{ getS3PresignedURL: string }>(GET_S3_PRESIGNED_URL);

  useEffect(() => {
    const setAttachments = async () => {
      const promises = comment.attachmentRefs.map((name) => {
        return getPresignedUrl({ variables: { name: name, operation: 'getObject' } });
      });
      const responses = await Promise.all(promises);

      const attachmentLinks = responses.map((r) => r.data.getS3PresignedURL);

      setAttachmentLinks(attachmentLinks);
    };

    setAttachments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment]);

  return (
    <Box key={comment.created} m="5px" p="5px" w="100%">
      <Flex width={'100%'} justifyContent="space-between">
        <small
          style={{
            maxWidth: '250px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>
          <b>{comment.creator}</b>
        </small>
        <small>{moment(comment.created).format('lll')}</small>
      </Flex>
      <br />
      <Text>{comment.comment.replace(/@\[(.+@.+)\]\((.+@.+)\)/gm, '$1')}</Text>
      <HStack>
        {attachmentLinks.length > 0 &&
          attachmentLinks.map((preSignedUrl, idx) => {
            return (
              <Box key={preSignedUrl}>
                <small>
                  <AttachmentIcon />
                  <a
                    style={{ color: 'royalblue' }}
                    href={preSignedUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    {comment.attachmentRefs[idx].split('/')[1]}
                  </a>
                </small>
              </Box>
            );
          })}
      </HStack>
    </Box>
  );
};

export default EventComment;
