import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { ReactElement } from 'react';

export type BaseInputProps = {
  label?: string;
  tooltip?: string;
  errorMessage?: string;
  icon?: ReactElement;
} & InputProps;

export const formatDate = (date?: Date) =>
  date
    ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, -5)
    : '';

const BaseInput = ({ label, tooltip, errorMessage, icon, ...props }: BaseInputProps) => {
  return (
    <FormControl isInvalid={!!errorMessage} w={props?.w}>
      {label && (
        <FormLabel mb="0px">
          <Text display="inline-block">{label}</Text>
          {tooltip && (
            <Tooltip label={tooltip}>
              <InfoOutlineIcon ml="5px" />
            </Tooltip>
          )}
        </FormLabel>
      )}
      <InputGroup>
        {icon && <InputLeftElement>{icon}</InputLeftElement>}
        <Input {...props} />
      </InputGroup>
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

export default BaseInput;
