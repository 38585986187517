import {
  Box,
  HStack,
  VStack,
  FormLabel,
  Slider,
  SliderTrack,
  Text,
  SliderFilledTrack,
  SliderThumb,
  SliderMark
} from '@chakra-ui/react';
import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';
import { ParameterOptions } from './ForecastPlot';
import { permissions } from '@scoot/permissions';
import { UserContext } from 'contexts/UserContext';
import { useContext } from 'react';

const Controller = ({ chartSettings, setChartSettings }: ChartController<ChartSettings>) => {
  const currentUser = useContext(UserContext);
  chartSettings.parameters = chartSettings.parameters
    ? chartSettings.parameters
    : permissions.isSuperuser(currentUser)
      ? [
          { label: 'Water Temperature', value: 'waterTempAvg' },
          { label: 'Oxygen Saturation', value: 'oxygenSaturationAvg' },
          { label: 'Salinity', value: 'salinityAvg' }
        ]
      : [
          { label: 'Water Temperature', value: 'waterTempAvg' },
          { label: 'Oxygen Saturation', value: 'oxygenSaturationAvg' },
          { label: 'Salinity', value: 'salinityAvg' }
        ];

  const variableOptions = permissions.isSuperuser(currentUser)
    ? [
        { label: 'Water Temperature', value: 'waterTempAvg' },
        { label: 'Oxygen Saturation', value: 'oxygenSaturationAvg' },
        { label: 'Salinity', value: 'salinityAvg' },
        { label: 'Oxygen Concentration', value: 'oxygenConcentrationAvg' },
        { label: 'Pycnocline Depth', value: 'pycnoDepthAvg' },
        { label: 'TKE (mixing force)', value: 'tkeAvg' },
        { label: 'Buoyancy Frequency (stability)', value: 'buoyancyFreqAvg' }
      ]
    : [
        { label: 'Water Temperature', value: 'waterTempAvg' },
        { label: 'Oxygen Saturation', value: 'oxygenSaturationAvg' },
        { label: 'Salinity', value: 'salinityAvg' },
        { label: 'Oxygen Concentration', value: 'oxygenConcentrationAvg' }
      ];

  const labelStyles = {
    mt: '2',
    ml: '-2.5',
    fontSize: 'sm'
  };
  return (
    <Box>
      <HStack alignItems="center" justifyContent="space-evenly" h="55px" w="100%">
        <FormLabel display="inline" mr="-50px" alignItems="right">
          <VStack mr="30px">
            <Text mb="-10px">Model Ensemble</Text>
            <Text mt="-10px">Percentile</Text>
          </VStack>
        </FormLabel>
        <Slider
          aria-label="slider-ex-6"
          width="15%"
          defaultValue={50}
          min={0}
          max={100}
          step={25}
          onChange={(value) => {
            setChartSettings({
              ...chartSettings,
              aggregation: value
            });
          }}>
          <SliderMark value={0} {...labelStyles}>
            Min.
          </SliderMark>
          <SliderMark value={25} {...labelStyles}>
            25%
          </SliderMark>
          <SliderMark value={50} {...labelStyles}>
            Avg.
          </SliderMark>
          <SliderMark value={75} {...labelStyles}>
            75%
          </SliderMark>
          <SliderMark value={100} {...labelStyles}>
            Max.
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack bg="blue.400" />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <ChartSwitchPanel
          switches={[
            {
              left: { value: 'regional', label: 'Regional Color Scale' },
              right: {
                value: 'local',
                label: 'Local Color Scale',
                tooltip:
                  'Adjusts the color scale limits to the maximum and minimum values measured at this site vs the regional defaults.'
              },
              toggle: ({ checked }) =>
                setChartSettings({ ...chartSettings, useLocalMinMax: checked }),
              switchProps: { isChecked: chartSettings.useLocalMinMax }
            }
          ]}
          selects={[
            {
              value: chartSettings?.parameters,
              defaultValue: chartSettings?.parameters,
              options: variableOptions,
              placeholder: 'Select Hydrographic Parameters',
              isMulti: true,
              onChange: (x) =>
                setChartSettings({
                  ...chartSettings,
                  parameters: x as ParameterOptions
                })
            }
          ]}
          width="70%"
        />
      </HStack>
    </Box>
  );
};

export default Controller;
