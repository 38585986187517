import { SiwiDatum } from 'components/types';

export function groupby(xs, key) {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function concentrationNumFormatter(num, sigfigs, digits) {
  //HA 1/21/2020: we want concentrations formatted in a very specific way:
  //  custom sig figs, displaying a certain max digits, no scientific formatting:
  //  ex with 3 sig figs, 5 digits:
  //      54321 becomes 54300,
  //      54.321 becomes 54.3,
  //      5.4321 becomes 5.43,
  //      0.00054321 becomes .00054
  //      0.0000054321 becomes >.00001
  const sigFigNum = +num.toPrecision(sigfigs);
  if (sigFigNum % 1 === 0) {
    return sigFigNum.toFixed(0); // if we are evenly divisible by 1 just return the number
  }
  if (sigFigNum < 10 ** -digits) {
    return '<' + (10 ** -digits).toFixed(digits);
  }
  const regex = /(0*$)+/g;
  return sigFigNum.toFixed(digits).replace(regex, '');
}

export const getKOReasons = (datum: SiwiDatum): string[] => {
  return Object.entries(datum.indicators)
    .map((indicatorPair) => {
      if (indicatorPair[1].ko === 'True') {
        return indicatorPair[0];
      }
    })
    .filter((r) => r !== undefined);
};

// Returns an object of string formated date to list of reasons (Indicators) for the ko event
// { "2022-05-05": [ "Mortalitity", "Sealions" ]}
export const getKODays = (dates: Date[], siwiData: SiwiDatum[]) => {
  return dates.reduce<{ [key: string]: string[] }>((acc, current) => {
    const kos = getKOReasons(siwiData.filter((d) => d.time === current)[0]);
    if (kos.length > 0) {
      if (acc[current.toString()]) {
        acc[current.toString()].concat(kos);
      } else {
        acc[current.toString()] = kos;
      }
    }
    return acc;
  }, {});
};

export const getKOShapes = (koDays: { [key: string]: string[] }, siwiData: SiwiDatum[]) => {
  return Object.keys(koDays).map((koDay) => {
    const x0 = new Date(koDay);
    x0.setHours(x0.getHours() - 10);

    const x1 = new Date(koDay);
    x1.setHours(x1.getHours() + 10);

    const currentDay = siwiData.filter((d) => d.time.toString() === koDay)[0];
    const ySum = Object.values(currentDay.indicators).reduce((prev, curr) => {
      return prev + curr.weight * curr.score;
    }, 0);

    return {
      type: 'rect',
      xref: 'x',
      yref: 'paper',
      x0,
      y0: 0,
      x1,
      y1: ySum / 100 - 0.01,
      line: {
        color: 'rgb(229, 62, 62)'
      }
    };
  });
};
