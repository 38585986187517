import { Box, SimpleGrid, Tooltip } from '@chakra-ui/react';
import { SiteOption, User } from '@scoot/permissions';
import { ThreeStateCheck } from 'components/Forms/ThreeStateCheckbox';
import PermissionToggle from './PermissionToggle';

type PermissionsGridProps = {
  user: User;
  options: { [option: string]: ThreeStateCheck };
  onChange: (option: SiteOption) => void;
  tooltips?: { [option: string]: string };
};

const PermissionsGrid = ({ user, options, onChange, tooltips }: PermissionsGridProps) => {
  const toggle = (option: SiteOption, state: ThreeStateCheck) => {
    return (
      <PermissionToggle
        key={option}
        user={user}
        option={option as SiteOption}
        state={state}
        onChange={onChange}
      />
    );
  };

  return (
    <SimpleGrid data-cypress="permissions-grid" columns={7}>
      {Object.entries(options).map(([option, state]) => {
        if (tooltips && Object.keys(tooltips).includes(option)) {
          return (
            <Tooltip key={option} label={tooltips[option]}>
              <Box>{toggle(option as SiteOption, state)}</Box>
            </Tooltip>
          );
        } else {
          return toggle(option as SiteOption, state);
        }
      })}
    </SimpleGrid>
  );
};

export default PermissionsGrid;
