import { Center, Text } from '@chakra-ui/react';
import { RecoveryFlow, UpdateRecoveryFlowBody } from '@ory/client';
import RecoveryForm from 'components/Forms/RecoveryForm';
import FrontPage from 'components/Pages/FrontPage';
import { SessionContext } from 'contexts/SessionContext';
import useQueryParams from 'hooks/useQueryParams';
import ory from 'ory';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'shared/LocalStorage';

const Recovery = () => {
  const [flow, setFlow] = useState<RecoveryFlow | null>(null);
  const [csrf, setCsrf] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_forcePasswordReset, setForcePasswordReset] = useLocalStorage('forcePasswordReset', false);
  const [recoveryError, setRecoveryError] = useState<string | null>(null);
  const searchParams = useQueryParams();
  const navigate = useNavigate();
  const session = useContext(SessionContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (session?.id) {
      navigate('/');
    }
  }, [session, navigate]);

  // Kinda hacky, but since I don't want to use the ui library, but need the csrf token...
  useEffect(() => {
    if (flow?.ui?.nodes) {
      //@ts-ignore
      const csrfNodes = flow.ui.nodes.filter((n) => n.attributes?.name === 'csrf_token');
      if (csrfNodes.length > 0) {
        //@ts-ignore
        setCsrf(csrfNodes[0].attributes.value);
      }
    }
  }, [flow, flow?.ui?.nodes]);

  const getFlow = useCallback(
    (flowId: string) =>
      ory
        .getRecoveryFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch((err) => {
          console.error(err);
          setRecoveryError(err);
          return err;
        }),
    []
  );

  useEffect(() => {
    // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
    const flowId = searchParams['flow'];
    // the flow already exists
    if (flowId) {
      getFlow(flowId); // if for some reason the flow has expired, we need to get a new one
      return;
    }
  }, [getFlow, searchParams]);

  const submitFlow = (body: UpdateRecoveryFlowBody) => {
    // something unexpected went wrong and the flow was not set
    if (!flow) return navigate('/login', { replace: true });

    ory
      .updateRecoveryFlow({ flow: flow.id, updateRecoveryFlowBody: body })
      .catch((error) => {
        console.error(error);
        setFlow(error?.response?.data);
        setRecoveryError(error?.response?.data);
        history.go(0);
      })
      .finally(() => {
        setForcePasswordReset(true);
        navigate('/', { replace: true });
      });
  };

  const recover = async ({ code }) => {
    setIsLoading(true);

    await submitFlow({
      code,
      method: 'code',
      csrf_token: csrf
    });

    setIsLoading(false);
  };

  return (
    <FrontPage>
      <Center>
        <Text fontSize="4xl">Account Recovery</Text>
      </Center>
      <RecoveryForm onSubmit={recover} recoveryError={recoveryError} isLoading={isLoading} />
    </FrontPage>
  );
};

export default Recovery;
