import { gql, useQuery } from '@apollo/client';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Card, CardBody, CardHeader, CardProps, HStack, IconButton, Text } from '@chakra-ui/react';
import ConfirmDeleteAlert from 'components/Modals/ConfirmDeleteAlert';
import { GetSiteAlarmDetailsQuery } from 'graphql/generated';
import { AlarmGroupSelection } from 'pages/project/[Id]/Alarm/Index';
import { useState } from 'react';
import AlarmTable from './AlarmTable';

const GET_SITE_ALARM_DETAILS = gql`
  query GetSiteAlarmDetails($alarmGroupId: Int!) {
    siteAlarms(alarmGroupId: $alarmGroupId) {
      id
      lastCheck {
        id
        state
        checkedAt
        errorMessage
      }
      lastAlert {
        id
        checkedAt
      }
    }
  }
`;

export const AlarmGroupCard = ({
  alarmGroup,
  onSiteAlarmSelect,
  onSiteAlarmSuspend,
  onAlarmGroupEdit,
  onAlarmGroupDelete,
  ...props
}: {
  alarmGroup: AlarmGroupSelection;
  onSiteAlarmSelect: (siteAlarm: { siteId: number }) => void;
  onSiteAlarmSuspend: (siteAlarm: {
    siteAlarmId: number;
    alarmGroupId: number;
    suspend: boolean;
  }) => void;
  onAlarmGroupEdit: () => void;
  onAlarmGroupDelete: () => void;
} & CardProps) => {
  const { data, loading, error } = useQuery<GetSiteAlarmDetailsQuery>(GET_SITE_ALARM_DETAILS, {
    variables: { alarmGroupId: alarmGroup.id }
  });

  const [deletingAlarm, setDeletingAlarm] = useState<AlarmGroupSelection | null>();
  return (
    <Card {...props}>
      <CardHeader>
        <HStack w="100%" justifyContent="space-between">
          {onAlarmGroupEdit && (
            <IconButton
              data-cypress="alarm-group-edit-btn"
              size="sm"
              onClick={onAlarmGroupEdit}
              colorScheme="blue"
              icon={<EditIcon />}
              aria-label={'Edit Alarm Group'}
            />
          )}
          <Text fontSize="2xl">{alarmGroup.name}</Text>
          {onAlarmGroupDelete && (
            <IconButton
              data-cypress="alarm-group-delete-btn"
              size="sm"
              onClick={() => setDeletingAlarm(alarmGroup)}
              colorScheme="red"
              icon={<DeleteIcon />}
              aria-label={'Delete Alarm Group'}
            />
          )}
        </HStack>
      </CardHeader>
      <CardBody>
        <AlarmTable
          siteAlarms={alarmGroup.siteAlarms.map((s) => ({
            id: s.id,
            name: s.site.name,
            mostRecentCheck: loading
              ? 'loading'
              : error
                ? 'error'
                : data?.siteAlarms.find((sa) => sa.id === s.id)?.lastCheck,
            lastInAlert: loading
              ? 'loading'
              : error
                ? 'error'
                : data?.siteAlarms.find((sa) => sa.id === s.id)?.lastAlert?.checkedAt,
            alarmGroupId: alarmGroup.id,
            suspended: s.suspended,
            siteId: s.site.id
          }))}
          onRowClick={(sa) => onSiteAlarmSelect({ siteId: sa.siteId })}
          onToggleSuspend={(sa) => onSiteAlarmSuspend(sa)}
          type="alarmGroup"
        />
      </CardBody>
      <ConfirmDeleteAlert
        isOpen={!!deletingAlarm}
        dialogHeader={<Text>Delete Alarm Group?</Text>}
        onClose={() => setDeletingAlarm(null)}
        onVerifyDelete={() => {
          onAlarmGroupDelete();
          setDeletingAlarm(null);
        }}
      />
    </Card>
  );
};
