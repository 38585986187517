import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import { BinaryFilter, TCubeDimension, TCubeMeasure } from '@cubejs-client/core';
import FormNumberInput from 'components/Forms/FormNumberInput';
import FormSelect from 'components/Forms/FormSelect';
import { sortBy } from 'lodash';
import { useEffect } from 'react';
import { UseControllerProps, useFieldArray } from 'react-hook-form';
import AdvancedFilter from './AdvancedFilter';
import { EditAlarmForm } from './EditAlarmModal';

type ThresholdEditorProps = {
  measures: TCubeMeasure & { original: TCubeMeasure }[];
  timeDimension?: TCubeDimension;
  sublocations?: string[];
} & UseControllerProps<EditAlarmForm, 'thresholds'>;

const supportedOperators = [
  { label: '<=', value: 'lte' },
  { label: '>=', value: 'gte' }
];

const ThresholdEditor = ({
  control,
  name,
  measures,
  timeDimension,
  sublocations
}: ThresholdEditorProps) => {
  const { fields, update, append, remove } = useFieldArray<EditAlarmForm, 'thresholds'>({
    control,
    name,
    //@ts-ignore
    keyName: 'fieldId'
  });
  // Hack in time dimension when a field is added
  useEffect(() => {
    fields.forEach((f, i) => {
      update(i, { ...f, timeDimension: timeDimension?.name });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.length, timeDimension]);

  const toOption = (measure): { label: string; value: string } => ({
    label: measure.original.title,
    value: measure.name
  });

  const measureOptions = measures
    ? sortBy(
        measures.map((m) => toOption(m)),
        'label'
      )
    : [];

  const onFilterChange = (index: number, filters: BinaryFilter[]) => {
    update(index, { ...fields[index], additionalFilters: filters });
  };

  return (
    <VStack w="100%">
      {fields.map((threshold, i) => {
        return (
          <Box w="100%" key={threshold.id}>
            {i !== 0 && <Text textAlign="center">And</Text>}
            <HStack w="100%">
              <FormSelect
                data-cypress="alarm-measure-select"
                w="100%"
                placeholder="Select a measure..."
                control={control}
                onChange={(e) => {
                  update(i, { ...threshold, measure: e.value, additionalFilters: [] });
                }}
                value={measureOptions.find((mo) => mo.value === threshold.measure)}
                name={`${name}.${i}.measure`}
                options={measureOptions}
                label="Measure"
                rules={{ required: true }}
              />
              <FormSelect
                w="100px"
                control={control}
                name={`${name}.${i}.operator`}
                onChange={(e) => update(i, { ...threshold, operator: e.value as 'gte' | 'lte' })}
                options={supportedOperators}
                value={supportedOperators.find((o) => o.value === threshold.operator)}
                label="Operator"
              />
              <FormNumberInput
                w="200px"
                label="Alarm Threshold"
                control={control}
                name={`${name}.${i}.value`}
                precision={2}
                step={0.01}
                rules={{ required: true }}
                onChange={(e) => {
                  update(i, { ...threshold, value: e });
                }}
              />
              <IconButton
                data-cypress={`delete-threshold-${i}`}
                isDisabled={fields.length === 1}
                colorScheme="red"
                icon={<DeleteIcon />}
                onClick={() => remove(i)}
                aria-label={'Delete Threshold'}
              />
            </HStack>
            <AdvancedFilter
              sublocations={sublocations}
              measure={threshold.measure}
              filters={threshold.additionalFilters}
              onFieldChange={(filters) => onFilterChange(i, filters)}
            />
          </Box>
        );
      })}
      <Button
        data-cypress="add-threshold"
        leftIcon={<AddIcon />}
        onClick={() =>
          append({
            id: null,
            measure: '',
            operator: 'gte',
            value: '0',
            timeDimension: timeDimension?.name,
            additionalFilters: []
          })
        }>
        Add Threshold
      </Button>
    </VStack>
  );
};

export default ThresholdEditor;
