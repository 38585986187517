import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Center,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  StyleProps
} from '@chakra-ui/react';
import React, { HTMLAttributes } from 'react';

const InfoPopover: React.FC<StyleProps & Omit<HTMLAttributes<HTMLDivElement>, 'color'>> = ({
  children,
  ...base
}) => {
  return (
    <Center fontSize="md" {...base} display="inline">
      <Popover trigger="hover">
        <PopoverTrigger>
          <InfoOutlineIcon />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody textAlign="left">{children}</PopoverBody>
        </PopoverContent>
      </Popover>
    </Center>
  );
};

export default InfoPopover;
