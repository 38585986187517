import { AccordionButton, AccordionItem, AccordionPanel, Checkbox } from '@chakra-ui/react';
import { SiteOption, User, permissions } from '@scoot/permissions';
import { ThreeStateCheck } from 'components/Forms/ThreeStateCheckbox';
import { Site } from 'graphql/generated';
import PermissionsGrid from './PermissionsGrid';
import './SitePermissions.css';

type SitePermissionsProps = {
  site: Site;
  user: User;
  options: { [option: string]: ThreeStateCheck };
  onChange: (option: SiteOption, siteId: number) => void;
};

const SitePermissions = ({ site, user, options, onChange }: SitePermissionsProps) => {
  const onPermissionChange = (option: SiteOption) => {
    onChange(option, site.id);
  };

  return (
    <AccordionItem className="site-row">
      <AccordionButton>
        <Checkbox
          data-cypress={`view-site-${site.id}`}
          onChange={() => onChange('read', site.id)}
          isChecked={permissions.canViewSite(user, site)}
          mr="5px"
          size="lg"
          colorScheme="green"
          borderColor="red.300"
        />
        {site.name}
      </AccordionButton>
      <AccordionPanel>
        <PermissionsGrid user={user} options={options} onChange={onPermissionChange} />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default SitePermissions;
