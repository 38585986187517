import { BinaryFilter, Query } from '@cubejs-client/core';
import CustomChart from 'components/Charts/Custom/Chart';
import { ProjectContext } from 'contexts/ProjectContext';
import { add, max as dateMax, min as dateMin, isEqual, sub } from 'date-fns';
import { AlarmThreshold } from 'graphql/generated';
import { max, min } from 'lodash';
import { Data, Layout } from 'plotly.js';
import { useCallback, useContext, useMemo } from 'react';
import { TimeFragment } from '../Custom/TimeFragmentPicker';

type ChartProps = {
  timeFragment: TimeFragment;
  threshold: Omit<AlarmThreshold, 'alarmGroupId' | 'alarmGroup' | 'alarmStatusChecks'>;
  timeDimension: string;
  smbSiteIds: string[];
};

const Chart = ({ timeFragment, threshold, timeDimension, smbSiteIds }: ChartProps) => {
  const project = useContext(ProjectContext);

  const addThresholdLine = useCallback(
    ({ data, layout }: { data: Data[]; layout: Partial<Layout> }) => {
      //@ts-ignore
      const xData = data.flatMap((d) => d.x).map((d) => new Date(d));
      //@ts-ignore
      const yData = data.flatMap((d) => d.y);
      if (!xData || !yData) {
        return { data, layout };
      }

      const minMaxX = [dateMin(xData), dateMax(xData)];
      const minMaxY = [Number(min(yData)), Number(max(yData))];

      if (!threshold.measure) return;
      data.push({
        type: 'scatter',
        mode: 'lines',
        x: minMaxX,
        y: [threshold.value, threshold.value],
        line: { dash: 'dash', color: 'red' },
        name: 'Threshold'
      });

      const thresholdLowerThanLowest = Number(threshold.value) <= Number(minMaxY[0]);
      const thresholdHigherThanHighest = Number(threshold.value) >= Number(minMaxY[1]);

      const edgeBuffer = (Number(minMaxY[1]) - Number(minMaxY[0])) * 0.1;

      let y1 = 0;

      if (thresholdLowerThanLowest && threshold.operator === 'lte') {
        y1 = Number(threshold.value) - edgeBuffer;
      } else if (thresholdHigherThanHighest && threshold.operator === 'gte') {
        y1 = Number(threshold.value) + edgeBuffer;
      } else {
        y1 = threshold.operator === 'gte' ? Number(minMaxY[1]) : Number(minMaxY[0]);
      }

      let x0 = minMaxX[0];
      let x1 = minMaxX[1];
      if (isEqual(minMaxX[0], minMaxX[1])) {
        x0 = sub(minMaxX[0], { minutes: 1 });
        x1 = add(minMaxX[1], { minutes: 1 });
      }

      const shape = {
        type: 'rect',
        xref: 'x',
        yref: 'y',
        x0,
        y0: threshold.value,
        x1,
        y1,
        fillcolor: '#EE4B2B',
        opacity: 0.3,
        line: {
          width: 0
        }
      };

      //@ts-ignore
      layout.shapes = layout.shapes ? [...layout.shapes, shape] : [shape];

      return { data, layout };
    },
    [threshold]
  );
  const query: Query = useMemo(() => {
    if (!threshold.measure) return;
    if (!timeDimension) return null;
    return {
      measures: [threshold.measure],
      dimensions: threshold.measure.includes('TessRivers')
        ? ['TessRivers.riverStationName']
        : ['Site.name'],
      timeDimensions: [
        {
          dimension: timeDimension,
          ...timeFragment
        }
      ],
      filters: threshold.measure.includes('TessRivers')
        ? [
            //@ts-ignore
            ...threshold.additionalFilters.filter((f) => f)
          ]
        : [
            {
              member: 'Site.id',
              operator: 'equals',
              values: smbSiteIds
            } as BinaryFilter,
            //@ts-ignore
            ...threshold.additionalFilters.filter((f) => f)
          ],
      limit: 5000,
      timezone: project.timezone
    };
  }, [project.timezone, smbSiteIds, threshold, timeDimension, timeFragment]);

  return (
    <CustomChart
      {...timeFragment}
      //@ts-ignore
      settings={{
        chartType: 'line',
        query,
        fillZeros: false,
        displayName: 'Alarm',
        showLegend: false,
        xaxisTitle: 'Time',
        //@ts-ignore
        beforePlot: addThresholdLine
      }}
    />
  );
};

export default Chart;
