import { Text, VStack } from '@chakra-ui/react';
import {
  EquipmentNumberState,
  EquipmentSelectionState,
  EquipmentState,
  EquipmentTextState
} from 'graphql/generated';
import { UseControllerProps, useController } from 'react-hook-form';
import { EquipmentEntryForm } from '../EquipmentEntryModal';
import { EquipmentNumberEntry } from './EquipmentNumberEntry';
import { EquipmentSelectionEntry } from './EquipmentSelectionEntry';
import { EquipmentTextEntry } from './EquipmentTextEntry';

export const EquipmentEntryStateRouter = (
  props: UseControllerProps<EquipmentEntryForm, `states`> & { state: EquipmentState }
) => {
  const { field, fieldState } = useController<EquipmentEntryForm, `states`>(props);

  const getEntry = () => {
    switch (props.state.__typename) {
      case 'EquipmentSelectionState':
        return (
          <EquipmentSelectionEntry {...props} state={props.state as EquipmentSelectionState} />
        );
      case 'EquipmentNumberState':
        return <EquipmentNumberEntry {...props} state={props.state as EquipmentNumberState} />;
      case 'EquipmentTextState':
        return <EquipmentTextEntry {...props} state={props.state as EquipmentTextState} />;
      default:
        return (
          <>
            {/*
            // @ts-ignore */}
            <p>Handler not found for {field?.value?.__typename}</p>
          </>
        );
    }
  };

  return (
    <VStack w="100%">
      {getEntry()}
      {fieldState?.error?.[props.state.id] && (
        <Text color="red.500">{fieldState.error[props.state.id].message}</Text>
      )}
    </VStack>
  );
};
