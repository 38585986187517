import React from 'react';
import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import { WEATHER_PLACEHOLDER } from './Weather';
import { WiThermometer } from 'weather-icons-react';

const AirTemperature = ({ value }: { value: number | string }) => {
  return (
    <Box textAlign="center">
      <Tooltip label="Air Temperature (avg. next 24 hrs)">
        {value === WEATHER_PLACEHOLDER ? (
          <Text>{WEATHER_PLACEHOLDER}</Text>
        ) : (
          <HStack>
            <Box color="blue.600">
              <WiThermometer size={40} />
            </Box>
            {/* Kelvin to C */}
            <Text>{Math.round((value as number) - 273.15)}&#8451;</Text>
          </HStack>
        )}
      </Tooltip>
    </Box>
  );
};

export default AirTemperature;
