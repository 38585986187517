import { CardBody, Input, Text, VStack } from '@chakra-ui/react';
import { EquipmentAdminForm } from 'pages/project/[Id]/Admin/Equipment/EquipmentAdmin';
import { UseControllerProps, useController } from 'react-hook-form';

export const ActionEditor = (
  props: UseControllerProps<EquipmentAdminForm, `equipmentTypes.${number}.actions.${number}`>
) => {
  const { fieldState } = useController<
    EquipmentAdminForm,
    `equipmentTypes.${number}.actions.${number}`
  >(props);

  return (
    <CardBody>
      <VStack w="100%">
        <Input
          data-cypress="action-name"
          placeholder="Action Name..."
          {...props.control.register(`${props.name}.name`, {
            required: 'A name is required.',
            minLength: 1
          })}
        />
        <Input
          placeholder="Optional Description..."
          {...props.control.register(`${props.name}.description`)}
        />
        {fieldState?.error?.['name'] && (
          <Text color="red.500">{fieldState?.error?.['name']?.message}</Text>
        )}
      </VStack>
    </CardBody>
  );
};
