import { useQuery } from '@apollo/client';
import { Center } from '@chakra-ui/react';
import apiClient from 'ApiClient';
import { UserLoginError } from 'components/UserLoginError';
import { createContext, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import { User } from '../graphql/generated';
import { CURRENT_USER } from '../graphql/globalQueries';
import { SessionContext, UNAUTH_ROUTES } from './SessionContext';

export const UserContext = createContext<null | User>(null);

export const UserContextProvider = () => {
  const sessionContext = useContext(SessionContext);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (!UNAUTH_ROUTES.some((r) => location.pathname.includes(r))) {
      apiClient.get(`t?e=visit&p=${location?.pathname}`);
    }
  }, [location]);

  const {
    loading: isUserLoading,
    data: userData,
    error: userError
  } = useQuery(CURRENT_USER, {
    skip: !sessionContext?.identity?.id,
    variables: {
      id: sessionContext?.identity?.id
    }
  });

  useEffect(() => {
    if (!isUserLoading && userData?.currentUser) {
      const user = {
        ...userData.currentUser,
        projects: userData.currentUser.organizations.flatMap((o) => o.projects)
      };
      setCurrentUser(user);
    }
  }, [isUserLoading, userData, sessionContext?.identity?.id]);

  if (UNAUTH_ROUTES.some((r) => location.pathname.includes(r))) {
    return <Outlet />;
  }

  if (userError) {
    return (
      <Center data-cypress="login-error" width={'100vw'} height={'100vh'}>
        <UserLoginError />
      </Center>
    );
  }

  return (
    <UserContext.Provider value={currentUser}>
      {isUserLoading && <></>}

      {currentUser && <Outlet />}
    </UserContext.Provider>
  );
};
