import { SearchIcon } from '@chakra-ui/icons';
import {
  Card,
  Divider,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';
import { ProjectContext } from 'contexts/ProjectContext';
import { groupBy, startCase, toLower, toUpper } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

const SiteSearch = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const project = useContext(ProjectContext);

  const [searchInput, setSearchInput] = useState('');

  const filteredSites = useMemo(() => {
    return searchInput
      ? project.sites.filter((s) => s.name.toLocaleLowerCase().includes(searchInput.toLowerCase()))
      : project.sites;
  }, [project.sites, searchInput]);

  const groupedSites = useMemo(() => {
    return groupBy(filteredSites, 'type');
  }, [filteredSites]);

  const typeToDisplay = (type: string) =>
    type === 'asc' ? toUpper(type) : startCase(toLower(type));

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="15px">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon mt="5px" color="blue.500" />
          </InputLeftElement>
          <Input
            tabIndex={1}
            value={searchInput}
            onChange={(e) => setSearchInput(e.currentTarget.value)}
            size="lg"
            border="none"
            placeholder="Site Search..."
          />
        </InputGroup>
        <>
          <Divider my="10px" />
          <List ml="0px" spacing="2" maxH="400px" overflowY="scroll">
            {Object.keys(groupedSites).map((siteType) => (
              <>
                <Heading fontSize="sm">{typeToDisplay(siteType)}</Heading>
                {groupedSites[siteType].map((s, i) => (
                  <Link
                    tabIndex={-1}
                    onClick={() => onClose()}
                    style={{ display: 'block' }}
                    key={s.id}
                    to={`/project/${project.id}/site/${s.id}`}>
                    <ListItem>
                      <Card
                        tabIndex={1 + i}
                        _hover={{ backgroundColor: 'blue.200' }}
                        _focus={{ backgroundColor: 'blue.200' }}
                        _active={{ backgroundColor: 'blue.200' }}
                        p="15px"
                        backgroundColor="gray.200">
                        {s.name}
                      </Card>
                    </ListItem>
                  </Link>
                ))}
              </>
            ))}
          </List>
        </>
      </ModalContent>
    </Modal>
  );
};

export { SiteSearch };
