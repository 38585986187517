const canAdminProject = (user, pid) => {
  if (user.superuser) return true;
  return user.projectAdministrator.includes(pid);
};

const permissions = {
  //default permissions object that applies to each user, the permissions
  //string is translated into this format with the createPermissionsObject
  //in the api/DashboardDatabase/DashboardDatabase.js file
  permissionsObject: {
    superuser: false,
    organizations: [],
    projects: [],
    projectAdministrator: [],
    alerts: [],
    asc: [],
    manualHydrography: [],
    read: [],
    events: [],
    equipment: [],
    plankton: [],
    seaLice: [],
    alarmSites: [],
  },

  //components of the permissions object that are at a site level
  siteOptions: [
    "asc",
    "manualHydrography",
    "read",
    "events",
    "equipment",
    "plankton",
    "seaLice",
  ],

  //components of the permissions object that are at a project level
  projectOptions: ["admin", "alerts"],

  //components of the permissions object that apply generally to user
  userOptions: ["superuser", "organizations", "userProjects"],

  //functions for checking user permission in the api or on the client:
  isSuperuser: function (user) {
    return user.superuser;
  },

  isPartOfProject: function (user, projectId) {
    if (this.canAdminProject(user, projectId)) return true;
    return user.projects.includes(projectId);
  },

  isPartOfOrg: function (user, oid) {
    if (user.superuser) return true;
    return user.organizations.includes(oid);
  },

  canAdminProject: (user, pid) => canAdminProject(user, pid),

  canManageAlertsForProject: function (user, pid) {
    if (canAdminProject(user, pid)) return true;
    return user.alerts.includes(pid);
  },

  canViewSite: function (user, { id, projectId }) {
    if (canAdminProject(user, projectId)) return true;
    return user.read.includes(id);
  },

  canManageEventsForSite: function (user, { id, projectId }) {
    if (canAdminProject(user, projectId)) return true;
    return user.events.includes(id);
  },

  canSubmitPlanktonForSite: function (user, { id, projectId }) {
    if (canAdminProject(user, projectId)) return true;
    return user.plankton.includes(id);
  },

  canSubmitSeaLiceForSite: function (user, { id, projectId }) {
    if (canAdminProject(user, projectId)) return true;
    return user.seaLice.includes(id);
  },

  canSubmitEquipmentForSite: function (user, { id, projectId }) {
    if (canAdminProject(user, projectId)) return true;
    return user.equipment.includes(id);
  },

  canSubmitASCForSite: function (user, { id, projectId }) {
    if (canAdminProject(user, projectId)) return true;
    return user.asc.includes(id);
  },

  canSubmitManualHydrographyForSite: function (user, { id, projectId }) {
    if (canAdminProject(user, projectId)) return true;
    return user.manualHydrography.includes(id);
  },

  hasNoSiteAccess: (user) => {
    return (
      user.asc.length === 0 &&
      user.manualHydrography.length === 0 &&
      user.events.length === 0 &&
      user.equipment.length === 0 &&
      user.plankton.length === 0 &&
      user.seaLice.length === 0 &&
      user.read.length === 0
    );
  },
};

module.exports = { permissions };
