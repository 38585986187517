import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { TabErrorBoundary } from 'components/Layouts/SiteLayout';
import { siteTabs } from 'components/Site/Tabs/tabs';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { Site } from 'graphql/generated';
import { useContext } from 'react';
import { Navigate, Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';

const TabId = () => {
  const { tabId, paneId } = useParams();
  const site = useOutletContext<Site>();
  const project = useContext(ProjectContext);
  const user = useContext(UserContext);

  //Probably a better way of doing this...
  const tabUrl = (tab: string) => `/project/${project.id}/site/${site.id}/${tab}`;
  const paneUrl = (paneId: string) => `/project/${project.id}/site/${site.id}/${tabId}/${paneId}`;
  const navigate = useNavigate();

  const tabList = siteTabs(site, user);
  const tab = tabList.find((tab) => tab.id === tabId);

  const selectedIdx = tabList.findIndex((tt) => tt.id === tabId);

  //Tab not found
  if (selectedIdx === -1) {
    return <Navigate to={tabUrl(tabList[0].id)} replace={true} />;
  }

  const defaultPane = tab.panes?.[0];

  if (!tab.element && !paneId && defaultPane) {
    return <Navigate to={`${tabUrl(tab.id)}/${defaultPane.id}`} replace={true} />;
  }

  return (
    <Tabs index={selectedIdx} defaultIndex={0}>
      <TabList mb="20px">
        {tabList.map((tab) => (
          <Tab
            onClick={() => navigate(tabUrl(tab.id))}
            key={tab.id}
            data-cypress={`site-tab-${tab.displayName.toLowerCase().replace(' ', '-')}`}>
            {tab.displayName}
          </Tab>
        ))}
      </TabList>

      {tab.panes?.length > 1 && (
        <Tabs
          variant="soft-rounded"
          align="center"
          size="lg"
          colorScheme="gray"
          mb="20px"
          index={tab.panes.map((p) => p.id).indexOf(paneId)}>
          <TabList>
            {tab.panes.map((pane) => (
              <Tab
                data-cypress={`site-tab-${tab.displayName.toLowerCase().replace(' ', '-')}-${
                  pane.id
                }`}
                key={pane.id}
                onClick={() => navigate(paneUrl(pane.id))}>
                {pane.displayName}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      )}

      {/*
          // @ts-ignore */}
      <TabErrorBoundary>
        {/*
          // @ts-ignore */}
        {tab.element ? <tab.element project={project} site={site} /> : <Outlet context={site} />}
      </TabErrorBoundary>
    </Tabs>
  );
};

export default TabId;
