import { gql, useQuery } from '@apollo/client';
import { Skeleton } from '@chakra-ui/react';
import { ChartErrorText } from 'components/Charts/ChartErrorText';
import { ReportListing } from 'components/ReportListing/ReportListing';
import { PlanktonReport, Site } from 'graphql/generated';
import moment from 'moment';
import { PlanktonDataCell } from './PlanktonDataCell';

export const GET_PLANKTON_REPORTS = gql`
  query getPlanktonReports($siteId: Int!) {
    reports: getPlanktonReports(siteId: $siteId) {
      id
      reportTime
      lastUpdated
      reportedBy
      siteId
      sublocation
      formInput
      transactionId
    }
  }
`;

interface PlanktonReportListProps {
  site: Site;
  openReportForm: (report: PlanktonReport) => void;
}

export function PlanktonReportList({ site, openReportForm }: PlanktonReportListProps) {
  const reportsQuery = useQuery<{
    reports: PlanktonReport[];
  }>(GET_PLANKTON_REPORTS, {
    variables: { siteId: site.id },
    pollInterval: 600000
  });

  const tableFilter = (rows, globalFilterValue) => {
    return rows.filter((row) => {
      const v = row.values;
      const filterString = [
        moment(v.reportTime).format('lll'),
        v.reportedBy,
        v.sublocation,
        moment(v.lastUpdated).format('lll'),
        v.formInput.counts.map((c) => c.species).join('|')
      ].join('|');
      return filterString.toLowerCase().indexOf(globalFilterValue.toLowerCase()) > -1;
    });
  };

  if (reportsQuery.error) {
    return (
      <div className={'flex flex-column items-center'}>
        <ChartErrorText />
      </div>
    );
  }

  if (reportsQuery.loading) {
    return <Skeleton h="800px" />;
  }

  return (
    <ReportListing
      reports={reportsQuery.data?.reports || []}
      openReportForm={openReportForm}
      addLocationColumn={true}
      userCanCreateNew={true}
      dataColumns={[
        {
          Header: 'Species Observed',
          accessor: 'formInput',
          width: 300,
          Cell: ({
            value
          }: {
            value: {
              numSlides: number;
              numQuadrants: number;
              counts: {
                species: string;
              }[];
            };
          }) => <PlanktonDataCell {...value} />
        }
      ]}
      tableFilter={tableFilter}
    />
  );
}
