import { createRoot } from 'react-dom/client';
import './index.css';
import { App } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Button, ChakraProvider, CSSReset } from '@chakra-ui/react';
import {
  createStandaloneToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  HStack,
  VStack
} from '@chakra-ui/react';
import { initializeSentry } from './initializeSentry';

initializeSentry();

const container = document.getElementById('root');
const root = createRoot(container);

const { ToastContainer, toast } = createStandaloneToast();

root.render(
  <ChakraProvider>
    {' '}
    <CSSReset /> <App /> <ToastContainer />
  </ChakraProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (window.Cypress) {
  // TODO - Revisit PWA and get working with Cypress.
  //  When on, the PWA never sends a 'load' event to cy. Not sure why. - MEA
  console.info('Unregistering service worker because Cypress was found');
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.register({
    // when new version of SeaState is available show a message
    onUpdate: (reg) => {
      const registrationWaiting = reg.waiting;
      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        registrationWaiting.addEventListener('statechange', (e) => {
          if (e.target.state === 'activated') {
            window.focus();
            //Chakra reference error???
            toast({
              title: 'Updates available',
              render: () => {
                return (
                  <Alert w="800px" status="info" variant="left-accent">
                    <VStack w="100%">
                      <HStack>
                        <AlertIcon mb="5px" />
                        <AlertTitle mr={1}>A new update is available!</AlertTitle>
                      </HStack>
                      <AlertDescription>
                        Please close all your SeaState tabs or refresh all your browser tabs in
                        order see updates.
                      </AlertDescription>
                      <Button w="100%" onClick={() => location.reload()}>
                        Refresh
                      </Button>
                    </VStack>
                  </Alert>
                );
              },
              variant: 'left-accent',
              position: 'bottom-left',
              status: 'info',
              // Show it for 15 minutes
              duration: 900000,
              isClosable: true
            });
          }
        });
      }
    }
  });
}
