import { Heading, SimpleGrid } from '@chakra-ui/react';
import FormNumberInput from 'components/Forms/FormNumberInput';
import { FieldDefinition } from 'contexts/ProjectContext';
import { UseControllerProps } from 'react-hook-form';
import { Form } from './EntryForm';

type ToteCountsProps = {
  liceStages: FieldDefinition[];
} & UseControllerProps<Form>;

const ToteCounts = ({ liceStages, ...props }: ToteCountsProps) => {
  return (
    <>
      <Heading size="md" mt="20px" mb="10px">
        Tote Counts
      </Heading>
      <SimpleGrid columns={4} spacing="10">
        {liceStages.map((liceStage) => {
          return (
            <FormNumberInput
              key={liceStage.value}
              //@ts-ignore
              name={`toteCounts.${liceStage.value}`}
              defaultValue={0}
              rules={{ required: false, min: 0 }}
              control={props.control}
              label={liceStage.label}
            />
          );
        })}
      </SimpleGrid>
    </>
  );
};

export default ToteCounts;
