import { Dictionary, groupBy } from 'lodash';
type siwiDatum = {
  /**
   * The input SIWI result datum. Due to the large number of params the param names had to be shorted to allow the query to go through
   *
   * The first part of the camel cased word is the indicator. Can be one of the following:
   * - s: 'siwi'
   * - m: 'mortality'
   * - sal: 'salinity'
   * - o: 'oxygen'
   * - a: 'appetite'
   * - l: 'lice'
   * - d: 'disturbance'
   * - pT: 'plankton Toxic'
   * - pM: 'plankton Mechanical'
   * - t: 'temperature'
   * - sto: 'stocking'
   *
   * The second part of the phrase is the parameter:
   * - T: 'total'
   * - E: 'Environmental'
   * - F: 'Fish'
   * - I: 'Individual'
   * - V: 'Value' - The raw value of the indicator (e.g. 12degC)
   * - W: 'Weight' - The indicators weighting (weight * score = contribution to overall score)
   * - S: 'Score' - The indicators siwi score (0-100)
   * - M: 'Missing' - If the indicator is missing and has been interpolated
   * - KO: 'KO' - If the indicator is in a KO state
   * - U: 'Unit' - The unit of the indicator (e.g. lice per cm2)
   */
  'TessSiwi.measured_at': string;
  'TessSiwi.statusCode': string;
  'TessSiwi.cohortId': string;
  'TessSiwi.sT': number;
  'TessSiwi.sE': number;
  'TessSiwi.sF': number;
  'TessSiwi.sI': number;
  'TessSiwi.mV': number;
  'TessSiwi.salV': number;
  'TessSiwi.oV': number;
  'TessSiwi.aV': number;
  'TessSiwi.lV': number;
  'TessSiwi.dV': number;
  'TessSiwi.pTV': number;
  'TessSiwi.tV': number;
  'TessSiwi.stoV': number;
  'TessSiwi.pMV': number;
  'TessSiwi.mW': number;
  'TessSiwi.salW': number;
  'TessSiwi.oW': number;
  'TessSiwi.aW': number;
  'TessSiwi.lW': number;
  'TessSiwi.dW': number;
  'TessSiwi.pTW': number;
  'TessSiwi.tW': number;
  'TessSiwi.stoW': number;
  'TessSiwi.pMW': number;
  'TessSiwi.mS': number;
  'TessSiwi.salS': number;
  'TessSiwi.oS': number;
  'TessSiwi.aS': number;
  'TessSiwi.lS': number;
  'TessSiwi.dS': number;
  'TessSiwi.pTS': number;
  'TessSiwi.tS': number;
  'TessSiwi.stoS': number;
  'TessSiwi.pMS': number;
  'TessSiwi.mM': boolean;
  'TessSiwi.salM': boolean;
  'TessSiwi.oM': boolean;
  'TessSiwi.aM': boolean;
  'TessSiwi.lM': boolean;
  'TessSiwi.dM': boolean;
  'TessSiwi.pTM': boolean;
  'TessSiwi.tM': boolean;
  'TessSiwi.stoM': boolean;
  'TessSiwi.pMM': boolean;
  'TessSiwi.mKO': boolean;
  'TessSiwi.salKO': boolean;
  'TessSiwi.oKO': boolean;
  'TessSiwi.aKO': boolean;
  'TessSiwi.lKO': boolean;
  'TessSiwi.dKO': boolean;
  'TessSiwi.pTKO': boolean;
  'TessSiwi.tKO': boolean;
  'TessSiwi.stoKO': boolean;
  'TessSiwi.pMKO': boolean;
  'TessSiwi.mU': string;
  'TessSiwi.salU': string;
  'TessSiwi.oU': string;
  'TessSiwi.aU': string;
  'TessSiwi.lU': string;
  'TessSiwi.dU': string;
  'TessSiwi.pTU': string;
  'TessSiwi.tU': string;
  'TessSiwi.stoU': string;
  'TessSiwi.pMU': string;
};

type siwiStructure = {
  [indicator: string]: {
    [measuredAt: string]: {
      value: number;
      score: number;
      weight: number;
      units: string;
      ko: boolean;
      missing: boolean;
      statusCode: string;
    };
  };
};

const categoryMap = {
  mortality: ['m'],
  environmental: ['o', 't', 'pT', 'pM', 'l', 'sal'],
  operational: ['a', 'd', 'sto']
};

const statusCodeMap: {
  [status_code: string]: { level: 'ok' | 'warning' | 'error'; readable: string };
} = {
  input_missing_hydro: {
    level: 'error',
    readable: 'Input Missing Hydro'
  },
  qc_hydro_failure: {
    level: 'error',
    readable: 'QC Hydro Failure'
  },
  cannot_fill_intermediate_data_because_no_hist_ind_data: {
    level: 'error',
    readable: 'Missing Historic Indicator Data'
  },
  cannot_fill_intermediate_data_because_hist_ind_data_has_been_filled: {
    level: 'error',
    readable: 'Filled Historic Indicator Data'
  },
  cannot_calculate_indicator_score: {
    level: 'error',
    readable: 'Cannot Calculate Indicator Score'
  },
  indicator_data_missing: {
    level: 'ok',
    readable: 'Indicator Data Missing'
  },
  cannot_calculate_longterm_ko: {
    level: 'warning',
    readable: 'Cannot Calculate Long Term KO'
  },
  ok: { level: 'ok', readable: 'Ok' },
  using_manual_barge_data: { level: 'warning', readable: 'Using Manual Barge Data' },
  using_hi_res_barge_data: { level: 'warning', readable: 'Using Hi Res Barge Data' },
  site_fallow: { level: 'error', readable: 'Site Fallow' }
};

const calculateCategoryContribution = (
  data: any,
  category: string,
  cubeName: 'LatestSiwi' | 'TessSiwi'
): number => {
  // Sum up the indicator contributions for the indicators in the category
  return categoryMap[category].reduce((subAcc, indicator: string) => {
    return (
      subAcc +
      (data[`${cubeName}.${indicator}KO`] ? 100 : 100 - data[`${cubeName}.${indicator}S`]) *
        data[`${cubeName}.${indicator}W`]
    );
  }, 0);
};

// This chooses which result in a day to use by grabbing the latest non empty result
const getDayIdx = (data: siwiDatum[], cubeName: 'LatestSiwi' | 'TessSiwi'): number => {
  return data
    .slice() // This is used to stop it from reversing inplace
    .reverse()
    .reduce((acc, result, idx) => {
      acc = result[`${cubeName}.sT`] === null ? acc : data.length - 1 - idx;
      return acc;
    }, 0);
};

const filterBestResultForDay = (
  data: siwiDatum[],
  cubeName: 'LatestSiwi' | 'TessSiwi',
  granularity = 'day'
): Dictionary<[siwiDatum, ...siwiDatum[]]> => {
  // Select the latest result in each day if it has a result otherwise select the earlier result
  const byMeasuredAt = groupBy(data, (d) => d[`${cubeName}.measuredAt.${granularity}`]);
  return Object.keys(byMeasuredAt).reduce((acc, measuredAt: string) => {
    acc[measuredAt] = byMeasuredAt[measuredAt][getDayIdx(byMeasuredAt[measuredAt], cubeName)];
    return acc;
  }, {});
};

const isFallow = (data: siwiDatum[], cubeName: 'LatestSiwi' | 'TessSiwi'): boolean =>
  data[`${cubeName}.statusCode`] === 'site_fallow';

const fullDatumTransform = (
  data: siwiDatum[],
  //@ts-ignore
  dependencies,
  granularity = 'day'
): siwiStructure => {
  const cubeName = dependencies['cubeName'];
  // Get all the indicators by getting the letters in front of the 'Value' params
  const indicators = Object.keys(data[0] ?? [])
    .filter((key) => key.includes('V'))
    .map((x) => x.replace('V', '').replace(`${cubeName}.`, ''));

  // Create the SIWI structure for each indicator and measurement date.
  // First we are filtering the best result for a day as we only want one result each day
  const byMeasuredAt = filterBestResultForDay(data, cubeName, granularity);
  const output = indicators.reduce((acc, indicator: string) => {
    acc[indicatorMap[indicator]] = Object.keys(byMeasuredAt).reduce(
      (subAcc, measuredAt: string) => {
        // Check if the site is fallow for this measured at value
        if (isFallow(byMeasuredAt[measuredAt], cubeName)) {
          return subAcc;
        }
        subAcc[measuredAt] = {
          value:
            indicator === 'm'
              ? byMeasuredAt[measuredAt][`${cubeName}.${indicator}V`] * 100
              : byMeasuredAt[measuredAt][`${cubeName}.${indicator}V`],
          // Convert the score from a 'welfare' score to the 'stress' score (100 - welfareScore)
          score:
            statusCodeMap[byMeasuredAt[measuredAt][`${cubeName}.statusCode`]]['level'] != 'error'
              ? byMeasuredAt[measuredAt][`${cubeName}.${indicator}KO`] // Set KO to 100
                ? 100
                : 100 - byMeasuredAt[measuredAt][`${cubeName}.${indicator}S`]
              : null,
          weight: byMeasuredAt[measuredAt][`${cubeName}.${indicator}W`],
          units: byMeasuredAt[measuredAt][`${cubeName}.${indicator}U`],
          ko: byMeasuredAt[measuredAt][`${cubeName}.${indicator}KO`],
          missing: byMeasuredAt[measuredAt][`${cubeName}.${indicator}M`]
        };
        return subAcc;
      },
      {}
    );
    return acc;
  }, {});

  // Now create the siwi structure for each of the categories: Environmental, Operations, and Mortality which
  // are accumulations of different indicators
  Object.keys(categoryMap).forEach((category) => {
    output[indicatorMap[category]] = Object.keys(byMeasuredAt).reduce((acc, measuredAt: string) => {
      if (isFallow(byMeasuredAt[measuredAt], cubeName)) {
        return acc;
      }
      acc[measuredAt] = {
        value: undefined,
        score: calculateCategoryContribution(byMeasuredAt[measuredAt], category, cubeName),
        // Set to 1 so downstream calcs can still do score*weight and get the right result
        weight: 1,
        units: undefined,
        ko: categoryMap[category].some(
          (indicator) => output[indicatorMap[indicator]][measuredAt].ko
        ),
        missing: categoryMap[category].some(
          (indicator) => output[indicatorMap[indicator]][measuredAt].missing
        )
      };

      return acc;
    }, {});
  });

  // Now create the siwi structure for the total score which is mostly holding the total score and the overall status code
  output[indicatorMap.sT] = Object.keys(byMeasuredAt).reduce((acc, measuredAt: string) => {
    if (isFallow(byMeasuredAt[measuredAt], cubeName)) {
      return acc;
    }
    acc[measuredAt] = {
      value: undefined,
      score:
        statusCodeMap[byMeasuredAt[measuredAt][`${cubeName}.statusCode`]]['level'] != 'error'
          ? convertToIndex(byMeasuredAt[measuredAt][`${cubeName}.sT`])
          : null,
      weight: 1,
      units: undefined,
      statusCode: byMeasuredAt[measuredAt][`${cubeName}.statusCode`],
      ko: indicators.some((indicator) => output[indicatorMap[indicator]][measuredAt].ko)
    };

    return acc;
  }, {});

  // Sort the dates for each indicator/category/total
  [...indicators, ...Object.keys(categoryMap), 'sT'].forEach((key) => {
    output[indicatorMap[key]] = Object.keys(output[indicatorMap[key]])
      .sort((a, b) => (new Date(a) > new Date(b) ? 1 : -1))
      .reduce((subacc, measuredAt) => {
        subacc[measuredAt] = output[indicatorMap[key]][measuredAt];
        return subacc;
      }, {});
  });
  return output;
};

const siwiMeasures = (cubeName: 'LatestSiwi' | 'TessSiwi') => [
  `${cubeName}.lS`,
  `${cubeName}.tS`,
  `${cubeName}.oS`,
  `${cubeName}.mS`,
  `${cubeName}.dS`,
  `${cubeName}.salS`,
  `${cubeName}.pTS`,
  `${cubeName}.stoS`,
  `${cubeName}.pMS`,
  `${cubeName}.aS`,
  `${cubeName}.pMW`,
  `${cubeName}.dW`,
  `${cubeName}.tW`,
  `${cubeName}.pTW`,
  `${cubeName}.lW`,
  `${cubeName}.salW`,
  `${cubeName}.oW`,
  `${cubeName}.stoW`,
  `${cubeName}.mW`,
  `${cubeName}.aW`,
  `${cubeName}.sT`,
  `${cubeName}.sE`,
  `${cubeName}.sF`,
  `${cubeName}.sI`
];

const siwiDimensions = (cubeName: 'LatestSiwi' | 'TessSiwi') => [
  `${cubeName}.pMM`,
  `${cubeName}.dM`,
  `${cubeName}.lM`,
  `${cubeName}.tM`,
  `${cubeName}.salM`,
  `${cubeName}.pTM`,
  `${cubeName}.oM`,
  `${cubeName}.mM`,
  `${cubeName}.aM`,
  `${cubeName}.stoM`,
  `${cubeName}.pMKO`,
  `${cubeName}.dKO`,
  `${cubeName}.lKO`,
  `${cubeName}.tKO`,
  `${cubeName}.salKO`,
  `${cubeName}.pTKO`,
  `${cubeName}.oKO`,
  `${cubeName}.mKO`,
  `${cubeName}.aKO`,
  `${cubeName}.stoKO`,
  `${cubeName}.stoU`,
  `${cubeName}.salU`,
  `${cubeName}.dU`,
  `${cubeName}.pTU`,
  `${cubeName}.pMU`,
  `${cubeName}.mU`,
  `${cubeName}.aU`,
  `${cubeName}.tU`,
  `${cubeName}.lU`,
  `${cubeName}.oU`,
  `${cubeName}.mV`,
  `${cubeName}.salV`,
  `${cubeName}.oV`,
  `${cubeName}.aV`,
  `${cubeName}.lV`,
  `${cubeName}.dV`,
  `${cubeName}.pTV`,
  `${cubeName}.tV`,
  `${cubeName}.stoV`,
  `${cubeName}.pMV`,
  `${cubeName}.measuredAt`,
  `${cubeName}.statusCode`,
  `${cubeName}.cohortId`
];

const safeFormat = (value?: number, digits = 0, placeholder = '--') =>
  value != null && !Number.isNaN(value)
    ? Number(value.toFixed(digits)).toLocaleString()
    : placeholder;

// Convert from score to index. The result comes in as a "welfare score" which
// is a number within 0-100 (but is scaled 0-1) with 100 being the best value.
// Stress index is the inverse of this, so minus the score from 100
const convertToIndex = (value?: number) => (value || value == 0 ? 100 - value * 100 : null);

const categoryPrefix = 'cat_';
const indicatorList = [
  { abbrev: 'sto', full: 'Stocking' },
  { abbrev: 'd', full: 'Disturbance' },
  { abbrev: 'a', full: 'Appetite' },
  { abbrev: 'sal', full: 'Salinity' },
  { abbrev: 'l', full: 'Lice' },
  { abbrev: 'pM', full: 'Plankton Mechanical' },
  { abbrev: 'pT', full: 'Plankton Toxic' },
  { abbrev: 't', full: 'Temperature' },
  { abbrev: 'o', full: 'Oxygen' },
  { abbrev: 'm', full: 'Mortality' },
  { abbrev: 'sT', full: 'SIWI Stress Total' },
  { abbrev: 'operational', full: `${categoryPrefix}Operational` },
  { abbrev: 'environmental', full: `${categoryPrefix}Environmental` },
  { abbrev: 'mortality', full: `${categoryPrefix}Mortality` }
];

const indicatorMap: { [key: string]: string } = indicatorList.reduce(
  (acc, ind: { abbrev: string; full: string }) => {
    acc[ind.abbrev] = ind.full;
    return acc;
  },
  {}
);

const indicatorOrder = indicatorList.reduce(
  (acc, ind: { abbrev: string; full: string }, idx: number) => {
    acc[ind.full] = idx;
    return acc;
  },
  {}
);

const unitMap = {
  mean_mortality_percent: 'Mean Mortality (%)',
  salinity_ppt: 'Salinity (PSU)',
  'mean_pen_density_kg/m3': 'Mean Pen Density (kg/m3)',
  level: 'Level',
  lice_per_cm2: 'Lice Density (l/cm2)',
  daily_sfr: 'Daily SFR',
  temperature_c: 'Temperature (°C)',
  oxygen_sat_percent: 'Oxygen Saturation (%)'
};

export {
  siwiDatum,
  siwiStructure,
  safeFormat,
  convertToIndex,
  indicatorMap,
  unitMap,
  fullDatumTransform,
  siwiDimensions,
  siwiMeasures,
  indicatorOrder,
  calculateCategoryContribution,
  getDayIdx,
  filterBestResultForDay,
  statusCodeMap,
  categoryPrefix
};
