import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputProps,
  Text,
  useOutsideClick,
  VStack
} from '@chakra-ui/react';
import Loading from 'components/Loaders/Loading';
import Tile from 'components/Tile';
import React, { useRef, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import './FormSearchableInput.css';

type FormSearchableInputProps = {
  label: string;
  onImmediateChange?: InputProps['onChange'];
  options: { label: string; value: string }[];
  loading: boolean;
  searchPlaceholder: string;
} & UseControllerProps<any>;

const FormSearchableInput = ({
  label,
  onImmediateChange,
  options,
  loading,
  searchPlaceholder,
  ...controllerProps
}: FormSearchableInputProps) => {
  const { field, fieldState } = useController(controllerProps);
  const [showingOptions, setShowingOptions] = useState(false);
  const [highlightIndex, setHighlightIndex] = useState(null);
  const baseRef = useRef();
  const inputRef = useRef<HTMLInputElement>();
  useOutsideClick({ ref: baseRef, handler: () => setShowingOptions(false) });

  const handleOptionKeys = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' && showingOptions && highlightIndex !== null) {
      selectOption(options[highlightIndex]);
      setHighlightIndex(null);
      return;
    }

    if (e.key === 'ArrowDown' && showingOptions) {
      e.preventDefault();

      if (highlightIndex == null) {
        setHighlightIndex(0);
        return;
      } else if (highlightIndex < options?.length - 1) {
        setHighlightIndex(highlightIndex + 1);
      }
    } else if (e.key === 'ArrowUp' && showingOptions && highlightIndex > 0) {
      e.preventDefault();
      setHighlightIndex(highlightIndex - 1);
    }
  };

  const selectOption = (option: { label: string; value: string }) => {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    ).set;
    nativeInputValueSetter.call(inputRef.current, option.label);
    inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
    setShowingOptions(false);
  };

  return (
    <FormControl
      isInvalid={!!fieldState.error}
      isRequired={controllerProps.rules.required as boolean}
      ref={baseRef}>
      <FormLabel mb="0px">{label}</FormLabel>
      <Input
        minLength={controllerProps.rules.minLength as number}
        {...field}
        ref={(e) => {
          field.ref(e);
          inputRef.current = e;
        }}
        onChange={(e) => {
          setHighlightIndex(null);
          setShowingOptions(true);
          onImmediateChange(e);
          field.onChange(e);
        }}
        onFocus={() => setShowingOptions(true)}
        onBlur={() => {
          setShowingOptions(false);
          setHighlightIndex(null);
          field.onBlur();
        }}
        onKeyDown={handleOptionKeys}
      />
      {showingOptions && (
        <Tile
          zIndex={99999}
          position="absolute"
          left={0}
          top={16}
          w="300px"
          maxH="200px"
          overflowY="scroll"
          backgroundColor="white">
          {!loading && !(options?.length > 0) && <Text p="10px">{searchPlaceholder}</Text>}

          {loading && <Loading m="10px" />}

          {options?.length > 0 && (
            <VStack alignItems="start" spacing={0}>
              {options.map((option, i) => (
                <Box
                  className={`${i === highlightIndex && 'search-option--highlight'}`}
                  w="100%"
                  p="10px"
                  key={option.label}
                  //Use onMouseDown explicity due to focus issues with above out of area click
                  onMouseOver={() => setHighlightIndex(i)}
                  onMouseDown={() => selectOption(option)}>
                  <Text>{option.label}</Text>
                </Box>
              ))}
            </VStack>
          )}
        </Tile>
      )}
    </FormControl>
  );
};

export default FormSearchableInput;
