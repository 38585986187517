import TabHeadline from 'components/TabHeadline';
import { SeaLiceReport, Site } from 'graphql/generated';
import { useState } from 'react';
import { RiBug2Line } from 'react-icons/ri';
import EntryForm from '../Entry/EntryForm';
import { SeaLiceReportList } from './SeaLiceReportList';

interface SeaLiceDataEntryTabProps {
  site: Site;
}

const SeaLiceEntry = ({ site }: SeaLiceDataEntryTabProps) => {
  const [formIsVisible, setFormIsVisible] = useState(false);
  const [activeReport, setActiveReport] = useState<SeaLiceReport | null>();
  function handleReportFormOpen(report: SeaLiceReport | null) {
    setFormIsVisible(true);
    setActiveReport(report);
  }

  return (
    <>
      <TabHeadline
        text="Submit sea lice data directly to SeaState. Data will populate the Lice Charts under the Sea Lice tab and can be viewed and exported alongside other farm data in the Explore tab."
        icon={<RiBug2Line />}
      />
      <div data-cypress={'sea-lice-data-entry'}>
        <SeaLiceReportList site={site} openReportForm={handleReportFormOpen} />
        {formIsVisible && (
          <EntryForm site={site} report={activeReport} onClose={() => setFormIsVisible(false)} />
        )}
      </div>
    </>
  );
};

export default SeaLiceEntry;
