import { HStack } from '@chakra-ui/react';
import { Site } from 'graphql/generated';
import ChartSelect from '../ChartSelect';
import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';

export type ControllerInputs = {
  availableSites: Site[];
};

const Controller = ({
  setChartSettings,
  chartSettings,
  controllerSettings,
  chartInputs
}: ChartController<ChartSettings, ControllerInputs>) => {
  const locationOptions: { label: string; value: string }[] = chartInputs?.availableSites
    ? chartInputs.availableSites.map((s) => ({ label: s.name, value: s.smbId.toString() }))
    : [];

  return (
    <HStack alignItems="center" justifyContent="space-evenly">
      {locationOptions.length > 0 && controllerSettings?.showSublocations && (
        <ChartSelect
          width="200px"
          label="Sublocation"
          options={locationOptions}
          value={locationOptions.find((o) => o.value === chartSettings.site?.smbId?.toString())}
          onChange={(option: { label: string; value: string }) => {
            setChartSettings({
              ...chartSettings,
              site: chartInputs.availableSites.find((s) => s.smbId === Number(option.value))
            });
          }}
        />
      )}
      <ChartSwitchPanel
        switches={[
          {
            switchProps: { isChecked: chartSettings.showCategories },
            left: { value: 'individual', label: 'Individual' },
            right: { value: 'categories', label: 'Categories' },
            toggle: ({ checked }) => setChartSettings({ ...chartSettings, showCategories: checked })
          }
        ]}
      />
    </HStack>
  );
};

export default Controller;
