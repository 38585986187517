import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';

type RecoveryFormProps = {
  onSubmit: (form: any) => void;
  recoveryError: string | null;
  isLoading: boolean;
};

const RecoveryForm = ({ onSubmit, recoveryError, isLoading }: RecoveryFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        mt="30px"
        isInvalid={Object.keys(errors).length > 0 || recoveryError?.length > 0}>
        <FormLabel htmlFor="code">Recovery Code</FormLabel>
        <Input
          id="code"
          placeholder="Enter Recovery Code"
          {...register('code', { required: 'Please enter your recovery code.' })}
        />
        {errors?.code?.message ? (
          <FormErrorMessage>{errors.code.message}</FormErrorMessage>
        ) : (
          <Spacer h="25px" />
        )}
        <Button
          isLoading={isSubmitting || isLoading}
          w="100%"
          mt="25px"
          colorScheme="blue"
          type="submit">
          Submit
        </Button>
        {recoveryError ? <FormErrorMessage>{recoveryError}</FormErrorMessage> : <Spacer h="25px" />}
        <Center>
          {/* <Button mt="25px" variant="link" type="submit" isLoading={isSubmitting}>
            Forgot Password?
          </Button> */}
        </Center>
      </FormControl>
    </form>
  );
};

export default RecoveryForm;
