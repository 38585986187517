import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  CardBody,
  Divider,
  HStack,
  IconButton,
  Input,
  Text,
  VStack
} from '@chakra-ui/react';
import { ColorPicker } from 'components/Forms/ColorPicker';
import { EquipmentAdminForm } from 'pages/project/[Id]/Admin/Equipment/EquipmentAdmin';
import { ControllerFieldState, UseControllerProps, useFieldArray } from 'react-hook-form';

export const EquipmentSelectionType = (
  props: UseControllerProps<EquipmentAdminForm, `equipmentTypes.${number}.states.${number}`> & {
    fieldState: ControllerFieldState;
  }
) => {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: `${props.name}.options`,
    rules: {
      minLength: { value: 2, message: 'Selections must have at least 2 options.' },
      validate: (options) => {
        return options.every((o) => o.name.length > 0) ? true : 'All options must have text';
      }
    }
  });

  return (
    <CardBody>
      <VStack>
        <Input
          data-cypress="selection-state-name"
          placeholder="State Name..."
          isInvalid={!!props.fieldState?.error?.['name']}
          {...props.control.register(`${props.name}.name`, {
            required: 'A name is required.',
            minLength: 1
          })}
        />
        <Input
          placeholder="Optional Description..."
          {...props.control.register(`${props.name}.description`)}
        />
        {props?.fieldState?.error?.['name'] && (
          <Text color="red.500">{props.fieldState?.error?.['name'].message}</Text>
        )}
        <Divider my="10px" />
        {fields.map((f, i) => {
          return (
            <HStack w="100%" justifyContent="space-around" key={f.id}>
              <ColorPicker control={props.control} name={`${props.name}.options.${i}.color`} />
              <Input
                data-cypress={`selection-option-${i}`}
                placeholder="Option..."
                {...props.control.register(`${props.name}.options.${i}.name`)}
              />
              <IconButton
                data-cypress={`delete-selection-${i}`}
                colorScheme="red"
                icon={<DeleteIcon />}
                onClick={() => remove(i)}
                aria-label={'Delete Threshold'}
              />
            </HStack>
          );
        })}
        {props?.fieldState?.error?.['options'] && (
          <Text color="red.500">{props.fieldState?.error?.['options']?.root.message}</Text>
        )}
        <Button
          data-cypress={`add-selection-option`}
          // @ts-ignore
          onClick={() => append({ name: '', description: '', color: 'gray.500' })}
          variant={'outline'}
          colorScheme="green"
          leftIcon={<AddIcon />}>
          Add Option
        </Button>
      </VStack>
    </CardBody>
  );
};
