import { Box, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { WiWindDeg } from 'weather-icons-react';
import { WEATHER_PLACEHOLDER } from './Weather';

type WindProps = {
  u: number | string;
  v: number | string;
};

const windNotation = (degree: number) => {
  if (degree <= 23 || degree >= 337) {
    return 'N';
  }
  if (degree < 67 && degree > 23) {
    return 'NE';
  }
  if (degree <= 113 && degree >= 67) {
    return 'E';
  }
  if (degree < 157 && degree > 113) {
    return 'SE';
  }
  if (degree <= 203 && degree >= 157) {
    return 'S';
  }
  if (degree < 247 && degree > 203) {
    return 'SW';
  }
  if (degree <= 293 && degree >= 247) {
    return 'W';
  }
  return 'NW';
};

const Wind = ({ u, v }: WindProps) => {
  if (u === WEATHER_PLACEHOLDER || v === WEATHER_PLACEHOLDER) {
    return (
      <>
        <Text>Wind</Text>
        <Text>{WEATHER_PLACEHOLDER}</Text>
      </>
    );
  }

  const uWind = Number(u);
  const vWind = Number(v);

  // Some dark magic from a data scientist
  const degree = 180 + (180 / Math.PI) * Math.atan2(uWind, vWind);
  const speed = Math.round(Math.hypot(uWind, vWind) * 1.94384); //1 m/s = 1.94384 knots

  return (
    <VStack>
      <Tooltip label="Wind Direction and Speed (avg. next 24 hrs)">
        <HStack>
          <Box color="gray.400">
            <WiWindDeg size={40} transform={`rotate(${degree + 180})`} />
          </Box>
          <Text fontSize={'sm'}>({windNotation(degree)})</Text>
          <Text fontSize="sm">
            {speed} {speed === 1 ? 'knot' : 'knots'}
          </Text>
        </HStack>
      </Tooltip>
    </VStack>
  );
};

export default Wind;
