import { FormLabel, Input, Text } from '@chakra-ui/react';
import React from 'react';

type NewUserPaneProps = {
  disabled: boolean;
  email: string;
  setEmail: (email: string) => void;
};

const NewUserPane = ({ email, setEmail, disabled }: NewUserPaneProps) => {
  return (
    <>
      <Text>
        Please enter a valid email address and press &quot;Create&quot; to send an invitation for a
        new user to join your SeaState platform.
      </Text>
      <Text mt="10px">
        If the email and code expire before they log on the first time, they may select &quot;Forgot
        Password&quot; on the login form and follow the password reset process.
      </Text>
      <FormLabel mt="10px">Email</FormLabel>
      <Input
        data-cypress="new-user-email"
        disabled={disabled}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.currentTarget.value)}
      />
    </>
  );
};

export default NewUserPane;
