import { useState } from 'react';
import Chart from '../PlanktonDiscreteSample/Chart';
import { FormControl, FormLabel, HStack, Switch, Text } from '@chakra-ui/react';
import Select from 'react-select';
import { BaseChartProps } from '../types';

const DiscreteSampleCombo = (props: BaseChartProps) => {
  // Discrete summary
  const [discreteSummaryToggle, setDiscreteSummaryToggle] = useState(false);
  const [discreteSummarySublocation, setDiscreteSummarySublocation] = useState([
    { label: 'All', value: 'All' }
  ]);
  const handleChangeDiscreteSummary = (newValue) => {
    setDiscreteSummarySublocation(newValue);
    return newValue;
  };

  return (
    <Chart
      settings={{
        ...props.settings,
        sublocations: discreteSummarySublocation.map((l) => l.label),
        displayConcentration: discreteSummaryToggle
      }}
      control={({ availableSublocations }) => (
        <HStack mb="5px" alignContent="center" justify="center">
          <Text>Sublocations</Text>
          <Select
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                width: '300px',
                textAlign: 'left'
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                textAlign: 'left'
              })
            }}
            isMulti
            // defaultValue={[{ label: sublocations[1], value: sublocations[1] }]}
            value={discreteSummarySublocation}
            placeholder="Select Sublocation(s)"
            onChange={handleChangeDiscreteSummary}
            options={[
              { label: 'All', value: 'All' },
              ...availableSublocations.map((s) => ({ label: s, value: s }))
            ]}
          />
          <FormControl w="auto">
            <FormLabel display="inline" marginInlineEnd="0">
              Risk Threshold
            </FormLabel>
            <Switch
              id="group_sublocs_toggle"
              onChange={(e) => setDiscreteSummaryToggle(e.target.checked)}
              isChecked={discreteSummaryToggle}
              colorScheme="blue"
              ml="1rem"
              mr="1rem"
            />
            <FormLabel display="inline">Avg. Concentration</FormLabel>
          </FormControl>
        </HStack>
      )}
    />
  );
};

export default DiscreteSampleCombo;
