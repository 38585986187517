import { Project, User } from 'graphql/generated';
import { FC } from 'react';
import Environment from '../Site/Tabs/Environment/Environment';
import FishHealth from '../Site/Tabs/FishHealth/FishHealth';
import SeaLiceData from '../Site/Tabs/SeaLice/SeaLiceData';
import SeaLiceEntry from '../Site/Tabs/SeaLice/SeaLiceDataEntryTab/SeaLiceEntry';
// import Events from '../Site/Tabs/Events/Events';
// import Explore from '../Site/Tabs/Explore/Explore';
// import Forecast from '../Site/Tabs/Forecast/Forecast';
import Explore from 'components/Site/Tabs/Explore/Explore';
import { PlanktonData } from '../Site/Tabs/Plankton/PlanktonData';
// import SIWI from '../Site/Tabs/SIWI/SIWI';

type Tab<TProps = object> = {
  id: string;
  serverKey?: string;
  displayName: string;
  panes?: Pane[];
  element?: FC<TProps>;
  permission?: (user: User, project: Project) => boolean;
  alwaysInclude?: boolean;
};

type Pane<TProps = object> = {
  id: string;
  serverKey: string;
  displayName: string;
  element: FC<TProps>;
  permission?: (user: User, project: Project) => boolean;
};

const environmentTab = {
  id: 'environment',
  serverKey: 'Environment',
  displayName: 'Environment',
  element: Environment
};

const fishHealthTab = {
  id: 'fish-health',
  serverKey: 'Fish Health',
  displayName: 'Fish Health',
  element: FishHealth
};

const planktonDataPane = {
  id: 'data',
  serverKey: 'Plankton Data',
  displayName: 'Data',
  element: PlanktonData
};

const planktonTab = {
  id: 'plankton',
  displayName: 'Plankton',
  panes: [planktonDataPane]
};

const exploreTab = {
  id: 'explore',
  displayName: 'Explore',
  element: Explore,
  alwaysInclude: true
};

// const eventsTab = {
//   id: 'events',
//   serverKey: 'Events',
//   displayName: 'Events',
//   element: Events,
//   permission: permissions.canManageEventsForSite
// };

const seaLiceDataPane = {
  id: 'data',
  serverKey: 'Sea Lice Data',
  displayName: 'Data',
  element: SeaLiceData
};
const seaLiceEntryPane = {
  id: 'entry',
  serverKey: 'Sea Lice Entry',
  displayName: 'Entry',
  element: SeaLiceEntry
};
const seaLiceTab = {
  id: 'sea-lice',
  displayName: 'Sea Lice',
  panes: [seaLiceDataPane, seaLiceEntryPane]
};

// const siwiTab: Tab = {
//   id: 'siwi',
//   displayName: 'SIWI',
//   serverKey: 'Siwi',
//   element: SIWI
//   //Limit griegbc siwi to superuser only for the time being
//   // permission: (_user, site) => site.tabs.includes('Siwi')
// };

// const forecastTab = {
//   id: 'forecast',
//   displayName: 'Forecast',
//   serverKey: 'Forecast',
//   element: Forecast,
//   permission: permissions.isSuperuser
//   // alwaysInclude: true // this is temporary for dev.
// };

export const tabArray: Tab[] = [
  environmentTab,
  fishHealthTab,
  // siwiTab,
  // forecastTab,
  planktonTab,
  // hydrographyTab,
  exploreTab,
  // eventsTab,
  // equipmentTab,
  seaLiceTab
  // alarmTab
];

export const projectTabs = (project: Project, user: User): Tab[] => {
  if (!project || !project.tabs) return [];

  // if (project.archived) return [exploreTab];
  const filteredTabs: Tab[] = [];

  tabArray.forEach((primaryTab) => {
    if (project.tabs.includes(primaryTab.serverKey) || primaryTab.alwaysInclude) {
      if (!primaryTab.permission) {
        filteredTabs.push(primaryTab);
      } else {
        primaryTab.permission(user, project) && filteredTabs.push(primaryTab);
      }
    }

    primaryTab.panes &&
      primaryTab.panes.forEach((pane) => {
        if (pane.permission && !pane.permission(user, project)) {
          return;
        }

        if (project.tabs.includes(pane.serverKey)) {
          const existingTab = filteredTabs.find((et) => et.id === primaryTab.id);

          //if there's an existing tab, add the pane
          if (existingTab) {
            existingTab.panes.push(pane);
            //otherwise add the tab, and just this pane
          } else {
            filteredTabs.push({ ...primaryTab, panes: [pane] });
          }
        }
      });
  });

  return filteredTabs;
};
