import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  HStack,
  IconButton
} from '@chakra-ui/react';
import { Editor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import {
  MdAreaChart,
  MdFormatAlignCenter,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdFormatBold,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdFormatStrikethrough
} from 'react-icons/md';
import './FancyEditor.css';
import PlotlyExtension from './TipTap/PlotlyExtension';

export const DEFAULT_EXTENSIONS = [
  PlotlyExtension,
  StarterKit,
  TextAlign.configure({
    types: ['heading', 'paragraph']
  })
];

const FancyEditor = ({ editor }: { editor: Editor }) => {
  if (!editor) return <></>;

  return (
    <Card minW="600px">
      {editor.isEditable && (
        <CardHeader
          backgroundColor="white"
          position="sticky"
          top="20px"
          zIndex={99}
          width="fit-content">
          <HStack>
            <ButtonGroup isAttached justifyContent="space-evenly">
              <IconButton
                icon={<MdFormatBold fontSize="24px" />}
                colorScheme={editor.isActive('bold') ? 'blue' : null}
                onClick={() => editor.chain().focus().toggleBold().run()}
                aria-label="Bold"
              />
              <IconButton
                icon={<MdFormatItalic fontSize="24px" />}
                colorScheme={editor.isActive('italic') ? 'blue' : null}
                onClick={() => editor.chain().focus().toggleItalic().run()}
                aria-label="Italic"
              />
              <IconButton
                icon={<MdFormatStrikethrough fontSize="24px" />}
                colorScheme={editor.isActive('strike') ? 'blue' : null}
                onClick={() => editor.chain().focus().toggleStrike().run()}
                aria-label="Strikethrough"
              />
              <Button
                colorScheme={editor.isActive('heading', { level: 1 }) ? 'blue' : null}
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}>
                H1
              </Button>
              <Button
                colorScheme={editor.isActive('heading', { level: 2 }) ? 'blue' : null}
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}>
                H2
              </Button>
              <Button
                colorScheme={editor.isActive('heading', { level: 3 }) ? 'blue' : null}
                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}>
                H3
              </Button>
              <IconButton
                icon={<MdFormatListBulleted fontSize="24px" />}
                colorScheme={editor.isActive('bulletList') ? 'blue' : null}
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                aria-label="Bullet List"
              />
              <IconButton
                icon={<MdFormatListNumbered fontSize="24px" />}
                colorScheme={editor.isActive('orderedList') ? 'blue' : null}
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                aria-label="Ordered List"
              />
              <IconButton
                icon={<MdFormatQuote fontSize="24px" />}
                colorScheme={editor.isActive('blockquote') ? 'blue' : null}
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                aria-label="Block Quote"
              />
            </ButtonGroup>
            <ButtonGroup isAttached>
              <IconButton
                icon={<MdFormatAlignLeft fontSize="24px" />}
                colorScheme={editor.isActive({ textAlign: 'left' }) ? 'blue' : null}
                onClick={() => editor.chain().focus().setTextAlign('left').run()}
                aria-label="Left Align"
              />
              <IconButton
                icon={<MdFormatAlignCenter fontSize="24px" />}
                colorScheme={editor.isActive({ textAlign: 'center' }) ? 'blue' : null}
                onClick={() => editor.chain().focus().setTextAlign('center').run()}
                aria-label="Center Align"
              />
              <IconButton
                icon={<MdFormatAlignRight fontSize="24px" />}
                colorScheme={editor.isActive({ textAlign: 'right' }) ? 'blue' : null}
                onClick={() => editor.chain().focus().setTextAlign('right').run()}
                aria-label="Right Align"
              />
            </ButtonGroup>

            <ButtonGroup>
              <IconButton
                data-cypress="tiptap-add-chart"
                icon={<MdAreaChart fontSize="24px" />}
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .insertContent({
                      type: 'plotlyComponent',
                      attrs: { chart: { chartId: 'absolute-mortality' } }
                    })
                    .run()
                }
                aria-label="Insert Chart"
              />
            </ButtonGroup>
          </HStack>
        </CardHeader>
      )}
      <CardBody pt="0">
        <EditorContent editor={editor} />
      </CardBody>
    </Card>
  );
};

export default FancyEditor;
