import { WarningIcon } from '@chakra-ui/icons';
import { Center, CenterProps, Text, VStack } from '@chakra-ui/react';

const NoData = (props: CenterProps) => {
  return (
    <Center w="100%" h="100%" border="1px" borderColor="yellow.500" borderRadius="3px" {...props}>
      <VStack>
        <WarningIcon boxSize={8} color="yellow.500" />
        <Text>No Data Available</Text>
      </VStack>
    </Center>
  );
};

export default NoData;
