import { Alert, AlertIcon, VStack } from '@chakra-ui/react';
import { CubeMap, TCubeMeasure } from '@cubejs-client/core';
import Chart from 'components/Charts/Alarm/Chart';
import { fromSameCube } from 'components/Charts/Custom/Controller';
import TimeFragmentPicker, { TimeFragment } from 'components/Charts/Custom/TimeFragmentPicker';
import SelectPlot from 'components/Charts/SelectPlot';
import { useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { ConfigureAlarmProps } from './AlarmSteps/ConfigureAlarm';
import ThresholdEditor from './ThresholdEditor';

type AlarmEditorProps = {
  exploreCube: CubeMap;
} & ConfigureAlarmProps;

const AlarmEditor = ({ control, exploreCube, sites }: AlarmEditorProps) => {
  const [thresholds] = useWatch({ control, name: ['thresholds'] });
  // Time fragment doesn't need to be part of form state as its ephemeral
  const [timeFragment, setTimeFragment] = useState<TimeFragment>({
    granularity: 'minute',
    dateRange: 'last day until now'
  });

  const measures: TCubeMeasure[] = exploreCube?.measures
    ? Object.values(exploreCube?.measures)
    : [];

  const timeDimensions = Object.values(exploreCube?.dimensions ?? {}).filter(
    (d) => d?.type === 'time'
  );

  const timeDimension = useMemo(() => {
    if (thresholds.map((t) => t.measure).filter((m) => m).length === 0) return;
    const selectedTimeDimension = timeDimensions.find((timeD) =>
      //@ts-ignore
      fromSameCube(timeD, { name: thresholds[0].measure })
    );

    return selectedTimeDimension;
  }, [thresholds, timeDimensions]);

  return (
    <>
      <ThresholdEditor
        control={control}
        name="thresholds"
        //@ts-ignore
        measures={measures}
        timeDimension={timeDimension}
        sublocations={sites.length === 1 ? sites[0].sublocations : []}
      />
      <VStack w="100%">
        <TimeFragmentPicker
          timeFragment={timeFragment}
          onChange={(tf) => setTimeFragment(tf)}
          granularityIsDisabled={true}
          timeFrames={['Last Day', 'Last Week', 'Last Month']}
        />
        <Alert status="info" variant="left-accent">
          <AlertIcon mb="5px" />
          Alarms are checked every 30 minutes by our system at the &quot;Minute&quot; granularity
          for accuracy. &quot;Time Frame&quot; is for visualization purposes only.
        </Alert>
      </VStack>

      {thresholds.map((t) => t.measure).filter((m) => m).length === 0 && (
        <SelectPlot minH={'200px'} />
      )}

      {thresholds.map((t, i) => {
        return (
          <Chart
            key={i}
            timeFragment={timeFragment}
            threshold={t}
            timeDimension={timeDimension?.name}
            smbSiteIds={sites.map((s) => s.smbId.toString())}
          />
        );
      })}
    </>
  );
};

export default AlarmEditor;
