import { useMutation } from '@apollo/client';
import { Box, Center, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import Tile from 'components/Tile';
import { GET_S3_PRESIGNED_URL } from 'graphql/globalMutations';
import { useEffect, useState } from 'react';
import VideoModal from './VideoModal';

const VIDEOS = [
  {
    name: 'Landing Page',
    bucketObject: 'v2/01-Landing-Page.mp4',
    thumbnail: '/vid-thumbnails/landing-page.png',
    description:
      'View site locations, thresholds, and explore the regional ocean and weather forecast.'
  },
  {
    name: 'Environment Data',
    bucketObject: 'v2/02-Environmental-Data-Forecasts.mp4',
    thumbnail: '/vid-thumbnails/environmental-data-forecasts.png',
    description:
      'View site headlines, current environmental conditions, and site-specific forecasts.'
  },
  {
    name: 'Fish Health, Plankton, and Sea Lice',
    bucketObject: 'v2/03-Fish-Health-Plankton-Sea-Lice.mp4',
    thumbnail: '/vid-thumbnails/fish-health-plankton-sea-lice.png',
    description: 'Explore fish inventory and sea lice data, and enter / view plankton data.'
  },
  {
    name: 'SIWI',
    bucketObject: 'v2/04-SIWI.mp4',
    thumbnail: '/vid-thumbnails/siwi.png',
    description:
      "Understand SIWI, view today's score, and explore the recent and cohort histories of SIWI scores."
  },
  {
    name: 'Data Explore and Events',
    bucketObject: 'v2/05-Explore-Events.mp4',
    thumbnail: '/vid-thumbnails/explore-events.png',
    description: 'Create custom visualizations, download data, and create and update an event.'
  },
  {
    name: 'Alarms',
    bucketObject: 'v2/06-Alarms.mp4',
    thumbnail: '/vid-thumbnails/alarms.png',
    description: 'Create an alarm, configure notifications, and view alarm status.'
  }
];

const HelpVideos = () => {
  const [videoUrls, setVideoUrls] = useState({});
  const [getPresignedUrl] = useMutation(GET_S3_PRESIGNED_URL);
  const [openVideo, setOpenVideo] = useState<(typeof VIDEOS)[0] | null>(null);
  useEffect(() => {
    Promise.all(
      VIDEOS.map((v) => {
        return getPresignedUrl({
          variables: {
            name: v.bucketObject,
            bucket: 'seastate-tutorial-videos',
            operation: 'getObject'
          }
        });
      })
    ).then((datum) => {
      const urls = datum.map((d) => d.data.getS3PresignedURL);
      const videos = VIDEOS.reduce((acc, curr, i) => {
        acc[curr.bucketObject] = urls[i];
        return acc;
      }, {});

      setVideoUrls(videos);
    });
  }, []);

  return (
    <>
      <Text style={{ margin: '1em 0' }} fontSize="4xl">
        Videos
      </Text>
      <SimpleGrid columns={3} spacing={5}>
        {VIDEOS.map((v) => {
          return (
            <Tile key={v.name}>
              <Heading textAlign={'center'} fontWeight={'md'} fontSize={'2xl'} my="15px">
                {v.name}
              </Heading>
              <Box
                position={'relative'}
                onClick={() => {
                  setOpenVideo(v);
                }}>
                <img src={v.thumbnail} alt="Map-Based Navigation" style={{ width: 500 }} />
                <Center
                  textAlign={'center'}
                  fontSize="2xl"
                  cursor={'pointer'}
                  color="white"
                  position="absolute"
                  top={0}
                  bottom={0}
                  right={0}
                  left={0}
                  px="10px"
                  w="100%"
                  height="100%"
                  transition={'ease-in'}
                  transitionDuration="0.2s"
                  opacity={0}
                  _hover={{
                    backgroundColor: 'blue.500',
                    opacity: 1
                  }}>
                  {v.description}
                </Center>
              </Box>
            </Tile>
          );
        })}
        {openVideo && (
          <VideoModal
            onClose={() => setOpenVideo(null)}
            modalHeader={openVideo.name}
            src={videoUrls[openVideo.bucketObject]}
          />
        )}
      </SimpleGrid>
    </>
  );
};

export default HelpVideos;
