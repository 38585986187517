import { Center, Text, VStack } from '@chakra-ui/react';
import React from 'react';

type SelectPlotProps = {
  minH: string;
};

const SelectPlot = ({ minH }: SelectPlotProps) => {
  return (
    <Center w="100%" h="100%" minH={minH} border="1px" borderColor="yellow.500" borderRadius="3px">
      <VStack>
        <Text>No plot selected.</Text>
        <Text>Please select a plot in the drop down to view the raw data.</Text>
      </VStack>
    </Center>
  );
};

export default SelectPlot;
