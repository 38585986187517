import { Center, Image, Link, Text } from '@chakra-ui/react';
import { LoginFlow, UpdateLoginFlowBody } from '@ory/client';
import LoginForm from 'components/Forms/LoginForm';
import FrontPage from 'components/Pages/FrontPage';
import { SessionContext } from 'contexts/SessionContext';
import ory from 'ory';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import './Login.css';

// Ory login logic: https://www.ory.sh/docs/getting-started/custom-ui-ory-elements#login
const Login = () => {
  const [flow, setFlow] = useState<LoginFlow | null>(null);
  const [csrf, setCsrf] = useState<string | null>(null);
  const [loginError, setLoginError] = useState<string | null>(null);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _setSearchParams] = useSearchParams();
  const session = useContext(SessionContext);

  const postLogin = useCallback(() => {
    const redirect = searchParams.get('redirect');
    redirect ? navigate(redirect, { replace: true }) : navigate('/', { replace: true });
  }, [searchParams, navigate]);

  useEffect(() => {
    if (session?.id) {
      postLogin();
    }
  }, [session, navigate, postLogin]);

  // Create a new login flow
  const createFlow = useCallback(() => {
    return (
      ory
        // aal2 is a query parameter that can be used to request Two-Factor authentication
        // aal1 is the default authentication level (Single-Factor)
        // we always pass refresh (true) on login so that the session can be refreshed when there is already an active session
        .createBrowserLoginFlow({ refresh: true, aal: 'aal1' })
        // flow contains the form fields and csrf token
        .then(({ data: flow }) => setFlow(flow))
        .catch((error) => {
          switch (error.response?.status) {
            case 400:
              // the request could contain invalid parameters which would set error messages in the flow
              setFlow(error.response?.data);
              break;
            // something went wrong so we redirect to the login page
            case 410:
            case 404:
              return navigate('/login', { replace: true });
          }
        })
    );
  }, [navigate]);

  // Get the flow based on the flowId in the URL (.e.g redirect to this page after flow initialized)
  const getFlow = useCallback(
    (flowId: string) =>
      ory
        // the flow data contains the form fields, error messages and csrf token
        .getLoginFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch((err) => {
          console.error(err);
          return err;
        }),
    []
  );

  // submit the login form data to Ory
  const submitFlow = async (body: UpdateLoginFlowBody) => {
    // something unexpected went wrong and the flow was not set
    if (!flow) return navigate('/login', { replace: true });

    try {
      const sessionResponse = await ory.updateLoginFlow({
        flow: flow.id,
        updateLoginFlowBody: body
      });
      session.setSession(sessionResponse.data.session);
      const logoutResponse = await ory.createBrowserLogoutFlow();
      session.setLogoutUrl(logoutResponse.data.logout_url);
      // we successfully submitted the login flow, so lets redirect to the dashboard
      postLogin();
    } catch (error) {
      switch (error.response?.status) {
        // some user input error occurred, so we update the flow which constains UI error messages
        case 400:
          setFlow(error.response?.data);
          setLoginError('Invalid email or password.');
          break;
        case 422:
          // for webauthn we need to reload the flow
          // eslint-disable-next-line no-case-declarations
          const u = new URL(error.response?.data.redirect_browser_to);
          // get new flow data based on the flow id in the redirect url
          ory
            .getLoginFlow({ id: u.searchParams.get('flow') || '' })
            .then(({ data: flow }) => {
              setFlow(flow);
            })
            // something unexpected went wrong and the flow was not set - redirect the user to the login page
            .catch((err) => {
              console.error(err);
              navigate('/login', { replace: true });
            });
          break;
        // other errors we just redirect to the login page
        case 410:
        case 404:
        default:
          setLoginError(
            'There was an issue submitting your sign in request. Please contact SeaState support if you see this frequently.'
          );
          return navigate('/login', { replace: true });
      }
    }
  };

  useEffect(() => {
    // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
    const flowId = searchParams['flow'];
    // the flow already exists
    if (flowId) {
      getFlow(flowId).catch(createFlow); // if for some reason the flow has expired, we need to get a new one
      return;
    }

    // we assume there was no flow, so we create a new one
    createFlow();
  }, [createFlow, getFlow, searchParams]);

  // Kinda hacky, but since I don't want to use the ui library, but need the csrf token...
  useEffect(() => {
    if (flow?.ui.nodes) {
      //@ts-ignore
      const csrfNodes = flow.ui.nodes.filter((n) => n.attributes?.name === 'csrf_token');
      if (csrfNodes.length > 0) {
        //@ts-ignore
        setCsrf(csrfNodes[0].attributes.value);
      }
    }
  }, [flow?.ui.nodes]);

  const login = async ({ email, password }) => {
    await submitFlow({
      identifier: email,
      password,
      method: 'password',
      csrf_token: csrf
    });
  };

  return (
    <FrontPage>
      <Center mb="40px">
        <Image src="/seastate.png" />
      </Center>
      <Center>
        <Text fontSize="4xl">Welcome Back</Text>
      </Center>
      <LoginForm submit={login} loginError={loginError} />
      <Center>
        <Link mt="15px" href="/start-recovery">
          Forgot Password?
        </Link>
      </Center>
    </FrontPage>
  );
};

export default Login;
