import { BaseChartProps, BaseChartSettings } from './types';
import useCubeLTG from 'hooks/useCubeLTG';
import GraphError from './GraphError';
import NoData from './NoData';
import NotIncluded from './NotIncluded';
import Plot, { plotDates } from './Plot';
import { Skeleton } from '@chakra-ui/react';
import { Layout, PlotData, PlotlyDataLayoutConfig } from 'plotly.js';

export type ChartSettings<T> = BaseChartSettings & {
  traces: { title: string; measure: keyof T; yAxisLabel: string }[];
  timeDimension: keyof T;
  title: string;
};

const BasicLineChart = <T,>({
  dateRange = 'from 3 days ago until now',
  chartRange,
  granularity = 'hour',
  skip,
  settings: { project, site, traces, timeDimension, title, showTitle },
  onDataLoaded,
  className
}: BaseChartProps<ChartSettings<T>> & { className?: string }) => {
  const transform = (data: T[]) => {
    return data;
  };

  const graph = (data: T[]): PlotlyDataLayoutConfig => {
    const plotData: Partial<PlotData>[] = traces.map((t, i) => ({
      mode: 'lines+markers',
      type: 'scattergl',
      name: t.title,
      x: data.map((datum) => datum[timeDimension].toString()),
      y: data.map((datum) => datum[t.measure]?.toString()),
      hovertemplate: `${t.yAxisLabel}: <b>%{y:0.2f}</b><extra></extra>`,
      yaxis: i !== 0 ? `y${i + 1}` : undefined
    }));

    const [minDate, maxDate] = plotDates(plotData);

    const layout: Partial<Layout> = {
      title: showTitle && {
        text: title,
        y: 1
      },
      xaxis: {
        range: chartRange,
        title: {
          text: `${minDate} - ${maxDate} by ${granularity}`
        }
      },
      autosize: true,
      legend: {
        orientation: 'h',
        yanchor: 'top',
        y: 1.2,
        xanchor: 'left',
        x: 0
        // orientation: 'h',
        // x: 0,
        // y: 1.25,
        // traceorder: 'normal'
      },
      margin: {
        t: 25
      },
      hovermode: 'x unified'
    };

    traces.forEach((t, i) => {
      layout[i === 0 ? 'yaxis' : `yaxis${i + 1}`] = {
        title: t.yAxisLabel,
        side: i === 0 ? 'left' : 'right',
        overlaying: i === 0 ? undefined : 'y'
      };
    });

    return {
      data: plotData,
      layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: traces.map((t) => t.measure.toString()),
      timeDimensions: [
        {
          dimension: timeDimension.toString(),
          granularity,
          dateRange
        }
      ],
      dimensions: [],
      filters: site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [site.smbId.toString()]
            }
          ]
        : [],
      timezone: project.timezone
    },
    transform,
    graph,
    options: {
      skip,
      dependencies: { chartRange },
      onDataLoaded
    }
  });

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.length === 0 && project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : //@ts-ignore
  plot?.data?.flatMap((d) => d.x).length === 0 && !project.freeTrial ? (
    <NoData minH="450px" />
  ) : (
    <Plot className={`w-100 ${className}`} useResizeHandler={true} {...plot} />
  );
};

export { BasicLineChart };
