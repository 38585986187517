import { ResultSet } from '@cubejs-client/core';
import { TimeFragment } from 'components/Charts/Custom/TimeFragmentPicker';
import { InflatedChart } from 'components/Charts/types';
import { Site } from 'graphql/generated';
import Custom from 'components/Charts/Custom/Chart';
import { Notice } from 'components/Notice';
import { Text } from '@chakra-ui/react';

type InflatedChartRenderProps = {
  inflatedChart: InflatedChart;
  timeFrame?: TimeFragment;
  chartRange?: [Date, Date];
  site?: Site;
  onDataLoaded?: (resultSets: ResultSet[], dateRange?: [string, string]) => void;
};

const InflatedChartRender = ({
  inflatedChart,
  timeFrame,
  chartRange,
  site,
  onDataLoaded
}: InflatedChartRenderProps) => {
  if (!inflatedChart || !inflatedChart?.chart) {
    console.error('No Inflated Chart.chart is null!');
    return (
      // NOTE: Careful with className here. This is used in the screenshot function to look for error'd charts.
      <Notice className="plot-error" noticeColor="red.500">
        <Text>Something went wrong rendering this chart.</Text>
      </Notice>
    );
  }

  if (inflatedChart.custom) {
    return (
      <Custom
        smbSiteId={site?.smbId}
        dateRange={
          inflatedChart.settings?.aggregate
            ? inflatedChart.settings.dateRange
            : timeFrame?.dateRange
        }
        granularity={inflatedChart.settings?.aggregate ? undefined : timeFrame?.granularity}
        chartRange={chartRange}
        settings={inflatedChart.settings}
        {...inflatedChart.settings}
        onDataLoaded={onDataLoaded}
      />
    );
  } else {
    return (
      <inflatedChart.chart
        smbSiteId={site?.smbId}
        settings={inflatedChart.settings}
        dateRange={timeFrame?.dateRange}
        granularity={timeFrame?.granularity}
        chartRange={chartRange}
        {...inflatedChart.settings}
        onDataLoaded={onDataLoaded}
      />
    );
  }
};

export default InflatedChartRender;
