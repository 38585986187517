import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Text, Textarea, Tooltip, VStack } from '@chakra-ui/react';
import { EquipmentTextState } from 'graphql/generated';
import { UseControllerProps, useController } from 'react-hook-form';
import { EquipmentEntryForm } from '../EquipmentEntryModal';

export const EquipmentTextEntry = ({
  state,
  ...props
}: UseControllerProps<EquipmentEntryForm, `states`> & {
  state: EquipmentTextState;
}) => {
  const { field } = useController<EquipmentEntryForm, `states`>(props);
  return (
    <VStack w="100%">
      <Box>
        <Text display="inline">{state.name}</Text>
        {state?.description && (
          <Tooltip display="inline" label={state.description}>
            <InfoOutlineIcon ml="5px" />
          </Tooltip>
        )}
      </Box>
      <Textarea
        value={field.value?.[state.id] ?? ''}
        onChange={(e) => field.onChange({ ...field.value, [state.id]: e.currentTarget.value })}
      />
    </VStack>
  );
};
