import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';

const Controller = ({ chartSettings, setChartSettings }: ChartController<ChartSettings>) => {
  return (
    <ChartSwitchPanel
      switches={[
        {
          switchProps: { isChecked: chartSettings.showInBiomass },
          left: { value: 'count', label: 'Count' },
          right: { value: 'biomass', label: 'Biomass' },
          toggle: ({ checked }) => setChartSettings({ ...chartSettings, showInBiomass: checked })
        }
      ]}
    />
  );
};

export default Controller;
