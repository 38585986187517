import { gql, useQuery } from '@apollo/client';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link, Skeleton, Text } from '@chakra-ui/react';
import { Notice } from 'components/Notice';
import { ReportListing } from 'components/ReportListing/ReportListing';
import { DataTableFilter } from 'components/Tables/DataTable';
import config from 'config';
import { ProjectContext } from 'contexts/ProjectContext';
import { SeaLiceReport, Site } from 'graphql/generated';
import moment from 'moment';
import { useContext } from 'react';

export const GET_SEALICE_REPORTS = gql`
  query getSeaLiceReports($siteId: Int!) {
    reports: getSeaLiceReports(siteId: $siteId) {
      id
      reportTime
      lastUpdated
      reportedBy
      siteId
      sublocation
      formInput
      transactionId
    }
  }
`;

const tableFilter: DataTableFilter<SeaLiceReport> = (rows, globalFilterValue) =>
  rows.filter((row) => {
    const report = row.original;
    const filterString = [
      moment(report.reportTime).format('lll'),
      report.reportedBy,
      moment(report.lastUpdated).format('lll'),
      report.formInput.sublocation,
      report.formInput.averageLice,
      report.formInput.reportedBy
    ].join('|');
    return filterString.toLowerCase().includes(globalFilterValue.toLowerCase());
  });

interface SeaLiceReportListProps {
  site: Site;
  openReportForm: (report: SeaLiceReport | null) => void;
}

export function SeaLiceReportList({ site, openReportForm }: SeaLiceReportListProps) {
  const projectContext = useContext(ProjectContext);
  const reportsQuery = useQuery<{
    reports: SeaLiceReport[];
  }>(GET_SEALICE_REPORTS, {
    variables: { siteId: site.id },
    pollInterval: 600000
  });

  if (!projectContext || !projectContext.dataEntry.seaLice) {
    console.error('SeaLice: Must load project context with existing config to render.');
    return null;
  }

  const dataColumns = [
    {
      Header: 'Sublocation',
      accessor: 'sublocation',
      width: 150,
      Cell: ({ value }) => value?.split(' ')[1]
    },
    {
      Header: 'Average Motile Lice',
      accessor: 'formInput.averageLice',
      width: 300
    }
  ];

  if (reportsQuery.error) {
    return (
      <Notice mx="auto" w="50%" noticeColor="red.500">
        <Text>We experienced an issue loading your plankton reports.</Text>
        <Text>
          Please{' '}
          <Link href={config.ASANA_BUG_FORM_URL} isExternal color={'blue.600'}>
            file a ticket <ExternalLinkIcon mx={'2px'} />
          </Link>{' '}
          if this occurs frequently.
        </Text>
      </Notice>
    );
  }

  if (reportsQuery.loading) {
    return <Skeleton h="800px" />;
  }

  return (
    <div>
      <ReportListing
        reports={reportsQuery.data?.reports || []}
        openReportForm={openReportForm}
        addLocationColumn={false}
        userCanCreateNew={true}
        dataColumns={dataColumns}
        tableFilter={tableFilter}
        siteLabel={site.siteLabel}
      />
    </div>
  );
}
