import FormDataGrid from 'components/Forms/DataGrid/FormDataGrid';
import GridNumberEditor from 'components/Forms/DataGrid/GridNumberEditor';
import { Column } from 'react-data-grid';
import { useController, UseControllerProps } from 'react-hook-form';
import { Form } from './EntryForm';

type EntryGridProps = UseControllerProps<Form, 'measures'>;

export type AscRow = {
  measure: string;
  value: number;
};

const referenceToDisplayName: { [key: string]: string } = {
  salinity: 'Salinity',
  temperature: 'Temperature',
  'do-percent-saturation': 'Oxygen Saturation (%)'
};

const getColumns = (): readonly Column<AscRow>[] => {
  return [
    {
      key: 'measure',
      name: 'Measure',
      width: 250,
      editable: false,
      formatter: ({ row }) => referenceToDisplayName[row.measure]
    },
    {
      key: 'value',
      name: 'Reference Point',
      width: 150,
      editor: GridNumberEditor
    }
  ];
};

const EntryGrid = (props: EntryGridProps) => {
  const { field, fieldState } = useController(props);

  return (
    <FormDataGrid
      fieldState={fieldState}
      columns={getColumns()}
      rows={field.value}
      onRowsChange={(rows) => field.onChange(rows)}
    />
  );
};

export default EntryGrid;
