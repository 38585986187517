import { gql, useQuery } from '@apollo/client';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { User, permissions } from '@scoot/permissions';
import EditUserModal from 'components/Admin/EditUserModal';
import NewUserFlowModal from 'components/Admin/NewUserFlowModal';
import UserTable from 'components/Admin/UserTable';
import NotFound from 'components/Pages/NotFound';
import Tile from 'components/Tile';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { isWithinInterval, parseISO, subDays, subMonths } from 'date-fns';
import { useContext, useMemo, useState } from 'react';
import UserViewsSection from 'components/Admin/UserViewsSection';

const PROJECT_USERS = gql`
  query projectUsers($projectId: Int!) {
    project(id: $projectId) {
      users {
        oryId
        name
        jobTitle
        avatarUrl
        email
        superuser
        creationTime
        lastActivity
        projectAdministrator
        alerts
        asc
        manualHydrography
        read
        events
        equipment
        plankton
        seaLice
        alarmSites
      }
    }
  }
`;

export const UsersAdmin = () => {
  const user = useContext(UserContext);
  const project = useContext(ProjectContext);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [creatingNewUser, setCreatingNewUser] = useState<boolean>(false);

  const { loading, data, error } = useQuery(PROJECT_USERS, {
    variables: { projectId: project.id }
  });

  const projectAdminArray: number[] = data?.project.users
    .filter((u) => !u.superuser)
    .flatMap((u) => u.projectAdministrator);

  const onlyProjectAdmin = useMemo(() => {
    if (!projectAdminArray) return false;
    let projectAdmins = 0;
    projectAdminArray.forEach((projectId) => {
      if (Number(projectId) === project.id) projectAdmins++;
    });
    return projectAdmins <= 1;
  }, [projectAdminArray, project?.id]);

  const usersThisWeek = useMemo(() => {
    if (!data) return [];
    const oneWeekAgo = subDays(new Date(), 7);
    return data.project.users
      .filter((u) => !u.superuser)
      .filter((u) =>
        isWithinInterval(parseISO(u.lastActivity), { start: oneWeekAgo, end: new Date() })
      );
  }, [data?.project.users]);

  const usersThisMonth = useMemo(() => {
    if (!data) return [];
    const oneMonthAgo = subMonths(new Date(), 1);
    return data.project.users
      .filter((u) => !u.superuser)
      .filter((u) =>
        isWithinInterval(parseISO(u.lastActivity), { start: oneMonthAgo, end: new Date() })
      );
  }, [data?.project.users]);

  const deleteDisabled = useMemo(() => {
    if (
      onlyProjectAdmin &&
      selectedUser?.projectAdministrator.includes(project.id) &&
      !selectedUser?.superuser
    ) {
      return {
        disabled: true,
        reason: 'A project must have at least one project administrator.'
      };
    }

    if (data?.project.users.length <= 1)
      return {
        disabled: true
      };

    return {
      disabled: false
    };
  }, [
    data?.project.users.length,
    onlyProjectAdmin,
    project.id,
    selectedUser?.projectAdministrator,
    selectedUser?.superuser
  ]);

  if (!permissions.canAdminProject(user, project.id)) {
    return <NotFound />;
  }

  return (
    <>
      <VStack w="100%" mb="2rem">
        <Text fontSize="4xl" margin="1em 0">
          User Management
        </Text>
        <HStack>
          <Tile textAlign="center" p="15px">
            <Text fontSize="4xl">{usersThisWeek.length}</Text>
            <Text color="blue.500">Users with activity the last 7 days.</Text>
          </Tile>
          <Tile textAlign="center" p="15px">
            <Text fontSize="4xl">{usersThisMonth.length}</Text>
            <Text color="blue.500">Users with activity this month.</Text>
          </Tile>
        </HStack>
        {user.superuser && <UserViewsSection />}
        <UserTable
          users={data?.project.users}
          loading={loading}
          error={error}
          onUserClicked={(user) => setSelectedUser(user)}
          onNewUserClicked={() => setCreatingNewUser(true)}
        />
      </VStack>
      {selectedUser && (
        <EditUserModal
          defaultUser={selectedUser}
          showAdminPanel={true}
          showPasswordPanel={false}
          onClose={() => setSelectedUser(null)}
          isDeleteDisabled={deleteDisabled.disabled}
          disabledReason={deleteDisabled.reason}
        />
      )}
      {creatingNewUser && (
        <NewUserFlowModal
          onClose={() => setCreatingNewUser(false)}
          onContinue={(user) => {
            setSelectedUser(user);
            setCreatingNewUser(false);
          }}
        />
      )}
    </>
  );
};
