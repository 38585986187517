import { Center, FormLabel, InputGroup, Spacer, Text, Textarea } from '@chakra-ui/react';
import BaseInput from 'components/Forms/BaseInput';
import FancyEditor from 'components/Forms/FancyEditor';
import { useContext } from 'react';
import { ReportingEditContext } from './ReportingEditContext';

const ProjectReportReview = () => {
  const {
    reportInfo,
    setReportInfo,
    queryData: {
      data: { projectReport }
    },
    editor
  } = useContext(ReportingEditContext);

  return (
    <>
      <Center>
        <Text fontSize="2xl">Review and Publish</Text>
      </Center>
      <BaseInput
        data-cypress="report-name-input"
        value={reportInfo.name}
        placeholder="Report Name..."
        label="Report Name"
        onChange={(e) => {
          setReportInfo({ ...reportInfo, name: e.currentTarget.value });
        }}
      />

      <FormLabel mb="0" mt="10px">
        <Text display="inline-block">Description</Text>
      </FormLabel>
      <InputGroup>
        <Textarea
          data-cypress="report-details-textarea"
          value={reportInfo.description}
          placeholder="Short Summary..."
          title="Report Descriptinon"
          onChange={(e) => {
            setReportInfo({ ...reportInfo, description: e.currentTarget.value });
          }}
        />
      </InputGroup>

      <Center mt="10px">
        <Text fontSize="2xl">Report Preview</Text>
      </Center>
      <Spacer mt="10px" />
      {projectReport && <FancyEditor editor={editor} />}
    </>
  );
};

export default ProjectReportReview;
