import { isAfter } from 'date-fns';
import { AlarmState } from 'graphql/generated';
import { PlotlyDataLayoutConfig } from 'plotly.js';
import NoData from '../NoData';
import Plot from '../Plot';

type ChartProps = {
  alarmStatusChecks: { checkedAt: Date; state: AlarmState }[];
  notificationHistory: { notifiedAt: Date }[];
};

const Chart = ({ alarmStatusChecks, notificationHistory }: ChartProps) => {
  const plot: PlotlyDataLayoutConfig = {
    data: [
      {
        type: 'scatter',
        mode: 'markers',
        name: 'Sent Notifications',
        x: notificationHistory.map((n) => new Date(n.notifiedAt)),
        y: notificationHistory.map(() => 'Notification')
        // marker: { size: 12 }
      },
      {
        type: 'scatter',
        mode: 'markers',
        line: { shape: 'hv', color: '#E53E3E' },
        name: 'Alarm Status',
        x: alarmStatusChecks
          .map((asc) => new Date(asc.checkedAt))
          .sort((a, b) => (isAfter(a, b) ? -1 : 1)),
        y: alarmStatusChecks.map((asc) => asc.state)
      }
    ]
  };

  if (alarmStatusChecks.length === 0 && notificationHistory.length === 0) {
    return <NoData minH="300px" />;
  }

  return <Plot className="w-100" useResizeHandler="true" {...plot} />;
};

export default Chart;
