import colormap from 'colormap';

class Config {}

Config.ox_colorscale = [
  [0.0, 'rgb(14,0,0)'],
  [0.13, 'rgb(76,3,10)'],
  [0.25, 'rgb(134,26,7)'],
  [0.38, 'rgb(168,64,3)'],
  [0.5, 'rgb(188,100,4)'],
  [0.63, 'rgb(206,136,11)'],
  [0.75, 'rgb(225,180,25)'],
  [0.88, 'rgb(238,230,54)'],
  [1, 'rgb(255,255,25)']
];
Config.deep_colorscale = [
  [0.0, 'rgb(228, 244, 190)'],
  [0.02, 'rgb(228, 244, 190)'],
  [0.02, 'rgb(178, 227, 169)'],
  [0.1, 'rgb(178, 227, 169)'],
  [0.1, 'rgb(105, 197, 163)'],
  [0.3, 'rgb(105, 197, 163)'],
  [0.3, 'rgb(78, 161, 161)'],
  [0.5, 'rgb(78, 161, 161)'],
  [0.5, 'rgb(66, 124, 153)'],
  [0.7000000000000001, 'rgb(66, 124, 153)'],
  [0.7000000000000001, 'rgb(62, 87, 145)'],
  [0.9, 'rgb(62, 87, 145)'],
  [0.9, 'rgb(61, 52, 98)'],
  [1.0, 'rgb(61, 52, 98)']
];
Config.threshold_colorscale = [
  [0, '#eeeeee'],
  [0.000001, '#cafeca'],
  [0.5, '#fefecb'],
  [1, '#ff9897']
];
Config.plankton_presence = [
  [0, '#eeeeee'],
  [0.000001, '#89CFF0'],
  [1, '#89CFF0']
];

Config.MAX_TEMP = 25;
Config.MIN_TEMP = 0;

Config.MIN_OX = 0;
Config.MAX_OX = 15;

Config.MAX_TEMP_EC = 22;
Config.MIN_TEMP_EC = -2;

Config.MIN_OX_PERCENT = 0;
Config.MAX_OX_PERCENT = 200;

Config.MIN_SAL = 15;
Config.MAX_SAL = 35;

Config.MIN_PLANK = 0;
Config.MAX_PLANK = 25;

Config.MIN_NO3 = 0;
Config.MAX_NO3 = 65;

Config.MIN_DEN = 1010;
Config.MAX_DEN = 1040;

Config.MIN_ARAG = 0;
Config.MAX_ARAG = 5;

Config.MIN_WND = 0;
Config.MAX_WND = 60;

Config.MIN_TA2 = -20;
Config.MAX_TA2 = 40;

Config.MIN_PA0 = 0;
Config.MAX_PA0 = 140;

Config.MIN_APM = 94000;
Config.MAX_APM = 108000;

Config.MIN_DEN_SUBTRACTED = 10;
Config.MAX_DEN_SUBTRACTED = 40;

Config.COLUMN_HEIGHT = 1000;
Config.COLUMN_RADIUS = 1000;

function convertColorscale(scale) {
  let increment = 1 / (scale.length - 1);
  let index = 0.0;
  let plotlyScale = [];
  for (const color of scale) {
    plotlyScale.push([index, color]);
    index = index + increment;
  }
  //plotly requires largest value to be exactly 1 for mapping
  plotlyScale[scale.length - 1][0] = 1;
  return plotlyScale;
}

const temp_colors = colormap({
  colormap: 'temperature',
  nshades: 256,
  format: 'rgbaString',
  alpha: 1.0
});

const ox_colors = colormap({
  colormap: [
    { index: 0, rgb: [14, 0, 0] },
    { index: 0.13, rgb: [76, 3, 10] },
    { index: 0.25, rgb: [134, 26, 7] },
    { index: 0.38, rgb: [168, 64, 3] },
    { index: 0.5, rgb: [188, 100, 4] },
    { index: 0.63, rgb: [206, 136, 11] },
    { index: 0.75, rgb: [225, 180, 25] },
    { index: 0.88, rgb: [238, 230, 54] },
    { index: 1, rgb: [255, 255, 25] }
  ],
  nshades: 256,
  format: 'rgbaString',
  alpha: 1.0
});

const sal_colors = colormap({
  colormap: 'salinity',
  nshades: 256,
  format: 'rgbaString',
  alpha: 1.0
});

const secchi_colors = colormap({
  colormap: 'bathymetry',
  nshades: 256,
  format: 'rba',
  alpha: 1.0
});

const chlorophyll_colors = colormap({
  colormap: 'chlorophyll',
  nshades: 256,
  format: 'rgbaString',
  alpha: 1.0
}).reverse();

const turbidity_colors = colormap({
  colormap: 'turbidity',
  nshades: 256,
  format: 'rgbaString',
  alpha: 1.0
});

const aragonite_colors = colormap({
  colormap: 'copper',
  nshades: 256,
  format: 'rgbaString',
  alpha: 1.0
});

const paramOptions = {
  visibility: {
    range: [0, 25],
    name: 'Visibility',
    label: 'Visibility (m)',
    units: 'm',
    colorscale: secchi_colors,
    color: convertColorscale(secchi_colors)
  },
  secchi_disc_m: {
    range: [0, 15],
    name: 'Secchi Disc',
    label: 'Secchi Disc (m)',
    units: 'm',
    colorscale: secchi_colors,
    color: convertColorscale(secchi_colors)
  },
  salinity_psu: {
    range: [0, 40],
    name: 'Salinity',
    label: 'Salinity (PSU)',
    units: 'PSU',
    colorscale: sal_colors,
    color: convertColorscale(sal_colors)
  },
  salinityPsu: {
    range: [0, 40],
    name: 'Salinity',
    label: 'Salinity (PSU)',
    units: 'PSU',
    colorscale: sal_colors,
    color: convertColorscale(sal_colors)
  },
  oxygen_mgl: {
    range: [0, 15],
    name: 'Oxygen',
    label: 'Oxygen (mg/L)',
    units: 'mg/L',
    colorscale: ox_colors,
    color: Config.ox_colorscale
  },
  oxygenMgl: {
    range: [0, 15],
    name: 'Oxygen',
    label: 'Oxygen (mg/L)',
    units: 'mg/L',
    colorscale: ox_colors,
    color: Config.ox_colorscale
  },
  oxygenConcentration: {
    range: [0, 15],
    name: 'Oxygen',
    label: 'Oxygen (mg/L)',
    units: 'mg/L',
    colorscale: ox_colors,
    color: Config.ox_colorscale
  },
  water_temp_c: {
    range: [0, 25],
    name: 'Temp',
    label: 'Temp (C)',
    units: 'C',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  u_mps: {
    range: [-3, 3],
    name: 'East Velocity',
    label: 'East Velocity (m/s)',
    units: 'm/s',
    colorscale: turbidity_colors,
    color: convertColorscale(turbidity_colors)
  },
  v_mps: {
    range: [-3, 3],
    name: 'North Velocity',
    label: 'North Velocity (m/s)',
    units: 'm/s',
    colorscale: turbidity_colors,
    color: convertColorscale(turbidity_colors)
  },
  waterTempC: {
    range: [0, 25],
    name: 'Temp',
    label: 'Temp (C)',
    units: 'C',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  oxygen_sat: {
    range: [0, 140],
    name: 'Oxygen Sat.',
    label: 'Oxygen Sat. (%)',
    units: '%',
    colorscale: ox_colors,
    color: Config.ox_colorscale
  },
  oxygenSat: {
    range: [0, 140],
    name: 'Oxygen Sat',
    label: 'Oxygen Sat (%)',
    units: '%',
    colorscale: ox_colors,
    color: Config.ox_colorscale
  },
  oxygen_umol: {
    range: [0, 500],
    name: 'Oxygen',
    label: 'Oxygen (umol)',
    units: 'umol',
    colorscale: ox_colors,
    color: Config.ox_colorscale
  },
  depth: {
    range: [0, 25],
    name: 'Depth',
    label: 'Depth (m)',
    units: 'm'
  },
  phyto_umol: {
    range: [0, 25],
    name: 'Chlorophyll-A',
    label: 'Chlorophyll-A (umol/L)',
    units: 'umol/L',
    colorscale: chlorophyll_colors,
    color: convertColorscale(chlorophyll_colors)
  },
  nitrate_umol: {
    range: [0, 65],
    name: 'Nitrate',
    label: 'Nitrate (umol/L)',
    units: 'umol',
    colorscale: turbidity_colors,
    color: convertColorscale(turbidity_colors)
  },
  plankton_cell_count: {
    range: [0, 200],
    name: 'Cell Count',
    label: 'Cell Count (cells/mL)',
    units: 'cells/mL',
    colorscale: turbidity_colors,
    color: convertColorscale(turbidity_colors)
  },
  plankton_threshold_value: {
    range: [0, 2],
    name: 'Risk Threshold',
    label: 'Risk Threshold',
    units: '',
    colorscale: turbidity_colors,
    color: Config.threshold_colorscale
  },
  plankton_presence: {
    range: [0, 2],
    name: 'Plankton Presence',
    label: 'Plankton Presence',
    units: '',
    colorscale: turbidity_colors,
    color: Config.plankton_presence
  },
  waterlevel: {
    range: [-1, 5.5],
    name: 'Tide Height',
    label: 'Tide Height (m)',
    units: 'm'
  },
  harvest_count: {
    name: 'Harvest Count',
    label: 'Harvest Count',
    units: 'fish'
  },
  mortality_count: {
    name: 'Mortality Count',
    label: 'Mortality Count',
    units: 'fish'
  },
  end_count: {
    name: 'End Count',
    label: 'End Count',
    units: 'fish'
  },
  start_count: {
    name: 'Start Count',
    label: 'Start Count',
    units: 'fish'
  },
  end_weight: {
    name: 'End Weight',
    label: 'End Weight (kg)',
    units: 'kg'
  },
  net_growth_weight: {
    name: 'Net Growth Weight',
    label: 'Net Growth Weight (kg)',
    units: 'kg'
  },
  start_weight: {
    name: 'Start Weight',
    label: 'Start Weight (kg)',
    units: 'kg'
  },
  average_harvest_weight: {
    name: 'Average Harvest Weight',
    label: 'Average Harvest Weight (kg)',
    units: 'kg'
  },
  average_weight: {
    name: 'Average Weight',
    label: 'Average Weight (kg)',
    units: 'kg'
  },
  harvest_weight: {
    name: 'Harvest Weight',
    label: 'Harvest Weight (kg)',
    units: 'kg'
  },
  mortality_weight: {
    name: 'Mortality Weight',
    label: 'Mortality Weight (kg)',
    units: 'kg'
  },
  inside_mitigation: {
    name: 'Inside Mitigation',
    label: 'Inside Mitigation',
    units: '',
    color: '#1f77b4'
  },
  walkway_mitigation: {
    name: 'Outside Mitigation',
    label: 'Outside Mitigation',
    units: '',
    color: '#ff7f0e'
  },
  GDB_connection: {
    name: 'GDB Connection Status',
    label: 'GDB Connection Status',
    units: '',
    color: '#2ca02c'
  },
  feed_use_kg: {
    name: 'Feed Use (kg)',
    label: 'Feed Use (kg)',
    units: 'kg'
  },
  calc_feed_amount_kg: {
    name: 'Calc Feed Amount (kg)',
    label: 'Calc Feed Amount (kg)',
    units: 'kg'
  },
  sgr_daily: {
    name: 'SGR (daily)',
    label: 'SGR (daily)',
    units: 'sgr'
  },
  fcr_daily: {
    name: 'FCR (daily)',
    label: 'FCR (daily)',
    units: 'fcr'
  },
  sfr_daily: {
    name: 'SFR (daily)',
    label: 'SFR (daily)',
    units: 'sfr'
  },
  average_lice: {
    name: 'Average Motile Lice Per Fish',
    label: 'Average Motile Lice',
    units: 'lice / fish'
  },
  lice_stages: {
    name: 'Lice Counts by Stage',
    label: 'Lice Counts by Stage',
    units: 'lice'
  },
  treatment: {
    name: 'Treatment Types',
    label: 'Treatment Types',
    units: ''
  },
  aragonite: {
    range: [0, 5],
    name: 'Aragonite Saturation State',
    label: 'Aragonite Saturation State (Ω)',
    units: 'Ω',
    colorscale: aragonite_colors,
    color: convertColorscale(aragonite_colors)
  },
  WND: {
    range: [0, 60],
    name: 'Wind Speed / Direction',
    label: 'Wind Speed (m/s)',
    units: 'm/s'
  },
  TA2: {
    range: [-20, 40],
    name: 'Air Temperature',
    label: 'Air Temperature (C)',
    units: 'C'
  },
  PA0: {
    range: [0, 140],
    name: 'Precipitation',
    label: 'Precipitation (mm)',
    units: 'mm'
  },
  APM: {
    range: [94000, 108000],
    name: 'Atmospheric Pressure',
    label: 'Atmospheric Pressure (hPa)',
    units: 'hPa'
  }
};

const forecastParamOptions = {
  salinityAvg: {
    range: [0, 40],
    name: 'Salinity',
    label: 'Salinity (PSU)',
    units: 'PSU',
    colorscale: sal_colors,
    color: convertColorscale(sal_colors)
  },
  oxygenConcentrationAvg: {
    range: [0, 15],
    name: 'Oxygen',
    label: 'Oxygen (mg/L)',
    units: 'mg/L',
    colorscale: ox_colors,
    color: Config.ox_colorscale
  },
  oxygenMglAvg: {
    range: [0, 15],
    name: 'Oxygen',
    label: 'Oxygen (mg/L)',
    units: 'mg/L',
    colorscale: ox_colors,
    color: Config.ox_colorscale
  },
  waterTempAvg: {
    range: [0, 25],
    name: 'Temp',
    label: 'Temp (C)',
    units: 'C',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  oxygenSaturationAvg: {
    range: [0, 140],
    name: 'Oxygen Sat',
    label: 'Oxygen (%)',
    units: '%',
    colorscale: ox_colors,
    color: Config.ox_colorscale
  },
  pycnoDepthAvg: {
    range: [0, 25],
    name: 'Depth of Pycnocline',
    label: 'Pycnocline (m)',
    units: 'm',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  tkeAvg: {
    range: [0, 0.0001],
    name: 'Turbulent Kinetic Energy',
    label: 'TKE (J)',
    units: 'J',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  buoyancyFreqAvg: {
    range: [0, 0.005],
    name: 'Buoyancy Frequency Squared',
    label: 'Buoyancy (s^-2)',
    units: 's^-2',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  airTempAvg: {
    range: [-10, 45],
    name: 'Temp',
    label: 'Temp (C)',
    units: 'C',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  cloudCoverAvg: {
    range: [0, 100],
    name: 'Cloud Cover',
    label: 'Cloud Cover (%)',
    units: '%',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  windEastAvg: {
    range: [0, 25],
    name: 'East Wind',
    label: 'East Wind (m/s)',
    units: 'm/s',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  windNorthAvg: {
    range: [0, 25],
    name: 'North Wind',
    label: 'North Wind (m/s)',
    units: 'm/s',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  totalPrecipAvg: {
    range: [0, 60],
    name: 'Total Precipitation',
    label: 'Total Precipitation (mm)',
    units: 'mm',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  seaSurfacePressureAvg: {
    range: [990, 1030],
    name: 'Sea Surface Pressure',
    label: 'Sea Surface Pressure (millibars)',
    units: 'millibars',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  relativeHumidityAvg: {
    range: [0, 100],
    name: 'Relative Humidity',
    label: 'Relative Humidity (%)',
    units: '%',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  downwardLongRadiationAvg: {
    range: [0, 25],
    name: 'Incoming Long-wave Radiation',
    label: 'Incoming Long-wave Radiation (W/m^2)',
    units: 'W/m^2',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  downwardShortRadiationAvg: {
    range: [0, 25],
    name: 'Incoming Short-wave Radiation',
    label: 'Incoming Short-wave Radiation (W/m^2)',
    units: '(W/m^2)',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  },
  upwellingIndexAvg: {
    range: [-500, 500],
    name: 'Upwelling Index',
    label: 'Upwelling Index (kg/m/s)',
    units: 'kg/m/s',
    colorscale: temp_colors,
    color: convertColorscale(temp_colors)
  }
};

export { Config, paramOptions, forecastParamOptions };
