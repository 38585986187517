import { Box, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { ProjectContext } from 'contexts/ProjectContext';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useContext } from 'react';
import { getSunset } from 'sunrise-sunset-js';
import { WiSunset } from 'weather-icons-react';

const Sunset = ({ lat, lon }: { lat: number; lon: number }) => {
  const projectContext = useContext(ProjectContext);
  const sunset = getSunset(lat, lon);
  let sunset_formatted = '';
  if (!isNaN(sunset.getTime())) {
    sunset_formatted = format(utcToZonedTime(sunset, projectContext.timezone), 'kk:mm');
  } else {
    sunset_formatted = '--';
  }
  return (
    <VStack>
      <Tooltip label="Time of Sunset">
        <HStack>
          <Box color="orange.600">
            <WiSunset size={40} />
          </Box>
          <Text>{sunset_formatted}</Text>
        </HStack>
      </Tooltip>
    </VStack>
  );
};

export default Sunset;
