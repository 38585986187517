import { ProjectContextType } from 'contexts/ProjectContext';
import { PlotlyDataLayoutConfig } from 'plotly.js';

const addOxygenSatShapes = (
  yaxis: string,
  x: any[],
  plot: PlotlyDataLayoutConfig,
  projectContext: ProjectContextType
) => {
  const thresholds = projectContext.thresholds.oxygen_saturation;

  let oneElement = false;
  if (x.length >= 2) {
    oneElement = x[0] === x[1];
  }

  plot.data = [
    ...plot.data,
    {
      type: 'scatter',
      mode: oneElement ? 'markers' : 'lines',
      x,
      y: [thresholds[0], thresholds[0]],
      line: { dash: 'dash', color: 'orange' },
      name: 'Caution',
      yaxis
    },
    {
      type: 'scatter',
      mode: oneElement ? 'markers' : 'lines',
      x,
      y: [thresholds[1], thresholds[1]],
      line: { dash: 'dash', color: 'red' },
      name: 'Danger',
      yaxis
    }
  ];

  plot.layout.legend = {
    orientation: 'h',
    traceorder: 'normal',
    x: 0,
    y: 1.1
  };
};

export default addOxygenSatShapes;
