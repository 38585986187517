import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Center, Heading, Link as ChakraLink, Text, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Center w={'100vw'} pt={'15vh'}>
      <VStack>
        <img src={'/ScootScienceLogo.png'} alt={'Scoot Science Logo'} width={'80px'} />
        <Heading as={'h1'} size={'4xl'} pt={'1rem'}>
          404
        </Heading>
        <Text fontSize={'2xl'}>{`We're sorry, we couldn't find what you were looking for.`}</Text>
        <ChakraLink as={Link} to={'/'}>
          Return Home
          <ExternalLinkIcon mx={'2px'} />
        </ChakraLink>
      </VStack>
    </Center>
  );
};

export default NotFound;
