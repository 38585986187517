import { Skeleton } from '@chakra-ui/react';
import GraphError from '../GraphError';
import NoData from '../NoData';
import Plot from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';
import useHydrographyPlot, { ParameterOptions } from './ForecastPlot';
import { ProjectContext } from 'contexts/ProjectContext';
import { useContext } from 'react';
import NotIncluded from '../NotIncluded';

export type ChartSettings = BaseChartSettings & {
  useLocalMinMax?: boolean;
  useSensor?: boolean;
  skipForecasting?: boolean;
  dateRange?: string;
  parameters: ParameterOptions;
  showParameterSelect?: boolean;
  aggregation?: number;
};

const height = '550px';

const Chart = (props: BaseChartProps<ChartSettings>) => {
  const { isLoading, error, hasData, plot } = useHydrographyPlot(props);
  const projectContext = useContext(ProjectContext);

  if (isLoading) {
    return <Skeleton minH={height} height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH={height} />;
  }

  if (projectContext.freeTrial && plot?.data[0]['x'].length === 0) {
    return <NotIncluded minH="450px" />;
  }

  return hasData ? (
    <Plot className="w-100 barge-hydrography-plot" useResizeHandler={true} {...plot} />
  ) : (
    <NoData minH={height} />
  );
};

export default Chart;
