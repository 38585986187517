import { Box, Heading, Text } from '@chakra-ui/react';
import { Notice } from 'components/Notice';

const EquipmentDevices = () => {
  return (
    <Notice textAlign="center" mb="25px">
      <Heading fontSize={'md'}>
        Due to system updates, this page is temporarily out of service. It will be returning soon in
        a new update!
      </Heading>
      <Box>
        <Text mt="25px">
          If you need to know your device mappings or how your devices are configured at this
          moment, please contact us.
        </Text>
        <Text mt="15px">
          If you would like to have a say in what this new page will contain. Feel free to use the
          &apos;Give us Feedback!&apos; in the top right corner describing what you would like to
          see!
        </Text>
      </Box>
    </Notice>
  );
};

export { EquipmentDevices };
