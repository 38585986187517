import { useCubeQuery } from '@cubejs-client/react';
import BaseSelect from 'components/Forms/BaseSelect';
import { useMemo } from 'react';

type MortalityByCauseSelectProps = {
  value: string;
  onChange: (cause: string) => void;
};

export const MortalityByCauseSelect = ({ value, onChange }: MortalityByCauseSelectProps) => {
  const { resultSet, isLoading } = useCubeQuery({
    dimensions: ['TessMortalityLookup.mortalityCauseName']
  });

  const options = useMemo(() => {
    if (!resultSet) return [];
    return resultSet.rawData().map((d) => ({
      label: d['TessMortalityLookup.mortalityCauseName'],
      value: d['TessMortalityLookup.mortalityCauseName']
    }));
  }, [resultSet]);

  return (
    <BaseSelect
      isClearable={true}
      isLoading={isLoading}
      w="250px"
      placeholder="Mortality Cause..."
      options={options}
      value={options.find((o) => o.value === value)}
      onChange={(o) => onChange(o?.value.toString())}
    />
  );
};
