import { useQuery } from '@apollo/client';
import { LockIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Text, VStack } from '@chakra-ui/react';
import { ErrorBoundary } from '@sentry/react';
import DashboardMap from 'components/Map/DashboardMap';
import NotFound from 'components/Pages/NotFound';
import { RenderError } from 'components/Pages/RenderError';
import HeadlineStats from 'components/Site/Headline/Stats/HeadlineStats';
import Weather from 'components/Site/Headline/Weather/Weather';
import SiteStatusMessages from 'components/Site/SiteStatusMessages';
import { Site } from 'graphql/generated';
import { GET_SITE } from 'graphql/globalQueries';
import { FC, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';

export const TabErrorBoundary: FC = ({ children }) => (
  <ErrorBoundary
    fallback={
      <Center width={'100vw'} height={'100vh'}>
        <RenderError />
      </Center>
    }
    showDialog={false}>
    {children}
  </ErrorBoundary>
);

const SiteLayout = () => {
  const { siteId } = useParams();
  const { data, loading, error } = useQuery<{ site: Site }>(GET_SITE, {
    skip: !siteId,
    variables: {
      id: Number(siteId)
    }
  });

  const useStatusMessages = useMemo(() => {
    if (!data?.site) return false;

    return !(data?.site.siteLabel === 'ASC' || data?.site.archived);
  }, [data]);

  if (loading) {
    return <></>;
  }

  if (!loading && (data?.site === null || error)) {
    return <NotFound />;
  }

  return (
    <VStack>
      {useStatusMessages && (
        <SiteStatusMessages
          siteHasGdb={data.site.hasGdb}
          siteName={data.site.name}
          siteSmbId={data.site.smbId}
        />
      )}
      <Flex w="100%">
        <TabErrorBoundary>
          <Box w="400px" maxH="500px" position="relative" borderRadius="base" boxShadow="base">
            <DashboardMap activeSiteId={data.site.id} />
          </Box>
          {data.site.archived ? (
            <VStack m="1rem" alignItems="center" w="100%">
              <Text fontSize="3xl">{data.site.name}</Text>
              <VStack
                data-cypress="archived-header-info"
                justifyContent="center"
                w="100%"
                h="100%"
                minH="415px"
                border="1px"
                borderColor="yellow.500"
                borderRadius="3px">
                <LockIcon boxSize={8} color="yellow.500" />
                <Text fontSize="xl">This site is Archived.</Text>
                <Text textAlign="center" w="75%">
                  This site is archived and will not receive any new data or updates. Please contact
                  Scoot Science if you need this site active again.
                </Text>
                <Text textAlign="center" w="75%">
                  You can still access your old data through Explore.
                </Text>
              </VStack>
            </VStack>
          ) : (
            <HeadlineStats site={data.site} />
          )}
        </TabErrorBoundary>
      </Flex>
      {!data.site.archived && (
        <Box w="100%">
          <Weather smbSiteId={data.site.smbId} lat={data.site.lat} lon={data.site.lon} />
        </Box>
      )}

      <Box pt="20px" pb="20px" w="100%" id={'sitepage'}>
        <TabErrorBoundary>
          <Outlet context={data.site} />
        </TabErrorBoundary>
      </Box>
    </VStack>
  );
};

export default SiteLayout;
