import { CheckCircleIcon, MinusIcon, WarningIcon } from '@chakra-ui/icons';
import { Box, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import { DateRange, TimeDimensionGranularity } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { ProjectContextType } from 'contexts/ProjectContext';
import { add, startOfDay, sub } from 'date-fns';
import { max, mean, min, sortBy, uniq } from 'lodash';
import { PlotlyDataLayoutConfig, ScatterData } from 'plotly.js';
import { useMemo } from 'react';
import {
  PlanktonThreshold,
  planktonThresholdToColor,
  thresholdForSpeciesValue
} from 'shared/plankton';
import GraphError from '../GraphError';
import Plot from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';
import { ControllerInputs } from './RecentTrendsController';

export type ChartSettings = BaseChartSettings & {
  sublocations: { label: string; value: string }[];
  dateRange: DateRange;
};

type TableRow = {
  species: string;
  avgCellsMl: number;
  maxCellsMl: number;
  maxThreshold: PlanktonThreshold;
};

type PlanktonCubeDatum = {
  'TessPlanktonLookup.sublocation'?: string;
  'Site.id'?: string;
  'TessPlankton.measuredAt': string;
  'TessPlankton.measuredAt.minute': string;
  'TessPlanktonLookup.species': string;
  'TessPlankton.avgCellCount': number;
  'TessPlankton.maxCellCount': number;
};

export const RecentTrendsV2 = ({
  settings,
  control
}: BaseChartProps<ChartSettings, ControllerInputs>) => {
  const locationDimension = settings.site?.smbId ? 'TessPlanktonLookup.sublocation' : 'Site.id';

  const timeDimensions = [
    {
      dimension: 'TessPlankton.measuredAt',
      granularity: 'minute' as TimeDimensionGranularity,
      dateRange: settings.dateRange
    }
  ];

  const { resultSet, isLoading, error } = useCubeQuery<PlanktonCubeDatum>({
    timeDimensions,
    dimensions: [locationDimension, 'TessPlanktonLookup.species'],
    measures: ['TessPlankton.avgCellCount', 'TessPlankton.maxCellCount'],
    //@ts-ignore not sure why it's angry and im too lazy to figure it out
    filters: [
      { member: 'TessPlanktonLookup.method', operator: 'equals', values: ['discrete'] },
      { member: locationDimension, operator: 'set' },
      { member: 'TessPlanktonLookup.depth', operator: 'set' },
      { member: 'TessPlankton.avgCellCount', operator: 'gt', values: ['0'] },
      settings.site?.smbId && {
        member: 'Site.id',
        operator: 'equals',
        values: [settings.site?.smbId.toString()]
      }
    ].filter((f) => f),
    timezone: settings.project.timezone
  });

  const filteredData = useMemo(() => {
    if (!resultSet?.rawData()) return [];

    const filterValues = settings.sublocations.map((f) => f.value);

    return filterValues?.length > 0
      ? resultSet.rawData().filter((d) => filterValues.includes(d[locationDimension]))
      : resultSet.rawData();
  }, [resultSet, settings.sublocations]);

  const summaryCounts = useMemo(() => {
    const maxThresholdsByDay = filteredData.reduce(
      (acc, curr) => {
        const day = startOfDay(new Date(curr['TessPlankton.measuredAt']));

        const thresholdForCurr = thresholdForSpeciesValue(
          settings.project,
          curr['TessPlanktonLookup.species'],
          curr['TessPlankton.maxCellCount']
        );

        const existingOnDay = acc?.[day.toISOString()];

        if (thresholdForCurr === 'danger') {
          acc[day.toISOString()] = 'danger';
          return acc;
        } else if (thresholdForCurr === 'caution' && existingOnDay !== 'danger') {
          acc[day.toISOString()] = thresholdForCurr;
        } else if (!existingOnDay) {
          acc[day.toISOString()] = thresholdForCurr;
        }

        return acc;
      },
      {} as { [day: string]: TableRow['maxThreshold'] }
    );

    return {
      safe: Object.values(maxThresholdsByDay).filter((v) => v === 'safe').length,
      caution: Object.values(maxThresholdsByDay).filter((v) => v === 'caution').length,
      danger: Object.values(maxThresholdsByDay).filter((v) => v === 'danger').length
    };
  }, [filteredData]);

  const tableData: TableRow[] = useMemo(() => {
    const species = uniq(filteredData.map((d) => d['TessPlanktonLookup.species']));

    const agg = species.map((s) => {
      const maxValue = max(
        filteredData
          .filter((d) => d['TessPlanktonLookup.species'] === s)
          .map((d) => d['TessPlankton.maxCellCount'])
      );

      const threshold: TableRow['maxThreshold'] = thresholdForSpeciesValue(
        settings.project,
        s,
        maxValue
      );

      return {
        species: s,
        avgCellsMl: mean(
          filteredData
            .filter((d) => d['TessPlanktonLookup.species'] === s)
            .map((d) => d['TessPlankton.avgCellCount'])
        ),
        maxCellsMl: maxValue,
        maxThreshold: threshold
      };
    });

    return sortBy(agg, 'species');
  }, [filteredData]);

  const MiniPlot = ({ species, project }: { species: string; project: ProjectContextType }) => {
    if (isLoading) return <Skeleton w="200px" h="50px" />;

    const speciesData = filteredData
      .filter((d) => d['TessPlanktonLookup.species'] === species)
      .map((d) => ({
        ...d,
        threshold: thresholdForSpeciesValue(
          project,
          d['TessPlanktonLookup.species'],
          d['TessPlankton.avgCellCount']
        )
      }));

    const plotData: Partial<ScatterData> = {
      type: 'scatter',
      mode: 'lines+markers',
      name: species,
      x: speciesData.map((d) => d['TessPlankton.measuredAt']),
      y: speciesData.map((d) => d['TessPlankton.avgCellCount']),
      hoverinfo: 'y+x+text',
      marker: {
        size: 10,
        color: speciesData.map((d) => planktonThresholdToColor(d.threshold, { useHex: true })),
        line: {
          color: 'black',
          width: 0.5
        }
      }
    };

    const times = filteredData.map((d) => d['TessPlankton.measuredAt']);

    const plot: PlotlyDataLayoutConfig = {
      data: [plotData],
      layout: {
        autosize: true,
        hovermode: 'x unified',
        margin: { t: 0, r: 0, b: 20 },
        height: 100,
        showlegend: false,
        xaxis: {
          range: [sub(new Date(min(times)), { days: 1 }), add(new Date(max(times)), { days: 1 })]
        }
      },
      config: {
        displayModeBar: false
      }
    };

    return <Plot useResizeHandler={true} style={{ width: '100%' }} {...plot} />;
  };

  const sublocations = useMemo(() => {
    if (!resultSet?.rawData()) return [];

    const subs = uniq(resultSet.rawData().map((d) => d[locationDimension]));

    return subs.map((s) => ({
      label: settings.site?.smbId ? s : settings.project.siteNameMappings[s],
      value: s
    }));
  }, [resultSet]);

  if (isLoading) {
    return <Skeleton w="100" h="600px" />;
  }

  if (error) {
    return <GraphError minH="600px" />;
  }

  return (
    <VStack>
      {control ? control({ sublocations }) : <></>}
      <TableContainer w="100%">
        <VStack alignContent="center" w="100%">
          <Text>Risk Summary</Text>
          <HStack w="100%" justifyContent="space-evenly">
            <HStack>
              <CheckCircleIcon color="green.300" />
              <Text>{summaryCounts.safe} Safe Days</Text>
            </HStack>
            <HStack>
              <MinusIcon color="yellow.300" />
              <Text>{summaryCounts.caution} Caution Days</Text>
            </HStack>
            <HStack>
              <WarningIcon color="red.300" />
              <Text>{summaryCounts.danger} Dangerous Days</Text>
            </HStack>
          </HStack>
        </VStack>
        <Box maxH="600px" overflowY="scroll">
          <Table size="sm">
            <Thead zIndex={1} backgroundColor="gray.200" position="sticky" top="0">
              <Tr>
                <Th textAlign="center" w="5%">
                  Species
                </Th>
                <Th textAlign="center" w="85%">
                  Depth Average Concentration (Cells/mL)
                </Th>
                <Th textAlign="center" w="5%">
                  Avg Cells/mL
                </Th>
                <Th textAlign="center" w="5%">
                  Max Cells/mL
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {tableData.map((td, i) => (
                <Tr h="20px" key={i}>
                  <Td textAlign="center">{settings.project.findPlanktonPolicy(td.species).name}</Td>
                  <Td>
                    <MiniPlot species={td.species} project={settings.project} />
                  </Td>
                  <Td textAlign="center">{td.avgCellsMl.toFixed(1)}</Td>
                  <Td
                    textAlign="center"
                    backgroundColor={planktonThresholdToColor(td.maxThreshold)}>
                    {td.maxCellsMl.toFixed(1)}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </TableContainer>
    </VStack>
  );
};
