import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  UseModalProps,
  VStack
} from '@chakra-ui/react';
import { TimeFragment } from 'graphql/generated';
import { BaseChartSettings, ExploreChart, InflatedChart } from 'components/Charts/types';
import { FC, useMemo, useRef, useState } from 'react';
import ChartWithController from './ChartWithController';

type ChartEditorModalProps = {
  editingChart?: InflatedChart;
  charts: ExploreChart[];
  chartSettingsOverrides?: Partial<BaseChartSettings>;
  timeFragment?: TimeFragment;
  showSublocations?: boolean;
  onSave: (inflatedChart: InflatedChart, dateRange?: [string, string]) => void;
} & UseModalProps;

const ChartEditorModal: FC<ChartEditorModalProps> = ({
  editingChart,
  charts,
  chartSettingsOverrides,
  timeFragment,
  showSublocations,
  onSave,
  ...modalProps
}) => {
  const first = useMemo(() => charts?.[0], [charts]);
  //@ts-ignore
  const [selectedChart, setSelectedChart] = useState<ExploreChart>(first || editingChart);
  const [chartSettings, setChartSettings] = useState(
    editingChart?.settings ?? selectedChart.initSettings
  );

  const [currentDateRange, setCurrentDateRange] = useState<[string, string] | null>(null);

  // Really gross I know, but the only way I could get
  // The custom chart controller working reasonably well with
  // the 'preview' / useForm workflow.
  const customRef = useRef(null);

  return (
    <Modal size="full" {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Chart Editor</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack align="start">
            {charts.length > 0 && (
              <VStack>
                <Tabs
                  w="250px"
                  orientation="vertical"
                  onChange={(idx) => {
                    setSelectedChart(charts[idx]);
                    setChartSettings({ ...charts[idx].initSettings });
                  }}>
                  <TabList>
                    {charts.map((c) => {
                      return (
                        <Tab data-cypress={`chart-tab-${c.id}`} key={c.displayName}>
                          {c.displayName}
                        </Tab>
                      );
                    })}
                  </TabList>
                </Tabs>
              </VStack>
            )}
            <ChartWithController
              exploreChart={selectedChart}
              controllerRef={customRef}
              showSublocations={showSublocations}
              chartSettingsOverrides={chartSettingsOverrides}
              chartSettings={chartSettings}
              setChartSettings={setChartSettings}
              timeFragment={timeFragment}
              onDataLoaded={(_rs, dateRange) => setCurrentDateRange(dateRange)}
            />
          </HStack>
        </ModalBody>

        <ModalFooter>
          <Button
            data-cypress="chart-modal-save"
            colorScheme="blue"
            onClick={() => {
              let customSettings = null;
              if (customRef?.current?.getChartSettings) {
                customSettings = customRef.current.getChartSettings();
              }

              // Handle custom chart display name
              const displayName = selectedChart.custom
                ? customSettings?.displayName
                  ? customSettings.displayName
                  : 'Custom'
                : selectedChart.displayName;

              onSave(
                {
                  ...selectedChart,
                  displayName,
                  settings: { ...chartSettings, ...customSettings }
                },
                currentDateRange
              );
            }}>
            {editingChart ? 'Save' : 'Add'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChartEditorModal;
