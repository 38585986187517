import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  VStack
} from '@chakra-ui/react';
import { ReactElement, useRef } from 'react';

type ConfirmDeleteAlertProps = {
  isOpen: boolean;
  dialogHeader: ReactElement;
  onClose: () => void;
  onVerifyDelete: () => void;
};

const ConfirmDeleteAlert = ({
  isOpen,
  onClose,
  dialogHeader,
  onVerifyDelete
}: ConfirmDeleteAlertProps) => {
  const cancelRef = useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={undefined}
      isCentered={true}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {dialogHeader}
          </AlertDialogHeader>

          <AlertDialogBody>
            <VStack>
              <Text>Are you sure?</Text>
              <Text>You cannot undo this action.</Text>
            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button data-cypress="confirm-delete" colorScheme="red" onClick={onVerifyDelete} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ConfirmDeleteAlert;
