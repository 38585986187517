// @ts-strict
import { gql, useQuery } from '@apollo/client';
import { Skeleton } from '@chakra-ui/react';
import { ChartErrorText } from 'components/Charts/ChartErrorText';
import { ReportListing } from 'components/ReportListing/ReportListing';
import { HydrographyReport, Site } from 'graphql/generated';

export const GET_HYDRO_REPORTS = gql`
  query getHydrographyReports($siteId: Int!) {
    reports: getHydrographyReports(siteId: $siteId) {
      id
      reportTime
      lastUpdated
      reportedBy
      siteId
      formInput
      transactionId
    }
  }
`;

interface HydrographyReportListProps {
  site: Site;
  openReportForm: (report: HydrographyReport | null) => void;
  // TODO - formInfo is probably best obtained from the ProjectContext - MEA
  formInfo: {
    columns: Record<string, { label: string; value: string }[]>;
  };
}

export function HydrographyReportList({ site, openReportForm }: HydrographyReportListProps) {
  const reportsQuery = useQuery<{
    reports: HydrographyReport[];
  }>(GET_HYDRO_REPORTS, {
    variables: { siteId: site.id },
    pollInterval: 600000
  });
  if (reportsQuery.error) {
    return (
      <div className={'flex flex-column items-center'}>
        <ChartErrorText />
      </div>
    );
  }

  if (reportsQuery.loading) {
    return <Skeleton h="800px" />;
  }

  return (
    <ReportListing
      reports={reportsQuery.data?.reports || []}
      openReportForm={openReportForm}
      addLocationColumn={false}
      userCanCreateNew={true}
    />
  );
}
