import { gql, useMutation, useQuery } from '@apollo/client';
import { AddIcon, ArrowLeftIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Button,
  ButtonGroup,
  Center,
  Input,
  Skeleton,
  Text,
  VStack,
  Wrap,
  useToast
} from '@chakra-ui/react';
import { permissions } from '@scoot/permissions';
import NotificationListTile from 'components/Alarm/NotificationListTile';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { isEmpty, omit } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const GET_CONTACT_LISTS = gql`
  query ContactLists($projectId: Int!) {
    project(id: $projectId) {
      id
      contactLists {
        id
        name
        contacts
      }
    }
  }
`;

const SAVE_CONTACT_LIST = gql`
  mutation SaveContactList($id: Int, $contactList: ContactListArgs!) {
    saveContactList(id: $id, contactListArgs: $contactList) {
      id
      name
      contacts
    }
  }
`;

const DELETE_CONTACT_LIST = gql`
  mutation DeleteContactList($id: Int!) {
    deleteContactList(id: $id)
  }
`;

export type ContactList = {
  id?: number;
  name: string;
  contacts: string[];
};

const NotificationList = () => {
  const project = useContext(ProjectContext);
  const user = useContext(UserContext);
  const { data, loading } = useQuery(GET_CONTACT_LISTS, { variables: { projectId: project.id } });
  const [saveContactList] = useMutation(SAVE_CONTACT_LIST, { refetchQueries: ['ContactLists'] });
  const [deleteContactList] = useMutation(DELETE_CONTACT_LIST, {
    refetchQueries: ['ContactLists']
  });
  const [editingList, setEditingList] = useState<ContactList>();
  const [filter, setFilter] = useState<string>('');
  const [notificationLists, setNotificationLists] = useState<ContactList[]>([]);
  const toast = useToast();

  useEffect(() => {
    if (!loading && data) {
      setNotificationLists(data.project.contactLists);
    }
  }, [data, loading]);

  useEffect(() => {
    if (!data) return;
    if (!isEmpty(filter) && data?.project.contactLists.length > 0)
      setNotificationLists(
        data.project.contactLists.filter((nl) =>
          nl.name.toLowerCase().includes(filter.toLowerCase())
        )
      );
    else setNotificationLists(data.project.contactLists);
  }, [data, filter]);

  const onSave = async (
    formList: Omit<ContactList, 'contacts'> & { contacts: { value: string; type: string }[] }
  ) => {
    const list = {
      ...formList,
      contacts: formList.contacts.map((fc) => fc.value).filter((c) => !isEmpty(c))
    };
    try {
      await saveContactList({
        variables: { id: list?.id, contactList: { ...omit(list, 'id'), projectId: project.id } }
      });
      toast({
        status: 'success',
        description: 'Notification list saved successfully.'
      });
    } catch (e) {
      toast({
        status: 'error',
        description: 'Notification list could not be saved.'
      });
    } finally {
      setEditingList(null);
    }
  };

  const onDelete = async (list: ContactList) => {
    try {
      if (list.id) {
        await deleteContactList({
          variables: { id: list.id }
        });
      } else {
        setNotificationLists(notificationLists.filter((nl) => nl.id));
      }
      toast({
        status: 'success',
        description: 'Notification list deleted.'
      });
    } catch (e) {
      toast({
        status: 'error',
        description: 'Notification list could not be deleted.'
      });
    } finally {
      setEditingList(null);
    }
  };

  return (
    <>
      <Center>
        <Text fontSize="4xl" margin="1em 0">
          Notification Lists
        </Text>
      </Center>
      <ButtonGroup mb="15px">
        {permissions.canManageAlertsForProject(user, project.id) && (
          <Button
            data-cypress="new-list-btn"
            leftIcon={<AddIcon />}
            isDisabled={!!editingList}
            colorScheme="blue"
            onClick={() => {
              const template: ContactList = { name: '', contacts: [] };
              setNotificationLists([...notificationLists, template]);
              setEditingList(template);
            }}>
            New List
          </Button>
        )}
        <Link to={`/project/${project.id}/alarm`}>
          <Button leftIcon={<ArrowLeftIcon />}>Alarms</Button>
        </Link>
      </ButtonGroup>

      <Input
        isDisabled={!!editingList}
        placeholder="Filter..."
        onChange={(e) => {
          setFilter(e.currentTarget.value);
          setEditingList(null);
        }}
      />

      <Wrap mt="15px" spacing="15px">
        {loading && <Skeleton h="500px" />}
        {notificationLists.length > 0 ? (
          notificationLists.map((nl) => (
            <NotificationListTile
              key={nl.id}
              defaultList={nl}
              showEditButton={!editingList}
              onEditMode={() => setEditingList(nl)}
              editMode={nl.id === editingList?.id}
              onDelete={() => onDelete(nl)}
              onSave={onSave}
            />
          ))
        ) : (
          <Center w="100%" h="500px" border="1px" borderColor="blue.500" borderRadius="3px">
            <VStack>
              <InfoOutlineIcon boxSize={8} color="blue.500" />
              <Text>No notification lists found!</Text>
            </VStack>
          </Center>
        )}
      </Wrap>
    </>
  );
};

export default NotificationList;
