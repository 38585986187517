import { Container, Heading, HStack, Link } from '@chakra-ui/react';
import ProjectCard from 'components/Picker/ProjectCard';
import { UserContext } from 'contexts/UserContext';
import { cloneDeep, sortBy } from 'lodash';
import { useContext, useMemo } from 'react';
import { Link as ReactLink } from 'react-router-dom';

const ProjectPicker = () => {
  const user = useContext(UserContext);

  const sortedOrgProjects = useMemo(() => {
    const orgs = cloneDeep(sortBy(user.organizations, 'name'));
    orgs.forEach((o) => {
      o.projects = sortBy(o.projects, 'name');
    });

    return orgs;
  }, [user.organizations]);

  return (
    <Container maxWidth={['90vw', null, '80vw']} marginTop={10}>
      <Heading as="h1" size={'2xl'} fontWeight={'normal'} textAlign={'center'} marginBottom={10}>
        SeaState Projects
      </Heading>
      <HStack flexWrap="wrap" rowGap="20px" justifyContent="center">
        {sortedOrgProjects.map((o) => {
          return o.projects.map((p) => {
            return (
              <Link as={ReactLink} to={'/project/' + p.id} key={p.id + '_link'}>
                <ProjectCard
                  latitude={p.latitude as number}
                  longitude={p.longitude as number}
                  organizationName={o.name}
                  projectName={p.name}
                />
              </Link>
            );
          });
        })}
      </HStack>
    </Container>
  );
};

export default ProjectPicker;
