import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseModalProps,
  VStack
} from '@chakra-ui/react';
import FormInput from 'components/Forms/FormInput';
import FormTextarea from 'components/Forms/FormTextarea';
import { ChartSet } from 'graphql/generated';
import { useForm } from 'react-hook-form';
import { IoMdSave } from 'react-icons/io';
import { MdContentCopy } from 'react-icons/md';

type ChartSetModalProps = {
  chartSet?: ChartSet;
  onSubmit: (info: { name: string; description: string }, copy: boolean) => void;
} & UseModalProps;

export const ChartSetModal = (props: ChartSetModalProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      id: props?.chartSet?.id,
      name: props?.chartSet?.name ?? '',
      description: props.chartSet?.description ?? ''
    },
    reValidateMode: 'onSubmit'
  });

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Save Explore Chart Set</ModalHeader>
        <ModalBody>
          <VStack spacing={4} w="100%">
            <Text textAlign="center">
              Save the explore charts you have configured into a saved set for later retrival and
              quick reference.
            </Text>
            <FormInput
              label="Name"
              placeholder="Name of the chart set..."
              control={control}
              name="name"
              rules={{
                required: 'A name is required',
                minLength: { value: 1, message: 'A name is required' }
              }}
              borderColor={errors?.name ? 'red.500' : undefined}
            />

            <FormTextarea
              placeholder="Optional description..."
              control={control}
              name="description"
              label={'Description'}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <VStack w="100%">
            {errors?.name && <Text color="red.500">{errors.name.message}</Text>}
            <ButtonGroup w="100%" justifyContent="space-between">
              <Button onClick={props.onClose}>Cancel</Button>
              {props?.chartSet?.id && (
                <Button
                  leftIcon={<MdContentCopy />}
                  isDisabled={!props?.chartSet?.id}
                  onClick={handleSubmit((form) => props.onSubmit(form, true))}
                  colorScheme="blue">
                  Save Copy
                </Button>
              )}
              <Button
                leftIcon={<IoMdSave style={{ fontSize: '20px', marginBottom: '3px' }} />}
                onClick={handleSubmit((form) => props.onSubmit(form, false))}
                colorScheme="blue">
                {props?.chartSet?.id ? 'Overwrite' : 'Save'}
              </Button>
            </ButtonGroup>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
