import { gql, useMutation, useQuery } from '@apollo/client';
import { AddIcon, ChevronDownIcon, ChevronUpIcon, CopyIcon, EditIcon } from '@chakra-ui/icons';
import {
  VStack,
  HStack,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  ButtonGroup,
  Button,
  Skeleton,
  Badge,
  IconButton,
  useToast
} from '@chakra-ui/react';
import { Notice } from 'components/Notice';
import ProjectReportModal from 'components/Reporting/ProjectReportModal';
import TabHeadline from 'components/TabHeadline';
import { ProjectContext } from 'contexts/ProjectContext';
import { format, isAfter } from 'date-fns';
import { GetProjectReportsQuery, ProjectReport } from 'graphql/generated';
import { groupBy } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { TbReport } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

const CREATE_PROJECT_REPORT = gql`
  mutation CreateProjectReport($projectId: Int!) {
    createProjectReport(projectId: $projectId)
  }
`;

const CLONE_PROJECT_REPORT = gql`
  mutation CloneProjectReport($projectReportId: Int!) {
    cloneProjectReport(projectReportId: $projectReportId)
  }
`;

export const GET_PROJECT_REPORTS = gql`
  query GetProjectReports($projectId: Int!) {
    projectReports(projectId: $projectId) {
      id
      type
      name
      description
      content
      created
      url
      published
      parentProjectReportId
      createdBy {
        oryId
        name
      }
    }
  }
`;

const ReportingIndex = () => {
  const project = useContext(ProjectContext);
  const navigate = useNavigate();
  const toast = useToast();

  const [createProjectReport] = useMutation(CREATE_PROJECT_REPORT);
  const [cloneProjectReport] = useMutation(CLONE_PROJECT_REPORT);

  const { data, loading, error } = useQuery<GetProjectReportsQuery>(GET_PROJECT_REPORTS, {
    variables: { projectId: project.id }
  });

  const groupedSortedProjectReports = useMemo(() => {
    if (!data?.projectReports) return [];

    const reportGroups = groupBy(data.projectReports, (r) => r?.parentProjectReportId ?? r?.id);

    const srgs = Object.entries(reportGroups).reduce(
      (acc, [parentReportId, childReports]) => {
        const reports = [...childReports];

        reports.sort((ra, rb) => (isAfter(new Date(ra.created), new Date(rb.created)) ? -1 : 1));
        acc[parentReportId] = reports;

        return acc;
      },
      {} as { [id: string]: GetProjectReportsQuery['projectReports'] }
    );

    const topOrdered = Object.entries(srgs);
    topOrdered.sort((groupOne, groupTwo) =>
      isAfter(new Date(groupOne[1][0].created), new Date(groupTwo[1][0].created)) ? -1 : 1
    );

    return topOrdered;
  }, [data]);

  const [viewingReport, setViewingReport] = useState<Partial<ProjectReport> | null>();
  const [expandedReportRow, setExpandedReportRow] = useState<number | null>();

  const createReportAndNavigate = async () => {
    try {
      const resp = await createProjectReport({ variables: { projectId: project.id } });
      navigate(`/project/${project.id}/admin/reports/${resp.data.createProjectReport}/edit/select`);
    } catch (e) {
      console.error(e);
      toast({
        status: 'error',
        description: 'Error creating report'
      });
    }
  };

  return (
    <VStack w="100%" mb="2rem">
      <HStack alignItems={'start'}>
        <Text fontSize="4xl" mt="1em">
          Reports{' '}
        </Text>
        <Text color="blue.500" fontSize="xs" pt="40px">
          New!
        </Text>
      </HStack>
      <TabHeadline
        icon={<TbReport />}
        text="Generate reports and documents for external reporting and management."
      />

      <ButtonGroup py="20px" alignSelf="start">
        <Button
          data-cypress="new-report"
          colorScheme="blue"
          leftIcon={<AddIcon />}
          onClick={() => createReportAndNavigate()}>
          New Report
        </Button>
      </ButtonGroup>

      {error && (
        <Notice noticeColor="red.500">
          <Text>An error occured loading your reports.</Text>
        </Notice>
      )}

      {loading ? (
        <Skeleton h="600px" />
      ) : (
        <TableContainer w="100%">
          <Table layout="fixed" variant="striped">
            <Thead>
              <Tr>
                <Th w="50px"></Th>
                <Th w="100px">Status</Th>
                <Th w="100px">Type</Th>
                <Th>Name</Th>
                <Th>Created By</Th>
                <Th>Created</Th>
                <Th>Published</Th>
                <Th w="100px"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {groupedSortedProjectReports.flatMap(([reportId, groupedReports]) =>
                (expandedReportRow === Number(reportId) ? groupedReports : [groupedReports[0]]).map(
                  //@ts-ignore
                  (report: Partial<ProjectReport>, i: number) => (
                    <Tr
                      data-cypress="report-row"
                      onClick={() => {
                        setViewingReport(report as Partial<ProjectReport>);
                      }}
                      key={report.id}
                      _hover={{ cursor: 'pointer' }}>
                      <Td
                        style={
                          i !== 0
                            ? {
                                borderLeft: '10px solid',
                                borderLeftColor: 'var(--chakra-colors-blue-500)'
                              }
                            : {}
                        }>
                        {groupedReports?.length > 1 && i === 0 && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                (report?.parentProjectReportId ?? report.id) === expandedReportRow
                              )
                                setExpandedReportRow(null);
                              else setExpandedReportRow(report?.parentProjectReportId ?? report.id);
                            }}
                            variant="ghost"
                            icon={
                              (report?.parentProjectReportId ?? report.id) === expandedReportRow ? (
                                <ChevronUpIcon />
                              ) : (
                                <ChevronDownIcon />
                              )
                            }
                            aria-label={'Expand Report Row'}
                          />
                        )}
                      </Td>
                      <Td>
                        <Badge colorScheme={report.published ? 'green' : 'gray'}>
                          {report.published ? 'Published' : 'Draft'}
                        </Badge>
                      </Td>
                      <Td>
                        {!report.type ? (
                          '-'
                        ) : (
                          <Badge variant="outline" colorScheme="blue">
                            {report.type}
                          </Badge>
                        )}
                      </Td>
                      <Td overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                        {report.name ?? '-'}
                      </Td>
                      <Td>{report.createdBy.name}</Td>
                      <Td>{format(new Date(report.created), 'PP p')}</Td>
                      <Td>
                        {report?.published ? format(new Date(report?.published), 'PP p') : ''}
                      </Td>
                      <Td textAlign="center">
                        {!report.published ? (
                          <IconButton
                            onClick={() =>
                              navigate(
                                `/project/${project.id}/admin/reports/${report.id}/edit/details`
                              )
                            }
                            colorScheme="blue"
                            icon={<EditIcon />}
                            aria-label={'Edit Report'}
                          />
                        ) : (
                          <IconButton
                            onClick={async (e) => {
                              e.stopPropagation();
                              const resp = await cloneProjectReport({
                                variables: { projectReportId: report.id }
                              });
                              navigate(
                                `/project/${project.id}/admin/reports/${resp.data.cloneProjectReport}/edit/details`
                              );
                            }}
                            variant="outline"
                            colorScheme="green"
                            icon={<CopyIcon />}
                            aria-label={'Clone Report'}
                          />
                        )}
                      </Td>
                    </Tr>
                  )
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {viewingReport && (
        <ProjectReportModal
          isOpen={true}
          onClose={() => setViewingReport(null)}
          report={viewingReport}
        />
      )}
    </VStack>
  );
};

export default ReportingIndex;
