import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure
} from '@chakra-ui/react';
import ChartSwitchPanel from 'components/Charts/ChartSwitchPanel';
import ChartTile from 'components/Charts/ChartTile';
import EventWrapper from 'components/Charts/EventWrapper';
import SIWIArrowChart from 'components/Charts/SIWI/SIWIArrowChart';
import SIWICohort from 'components/Charts/SIWI/SIWICohort';
import SIWIDoughnut from 'components/Charts/SIWI/SIWIDoughnut';
import SIWIHistogram from 'components/Charts/SIWI/SIWIHistogram';
import { rawDataOptions, SIWIRawData } from 'components/Charts/SIWI/SIWIRawData';
import { default as SIWIStackedBar } from 'components/Charts/SiwiDailyStress/Chart';
import FAQModal from 'components/FAQ/FAQModal';
import { SIWIFaq } from 'components/FAQ/SIWIFAQ';
import InfoPopover from 'components/InfoPopover';
import Tile from 'components/Tile';
import { ProjectContextType } from 'contexts/ProjectContext';
import { Site } from 'graphql/generated';
import { useState } from 'react';
import Select from 'react-select';
import SIWIHeadlines from './SIWIHeadlines';

type SIWIProps = {
  site: Site;
  project: ProjectContextType;
};

const SIWI = ({ site, project }: SIWIProps) => {
  const [barChartCategoryToggle, setBarChartCategoryToggle] = useState(false);
  const [arrowChartWeightedToggle, setArrowChartWeightedToggle] = useState(false);
  const [selectedRawData, setSelectedRawData] = useState('none');
  const [faqIdx, setFaqIdx] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openModal = (idx = 0) => {
    setFaqIdx(idx);
    onOpen();
  };

  const leanMoreButton = (idx = 0, fontSize = 14) => (
    <Button variant="link" onClick={() => openModal(idx)} fontSize={fontSize} colorScheme="blue">
      Learn more...
    </Button>
  );

  const [selectedWindow, setSelectedWindow] = useState(30);
  const windowSizes = [
    { value: 7, label: '7 days' },
    { value: 14, label: '14 days' },
    { value: 30, label: '30 days' }
  ];

  return (
    <>
      <Box textAlign="center">
        <FAQModal data={SIWIFaq} isOpen={isOpen} onClose={onClose} idx={faqIdx} />
        <HStack justifyContent="center" spacing="5">
          <Box p="15px" borderRadius="full" boxShadow="base" w="fit-content">
            <Box color="blue.500">
              <Image src="/siwi_icon.jpg" h="60px" />
            </Box>
          </Box>
          <Image src="/siwi_logo.jpg" h="80px" />
          <Box w="90px" h="90px" />
        </HStack>
        <HStack justifyContent="center">
          <Box className="w-70">
            <Text fontSize="md" mt="15px" mb="10px" textAlign="center">
              The Scoot Integrated Welfare Index (SIWI) captures daily, site-level fish stress. SIWI
              takes raw data from across up to 10 different operational and environmental indicators
              and turns it into insights you can use to make more informed decisions and improve
              fish welfare. SIWI scores range from 0 (optimal conditions) to 100 (potentially lethal
              conditions).&nbsp;
              {leanMoreButton(0, 16)}
            </Text>
          </Box>
        </HStack>
        <Tile mb="1rem" p="1rem" textAlign="center" id="overview">
          <Heading as="h5" size="lg" mb="10px">
            Overview
          </Heading>
          <Flex flexWrap="wrap" justifyContent="space-evenly" alignItems="center" mb="10">
            <Box w="50%">
              <SIWIHeadlines smbSiteId={site.smbId} />
            </Box>
            <Box minW="600px" justifyContent="center">
              <HStack justify="center" alignContent="center">
                <Heading as="h3" size="md" textAlign="center" mb="5" mt="5">
                  Latest Daily Stress Index
                </Heading>
                <InfoPopover>
                  This is a high-level snapshot of the most recent daily SIWI stress total and the
                  corresponding indicator stress scores, to help with prioritizing the indicators
                  that are having the greatest impact on fish stress.
                  <UnorderedList>
                    <ListItem>
                      Color coding represents the indicator stress score (green is good, red is bad)
                    </ListItem>
                    <ListItem>
                      The importance (weight) of each indicator to the SIWI stress total is
                      represented by the width of each wedge and never changes. Indicators with a
                      higher &quot;contribution to total stress&quot; (hover over the wedge to view)
                      have a greater overall impact on stress
                    </ListItem>
                  </UnorderedList>
                  {leanMoreButton(2)}
                </InfoPopover>
              </HStack>
              <Box h="350px">
                <SIWIDoughnut smbSiteId={site.smbId} />
              </Box>
            </Box>
          </Flex>
          <Flex flexWrap="wrap" justifyContent="space-evenly" alignItems="top" mb="10">
            <Box minW="600px" w="48%">
              <HStack justify="center" alignContent="center">
                <Heading as="h3" size="md" textAlign="center" mb="5" mt="5">
                  Weekly Stress Changes
                </Heading>
                <InfoPopover>
                  The trend in each welfare indicator can help you identify environmental conditions
                  or areas of your operation to pay closer attention to in the upcoming week. The
                  change in SIWI stress total is also plotted as a reference for overall welfare.
                  <br></br>
                  <br></br>
                  For example, the oxygen indicator might have worsened from the previous 7-day
                  period to the current 7-day period, but the overall SIWI stress total may have
                  decreased if other indicator scores improved.<br></br>
                  {leanMoreButton(2)}
                </InfoPopover>
              </HStack>
              <ChartSwitchPanel
                switches={[
                  {
                    left: { value: 'unweighted', label: 'Change in Score' },
                    right: {
                      value: 'weighted',
                      label: 'Change in Contribution',
                      tooltip: (
                        <>
                          <Text fontWeight="bold">Indicator Score</Text>
                          <Text>
                            The individual indicator scores tell you the status of each indicator
                            and range from 0 (optimal conditions) to 100 (potentially lethal
                            conditions).
                          </Text>
                          <Text fontWeight="bold">Indicator Contribution</Text>
                          <Text>
                            The indicator contributions are weighted and tell you how much each
                            indicator contributes to the overall SIWI stress total.
                          </Text>
                        </>
                      )
                    },
                    toggle: ({ checked }) => setArrowChartWeightedToggle(checked)
                  }
                ]}
              />
              <Box h="450px" mt="2">
                <SIWIArrowChart smbSiteId={site.smbId} showWeighted={arrowChartWeightedToggle} />
              </Box>
            </Box>
            <Box minW="600px" w="48%">
              <HStack justify="center" alignContent="center">
                <Heading as="h3" size="md" textAlign="center" mb="5" mt="5">
                  Daily Stress (past 14 days)
                </Heading>
                <InfoPopover>
                  <Text>
                    This chart displays the recent history of SIWI stress total and the individual
                    indicator or category scores. You can see which indicators have had the greatest
                    contributions to total stress over the last two weeks, and which days were
                    particularly bad.&nbsp;
                    {leanMoreButton(2)}
                  </Text>
                  <Text>
                    <br></br>The Status Code beneath the plot indicates days with missing data or
                    when SIWI could not be calculated. See the FAQ for the full list of Status Code
                    definitions. {leanMoreButton(5)}
                  </Text>
                </InfoPopover>
              </HStack>
              <ChartSwitchPanel
                switches={[
                  {
                    left: { value: 'individual', label: 'Individual' },
                    right: { value: 'categories', label: 'Categories' },
                    toggle: ({ checked }) => setBarChartCategoryToggle(checked)
                  }
                ]}
              />
              <Box h="450px" mt="2">
                <EventWrapper site={site}>
                  <SIWIStackedBar
                    settings={{ site, project, showCategories: barChartCategoryToggle }}
                  />
                </EventWrapper>
              </Box>
            </Box>
          </Flex>
        </Tile>
        <ChartTile
          heading="Raw Data"
          site={site}
          control={
            <HStack mb="0.5rem" justifyContent="center">
              <Select
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    width: '300px'
                  }),
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    textAlign: 'left'
                  })
                }}
                onChange={(x) => setSelectedRawData(x.value)}
                options={rawDataOptions}
              />
            </HStack>
          }
          tooltip={
            'Select a data stream to view the raw data that is used to calculate the SIWI stress total.'
          }>
          <SIWIRawData site={site} project={project} selection={selectedRawData} />
        </ChartTile>
        <ChartTile
          heading="Historical SIWI"
          site={site}
          tooltip="These historical charts show the SIWI stress totals over each cohort's time at sea. 
Please note that any SIWI scores prior to the implementation of SIWI at your company are estimates and are intended to be used as a reference.">
          <Flex flexWrap="wrap" justifyContent="space-evenly" alignItems="center" mb="10">
            <Box minW="600px" w="48%">
              <HStack justify="center" alignContent="center">
                <Heading as="h3" size="md" textAlign="center" mb="5" mt="5">
                  Cohort Comparison
                </Heading>
                <InfoPopover>
                  <Text>
                    This chart shows the historic SIWI stress totals over a cohort&apos;s time at
                    sea. The default trends are a rolling average with a 30-day window and can be
                    adjusted via the drop down. Depending on the window selected, you will not see
                    data points for the first 7, 14, or 30-days.&nbsp;
                    {leanMoreButton(2)}
                  </Text>
                </InfoPopover>
              </HStack>
              <HStack justify="center">
                <Text>Rolling Window Time Frame: </Text>
                <Box w="fit-content">
                  <Select
                    onChange={(x) => setSelectedWindow(x.value)}
                    options={windowSizes}
                    value={windowSizes.find((x) => x?.value === selectedWindow)}
                  />
                </Box>
              </HStack>
              <Box h="450px" mt="2">
                <SIWICohort smbSiteId={site.smbId} rollingWindow={selectedWindow} />
              </Box>
            </Box>
            <Box minW="600px" w="48%">
              <HStack justify="center" alignContent="center">
                <Heading as="h3" size="md" textAlign="center" mb="5" mt="5">
                  Daily Stress Distribution
                </Heading>
                <InfoPopover>
                  <Text>
                    This chart shows the likelihood of a daily SIWI stress total values for current
                    and past cohorts at this site. The vertical dashed line marks where the most
                    recent SIWI stress total falls in this distribution.&nbsp;
                    {leanMoreButton(2)}
                  </Text>
                </InfoPopover>
              </HStack>
              <Box h={38} />
              <Box h="450px" mt="2">
                <SIWIHistogram smbSiteId={site.smbId} />
              </Box>
            </Box>
          </Flex>
        </ChartTile>
      </Box>
    </>
  );
};
export default SIWI;
