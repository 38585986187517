import { Skeleton } from '@chakra-ui/skeleton';
import useCubeLTG from 'hooks/useCubeLTG';
import { PlotlyDataLayoutConfig } from 'plotly.js';
import NoData from '../NoData';
import Plot from '../Plot';

const Chart = ({ smbSiteId }: { smbSiteId: number }) => {
  const gdbGraph = (data): PlotlyDataLayoutConfig => {
    return {
      data: [
        {
          name: '# of Data Points Received',
          x: data.map((d) => d['TessGdbStatus.measuredAt.hour']),
          y: data.map((d) => d['TessGdbStatus.sumCount'])
        }
      ],
      layout: {
        showlegend: true,
        legend: {
          orientation: 'h',
          x: 0,
          y: 1.25,
          traceorder: 'normal'
        }
      }
    };
  };

  const { plot: gdbPlot, isLoading: gdbLoading } = useCubeLTG({
    cubeQuery: {
      measures: ['TessGdbStatus.sumCount'],
      timeDimensions: [
        { dimension: 'TessGdbStatus.measuredAt', granularity: 'hour', dateRange: 'last 24 hours' }
      ],
      filters: [
        {
          member: 'TessGdbStatus.siteId',
          operator: 'equals',
          values: [smbSiteId.toString()]
        }
      ]
    },
    transform: (data) => data,
    graph: gdbGraph
  });

  if (gdbLoading) {
    return <Skeleton h="400px" w="100%" />;
  }

  // @ts-ignore
  if (gdbPlot?.data?.every((d) => d.x?.length === 0)) {
    return <NoData minH="400px" />;
  }

  return <Plot className="w-100" useResizeHandler={true} {...gdbPlot} />;
};

export default Chart;
