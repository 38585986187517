// TODO - Move config to .env files, delete unused FIREBASE_API_ROOT - MEA
//   https://create-react-app.dev/docs/deployment/#customizing-environment-variables-for-arbitrary-build-environments
const development = {
  api: {
    URL: 'http://localhost:4000',
    DASHBOARD_GQL_API: 'http://localhost:4000/graphql',
    CUBE_API_ENDPOINT: 'http://localhost:4000/cubejs-api/v1'
  },
  assetUrl: 'http://localhost:3000',
  ORY_URL: 'http://localhost:4009/.ory',
  SEASTATE_VERSION: 'dev'
};

const staging = {
  api: {
    URL: 'https://staging-dashboard.scootscience.com/api',
    DASHBOARD_GQL_API: 'https://staging-dashboard.scootscience.com/api/dashboard/graphql',
    CUBE_API_ENDPOINT: 'https://staging-dashboard.scootscience.com/api/cubejs-api/v1'
  },
  assetUrl: 'https://staging-dashboard.scootscience.com',
  ORY_URL: 'https://auth-staging.scootscience.com'
};

const production = {
  api: {
    URL: 'https://seastate.scootscience.com/api',
    DASHBOARD_GQL_API: 'https://seastate.scootscience.com/api/dashboard/graphql',
    CUBE_API_ENDPOINT: 'https://seastate.scootscience.com/api/cubejs-api/v1'
  },
  assetUrl: 'https://seastate.scootscience.com',
  ORY_URL: 'https://auth.scootscience.com'
};

let config = development;
if (process.env.REACT_APP_DASHBOARD_ENV === 'production') {
  config = production;
} else if (process.env.REACT_APP_DASHBOARD_ENV === 'staging') {
  config = staging;
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ASANA_BUG_FORM_URL: 'https://form.asana.com/?k=8I-LRaMLTaE36I_6iExTUw&d=13030245287964',
  ASANA_FEEDBACK_FORM_URL: 'https://form.asana.com/?k=foE7nMBJSqcZrDPtUpc-OA&d=13030245287964',
  MIN_PASSWORD_LENGTH: 8,
  SEASTATE_VERSION: process.env.REACT_APP_SEASTATE_VERSION,
  ...config
};
