import { Center, Heading, Image, Text, VStack } from '@chakra-ui/react';

export const ProjectSuspended = () => {
  return (
    <Center>
      <VStack mt="100px">
        <Heading>You&apos;ve gone belly up!</Heading>
        <Image borderRadius="full" src="/cartoon-salmon.png" h="300px" />
        <Text pt="20px" fontSize="2xl">
          Your project is currently suspended
        </Text>
        <Text>Please contact ScootScience if you would like your project resumed</Text>
        <Text fontStyle="italic">None of your data has been deleted or removed</Text>
        <Image src="/ScootScienceLogo.png" h="200px" />
      </VStack>
    </Center>
  );
};
