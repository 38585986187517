import { BellIcon, CheckCircleIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Divider, Spinner, Stack, Text } from '@chakra-ui/react';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { format } from 'date-fns';
import { AlarmState, GetRecentAlarmsQuery } from 'graphql/generated';
import useAudio from 'hooks/useAudio';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { MdOutlineCelebration } from 'react-icons/md';

type AlarmsNoticeProps = {
  previousAlarms?: GetRecentAlarmsQuery['alarmStatusCheckRuns'];
  alarms: GetRecentAlarmsQuery['alarmStatusCheckRuns'];
  error: boolean;
  loading: boolean;
};

const AlarmsNotice = ({ alarms, previousAlarms, error, loading }: AlarmsNoticeProps) => {
  const projectContext = useContext(ProjectContext);
  const user = useContext(UserContext);
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  const [playing, toggle] = useAudio('/audio/notif.mp3');

  const alerting = useMemo(() => {
    return alarms.filter((a) => a.state === AlarmState.Alert);
  }, [alarms]);

  useEffect(() => {
    if (!previousAlarms || !user.audioNotifications) return;
    if (
      alerting.length > previousAlarms?.filter((a) => a.state === AlarmState.Alert).length &&
      !playing &&
      !firstRender.current
    ) {
      toggle();
    }

    if (Notification.permission === 'granted') {
      const previousAlertIds = previousAlarms
        .filter((a) => a.state === AlarmState.Alert)
        .map((a) => `${a.alarmGroup.id}-${a.site.id}`);
      const newAlerts = alerting.filter((alert) => {
        const id = `${alert.alarmGroup.id}-${alert.site.id}`;
        return !previousAlertIds.includes(id);
      });

      newAlerts.forEach((a) => {
        const options: NotificationOptions = {
          body: a.alarmGroup.name.replace('{site}', a.site.name),
          icon: 'https://seastate.scootscience.com/ScootScienceLogo.png',
          tag: `${a.alarmGroup.id}-${a.site.id}`
        };

        new Notification('SeaState Alert', options);
      });
    }
  }, [alerting, previousAlarms]);

  return (
    <Menu closeOnSelect={false}>
      <MenuButton minW="50px" data-cypress="alarms-notice-menu" disabled={loading}>
        <BellIcon fontSize="24px" color="gray.500" />
        {error ? (
          <WarningTwoIcon color="yellow.500" />
        ) : loading ? (
          <Spinner size="sm" color="blue.200" />
        ) : alerting.length === 0 ? (
          <CheckCircleIcon color="green.500" />
        ) : (
          <WarningIcon color="red.500" />
        )}
      </MenuButton>

      <MenuList w="400px" maxH="400px" overflowY="auto">
        <MenuItem as="a" href={`/project/${projectContext.id}/alarm`} m="5px 0px" fontWeight="bold">
          <Text w="100%" textAlign="center">
            View Alarms
          </Text>
        </MenuItem>
        <Divider></Divider>
        {alerting.map((n, i) => {
          return (
            <MenuItem
              data-cypress={`alert-notice`}
              as="a"
              href={`/project/${projectContext.id}/site/${n.site.id}/alarm`}
              pt="10px"
              pb="10px"
              key={i}>
              <WarningIcon mb="3px" mr="5px" color="red.500" />
              <Stack data-cypress={`alert-${i}`} spacing="0">
                <Text fontSize="smaller" color="gray.500">
                  {format(new Date(n.checkedAt), 'PP p')}
                </Text>
                <Text>{n.alarmGroup.name.replace('{site}', n.site.name)}</Text>
              </Stack>
            </MenuItem>
          );
        })}
        {alerting.length === 0 && (
          <Stack data-cypress="no-alerting-alarms" spacing="0" mt="10px" alignItems="center">
            <MdOutlineCelebration
              style={{ fontSize: '50px', color: 'var(--chakra-colors-green-500)' }}
            />
            <Stack alignItems="center">
              <Text fontSize="lg">Congrats!</Text>
              <Text>You have no alarms in alert</Text>
            </Stack>
          </Stack>
        )}
        {error && (
          <MenuItem>
            <Text fontStyle="italic">There was an error fetching your current alarms.</Text>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

export default AlarmsNotice;
