import { Configuration, FrontendApi } from '@ory/client';
import config from 'config';

const basePath = config.ORY_URL;
const ory = new FrontendApi(
  new Configuration({
    basePath,
    baseOptions: {
      withCredentials: true
    }
  })
);

export default ory;
