import { IconButton } from '@chakra-ui/button';
import { DeleteIcon, WarningIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { FormatterProps } from 'react-data-grid';

const GridCellDelete = <TRow,>({
  shown,
  isCellSelected,
  row,
  onRowDelete
}: FormatterProps<TRow, unknown> & { shown: boolean; onRowDelete: (row: TRow) => void }) => {
  const [verifyingDelete, setVerifyingDelete] = useState(false);

  useEffect(() => {
    if (!isCellSelected) setVerifyingDelete(false);
  }, [isCellSelected]);

  const press = () => (verifyingDelete ? onRowDelete(row) : setVerifyingDelete(true));

  if (!shown) {
    return <></>;
  }

  return (
    <IconButton
      aria-label="remove row"
      icon={verifyingDelete ? <WarningIcon /> : <DeleteIcon />}
      size="sm"
      variant="unstyled"
      color="red.500"
      onClick={press}
      onKeyDown={(e) => e.key === 'Enter' && press()}
    />
  );
};

export default GridCellDelete;
