import { ResultSet } from '@cubejs-client/core';
import { safeFormat } from 'components/Site/Headline/Stats/HeadlineStats';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { DataTable } from './DataTable';

const CubeTable = <T extends object>({ resultSet }: { resultSet: ResultSet<T> }) => {
  const columns = useMemo(() => {
    const tableColumns: Column[] = resultSet.tableColumns().map((tc) => ({
      Header: tc.shortTitle,
      accessor: (datum: T) =>
        tc.type === 'time'
          ? format(new Date(datum[tc.dataIndex]), 'PP p')
          : tc.type === 'number'
            ? safeFormat(Number(datum[tc.dataIndex]))
            : datum[tc.dataIndex]
    }));

    return tableColumns;
  }, [resultSet.tableColumns]);

  const dateRange = useMemo(() => resultSet.query().timeDimensions[0].dateRange, [resultSet]);

  return (
    <DataTable
      tableLayout={{ maxH: '500px', overflowY: 'scroll' }}
      columns={columns}
      data={resultSet?.rawData()}
      caption={`${format(new Date(dateRange[0]), 'PP p')} - ${format(new Date(dateRange[1]), 'PP p')}`}
    />
  );
};

export default CubeTable;
