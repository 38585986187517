import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from '@chakra-ui/number-input';
import { EditorProps, useFocusRef } from 'react-data-grid';

// 'Tabbing' is still buggy and gets caught by the react-data-grid formatter
// See here for details: https://github.com/adazzle/react-data-grid/blob/6b2cb838c07f48cc0c4cd1640a9db2c09d4bc604/src/utils/keyboardUtils.ts
const GridNumberEditor = <TRow,>({ row, column, onRowChange, onClose }: EditorProps<TRow>) => {
  const { ref, tabIndex } = useFocusRef(true);

  const onChange = (s: string, num: number) => {
    // Hack to get decimals working
    if (s && s.split('.').length === 2 && s[s.length - 1] === '.') {
      onRowChange({ ...row, [column.key]: s });
    } else {
      onRowChange({ ...row, [column.key]: isNaN(num) ? 0 : num });
    }
  };

  return (
    <NumberInput
      // as="input"
      // tabIndex={-1}
      variant="unstyled"
      min={0}
      precision={2}
      defaultValue={null}
      onChange={onChange}
      onBlur={() => onClose(true)}
      value={row[column.key]}>
      <NumberInputField
        //@ts-ignore
        ref={ref}
        pt="7px"
        tabIndex={tabIndex}
        outline="none"
        border="none"
        borderRadius="none"
      />
      <NumberInputStepper pb="3px">
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};

export default GridNumberEditor;
