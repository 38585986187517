import { gql, useQuery } from '@apollo/client';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Divider,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Skeleton,
  Switch,
  Text
} from '@chakra-ui/react';
import UsershipChart from 'components/Charts/Usership/Chart';
import PageViewsChart from 'components/Charts/PageViews/Chart';
import { ProjectContext } from 'contexts/ProjectContext';
import { endOfDay, startOfDay, sub } from 'date-fns';
import { useContext, useMemo, useState } from 'react';
import BaseInput from 'components/Forms/BaseInput';

const USER_VIEW_INFO = gql`
  query ProjectViewing($projectId: Int!, $start: Date!, $end: Date) {
    project(id: $projectId) {
      id
      visits(start: $start, end: $end) {
        path
        count
      }
      usership(start: $start, end: $end) {
        datetime
        count
      }
    }
  }
`;

export const UserViewsSection = () => {
  const project = useContext(ProjectContext);
  const now = useMemo(() => new Date(), []);

  const [isExpanded, setIsExpanded] = useState(false);

  const [start, setStart] = useState(sub(now, { months: 1 }));
  const [end, setEnd] = useState(now);
  const [ignoreIds, setIgnoreIds] = useState(true);
  const [align, setAlign] = useState<'left' | 'right'>('left');
  const [sankeyHeight, setSankeyHeight] = useState(800);

  const { loading, data } = useQuery(USER_VIEW_INFO, {
    variables: {
      projectId: project.id,
      start: startOfDay(start).toISOString(),
      end: endOfDay(end).toISOString()
    },
    skip: !isExpanded
  });

  return (
    <Accordion allowToggle w="100%">
      <AccordionItem>
        <AccordionButton onClick={() => setIsExpanded(!isExpanded)}>
          <Text>Viewership Details</Text>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          {loading ? (
            <Skeleton w="100%" h="500px" />
          ) : (
            <Box>
              <HStack justifyContent="space-evenly">
                <BaseInput
                  w="200px"
                  label="Start"
                  type="date"
                  value={start.toISOString().split('T')[0]}
                  onChange={(e) => setStart(new Date(e.currentTarget.value))}
                />
                <BaseInput
                  w="200px"
                  label="End"
                  type="date"
                  value={end.toISOString().split('T')[0]}
                  onChange={(e) => setEnd(new Date(e.currentTarget.value))}
                />
              </HStack>
              <UsershipChart usership={data?.project.usership ?? []} chartRange={[start, end]} />
              <Divider w="100%" my="10px" />
              <Center mb="0px" w="100%">
                <HStack pt="4px">
                  <Text>Ignore Ids</Text>
                  <Switch
                    mb="0px"
                    pb="5px"
                    isChecked={ignoreIds}
                    onChange={() => setIgnoreIds(!ignoreIds)}
                  />
                </HStack>
                <HStack ml="50px" pt="4px">
                  <Text>Align Left</Text>
                  <Switch
                    mb="0px"
                    pb="5px"
                    isChecked={align === 'right'}
                    onChange={() => (align === 'left' ? setAlign('right') : setAlign('left'))}
                  />
                  <Text>Align Right</Text>
                </HStack>
                <HStack ml="50px" pt="4px">
                  <Text>Height</Text>
                  <NumberInput
                    allowMouseWheel
                    min={800}
                    step={100}
                    clampValueOnBlur={true}
                    value={sankeyHeight}
                    onChange={(_, num) => setSankeyHeight(num)}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </HStack>
              </Center>
              <PageViewsChart
                visits={data?.project.visits ?? []}
                ignoreIds={ignoreIds}
                align={align}
                height={sankeyHeight}
              />
            </Box>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default UserViewsSection;
