import { TimeDimensionGranularity } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { permissions } from '@scoot/permissions';
import { ProjectContextType } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { groupBy, uniq } from 'lodash';
import { Layout, LayoutAxis, PlotData } from 'plotly.js';
import { useCallback, useContext, useEffect, useState } from 'react';
import { forecastParamOptions } from 'shared/Config';
import { getResultSetsDateRange } from '../getResultSetsDateRange';
import { BaseChartProps } from '../types';
import { ChartSettings } from './Chart';

export type ParameterOptions = (
  | { label: 'Water Temperature'; value: 'waterTempAvg' }
  | { label: 'Oxygen Saturation'; value: 'oxygenSaturationAvg' }
  | { label: 'Salinity'; value: 'salinityAvg' }
  | { label: 'Oxygen Concentration'; value: 'oxygenConcentrationAvg' }
  | { label: 'Pycnocline Depth'; value: 'pycnoDepthAvg' }
  | { label: 'TKE (mixing force)'; value: 'tkeAvg' }
  | { label: 'Buoyancy Frequency (stability)'; value: 'buoyancyFreqAvg' }
)[];

type ForecastHydrographyCubeDatum = {
  'TessNewForecastHydrography.oxygenSaturationAvg': number;
  'TessNewForecastHydrography.salinityAvg': number;
  'TessNewForecastHydrography.waterTempAvg': number;
  'TessNewForecastHydrography.oxygenConcentrationAvg': number;
  'TessNewForecastHydrography.pycnoDepthAvg'?: number;
  'TessNewForecastHydrography.tkeAvg'?: number;
  'TessNewForecastHydrography.buoyancyFreqAvg'?: number;

  'TessNewForecastHydrographyLookup.depth': number;
  'TessNewForecastHydrographyLookup.ensemble': number;
  'TessNewForecastHydrography.measuredAt': string;
  'TessNewForecastHydrography.measuredAt.hour': string;
};

type HydrographyStructure = {
  [depth: string]: {
    [ensemble: string]: {
      measuredAt: string[];
      oxygenSaturationAvg: string[];
      salinityAvg: string[];
      waterTempAvg: string[];
      oxygenConcentrationAvg: string[];
      pycnoDepthAvg: string[];
      tkeAvg: string[];
      buoyancyFreqAvg: string[];
    };
  };
};

const transform = (
  data: ForecastHydrographyCubeDatum[],
  cube: 'TessNewForecastHydrography',
  granularity: TimeDimensionGranularity,
  parameters: ParameterOptions,
  selectedDepth: number
): HydrographyStructure => {
  const contourData = data.filter((d) => {
    return d[`${cube}Lookup.depth`] == selectedDepth;
  });
  const byEnsemble = groupBy(contourData, (d) => d['TessNewForecastHydrographyLookup.ensemble']);
  const byMeasuredAt = groupBy(contourData, (d) => d['TessNewForecastHydrography.measuredAt']);
  const tsData = Object.entries(byEnsemble).reduce((acc, [ens, ensData]) => {
    const measured = Object.values(ensData).map((d) => d['TessNewForecastHydrography.measuredAt']);
    const ensTimeseries = {
      measuredAt: measured
    };
    Object.values(parameters).map((d) => {
      ensTimeseries[d.value] = Object.values(ensData).map(
        (dd) => dd[`TessNewForecastHydrography.${d.value}`]
      );
    });
    acc[ens] = ensTimeseries;
    return acc;
  }, {});
  // Average across the ensemble
  tsData[-1] = {}; // use -1 to mark ensemble average
  Object.values(parameters).map((d) => {
    const ensAverage = Object.entries(byMeasuredAt).reduce((acc, [time, timeData]) => {
      // timeData has all ensembles, so must avg. the param
      const paramSum = Object.values(timeData).reduce(
        (sumAcc, dd) => sumAcc + dd[`TessNewForecastHydrography.${d.value}`],
        0
      );
      const paramAvg = paramSum / Object.values(timeData).length;
      acc[time] = paramAvg;
      return acc;
    }, {});
    tsData[-1][d.value] = Object.values(ensAverage);
  }, {});
  tsData[-1]['measuredAt'] = Object.keys(byMeasuredAt);
  return { [selectedDepth]: tsData };
};

const createLinePlots = (
  data: HydrographyStructure,
  project: ProjectContextType,
  options: {
    plotIdxOffset: number;
    xaxis: string;
    colorbarX?: number;
    selectedDepth: string;
    parameters: { value: string; label: string }[];
  }
) => {
  const layouts: Partial<LayoutAxis>[] = [];
  const plotData: Partial<PlotData>[] = Object.values(options.parameters).flatMap(
    (measurement, midx) => {
      const dataAtDepth = data[options.selectedDepth];
      layouts.push({
        title: { text: measurement.label, font: { size: 12 } }
      });
      return Object.entries(dataAtDepth ?? {}).map(([ensemble, ensembleData]) => {
        const cubeMeasurement = `${measurement.value}`;
        const measurementConfig = forecastParamOptions[cubeMeasurement];
        const plot: Partial<PlotData> = {
          name: ensemble === '-1' ? 'average' : ensemble,
          x: ensembleData.measuredAt,
          y: ensembleData[measurement.value],
          mode: 'lines+markers',
          hovertemplate:
            `<b>${measurement.value} ensemble ${ensemble === '-1' ? 'average' : ensemble}</br>` +
            `<b>depth ${options.selectedDepth}</br>` +
            `<b>%{y:.2f} ${measurementConfig.units} </b></br>` +
            `<b>Time: %{x} ${project.timezone} </b><br>` +
            '<extra></extra>',
          showscale: true,
          yaxis: `y${midx + 1}`,
          xaxis: options.xaxis,
          legendgroup: `y${midx + 1}`,
          line: {
            color: ensemble === '-1' ? 'black' : null,
            width: ensemble === '-1' ? 3 : 1.5
          },
          marker: {
            color: ensemble === '-1' ? 'black' : null,
            size: ensemble === '-1' ? 2 : 1
          }
        };

        return plot;
      });
    }
  );
  return {
    plotData,
    layouts
  };
};

const useHydrographyPlot = ({
  granularity = 'hour',
  chartRange,
  skip,
  refreshInterval,
  settings,
  onDataLoaded,
  setChartSettings
}: BaseChartProps<ChartSettings> & {
  setChartSettings: (chartSettings: ChartSettings) => void;
}) => {
  const parameterValues = settings.parameters.map((d) => d.value);
  const useForecasting = settings.project.forecasting;
  const graph = useCallback(
    (forecastData: HydrographyStructure, projectContext: ProjectContextType) => {
      let layouts: Partial<LayoutAxis>[] = [];
      const plotIdxOffset = 0;

      const forecastedPlots = createLinePlots(forecastData, projectContext, {
        plotIdxOffset,
        xaxis: 'x1',
        selectedDepth: settings.selectedDepth?.toFixed(0),
        parameters: settings.parameters
      });
      layouts = layouts.concat(forecastedPlots.layouts);
      const hasVisibility = 'buoyancyFreqAvg' in parameterValues;

      const yAxisCount = settings.parameters.length + (hasVisibility ? 1 : 0);
      const layout: Partial<Layout> = {
        grid: {
          rows: yAxisCount,
          columns: 1
        },
        height: (settings.parameters.length + (hasVisibility ? 1 : 0)) * 150 + 150,
        margin: { t: 10, b: 60, l: 50, r: 20 },
        hovermode: 'closest',
        autosize: true,
        xaxis: {
          showspikes: true,
          spikemode: 'across',
          spikecolor: 'black',
          hoverformat: '%Y-%m-%d %H:00',
          domain: [0, 1],
          title: {
            font: {
              size: 18
            }
          }
        },
        showlegend: false,
        legend: {
          orientation: 'v',
          // tracegroupgap: 100,
          x: 1,
          y: 0.09
        }
      };
      layouts.forEach((l, i) => {
        layout[`yaxis${i + 1}`] = l;
      });

      const data = forecastedPlots.plotData;

      return {
        data,
        layout
      };
    },
    [chartRange, settings.selectedDepth, settings.parameters]
  );
  const currentUser = useContext(UserContext);
  const variableOptions = permissions.isSuperuser(currentUser)
    ? [
        { label: 'Water Temperature', value: 'waterTempAvg' },
        { label: 'Oxygen Saturation', value: 'oxygenSaturationAvg' },
        { label: 'Salinity', value: 'salinityAvg' },
        { label: 'Oxygen Concentration', value: 'oxygenConcentrationAvg' },
        { label: 'Pycnocline Depth', value: 'pycnoDepthAvg' },
        { label: 'TKE (mixing force)', value: 'tkeAvg' },
        { label: 'Buoyancy Frequency (stability)', value: 'buoyancyFreqAvg' }
      ]
    : [
        { label: 'Water Temperature', value: 'waterTempAvg' },
        { label: 'Oxygen Saturation', value: 'oxygenSaturationAvg' },
        { label: 'Salinity', value: 'salinityAvg' },
        { label: 'Oxygen Concentration', value: 'oxygenConcentrationAvg' }
      ];
  const queryMeasuresSmall = variableOptions.map((param) => {
    return `TessNewForecastHydrography.${param.value}`;
  });
  const {
    isLoading: forecastLoading,
    error: forecastError,
    resultSet: forecastResult,
    refetch: forecastRefetch
  } = useCubeQuery<ForecastHydrographyCubeDatum>(
    {
      measures: queryMeasuresSmall,
      timeDimensions: [
        {
          dimension: 'TessNewForecastHydrography.measuredAt',
          granularity: 'hour',
          dateRange: settings.dateRange
        }
      ],
      dimensions: [
        'TessNewForecastHydrography.measuredAt',
        'TessNewForecastHydrographyLookup.depth',
        'TessNewForecastHydrographyLookup.ensemble'
      ],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: [settings.site.smbId.toString()]
        },
        { member: 'TessNewForecastHydrographyLookup.depth', operator: 'lte', values: ['0'] },
        {
          member: 'TessNewForecastHydrographyLookup.sublocation',
          operator: 'equals',
          values: ['house']
        }
      ],
      timezone: settings.project.timezone,
      limit: 50000
    },
    { skip: skip }
  );
  const [plot, setPlot] = useState(null);

  const start = chartRange?.[0].toISOString() ?? '';
  const end = chartRange?.[1].toISOString() ?? '';

  useEffect(() => {
    if (settings.project.forecasting && (forecastLoading || !forecastResult)) return;
    const forecast = forecastResult?.rawData() ?? [];
    if (onDataLoaded) {
      onDataLoaded(
        [forecastResult],
        getResultSetsDateRange(forecastResult, settings.project.timezone)
      );
    }

    const transformedForecasts = transform(
      forecast,
      'TessNewForecastHydrography',
      'hour',
      [...settings.parameters],
      settings.selectedDepth
    );
    const availableDepths =
      uniq(forecast.map((d) => d['TessNewForecastHydrographyLookup.depth'])).sort(
        (a, b) => a - b
      ) ?? [];

    setChartSettings({
      ...settings,
      availableDepths,
      selectedDepth: settings.selectedDepth
        ? settings.selectedDepth
        : availableDepths[availableDepths.length - 1]
    });
    setPlot(graph(transformedForecasts, settings.project));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    forecastLoading,
    forecastError,
    forecastResult,
    settings.project,
    useForecasting,
    granularity,
    settings.parameters,
    settings.selectedDepth,
    start,
    end
    // Causing endless render loop for some reason
    // onDataLoaded,
    // graph
  ]);

  useEffect(() => {
    if (refreshInterval) {
      const interval = setInterval(() => {
        forecastRefetch();
        // settings?.useSensor;
      }, refreshInterval);

      return () => clearInterval(interval);
    }
  }, [refreshInterval, forecastRefetch]);

  const hasData = settings.parameters.length > 0;

  return {
    isLoading: forecastLoading,
    error: forecastError,
    hasData,
    plot
  };
};

export default useHydrographyPlot;
