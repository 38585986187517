import { Box } from '@chakra-ui/react';
import { UTCDate } from '@date-fns/utc';
import { Usership } from 'graphql/generated';
import NoData from '../NoData';
import Plot from '../Plot';

const Chart = ({ usership, chartRange }: { usership: Usership[]; chartRange: [Date, Date] }) => {
  const plot = {
    data: [
      {
        mode: 'lines+markers',
        type: 'scattergl',
        name: 'Usership',
        x: usership.map((u) => new UTCDate(u.datetime)),
        y: usership.map((u) => u.count)
      }
    ],
    layout: {
      xaxis: {
        title: 'Time',
        range: chartRange
      },
      yaxis: {
        title: 'Approx. Page Views'
      }
    },
    autosize: true
  };

  if (usership.length === 0) {
    return (
      <Box w="100%" m="20px">
        <NoData minH="400px" />
      </Box>
    );
  }

  return <Plot className="w-100" useResizeHandler={true} {...plot} />;
};

export default Chart;
