import { gql, useMutation, useQuery } from '@apollo/client';
import { DeleteIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  IconButton,
  Input,
  Skeleton,
  Tag,
  Text,
  VStack,
  Wrap,
  useToast
} from '@chakra-ui/react';
import { AssignEquipmentModal } from 'components/Equipment/AssignEquipmentModal';
import { Notice } from 'components/Notice';
import { RenderError } from 'components/Pages/RenderError';
import { ProjectContext } from 'contexts/ProjectContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MdOutlineAssignment } from 'react-icons/md';

const GET_EQUIPMENT_ASSIGNMENTS = gql`
  query GetEquipmentAssignmentsForProject($projectId: Int!) {
    sites(projectId: $projectId) {
      id
      name
      equipment {
        id
        name
        details
        sublocation
        equipmentType {
          id
          name
          apiManaged
        }
      }
    }
  }
`;

const REMOVE_EQUIPMENT = gql`
  mutation RemoveEquipmentFromSite($id: Int!) {
    deleteSiteEquipment(siteEquipmentId: $id)
  }
`;

export const EquipmentAssign = () => {
  const project = useContext(ProjectContext);
  const toast = useToast();
  const [isAssigning, setIsAssigning] = useState(false);

  const { data, loading, refetch, error } = useQuery(GET_EQUIPMENT_ASSIGNMENTS, {
    variables: { projectId: project.id }
  });

  //Refetch every component mount. This allows deletions in Equipment admin
  //to propogate. I should probably manually update chache, but this works for now
  // see EquipmentAdmin#~92
  useEffect(() => {
    refetch();
  }, []);

  const [equipmentFilters, setEquipmentFilters] = useState<Record<number, string>>({});

  const [deleteSiteEquipment] = useMutation(REMOVE_EQUIPMENT, {
    refetchQueries: ['GetEquipmentAssignmentsForProject']
  });

  const sitesWithEquipment = useMemo(() => {
    return data?.sites?.filter((s) => s.equipment.length > 0) ?? [];
  }, [data]);

  const deleteEquipment = async (id: number) => {
    try {
      const resp = await deleteSiteEquipment({ variables: { id } });
      if (!resp.data) throw new Error('Error deleting equipment');
      toast({
        status: 'success',
        description: 'Successfully deleted equipment.'
      });
    } catch (e) {
      console.error(e);
      toast({
        status: 'error',
        description: 'Error deleting equipment.'
      });
    }
  };

  if (error) {
    return <RenderError />;
  }

  return (
    <VStack pl="5px" pt="20px" alignItems="start" w="100%">
      <Box mr="10px">
        <Button
          data-cypress="assign-equipment"
          w="100%"
          mb="10px"
          colorScheme="green"
          leftIcon={<MdOutlineAssignment size="20px" style={{ marginBottom: '2px' }} />}
          variant="solid"
          onClick={() => {
            setIsAssigning(true);
          }}>
          Assign Equipment
        </Button>
      </Box>

      {loading ? (
        <Skeleton w="100%" h="500px" />
      ) : (
        <Wrap w="100%" p="5px" mt="20px" spacing="15px" align="start">
          {sitesWithEquipment.length === 0 && !loading ? (
            <Notice>
              <Text fontSize="lg">
                No equipment assigned to sites yet. Select &quot;Assign Equipment&quot; to get
                started.
              </Text>
            </Notice>
          ) : (
            sitesWithEquipment.map((s) => {
              return (
                <Card data-cypress={`site-equipment-${s.id}`} w="400px" key={s.id}>
                  <CardHeader background="gray.100">
                    <Heading size="md">{s.name}</Heading>
                  </CardHeader>
                  <CardBody>
                    <Input
                      placeholder="Filter by sublocation or name..."
                      value={equipmentFilters[s.id]}
                      onChange={(e) =>
                        setEquipmentFilters({ ...equipmentFilters, [s.id]: e.currentTarget.value })
                      }
                    />
                    <VStack mt="10px" w="100%" overflowY={'scroll'} h={'420px'}>
                      {s.equipment
                        .filter((e) => {
                          if (!equipmentFilters[s.id]) return true;

                          return (
                            e.name.toLowerCase().includes(equipmentFilters[s.id].toLowerCase()) ||
                            e.sublocation
                              ?.toLowerCase()
                              .includes(equipmentFilters[s.id].toLowerCase())
                          );
                        })
                        .map((e) => {
                          return (
                            <Box
                              data-cypress="site-equipment-assignment"
                              w="100%"
                              borderWidth="1px"
                              borderColor="grey.500"
                              borderRadius="md"
                              p="10px"
                              key={e.id}>
                              {e.equipmentType.apiManaged && (
                                <Alert
                                  h="30px"
                                  overflow="visible"
                                  variant="left-accent"
                                  status="info">
                                  <AlertIcon />
                                  <Text pt="3px">API Managed</Text>
                                </Alert>
                              )}
                              <HStack justifyContent="space-between">
                                {e.sublocation && <Tag color="blue.500">{e.sublocation}</Tag>}
                                <Text pt="5px">
                                  {e.equipmentType.name} - {e.name}
                                </Text>
                                {!e.equipmentType.apiManaged && (
                                  <IconButton
                                    data-cypress="delete-equipment"
                                    onClick={() => deleteEquipment(e.id)}
                                    colorScheme="red"
                                    icon={<DeleteIcon />}
                                    aria-label={'Remove equipment'}
                                  />
                                )}
                              </HStack>
                              <Text mt="5px" w="100%" textAlign="center">
                                {e.details}
                              </Text>
                            </Box>
                          );
                        })}
                    </VStack>
                    <Text mt="20px" float="right" fontSize={'sm'}>
                      {s.equipment.length} equipment assigned.
                    </Text>
                  </CardBody>
                </Card>
              );
            })
          )}
        </Wrap>
      )}
      {isAssigning && <AssignEquipmentModal onClose={() => setIsAssigning(false)} />}
    </VStack>
  );
};
