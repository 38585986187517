import { gql, useQuery } from '@apollo/client';
import { Card, CardBody, HStack, Text, VStack } from '@chakra-ui/react';
import { Notice } from 'components/Notice';
import { ProjectContext } from 'contexts/ProjectContext';
import { format, isAfter, sub } from 'date-fns';
import { useContext, useMemo } from 'react';

const GET_RECENT_ALERTS = gql`
  query GetRecentProjectAlerts($projectId: Int!, $startTime: String!) {
    alarmGroups(projectId: $projectId) {
      id
      name
      siteAlarms {
        id
        site {
          id
          name
        }
        lastAlert(startTime: $startTime) {
          id
          checkedAt
          alarmGroup {
            id
            name
          }
        }
      }
    }
  }
`;

export const RecentAlerts = () => {
  const project = useContext(ProjectContext);

  const threeDaysAgo = useMemo(() => sub(new Date(), { days: 3 }), []);

  const { data } = useQuery(GET_RECENT_ALERTS, {
    variables: { projectId: project.id, startTime: threeDaysAgo.toISOString() }
  });

  const alerts = useMemo(() => {
    if (!data?.alarmGroups) return [];

    const checks: { name: string; checkedAt: string }[] = data.alarmGroups.flatMap((ag) => {
      return ag.siteAlarms
        .filter((sa) => sa.lastAlert !== null)
        .map((sa) => ({
          name: ag.name.replace('{site}', sa.site.name),
          checkedAt: format(new Date(sa.lastAlert.checkedAt), 'PP p')
        }));
    });

    checks.sort((a, b) => (isAfter(new Date(a.checkedAt), new Date(b.checkedAt)) ? -1 : 1));

    return checks;
  }, [data?.alarmGroups]);

  return (
    <VStack w="100%" spacing="0">
      <Text fontSize="2xl" mt="10px">
        Recent Alerts
      </Text>
      <Text color="blue.500" fontSize="sm">
        (Last 3 days)
      </Text>
      <HStack
        pb="15px"
        w="100%"
        maxW="100%"
        overflowX="scroll"
        justifyContent={alerts.length ? 'start' : 'center'}>
        {alerts.length === 0 && (
          <Notice w="700px" noticeColor="yellow.500">
            <Text>No Recent Alerts.</Text>
          </Notice>
        )}
        {alerts.map((a, i) => (
          <Card flexShrink={0} w="300px" borderWidth="1px" borderColor="red.300" key={i}>
            <CardBody>
              <VStack justifyContent="center">
                <Text>{a.name}</Text>
                <Text fontSize="xs" color="gray.500">
                  {a.checkedAt}
                </Text>
              </VStack>
            </CardBody>
          </Card>
        ))}
      </HStack>
    </VStack>
  );
};
