import { useQuery } from '@apollo/client';
import { ChartSettings } from 'components/Charts/Equipment/Chart';
import EquipmentController, { ControllerInputs } from 'components/Charts/Equipment/Controller';
import { ChartController } from 'components/Charts/types';
import { GetEquipmentOnSiteQuery } from 'graphql/generated';
import { useMemo } from 'react';
import { EquipmentTypeWithSiteEquipment } from '../../Equipment/Data';
import { GET_EQUIPMENT_ON_SITE } from '../../Equipment/EquipmentEntry';

const ExploreEquipmentController = (props: ChartController<ChartSettings, ControllerInputs>) => {
  const { data, loading } = useQuery<GetEquipmentOnSiteQuery>(GET_EQUIPMENT_ON_SITE, {
    variables: { siteId: props.chartSettings.site.id }
  });

  // Transform the siteEquipment into a data structure for equipment type to site equipment
  const byEquipmentType = useMemo(() => {
    return (
      data?.site.equipment.reduce((acc, curr) => {
        const existingEt = acc.find((et) => et.id === curr.equipmentType.id);
        if (existingEt) {
          existingEt.siteEquipment.push(curr);
        } else {
          acc.push({ ...curr.equipmentType, siteEquipment: [curr] });
        }

        return acc;
      }, [] as EquipmentTypeWithSiteEquipment[]) ?? []
    );
  }, [data]);

  if (loading) return <></>;

  return (
    <EquipmentController
      {...props}
      chartInputs={{
        ...props.chartInputs,
        equipmentTypes: byEquipmentType
      }}
    />
  );
};

export default ExploreEquipmentController;
