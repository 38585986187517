import { Center, Spinner, SpinnerProps } from '@chakra-ui/react';

const Loading = ({ size = 'xl', ...props }: SpinnerProps) => (
  <Center w="100%" height="100%">
    <Spinner
      className="rotate-spinner"
      thickness="2px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size={size}
      {...props}
    />
  </Center>
);

export default Loading;
