import { Box, Skeleton, Text } from '@chakra-ui/react';
import { Notice } from 'components/Notice';
import { useContext } from 'react';
import ProjectReportDetails from './ProjectReportDetails';
import ProjectReportReview from './ProjectReportReview';
import { ReportingEditContext } from './ReportingEditContext';
import SelectProjectReportTemplate from './SelectProjectReportTemplate';

const ProjectReportingEditContent = () => {
  const {
    activeStep,
    queryData: { loading, error }
  } = useContext(ReportingEditContext);

  if (loading) {
    return <Skeleton w="100%" h="400px" />;
  }

  if (error) {
    return (
      <Notice noticeColor="red.500">
        <Text>An error occured loading your report.</Text>
      </Notice>
    );
  }

  return (
    <Box w="100%" pt="20px">
      {activeStep === 1 && <SelectProjectReportTemplate />}
      {activeStep === 2 && <ProjectReportDetails />}
      {activeStep === 3 && <ProjectReportReview />}
    </Box>
  );
};

export default ProjectReportingEditContent;
