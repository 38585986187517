import { gql, useMutation } from '@apollo/client';
import { formatDate } from 'components/Forms/BaseInput';
import EntryFormModal from 'components/Forms/EntryFormModal';
import FormInput from 'components/Forms/FormInput';
import SearchableUserInput from 'components/Forms/SearchableUserInput';
import { TessASCSchema } from 'components/types';
import { ProjectContext } from 'contexts/ProjectContext';
import { AscReport, Site } from 'graphql/generated';
import { SUBMIT_DEVICE_VALUES } from 'graphql/globalMutations';
import { useContext } from 'react';
import { GET_ASC_REPORTS } from '../ASCReportList';
import EntryGrid from './EntryGrid';

const SAVE_ASC_REPORT = gql`
  mutation saveASCReport(
    $id: Int
    $reportTime: Date!
    $lastUpdated: Date!
    $reportedBy: String!
    $siteId: Int!
    $formInput: JSON!
    $transactionId: String!
  ) {
    saveASCReport(
      id: $id
      reportTime: $reportTime
      lastUpdated: $lastUpdated
      reportedBy: $reportedBy
      siteId: $siteId
      formInput: $formInput
      transactionId: $transactionId
    ) {
      id
      reportTime
      lastUpdated
      reportedBy
      siteId
      formInput
      transactionId
    }
  }
`;

const DELETE_ASC_REPORTS = gql`
  mutation deleteASCReports($ids: [Int!]!) {
    deleteASCReports(ids: $ids)
  }
`;

type EntryFormProps = {
  site: Site;
  report: AscReport;
  onClose: () => void;
};

export type Form = {
  id?: string;
  reportTime: string;
  reportedBy: string;
  measures: {
    measure: AscReference;
    value: number;
  }[];
};

export type AscReference = 'salinity' | 'temperature' | 'do-percent-saturation';

const EntryForm = ({ site, onClose, report }: EntryFormProps) => {
  const projectContext = useContext(ProjectContext);

  const defaultValues = {
    id: report?.id,
    reportTime: formatDate(report?.reportTime ? new Date(report?.reportTime) : new Date()),
    reportedBy: report?.reportedBy ?? '',
    measures: report?.formInput
      ? Object.entries(report.formInput.values).map(([key, value]) => ({ measure: key, value }))
      : [
          {
            measure: 'salinity'
          },
          {
            measure: 'temperature'
          },
          {
            measure: 'do-percent-saturation'
          }
        ]
  };

  const [saveReport] = useMutation(SAVE_ASC_REPORT, {
    refetchQueries: [GET_ASC_REPORTS, 'getASCReports']
  });

  const [deleteReport, { loading: isDeleting }] = useMutation(DELETE_ASC_REPORTS, {
    refetchQueries: [GET_ASC_REPORTS, 'getASCReports']
  });

  const [submitDeviceValues, { loading: isSubmittingSmb }] = useMutation(SUBMIT_DEVICE_VALUES);

  const submit = async (form: Partial<Form>) => {
    const items: TessASCSchema[] = [
      {
        site_id: Number(site.smbId),
        salinity: Number(form.measures?.filter((x) => x.measure == 'salinity')[0].value),
        water_temp: Number(form.measures?.filter((x) => x.measure == 'temperature')[0].value),
        oxygen_saturation: Number(
          form.measures?.filter((x) => x.measure == 'do-percent-saturation')[0].value
        ),
        measured_at: new Date(form.reportTime).toISOString()
      }
    ];

    const encodedItems = btoa(JSON.stringify(items));
    const response = await submitDeviceValues({
      variables: { tableName: 'tess_asc', values: encodedItems, action: 'INSERT' }
    });

    await saveReport({
      variables: {
        id: report ? report.id : null,
        reportedBy: form.reportedBy,
        reportTime: new Date(form.reportTime).toISOString(),
        lastUpdated: new Date().toISOString(),
        siteId: site.id,
        formInput: {
          reportedBy: form.reportedBy,
          values: form.measures.reduce((acc, curr) => {
            acc[curr.measure] = curr.value;
            return acc;
          }, {})
        },
        transactionId: response.data?.submitDeviceValues?.data?.transaction_id
      }
    });
  };
  const handleDelete = async () => {
    await submitDeviceValues({
      variables: {
        tableName: 'tess_asc',
        values: btoa(JSON.stringify({ where: [['transaction_id', '=', report?.transactionId]] })),
        action: 'DELETE'
      }
    });
    await deleteReport({ variables: { ids: [report.id] } });
  };

  return (
    <EntryFormModal
      siteName={site.name}
      entryName="ASC"
      defaultValues={defaultValues}
      isDeleting={isDeleting}
      //@ts-ignore
      onSubmit={submit}
      isSubmitting={isSubmittingSmb}
      onDelete={handleDelete}
      onClose={onClose}
      basicFields={(control) => (
        <>
          <SearchableUserInput projectId={projectContext.id} name="reportedBy" control={control} />

          <FormInput
            isDisabled={!!report}
            name="reportTime"
            control={control}
            label="Sample Time"
            rules={{ required: true }}
            type="datetime-local"
          />
        </>
      )}
      //@ts-ignore
      dataEntry={(control) => <EntryGrid name="measures" control={control} />}
    />
  );
};

export default EntryForm;
