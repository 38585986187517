import { Box, Flex, Heading, HStack, Text } from '@chakra-ui/react';
import { useCubeQuery } from '@cubejs-client/react';
import {
  calculateCategoryContribution,
  convertToIndex,
  filterBestResultForDay,
  safeFormat,
  siwiDatum,
  statusCodeMap
} from 'components/Charts/SIWI/SIWIShared';
import InfoPopover from 'components/InfoPopover';
import Stat from 'components/Stat';
import { ProjectContext } from 'contexts/ProjectContext';
import { useContext, useMemo } from 'react';

const last24hours = 'Last 24 hours';
const last7days = 'Last 7 days';
const currentCohort = 'Current Cohort';

const GetValidNumberOfRuns = (data: [siwiDatum, ...siwiDatum[]][]): number => {
  return data?.filter((d) => statusCodeMap[d['TessSiwi.statusCode']].level != 'error').length;
};

const SIWIHeadlines = ({ smbSiteId, skip }: { smbSiteId: number; skip?: boolean }) => {
  const projectContext = useContext(ProjectContext);

  const { resultSet: weeklySet } = useCubeQuery<siwiDatum>(
    {
      measures: [
        'TessSiwi.lS',
        'TessSiwi.tS',
        'TessSiwi.oS',
        'TessSiwi.mS',
        'TessSiwi.dS',
        'TessSiwi.salS',
        'TessSiwi.pTS',
        'TessSiwi.stoS',
        'TessSiwi.pMS',
        'TessSiwi.aS',
        'TessSiwi.pMW',
        'TessSiwi.dW',
        'TessSiwi.tW',
        'TessSiwi.pTW',
        'TessSiwi.lW',
        'TessSiwi.salW',
        'TessSiwi.oW',
        'TessSiwi.stoW',
        'TessSiwi.mW',
        'TessSiwi.aW',
        'TessSiwi.sT'
      ],
      dimensions: [
        'TessSiwi.pMKO',
        'TessSiwi.dKO',
        'TessSiwi.lKO',
        'TessSiwi.tKO',
        'TessSiwi.salKO',
        'TessSiwi.pTKO',
        'TessSiwi.oKO',
        'TessSiwi.mKO',
        'TessSiwi.aKO',
        'TessSiwi.stoKO',
        'TessSiwi.measuredAt',
        'TessSiwi.cohortId',
        'TessSiwi.statusCode'
      ],
      filters: [{ member: 'Site.id', operator: 'equals', values: [smbSiteId.toString()] }],
      timezone: projectContext.timezone,
      order: {
        'TessSiwi.measuredAt': 'desc'
      },
      timeDimensions: [
        {
          dimension: 'TessSiwi.measuredAt',
          granularity: 'day',
          compareDateRange: ['from 13 days ago to 7 days ago', 'from 6 days ago to now']
        }
      ]
    },
    { skip: skip, subscribe: true }
  );

  const cohortId = useMemo(() => {
    const thisWeek = Object.values(
      filterBestResultForDay(weeklySet?.decompose()[1].rawData(), 'TessSiwi')
    );
    // Failing SIWI runs are blank with only the status code and table key.
    // This grabs the first occuring cohort id in the current week
    return thisWeek.reduce((acc, d) => {
      return acc ? acc : d['TessSiwi.cohortId'];
    }, undefined);
  }, [weeklySet]);

  const { resultSet: cohortSet } = useCubeQuery<siwiDatum>(
    {
      measures: ['TessSiwi.sT'],
      timeDimensions: [
        {
          dimension: 'TessSiwi.measuredAt'
        }
      ],
      filters: [
        { member: 'Site.id', operator: 'equals', values: [smbSiteId] },
        { member: 'TessSiwi.cohortId', operator: 'equals', values: [cohortId] }
      ]
    },
    { skip: !cohortId }
  );
  const cohortIndex = useMemo(() => {
    return convertToIndex(cohortSet?.rawData()[0]['TessSiwi.sT']);
  }, [cohortSet]);

  const thisWeek = useMemo(() => {
    return Object.values(filterBestResultForDay(weeklySet?.decompose()[1].rawData(), 'TessSiwi'));
  }, [weeklySet]);

  const lastWeek = useMemo(() => {
    return Object.values(filterBestResultForDay(weeklySet?.decompose()[0].rawData(), 'TessSiwi'));
  }, [weeklySet]);

  const dailyIndex = useMemo(() => {
    const isKO = Object.keys(thisWeek?.[0] ?? [])
      .filter((key) => key.includes('KO'))
      .some((x) => thisWeek?.[0]?.[x]);
    return isKO ? 'KO' : convertToIndex(Object.values(thisWeek)?.[0]?.['TessSiwi.sT']);
  }, [thisWeek]);

  const dailyTrend = useMemo(() => {
    return convertToIndex(Object.values(thisWeek)?.[1]?.['TessSiwi.sT']) < dailyIndex;
  }, [thisWeek, dailyIndex]);

  const weeklyIndex = useMemo(() => {
    return convertToIndex(
      thisWeek?.reduce((acc, day) => {
        return acc + day['TessSiwi.sT'];
      }, 0) / GetValidNumberOfRuns(thisWeek)
    );
  }, [thisWeek]);

  const weeklyTrend = useMemo(() => {
    return (
      convertToIndex(
        lastWeek?.reduce((acc, day) => {
          return acc + day['TessSiwi.sT'];
        }, 0) / GetValidNumberOfRuns(lastWeek)
      ) < weeklyIndex
    );
  }, [lastWeek, weeklyIndex]);

  const envIndex = useMemo(() => {
    return (
      thisWeek?.reduce((acc, day) => {
        return acc + calculateCategoryContribution(day, 'environmental', 'TessSiwi');
      }, 0) / GetValidNumberOfRuns(thisWeek)
    );
  }, [thisWeek]);

  const envTrend = useMemo(() => {
    return (
      lastWeek?.reduce((acc, day) => {
        return acc + calculateCategoryContribution(day, 'environmental', 'TessSiwi');
      }, 0) /
        GetValidNumberOfRuns(lastWeek) <
      envIndex
    );
  }, [lastWeek, envIndex]);

  const opIndex = useMemo(() => {
    return (
      thisWeek?.reduce((acc, day) => {
        return acc + calculateCategoryContribution(day, 'operational', 'TessSiwi');
      }, 0) / GetValidNumberOfRuns(thisWeek)
    );
  }, [thisWeek]);

  const opTrend = useMemo(() => {
    return (
      lastWeek?.reduce((acc, day) => {
        return acc + calculateCategoryContribution(day, 'operational', 'TessSiwi');
      }, 0) /
        GetValidNumberOfRuns(lastWeek) <
      opIndex
    );
  }, [lastWeek, opIndex]);

  const mortIndex = useMemo(() => {
    return (
      thisWeek?.reduce((acc, day) => {
        return acc + calculateCategoryContribution(day, 'mortality', 'TessSiwi');
      }, 0) / GetValidNumberOfRuns(thisWeek)
    );
  }, [thisWeek]);

  const mortTrend = useMemo(() => {
    return (
      lastWeek?.reduce((acc, day) => {
        return acc + calculateCategoryContribution(day, 'mortality', 'TessSiwi');
      }, 0) /
        GetValidNumberOfRuns(lastWeek) <
      mortIndex
    );
  }, [lastWeek, mortIndex]);

  return (
    <>
      <Box textAlign="center">
        <HStack justify="center" alignContent="center">
          <Heading as="h3" size="md" textAlign="center" mb="5" mt="5">
            SIWI Stress Total
          </Heading>
          <InfoPopover>
            <Text>
              The summary tiles display SIWI stress totals averaged over various time periods
              (rounded to the nearest whole number).
              <br></br>
              <br></br>The arrow indicates trend in index since previous time period. <br></br>
              <br></br>Example: An upwards red arrow represents an increase in SIWI stress total, or
              worsening of welfare
            </Text>
          </InfoPopover>
        </HStack>
        <Flex flexWrap="wrap" justifyContent="center">
          <Stat
            label="Daily Stress Index"
            value={dailyIndex === 'KO' ? dailyIndex : safeFormat(dailyIndex)}
            timeLabel={last24hours}
            trendUp={dailyTrend}
          />
          <Stat
            label="Weekly Stress Index"
            value={safeFormat(weeklyIndex)}
            timeLabel={last7days}
            trendUp={weeklyTrend}
          />
          <Stat
            label="Cohort Stress Index"
            value={safeFormat(cohortIndex)}
            timeLabel={currentCohort}
          />
        </Flex>

        <HStack justify="center" alignContent="center">
          <Heading as="h3" size="md" textAlign="center" m="5">
            Contributions to Weekly Stress Index by Category
          </Heading>
          <InfoPopover>
            <Text>
              Category stress contributions add up to the weekly SIWI stress total above (rounded to
              the nearest whole number). Each category includes a unique set of stress indicators:
              <br></br>
              <br></br>
            </Text>
            <Text fontWeight="bold">Environmental Stress:</Text>
            <Text>Temperature, Oxygen, Salinity, Mechanical & Toxic Plankton, Lice</Text>
            <Text fontWeight="bold">Operational Stress:</Text>
            <Text>Appetite, Stocking Density, Disturbance</Text>
            <Text fontWeight="bold">Mortality Stress:</Text>
            <Text>Mortality</Text>
          </InfoPopover>
        </HStack>
        <Flex flexWrap="wrap" justifyContent="center">
          <Stat
            label="Environment Contribution"
            value={safeFormat(envIndex)}
            timeLabel={last7days}
            trendUp={envTrend}
          />
          <Stat
            label="Operations Contribution"
            value={safeFormat(opIndex)}
            timeLabel={last7days}
            trendUp={opTrend}
          />
          <Stat
            label="Mortality Contribution"
            value={safeFormat(mortIndex)}
            timeLabel={last7days}
            trendUp={mortTrend}
          />
        </Flex>
      </Box>
    </>
  );
};

export default SIWIHeadlines;
