import { gql, useMutation } from '@apollo/client';
import { Text, VStack } from '@chakra-ui/react';
import EntryFormModal from 'components/Forms/EntryFormModal';
import FormInput from 'components/Forms/FormInput';
import FormSelect from 'components/Forms/FormSelect';
import SearchableUserInput from 'components/Forms/SearchableUserInput';
import Tile from 'components/Tile';
import { TessLiceSchema } from 'components/types';
import { ProjectContext } from 'contexts/ProjectContext';
import { SeaLiceReport, Site } from 'graphql/generated';
import { SUBMIT_DEVICE_VALUES } from 'graphql/globalMutations';
import { range, sum } from 'lodash';
import { useContext } from 'react';
import { GET_SEALICE_REPORTS } from '../SeaLiceDataEntryTab/SeaLiceReportList';
import EntryGrid from './EntryGrid';
import ToteCounts from './ToteCounts';

const DELETE_SEALICE_REPORT = gql`
  mutation deleteSeaLiceReports($ids: [Int!]!) {
    deleteSeaLiceReports(ids: $ids)
  }
`;

const SAVE_SEALICE_REPORT = gql`
  mutation saveSeaLiceReport(
    $id: Int
    $reportTime: Date!
    $lastUpdated: Date!
    $reportedBy: String!
    $siteId: Int!
    $sublocation: String
    $formInput: JSON!
    $transactionId: String!
  ) {
    saveSeaLiceReport(
      id: $id
      reportTime: $reportTime
      lastUpdated: $lastUpdated
      reportedBy: $reportedBy
      siteId: $siteId
      sublocation: $sublocation
      formInput: $formInput
      transactionId: $transactionId
    ) {
      id
      reportTime
      lastUpdated
      reportedBy
      siteId
      sublocation
      formInput
      transactionId
    }
  }
`;

type EntryFormProps = {
  site: Site;
  report?: SeaLiceReport;
  onClose: () => void;
};

export type Form = {
  id: number;
  info: {
    reporter: string;
    sampleTime: string;
    sublocation: { label: string; value: string } | string;
  };
  fishCounts: {
    [fishIndex: number]: number | string;
  }[];
  toteCounts: {
    [liceStage: string]: number | string;
  };
};

const formatDate = (date?: Date) =>
  date
    ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, -5)
    : '';

const EntryForm = ({ site, report, onClose }: EntryFormProps) => {
  const projectContext = useContext(ProjectContext);

  const defaultValues = {
    id: report?.id ?? null,
    info: {
      reporter: report?.reportedBy ?? '',
      sampleTime: formatDate(report?.reportTime ? new Date(report?.reportTime) : new Date()),
      sublocation: report?.sublocation
        ? { label: report.sublocation, value: report.sublocation }
        : site.sampleLocations?.length > 0
          ? { label: site.sampleLocations[0], value: site.sampleLocations[0] }
          : null
    },
    fishCounts:
      report?.formInput.counts.length > 0
        ? [...report.formInput.counts, {}]
        : range(20).map((i) => ({ fishIndex: i + 1 })),
    toteCounts: report?.formInput.toteCounts ?? {}
  };

  const [saveReport] = useMutation(SAVE_SEALICE_REPORT, {
    refetchQueries: [GET_SEALICE_REPORTS, 'getSeaLiceReports']
  });

  const [deleteReport, { loading: isDeleting }] = useMutation(DELETE_SEALICE_REPORT, {
    refetchQueries: [GET_SEALICE_REPORTS, 'getSeaLiceReports']
  });

  const [submitDeviceValues, { loading: isSubmittingSmb }] = useMutation(SUBMIT_DEVICE_VALUES);

  const submit = async (form: Form) => {
    const items: TessLiceSchema[] = [];
    Object.keys(form.toteCounts).forEach((liceStage) => {
      const item = form.fishCounts.reduce(
        (item: TessLiceSchema, fish) => {
          item.lice_count += liceStage in fish ? fish[liceStage] : 0;
          return item;
        },
        {
          lice_count: 0,
          measured_at: new Date(form.info.sampleTime).toISOString(),
          number_of_fish: form.fishCounts.filter((x) => Object.keys(x).length >= 1).length,
          site_id: Number(site.smbId),
          stage_id: liceStage,
          // @ts-ignore
          sublocation: form.info.sublocation?.value
        }
      );
      item.lice_count += Number(form.toteCounts[liceStage]);
      items.push(item);
    });
    const encodedItems = btoa(JSON.stringify(items));
    const response = await submitDeviceValues({
      variables: { tableName: 'tess_lice', values: encodedItems, action: 'INSERT' }
    });

    await saveReport({
      variables: {
        id: report?.id,
        reportedBy: form.info.reporter,
        reportTime: new Date(form.info.sampleTime).toISOString(),
        lastUpdated: new Date().toISOString(),
        siteId: site.id,
        //@ts-ignore
        sublocation: form.info.sublocation.value,
        formInput: {
          reportedBy: form.info.reporter,
          counts: form.fishCounts,
          averageLice: averageMotileLice(form),
          toteCounts: form.toteCounts
        },
        transactionId: response.data?.submitDeviceValues?.data?.transaction_id
      }
    });
  };

  const handleDelete = async () => {
    await submitDeviceValues({
      variables: {
        tableName: 'tess_lice',
        values: btoa(JSON.stringify({ where: [['transaction_id', '=', report?.transactionId]] })),
        action: 'DELETE'
      }
    });

    await deleteReport({ variables: { ids: [report.id] } });
  };

  const sampleLocationsOptions = site.sampleLocations.map((sl) => ({ label: sl, value: sl }));

  const totalMotileLice = (form: Form) => {
    return (
      form.fishCounts.reduce((acc, liceStage) => {
        // Drop the fishIndex from the calculated values
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { fishIndex, ...values } = liceStage;
        return acc + sum(Object.values(values));
      }, 0) + sum(Object.values(form.toteCounts))
    );
  };

  const averageMotileLice = (form: Form) => {
    return (totalMotileLice(form) / form.fishCounts.length).toFixed(2);
  };

  return (
    <EntryFormModal
      siteName={site.name}
      entryName="Sea Lice"
      defaultValues={defaultValues}
      isDeleting={isDeleting}
      isSubmitting={isSubmittingSmb}
      onSubmit={submit}
      onDelete={handleDelete}
      onClose={onClose}
      basicFields={(control, watch) => (
        <VStack spacing={5}>
          <SearchableUserInput
            projectId={projectContext.id}
            name="info.reporter"
            control={control}
          />

          <FormSelect
            name="info.sublocation"
            label="Sampling Location"
            rules={{ required: true }}
            options={sampleLocationsOptions}
            control={control}
          />

          <FormInput
            isDisabled={!!report}
            name="info.sampleTime"
            control={control}
            label="Sample Time"
            rules={{ required: true }}
            type="datetime-local"
          />

          <Tile p="20px">
            {/* close enough
          // @ts-ignore */}
            <Text>Total Motile Lice: {totalMotileLice(watch())}</Text>
            {/* close enough
          // @ts-ignore */}
            <Text>Average Lice / Fish: {averageMotileLice(watch())}</Text>
          </Tile>
        </VStack>
      )}
      dataEntry={(control) => (
        <>
          <EntryGrid
            name="fishCounts"
            //@ts-ignore
            control={control}
            liceColumns={projectContext.dataEntry.seaLice.fishLiceStages}
          />
          <ToteCounts
            //@ts-ignore
            control={control}
            liceStages={projectContext.dataEntry.seaLice.fishLiceStages}
          />
        </>
      )}
    />
  );
};

export default EntryForm;
