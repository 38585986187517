import { Skeleton } from '@chakra-ui/react';
import GraphError from '../GraphError';
import NoData from '../NoData';
import Plot from '../Plot';
import { BaseChartProps } from '../types';
import useRiverTrace from './useRiversTrace';
import NotIncluded from '../NotIncluded';
import { RiverChartInputs } from './Controller';
import { UseRiverTraceParams } from './useRiversTrace';

const Chart = (props: BaseChartProps<UseRiverTraceParams, RiverChartInputs>) => {
  const {
    isLoading: riverLoading,
    error: riverError,
    plot: riverPlot,
    allStationNames
  } = useRiverTrace({
    dateRange: 'from 7 days ago to 1 days from now',
    settings: props.settings,
    ...props
  });

  if (riverLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (riverError) {
    return <GraphError minH="450px" />;
  }

  return riverPlot?.data?.length ? (
    <>
      {props.control ? props.control({ allRiverStations: allStationNames }) : <></>}
      <Plot className="w-100 h-500 rivers-plot" useResizeHandler={true} {...riverPlot} />
    </>
  ) : riverPlot?.data?.length === 0 && props.settings.project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <>
      {props.control ? props.control({ allRiverStations: allStationNames }) : <></>}
      <NoData minH="450px" />
    </>
  );
};

export default Chart;
