import { Box, Button } from '@chakra-ui/react';
import { permissions } from '@scoot/permissions';
import EventForm from 'components/Site/Tabs/Events/EventForm';
import { UserContext } from 'contexts/UserContext';
import { Project, Site } from 'graphql/generated';
import { FC, MouseEventHandler, cloneElement, useContext, useState } from 'react';

type EventWrapperProps = {
  site?: Site;
  project?: Project;
};

const EventWrapper: FC<EventWrapperProps> = ({ children, site }) => {
  const currentUser = useContext(UserContext);
  const canManageEvents = site ? permissions.canManageEventsForSite(currentUser, site) : false;
  const [contextMenu, setContextMenu] = useState({ showing: false, x: 0, y: 0 });
  const [showingEventForm, setShowingEventForm] = useState(false);
  const [hoverDate, setHoverDate] = useState<Date | null>(null);

  const onContextMenuClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.type !== 'contextMenu' && contextMenu.showing) {
      setContextMenu({
        ...contextMenu,
        showing: false
      });
    }

    if (canManageEvents && e.type === 'contextmenu') {
      e.preventDefault();
      setContextMenu({
        showing: true,
        x: e.pageX,
        y: e.pageY
      });
    }
  };

  const onCreateEvent = () => {
    setShowingEventForm(true);
    setContextMenu({
      ...contextMenu,
      showing: false
    });
  };

  const onHover = ({ xvals }) => {
    if (xvals && xvals.length > 0) {
      setHoverDate(new Date(xvals[0]));
    }
  };

  //@ts-ignore
  const plot = cloneElement(children, {
    onHover
  });

  return (
    <>
      <Box onClick={onContextMenuClick} onContextMenu={onContextMenuClick}>
        {contextMenu.showing && (
          <Box
            style={{
              position: 'absolute',
              zIndex: 9999,
              left: contextMenu.x,
              top: contextMenu.y
            }}>
            <Button onClick={onCreateEvent} colorScheme="blue">
              Create Event
            </Button>
          </Box>
        )}
        {plot}
      </Box>
      {showingEventForm && (
        <EventForm
          site={site}
          onClose={() => setShowingEventForm(false)}
          event={{ startTime: hoverDate }}
        />
      )}
    </>
  );
};

export default EventWrapper;
