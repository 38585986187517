import { ProjectContextType } from 'contexts/ProjectContext';

export type ThresholdClass = 'threshold-lethal' | 'threshold-caution' | 'threshold-safe';

export type PlanktonThreshold = 'safe' | 'caution' | 'danger';

export const thresholdForSpeciesValue = (
  project: ProjectContextType | null,
  speciesKey: string,
  value?: number
): PlanktonThreshold | null => {
  if (!project) return null;
  if (isNaN(value)) return null;
  const policy = project.findPlanktonPolicy(speciesKey);

  if (!policy) {
    return null;
  }

  if (value < policy.caution) return 'safe';
  else if (value >= policy.caution && value < policy.danger) {
    return 'caution';
  } else {
    return 'danger';
  }
};

// The "threshold value" is defined as the percentage beyond whichever threshold the count has crossed
export const planktonThresholdValue = (
  project: ProjectContextType,
  speciesKey: string,
  count: number
) => {
  const lowerThreshold = thresholdForSpeciesValue(project, speciesKey, count);
  const policy = project.findPlanktonPolicy(speciesKey);

  if (!policy) {
    return null;
  }

  let lowerCount = 0;
  let higherCount = 0;

  if (lowerThreshold === 'safe') {
    higherCount = policy.caution;
  } else {
    lowerCount = policy.caution;
    higherCount = policy.danger;
  }

  return (count - lowerCount) / (higherCount - lowerCount);
};

export const planktonThresholdToColor = (
  threshold: PlanktonThreshold,
  { useHex }: { useHex: boolean } = { useHex: false }
) => {
  if (threshold === 'safe') return !useHex ? 'green.300' : '#68D391';
  if (threshold === 'caution') return !useHex ? 'yellow.300' : '#F6E05E';
  if (threshold === 'danger') return !useHex ? 'red.300' : '#FC8181';
  return !useHex ? 'gray.300' : '#E2E8F0';
};
