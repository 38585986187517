import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { Card, CardBody, CardHeader, Divider, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { THRESHOLD_LEVEL } from 'components/types';
import { useMemo } from 'react';
import { GiBubbles } from 'react-icons/gi';
import { MapSite } from '../useMapSites';

const CAGE_DISPLAY_LIMIT = 5;

const colors = {
  0: 'blue.500',
  1: 'green.500',
  2: 'orange.500',
  3: 'red.500'
};

const varColors = {
  0: 'var(--chakra-colors-blue-500)',
  1: 'var(--chakra-colors-green-500)',
  2: 'var(--chakra-colors-orange-500)',
  3: 'var(--chakra-colors-red-500)'
};

const thresholdDisplay = {
  0: 'No Data',
  1: 'Safe',
  2: 'Caution',
  3: 'Danger'
};

export const OxygenCard = ({ site }: { site: MapSite }) => {
  const noOxygen = useMemo(() => {
    return site.oxygenAlerts.every((a) => a.level === THRESHOLD_LEVEL.NO_DATA);
  }, [site.oxygenAlerts]);

  const topOxygenAlert = useMemo(() => {
    return site.oxygenAlerts?.[0]?.level ?? THRESHOLD_LEVEL.NO_DATA;
  }, [site.oxygenAlerts]);

  const oxygenNonSafe = useMemo(() => {
    return site.oxygenAlerts.filter((oa) => oa.level > THRESHOLD_LEVEL.SAFE);
  }, [site.oxygenAlerts]);

  return (
    <Card minW="200px">
      <CardHeader pb="0" textAlign="center">
        <Text fontSize="sm">Oxygen Saturation</Text>
        <Divider mb="10px" />
        <VStack fontSize="lg">
          <GiBubbles aria-label="Oxygen Level" color={varColors[topOxygenAlert]} fontSize="40px" />
          {topOxygenAlert !== THRESHOLD_LEVEL.NO_DATA && (
            <Text fontSize="xxlg" color={colors[topOxygenAlert]}>
              {thresholdDisplay[topOxygenAlert]}
            </Text>
          )}
        </VStack>
      </CardHeader>
      <CardBody textAlign="center">
        {oxygenNonSafe.length === 0 && !noOxygen ? (
          <VStack>
            <CheckCircleIcon fontSize="xx-large" color="green.500" />
            <Text fontWeight="bold" color="green.500" fontSize="sm">
              Healthy Oxygen!
            </Text>
          </VStack>
        ) : noOxygen ? (
          <>
            <VStack>
              <WarningIcon fontSize="xx-large" color="yellow.500" />
              <Text fontWeight="bold" fontSize="sm">
                No Oxygen recorded
              </Text>
            </VStack>
          </>
        ) : (
          <SimpleGrid w="100%" columns={2}>
            {site.oxygenAlerts.slice(0, CAGE_DISPLAY_LIMIT).map((a) => (
              <>
                <Text fontWeight="bold" color={colors[a.level]} align="start" fontSize="sm">
                  {thresholdDisplay[a.level]}
                </Text>
                <Text align="end" fontSize="sm">
                  {a.sublocation}
                </Text>
              </>
            ))}
          </SimpleGrid>
        )}
        {oxygenNonSafe.length - CAGE_DISPLAY_LIMIT > 0 && (
          <Text fontSize="xs">{oxygenNonSafe.length - CAGE_DISPLAY_LIMIT} more non-safe...</Text>
        )}
        <Text color="blue.500" fontWeight="bold" fontSize="xs">
          (Last 30 Minutes)
        </Text>
      </CardBody>
    </Card>
  );
};
