// @ts-strict
import TabHeadline from 'components/TabHeadline';
import { HydrographyDataEntryDefinition } from 'contexts/ProjectContext';
import { HydrographyReport, Site } from 'graphql/generated';
import { useState } from 'react';
import { GiBubbles } from 'react-icons/gi';
import EntryForm from '../Entry/EntryForm';
import { HydrographyReportList } from './HydrographyReportList';

interface HydrographyDataEntryTabProps {
  formInfo: HydrographyDataEntryDefinition;
  site: Site;
}

export function HydrographyEntry({ formInfo, site }: HydrographyDataEntryTabProps) {
  const [formIsVisible, setFormIsVisible] = useState(false);
  const [activeReport, setActiveReport] = useState<HydrographyReport | null>(null);
  function handleReportFormOpen(report: HydrographyReport | null) {
    setFormIsVisible(true);
    setActiveReport(report);
  }
  return (
    <>
      <TabHeadline text="Submit hydrography data from Pilot sites." icon={<GiBubbles />} />
      <div data-cypress={'hydrography-data-entry'}>
        <HydrographyReportList
          site={site}
          openReportForm={handleReportFormOpen}
          formInfo={formInfo}
        />
        {formIsVisible && (
          <EntryForm report={activeReport} site={site} onClose={() => setFormIsVisible(false)} />
        )}
      </div>
    </>
  );
}
