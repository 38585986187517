import { HStack } from '@chakra-ui/react';
import ChartSelect from '../ChartSelect';
import TimeFragmentPicker from '../Custom/TimeFragmentPicker';
import { ChartController } from '../types';
import { ChartSettings } from './RecentTrendsV2';

export type ControllerInputs = {
  sublocations: { label: string; value: string }[];
};

export const trendsTimeFrames = [
  { label: 'Last Day', value: 'last 1440 minutes' },
  { label: 'Last Week', value: 'last 10080 minutes' },
  { label: 'Last Month', value: 'last 43200 minutes' },
  { label: 'Last Quarter', value: 'last 172800 minutes' },
  { label: 'Last 6 Months', value: 'last 259200 minutes' }
];

const Controller = ({
  setChartSettings,
  chartSettings,
  chartInputs
}: ChartController<ChartSettings, ControllerInputs>) => {
  return (
    <HStack mt="10px" w="100%" justifyContent="space-evenly">
      <ChartSelect
        isMulti={true}
        minWidth="200px"
        maxWidth="400px"
        width="100%"
        label="Sublocation(s)"
        options={chartInputs.sublocations}
        value={chartSettings.sublocations}
        onChange={(options: { label: string; value: string }[]) =>
          setChartSettings({ ...chartSettings, sublocations: options })
        }
      />
      <TimeFragmentPicker
        display="inline"
        timeFragment={{ granularity: 'minute', dateRange: chartSettings.dateRange }}
        onChange={(frag) => setChartSettings({ ...chartSettings, dateRange: frag.dateRange })}
        showGranularity={false}
        timeFramesOverride={trendsTimeFrames}
      />
    </HStack>
  );
};

export default Controller;
