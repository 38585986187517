import TabHeadline from 'components/TabHeadline';
import { PlanktonReport, Site } from 'graphql/generated';
import { useState } from 'react';
import { GiLongAntennaeBug } from 'react-icons/gi';
import EntryForm from '../Entry/EntryForm';
import { PlanktonReportList } from './PlanktonReportList';

interface PlanktonDataEntryTabProps {
  site: Site;
}

export function PlanktonEntry({ site }: PlanktonDataEntryTabProps) {
  const [formIsVisible, setFormIsVisible] = useState(false);
  const [activeReport, setActiveReport] = useState<PlanktonReport | null>();

  function handleReportFormOpen(report: PlanktonReport) {
    setFormIsVisible(true);
    setActiveReport(report);
  }

  return (
    <>
      <TabHeadline
        text="Submit plankton data from discrete or tow samples directly to SeaState. Data will populate the Plankton Charts under the Plankton tab and can be viewed and exported alongside other farm data in the Explore tab."
        icon={<GiLongAntennaeBug />}
      />
      <div data-cypress={'plankton-data-entry'}>
        <PlanktonReportList site={site} openReportForm={handleReportFormOpen} />
        {formIsVisible && (
          <EntryForm site={site} report={activeReport} onClose={() => setFormIsVisible(false)} />
        )}
      </div>
    </>
  );
}
