import { Skeleton } from '@chakra-ui/react';
import { ProjectContext } from 'contexts/ProjectContext';
import { format, max as maxDate, min } from 'date-fns';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, max, range, uniq } from 'lodash';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { ReactChild, useContext, useMemo } from 'react';
import { planktonThresholdToColor, thresholdForSpeciesValue } from 'shared/plankton';
import GraphError from '../GraphError';
import NoData from '../NoData';
import Plot from '../Plot';

type PlanktonCubeDatum = {
  'TessPlanktonLookup.sublocation'?: string;
  'Site.id'?: string;
  'LatestTessPlankton.measuredAt': string;
  'LatestTessPlankton.measuredAt.minute': string;
  'TessPlanktonLookup.species': string;
  'TessPlanktonLookup.depth': string;
  'LatestTessPlankton.avgCellCount': number;
  'LatestTessPlankton.maxCellCount': number;
};

// Target data structure for plot output
type PlanktonStructure = {
  [sublocation: string]: {
    [species: string]: {
      [depth: number]: {
        [measuredAt: string]: {
          avgCellCount: number;
          maxCellCount: number;
          threshold: string;
          maxCellCountOverall: number;
        };
      };
    };
  };
};

const LatestSample = ({
  smbSiteId,
  selectedSublocation,
  control,
  skip
}: {
  smbSiteId: number;
  selectedSublocation: { label: string; value: string }[];
  control: (sublocations: { label: string; value: string }[]) => ReactChild;
  skip?: boolean;
}) => {
  const projectContext = useContext(ProjectContext);
  const locationDimension = smbSiteId ? 'TessPlanktonLookup.sublocation' : 'Site.id';

  const transform = (rawData: PlanktonCubeDatum[]): PlanktonStructure => {
    const selectedSublocations = selectedSublocation.map((d) => d.value);
    const allAvgCellCounts = rawData.flatMap((d) => d['LatestTessPlankton.avgCellCount']);
    let filteredDataSublocation = rawData;
    if (selectedSublocations.length > 0 && !selectedSublocations.includes('All')) {
      filteredDataSublocation = filteredDataSublocation.filter((d) =>
        selectedSublocations.includes(d[locationDimension])
      );
    }
    // Add threshold values from projectContext using counts of plankton.
    const maxCellCount = max(allAvgCellCounts);
    const processedPlanktonData = filteredDataSublocation.map((d) => {
      return {
        ...d,
        threshold: thresholdForSpeciesValue(
          projectContext,
          d['TessPlanktonLookup.species'],
          d['LatestTessPlankton.maxCellCount']
        ),
        'TessPlanktonLookup.depth': d['TessPlanktonLookup.depth'] || 'depthAvg',
        maxCellCountOverall: maxCellCount
      };
    });

    const bySublocation = groupBy(processedPlanktonData, locationDimension);
    // Aggregate on sublocation and depth
    const depthData = Object.entries(bySublocation)
      .sort((one, two) => (one > two ? 1 : -1))
      .reduce((sublocAcc, [sublocation, dataAtSublocation]) => {
        // Aggregate on species
        const byDepth = Object.entries(groupBy(dataAtSublocation, 'TessPlanktonLookup.species'))
          .sort((a, b) => Number(a) - Number(b))
          .reduce((depthAcc, [depth, dataAtDepth]) => {
            const bySpecies = Object.entries(
              groupBy(dataAtDepth, 'TessPlanktonLookup.depth')
            ).reduce((speciesAcc, [species, speciesData]) => {
              // depthAcc[depth] = depthAcc[depth] || {};
              speciesAcc[species] = speciesAcc[species] || {};
              speciesData.forEach((d) => {
                speciesAcc[species][d['LatestTessPlankton.measuredAt.minute']] = {
                  // species: d['TessPlanktonLookup.species'],
                  avgCellCount: d['LatestTessPlankton.avgCellCount'],
                  threshold: d['threshold'],
                  maxCellCountOverall: d['maxCellCountOverall']
                };
              }, {});
              return speciesAcc;
            }, {});
            depthAcc[depth] = bySpecies;
            return depthAcc;
          }, {});
        sublocAcc[sublocation] = byDepth;
        return sublocAcc;
      }, {});
    return depthData;
  };

  const graph = (data: PlanktonStructure): PlotlyDataLayoutConfig => {
    const tickTransform = Math.sqrt;
    const startTime = new Date();
    const endTime = new Date(startTime.getTime());
    startTime.setDate(startTime.getDate() - 7);
    endTime.setDate(endTime.getDate() + 1);
    // get lists of dates and depths so we can use them for plot layout attributes
    let uniqueSublocations = [];
    let uniqueDates = [];
    let maxCellVals = [];
    const sppAggs = {} as { string: number[] };
    Object.entries(data).flatMap(([sublocation, sublocationData]) => {
      uniqueSublocations.push(sublocation);
      Object.entries(sublocationData).flatMap(([species, sppData]) => {
        sppAggs[species] = sppAggs[species] ? sppAggs[species] : [];
        const depthAvgArray = [];
        Object.values(sppData).flatMap((dataAtDepth) => {
          const aggAvgCellCount =
            Object.values(dataAtDepth).reduce((a, b) => a + b.avgCellCount, 0) /
            Object.values(dataAtDepth).length;
          depthAvgArray.push(aggAvgCellCount);
          const maxCellVal = max(Object.values(dataAtDepth).flatMap((d) => d.maxCellCountOverall));
          uniqueDates.push(...Object.keys(dataAtDepth));
          maxCellVals.push(maxCellVal);
        }, {});
        const depthAvg = depthAvgArray.reduce((a, b) => a + b, 0) / depthAvgArray.length;
        sppAggs[species].push(depthAvg);
      }, {});
    }, {});
    maxCellVals = max(maxCellVals);
    uniqueSublocations = uniq(uniqueSublocations);
    uniqueDates = uniq(uniqueDates).map((d) => new Date(d));
    const layout = {
      // ...layout,
      autosize: true,
      height: 500,
      // width: 100,
      showlegend: false,
      margin: { t: 120, b: 20, r: 90 },
      // hovermode: 'unified',
      polar: {
        // hole: 0.05,
        radialaxis: {
          title: { text: '<b>cells/mL', font: { size: 12 } },
          tickfont: {
            size: 12
          },
          showgrid: true,
          // type: 'log',
          autorange: false,
          range: [0, tickTransform(Number(maxCellVals))],
          angle: 0
        },
        angularaxis: {
          // type: 'log',
          direction: 'clockwise',
          period: 6,
          showgrid: true,
          showticklabels: true,
          tickfont: { size: 10 }
        }
      },
      legend: {
        orientation: 'h',
        x: 0,
        y: 1.1,
        font: {
          size: 14
        }
      },
      annotations: [
        {
          xref: 'paper',
          yref: 'paper',
          x: 0.5,
          // borderpad: 0.4,
          xanchor: 'center',
          y: 1.275,
          yanchor: 'center',
          textangle: 0,
          text:
            '<b>Sample(s) taken:</b><br> ' +
            (uniqueDates.length > 0
              ? `${format(new Date(min(uniqueDates)), 'PPp')} - ${format(
                  new Date(maxDate(uniqueDates)),
                  'PPp'
                )}`
              : 'None') +
            // uniqueDates
            //   .flatMap((d) => {
            //     const timeTag = `${format(new Date(d), 'PPPPpppp')} <br>`;
            //     return timeTag;
            //   })
            //   .join('') +
            `<b> at </b> ${
              uniqueSublocations.length < 4
                ? uniqueSublocations.join(', ')
                : `${uniqueSublocations.length} sublocations`
            }`,
          font: { size: 12 },
          showarrow: false
        }
      ]
      //@ts-ignore
      // annotations
    };

    const fullsppAggs = Object.entries(sppAggs).reduce((fullSppAgg, [species, speciesArray]) => {
      fullSppAgg[species] = speciesArray.reduce((a, b) => a + b, 0) / speciesArray.length;
      return fullSppAgg;
    }, {});
    // Do we want to add in all possible species to the circle? Makes it look pretty awful b/c there are so many
    // There seem to be 235 spp in one project config. We could do this by querying for some number of the most recently seen or most abundant spp.
    // Object.keys(projectContext.thresholds['plankton']).forEach((d) => {
    //   fullsppAggs[lowerCase(d).replaceAll(' ', '-')] =
    //     fullsppAggs[lowerCase(d).replaceAll(' ', '-')] || 0;
    // });
    let thetaCounter = 0;
    const legendFlags = {};
    Object.keys(fullsppAggs).forEach((spp) => {
      legendFlags[spp] = true;
    });
    const numberOfSpecies = Object.keys(fullsppAggs).length;
    const radialTickLabels = [];
    const radialTickText = [];
    const numberOfTicks = 5;
    if (Number(maxCellVals) > 0) {
      for (let i = 0; i < Number(maxCellVals); i += Number(maxCellVals) / numberOfTicks) {
        radialTickLabels.push(tickTransform(i).toFixed(2));
        radialTickText.push(Number(maxCellVals) > 20 ? i.toFixed(0) : i.toFixed(2));
      }
    }
    layout.polar.radialaxis['tickmode'] = 'array';
    layout.polar.radialaxis['tickvals'] = radialTickLabels;
    layout.polar.radialaxis['ticktext'] = radialTickText;
    layout.polar.angularaxis['tickvals'] = [];
    layout.polar.angularaxis['ticktext'] = Object.keys(fullsppAggs).map(
      (d) => projectContext.findPlanktonPolicy(d).name
    );
    //@ts-ignore
    const plotData: Data[] = Object.entries(fullsppAggs).map(([species, avgForSpecies]) => {
      const thetaIncrement = numberOfSpecies > 8 ? -360 / numberOfSpecies : -360 / 8;
      const startTheta = thetaCounter * thetaIncrement + 90;
      const endTheta = thetaCounter * thetaIncrement + thetaIncrement + 90;
      const thetaPoints = 30;
      const thetaRange = range(startTheta, endTheta, (endTheta - startTheta) / thetaPoints);
      const hoverText = {} as { species: { depth: number[] } };
      Object.values(data).flatMap((sublocationData) => {
        if (Object.keys(sublocationData).includes(species)) {
          const sppData = sublocationData[species];
          Object.entries(sppData).flatMap(([depth, depthData]) => {
            // need to aggregate across sublocation for the hovertext
            hoverText[species] = hoverText[species] || {};
            hoverText[species][depth] = hoverText[species][depth] || [];
            const depthAvg = depthData
              ? Object.values(depthData).reduce((rowAcc, row) => rowAcc + row.avgCellCount, 0)
              : 0;
            hoverText[species][depth].push(depthAvg);
          }, {});
        }
      });
      const policy = projectContext.findPlanktonPolicy(species);
      const sppPlot = {
        mode: 'lines',
        type: 'scatterpolargl',
        theta: [0, ...thetaRange, 0],
        r: [0, ...Array(thetaPoints).fill(tickTransform(Number(avgForSpecies))), 0],
        fill: 'toself',
        fillcolor: avgForSpecies
          ? planktonThresholdToColor(
              thresholdForSpeciesValue(projectContext, species, Number(avgForSpecies)),
              { useHex: true }
            )
          : 'black',
        name: policy.name,
        hovertemplate: `Depth: cells/mL<br>${Object.values(hoverText).flatMap((depthData) => {
          return Object.entries(depthData).flatMap(([depth, counts]) => {
            return `${depth}m: ${(counts.reduce((a, b) => a + b, 0) / counts.length).toFixed(
              1
            )}<br>`;
          });
        })}`.replaceAll(',', ''),
        legendgroup: policy.name,
        showlegend: legendFlags[species],
        line: {
          width: 3,
          color: 'black'
        }
      };
      const labelAngle = thetaCounter * thetaIncrement + 0.5 * thetaIncrement + 90;
      layout.polar.angularaxis['tickvals'].push(labelAngle > 0 ? labelAngle : 360 + labelAngle);
      if (legendFlags[species]) {
        legendFlags[species] = false;
      }
      thetaCounter++;
      return sppPlot;
    }, {});
    return {
      data: plotData,
      //@ts-ignore
      layout: layout,
      //@ts-ignore
      config: {
        modeBarButtonsToAdd: ['resetScale2d']
      }
    };
  };
  const queryDimensions = [
    locationDimension,
    'TessPlanktonLookup.species',
    'TessPlanktonLookup.depth'
  ];
  const { isLoading, error, plot, resultSet } = useCubeLTG({
    cubeQuery: {
      timeDimensions: [
        {
          dimension: 'LatestTessPlankton.measuredAt',
          granularity: 'minute',
          dateRange: 'from 4320 minutes ago to 1440 minutes from now'
        }
      ],
      dimensions: queryDimensions,
      measures: ['LatestTessPlankton.avgCellCount', 'LatestTessPlankton.maxCellCount'],
      filters: smbSiteId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [smbSiteId.toString()]
            },
            { member: 'TessPlanktonLookup.method', operator: 'equals', values: ['discrete'] },
            { member: locationDimension, operator: 'set' },
            { member: 'TessPlanktonLookup.depth', operator: 'set' },
            { member: 'LatestTessPlankton.avgCellCount', operator: 'gt', values: ['0'] }
          ]
        : [
            { member: 'TessPlanktonLookup.method', operator: 'equals', values: ['discrete'] },
            { member: locationDimension, operator: 'set' },
            { member: 'TessPlanktonLookup.depth', operator: 'set' },
            { member: 'LatestTessPlankton.avgCellCount', operator: 'gt', values: ['0'] }
          ],
      timezone: projectContext.timezone
    },
    transform,
    graph,
    options: {
      refreshInterval: 900000,
      skip,
      dependencies: {
        selectedSublocation
      }
    }
  });

  const sublocations = useMemo(() => {
    const uniqueIds = uniq(resultSet?.rawData().map((d) => d[locationDimension]));
    const sublocMap = uniqueIds.flatMap((d) => {
      return {
        label: smbSiteId ? d : projectContext.siteNameMappings[d],
        value: d
      };
    });
    return sublocMap;
  }, [resultSet]);

  if (isLoading) {
    return (
      <>
        {control(sublocations)}
        <Skeleton minH="500px" height="100%" width="100%" />
      </>
    );
  }

  if (error) {
    return <GraphError minH="500px" />;
  }
  return plot?.data?.length > 0 ? (
    <>
      {control(sublocations)}
      <Plot className="w-100 plankton-polar-chart" useResizeHandler={true} {...plot} />
    </>
  ) : (
    <>
      {control(sublocations)}
      <NoData minH="500px" />
    </>
  );
};

export default LatestSample;
