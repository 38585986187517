import { useController, UseControllerProps } from 'react-hook-form';
import BaseInput, { BaseInputProps } from './BaseInput';

type FormInputProps<TForm> = BaseInputProps & UseControllerProps<TForm>;

const FormInput = <TForm,>(props: FormInputProps<TForm>) => {
  const { field, fieldState } = useController(props);

  //@ts-ignore
  return <BaseInput {...field} {...fieldState} {...props} />;
};

export default FormInput;
