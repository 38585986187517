import { gql } from '@apollo/client';

const GET_EVENTS = gql`
  query getEvents($siteId: Int!, $startTime: Date, $endTime: Date) {
    getEvents(siteId: $siteId, startTime: $startTime, endTime: $endTime) {
      id
      eventType
      severity
      creator
      created
      siteId
      startTime
      endTime
      trace
      detail
      comments {
        id
        eventId
        creator
        comment
        created
        attachmentRefs
      }
      subscribed
      hasNew
    }
  }
`;

export { GET_EVENTS };
