import { Link, Text, VStack } from '@chakra-ui/react';
import FAQ from 'components/FAQ/FAQ';
import HelpVideos from '../components/UserGuide/HelpVideos';
import './Docs.css';

const Docs = () => {
  return (
    <VStack mb="150px">
      <FAQ />
      <HelpVideos />

      <Text style={{ margin: '1em 0' }} fontSize="4xl">
        API
      </Text>
      <Link isExternal={true} href="/docs/api.html">
        <Text color="blue.500" fontSize="2xl">
          SeaState API Documentation
        </Text>
      </Link>
      <Text style={{ margin: '1em 0' }} fontSize="4xl"></Text>
    </VStack>
  );
};

export default Docs;
