import { Flex, VStack, Text, useRadioGroup } from '@chakra-ui/react';
import RadioCard from 'components/Forms/RadioCard';
import { useContext } from 'react';
import { ReportingEditContext } from './ReportingEditContext';

const SelectProjectReportTemplate = () => {
  const { setSelectedReportTemplate, selectedReportTemplate, reportTemplates } =
    useContext(ReportingEditContext);

  const { getRadioProps } = useRadioGroup({
    value: selectedReportTemplate.id,
    onChange: (value) => setSelectedReportTemplate(reportTemplates.find((r) => r.id === value))
  });

  return (
    <VStack>
      <Text fontSize="2xl">Select Report Type</Text>
      <Flex w="100%" justifyContent="space-evenly">
        {reportTemplates.map((template) => (
          <RadioCard
            key={template.label}
            isDisabled={template?.isDisabled}
            cardProps={{ w: '300px', h: '300px' }}
            {...getRadioProps({ value: template.id })}>
            <VStack h="80%" justifyContent="center">
              {template?.icon && template.icon}
              <Text fontSize="2xl" display="inline">
                {template.label}
              </Text>
            </VStack>
          </RadioCard>
        ))}
      </Flex>
    </VStack>
  );
};

export default SelectProjectReportTemplate;
