import { FormControl, FormControlProps, FormLabel, Switch, SwitchProps } from '@chakra-ui/react';
import React, { ReactChild } from 'react';
import InfoPopover from '../InfoPopover';

type ChartSwitchOption = {
  value: string;
  label: string;
  tooltip?: ReactChild | string;
};

export type ChartSwitchProps = {
  left: ChartSwitchOption;
  right: ChartSwitchOption;
  toggle: ({ checked, value }: { checked: boolean; value: ChartSwitchOption }) => void;
  switchProps?: SwitchProps;
  formControlProps?: FormControlProps;
};

const ChartSwitch = ({ left, right, toggle, switchProps, formControlProps }: ChartSwitchProps) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    e.target.checked
      ? toggle({ checked: true, value: right })
      : toggle({ checked: false, value: left });

  return (
    <FormControl w={'fit-content'} {...formControlProps}>
      <FormLabel display="inline" htmlFor={left.value}>
        {left?.label}
      </FormLabel>
      {left?.tooltip && <InfoPopover>{left.tooltip}</InfoPopover>}
      <Switch
        id="use-local-min-max"
        onChange={onChange}
        mr="1rem"
        colorScheme="blue"
        pt="2px"
        {...switchProps}
      />
      <FormLabel display="inline" htmlFor={right.value}>
        {right.label}
      </FormLabel>
      {right?.tooltip && <InfoPopover>{right.tooltip}</InfoPopover>}
    </FormControl>
  );
};

export default ChartSwitch;
